<template>
    <v-form ref="form">
        <v-row>
            <v-col cols="12" sm="12">
                <v-card>
                    <v-card-title>Anexos</v-card-title>
                    <v-card-text>
                        <v-alert v-if="!dadosAnexo.length" outlined type="warning" prominent text>
                            NENHUM ANEXO REALIZADO.
                        </v-alert>

                        <v-chip
                            v-for="(anexo, i) in dadosAnexo"
                            :key="i"
                            :color="selecionaCor()"
                            @click="downloadAnexo(anexo.id_documento_anexo)"
                            class="ma-2"
                            dark
                            label
                            small
                        >
                            {{ anexo.nome }}
                        </v-chip>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" sm="12">
                <v-card>
                    <v-card-title>Arquivos</v-card-title>

                    <v-card-text>
                        <InputFileV4
                            v-model="form.anexo.arquivos"
                            :regra="regrasArquivo"
                            :multiplo="true"
                            required
                            icone="upload"
                            rotulo="Selecione o arquivo (Excel, PDF, Imagem)"
                            tipoArquivo=".xls,.xlsx,.pdf,.doc"
                            v-on:change="adicionaArquivos($event)"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import InputFileV4 from '@/components/Form/InputFileV4.vue';

export default {
    name: 'FormAnexos',
    components: {
        InputFileV4
    },
    props: {
        dadosAnexo: {
            type: Object | Array,
            default() {
                return {};
            }
        }
    },
    async created() {},
    async mounted() {},
    computed: {},
    watch: {
        'dadosAnexo.anexo': {
            handler: function(dadosAtual, dadosAntigo) {
                this.form.anexo = [];
            },
            deep: true
        }
    },
    methods: {
        adicionaAnexo: function(dadosAnexo) {
            let arquivo = { arquivo: null };

            this.form.anexo.push(arquivo);
        },
        removeAnexo(posicao) {
            const listaAnexos = this.form.anexo.filter((item, index) => index !== posicao);

            this.form.anexo = [];

            listaAnexos.forEach((dados, index) => {
                this.form.anexo.push(dados.anexo);
            });
        },
        adicionaArquivos(arquivo) {
            this.form.anexo.arquivos = arquivo;
        },
        selecionaCor() {
            const posicao = parseInt(Math.random() * (this.cores.length - 1) + 1);

            return `${this.cores[posicao]}`;
        },
        preparaDados() {
            return this.form.anexo.arquivos;
        },
        async downloadAnexo(id) {
            await ApiService({
                method: 'GET',
                url: `${API_URL.erp.planodesenvolvimentoindividual.downloadAnexo}/${id}`,
                responseType: 'blob',
                mostraCarregando: false
            })
                .then(resposta => {
                    const blob = new Blob([resposta.data], { type: resposta.data.type });
                    const url = window.URL.createObjectURL(blob);
                    window.open(url);
                })
                .catch(erro => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        }
    },
    data() {
        return {
            arquivo: [],
            regrasArquivo: [
                v => !!v || 'Campo é obrigatório',
                files => !files || !files.some(file => file.size > 3000000) || 'Arquivo não pode ser maior que 3 MB!'
            ],
            form: {
                anexo: {
                    arquivos: []
                }
            },
            cores: [
                'green lighten-3',
                'pink lighten-3',
                'purple lighten-3',
                'deep-purple lighten-3',
                'blue lighten-3',
                'indigo lighten-3',
                'teal lighten-3',
                'orange lighten-3',
                'light-blue lighten-3',
                'cyan darken-1',
                'teal lighten-3',
                'yellow darken-1',
                'amber lighten-3',
                'deep-orange lighten-3',
                'brown lighten-3',
                'blue-grey'
            ]
        };
    }
};
</script>

<style></style>
