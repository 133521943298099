import { API_URL_BASE, API_URL } from '@/config/configs';

const Modulo = {
    datatable: {
        id_datatable: 'modulo',
        chave_primaria: 'id_modulo',
        url: `${API_URL_BASE}${API_URL.erp.modulo.datatable}`,
        colunas: false,
    },
    formRefsComponente: 'ModuloForm',
    formNome: 'formModulo',
    moduloNome: 'modulo',
    chavePrimaria: 'id_modulo',
    componenteArquivo: 'Modulo/Form.vue',
    tabRefs: 'tabModulo',
    urlNovoRegistro: '',
    urlConsultaRegistro: 'listaPorIdForm',
    tabs: []
}

export { Modulo };

