<template>
    <v-card outlined elevation="2">
        <CarregandoOverlay :overlay="overlay" mensagem="Preparando relatório, aguarde..." />

        <v-card-title class="">
            <span class="text-h6">
                Demanda

                <v-menu rounded="0" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="text-h7" color="grey darken-2" v-bind="attrs" v-on="on" dark tile>
                            De: {{ formataData(dataDe) }} Até: {{ formataData(dataAte) }}
                            <v-icon right dark>
                                mdi-chevron-down
                            </v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item link>
                            <v-list-item-title class="pt-1">
                                <DateTimePickerV2
                                    v-model="data_de"
                                    :dataInicial="data_de"
                                    label="Data de:"
                                    data-vv-delay="1000"
                                    class="mt-10"
                                />

                                <DateTimePickerV2
                                    v-model="data_ate"
                                    :dataInicial="data_ate"
                                    label="Data ate:"
                                    data-vv-delay="1000"
                                />
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                &nbsp;&nbsp;

                <v-menu rounded="0" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="text-h7" color="grey darken-2" v-bind="attrs" v-on="on" dark tile>
                            Somente container ?

                            <v-chip small class="ma-2" :color="apenas_container ? 'green' : 'red'" text-color="white">
                                <v-icon small left>
                                    mdi-alert-circle-outline
                                </v-icon>

                                <span v-if="apenas_container"> SIM</span>
                                <span v-else> NÃO</span>
                            </v-chip>

                            <v-icon right dark>
                                mdi-chevron-down
                            </v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item link>
                            <v-list-item-title class="pl-1">
                                <ButtonSwitchV3 v-model="apenas_container" :mostraIcone="true" />
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </span>

            <v-spacer></v-spacer>

            <span class="pr-2">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="green darken-1" dark v-bind="attrs" v-on="on" @click="geraExcel()">
                            mdi-file-excel
                        </v-icon>
                    </template>
                    <span>Gera Excel</span>
                </v-tooltip>
            </span>

            <span class="pr-2">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="blue darken-1" dark v-bind="attrs" v-on="on" @click="downloadImagem()">
                            mdi-cloud-download
                        </v-icon>
                    </template>
                    <span>Baixar imagem</span>
                </v-tooltip>
            </span>

            <span class="pr-2">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="blue-grey darken-1"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="carregaDashboard(data_de, data_ate)"
                        >
                            mdi-refresh
                        </v-icon>
                    </template>
                    <span>Recarregar</span>
                </v-tooltip>
            </span>

            <span class="pr-2">
                <v-tooltip max-width="200" left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="warning darken-1" dark v-bind="attrs" v-on="on" @click="ajuda = true">
                            mdi-account-question
                        </v-icon>
                    </template>
                    <span>
                        Ajuda com a composição do gráfico ? Clique para saber mais.
                    </span>
                </v-tooltip>
            </span>
        </v-card-title>

        <v-card-text>
            <v-chart
                :option="opcoes"
                :init-options="initOptions"
                :loading="carregando"
                :loadingOptions="carregandoOpcoes"
                :style="tamanhoPixel"
                ref="Grafico"
                autoresize
            />
        </v-card-text>

        <div class="text-center">
            <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                {{ notificacao.mensagem }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>

        <v-dialog v-model="ajuda" max-width="800">
            <v-card>
                <v-card-title class="text-h5"> Demanda Data De: {{ data_de }} Até: {{ data_ate }} </v-card-title>

                <v-card-text>
                    Programações de cargas com o status Previsto e Confirmado, de acordo com o filtro da data de e até. Filtro
                    de data está considerando Data Pedido.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="blue darken-1" text @click="ajuda = false">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import moment from 'moment';
import VChart from 'vue-echarts';

moment.locale('pt-br');

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { DownloadArquivo } from '@/helpers/Funcoes.js';

import CarregandoOverlay from '@/components/Default/CarregandoOverlay.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';
import DateTimePickerV2 from '@/components/Form/DateTimePickerV2.vue';
import ButtonSwitchV3 from '@/components/Form/ButtonSwitchV3.vue';

export default {
    name: 'Demanda',
    components: {
        VChart,
        CarregandoOverlay,
        InputSelectV3,
        DateTimePickerV2,
        ButtonSwitchV3
    },
    async mounted() {
        this.opcoes.color = this.cor;
        this.opcoes.series[0].emphasis = this.emphasis;
        this.opcoes.series[1].emphasis = this.emphasis;

        this.carregaDashboard(this.data_de, this.data_ate);
    },
    computed: {
        ...mapGetters({
            carregando: 'demanda/carregando',
            carregandoOpcoes: 'demanda/carregandoOpcoes',
            dataDe: 'demanda/dataDe',
            dataAte: 'demanda/dataAte',
            container: 'demanda/container',
            dadosDemanda: 'demanda/dadosDemanda',
            tamanhoDashboard: 'dashboard/tamanhoDashboard'
        }),
        ...mapState('demanda', {
            cor(state, getters) {
                return getters['cor'];
            },
            emphasis(state, getters) {
                return getters['emphasis'];
            }
        }),
        tamanhoPixel() {
            return this.tamanhoDashboard({ id_dashboard: this.id_dashboard });
        },
        data_de: {
            get() {
                return this.dataDe;
            },
            set(novoValor) {
                this.atualizaDataDe(novoValor);
            }
        },
        data_ate: {
            get() {
                return this.dataAte;
            },
            set(novoValor) {
                this.atualizaDataAte(novoValor);
                this.carregaDashboard(this.data_de, novoValor, this.apenas_container);
            }
        },
        apenas_container: {
            get() {
                return this.container;
            },
            set(novoValor) {
                this.atualizaContainer(novoValor);
                this.carregaDashboard(this.data_de, this.data_ate, novoValor);
            }
        }
    },
    methods: {
        ...mapActions({
            buscaDashboard: 'demanda/buscaDashboard',
            baixarImagem: 'demanda/baixarImagem',
            atualizaDataDe: 'demanda/atualizaDataDe',
            atualizaDataAte: 'demanda/atualizaDataAte',
            atualizaContainer: 'demanda/atualizaContainer'
        }),
        carregaDashboard(data_de, data_ate, apenas_container) {
            this.buscaDashboard({ data_de: data_de, data_ate: data_ate, container: apenas_container })
                .then(async () => {
                    this.adicionaDadosAoGrafico(this.dadosDemanda);
                })
                .catch(erro => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        },
        intervaloEntreDatas(dataInicio, dataFim) {
            const dias = [];
            const diaAtual = moment(dataInicio);
            const fim = moment(dataFim);

            while (diaAtual.isSameOrBefore(fim, 'day')) {
                dias.push(diaAtual.format('DD/MM/YYYY'));
                diaAtual.add(1, 'day');
            }

            return dias;
        },
        formataData(data) {
            return moment(data).format('DD/MM/YYYY');
        },
        downloadImagem() {
            this.baixarImagem(this.$refs['Grafico']);
        },
        async geraExcel() {
            try {
                this.overlay = true;

                const parametros = {
                    data_de: this.dataDe,
                    data_ate: this.dataAte,
                    container: this.container
                };

                await ApiService({
                    method: 'GET',
                    url: API_URL.erp.relatorio.programacaoCarga.demanda,
                    responseType: 'blob',
                    mostraCarregando: false,
                    params: parametros
                })
                    .then(resposta => {
                        DownloadArquivo(resposta.data, resposta.headers['x-suggested-filename']);

                        this.notificacao = {
                            mensagem: 'Relatório gerado, verifique a pasta de Downloads',
                            tipo: 'warning',
                            mostra: true
                        };
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.overlay = false;
            }
        },
        adicionaDadosAoGrafico(graficoDados) {
            this.opcoes.series[0].data = graficoDados.previsto;
            this.opcoes.series[1].data = graficoDados.confirmado;

            this.opcoes.xAxis[0].data = graficoDados?.data_retirada?.map(item => {
                let nomeDoDia = moment(item).format('dddd');

                nomeDoDia = nomeDoDia.charAt(0).toUpperCase() + nomeDoDia.slice(1);

                return this.formataData(item) + `\r\n\r\n ${nomeDoDia}`;
            });
        }
    },
    data() {
        return {
            id_dashboard: 8,
            ajuda: false,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            overlay: false,
            initOptions: {
                renderer: 'png'
            },
            opcoes: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                legend: {
                    data: ['Previsto', 'Confirmado']
                },
                toolbox: {
                    feature: {
                        dataView: { show: false, readOnly: false },
                        restore: { show: false },
                        saveAsImage: { show: false }
                    }
                },
                grid: {
                    top: '20%',
                    bottom: '28%',
                    right: '5%'
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            show: true
                        },
                        axisLabel: {
                            fontSize: 12,
                            fontWeight: 'bold',
                            rotate: 50,
                            margin: 15
                        },
                        data: []
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: 'QTD.',
                        position: 'left',
                        nameRotate: 50,
                        alignTicks: true,
                        axisLine: {
                            show: true
                        },
                        axisLabel: {
                            fontSize: 12,
                            fontWeight: 'bold',
                            formatter: '{value}'
                        },
                        nameTextStyle: {
                            fontSize: 12,
                            fontWeight: 'bold'
                        },
                        splitArea: {
                            show: true
                        }
                    }
                ],
                series: [
                    {
                        name: 'Previsto',
                        type: 'bar',
                        yAxisIndex: 0,
                        data: [],
                        itemStyle: {
                            borderType: 'dashed'
                        },
                        label: {
                            rotate: 50,
                            show: true,
                            fontSize: 10,
                            fontWeight: 'bold',
                            position: 'top',
                            formatter: function(value) {
                                return `${value.value}`;
                            }
                        },
                        emphasis: this.emphasis
                    },
                    {
                        name: 'Confirmado',
                        type: 'bar',
                        yAxisIndex: 0,
                        data: [],
                        itemStyle: {
                            borderType: 'dashed'
                        },
                        label: {
                            rotate: 50,
                            show: true,
                            fontSize: 10,
                            fontWeight: 'bold',
                            shadowBlur: 3,
                            position: 'top',
                            formatter: function(value) {
                                return `${value.value}`;
                            }
                        },
                        emphasis: this.emphasis
                    }
                ]
            }
        };
    }
};
</script>
