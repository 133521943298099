<template>
    <v-form ref="form">
        <v-row>
            <v-col cols="12" sm="8">
                <InputTextV3 v-model="form.id_profit_less_markup_receita_venda" rotulo="ID" :contador="200" v-show="false" />

                <InputTextMoedaV3
                    v-model="form.frete_peso_valor"
                    prefixo="R$"
                    rotulo="Frete Peso"
                    :desativado="true"
                    ajudaMensagem="Frete Custo (calculado no passo Client) * Margem Lucro Percentual em percentual"
                />
            </v-col>

            <v-col cols="12" sm="4">
                <InputTextMoedaV3
                    v-model="form.margem_lucro_percentual"
                    rotulo="Margem Lucro Percentual"
                    sufixo="%"
                    :regra="regraPadrao"
                />
            </v-col>

            <v-col cols="12" sm="4">
                <InputTextMoedaV3
                    v-model="form.seguro_valor"
                    prefixo="R$"
                    rotulo="Seguro"
                    :desativado="true"
                    ajudaMensagem="Valor Mercadoria / Seguro Percentual em valor"
                />
            </v-col>

            <v-col cols="12" sm="2">
                <InputTextMoedaV3
                    v-model="form.seguro_percentual"
                    rotulo="Seguro Percentual"
                    sufixo="%"
                    :tamanho="4"
                    :regra="regraPadrao"
                />
            </v-col>

            <v-col cols="12" sm="4">
                <InputTextMoedaV3
                    v-model="form.gris_valor"
                    prefixo="R$"
                    rotulo="Gris"
                    :desativado="true"
                    ajudaMensagem="Valor Mercadoria / Gris Percentual em valor"
                />
            </v-col>

            <v-col cols="12" sm="2">
                <InputTextMoedaV3
                    v-model="form.gris_percentual"
                    rotulo="Gris Percentual"
                    sufixo="%"
                    :tamanho="4"
                    :regra="regraPadrao"
                />
            </v-col>

            <v-col cols="12" sm="4">
                <InputTextMoedaV3
                    v-model="form.taxa_imo_anvisa_valor"
                    prefixo="R$"
                    rotulo="Taxa IMO Anvisa"
                    :desativado="true"
                />
            </v-col>

            <v-col cols="12" sm="2">
                <InputTextMoedaV3
                    v-model="form.taxa_imo_anvisa_percentual"
                    rotulo="Taxa IMO Anvisa Percentual"
                    sufixo="%"
                    :tamanho="4"
                    :regra="regraPadrao"
                />
            </v-col>

            <v-col cols="12" sm="4">
                <InputTextMoedaV3
                    v-model="form.profit_calculado_valor"
                    prefixo="R$"
                    rotulo="Profit Calculado"
                    :desativado="true"
                />
            </v-col>

            <v-col cols="12" sm="2">
                <InputTextMoedaV3
                    v-model="form.profit_calculado_percentual"
                    rotulo="Profit Calculado Percentual"
                    sufixo="%"
                    :tamanho="4"
                    :regra="regraPadrao"
                />
            </v-col>

            <v-col cols="12" sm="4">
                <InputTextMoedaV3
                    v-model="form.repasse_profit_valor"
                    prefixo="R$"
                    rotulo="Repasse Profit"
                    :desativado="true"
                />
            </v-col>

            <v-col cols="12" sm="2">
                <InputTextMoedaV3
                    v-model="form.repasse_profit_percentual"
                    rotulo="Repasse Profit Percentual"
                    sufixo="%"
                    :tamanho="4"
                    :regra="regraPadrao"
                />
            </v-col>

            <v-col cols="12" sm="3">
                <InputTextMoedaV3 v-model="form.licenca_valor" prefixo="R$" rotulo="Licença" :desativado="true" />
            </v-col>

            <v-col cols="12" sm="3">
                <InputTextMoedaV3
                    v-model="form.pedagio_valor"
                    prefixo="R$"
                    rotulo="Pedágio"
                    ajudaMensagem="Pedágio calculado no passo Client"
                    :desativado="true"
                />
            </v-col>

            <v-col cols="12" sm="3">
                <InputTextMoedaV3 v-model="form.despacho_valor" prefixo="R$" rotulo="Despacho" :regra="regraPadrao" />
            </v-col>

            <v-col cols="12" sm="3">
                <InputTextMoedaV3 v-model="form.ajudante_valor" prefixo="R$" rotulo="Ajudante" :regra="regraPadrao" />
            </v-col>

            <v-col cols="12" sm="3">
                <InputTextMoedaV3 v-model="form.escolta_valor" prefixo="R$" rotulo="Escolta" :regra="regraPadrao" />
            </v-col>

            <v-col cols="12" sm="12" class="text-right">
                <span class="text-md-body-1 font-weight-black">
                    Total Sales Revenue
                </span>

                <v-chip class="ma-2 text-sm-body-1" label small>
                    {{ totalValoresFormatado }}
                </v-chip>

                <v-chip class="ma-2 text-sm-body-1" label small>
                    {{ totalValoresPercentualFormatado }}
                </v-chip>

                <v-spacer></v-spacer>

                <span class="text-md-body-1 font-weight-black">
                    Income taxes (ICMS)
                </span>

                <v-chip class="ma-2 text-sm-body-1" label small>
                    {{ icmsFormatado }}
                </v-chip>

                <v-chip class="ma-2 text-sm-body-1" label small>
                    {{ icmsPercentualFormatado }}
                </v-chip>

                <v-spacer></v-spacer>

                <span class="text-md-body-1 font-weight-black">
                    Other taxes (PIS + COFINS)
                </span>

                <v-chip class="ma-2 text-sm-body-1" label small>
                    {{ pisCofinsFormatado }}
                </v-chip>

                <v-spacer></v-spacer>

                <span class="text-md-body-1 font-weight-black">
                    INSS Empresa
                </span>

                <v-chip class="ma-2 text-sm-body-1" label small>
                    {{ inssEmpresaFormatado }}
                </v-chip>

                <v-chip class="ma-2 text-sm-body-1" label small>
                    {{ inssEmpresaPercentualFormatado }}
                </v-chip>

                <v-spacer></v-spacer>

                <span class="text-md-body-1 font-weight-black">
                    Payroll taxes (IR + CSLL)
                </span>

                <v-chip class="ma-2 text-sm-body-1" label small>
                    {{ irCsllFormatado }}
                </v-chip>

                <v-chip class="ma-2 text-sm-body-1" label small>
                    {{ irCsllPercentualFormatado }}
                </v-chip>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { Minimo, Obrigatorio } from '@/helpers/ValidadorCampo.js';

import { FormataMoeda } from '@/helpers/Funcoes.js';

import TipoOperacaoEnum from '@/Modulo/ProfitLessMarkup/Dominio/Enum/TipoOperacaoEnum.js';

import InputTextMoedaV3 from '@/components/Form/InputTextMoedaV3.vue';
import InputTextV3 from '@/components/Form/InputTextV3.vue';

export default {
    name: 'FormReceitaVenda',
    components: {
        InputTextMoedaV3,
        InputTextV3
    },
    props: {
        id_profit_less_markup_receita_venda: {
            type: Number,
            default: () => null
        },
        margem_lucro_percentual: {
            type: Number,
            required: true
        },
        valor_mercadoria: {
            type: Number,
            required: true
        },
        custo_venda_total: {
            type: Number,
            required: true
        },
        frete_custo_valor: {
            type: Number,
            required: true
        },
        frete_peso_valor: {
            type: Number,
            default: () => 0
        },
        pedagio_valor: {
            type: Number,
            required: true
        },
        seguro_valor: {
            type: Number,
            default: () => 0.1
        },
        seguro_percentual: {
            type: Number,
            required: true
        },
        gris_valor: {
            type: Number,
            default: () => 0
        },
        gris_percentual: {
            type: Number,
            default: () => 0.05
        },
        profit_calculado_valor: {
            type: Number,
            default: () => 0
        },
        profit_calculado_percentual: {
            type: Number,
            default: () => 10.0
        },
        repasse_profit_valor: {
            type: Number,
            default: () => 0
        },
        repasse_profit_percentual: {
            type: Number,
            default: () => 0
        },
        licenca_valor: {
            type: Number,
            default: () => 0
        },
        licenca_valor: {
            type: Number,
            default: () => 0
        },
        taxa_imo_anvisa_valor: {
            type: Number,
            default: () => 0
        },
        taxa_imo_anvisa_percentual: {
            type: Number,
            default: () => 10.0
        },
        despacho_valor: {
            type: Number,
            default: () => 0
        },
        ajudante_valor: {
            type: Number,
            default: () => 0
        },
        escolta_valor: {
            type: Number,
            default: () => 0
        },
        icms_valor: {
            type: Number,
            default: () => 0
        },
        icms_percentual: {
            type: Number,
            default: () => 0.88
        },
        pis_cofins_valor: {
            type: Number,
            default: () => 0
        },
        pis_cofins_percentual: {
            type: Number,
            default: () => 3.65
        },
        inss_empresa_valor: {
            type: Number,
            default: () => 0
        },
        inss_empresa_percentual: {
            type: Number,
            default: () => 4.0
        },
        ir_csll_valor: {
            type: Number,
            default: () => 0
        },
        ir_csll_percentual: {
            type: Number,
            default: () => 3.08
        },
        total_imposto: {
            type: Number,
            default: () => 0
        },
        total_valor: {
            type: Number,
            default: () => 0
        },
        tipo_operacao: {
            type: Number,
            required: true
        }
    },
    created() {
        this.calculaPercentuais();
    },
    watch: {
        frete_custo_valor: {
            handler() {
                this.calculaPercentuais();
            },
            deep: true
        },
        pedagio_valor: {
            handler(data) {
                this.form.pedagio_valor = data;
            },
            deep: true
        },
        licenca_valor: {
            handler(data) {
                this.form.licenca_valor = data;

                this.calculaPercentuais();
            },
            deep: true
        },
        'form.margem_lucro_percentual': {
            handler() {
                this.calculaPercentuais();
            }
        },
        'form.seguro_percentual': {
            handler() {
                this.calculaPercentuais();
            }
        },
        'form.gris_percentual': {
            handler() {
                this.calculaPercentuais();
            }
        },
        'form.taxa_imo_anvisa_percentual': {
            handler() {
                this.calculaPercentuais();
            }
        },
        'form.profit_calculado_percentual': {
            handler() {
                this.calculaPercentuais();
            }
        },
        'form.repasse_profit_percentual': {
            handler() {
                this.calculaPercentuais();
            }
        },
        'form.despacho_valor': {
            handler() {
                this.calculaPercentuais();
            }
        },
        'form.ajudante_valor': {
            handler() {
                this.calculaPercentuais();
            }
        },
        'form.escolta_valor': {
            handler() {
                this.calculaPercentuais();
            }
        }
    },
    computed: {
        totalValoresFormatado() {
            return FormataMoeda(this.form.total_valor, {}, true);
        },
        totalValoresPercentualFormatado() {
            return FormataMoeda((this.form.total_valor - this.form.icms_valor) / this.custo_venda_total - 1, {}, false, true);
        },
        desativaCampos() {
            return this.form.frete_peso_valor <= 0 || this.form.margem_lucro_percentual <= 0 ? true : false;
        },
        icmsFormatado() {
            return this.form.icms_percentual == 0 ? `R$ 0` : FormataMoeda(this.form.icms_valor, {}, true);
        },
        icmsPercentualFormatado() {
            return this.form.icms_percentual == 0
                ? `0 %`
                : FormataMoeda((100 - 100 * this.form.icms_percentual) / 100, {}, false, true);
        },
        pisCofinsFormatado() {
            return FormataMoeda(this.form.pis_cofins_valor, {}, true);
        },
        pisCofinsPercentualFormatado() {
            return FormataMoeda(this.form.pis_cofins_percentual / 100, {}, false, true);
        },
        inssEmpresaFormatado() {
            return FormataMoeda(this.form.inss_empresa_valor, {}, true);
        },
        inssEmpresaPercentualFormatado() {
            return FormataMoeda(this.form.inss_empresa_percentual / 100, {}, false, true);
        },
        irCsllFormatado() {
            return FormataMoeda(this.form.ir_csll_valor, {}, true);
        },
        irCsllPercentualFormatado() {
            return FormataMoeda(this.form.ir_csll_percentual / 100, {}, false, true);
        }
    },
    methods: {
        totalValores() {
            this.form.total_valor = 0;

            const valores =
                parseFloat(this.form.frete_peso_valor) +
                parseFloat(this.form.pedagio_valor) +
                parseFloat(this.form.seguro_valor) +
                parseFloat(this.form.gris_valor) +
                parseFloat(this.form.licenca_valor) +
                parseFloat(this.form.taxa_imo_anvisa_valor) +
                parseFloat(this.form.despacho_valor) +
                parseFloat(this.form.profit_calculado_valor) +
                parseFloat(this.form.ajudante_valor) +
                parseFloat(this.form.escolta_valor) +
                parseFloat(this.form.icms_valor);

            if (!valores) {
                return;
            }

            this.form.total_valor = valores;
        },
        calculaPercentuais() {
            this.form.frete_peso_valor = (this.frete_custo_valor / 100) * (this.form.margem_lucro_percentual + 100);
            this.form.seguro_valor = (this.valor_mercadoria / 100) * this.form.seguro_percentual;
            this.form.gris_valor = (this.valor_mercadoria / 100) * this.form.gris_percentual;
            this.form.licenca_valor = (this.licenca_valor / 100) * this.form.margem_lucro_percentual;
            this.form.taxa_imo_anvisa_valor = (this.form.frete_peso_valor / 100) * this.form.taxa_imo_anvisa_percentual;
            this.form.profit_calculado_valor = (this.form.frete_peso_valor / 100) * this.form.profit_calculado_percentual;
            this.form.repasse_profit_valor = (this.form.frete_peso_valor / 100) * this.form.repasse_profit_percentual;

            let baseCalculoIcms = this.baseCalculoIcms();

            this.form.icms_valor = baseCalculoIcms / this.form.icms_percentual - baseCalculoIcms;

            if (this.tipo_operacao == TipoOperacaoEnum.EXPORTACAO) {
                this.form.icms_percentual = 0;
                this.form.icms_valor = 0;
            }

            this.form.pis_cofins_valor = (baseCalculoIcms / 100) * this.form.pis_cofins_percentual;
            this.form.inss_empresa_valor = (this.frete_custo_valor / 100) * this.form.inss_empresa_percentual;
            this.form.ir_csll_valor = (baseCalculoIcms / 100) * this.form.ir_csll_percentual;

            this.totalValores();
            this.totalImpostos();
        },
        calculaPercentual(valor, valor_total) {
            return parseFloat((valor / valor_total) * 100).toFixed(2);
        },
        baseCalculoIcms() {
            return (
                parseFloat(this.form.frete_peso_valor) +
                parseFloat(this.form.pedagio_valor) +
                parseFloat(this.form.seguro_valor) +
                parseFloat(this.form.gris_valor) +
                parseFloat(this.form.licenca_valor) +
                parseFloat(this.form.taxa_imo_anvisa_valor) +
                parseFloat(this.form.despacho_valor) +
                parseFloat(this.form.profit_calculado_valor) +
                parseFloat(this.form.ajudante_valor) +
                parseFloat(this.form.escolta_valor)
            );
        },
        totalImpostos() {
            this.form.total_imposto = parseFloat(
                this.form.icms_valor + this.form.pis_cofins_valor + this.form.inss_empresa_valor
            );
        },
        totalImpostoSobreSalario() {
            return parseFloat(this.form.icms_valor + this.form.ir_csll_valor);
        }
    },
    data() {
        return {
            form: {
                id_profit_less_markup_receita_venda: this.id_profit_less_markup_receita_venda,
                frete_peso_valor: this.frete_peso_valor,
                pedagio_valor: this.pedagio_valor,
                seguro_valor: this.seguro_valor,
                seguro_percentual: this.seguro_percentual,
                gris_valor: this.gris_valor,
                gris_percentual: this.gris_percentual,
                licenca_valor: this.licenca_valor,
                taxa_imo_anvisa_valor: this.taxa_imo_anvisa_valor,
                taxa_imo_anvisa_percentual: this.taxa_imo_anvisa_percentual,
                despacho_valor: this.despacho_valor,
                profit_calculado_valor: this.profit_calculado_valor,
                profit_calculado_percentual: this.profit_calculado_percentual,
                ajudante_valor: this.ajudante_valor,
                escolta_valor: this.escolta_valor,
                icms_valor: this.icms_valor,
                icms_percentual: this.icms_percentual,
                pis_cofins_valor: this.pis_cofins_valor,
                pis_cofins_percentual: this.pis_cofins_percentual,
                inss_empresa_valor: this.inss_empresa_valor,
                inss_empresa_percentual: this.inss_empresa_percentual,
                ir_csll_valor: this.ir_csll_valor,
                ir_csll_percentual: this.ir_csll_percentual, // IMPOSTO SOBRE SALARIO
                repasse_profit_valor: this.repasse_profit_valor,
                repasse_profit_percentual: this.repasse_profit_percentual,
                total_valor: this.total_valor,
                total_imposto: this.total_imposto,
                margem_lucro_percentual: this.margem_lucro_percentual
            },
            regraPadrao: [Obrigatorio(), Minimo(1)]
        };
    }
};
</script>
