<template>
    <v-card>
        <v-card-text>
            <v-timeline align-top dense clipped>
                <v-timeline-item fill-dot class="mb-5" color="orange" large>
                    <template v-slot:icon>
                        <v-avatar>
                            <img :src="require(`@/assets/image/logo/terranova_logo_fundo_branco.png`)" />
                        </v-avatar>
                    </template>

                    <v-row justify="space-between">
                        <v-col cols="12">
                            <v-chip class="white--text ml-0" color="blue-grey lighten-1" label>
                                Avaliação
                            </v-chip>
                        </v-col>

                        <v-col cols="12" class="font-weight-black text-h6">
                            {{ nome_avaliacao }}
                        </v-col>
                    </v-row>
                </v-timeline-item>

                <v-timeline-item class="mb-4" color="blue-grey lighten-1" icon-color="grey lighten-2" small>
                    <v-row justify="space-between">
                        <v-col cols="12">
                            <v-chip class="white--text ml-0" color="blue-grey lighten-1" label>
                                Participantes
                            </v-chip>
                        </v-col>

                        <v-col cols="12">
                            <v-card-actions class="text-caption mb-2">
                                <v-avatar>
                                    <v-img src="@/assets/image/perfil/img_avatar.png"></v-img>
                                </v-avatar>

                                <v-chip class="ma-2" color="indigo darken-1" dark label>
                                    <v-icon left>
                                        mdi-account-multiple
                                    </v-icon>
                                    <span>{{ nome_colaborador_gestor }}</span>
                                </v-chip>

                                <v-chip class="ma-2" color="blue-grey lighten-1" dark small>
                                    Gestor
                                </v-chip>
                            </v-card-actions>

                            <v-card-actions class="text-caption mb-2">
                                <v-avatar>
                                    <v-img src="@/assets/image/perfil/img_avatar.png"></v-img>
                                </v-avatar>

                                <v-chip class="ma-2" color="pink darken-1" dark label>
                                    <v-icon left>
                                        mdi-account
                                    </v-icon>
                                    <span>{{ nome_colaborador }}</span>
                                </v-chip>

                                <v-chip class="ma-2" color="blue-grey lighten-1" dark small>
                                    Colaborador
                                </v-chip>
                            </v-card-actions>
                        </v-col>
                    </v-row>
                </v-timeline-item>

                <v-timeline-item class="mb-4" color="blue-grey lighten-1" small>
                    <v-row justify="space-between">
                        <v-col cols="7">
                            <v-chip class="white--text ml-0" color="blue-grey lighten-1" label>
                                Critérios
                            </v-chip>
                        </v-col>

                        <v-col cols="6">
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left font-weight-black text-h6">
                                                Avaliação
                                            </th>
                                            <th class="text-left font-weight-black text-h6">
                                                Peso
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, key) in criterios" :key="key" class="text-h6 font-weight-normal">
                                            <td>{{ item.nome }}</td>
                                            <td>{{ item.peso }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-timeline-item>

                <v-timeline-item class="mb-4" color="blue-grey lighten-1" small>
                    <v-row justify="space-between">
                        <v-col cols="12">
                            <v-chip class="white--text ml-0" color="blue-grey lighten-1" label>
                                Orientações
                            </v-chip>
                        </v-col>
                        <v-col cols="12">
                            <span v-html="orientacao"></span>
                        </v-col>
                    </v-row>
                </v-timeline-item>
            </v-timeline>
        </v-card-text>

        <v-card-actions>
            <v-col class="text-right">
                <v-btn color="green" @click="iniciaAvaliacao()">
                    Iniciar avaliação ?
                </v-btn>
            </v-col>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'InstrucaoPreenchimento',
    components: {},
    props: {
        nome_avaliacao: {
            type: String,
            default: () => ''
        },
        nome_colaborador: {
            type: String,
            default: () => ''
        },
        nome_colaborador_gestor: {
            type: String,
            default: () => ''
        },
        orientacao: {
            type: String,
            default: () => ''
        },
        orientacao_criterio: {
            type: String,
            default: () => ''
        },
        criterios: {
            type: Array,
            default: () => []
        }
    },
    async created() {},
    async mounted() {},
    computed: {},
    watch: {},
    methods: {
        iniciaAvaliacao() {
            this.$emit('iniciaAvaliacao', {});
        }
    },
    data() {
        return {};
    }
};
</script>

<style></style>
