<template>
    <v-container fluid>
        <v-overlay :absolute="true" opacity="0.8" :value="carregando">
            <CarregandoLogoEmpresa :carregando="carregando" :mensagem="mensagemCarregando" />
        </v-overlay>

        <v-card>
            <v-card-title class="text-h5"> Auditoria CTe - Observação </v-card-title>

            <v-card-text>
                <TextAreaV3 v-model="form.observacao" :contador="4000" :linhas="10" rotulo="Observação" />
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="red darken-1" text @click="$emit('cancelar')">
                    Cancelar
                </v-btn>
                <v-btn color="green darken-1" text @click="salvaObservacao()">
                    Salvar
                </v-btn>
            </v-card-actions>
        </v-card>

        <div class="text-center">
            <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-container>
</template>

<script>
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import TextAreaV3 from '@/components/Form/TextAreaV3.vue';
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';

export default {
    name: 'FormObservacao',
    components: {
        CarregandoLogoEmpresa,
        TextAreaV3
    },
    props: {
        id_programacao_carga_rodopar: {
            type: Number,
            required: true
        },
        id_automata: {
            type: Number,
            required: true
        },
        observacao: {
            type: String,
            default: () => ''
        }
    },
    methods: {
        async salvaObservacao() {
            try {
                this.carregando = true;

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', 'Salvar observação ?', {
                        cor: 'success'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        this.carregando = true;

                        let url = `${API_URL.erp.cte.salvaObservacaoAuditoria}/${this.id_programacao_carga_rodopar}`;

                        const dados = {
                            id_programacao_carga_rodopar: this.id_programacao_carga_rodopar,
                            id_automata: this.id_automata,
                            observacao: this.form.observacao
                        };

                        await ApiService({
                            method: 'post',
                            url: url,
                            data: dados,
                            mostraCarregando: false
                        })
                            .then(resposta => {})
                            .catch(erro => {
                                throw new Error(erro.message);
                            });

                        this.carregando = false;

                        this.$emit('salva', dados);
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        }
    },
    data() {
        return {
            carregando: false,
            mensagemCarregando: 'Atualizando observação na API Automata, aguarde...',
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            form: {
                observacao: this.observacao
            }
        };
    }
};
</script>
