import { API_URL_BASE, API_URL } from '@/config/configs';

const ListaRamal = {
    datatable: {
        id_datatable: 'listaramal',
        chave_primaria: 'id_lista_ramal',
        url: `${API_URL_BASE}${API_URL.erp.listaramal.datatable}`,
        colunas: false,
    },
    formRefsComponente: 'ListaRamalForm',
    formNome: 'formListaRamal',
    moduloNome: 'listaramal',
    chavePrimaria: 'id_lista_ramal',
    componenteArquivo: 'ListaRamal/Form.vue',
    tabRefs: 'tabListaRamal',
    urlNovoRegistro: '',
    urlConsultaRegistro: 'listaPorIdForm',
    tabs: []
}

export { ListaRamal };

