import { API_URL_BASE, API_URL } from '../../../config/configs';
import ApiService from '@/services/ApiService.js';
import Cookies from 'vue-cookies';

const state = {
    dadosUsuario: Cookies.get('usuarioLogin') ? JSON.parse(atob(Cookies.get('usuarioLogin'))) : {},
    autenticado: false
};

const mutations = {
    AUTENTICACAO_USUARIO_OK(state, usuario) {
        state.dadosUsuario = usuario;
        state.autenticado = true;
    },
    AUTENTICACAO_USUARIO_SAI(state) {
        state.dadosUsuario.token = '';
        state.autenticado = false;
    },
    RENOVA_TOKEN(state, tokenAcesso) {
        state.dadosUsuario = Object.assign(state.dadosUsuario, tokenAcesso);
    }
};

const actions = {
    async entra(context, dadosFormulario, mostraCarregando) {
        mostraCarregando = typeof mostraCarregando !== 'undefined' ? mostraCarregando : false;

        return new Promise(async (resolve, reject) => {
            let urlConfig = {
                method: 'post',
                url: `${API_URL_BASE}${API_URL.erp.autenticacao.entra}`,
                data: dadosFormulario,
                mostraCarregando: mostraCarregando
            };

            await ApiService(urlConfig)
                .then(resposta => {
                    let dados_usuario = {
                        login: dadosFormulario.login,
                        nome_completo: resposta.data.conteudo.dados.nome_completo,
                        token: resposta.data.conteudo.dados.token,
                        cargo: resposta.data.conteudo.dados.cargo,
                        avatar: resposta.data.conteudo.dados.avatar
                    };

                    context.commit('AUTENTICACAO_USUARIO_OK', dados_usuario);

                    Cookies.set('usuarioLogin', btoa(JSON.stringify(dados_usuario)));

                    resolve();
                })
                .catch(erro => {
                    if (erro.hasOwnProperty('response')) {
                        reject(erro.response.data.mensagem);
                    }

                    reject(erro.message);
                });
        });
    },
    async sai(context) {
        try {
            let urlConfig = {
                method: 'post',
                url: `${API_URL.erp.autenticacao.sai}`,
                data: {}
            };

            await ApiService(urlConfig)
                .then(resposta => {
                    context.commit('AUTENTICACAO_USUARIO_SAI');

                    return true;
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        } catch (e) {
            throw new Error(e.message);
        }
    },
    async verificaAutenticacao({ getters, dispatch }) {
        const token = getters.getToken;

        return await new Promise(async (resolve, reject) => {
            if (!token || token == '') {
                reject('Sem autenticação');
                return;
            }

            let urlConfig = {
                method: 'post',
                url: `${API_URL.erp.autenticacao.validaToken}`,
                data: {}
            };

            await ApiService(urlConfig)
                .then(respostaApi => {
                    resolve(respostaApi.data.mensagem);
                })
                .catch(erro => {
                    dispatch('sai');
                    reject(erro.message);
                });
        });
    },
    async renovaToken({ commit, state }) {
        return new Promise(async (resolve, reject) => {
            let urlConfig = {
                method: 'post',
                url: `${API_URL_BASE}${API_URL.erp.autenticacao.renovaToken}`,
                data: {}
            };

            await ApiService(urlConfig)
                .then(respostaApi => {
                    let tokenObj = { token: respostaApi.data.conteudo.token };

                    commit('RENOVA_TOKEN', tokenObj);

                    Cookies.set('usuarioLogin', btoa(JSON.stringify(state.dadosUsuario)));

                    resolve('Token atualizado');
                })
                .catch(erro => {
                    if (erro.hasOwnProperty('response')) {
                        reject(erro.response.data.mensagem);
                    }

                    reject(erro.message);
                });
        });
    }
};

const getters = {
    estaAutenticado(state) {
        //return state.dadosUsuario !== null && state.dadosUsuario !== undefined && state.dadosUsuario.nome.length >= 0;
        return false;
    },
    getLogin: state => {
        let { login } = state.dadosUsuario;

        return login;
    },
    getToken: state => {
        let { token } = state.dadosUsuario;

        return token;
    },
    getNomeCompleto: state => {
        let { nome_completo } = state.dadosUsuario;

        return nome_completo;
    },
    getCargo: state => {
        let { cargo } = state.dadosUsuario;

        return cargo;
    },
    getAvatar: state => {
        let { avatar } = state.dadosUsuario;

        avatar = avatar ?? require('@/assets/image/perfil/img_avatar.png');

        return avatar;
    },
    getIdUsuario: state => {
        const { token } = state.dadosUsuario;

        if (!token) {
            return;
        }

        let { sub } = JSON.parse(atob(token.split('.')[1]));

        return sub;
    }
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
};
