/**
 * 
 * @param {*} form 
 * @param {*} campo 
 * @returns 
 */
const FormCampoRetornaDado = function (form, caminhoReferencia, campoSelecionado) {

    let referencia = caminhoReferencia;

    if (campoSelecionado == 'rotulo') {
        return _.get(
            form.configuracao.placeholder,
            `${referencia}`,
            ''
        );
    }

    if (campoSelecionado == 'regra') {
        let regras = _.get(
            form.configuracao.regra,
            `${referencia}`,
            []
        );

        return regras;
    }

    if (campoSelecionado == 'tamanhoMaximo') {
        let max = _.get(
            form.configuracao.tamanho,
            `${referencia}.max`,
            ''
        );

        return max;
    }

    if (campoSelecionado == 'obrigatorio') {
        return _.get(
            form.configuracao.obrigatorio,
            `${referencia}`,
            false
        );
    }

    if (campoSelecionado == 'desativado') {
        return Boolean(
            _.get(
                form.configuracao.desativado,
                `${referencia}`,
                false
            )
        );
    }

    if (campoSelecionado == 'campoFormatacao') {
        return _.get(
            form.configuracao.campoConfiguracao,
            `${referencia}.campoFormatacao`,
            ''
        );
    }

    if (campoSelecionado == 'complementoItens') {
        let caminho = _.get(
            form.configuracao.campoConfiguracao,
            `${referencia}.complemento`,
            []
        );

        if (caminho.length > 0) {
            return form.complemento[caminho];
        }

        return [];
    }

    if (campoSelecionado == 'complementoItensComplemento') {
        return _.get(
            form.configuracao.campoConfiguracao,
            `${referencia}.complemento`,
            ''
        );
    }

    if (campoSelecionado == 'complementoItemValor') {
        return _.get(
            form.configuracao.campoConfiguracao,
            `${referencia}.campo.valor`,
            ''
        );
    }

    if (campoSelecionado == 'complementoItemTexto') {
        return _.get(
            form.configuracao.campoConfiguracao,
            `${referencia}.campo.texto`,
            ''
        );
    }

    if (campoSelecionado == 'prefixo') {
        return _.get(
            form.configuracao.campoConfiguracao,
            `${referencia}.prefixo`,
            ''
        );
    }

    if (campoSelecionado == 'casaDecimal') {
        return _.get(
            form.configuracao.campoConfiguracao,
            `${referencia}.casa_decimal`,
            ''
        );
    }

    if (campoSelecionado == 'linhas') {
        return _.get(
            form.configuracao.campoConfiguracao,
            `${referencia}.linhas`,
            ''
        );
    }

    if (campoSelecionado == 'datatableConfiguracao') {
        return _.get(
            form.configuracao.campoConfiguracao,
            `${referencia}.datatable`,
            ''
        );
    }

    if (campoSelecionado == 'formatoRetornado') {
        return _.get(
            form.configuracao.campoConfiguracao,
            `${referencia}.datatable.formatoRetornado`,
            ''
        );
    }

    if (campoSelecionado == 'datatableUrlAutoComplete') {
        return _.get(
            form.configuracao.campoConfiguracao,
            `${referencia}.datatable.urlAutoComplete`,
            ''
        );
    }

    if (campoSelecionado == 'selectCarregando') {
        return _.get(
            form.configuracao.campoConfiguracao,
            `${referencia}.carregando`,
            false
        );
    }

    if (campoSelecionado == 'selectCampoRecarrega') {
        return _.get(
            form.configuracao.campoConfiguracao,
            `${referencia}.recarregaCampo.campo`,
            false
        );
    }

    if (campoSelecionado == 'selectCampoBuscaUrl') {
        return _.get(
            form.configuracao.campoConfiguracao,
            `${referencia}.recarregaCampo.url`,
            false
        );
    }

    return '';
}

export {
    FormCampoRetornaDado
}
