const GREEN = 1;
const RED = 2;
const YELLOW = 3;

const AuditoriaSituacaoEnum = {
    GREEN: GREEN,
    RED: RED,
    YELLOW: YELLOW
};

export function descricao(situacao) {
    switch (situacao) {
        case GREEN:
            return 'Verde';
        case RED:
            return 'Vermelho';
        case YELLOW:
            return 'Amarelo';
        default:
            return '';
    }
}

export function listaDescricao() {
    return [
        {
            value: GREEN,
            label: 'Verde'
        },
        {
            value: RED,
            label: 'Vermelho'
        },
        {
            value: YELLOW,
            label: 'Amarelo'
        }
    ];
}

export default AuditoriaSituacaoEnum;
