<template>
    <v-text-field
        :value="value"
        @input="$emit('input', $event)"
        :rules="regra"
        :counter="contador"
        :label="rotulo"
        :disabled="desativado"
        :required="obrigatorio"
        v-maska="campoFormatacao"
        :masked="!!campoFormatacao"
        :type="tipo"
        :maxlength="contador"
        :append-icon="iconeAppend"
        :outlined="estilo('outlined')"
        :menu-props="{ offsetY: true }"
        data-vv-delay="1000"
        v-on:keyup.enter="onEnter"
        clearable
        dense
    >
        <template v-slot:prepend>
            <div v-for="(chipText, index) in chipData" :key="index">
                <v-chip class="ma-1">{{ chipText }}</v-chip>
            </div>
        </template>
    </v-text-field>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
    directives: { mask },
    props: {
        value: {
            default: () => '',
        },
        regra: {
            type: Array,
            default: () => [],
        },
        contador: {
            type: Number,
            default: () => 0,
        },
        rotulo: {
            type: String,
            default: () => '',
        },
        desativado: {
            type: Boolean,
            default: () => false,
        },
        obrigatorio: {
            type: Boolean,
            default: () => false,
        },
        campoFormatacao: {
            type: String,
            default: () => '',
        },
        tipo: {
            type: String,
            default: () => 'text',
        },
        iconeAppend: {
            type: String,
            default: () => '',
        },
        estiloCampo: {
            type: String,
            default: () => 'outlined',
        },
        multiple: {
            type: Boolean,
            default: () => false,
        },
    },
    async created() {},
    computed: {},
    watch: {},
    mounted() {},
    methods: {
        estilo(estiloDefinido) {
            if (this.estiloCampo == estiloDefinido) {
                return true;
            }

            return false;
        },
        onEnter: function (valorDigitado) {
            this.chipData.push(this.value);
        },
    },
    data() {
        return {
            chipData: [],
            campoMascara: '',
            nomask: {
                mask: '*'.repeat(1000),
                tokens: {
                    '*': { pattern: /./ },
                },
            },
        };
    },
};
</script>

<style scoped>
</style>
