<template>
    <v-row justify="center">
        <v-dialog
            v-model="abreModal"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="fechaModal()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Buscar por {{ titulo }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-list three-line dense subheader>
                    <v-container fluid>
                        <v-alert
                            v-model="mostrarAlerta"
                            :type="alertaTipo"
                            dismissible
                            outlined
                            text
                            class="multi-line"
                        >
                            {{ alertaMensagem }}
                        </v-alert>
                    </v-container>
                    <v-list-item v-if="this.datatableBusca">
                        <v-row class="pa-2">
                            <Datatables
                                ref="DatatableBusca"
                                :id_datatable="datatable.id_datatable"
                                :chave_primaria="datatable.chave_primaria"
                                :url="datatable.url"
                                :colunas="datatable.colunas"
                                @consultaRegistro="consultaRegistro"
                                @mensagemAlerta="mensagemAlerta"
                            />
                        </v-row>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import Datatables from '@/components/Default/Datatables.vue';
import { Alerta } from '@/helpers/Funcoes.js';

export default {
    components: {
        Datatables,
        Alerta,
    },
    props: [],
    computed: {
        datatable() {
            if (this.datatableBusca) {
                return this.datatableBusca;
            }
        },
    },
    data: () => ({
        abreModal: false,
        titulo: '',
        objetoClicado: null,
        objetoRetorno: null,
        formatoRetornado: null,
        datatableBusca: null,
        mostrarAlerta: false,
        alertaMensagem: '',
        alertaTipo: 'success',
    }),
    methods: {
        fechaModal() {
            this.mostrarAlerta = false;
            this.abreModal = false;
            this.datatableBusca = null;
        },
        consultaRegistro(id_registro, dadosCompletos) {
            let objRetorno = this.objetoRetorno;
            let formatoRetornado = this.formatoRetornado;
            let dadosCompletosFiltrado = [];

            Object.keys(dadosCompletos).forEach(function (key) {
                if (key in objRetorno[0]) {
                    dadosCompletosFiltrado.push(dadosCompletos[key]);
                }
            });

            String.prototype.format = function () {
                return arguments[0].reduce((p, c) => p.replace(/%s/, c), this);
            };

            Object.keys(dadosCompletos).forEach(function (key) {
                if (key in objRetorno[0]) {
                    if (formatoRetornado[key]) {
                        objRetorno[0][key] = formatoRetornado[key].format(
                            dadosCompletosFiltrado
                        );

                        return;
                    }

                    objRetorno[0][key] = dadosCompletos[key];
                }
            });

            this.inputAutoCompletaAtualizaValor(objRetorno, id_registro);
        },
        mensagemAlerta(mensagem, tipo) {
            this.mostrarAlerta = true;
            this.alertaMensagem = mensagem;
            this.alertaTipo = tipo;
        },
        inputAutoCompletaAtualizaValor(objetoRetorno, id_registro) {
            this.fechaModal();

            this.$emit(
                'inputAutoCompletaAtualizaValor',
                objetoRetorno,
                id_registro
            );
        },
    },
};
</script >

<style scoped>
</style>
