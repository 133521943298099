<template>
    <span>
        <v-textarea
            :value="valor"
            @input="$emit('input', $event)"
            :label="rotulo"
            :rules="regra"
            :rows="linhas"
            :disabled="desativado"
            :counter="contador"
            :maxlength="contador"
            v-bind="$attrs"
            prepend-inner-icon="mdi-comment"
            clearable
            solo
            outlined
        ></v-textarea>
    </span>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        value: {
            default: () => ''
        },
        regra: {
            type: Array,
            default: () => []
        },
        contador: {
            type: Number,
            default: () => 0
        },
        rotulo: {
            type: String,
            default: () => ''
        },
        linhas: {
            type: Number,
            default: () => 5
        },
        desativado: {
            type: Boolean,
            default: () => false
        },
        obrigatorio: {
            type: Boolean,
            default: () => false
        }
    },
    data: () => ({}),
    methods: {},
    computed: {
        valor: function() {
            return this.value != (null || false) ? this.value : '';
        }
    }
};
</script>

<style scoped></style>
