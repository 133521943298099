<template>
    <v-card-text>
        <v-row>
            <v-col cols="12">
                <InputAutoCompleteV3
                    v-model="whatsapp_departamento"
                    :datatableConfiguracao="autoCompleta.departamento.datatable"
                    :itemsOpcoes="itensDepartamentos"
                    tituloBusca="Departamento Busca..."
                    itemValor="id_whatsapp_departamento"
                    itemTexto="nome"
                    :regra="regrasDepartamento"
                    :returnObject="true"
                    @valor-object="atualizaDepartamento"
                />
            </v-col>
        </v-row>
    </v-card-text>
</template>

<script>
import { API_URL, API_URL_BASE } from '@/config/configs';

import { Obrigatorio } from '@/helpers/ValidadorCampo.js';

import InputAutoCompleteV3 from '@/components/Form/InputAutoCompleteV3.vue';

export default {
    name: 'FormAtendenteDepartamento',
    components: {
        InputAutoCompleteV3
    },
    methods: {
        atualizaDepartamento() {
            if (!this.whatsapp_departamento) {
                return;
            }

            this.id_whatsapp_departamento = this.whatsapp_departamento.id_whatsapp_departamento;
            this.nome_departamento = this.whatsapp_departamento.nome;
        }
    },
    data() {
        return {
            whatsapp_departamento: null,
            id_whatsapp_departamento: null,
            nome_departamento: null,
            regrasDepartamento: [Obrigatorio()],
            itensDepartamentos: [],
            autoCompleta: {
                departamento: {
                    datatable: {
                        id_datatable: 'departamento_id_whatsapp_departamento',
                        chave_primaria: 'departamento_id_whatsapp_departamento',
                        url: `${API_URL_BASE}${API_URL.erp.whatsappatendente.datatable}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.whatsappatendente.buscaDepartamento}`,
                        colunas: [
                            {
                                id: 'id_whatsapp_departamento',
                                nome: 'id_whatsapp_departamento',
                                descricao: 'ID Departamento'
                            },
                            {
                                id: 'nome',
                                nome: 'nome',
                                descricao: 'Nome'
                            }
                        ]
                    }
                }
            }
        };
    }
};
</script>
