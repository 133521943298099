<template>
    <div>
        <TituloModulo />

        <AlertaMensagem dense style="width: 100%" />

        <CarregandoLogoEmpresa :mensagem="mensagemCarregando" />

        <BarraNavegacaoBotaoV3 v-if="!carregando.mostraCarregando && permissao" :botoes="botoesComPermissao(situacaoForm)" />

        <v-row v-if="!carregando.mostraCarregando && permissao">
            <v-col cols="12" class="text-right">
                <TabelaVuetify
                    v-model="datatable.itensSelecionados"
                    :cabecalho="datatable.cabecalho"
                    :itens="datatable.itens"
                    :total_registros="datatable.totalRegistros"
                    :por_pagina="datatable.por_pagina"
                    :ultima_pagina="datatable.ultima_pagina"
                    :carregando="datatable.carregando"
                    :chave_primaria="datatable.chave_primaria"
                    :ordenar_por="datatable.ordenarPor"
                    :ordenar_direcao="datatable.ordenarDirecao"
                    @opcoes="buscaDadosGrid"
                    ref="tabela"
                >
                    <template v-slot:item.acao="{ item }">
                        <v-icon small class="mr-2" @click="editaItem(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                </TabelaVuetify>
            </v-col>
        </v-row>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import GridMixins from '@/Modulo/WhatsappDepartamento/Dominio/Servico/GridMixins.js';

import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import TabelaVuetify from '@/components/Default/TabelaVuetify.vue';

export default {
    name: 'Grid',
    mixins: [GridMixins],
    components: {
        AlertaMensagem,
        BarraNavegacaoBotaoV3,
        CarregandoLogoEmpresa,
        TituloModulo,
        TabelaVuetify
    }
};
</script>
