<template>
    <div>
        <TituloModulo />

        <AlertaMensagem dense style="width: 100%" />

        <BarraNavegacaoBotaoV3 v-if="!carregando.mostraCarregando && permissao" :botoes="botoesComPermissao(1)" />

        <CarregandoLogoEmpresa :mensagem="mensagemCarregando" />

        <v-card v-show="!carregando.mostraCarregando && permissao">
            <v-tabs v-model="tabComponente" background-color="orange accent-2" dense centered dark icons-and-text>
                <v-tabs-slider></v-tabs-slider>

                <v-tab>
                    <v-badge
                        :content="tabDadosTotalErros > 0 ? tabDadosTotalErros : ''"
                        :color="tabDadosTotalErros > 0 ? 'red accent-4' : ''"
                        overlap
                    >
                        <v-icon>mdi-file-sign</v-icon>
                        <v-spacer></v-spacer>
                        Dados
                    </v-badge>
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabComponente">
                <v-form ref="formWhatsappDepartamento">
                    <v-tab-item eager>
                        <v-container fluid>
                            <form-dados
                                :id_whatsapp_fluxo="form.id_whatsapp_fluxo"
                                :id_whatsapp_numero_atendimento="form.id_whatsapp_numero_atendimento"
                                :descricao="form.descricao"
                                :fluxo_item="form.fluxo_item"
                                :fluxo_json="form.fluxo_json"
                                :opcao_whatsapp_numero_atendimento="opcaoWhatsappNumeroAtendimento"
                                ref="formDados"
                            />
                        </v-container>
                    </v-tab-item>
                </v-form>
            </v-tabs-items>
        </v-card>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import { API_URL } from '@/config/configs';
import { Alerta, AlertaEsconde } from '@/helpers/Funcoes.js';
import ApiService from '@/services/ApiService.js';

import BuscaModal from '@/components/Default/BuscaModal.vue';
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';

import FormDados from './FormDados.vue';

export default {
    components: {
        AlertaMensagem,
        BuscaModal,
        TituloModulo,
        BarraNavegacaoBotaoV3,
        CarregandoLogoEmpresa,
        FormDados
    },
    async mounted() {
        this.id_whatsapp_fluxo = parseInt(this.$route.params.id_whatsapp_fluxo) ?? null;

        this.carregaWhatsappNumeroAtendimento();

        await this.dadosPaginaInicial();

        this.mostraCarregando(false);
    },
    computed: {
        ...mapGetters(['botoesComPermissao']),
        ...mapState(['carregando'])
    },
    methods: {
        ...mapActions(['montaNavegacao', 'mostraCarregando']),
        async dadosPaginaInicial() {
            try {
                await this.buscaPermissoes();

                if (this.id_whatsapp_fluxo) {
                    await this.buscaForm();
                }

                this.permissao = true;
            } catch (erro) {
                Alerta([erro.message], 'error');
            }
        },
        async carregaWhatsappNumeroAtendimento() {
            try {
                await ApiService({
                    method: 'get',
                    url: `${API_URL.erp.whatsappfluxo.buscaWhatsappNumeroAtendimento}`,
                    data: {},
                    mostraCarregando: false
                })
                    .then(async resposta => {
                        this.opcaoWhatsappNumeroAtendimento = resposta.data.data.atributos.map(item => {
                            return {
                                id_whatsapp_numero_atendimento: item.id_whatsapp_numero_atendimento,
                                descricao: item.descricao
                            };
                        });
                    })
                    .catch(error => {
                        throw new Error(error);
                    });
            } catch (erro) {
                Alerta([erro.message], 'error');
            } finally {
                this.mostraCarregando(false);
            }
        },
        async buscaPermissoes() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.whatsappfluxo.permissao}`,
                data: {},
                mostraCarregando: false
            })
                .then(async resposta => {
                    const permissoes = resposta.data.data.atributos;

                    await this.montaNavegacao({ botoes: this.botoes, permissoes });
                })
                .catch(error => {
                    throw new Error(error);
                });
        },
        async buscaForm() {
            try {
                let urlConfig = {
                    method: 'GET',
                    url: `${API_URL.erp.whatsappfluxo.busca}/${this.id_whatsapp_fluxo}`,
                    data: {},
                    mostraCarregando: false
                };

                await ApiService(urlConfig)
                    .then(async resposta => {
                        resposta.data.data.atributos.id_whatsapp_fluxo = resposta.data.data.id_registro;

                        this.preencheDados(resposta.data.data.atributos);
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                throw new Error(erro.message);
            }
        },
        preencheDados(dadosForm) {
            this.form = {
                id_whatsapp_fluxo: dadosForm.id_whatsapp_fluxo,
                id_whatsapp_numero_atendimento: dadosForm.id_whatsapp_numero_atendimento,
                descricao: dadosForm.descricao,
                fluxo_item: dadosForm.fluxo_item,
                fluxo_json: dadosForm.fluxo_json
            };
        },
        FormataDadosParaEnviarForm() {
            const dados = this.$refs.formDados.exportaDados();

            const fluxo = Object.values(dados.drawflow.Home.data).map(item => {
                let itens = item.data.itens;
                return {
                    id_whatsapp_fluxo_item: item.data.id_whatsapp_fluxo_item,
                    id_node: item.id,
                    nome_node: item.name,
                    descricao: item.data.descricao,
                    questao: itens
                };
            });

            return {
                id_whatsapp_fluxo: this.$refs.formDados.form.id_whatsapp_fluxo,
                id_whatsapp_numero_atendimento: this.$refs.formDados.form.id_whatsapp_numero_atendimento,
                descricao: this.$refs.formDados.form.descricao,
                fluxo_json: dados,
                fluxo_item: fluxo
            };
        },
        fechaForm() {
            this.$router.push({ name: 'whatsapp-fluxo' });
        },
        async salvaForm() {
            try {
                AlertaEsconde();

                this.validationMessage = [];
                this.tabDadosTotalErros = 0;

                if (!this.$refs.formDados.$refs.form.validate()) {
                    this.tabDadosTotalErros += 1;

                    this.validationMessage.push('Dados contém campos inválidos');
                }

                if (this.validationMessage.length > 0) {
                    Alerta(this.validationMessage, 'error');

                    return;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', 'Salvar registro ?', {
                        cor: 'green'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        this.mostraCarregando(true);

                        this.mensagemCarregando = 'Salvando dados do fluxo, aguarde...';

                        const id_whatsapp_fluxo = this.$refs.formDados.form.id_whatsapp_fluxo;

                        let conteudoFormulario = this.FormataDadosParaEnviarForm();

                        console.log('conteudoFormulario', conteudoFormulario);

                        return;

                        let urlForm = API_URL.erp.whatsappfluxo.salva;
                        let metodo = 'POST';

                        if (id_whatsapp_fluxo) {
                            urlForm = `${API_URL.erp.whatsappfluxo.salva}/${id_whatsapp_fluxo}`;
                            metodo = 'PUT';
                        }

                        let urlConfig = {
                            method: metodo,
                            url: urlForm,
                            data: conteudoFormulario,
                            mostraCarregando: false
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                this.notificacao = {
                                    mensagem: `Fluxo salvo.`,
                                    tipo: 'success',
                                    mostra: true
                                };

                                resposta.data.data.atributos.id_whatsapp_fluxo = resposta.data.data.id_registro;

                                this.preencheDados(resposta.data.data.atributos);
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (erro) {
                Alerta([erro.message], 'error');
            } finally {
                this.mostraCarregando(false);
            }
        }
    },
    data() {
        return {
            tabDadosTotalErros: 0,
            mensagemCarregando: 'Aguarde, buscando dados...',
            permissao: false,
            permissoes: [],
            botoes: [
                {
                    id_permissao: [2, 3],
                    nome: 'Salvar',
                    acao: 'salvaForm',
                    icone: 'mdi-newspaper-variant-multiple',
                    cor: 'green',
                    form: ['todos']
                },
                {
                    id_permissao: [1],
                    nome: 'Fechar',
                    acao: 'fechaForm',
                    icone: 'mdi-file-cancel',
                    cor: 'orange',
                    form: ['todos']
                }
            ],
            id_whatsapp_fluxo: null,
            tabComponente: 'Dados',
            chavePrimaria: 'id_whatsapp_fluxo',
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            form: {
                id_whatsapp_fluxo: null,
                id_whatsapp_numero_atendimento: null,
                nome: null,
                fluxo_json: '',
                fluxo_item: []
            },
            opcaoWhatsappNumeroAtendimento: []
        };
    }
};
</script>
