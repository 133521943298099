import Vue from 'vue';
import Vuex from 'vuex';

import Alerta from './modules/Padrao/Alerta';
import Autenticacao from './modules/Autenticacao/Autenticacao';
import BarraNavegacao from './modules/BarraNavegacao/BarraNavegacao';
import Broadcast from './modules/Broadcast/Broadcast';
import Carregando from './modules/Carregando/Carregando';
import Pais from './modules/Pais/Pais.js';
import PainelDivisao from './modules/PainelDivisao/PainelDivisao';
import Snackbar from './modules/Padrao/Snackbar';
import OrderToDelivery from './modules/Dashboard/OrderToDelivery';
import Dashboard from './modules/Dashboard/Dashboard';
import NotificacaoBrowser from './modules/NotificacaoBrowser/NotificacaoBrowser';
import Demanda from './modules/Dashboard/Demanda';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        alerta: Alerta,
        autenticacao: Autenticacao,
        barraNavegacao: BarraNavegacao,
        broadcast: Broadcast,
        carregando: Carregando,
        pais: Pais,
        painelDivisao: PainelDivisao,
        snackbar: Snackbar,
        orderToDelivery: OrderToDelivery,
        dashboard: Dashboard,
        notificacaoBrowser: NotificacaoBrowser,
        demanda: Demanda
    }
});
