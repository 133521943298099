<template>
    <v-dialog v-model="abre" class="mx-auto" max-width="800" persistent>
        <v-form ref="form">
            <v-card outlined>
                <v-card-title>
                    Adiciona Conversa
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="6" md="6">
                            <InputTextV3
                                v-model="form.celular"
                                :contador="20"
                                :regra="regrasCelular"
                                campoFormatacao="+55 (##) # ####-####"
                                rotulo="Celular"
                                ajudaMensagem="Exemplo: +(55) 11 9 4575-7856"
                            />
                        </v-col>

                        <v-col cols="6" md="6">
                            <InputTextV3
                                v-model="form.whatsapp_nome_perfil"
                                :regra="regrasNomePerfil"
                                rotulo="Nome Perfil"
                                :contador="150"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="red darken-1" text @click="cancelar()">Cancelar</v-btn>

                    <v-btn :loading="carregando" color="green darken-1" text @click="adicionar()">Adicionar</v-btn>
                </v-card-actions>
            </v-card>

            <div class="text-center">
                <v-snackbar
                    v-model="notificacao.mostra"
                    :multi-line="true"
                    :color="`${notificacao.tipo} accent-4`"
                    elevation="10"
                    timeout="3000"
                    bottom
                    text
                >
                    <span v-html="notificacao.mensagem"></span>

                    <template v-slot:action="{ attrs }">
                        <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                            Fechar
                        </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </v-form>
    </v-dialog>
</template>

<script>
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import { Obrigatorio, Maximo } from '@/helpers/ValidadorCampo.js';

import InputTextV3 from '@/components/Form/InputTextV3.vue';

export default {
    name: 'AdicionaConversa',
    components: {
        InputTextV3
    },
    props: {
        abreModal: {
            type: Boolean,
            required: false
        },
        id_whatsapp_numero_atendimento: {
            type: Number,
            required: true
        }
    },
    watch: {
        abreModal: {
            deep: true,
            handler(novoValor) {
                this.abre = novoValor;
            }
        }
    },
    methods: {
        cancelar() {
            this.$emit('cancelar');
        },
        async adicionar() {
            try {
                if (!this.$refs.form.validate()) {
                    this.notificacao = {
                        mensagem: 'Verifique todos os campos em vermelho.',
                        tipo: 'error',
                        mostra: true
                    };

                    return;
                }

                this.carregando = true;

                let telefone = this.form.celular.toString();

                telefone = parseInt(telefone.replace(/\D/g, ''));

                const parametros = {
                    telefone_envio: telefone,
                    whatsapp_nome_perfil: this.form.whatsapp_nome_perfil
                };

                const url = `${API_URL.erp.whatsapp.adicionaConversa}`.replace(
                    '{id_whatsapp_numero_atendimento}',
                    this.form.id_whatsapp_numero_atendimento
                );

                await ApiService({
                    method: 'POST',
                    url: url,
                    data: parametros,
                    mostraCarregando: false
                })
                    .then(async resposta => {
                        this.$emit('adicionar', { id_whatsapp_numero_atendimento: this.id_whatsapp_numero_atendimento });
                    })
                    .catch(error => {
                        throw new Error(error);
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.carregando = false;
            }
        }
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            abre: this.abreModal,
            carregando: false,
            form: {
                id_whatsapp_numero_atendimento: this.id_whatsapp_numero_atendimento,
                celular: null
            },
            regrasCelular: [Obrigatorio(), Maximo(20)],
            regrasNomePerfil: [Maximo(150)]
        };
    }
};
</script>
