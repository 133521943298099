<template>
    <div class="pa-2">
        <TituloModulo />

        <BarraNavegacaoBotaoV3
            v-if="!carregando.mostraCarregando"
            :botoes="botoes()"
            referenciaComponenete="AvaliacaoDesempenhoForm"
        />

        <CarregandoLogoEmpresa :mensagem="mensagemCarregando" />

        <v-card v-if="!carregando.mostraCarregando" class="pa-5">
            <v-card-title>
                {{ avaliacao_desempenho.nome }}
            </v-card-title>

            <v-card-text>
                <strong>Nome do funcionário:</strong> {{ avaliacao_desempenho.nome_colaborador }} <br />
                <strong>Nome do gestor:</strong> {{ avaliacao_desempenho.nome_colaborador_gestor }}
            </v-card-text>

            <v-divider></v-divider>

            <v-timeline dense light align-top>
                <template v-for="(afirmacao, keyAfirmacao) in avaliacao_desempenho.afirmacao">
                    <v-timeline-item fill-dot :right="keyAfirmacao % 1 == 0" :left="keyAfirmacao % 2 == 0" :key="keyAfirmacao">
                        <template v-slot:icon>
                            <v-avatar>
                                <img :src="require(`@/assets/image/logo/terranova_logo_fundo_branco.png`)" />
                            </v-avatar>
                        </template>

                        <template v-slot:opposite>
                            <v-chip class="white--text ml-0" :color="corAleatoria(keyAfirmacao)" label small>
                                AFIRMAÇÃO
                            </v-chip>
                            &nbsp;
                            <span class="text-h6">{{ afirmacao.nome }}</span>
                        </template>

                        <template v-slot:default>
                            <v-card :color="corAleatoria(keyAfirmacao)" dark>
                                <v-card-title class="text-h6">
                                    <v-chip class="white--text ml-0" color="grey darken-1" label small>
                                        PALAVRA CHAVE
                                    </v-chip>
                                    &nbsp;
                                    {{ afirmacao.palavra_chave }}
                                </v-card-title>
                                <v-card-text class="white text--primary">
                                    <p class="pa-2">
                                        <v-chip class="white--text ml-0" :color="corAleatoria(keyAfirmacao)" label small>
                                            CONSIDERAÇÃO
                                        </v-chip>
                                    </p>
                                    <p>
                                        {{ afirmacao.consideracao }}
                                    </p>
                                </v-card-text>
                            </v-card>
                        </template>
                    </v-timeline-item>

                    <template v-for="(resposta, keyResposta) in afirmacao.resposta_avaliacao">
                        <v-timeline-item color="pink" small :right="keyAfirmacao % 1 == 0" :left="keyAfirmacao % 2 == 0">
                            <v-row class="pt-1">
                                <v-col cols="4" sm="4">
                                    <strong>{{ resposta.nome }}</strong>

                                    <v-card-actions class="text-caption mb-2">
                                        <v-rating
                                            :value="encontraCriterioPeso(resposta.id_avaliacao_desempenho_criterio)"
                                            :length="maximoPesoCriterio"
                                            background-color="warning lighten-3"
                                            color="warning lighten-2"
                                            size="22"
                                            readonly
                                        ></v-rating>

                                        <span class="grey--text text--lighten-1 text-caption mr-2">
                                            ({{ encontraCriterioPeso(resposta.id_avaliacao_desempenho_criterio) }})
                                        </span>

                                        <v-chip color="grey lighten-1" class="ml-2">
                                            {{ encontraCriterioNome(resposta.id_avaliacao_desempenho_criterio) }}
                                        </v-chip>
                                    </v-card-actions>

                                    <v-card-actions class="text-caption mb-2">
                                        <v-avatar>
                                            <v-img src="@/assets/image/perfil/img_avatar.png"></v-img>
                                        </v-avatar>

                                        <v-chip class="ma-2" color="pink lighten-1" dark label>
                                            <v-icon left>
                                                mdi-account-circle-outline
                                            </v-icon>
                                            <span v-if="resposta.tipo_colaborador == tipo_colaborador_gestor">Gestor</span>
                                            <span v-else>Colaborador</span>
                                        </v-chip>
                                    </v-card-actions>
                                </v-col>

                                <v-col cols="12" sm="8" v-if="resposta.observacao">
                                    <v-card elevation="5" color="blue lighten-5" min-height="185">
                                        <v-card-text> {{ resposta.observacao }} </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-timeline-item>
                    </template>
                </template>
            </v-timeline>

            <FormPontosFortesEDesenvolver ref="pdiPontosFortes" :tipo="1" />

            <br />

            <FormPontosFortesEDesenvolver ref="pdiPontosDesenvolver" :tipo="2" />
        </v-card>

        <br />

        <BarraNavegacaoBotaoV3
            v-if="!carregando.mostraCarregando"
            :botoes="botoes()"
            referenciaComponenete="AvaliacaoDesempenhoForm"
        />

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { Maximo, Minimo, Obrigatorio } from '@/helpers/ValidadorCampo.js';
import { Alerta, PreencheDadosMesmaPropriedade, sleep } from '@/helpers/Funcoes.js';

import TipoColaboradorEnum from '@/Enum/AvaliacaoDesempenho/TipoColaboradorEnum.js';

import TituloModulo from '@/components/Default/TituloModulo.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import FormPontosFortesEDesenvolver from '@/views/AvaliacaoDesempenho/FormPontosFortesEDesenvolver.vue';

export default {
    name: 'FormFeedback',
    components: {
        TituloModulo,
        CarregandoLogoEmpresa,
        BarraNavegacaoBotaoV3,
        FormPontosFortesEDesenvolver
    },
    async created() {},
    async mounted() {
        this.mostraCarregando(true);

        this.id_avaliacao_desempenho_avaliado = parseInt(this.$route.params.id_avaliacao_desempenho_avaliado) ?? null;

        await this.buscaFormAvaliadoFeedback();
    },
    computed: {
        ...mapGetters(['botoesPorForm']),
        ...mapState(['carregando']),
        maximoPesoCriterio() {
            const max = this.avaliacao_desempenho.criterio.map(criterio => {
                return criterio.peso;
            });

            return Math.max.apply(Math, max);
        }
    },
    watch: {},
    methods: {
        ...mapActions(['mostraCarregando']),
        async buscaFormAvaliadoFeedback() {
            let urlConfig = {
                method: 'GET',
                url: `${API_URL.erp.avaliacaodesempenho.buscaAvaliacaoFeedback}/${this.id_avaliacao_desempenho_avaliado}`,
                data: {},
                mostraCarregando: false
            };

            await ApiService(urlConfig)
                .then(resposta => {
                    const id_avaliacao_desempenho = resposta.data.data.id_registro;

                    resposta.data.data.atributos.id_avaliacao_desempenho = id_avaliacao_desempenho;

                    const avaliacao = PreencheDadosMesmaPropriedade(this.avaliacao_desempenho, resposta.data.data.atributos);

                    avaliacao.criterio = resposta.data.data.atributos.criterio;
                    avaliacao.afirmacao = resposta.data.data.atributos.afirmacao;

                    this.avaliacao_desempenho = avaliacao;
                })
                .catch(erro => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'success',
                        mostra: true
                    };
                });

            await sleep(2000);

            this.mostraCarregando(false);
        },
        corAleatoria(key) {
            return this.cores[key] ?? this.cores[0];
        },
        encontraCriterioNome(id_criterio) {
            const criterio = this.avaliacao_desempenho.criterio.find(
                criterio => criterio.id_avaliacao_desempenho_criterio === id_criterio
            );

            if (!criterio) {
                return 'Não Encontrado';
            }

            return criterio.nome;
        },
        encontraCriterioPeso(id_criterio) {
            const criterio = this.avaliacao_desempenho.criterio.find(
                criterio => criterio.id_avaliacao_desempenho_criterio === id_criterio
            );

            if (!criterio) {
                return 0;
            }

            return criterio.peso;
        },
        async gerarPDI() {
            try {
                if (!this.$refs.pdiPontosFortes.$refs.form.validate()) {
                    throw new Error('Preencha os 3 pontos fortes');
                }

                if (!this.$refs.pdiPontosDesenvolver.$refs.form.validate()) {
                    throw new Error('Preencha os 3 pontos a desenvolver');
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', 'Gerar PDI ?', {
                        cor: 'green'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        let conteudoFormulario = this.FormataDadosParaEnviarForm();

                        this.mensagemCarregando = 'Aguarde, gerando o PDI...';

                        this.mostraCarregando(true);

                        await sleep(2000);

                        let urlConfig = {
                            method: 'POST',
                            url: `${API_URL.erp.planodesenvolvimentoindividual.salva}`,
                            data: conteudoFormulario,
                            mostraCarregando: false
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                const id_plano_desenvolvimento_individual = resposta.data.conteudo.id_registro;

                                this.$router.push({
                                    name: 'avaliacao-desempenho-resposta',
                                    params: {
                                        mensagem: `PDI ${id_plano_desenvolvimento_individual} criado para o colaborador ${this.avaliacao_desempenho.nome_colaborador}.`
                                    }
                                });
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (erro) {
                this.mostraCarregando(false);

                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        FormataDadosParaEnviarForm() {
            return {
                dados: {
                    id_avaliacao_desempenho: this.avaliacao_desempenho.id_avaliacao_desempenho,
                    id_avaliacao_desempenho_avaliados: this.id_avaliacao_desempenho_avaliado,
                    fortes: this.$refs.pdiPontosFortes.preparaDados(),
                    desenvolver: this.$refs.pdiPontosDesenvolver.preparaDados()
                }
            };
        },
        cancelaForm() {
            this.$router.push({ name: 'avaliacao-desempenho-resposta' });
        },
        botoes() {
            return [
                {
                    nome: 'Gerar PDI',
                    acao: 'gerarPDI',
                    icone: 'mdi-newspaper-variant-multiple',
                    cor: 'green'
                },
                {
                    nome: 'Fechar',
                    acao: 'cancelaForm',
                    icone: 'mdi-file-cancel',
                    cor: 'orange'
                }
            ];
        }
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            mensagemCarregando: 'Aguarde, preparando avaliação de feedback...',
            form: {
                ponto_forte: [{ ponto: null }, { ponto: null }, { ponto: null }],
                criterios: []
            },
            regrasPontoForte: [Obrigatorio(), Minimo(10), Maximo(3000)],
            id_avaliacao_desempenho_avaliado: null,
            tipo_colaborador: TipoColaboradorEnum.COLABORADOR,
            tipo_colaborador_gestor: TipoColaboradorEnum.GESTOR,
            cores: [
                'green',
                'purple',
                'deep-purple',
                'blue',
                'indigo',
                'cyan',
                'teal',
                'orange',
                'light-blue',
                'pink',
                'cyan',
                'teal',
                'yellow',
                'amber',
                'deep-orange',
                'brown',
                'blue-grey'
            ],
            avaliacao_desempenho: {
                id_avaliacao_desempenho: null,
                nome_colaborador: null,
                nome_colaborador_gestor: null,
                nome: null,
                orientacao: null,
                orientacao_criterio: null,
                criterio: [
                    {
                        id_avaliacao_desempenho: null,
                        id_avaliacao_desempenho_criterio: null,
                        nome: null,
                        peso: null
                    }
                ],
                afirmacao: [
                    {
                        id_avaliacao_desempenho: null,
                        id_avaliacao_desempenho_afirmacao: null,
                        nome: null,
                        palavra_chave: null,
                        consideracao: null,
                        resposta_avaliacao: [
                            {
                                id_avaliacao_desempenho: null,
                                id_avaliacao_desempenho_resposta: null,
                                id_colaborador: null,
                                id_avaliacao_desempenho_criterio: null,
                                id_avaliacao_desempenho_afirmacao: null,
                                id_avaliacao_desempenho_avaliados: null,
                                nome: null,
                                observacao: null,
                                tipo_colaborador: null
                            }
                        ]
                    }
                ]
            }
        };
    }
};
</script>

<style></style>
