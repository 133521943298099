const state = {
    conectado: false
};

const actions = {
    async atualizaEstadoConexao({ commit }, estado) {
        try {
            commit('CONECTAO_ESTADO', estado);
        } catch (e) {
            throw new Error(e.message);
        }
    }
};

const mutations = {
    CONECTAO_ESTADO(state, estado) {
        state.conectado = estado;
    }
};

const getters = {
    conectado: state => {
        return state.conectado;
    }
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
};
