<template>
    <transition-group name="fade" mode="out-in">
        <v-row key="arquivos">
            <v-col cols="12" md="2" v-for="(arquivo, index) in arquivos" :key="`arquivo-${index}`">
                <anexo :arquivo="arquivo" :posicao="index" @remove="$emit('anexo-remove', $event)" />
            </v-col>
        </v-row>
    </transition-group>
</template>

<script>
import Anexo from './Anexo.vue';

export default {
    name: 'Anexos',
    components: {
        Anexo,
    },
    props: {
        arquivos: {
            type: Array,
            required: true,
        },
    },
    emits: ['anexo-remove'],
    data() {
        return {
            imageLoading: false,
            imagemFinal: null,
            modalImagem: false,
        };
    },
};
</script>
