<template>
  <div>

    <v-dialog v-model="dialog" persistent max-width="800px">

      <template v-slot:activator="{ on, attrs }">
        <slot></slot>
      </template>

      <v-card>

        <v-card-title>
          <v-list-item class="grow">

            <span class="text-btn text-uppercase">Clonar as permissões de outro usuário</span>

            <v-row justify="end">
              <v-icon class="mr-1" large color="red" @click="eventoAbrirOuFecharModal()">
                mdi-close-circle-outline
              </v-icon>
            </v-row>

          </v-list-item>
        </v-card-title>

        <v-divider></v-divider>

        <template>
          <v-stepper v-model="stepperAtual">
            <v-stepper-header>
              <v-stepper-step :complete="stepperAtual > 1" step="1">
                Quem receberá
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="stepperAtual > 2" step="2">
                Usuario de referência
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3">
                Confirmar
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">

                <div class="pt-4 pb-2">Selecione o usuário que receberá o(s) perfil(s)</div>

                <InputAutoCompleteV3 v-model="usuarioQueReceberaPermissoes"
                  :datatableConfiguracao="autoCompleta.usuarios.datatable" tituloBusca="Usuario busca..."
                  itemValor="id_usuario" itemTexto="nome" return-object :regras="[v => !!v || 'Usuário é obrigatório']"
                  class="mb-12" />


                <v-btn color="primary" @click="avancaNoStepper()">
                  Continuar
                </v-btn>

                <v-btn text @click="resetarDialogDeClonarPermissoes(), eventoAbrirOuFecharModal()">
                  Cancelar
                </v-btn>
              </v-stepper-content>

              <v-stepper-content step="2">

                <div class="pt-4 pb-2">Selecione o usuário de refência para clonagem de seus perfis/permissões</div>

                <InputAutoCompleteV3 v-model="usuarioReferencia"
                  :datatableConfiguracao="autoCompleta.usuarios.datatable" tituloBusca="Usuario busca..."
                  itemValor="id_usuario" itemTexto="nome" return-object :regras="[v => !!v || 'Usuário é obrigatório']"
                  class="mb-12" />

                <v-btn color="primary" @click="avancaNoStepper()">
                  Continuar
                </v-btn>

                <v-btn text @click="stepperAtual = 1">
                  Voltar
                </v-btn>
              </v-stepper-content>

              <v-stepper-content step="3">

                <v-card class="mx-auto px-4 mb-16" elevation="0">
                  <v-card-title class="pb-2">Conferir mudanças</v-card-title>

                  <div class="pt-4 pb-2 text-center caption">Usuário que receberá os perfis</div>
                  <v-card class="d-flex align-center pa-4">
                    <div class="ps-2">{{usuarioQueReceberaPermissoes.nome}}</div>
                  </v-card>

                  <div class="pt-6 pb-6 text-center"><v-icon large class="arrow">mdi-arrow-up-thin</v-icon></div>

                  <div class="pb-2 text-center caption">Usuário referência</div>
                  <v-card  class="d-flex align-center pa-4">
                    <div class="ps-2">{{usuarioReferencia.nome}}</div>
                  </v-card>

                  <div>

                    <div class="pt-16 text-center"><strong>Perfis que permanecerão</strong></div>
                    <div class="text-center caption">Estes são os perfis que o usuário selecionado possuirá após a clonagem</div>
                    <div class="pb-6 text-center caption">(Apenas estes permanecerão, outros serão excluídos)</div>

                    <v-card v-for="perfil, key in perfis.usuarioReferencia.data" :key="key" class="d-flex pa-1 mb-2 align-center mx-auto primary--text rounded-pill" max-width="520px"
                      outlined style="border-color: blue">
                      <v-col class="pa-0" cols="2"><v-chip color="primary" class="white--text">ID: {{perfil.id_perfil}}</v-chip></v-col>
                      <v-col class=pa-0>
                        <div class="d-flex flex-column">
                          <span class="body-2"><strong>{{ perfil.nome }}</strong></span>
                          <span class="caption">{{perfil.descricao}}</span>
                        </div>
                      </v-col>
                    </v-card>

                  </div>

                  <div v-if="perfisQueSeraoExcluidos.length != 0">

                    <div></div>
                    <div class="pt-16 text-center"><strong>Perfis para exclusão</strong></div>
                    <div class="text-center caption">Estes são os perfis que o usuário clonado perderá</div>
                    <div class="pb-6 text-center caption">(O usuário de referência não possui estes perfis)</div>

                    <v-card v-for="perfil, key in perfisQueSeraoExcluidos" :key="key" class="d-flex pa-1 mb-2 align-center mx-auto red--text rounded-pill" max-width="520px"
                      outlined style="border-color: red">
                      <v-col class="pa-0" cols="2"><v-chip color="red" class="white--text">ID: {{perfil.id_perfil}}</v-chip></v-col>
                      <v-col class=pa-0>
                        <div class="d-flex flex-column">
                          <span class="body-2"><strong>{{ perfil.nome }}</strong></span>
                          <span class="caption">{{perfil.descricao}}</span>
                        </div>
                      </v-col>
                    </v-card>

                  </div>

                </v-card>

                <v-btn color="primary" @click="acionaApagaOuAdicionaPerfis()">
                  Confirmar
                </v-btn>

                <v-btn text @click="stepperAtual = 2">
                  Voltar
                </v-btn>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </template>

      </v-card>

    </v-dialog>

    <div>
      <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
        {{ notificacao.mensagem }}

        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
            Fechar
          </v-btn>
        </template>
      </v-snackbar>
    </div>

  </div>
</template>

<script>
import InputAutoCompleteV3 from '@/components/Form/InputAutoCompleteV3.vue';
import { API_URL, API_URL_BASE } from '@/config/configs';
import UsuarioPerfilTabPermissoesSelecionadas from '@/components/UsuarioPerfil/Embeeded/UsuarioPerfilTabPermissoesSelecionadas'
import ApiService from '@/services/ApiService.js';

export default {
  name: 'DialogClonarPermissoes',
  components: {
    InputAutoCompleteV3,
    UsuarioPerfilTabPermissoesSelecionadas
  },
  data() {
    return {
      usuarioQueReceberaPermissoes: {
        id_usuario: null,
        nome: null
      },
      usuarioReferencia: {
        id_usuario: null,
        nome: null
      },
      perfis: {
        usuarioQueReceberaPermissoes: {
          apenasIDS: [],
          data: []
        },
        usuarioReferencia: {
          apenasIDS: [],
          data: []
        }
      },
      perfisQueSeraoExcluidos: [],
      notificacao: {
        mostra: false,
        mensagem: '',
        tipo: 'success'
      },
      stepperAtual: 1,
      dialog: false,
      autoCompleta: {
        usuarios: {
          datatable: {
            id_datatable: 'id_usuario',
            chave_primaria: 'id_usuario',
            url: `${API_URL_BASE}${API_URL.erp.usuario.datatableVuetify}`,
            urlBusca: `${API_URL_BASE}${API_URL.erp.usuario.autoCompleta}`,
            colunas: [
              {
                id: 'id_usuario',
                nome: 'id_usuario',
                descricao: 'ID'
              },
              {
                id: 'nome',
                nome: 'nome',
                descricao: 'Nome Completo'
              }
            ]
          }
        }
      }
    }
  },
  watch: {
    'usuarioQueReceberaPermissoes.id_usuario': {
      handler: function (dadosAtual, dadosAntigo) {
        if(this.usuarioQueReceberaPermissoes.id_usuario != null) {
          this.buscaPerfisDoUsuario(this.usuarioQueReceberaPermissoes.id_usuario, 'usuarioQueReceberaPermissoes');
        }
      }
    },
    'usuarioReferencia.id_usuario': {
      handler: function (dadosAtual, dadosAntigo) {
        if(this.usuarioReferencia.id_usuario != null) {
          this.buscaPerfisDoUsuario(this.usuarioReferencia.id_usuario, 'usuarioReferencia');
        }
      }
    },
  },
  methods: {
    eventoAbrirOuFecharModal() {
      this.dialog = !this.dialog
    },

    async buscaPerfisDoUsuario(idDoUsuario, variavelParaArmazenarOsDados) {
      try {
        await ApiService({
          method: 'get',
          url: `${API_URL.erp.usuarioPerfil.buscaPerfisDoUsuario}/${idDoUsuario}`,
          data: {},
          mostraCarregando: true
        })
          .then(async resposta => {

            let IDsDosPerfisQueOUsuarioPossui = []
            let perfisQueOUsuarioPossuiData = []

            // armazenando os perfis que o usuario possui em forma de objeto pois preciso do id_usuario_perfil para fazer a exclusao posteriormente
            resposta.data[0].perfis.map(perfil => {
              perfisQueOUsuarioPossuiData.push({
                id_usuario_perfil: perfil.id_usuario_perfil,
                id_perfil: perfil.id_perfil,
                nome: perfil.nome,
                descricao: perfil.descricao,
              });
              IDsDosPerfisQueOUsuarioPossui.push(perfil.id_perfil)
            });


            if (variavelParaArmazenarOsDados == 'usuarioReferencia') {
              this.perfis.usuarioReferencia.apenasIDS = IDsDosPerfisQueOUsuarioPossui
              this.perfis.usuarioReferencia.data = perfisQueOUsuarioPossuiData
              return
            }

            this.perfis.usuarioQueReceberaPermissoes.apenasIDS = IDsDosPerfisQueOUsuarioPossui
            this.perfis.usuarioQueReceberaPermissoes.data = perfisQueOUsuarioPossuiData

          })
      } catch (erro) {
        this.notificacao = {
          mensagem: erro.message,
          tipo: 'error',
          mostra: true
        };
      } finally {
        this.carregando = false;
      }
    },

    async atribuirUmPerfil(idPerfil) {
      try {
        await ApiService({
          method: 'POST',
          url: `${API_URL.erp.usuarioPerfil.novo}`,
          data: {
            id_usuario: this.usuarioQueReceberaPermissoes.id_usuario,
            id_perfil: idPerfil,
          },
          mostraCarregando: true
        })
      } catch (erro) {
        this.notificacao = {
          mensagem: erro.message,
          tipo: 'error',
          mostra: true
        }
      }
    },

    async apagaUsuarioPerfil(idUsuarioPerfil) {
      try {
        await ApiService({
          method: 'DELETE',
          url: `${API_URL.erp.usuarioPerfil.apaga}/${idUsuarioPerfil}`,
          data: {},
          mostraCarregando: true
        })
      } catch (erro) {
        this.notificacao = {
          mensagem: erro.message,
          tipo: 'error',
          mostra: true
        }
      }
    },

    async acionaApagaOuAdicionaPerfis() {
      if (this.usuarioQueReceberaPermissoes.id_usuario == null) {
        this.notificacao = {
          mensagem: 'O usuário que receberá as permissões não foi selecionado',
          tipo: 'error',
          mostra: true
        }
        return
      }

      if (this.usuarioReferencia.id_usuario == null) {
        this.notificacao = {
          mensagem: 'O usuário de referência não foi selecionado',
          tipo: 'error',
          mostra: true
        }
        return
      }

      this.mostraCarregando = true
      
      await this.apagaOuAdicionaPerfis()
      .then(res => {
        this.notificacao = {
          mensagem: 'Usuario clonado com sucesso!',
          tipo: 'success',
          mostra: true
        }
      })

      this.resetarDialogDeClonarPermissoes();
    },

    async apagaOuAdicionaPerfis() {

      try {
        // Ocorre uma validação simples, se ele ja possuir o perfil este será excluido, senão este será adicionado
        let perfisQueSerãoApagados = [];
        let perfisQueSerãoAdicionados = [];

        for (let key in this.perfis.usuarioQueReceberaPermissoes.apenasIDS) {

          const IDPerfil = this.perfis.usuarioQueReceberaPermissoes.apenasIDS[key]
          const usuarioReferenciaPossuiOPerfilSelecionado = this.perfis.usuarioReferencia.apenasIDS.includes(IDPerfil)

          if (!usuarioReferenciaPossuiOPerfilSelecionado) {
            perfisQueSerãoApagados.push(IDPerfil)
          }
        }

        for (let key in this.perfis.usuarioReferencia.apenasIDS) {

          const IDPerfil = this.perfis.usuarioReferencia.apenasIDS[key]
          const usuarioQueReceberaPermissoesPossuiOPerfilSelecionado = this.perfis.usuarioQueReceberaPermissoes.apenasIDS.includes(IDPerfil)

          if (!usuarioQueReceberaPermissoesPossuiOPerfilSelecionado) {
            if( !(perfisQueSerãoAdicionados.includes(IDPerfil)) ) {
              perfisQueSerãoAdicionados.push(IDPerfil)
            }
          }
        }

        for (let key in perfisQueSerãoApagados) {

          const IDPerfil = perfisQueSerãoApagados[key];

          // misturando os arrays com todos o id de ambos usuarios para encontrar o id_usuario_perfil pelo id_perfil
          const arrayCombinado = [...this.perfis.usuarioReferencia.data, ...this.perfis.usuarioQueReceberaPermissoes.data];
          const usuarioPerfil = this.encontrarUsuarioPerfilPeloIDPerfil(arrayCombinado, IDPerfil)
          await this.apagaUsuarioPerfil(usuarioPerfil.id_usuario_perfil);
        }
        
        for (let key in perfisQueSerãoAdicionados) {
          const IDPerfil = perfisQueSerãoAdicionados[key];  
          await this.atribuirUmPerfil(IDPerfil)
        }

      } catch (erro) {
        this.notificacao = {
          mensagem: erro.message,
          tipo: 'error',
          mostra: true
        }
      } finally {
        this.mostraCarregando = false
      }
    },

    avancaNoStepper() {
      if (this.usuarioQueReceberaPermissoes.id_usuario == null && this.stepperAtual == 1) {
        this.notificacao = {
          mensagem: 'O Usuário clone deve ser selecionado',
          tipo: 'error',
          mostra: true
        };
        return
      }

      if(this.usuarioReferencia.id_usuario == null && this.stepperAtual == 2) {
        this.notificacao = {
          mensagem: 'O Usuário referência deve ser selecionado',
          tipo: 'error',
          mostra: true
        };
        return
      }

      this.stepperAtual = this.stepperAtual + 1

      if(this.stepperAtual == 3) {
        this.preencherPerfisQueSeraoExcluídos();
      }
    },

    preencherPerfisQueSeraoExcluídos() {
      let perfisQueSeraoExcluidos = []

      this.perfis.usuarioQueReceberaPermissoes.data.map(perfil => {
        if( !(this.perfis.usuarioReferencia.apenasIDS.includes(perfil.id_perfil)) ) {
          perfisQueSeraoExcluidos.push(perfil)
        }
      });
      
      this.perfisQueSeraoExcluidos = perfisQueSeraoExcluidos
    },

    encontrarUsuarioPerfilPeloIDPerfil(array, id) {
      const perfil = array.find(perfil => perfil.id_perfil === id);
      return perfil;
    },

    resetarDialogDeClonarPermissoes() {
      this.usuarioQueReceberaPermissoes = {
        id_usuario: null,
        nome: null
      },

      this.usuarioReferencia = {
        id_usuario: null,
        nome: null
      }

      this.perfis = {
        usuarioQueReceberaPermissoes: {
          apenasIDS: [],
          data: []
        },
        usuarioReferencia: {
          apenasIDS: [],
          data: []
        }
      },

      this.perfisQueSeraoExcluidos
      this.stepperAtual = 1
    }

  }
}
</script>

<style scoped>

.arrow {
  position: relative;
  animation: arrowMove 3s ease-in-out infinite; /* Animação contínua */
}


@keyframes arrowMove {
  0% {
    transform: translateY(0); /* Posição inicial */
    opacity: 1; /* Totalmente visível */
  }
  50% {
    transform: translateY(-20px); /* Movendo para cima da altura da seta */
    opacity: 0; /* Totalmente invisível */
  }
  50.1% {
    transform: translateY(30px); /* Reaparece na parte de baixo */
    opacity: 0;
  }
  100% {
    transform: translateY(0); /* Volta para a posição inicial */
    opacity: 1; /* Visível novamente */
  }
}

</style>