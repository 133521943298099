<template>
    <v-textarea
        :value="valor"
        @input="$emit('input', $event)"
        :label="dadosForm('rotulo')"
        :rules="dadosForm('regra')"
        :rows="dadosForm('linhas')"
        :disabled="dadosForm('desativado')"
        prepend-inner-icon="mdi-comment"
        counter
        clearable
        solo
        outlined
    ></v-textarea>
</template>

<script>
import { FormCampoRetornaDado } from '@/helpers/Componente.js';

export default {
    props: {
        value: {
            type: String | null,
            default: false,
        },
        form: {
            type: Object,
        },
        campo: {
            type: String,
        },
    },
    data: () => ({}),
    methods: {
        dadosForm(campoSelecionado) {
            return FormCampoRetornaDado(
                this.form,
                this.campo,
                campoSelecionado
            );
        },
    },
    computed: {
        valor: function () {
            return this.value != (null || false) ? this.value : '';
        },
    },
};
</script >

<style scoped>
</style>
