<template>
    <v-form ref="form">
        <v-row>
            <v-col cols="12" sm="4">
                <InputTextV3
                    v-model="form.id_profit_less_markup_despesa_operacional"
                    rotulo="ID"
                    :contador="200"
                    v-show="false"
                />

                <InputTextMoedaV3
                    v-model="form.geral_administrativa_valor"
                    prefixo="R$"
                    rotulo="Gerais Administrativas"
                    :desativado="true"
                />
            </v-col>

            <v-col cols="12" sm="2">
                <InputTextMoedaV3
                    v-model="form.geral_administrativa_percentual"
                    rotulo="Gerais Administrativas"
                    sufixo="%"
                    :tamanho="4"
                    :regra="regraPadrao"
                />
            </v-col>

            <v-col cols="12" sm="4">
                <InputTextMoedaV3
                    v-model="form.comissao_venda_valor"
                    prefixo="R$"
                    rotulo="Comissão Vendas"
                    :desativado="true"
                />
            </v-col>

            <v-col cols="12" sm="2">
                <InputTextMoedaV3
                    v-model="form.comissao_venda_percentual"
                    rotulo="Comissão Vendas Percentual"
                    sufixo="%"
                    :tamanho="4"
                    :regra="regraPadrao"
                />
            </v-col>

            <v-col cols="12" sm="4">
                <InputTextMoedaV3
                    v-model="form.repasse_profit_valor"
                    prefixo="R$"
                    rotulo="Repasse Profit"
                    :desativado="true"
                />
            </v-col>

            <v-col cols="12" sm="2">
                <InputTextMoedaV3
                    v-model="form.repasse_profit_percentual"
                    rotulo="Repasse Profit Percentual"
                    sufixo="%"
                    :tamanho="4"
                    :desativado="true"
                />
            </v-col>

            <v-col cols="12" sm="4">
                <InputTextMoedaV3
                    v-model="form.financeira_capital_giro_valor"
                    prefixo="R$"
                    rotulo="Financeira Capital Giro"
                    :regra="regraPadrao"
                />
            </v-col>

            <v-col cols="12" sm="2">
                <InputTextMoedaV3
                    v-model="form.financeira_capital_giro_percentual"
                    rotulo="Financeira Capital Giro Percentual"
                    sufixo="%"
                    :tamanho="4"
                    :desativado="true"
                />
            </v-col>

            <v-col cols="12" sm="12" class="text-right">
                <span class="text-md-body-1 font-weight-black">
                    Total General and Administrative
                </span>

                <v-chip class="ma-2 text-sm-body-1" label small>
                    {{ totalValoresFormatado }}
                </v-chip>

                <v-chip class="ma-2 text-sm-body-1" label small>
                    {{ totalPercentualFormatado }}
                </v-chip>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { Minimo, Obrigatorio } from '@/helpers/ValidadorCampo.js';

import { FormataMoeda } from '@/helpers/Funcoes.js';

import InputTextMoedaV3 from '@/components/Form/InputTextMoedaV3.vue';
import InputTextV3 from '@/components/Form/InputTextV3.vue';

export default {
    name: 'FormDespesaOperacional',
    components: {
        InputTextMoedaV3,
        InputTextV3
    },
    props: {
        id_profit_less_markup_despesa_operacional: {
            type: Number,
            default: () => null
        },
        geral_administrativa_valor: {
            type: Number,
            default: () => 0
        },
        geral_administrativa_percentual: {
            type: Number,
            default: () => 1
        },
        comissao_venda_valor: {
            type: Number,
            default: () => 0
        },
        comissao_venda_percentual: {
            type: Number,
            default: () => 1
        },
        repasse_profit_valor: {
            type: Number,
            default: () => 0
        },
        repasse_profit_percentual: {
            type: Number,
            default: () => 10
        },
        financeira_capital_giro_valor: {
            type: Number,
            default: () => 0
        },
        financeira_capital_giro_percentual: {
            type: Number,
            default: () => 0
        },
        total_valor: {
            type: Number,
            default: () => 0
        },
        total_percentual: {
            type: Number,
            default: () => 0
        },
        receita_venda_total: {
            type: Number,
            required: true
        }
    },
    watch: {
        receita_venda_total: {
            handler() {
                this.calculaPercentuais();
            },
            deep: true
        },
        repasse_profit_valor: {
            handler(data) {
                this.form.repasse_profit_valor = data;

                this.calculaPercentuais();
            },
            deep: true
        },
        repasse_profit_percentual: {
            handler(data) {
                this.form.repasse_profit_percentual = data;

                this.calculaPercentuais();
            },
            deep: true
        },
        'form.geral_administrativa_percentual': {
            handler() {
                this.calculaPercentuais();
            },
            deep: true
        },
        'form.comissao_venda_percentual': {
            handler() {
                this.calculaPercentuais();
            },
            deep: true
        },
        'form.financeira_capital_giro_valor': {
            handler() {
                this.calculaPercentuais();
            },
            deep: true
        }
    },
    created() {
        this.calculaPercentuais();
    },
    computed: {
        totalValoresFormatado() {
            return FormataMoeda(this.form.total_valor, {}, true);
        },
        totalPercentualFormatado() {
            return FormataMoeda(this.form.total_percentual, {}, false);
        }
    },
    methods: {
        calculaPercentuais() {
            this.form.geral_administrativa_valor =
                (this.receita_venda_total / 100) * this.form.geral_administrativa_percentual;
            this.form.comissao_venda_valor = (this.receita_venda_total / 100) * this.form.comissao_venda_percentual;
            this.form.repasse_profit_valor = this.repasse_profit_valor;
            this.form.repasse_profit_percentual = this.repasse_profit_percentual;

            this.totalValores();

            this.form.financeira_capital_giro_percentual =
                (this.form.financeira_capital_giro_valor / this.form.total_valor) * 100;

            this.totalPercentual();
        },
        totalValores() {
            this.form.total_valor = 0;

            const valores =
                parseFloat(this.form.geral_administrativa_valor) +
                parseFloat(this.form.comissao_venda_valor) +
                parseFloat(this.form.repasse_profit_valor) +
                parseFloat(this.form.financeira_capital_giro_valor);

            this.form.total_valor = valores;
        },
        totalPercentual() {
            this.form.total_percentual = 0;

            const valores =
                parseFloat(this.form.geral_administrativa_percentual) +
                parseFloat(this.form.comissao_venda_percentual) +
                parseFloat(this.form.repasse_profit_percentual) +
                parseFloat(this.form.financeira_capital_giro_percentual);

            this.form.total_percentual = valores;
        }
    },
    data() {
        return {
            form: {
                id_profit_less_markup_despesa_operacional: this.id_profit_less_markup_despesa_operacional,
                geral_administrativa_valor: this.geral_administrativa_valor,
                geral_administrativa_percentual: this.geral_administrativa_percentual,
                comissao_venda_valor: this.comissao_venda_valor,
                comissao_venda_percentual: this.comissao_venda_percentual,
                repasse_profit_valor: this.repasse_profit_valor,
                repasse_profit_percentual: this.repasse_profit_percentual,
                financeira_capital_giro_valor: this.financeira_capital_giro_valor,
                financeira_capital_giro_percentual: this.financeira_capital_giro_percentual,
                total_valor: this.total_valor,
                total_percentual: this.total_percentual
            },
            regraPadrao: [Obrigatorio(), Minimo(1)]
        };
    }
};
</script>
