<template>
    <div>
        <TituloModulo />

        <AlertaMensagem dense style="width: 100%" />

        <CarregandoLogoEmpresa :mensagem="mensagemCarregando" />

        <BarraNavegacaoBotaoV3 v-if="!carregando.mostraCarregando && permissao" :botoes="botoesComPermissao(situacaoForm)" />

        <v-row v-if="!carregando.mostraCarregando && permissao">
            <v-col cols="12" class="text-right">
                <TabelaVuetify
                    v-model="datatable.itensSelecionados"
                    :cabecalho="datatable.cabecalho"
                    :itens="datatable.itens"
                    :total_registros="datatable.totalRegistros"
                    :por_pagina="datatable.por_pagina"
                    :ultima_pagina="datatable.ultima_pagina"
                    :carregando="datatable.carregando"
                    :chave_primaria="datatable.chave_primaria"
                    :ordenar_por="datatable.ordenarPor"
                    :ordenar_direcao="datatable.ordenarDirecao"
                    @opcoes="buscaDadosGrid"
                    ref="tabela"
                >
                    <template v-slot:item.acao="{ item }">
                        <v-icon small class="mr-2" @click="editaItem(item)">
                            mdi-pencil
                        </v-icon>
                    </template>

                    <template v-slot:item.telefone_atendimento="{ item }">
                        <the-mask
                            mask="(##) # ####-####"
                            :value="item.telefone_atendimento"
                            type="text"
                            :masked="false"
                            disabled
                        ></the-mask>
                    </template>
                </TabelaVuetify>
            </v-col>
        </v-row>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { Alerta } from '@/helpers/Funcoes.js';

import SituacaoFormEnum from '@/Modulo/WhatsappDepartamento/Dominio/Enum/SituacaoForm.js';

import TabelaAcao from '@/Modulo/Compartilhado/Tabela/Dominio/Servico/Acao';

import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import TabelaVuetify from '@/components/Default/TabelaVuetify.vue';
import { TheMask } from 'vue-the-mask';

export default {
    name: 'Grid',
    components: {
        AlertaMensagem,
        BarraNavegacaoBotaoV3,
        CarregandoLogoEmpresa,
        TituloModulo,
        TabelaVuetify,
        TheMask
    },
    async mounted() {
        await this.carregaDados();

        if (this.$route.params.mensagem) {
            Alerta([this.$route.params.mensagem], 'success');
        }
    },
    computed: {
        ...mapGetters(['botoesComPermissao']),
        ...mapState(['carregando'])
    },
    methods: {
        ...mapActions(['montaNavegacao', 'mostraCarregando']),
        async carregaDados() {
            try {
                this.mostraCarregando(true);

                const permissoes = await this.dadosPaginaInicial();

                this.aplicaPermissoes(permissoes);

                this.permissao = true;
            } catch (erro) {
                Alerta([erro.message], 'error');
            } finally {
                this.mostraCarregando(false);
            }
        },
        async dadosPaginaInicial() {
            return await ApiService({
                method: 'get',
                url: `${API_URL.erp.whatsappfluxo.dadosPaginaInicial}`,
                data: {},
                mostraCarregando: false
            })
                .then(async resposta => {
                    return resposta.data.conteudo.permissoes;
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        },
        aplicaPermissoes(permissoes) {
            this.situacaoForm = SituacaoFormEnum.GRID;

            this.montaNavegacao({ botoes: this.botoes, permissoes });
        },
        async buscaDadosGrid() {
            this.datatable.carregando = true;

            const query = this.$refs.tabela.queryFiltros();

            await ApiService({
                method: 'get',
                url: `${API_URL.erp.whatsappfluxo.datatable}/?${query}`,
                data: {},
                mostraCarregando: false
            })
                .then(resposta => {
                    this.datatable.itens = resposta.data.conteudo.datatable.itens;

                    this.datatable.totalRegistros = resposta.data.conteudo.datatable.total;
                    this.datatable.ultima_pagina = resposta.data.conteudo.datatable.ultima_pagina;
                    this.datatable.carregando = false;
                })
                .catch(erro => {
                    Alerta([erro.message], 'error');
                });
        },
        novoItem() {
            this.$router.push({
                name: 'whatsapp-fluxo-form'
            });
        },
        editaItem(item) {
            this.$router.push({
                name: 'whatsapp-fluxo-form',
                params: { id_whatsapp_fluxo: item.id_whatsapp_fluxo }
            });
        },
        async apagaItem(item) {
            try {
                const ids = this.datatable.itensSelecionados
                    .map(function(item) {
                        return item.id_whatsapp_fluxo;
                    })
                    .join();

                if (!ids) {
                    Alerta(['Selecione um ou mais registro no grid para apagar.'], 'error');

                    return;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Apagar', `Departamento(s) ${ids} ?`, {
                        cor: 'red'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        this.mensagemCarregando = 'Aguarde, apagando...';

                        this.mostraCarregando(true);

                        let urlConfig = {
                            method: 'DELETE',
                            url: `${API_URL.erp.whatsappfluxo.apaga}/${ids}`,
                            data: {},
                            mostraCarregando: false
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                Alerta([`Departamento(s) ${ids} apagado(s).`], 'success');
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });

                        this.datatable.itensSelecionados = [];
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.mostraCarregando(false);
            }
        }
    },
    data() {
        return {
            permissao: false,
            permissoes: [],
            botoes: [
                {
                    id_permissao: 2,
                    nome: 'Novo',
                    acao: 'novoItem',
                    icone: 'mdi-newspaper-variant-multiple',
                    cor: 'green lighten-1',
                    form: [SituacaoFormEnum.GRID]
                },
                {
                    id_permissao: 4,
                    nome: 'Apaga',
                    acao: 'apagaItem',
                    icone: 'mdi-trash-can-outline',
                    cor: 'red lighten-1',
                    form: [SituacaoFormEnum.GRID]
                },
                {
                    id_permissao: 6,
                    nome: '',
                    acao: 'buscaDadosGrid',
                    icone: 'mdi-reload',
                    cor: 'grey darken-5',
                    form: [SituacaoFormEnum.GRID]
                },
                {
                    id_permissao: 6,
                    nome: '',
                    acao: 'limpaFiltro',
                    icone: 'mdi-filter-multiple',
                    cor: 'red darken-5',
                    form: [SituacaoFormEnum.GRID]
                }
            ],
            situacaoForm: null,
            mensagemCarregando: 'Buscando, aguarde...',
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            datatable: {
                carregando: false,
                mensagemCarregando: 'Buscando, aguarde...',
                chave_primaria: 'id_whatsapp_fluxo',
                itens: [],
                totalRegistros: 0,
                ultima_pagina: 0,
                pagina_atual: 1,
                por_pagina: 50,
                ordenarPor: 'id_whatsapp_fluxo',
                ordenarDirecao: true,
                opcoes: {},
                itensSelecionados: [],
                cabecalho: [
                    {
                        text: 'AÇÃO',
                        value: 'acao',
                        align: 'start',
                        width: '100',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'ID',
                        value: 'id_whatsapp_fluxo',
                        width: '120'
                    },
                    {
                        text: 'DESCRIÇÃO',
                        value: 'descricao',
                        width: '80%'
                    },
                    {
                        text: 'TELEFONE ATENDIMENTO',
                        value: 'telefone_atendimento',
                        width: '250'
                    }
                ]
            }
        };
    }
};
</script>
