import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/es5/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: {
            pt
        },
        current: "pt"
    },
    theme: {
        themes: {
            light: {
                tnlogLaranja: '#EB7405',
                tnlogCinzaEscuro: '#7D7875',
                tnlogAzul: '#05B7E6',
                tnlogAzulEscuro: '#007A99',
            }
        }
    }
});