import { API_URL_BASE, API_URL } from '@/config/configs';

const Cargo = {
    datatable: {
        id_datatable: 'cargo',
        chave_primaria: 'id_cargo',
        url: `${API_URL_BASE}${API_URL.erp.cargo.datatable}`,
        colunas: false,
    },
    formRefsComponente: 'CargoForm',
    formNome: 'formCargo',
    moduloNome: 'cargo',
    chavePrimaria: 'id_cargo',
    componenteArquivo: 'Cargo/Form.vue',
    tabRefs: 'tabCargo',
    urlNovoRegistro: '',
    urlConsultaRegistro: 'listaPorIdForm',
    tabs: []
}

export { Cargo };

