<template>
    <div>
        <TituloModulo />

        <v-card outlined elevation="2" class="pa-2">
            <v-row>
                <v-col cols="2" md="1" class="pl-2 pr-0">
                    <h3 class="pa-1 font-weight-bold">Filtros:</h3>
                </v-col>

                <v-col cols="8" md="8" class="">
                    <InputSelectV3
                        v-model="filial"
                        rotulo="Filial"
                        :regra="regrasFilial"
                        :itens="opcaoFilial"
                        itemTexto="razao_social"
                        itemValor="id_filial"
                        ref="filial"
                    />
                </v-col>

                <v-col cols="2" md="3">
                    <v-btn elevation="2" color="warning" @click="getProgramacaoCarga()">
                        Buscar
                        <v-icon right> mdi-magnify </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>

        &nbsp;

        <v-row>
            <v-col cols="12" class="text-right">
                <v-data-table
                    v-model="programacaoCargasSelecionadas"
                    :headers="cabecalho"
                    :items="programacaoItens"
                    :sort-by="['id_programacao_carga_rodopar']"
                    :sort-desc="[true]"
                    :search="filtros.busca_geral"
                    :fixed-header="true"
                    :items-per-page="50"
                    :footer-props="{
                        'items-per-page-options': [50, 100, 200, -1],
                    }"
                    :show-current-page="true"
                    :item-class="regraPintaLinha"
                    height="60vh"
                    item-key="id_programacao_carga"
                    loading-text="Buscando, aguarde..."
                    class="elevation-4"
                    show-select
                    multi-sort
                    dense
                >
                    <template v-slot:top>
                        <v-badge color="green darken-2" offset-x="23" offset-y="20" :content="`${cardTotais.total_cargas}`">
                            <v-btn
                                dark
                                right
                                @click="abreModalTotalCargas"
                                color="pink darken-1"
                                class="mr-2 pa-3 mt-3 v-btn--example"
                            >
                                <v-icon dense left> mdi-treasure-chest </v-icon>
                                Total de Cargas
                            </v-btn>
                        </v-badge>

                        <v-badge
                            color="green darken-2"
                            offset-x="23"
                            offset-y="20"
                            :content="`${cardTotais.veiculos_alocados}`"
                        >
                            <v-btn dark @click="abreModalVeiculosAlocados" color="deep-purple darken-1" class="mr-2 pa-3 mt-3">
                                <v-icon dense left> mdi-text-box-check </v-icon>
                                Cargas Vinculadas
                            </v-btn>
                        </v-badge>

                        <v-btn
                            @click="abreVinculaModal"
                            :disabled="!podeVincular"
                            :dark="podeVincular"
                            color="teal darken-1"
                            class="mr-2 pa-3 mt-3"
                        >
                            <v-icon dense left> mdi-truck-check </v-icon>
                            Vincular
                        </v-btn>

                        <v-menu transition="slide-y-transition" :offset-y="true" class=".rounded-t-0" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    :disabled="!podeVincular"
                                    :dark="podeVincular"
                                    class="purple mr-2 pa-3 mt-3"
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon dense left> mdi-swap-horizontal-bold </v-icon>
                                    STATUS
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-btn
                                            @click="alteraStatus(statusConfirmado)"
                                            :disabled="!podeVincular"
                                            :dark="podeVincular"
                                            color="green darken-2"
                                            class="mr-2 pa-3 mt-3"
                                        >
                                            <v-icon dense left> mdi-check-decagram-outline </v-icon>
                                            CONFIRMADO
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-btn
                                            @click="alteraStatus(statusPrevisao)"
                                            :disabled="!podeVincular"
                                            :dark="podeVincular"
                                            color="warning"
                                            class="mr-2 pa-3 mt-3"
                                        >
                                            <v-icon dense left> mdi-alert-circle-check-outline </v-icon>
                                            PREVISTO
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <v-menu transition="slide-y-transition" :offset-y="true" class=".rounded-t-0" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-badge
                                    color="green darken-2"
                                    icon="mdi-filter-check"
                                    offset-x="20"
                                    offset-y="20"
                                    :value="filtros.numero_di_total || filtros.numero_dta_total"
                                >
                                    <v-btn class="pink darken-2 mr-2 pa-3 mt-3" color="primary" v-bind="attrs" v-on="on">
                                        <v-icon dense left> mdi-swap-horizontal-bold </v-icon>
                                        FILTROS PRÉ-DEFINIDOS
                                    </v-btn>
                                </v-badge>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-btn
                                            :color="filtros.numero_dta_total ? 'green darken-1' : 'red lighten-3'"
                                            class="mr-2 pa-3 mt-3"
                                        >
                                            <span @click="filtroSomenteNumeroDta()">
                                                <v-icon dense left> mdi-alert-circle-check-outline </v-icon>
                                                SOMENTE DTA
                                            </span>
                                            <v-icon
                                                dense
                                                left
                                                class="pl-5"
                                                v-if="filtros.numero_dta_total"
                                                @click="filtros.numero_dta = []"
                                            >
                                                mdi-close-circle
                                            </v-icon>
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-btn
                                            :color="filtros.numero_di_total ? 'green darken-1' : 'red lighten-3'"
                                            class="mr-2 pa-3 mt-3"
                                        >
                                            <span @click="filtroSomenteNumeroDi()">
                                                <v-icon dense left> mdi-alert-circle-check-outline </v-icon>
                                                SOMENTE DI
                                            </span>
                                            <v-icon
                                                dense
                                                left
                                                class="pl-5"
                                                v-if="filtros.numero_di_total"
                                                @click="filtros.numero_di = []"
                                            >
                                                mdi-close-circle
                                            </v-icon>
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <v-btn
                            color="red darken-1"
                            @click="limpaFiltroTabela"
                            class="mr-2 pa-3 mt-3"
                            :disabled="!podeRemoverFiltros"
                        >
                            <v-icon dense left> mdi-filter-off </v-icon>
                            Remove Filtros
                        </v-btn>

                        <v-menu transition="slide-y-transition" :offset-y="true" class=".rounded-t-0" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="purple mr-2 pa-3 mt-3" color="primary" v-bind="attrs" v-on="on">
                                    <v-icon dense left> mdi-arrow-collapse-down </v-icon>
                                    Exportar
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-btn
                                            @click="exportaTabelaParaExcel()"
                                            dark
                                            color="green darken-2"
                                            class="mr-2 pa-3 mt-3"
                                        >
                                            <v-icon dense left> mdi-file-excel-outline </v-icon>
                                            Excel
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <v-row class="pa-2">
                            <v-col cols="12" sm="6">
                                <InputSelectV3
                                    v-model="filtros.unidade_empreendimento"
                                    rotulo="Filtro por unidade do solicitante"
                                    :itens="opcaoUnidadeEmpreendimento"
                                    itemTexto="codigo"
                                    itemValor="codigo"
                                    :smallChips="true"
                                    :multiplo="true"
                                    iconeAppend="mdi-filter-multiple"
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <InputTextV3
                                    v-model="filtros.busca_geral"
                                    iconeAppend="mdi-magnify"
                                    rotulo="Busca geral..."
                                    :contador="200"
                                />
                            </v-col>
                        </v-row>
                    </template>

                    <template v-slot:header.id_programacao_carga_rodopar="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small color="green darken-1" v-if="filtros.id_programacao_carga.length">
                                        mdi-filter-check
                                    </v-icon>
                                    <v-icon small v-else> mdi-filter-multiple </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: white; width: 500px" class="font-weight-medium">
                                <InputAutoCompleteLocalV3
                                    v-model="filtros.id_programacao_carga"
                                    :tituloBusca="header.text"
                                    :itemsOpcoes="programacaoItens"
                                    :smallChips="true"
                                    :deletableChips="true"
                                    :multiple="true"
                                    estiloCampo="solo"
                                    itemValor="id_programacao_carga_rodopar"
                                    itemTexto="id_programacao_carga_rodopar"
                                    class="pa-4 pt-10"
                                />
                                <v-btn @click="filtros.id_programacao_carga = []" small text color="primary" class="ml-2 mb-2"
                                    >Limpar</v-btn
                                >
                            </div>
                        </v-menu>
                    </template>

                    <template v-slot:header.solicitante="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small color="green darken-1" v-if="filtros.solicitante.length">
                                        mdi-filter-check
                                    </v-icon>
                                    <v-icon small v-else> mdi-filter-multiple </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: white; width: 500px" class="font-weight-medium">
                                <ComboBoxPreenchimentoV3
                                    v-model="filtros.solicitante"
                                    :tituloBusca="header.text"
                                    :smallChips="true"
                                    :deletableChips="true"
                                    :multiple="true"
                                    estiloCampo="solo"
                                    itemValor="id_programacao_carga_rodopar"
                                    itemTexto="id_programacao_carga_rodopar"
                                    class="pa-4 pt-10"
                                />
                                <v-btn @click="filtros.solicitante = []" small text color="primary" class="ml-2 mb-2"
                                    >Limpar</v-btn
                                >
                            </div>
                        </v-menu>
                    </template>

                    <template v-slot:header.tipo_solicitacao="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small color="green darken-1" v-if="filtros.tipo_solicitacao.length">
                                        mdi-filter-check
                                    </v-icon>
                                    <v-icon small v-else> mdi-filter-multiple </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: white; width: 500px" class="font-weight-medium">
                                <InputAutoCompleteLocalV3
                                    v-model="filtros.tipo_solicitacao"
                                    :tituloBusca="header.text"
                                    :itemsOpcoes="programacaoItens"
                                    :smallChips="true"
                                    :deletableChips="true"
                                    :multiple="true"
                                    estiloCampo="solo"
                                    itemValor="tipo_solicitacao"
                                    itemTexto="tipo_solicitacao"
                                    class="pa-4 pt-10"
                                />
                                <v-btn @click="filtros.tipo_solicitacao = []" small text color="primary" class="ml-2 mb-2"
                                    >Limpar</v-btn
                                >
                            </div>
                        </v-menu>
                    </template>

                    <template v-slot:header.status="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small color="green darken-1" v-if="filtros.status.length">
                                        mdi-filter-check
                                    </v-icon>
                                    <v-icon small v-else> mdi-filter-multiple </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: white; width: 500px" class="font-weight-medium">
                                <InputAutoCompleteLocalV3
                                    v-model="filtros.status"
                                    :tituloBusca="header.text"
                                    :itemsOpcoes="opcaoStatus"
                                    :smallChips="true"
                                    :deletableChips="true"
                                    :multiple="true"
                                    estiloCampo="solo"
                                    itemValor="value"
                                    itemTexto="label"
                                    class="pa-4 pt-10"
                                />
                                <v-btn @click="filtros.status = []" small text color="primary" class="ml-2 mb-2">Limpar</v-btn>
                            </div>
                        </v-menu>
                    </template>

                    <template v-slot:header.tipo_carga="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small color="green darken-1" v-if="filtros.tipo_carga.length">
                                        mdi-filter-check
                                    </v-icon>
                                    <v-icon small v-else> mdi-filter-multiple </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: white; width: 500px" class="font-weight-medium">
                                <InputAutoCompleteLocalV3
                                    v-model="filtros.tipo_carga"
                                    :tituloBusca="header.text"
                                    :itemsOpcoes="programacaoItens"
                                    :smallChips="true"
                                    :deletableChips="true"
                                    :multiple="true"
                                    estiloCampo="solo"
                                    itemValor="tipo_carga"
                                    itemTexto="tipo_carga"
                                    class="pa-4 pt-10"
                                />
                                <v-btn @click="filtros.tipo_carga = []" small text color="primary" class="ml-2 mb-2"
                                    >Limpar</v-btn
                                >
                            </div>
                        </v-menu>
                    </template>

                    <template v-slot:header.numero_di="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small color="green darken-1" v-if="filtros.numero_di_total.length">
                                        mdi-filter-check
                                    </v-icon>
                                    <v-icon small v-else> mdi-filter-multiple </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: white; width: 500px" class="font-weight-medium">
                                <InputAutoCompleteLocalV3
                                    v-model="filtros.numero_di"
                                    :tituloBusca="header.text"
                                    :itemsOpcoes="programacaoItens"
                                    :smallChips="true"
                                    :deletableChips="true"
                                    :multiple="true"
                                    estiloCampo="solo"
                                    itemValor="numero_di"
                                    itemTexto="numero_di"
                                    class="pa-4 pt-10"
                                />
                                <v-btn @click="filtros.numero_di = []" small text color="primary" class="ml-2 mb-2"
                                    >Limpar</v-btn
                                >
                            </div>
                        </v-menu>
                    </template>

                    <template v-slot:header.numero_dta="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small color="green darken-1" v-if="filtros.numero_dta_total.length">
                                        mdi-filter-check
                                    </v-icon>
                                    <v-icon small v-else> mdi-filter-multiple </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: white; width: 500px" class="font-weight-medium">
                                <InputAutoCompleteLocalV3
                                    v-model="filtros.numero_dta"
                                    :tituloBusca="header.text"
                                    :itemsOpcoes="programacaoItens"
                                    :smallChips="true"
                                    :deletableChips="true"
                                    :multiple="true"
                                    estiloCampo="solo"
                                    itemValor="numero_dta"
                                    itemTexto="numero_dta"
                                    class="pa-4 pt-10"
                                />
                                <v-btn @click="filtros.numero_dta = []" small text color="primary" class="ml-2 mb-2"
                                    >Limpar</v-btn
                                >
                            </div>
                        </v-menu>
                    </template>

                    <template v-slot:header.referencia="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small color="green darken-1" v-if="filtros.referencia.length">
                                        mdi-filter-check
                                    </v-icon>
                                    <v-icon small v-else> mdi-filter-multiple </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: white; width: 500px" class="font-weight-medium">
                                <ComboBoxPreenchimentoV3
                                    v-model="filtros.referencia"
                                    :tituloBusca="header.text"
                                    :smallChips="true"
                                    :deletableChips="true"
                                    :multiple="true"
                                    estiloCampo="solo"
                                    itemValor="referencia"
                                    itemTexto="referencia"
                                    class="pa-4 pt-10"
                                />
                                <v-btn @click="filtros.referencia = []" small text color="primary" class="ml-2 mb-2"
                                    >Limpar</v-btn
                                >
                            </div>
                        </v-menu>
                    </template>

                    <template v-slot:header.observacao="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small color="green darken-1" v-if="filtros.observacao.length">
                                        mdi-filter-check
                                    </v-icon>
                                    <v-icon small v-else> mdi-filter-multiple </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: white; width: 500px" class="font-weight-medium">
                                <ComboBoxPreenchimentoV3
                                    v-model="filtros.observacao"
                                    :tituloBusca="header.text"
                                    :smallChips="true"
                                    :deletableChips="true"
                                    :multiple="true"
                                    estiloCampo="solo"
                                    itemValor="observacao"
                                    itemTexto="observacao"
                                    class="pa-4 pt-10"
                                />
                                <v-btn @click="filtros.observacao = []" small text color="primary" class="ml-2 mb-2"
                                    >Limpar</v-btn
                                >
                            </div>
                        </v-menu>
                    </template>

                    <template v-slot:header.nome_tomador_servico="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small color="green darken-1" v-if="filtros.cliente.length">
                                        mdi-filter-check
                                    </v-icon>
                                    <v-icon small v-else> mdi-filter-multiple </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: white; width: 500px" class="font-weight-medium">
                                <InputAutoCompleteLocalV3
                                    v-model="filtros.cliente"
                                    :tituloBusca="header.text"
                                    :itemsOpcoes="programacaoItens"
                                    :smallChips="true"
                                    :deletableChips="true"
                                    :multiple="true"
                                    estiloCampo="solo"
                                    itemValor="nome_tomador_servico"
                                    itemTexto="nome_tomador_servico"
                                    class="pa-4 pt-10"
                                />
                                <v-btn @click="filtros.cliente = []" small text color="primary" class="ml-2 mb-2"
                                    >Limpar</v-btn
                                >
                            </div>
                        </v-menu>
                    </template>

                    <template v-slot:header.particularidade_cliente="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small color="green darken-1" v-if="filtros.particularidade_cliente.length">
                                        mdi-filter-check
                                    </v-icon>
                                    <v-icon small v-else> mdi-filter-multiple </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: white; width: 500px" class="font-weight-medium">
                                <ComboBoxPreenchimentoV3
                                    v-model="filtros.particularidade_cliente"
                                    :tituloBusca="header.text"
                                    :smallChips="true"
                                    :deletableChips="true"
                                    :multiple="true"
                                    estiloCampo="solo"
                                    itemValor="particularidade_cliente"
                                    itemTexto="particularidade_cliente"
                                    class="pa-4 pt-10"
                                />
                                <v-btn
                                    @click="filtros.particularidade_cliente = []"
                                    small
                                    text
                                    color="primary"
                                    class="ml-2 mb-2"
                                    >Limpar</v-btn
                                >
                            </div>
                        </v-menu>
                    </template>

                    <template v-slot:item.acao="{ item }">
                        <v-speed-dial direction="right" :open-on-hover="false" transition="slide-y-reverse-transition">
                            <template v-slot:activator>
                                <v-btn v-model="fab" color="blue darken-2" dark fab x-small>
                                    <v-icon v-if="fab" x-smal> mdi-close </v-icon>
                                    <v-icon v-else x-small> mdi-pencil </v-icon>
                                </v-btn>
                            </template>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        fab
                                        dark
                                        small
                                        color="blue lighten-2"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="editaCampoModal(item, 'particularidade_cliente')"
                                    >
                                        <v-icon>mdi-notebook-edit-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>PARTICULARIDADE CLIENTE</span>
                            </v-tooltip>
                        </v-speed-dial>
                    </template>

                    <template v-slot:item.data_pedido="{ item }">
                        {{ formataData(item.data_pedido) }}
                    </template>

                    <template v-slot:item.data_retirada="{ item }">
                        {{ formataData(item.data_retirada) }}
                    </template>

                    <template v-slot:item.itinerario_origem_estado="{ item }">
                        {{ item.itinerario_origem }} /
                        {{ item.itinerario_origem_estado }}
                    </template>

                    <template v-slot:item.itinerario_destino_estado="{ item }">
                        {{ item.itinerario_destino }} /
                        {{ item.itinerario_destino_estado }}
                    </template>

                    <template v-slot:item.peso="{ item }">
                        {{ mascaraMoeda(item.peso, false) }}
                    </template>

                    <template v-slot:item.peso_cubico="{ item }">
                        {{ mascaraMoeda(item.peso_cubico, false) }}
                    </template>

                    <template v-slot:item.valor_mercadoria="{ item }">
                        {{ mascaraMoeda(item.valor_mercadoria, true) }}
                    </template>

                    <template v-slot:item.status="{ item }">
                        <span v-if="item.status == 1">
                            <v-chip class="ma-2" color="yellow darken-3" text-color="white" small>
                                <v-avatar left>
                                    <v-icon small>mdi-alert-circle-check-outline</v-icon>
                                </v-avatar>
                                PREVISTO
                            </v-chip>
                        </span>
                        <span v-if="item.status == 2">
                            <v-chip class="ma-2" color="green darken-2" text-color="white" small>
                                <v-avatar left>
                                    <v-icon small>mdi-file-document-check-outline</v-icon>
                                </v-avatar>
                                CONFIRMADO
                            </v-chip>
                        </span>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false"> Fechar </v-btn>
                </template>
            </v-snackbar>
        </div>

        <VinculaModal
            ref="vinculaModal"
            :veiculos="veiculos"
            :motoristas="motoristas"
            @fechouModal="fechouModal"
            @vincular="vincular"
        />

        <EditaObservacaoModal
            :campoSelecionado="campoSelecionado"
            :programacaoCarga="programacaoCargaSelecionada"
            ref="edita_observacao_gerais_modal"
            @fechouModal="fechouModalEditaColuna"
            @salvar="editaColuna"
        />

        <EditaParticularidadeClienteModal
            :campoSelecionado="campoSelecionado"
            :programacaoCarga="programacaoCargaSelecionada"
            ref="edita_particularidade_cliente_modal"
            @fechouModal="fechouModalEditaColuna"
            @salvar="editaColuna"
        />

        <ModalVeiculosAlocados
            ref="ModalVeiculosAlocados"
            :programacaoCargas="programacaoCargas"
            :motoristas="motoristas"
            @desvincular="desvincular"
            @fechouModal="fechouModal"
            @atualizaDados="getProgramacaoCarga()"
        />

        <ModalTotalCargas ref="ModalTotalCargas" :programacaoCargas="programacaoCargas" />
    </div>
</template>

<script>
import moment from 'moment';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { FormataMoeda } from '@/helpers/Funcoes.js';
import { ExportaArrayObjectsParaExcel } from '@/helpers/Funcoes.js';

import TituloModulo from '@/components/Default/TituloModulo.vue';
import DateTimePickerV2 from '@/components/Form/DateTimePickerV2.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';
import InputTextV3 from '@/components/Form/InputTextV3.vue';
import InputTextMultiploV3 from '@/components/Form/InputTextMultiploV3.vue';
import InputAutoCompleteLocalV3 from '@/components/Form/InputAutoCompleteLocalV3.vue';
import ComboBoxPreenchimentoV3 from '@/components/Form/ComboBoxPreenchimentoV3.vue';
import VinculaModal from '@/components/AlocacaoCarga/VinculaModal.vue';
import EditaObservacaoModal from '@/components/AlocacaoCarga/EditaObservacaoModal.vue';
import EditaParticularidadeClienteModal from '@/components/AlocacaoCarga/EditaParticularidadeClienteModal.vue';
import ModalVeiculosAlocados from '@/components/AlocacaoCarga/ModalVeiculosAlocados.vue';
import ModalTotalCargas from '@/components/AlocacaoCarga/ModalTotalCargas.vue';
import { isEmpty } from 'lodash';

import StatusProgramacaoCargaEnum from '@/Enum/StatusProgramacaoCargaEnum';
import AlocacaoCargaTipoColetaEnum from '@/Enum/AlocacaoCargaTipoColetaEnum';
import AlocacaoCargaSituacaoEnum from '@/Enum/AlocacaoCargaSituacaoEnum';

export default {
    name: 'AlocacaoCarga',
    components: {
        TituloModulo,
        InputSelectV3,
        InputTextV3,
        InputTextMultiploV3,
        DateTimePickerV2,
        InputAutoCompleteLocalV3,
        VinculaModal,
        ModalVeiculosAlocados,
        ModalTotalCargas,
        EditaObservacaoModal,
        EditaParticularidadeClienteModal,
        ComboBoxPreenchimentoV3,
    },
    mounted() {
        this.getFilial();
        this.getUnidadeEmpreendimento();
        this.getMotoristas();
        this.getVeiculos();
        this.getProgramacaoCarga();
    },
    computed: {
        totalVeiculosAlocado() {
            let programacoes = this.programacaoCargas;
            let totalVeiculosAlocados = 0;

            Object.values(programacoes).forEach((programacao) => {
                if (!isEmpty(programacao.motorista)) {
                    totalVeiculosAlocados += 1;
                }
            });

            return totalVeiculosAlocados;
        },
        podeVincular() {
            return this.programacaoCargasSelecionadas.length > 0;
        },
        podeRemoverFiltros() {
            let verdadeiroFalso = false;

            Object.values(this.filtros).forEach(function (valor) {
                if (valor || valor != '') {
                    verdadeiroFalso = true;
                }
            });

            return verdadeiroFalso;
        },
        programacaoItens() {
            return this.programacaoCargas.filter((programacaoCarga) => {
                if (
                    programacaoCarga.alocacao_carga_situacao == AlocacaoCargaSituacaoEnum.ABERTO ||
                    !programacaoCarga.alocacao_carga_situacao
                ) {
                    return programacaoCarga;
                }
            });
        },
    },
    watch: {
        programacaoCargas: {
            handler: function (dadosAtual, dadosAntigo) {
                this.cardCalculaTotais();
            },
            deep: true,
        },
        'filtros.numero_di': {
            handler: function (dadosAtual, dadosAntigo) {
                this.filtros.numero_di_total = dadosAtual.length;
            },
            deep: true,
        },
        'filtros.numero_dta': {
            handler: function (dadosAtual, dadosAntigo) {
                this.filtros.numero_dta_total = dadosAtual.length;
            },
            deep: true,
        },
    },
    methods: {
        regraPintaLinha(item) {
            return isEmpty(item.numero_dta) > 0 ? '' : 'yellow lighten-4';
        },
        mascaraMoeda(valor, prefixo) {
            if (valor == null) {
                return 0;
            }

            return FormataMoeda(valor, {}, prefixo);
        },
        formataData(data) {
            return moment(data).format('DD/MM/YYYY H:mm:ss');
        },
        cardCalculaTotais() {
            let programacoes = this.programacaoCargas;

            this.cardTotais = {
                total_cargas: 0,
                veiculos_alocados: 0,
                cargas_nao_alocadas: 0,
                cargas_previstos: 0,
                cargas_confirmados: 0,
            };

            Object.values(programacoes).forEach((programacao) => {
                if (
                    !programacao.alocacao_carga_situacao ||
                    programacao.alocacao_carga_situacao == AlocacaoCargaSituacaoEnum.ABERTO ||
                    programacao.alocacao_carga_situacao == AlocacaoCargaSituacaoEnum.VEICULO_ALOCADO
                ) {
                    this.cardTotais.total_cargas += 1;
                }

                if (programacao.alocacao_carga_situacao == AlocacaoCargaSituacaoEnum.VEICULO_ALOCADO) {
                    this.cardTotais.veiculos_alocados += 1;

                    return;
                }

                if (
                    programacao.alocacao_carga_situacao == AlocacaoCargaSituacaoEnum.ABERTO ||
                    isEmpty(programacao.alocacao_carga_situacao)
                ) {
                    this.cardTotais.cargas_nao_alocadas += 1;
                }

                if (programacao.status == StatusProgramacaoCargaEnum.PREVISTO) {
                    this.cardTotais.cargas_previstos += 1;
                }

                if (programacao.status == StatusProgramacaoCargaEnum.CONFIRMADO) {
                    this.cardTotais.cargas_confirmados += 1;
                }

                return;
            });
        },
        editaCampoModal(programacaoCarga, nomeCampo) {
            const modal = `edita_${nomeCampo}_modal`;

            this.$refs[modal].dialog = true;
            this.campoSelecionado = nomeCampo;
            this.programacaoCargaSelecionada = programacaoCarga;
        },
        editaColuna(dados) {
            const prg = this.programacaoCargas.find(
                (prgVei) => prgVei.id_programacao_carga === dados.programacaoCarga.id_programacao_carga
            );

            prg[dados.nomeCampo] = dados.campo;
        },
        async getProgramacaoCarga() {
            if (!this.$refs['filial'].$children[0].validate()) {
                this.$refs['filial'].$children[0].validate(true);

                return false;
            }

            this.programacaoCargasSelecionadas = [];

            await ApiService({
                method: 'get',
                url: `${API_URL.erp.alocacaocarga.listaCarga}?filial=${this.filial}`,
                data: {},
            })
                .then((resposta) => {
                    this.programacaoCargas = resposta.data.data;

                    this.programacaoCargas = this.programacaoCargas.map((programacaoCarga) => {
                        const id = programacaoCarga.id_registro;

                        programacaoCarga = programacaoCarga.atributos;
                        programacaoCarga.id_programacao_carga = id;
                        programacaoCarga.os = null;
                        programacaoCarga.ignorar = false;

                        return programacaoCarga;
                    });
                })
                .catch((erro) => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true,
                    };
                });
        },
        async getFilial() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.filial.lista}?fields[filial]=id_filial,filial_descricao`,
                data: {},
            })
                .then((resposta) => {
                    this.opcaoFilial = resposta.data.data.map((filial) => {
                        const dados = {};

                        dados.id_filial = filial.id_registro;
                        dados.razao_social = filial.atributos.filial_descricao;

                        return dados;
                    });

                    this.filial = this.opcaoFilial[0].id_filial;
                })
                .catch((erro) => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true,
                    };
                });
        },
        async getUnidadeEmpreendimento() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.unidadeempreendimento.lista}?fields[unidade_empreendimento]=id_unidade_empreendimento,codigo`,
                data: {},
            })
                .then((resposta) => {
                    this.opcaoUnidadeEmpreendimento = resposta.data.data.map((unidade) => {
                        const dados = [];

                        dados.id_unidade_empreendimento = unidade.id_registro;
                        dados.codigo = unidade.atributos.codigo;

                        return dados;
                    });

                    this.opcaoUnidadeEmpreendimento.push({
                        id_unidade_empreendimento: '',
                        codigo: 'NÃO DEFINIDO',
                    });
                })
                .catch((erro) => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true,
                    };
                });
        },
        async vincular(dados) {
            try {
                const registros = this.programacaoCargasSelecionadas.map(function (programacao) {
                    return programacao.id_programacao_carga;
                });

                // salva no banco de dados
                const alocacaocargas = await this.salvaVincularCarga(registros, dados);

                if (!alocacaocargas) {
                    return false;
                }

                alocacaocargas.map((item) => {
                    item.alocacao_carga_entrega.map((itemEntrega) => {
                        const prg = this.programacaoCargas.find(
                            (prgVei) => prgVei.id_programacao_carga === item.id_programacao_carga
                        );

                        if (prg) {
                            if (itemEntrega.tipo_coleta == AlocacaoCargaTipoColetaEnum.CROSSDOCKING) {
                                prg.motorista_coleta = dados.motorista_coleta.motorista.parceiro.nome;
                                prg.placa_coleta = dados.motorista_coleta.placa_cavalo.placa;
                                prg.tipo_coleta_coleta = itemEntrega.tipo_coleta;
                                prg.id_alocacao_carga_entrega_crossdocking = itemEntrega.id_alocacao_carga_entrega;
                                prg.alocacao_carga_situacao = AlocacaoCargaSituacaoEnum.VEICULO_ALOCADO;
                            }

                            if (itemEntrega.tipo_coleta == AlocacaoCargaTipoColetaEnum.ENTREGA_CLIENTE) {
                                prg.motorista_entrega = dados.motorista_entrega.motorista.parceiro.nome;
                                prg.placa_entrega = dados.motorista_entrega.placa_cavalo.placa;
                                prg.tipo_coleta_entrega = itemEntrega.tipo_coleta;
                                prg.id_alocacao_carga_entrega_entrega = itemEntrega.id_alocacao_carga_entrega;
                                prg.alocacao_carga_situacao = AlocacaoCargaSituacaoEnum.VEICULO_ALOCADO;
                            }

                            prg.telefone_principal = dados.telefone_principal;
                            prg.id_alocacao_carga = itemEntrega.id_alocacao_carga;
                            prg.ignorar = false;
                        }
                    });
                });

                this.notificacao = {
                    mensagem: `Carga(s) vinculada(s)`,
                    tipo: 'success',
                    mostra: true,
                };
            } catch (e) {
                this.notificacao = {
                    mensagem: e.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        async desvincular(selecionados) {
            try {
                let programacoesPorVirgula = '';
                let alocacoesCargasSelecionadas = [];

                if (selecionados) {
                    alocacoesCargasSelecionadas = selecionados.map(function (alocacao_carga) {
                        return alocacao_carga.id_alocacao_carga;
                    });

                    alocacoesCargasSelecionadas = alocacoesCargasSelecionadas.filter((v, i, a) => a.indexOf(v) === i);

                    programacoesPorVirgula = alocacoesCargasSelecionadas.join(',');
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Desvincular', `Desvincular programação de carga ${programacoesPorVirgula} ?`, {
                        cor: 'red darken-4',
                    })
                    .then(async (confirma) => {
                        if (!confirma) {
                            return false;
                        }
                        // salva no banco de dados
                        await this.salvaDesvincularCarga(selecionados);

                        selecionados.map((item) => {
                            const prg = this.programacaoCargas.find(
                                (prgVei) => prgVei.id_alocacao_carga === item.id_alocacao_carga
                            );

                            if (prg) {
                                if (item.tipo_coleta == AlocacaoCargaTipoColetaEnum.CROSSDOCKING) {
                                    prg.motorista_coleta = null;
                                    prg.placa_coleta = null;
                                    prg.placa_coleta_2 = null;
                                    prg.tipo_coleta_coleta = null;
                                    prg.id_alocacao_carga_romaneio_item_crossdocking = null;
                                    prg.alocacao_carga_situacao = AlocacaoCargaSituacaoEnum.ABERTO;
                                }

                                if (item.tipo_coleta == AlocacaoCargaTipoColetaEnum.ENTREGA_CLIENTE) {
                                    prg.motorista_entrega = null;
                                    prg.placa_entrega = null;
                                    prg.placa_entrega_2 = null;
                                    prg.tipo_coleta_entrega = null;
                                    prg.id_alocacao_carga_romaneio_item_entrega = null;
                                    prg.alocacao_carga_situacao = AlocacaoCargaSituacaoEnum.ABERTO;
                                }
                            }
                        });

                        this.notificacao = {
                            mensagem: `Carga(s) desvinculada(s)`,
                            tipo: 'success',
                            mostra: true,
                        };
                    });
            } catch (e) {
                this.notificacao = {
                    mensagem: e.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        async salvaDesvincularCarga(registros) {
            try {
                const dataForm = {
                    dados: {
                        alocacao_carga: registros.map(function (alocacao_carga) {
                            return {
                                id_alocacao_carga: alocacao_carga.id_alocacao_carga,
                                tipo_coleta: alocacao_carga.tipo_coleta,
                            };
                        }),
                    },
                };

                let resp = await ApiService({
                    method: 'PUT',
                    url: `${API_URL.erp.alocacaocarga.desvincularCarga}`,
                    data: dataForm,
                })
                    .then((resposta) => {
                        return true;
                    })
                    .catch((erro) => {
                        throw new Error(erro.message);
                    });

                return true;
            } catch (e) {
                this.notificacao = {
                    mensagem: e.message,
                    tipo: 'error',
                    mostra: true,
                };

                throw new Error(e.message);
            }
        },
        validaParaVincular() {
            const registros = this.programacaoCargasSelecionadas
                .map(function (programacao) {
                    if (programacao.status == StatusProgramacaoCargaEnum.PREVISTO) {
                        return programacao.id_programacao_carga;
                    }
                })
                .filter((notUndefined) => notUndefined !== undefined);

            if (registros.length) {
                const programacoes = registros.join(',');

                this.notificacao = {
                    mensagem: `Não é permitido vincular motorista a Programação de Carga com status PREVISTO, IDs (${programacoes}).`,
                    tipo: 'error',
                    mostra: true,
                };

                return false;
            }

            return true;
        },
        fechouModal() {
            this.programacaoCargasSelecionadas = [];
            this.checkbox = [];
        },
        fechouModalEditaColuna() {
            this.campoSelecionado = '';
            this.programacaoCargaSelecionada = {};
            this.programacaoCargasSelecionadas = [];
        },
        async alteraStatus(status) {
            try {
                const registros = this.programacaoCargasSelecionadas.map(function (programacao) {
                    return programacao.id_programacao_carga;
                });

                let resp = await ApiService({
                    method: 'PUT',
                    url: `${API_URL.erp.programacaocarga.mudaStatus}`,
                    data: {
                        dados: {
                            id_programacao_carga: registros,
                            status: status,
                        },
                    },
                })
                    .then((resposta) => {
                        return true;
                    })
                    .catch((erro) => {
                        throw new Error(erro.message);
                    });

                registros.map((item) => {
                    const programacao = this.programacaoCargas.find((prgVei) => prgVei.id_programacao_carga === item);

                    if (programacao) {
                        programacao.status = status;
                    }
                });

                this.fechouModal();

                this.notificacao = {
                    mensagem: 'Status alterados',
                    tipo: 'success',
                    mostra: true,
                };
            } catch (e) {
                this.notificacao = {
                    mensagem: e.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        ignorar() {
            const pgVeiculos = this.programacaoCargas.map((programacaoCarga) => {
                if (!this.checkbox.includes(programacaoCarga.id_programacao_carga)) {
                    return programacaoCarga;
                }

                programacaoCarga.motorista = '';
                programacaoCarga.veiculo_placa_cavalo_entrega = '';
                programacaoCarga.veiculo_placa_carreta_entrega = '';
                programacaoCarga.ignorar = true;
                return programacaoCarga;
            });

            this.programacaoCargas = pgVeiculos;

            this.checkbox = [];

            this.notificacao = {
                mensagem: `Carga(s) ignorada(s)`,
                tipo: 'success',
                mostra: true,
            };
        },
        async salvaVincularCarga(registros, dados) {
            try {
                const telefonePrincipal = dados.telefone_principal
                    ? Number(dados.telefone_principal.replace(/\D/g, ''))
                    : null;

                let dataForm = {
                    dados: {
                        id_programacao_carga: registros, // Array IDS
                        telefone_principal: telefonePrincipal,
                        coletas: [],
                    },
                };

                if (!isEmpty(dados.motorista_entrega)) {
                    dataForm.dados.coletas.push({
                        id_motorista: dados.motorista_entrega.motorista.id_motorista,
                        id_veiculo_placa_cavalo: dados.motorista_entrega.id_veiculo_placa_cavalo,
                        id_veiculo_placa_carreta: dados.motorista_entrega.id_veiculo_placa_carreta,
                        id_veiculo_placa_carreta_2: dados.motorista_entrega.id_veiculo_placa_carreta_2,
                        tipo_coleta: dados.motorista_entrega.tipo_coleta_entrega,
                    });
                }

                if (!isEmpty(dados.motorista_coleta)) {
                    dataForm.dados.coletas.push({
                        id_motorista: dados.motorista_coleta.motorista.id_motorista,
                        id_veiculo_placa_cavalo: dados.motorista_coleta.id_veiculo_placa_cavalo,
                        id_veiculo_placa_carreta: dados.motorista_coleta.id_veiculo_placa_carreta,
                        id_veiculo_placa_carreta_2: dados.motorista_coleta.id_veiculo_placa_carreta_2,
                        tipo_coleta: dados.motorista_coleta.tipo_coleta_coleta,
                    });
                }

                const resp = await ApiService({
                    method: 'post',
                    url: `${API_URL.erp.alocacaocarga.vincularCarga}`,
                    data: dataForm,
                })
                    .then((resposta) => {
                        let dados = resposta.data.data.atributos;

                        dados = dados.map((programacaoCarga) => {
                            const registro = {};

                            registro.id_alocacao_carga = programacaoCarga.alocacao_carga.id_registro;
                            registro.id_programacao_carga = programacaoCarga.alocacao_carga.id_programacao_carga;
                            registro.telefone_principal = programacaoCarga.alocacao_carga.telefone_principal;
                            registro.alocacao_carga_entrega = programacaoCarga.alocacao_carga.alocacao_carga_entrega;

                            return registro;
                        });

                        return dados;
                    })
                    .catch((erro) => {
                        this.notificacao = {
                            mensagem: erro.message,
                            tipo: 'error',
                            mostra: true,
                        };
                    });

                return resp;
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };

                return false;
            }
        },
        async salvar() {
            try {
                // if (!this.$refs[this.formNome].validate()) {
                //     this.$emit(
                //         'mensagemAlerta',
                //         'Preencha corretamente todos campos',
                //         'error'
                //     );

                //     return false;
                // }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', 'Salvar registro ?', {
                        cor: 'green',
                    })
                    .then(async (confirma) => {
                        if (!confirma) {
                            return false;
                        }

                        await ApiService(urlConfig)
                            .then((resposta) => {
                                this.fechaForm();
                            })
                            .catch((erro) => {
                                this.notificacao = {
                                    mensagem: erro.message,
                                    tipo: 'error',
                                    mostra: true,
                                };
                            });
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        abreVinculaModal() {
            if (!this.validaParaVincular(this.programacaoCargasSelecionadas)) {
                return;
            }

            this.$refs.vinculaModal.dialog = true;
        },
        abreStatusModal() {
            this.$refs.statusModal.dialog = true;
        },
        limpaFiltroTabela() {
            this.filtros = {
                busca_geral: '',
                id_programacao_carga: [],
                solicitante: [],
                unidade_empreendimento: [],
                tipo_carga: [],
                tipo_solicitacao: [],
                status: [],
                numero_di: [],
                numero_dta: [],
                referencia: [],
                observacao: [],
                cliente: [],
                particularidade_cliente: [],
            };
        },
        async getMotoristas() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.alocacaocarga.motoristaSelecaoCombo}?ativo=true&fields[parceiro]=nome`,
                data: {},
            })
                .then((resposta) => {
                    this.motoristas = resposta.data.data.map((motorista) => {
                        const dados = motorista.atributos;
                        dados.id_motorista = motorista.id_registro;
                        return dados;
                    });
                })
                .catch((erro) => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true,
                    };
                });
        },
        async getVeiculos() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.veiculo.lista}?fields[veiculo]=placa,veiculo_proprio&fields[frota]=nome&fields[marca]=nome&fields[modelo]=nome&include=marca,modelo,frota`,
                data: {},
            })
                .then((resposta) => {
                    this.veiculos = resposta.data.data.map((veiculo) => {
                        const dados = veiculo.atributos;
                        dados.id_veiculo = veiculo.id_registro;
                        dados.nomeFormatado = dados.marca.nome + ' - ' + dados.modelo.nome + ' - ' + dados.placa;
                        return dados;
                    });
                })
                .catch((erro) => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true,
                    };
                });
        },
        abreModalVeiculosAlocados() {
            this.$refs['ModalVeiculosAlocados'].abreModal = true;
        },
        abreModalTotalCargas() {
            this.$refs['ModalTotalCargas'].abreModal = true;
        },
        filtroUnidade(valor) {
            if (!this.filtros.unidade_empreendimento.length) {
                return true;
            }

            return this.filtros.unidade_empreendimento.includes(valor);
        },
        filtroIdProgramacaoCarga(valor) {
            if (!this.filtros.id_programacao_carga.length) {
                return true;
            }

            return this.filtros.id_programacao_carga.includes(valor);
        },
        filtroStatus(valor) {
            if (!this.filtros.status.length) {
                return true;
            }

            return this.filtros.status.includes(valor);
        },
        filtroSolicitante(valor) {
            if (!this.filtros.solicitante.length) {
                return true;
            }

            let filtroEncontrado = false;

            this.filtros.solicitante.map((element) => {
                if (!valor) return;

                if (valor.toLowerCase().includes(element.toLowerCase())) {
                    filtroEncontrado = true;
                }
            });

            return filtroEncontrado;
        },
        filtroTipoCarga(valor) {
            if (!this.filtros.tipo_carga.length) {
                return true;
            }

            let filtroEncontrado = false;

            this.filtros.tipo_carga.map((element) => {
                if (!valor) return;

                if (valor.toLowerCase().includes(element.toLowerCase())) {
                    filtroEncontrado = true;
                }
            });

            return filtroEncontrado;
        },
        filtroTipoSolicitacao(valor) {
            if (!this.filtros.tipo_solicitacao.length) {
                return true;
            }

            return this.filtros.tipo_solicitacao.includes(valor);
        },
        filtroNumeroDi(valor) {
            if (!this.filtros.numero_di.length) {
                return true;
            }

            return this.filtros.numero_di.includes(valor);
        },
        filtroSomenteNumeroDi() {
            this.limpaFiltroTabela();

            const registros = this.programacaoItens.map(function (item) {
                if (!isEmpty(item.numero_di)) {
                    return item.numero_di;
                }
            });

            this.filtros.numero_di = registros;
        },
        filtroNumeroDta(valor) {
            if (!this.filtros.numero_dta.length) {
                return true;
            }

            return this.filtros.numero_dta.includes(valor);
        },
        filtroSomenteNumeroDta() {
            this.limpaFiltroTabela();

            const registros = this.programacaoItens.map(function (item) {
                if (!isEmpty(item.numero_dta)) {
                    return item.numero_dta;
                }
            });

            this.filtros.numero_dta = registros;
        },
        filtroReferencia(valor) {
            if (!this.filtros.referencia.length) {
                return true;
            }

            let filtroEncontrado = false;

            this.filtros.referencia.map((element) => {
                if (!valor) return;

                if (valor.toLowerCase().includes(element.toLowerCase())) {
                    filtroEncontrado = true;
                }
            });

            return filtroEncontrado;
        },
        filtroObservacao(valor) {
            if (!this.filtros.observacao.length) {
                return true;
            }

            let filtroEncontrado = false;

            this.filtros.observacao.map((element) => {
                if (!valor) return;

                if (valor.toLowerCase().includes(element.toLowerCase())) {
                    filtroEncontrado = true;
                }
            });

            return filtroEncontrado;
        },
        filtroCliente(valor) {
            if (!this.filtros.cliente.length) {
                return true;
            }

            return this.filtros.cliente.includes(valor);
        },
        filtroParticularidadeCliente(valor) {
            if (!this.filtros.particularidade_cliente.length) {
                return true;
            }

            let filtroEncontrado = false;

            this.filtros.particularidade_cliente.map((element) => {
                if (!valor) return;

                if (valor.toLowerCase().includes(element.toLowerCase())) {
                    filtroEncontrado = true;
                }
            });

            return filtroEncontrado;
        },
        exportaTabelaParaExcel() {
            try {
                const itens = this.programacaoItens.map((item) => {
                    item.status = item.status == this.statusPrevisao ? 'PREVISTO' : 'CONFIRMADO';

                    return item;
                });

                ExportaArrayObjectsParaExcel(itens, this.cabecalho, 'alocacao-carga');
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
    },
    data() {
        return {
            checkbox: [],
            motoristas: [],
            veiculos: [],
            programacaoCargas: [],
            campoSelecionado: '',
            programacaoCargaSelecionada: {},
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success',
            },
            filial: null,
            opcaoFilial: [],
            opcaoUnidadeEmpreendimento: [],
            regrasFilial: [(v) => !!v || 'Campo é obrigatório'],
            cabecalho: [
                {
                    text: 'AÇÃO',
                    value: 'acao',
                    sortable: false,
                    escondeExportacao: true,
                },
                {
                    text: 'ID',
                    value: 'id_programacao_carga',
                    width: '80',
                },
                {
                    text: 'Programação de Carga Rodopar',
                    value: 'id_programacao_carga_rodopar',
                    align: 'start',
                    width: '150',
                    filter: this.filtroIdProgramacaoCarga,
                },
                {
                    text: 'STATUS',
                    value: 'status',
                    width: '150',
                    filter: this.filtroStatus,
                },
                {
                    text: 'SOLICITANTE',
                    value: 'solicitante',
                    width: '200',
                    filter: this.filtroSolicitante,
                },
                {
                    text: 'TIPO OPERAÇÃO',
                    value: 'tipo_solicitacao',
                    width: '200',
                    filter: this.filtroTipoSolicitacao,
                },
                {
                    text: 'N° DI',
                    value: 'numero_di',
                    width: '200',
                    filter: this.filtroNumeroDi,
                },
                {
                    text: 'N° DTA',
                    value: 'numero_dta',
                    width: '200',
                    filter: this.filtroNumeroDta,
                },
                {
                    text: 'REFERÊNCIA',
                    value: 'referencia',
                    width: '200',
                    filter: this.filtroReferencia,
                },
                {
                    text: 'DATA PEDIDO',
                    value: 'data_pedido',
                    width: '160',
                },
                {
                    text: 'DATA RETIRADA',
                    value: 'data_retirada',
                    width: '160',
                },
                {
                    text: 'MOTORISTA COLETA',
                    value: 'motorista_coleta',
                    width: '200',
                },
                {
                    text: 'PLACA COLETA',
                    value: 'placa_coleta',
                    width: '200',
                },
                {
                    text: 'OBS. ENTREGA',
                    value: 'observacao',
                    width: '200',
                    filter: this.filtroObservacao,
                },
                {
                    text: 'CLIENTE',
                    value: 'nome_tomador_servico',
                    width: '200',
                    filter: this.filtroCliente,
                },
                {
                    text: 'CIDADE/UF ORIGEM',
                    value: 'itinerario_origem_estado',
                    width: '200',
                },
                { text: 'DESTINO', value: 'terminal_entrega', width: '200' },
                {
                    text: 'CIDADE/UF DESTINO',
                    value: 'itinerario_destino_estado',
                    width: '200',
                },
                { text: 'TIPO CARGA', value: 'tipo_carga', width: '200', filter: this.filtroTipoCarga },
                {
                    text: 'NÚMERO CONTAINER',
                    value: 'numero_container',
                    width: '200',
                },
                { text: 'QTD.', value: 'quantidade_itens', width: '200' },
                { text: 'PESO BRUTO', value: 'peso', width: '200' },
                { text: 'PESO CUBADO', value: 'peso_cubico', width: '200' },
                {
                    text: 'VALOR CARGA',
                    value: 'valor_mercadoria',
                    width: '200',
                },
                {
                    text: 'PARTICULARIDADE CLIENTE',
                    value: 'particularidade_cliente',
                    width: '200',
                    filter: this.filtroParticularidadeCliente,
                },
                {
                    text: 'UNIDADE SOLICITANTE',
                    value: 'unidade_solicitante',
                    width: '200',
                    filter: this.filtroUnidade,
                },
            ],
            programacaoCargasSelecionadas: [],
            fab: false,
            cardTotais: {
                total_cargas: 0,
                veiculos_alocados: 0,
                cargas_nao_alocadas: 0,
                cargas_previstos: 0,
                cargas_confirmados: 0,
            },
            filtroUnidadeEmpreendimento: null,
            filtros: {
                busca_geral: '',
                id_programacao_carga: [],
                solicitante: [],
                unidade_empreendimento: [],
                tipo_carga: [],
                tipo_solicitacao: [],
                status: [],
                numero_di: [],
                numero_di_total: 0,
                numero_dta: [],
                numero_dta_total: 0,
                referencia: [],
                observacao: [],
                cliente: [],
                particularidade_cliente: [],
            },
            statusConfirmado: StatusProgramacaoCargaEnum.CONFIRMADO,
            statusPrevisao: StatusProgramacaoCargaEnum.PREVISTO,
            opcaoStatus: [
                {
                    value: StatusProgramacaoCargaEnum.CONFIRMADO,
                    label: 'CONFIRMADO',
                },
                {
                    value: StatusProgramacaoCargaEnum.PREVISTO,
                    label: 'PREVISTO',
                },
            ],
        };
    },
};
</script>

<style>
.pointer {
    cursor: pointer;
}

.v-data-table__wrapper > table > tbody > tr {
    height: 60px;
}

#lateral .v-btn--example {
    bottom: 0;
    position: absolute;
    margin: 0 0 16px 16px;
}
</style>
