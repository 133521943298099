import { render, staticRenderFns } from "./DataV1.vue?vue&type=template&id=dc78ad14&scoped=true"
import script from "./DataV1.vue?vue&type=script&lang=js"
export * from "./DataV1.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc78ad14",
  null
  
)

export default component.exports