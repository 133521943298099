<template>
    <v-container fluid>
        <v-row>
            <v-col cols="3">
                <card-info
                    iconeBoxCor="red"
                    icone="mdi-currency-brl"
                    titulo="Lucro Bruto"
                    :value="custoVendaTotalFormatado"
                    :carregando="false"
                    rodape-texto="Lucro bruto"
                />
            </v-col>

            <v-col cols="3">
                <card-info
                    iconeBoxCor="warning"
                    icone="mdi-percent-outline"
                    titulo="Lucro Bruto %"
                    :value="custoVendaPercentualFormatado"
                    :carregando="false"
                    rodape-texto="Lucro bruto em percentual"
                />
            </v-col>

            <v-col cols="3">
                <card-info
                    iconeBoxCor="green darken-1"
                    icone="mdi-cash-multiple"
                    titulo="Lucro Líquido"
                    :value="lucroLiquidoTotalFormatado"
                    :carregando="false"
                    rodape-texto="Lucro Líquido"
                />
            </v-col>

            <v-col cols="3">
                <card-info
                    iconeBoxCor="warning"
                    icone="mdi-percent-outline"
                    titulo="Lucro Líquido %"
                    :value="lucroLiquidoPercentualFormatado"
                    :carregando="false"
                    rodape-texto="Lucro Líquido em percentual"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <div class="text-lg-h5 font-weight-bold orange--text text--darken-4">
                    Cliente
                </div>

                <div style="width: 400px;">
                    <v-simple-table class="mr-15" dense>
                        <template v-slot:default>
                            <tbody class="body-2 font-weight-regular grey--text text--darken-2">
                                <tr>
                                    <td width="120">Cliente</td>
                                    <td colspan="2">{{ parceiro_razao_social }}</td>
                                </tr>
                                <tr>
                                    <td>Gross Margin</td>
                                    <td>{{ grossMarginPercentualFormatado }}</td>
                                    <td>{{ grossMarginValorFormatado }}</td>
                                </tr>
                                <tr>
                                    <td width="120">Gross Profit</td>
                                    <td>{{ custoVendaPercentualFormatado }}</td>
                                    <td>{{ custoVendaTotalFormatado }}</td>
                                </tr>
                                <tr>
                                    <td>Net Profit</td>
                                    <td>{{ lucroLiquidoPercentualFormatado }}</td>
                                    <td>{{ lucroLiquidoTotalFormatado }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </div>
            </v-col>

            <v-col cols="6">
                <v-simple-table class="ml-15" dense>
                    <template v-slot:default>
                        <tbody class="body-2 font-weight-regular grey--text text--darken-2">
                            <tr>
                                <td>Situação</td>
                                <td>
                                    <v-chip class="text-sm-body-1 font-weight-bold yellow darken-3" label small>
                                        {{ situacaoDescricao() }}
                                    </v-chip>
                                </td>
                            </tr>
                            <tr>
                                <td width="150">Valor Mercadoria</td>
                                <td>
                                    {{ valorMercadoriaFormatado }}
                                </td>
                            </tr>
                            <tr>
                                <td width="150">Tipo Operação</td>
                                <td>
                                    {{ tipo_operacao_descricao }}
                                </td>
                            </tr>
                            <tr>
                                <td>Quantidade Embarques</td>
                                <td>
                                    {{ quantidade_embarques }}
                                </td>
                            </tr>
                            <tr>
                                <td>Origem/Destino</td>
                                <td>
                                    {{ endereco_origem }} <br />
                                    {{ endereco_destino }}
                                </td>
                            </tr>
                            <tr>
                                <td>KM</td>
                                <td>
                                    {{ cliente.rota_km }}km
                                    <span v-if="cliente.rota_duracao"> - {{ cliente.rota_duracao }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>

        <v-divider class="ma-3"></v-divider>

        <v-row>
            <v-col cols="6">
                <div>
                    <div class="text-lg-h5 font-weight-bold orange--text text--darken-4">
                        CUSTO DE VENDA
                    </div>

                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-md-body-1 font-weight-black">Serviço</th>
                                    <th class="text-md-body-1 font-weight-black">Valor R$</th>
                                    <th class="text-md-body-1 font-weight-black">Serviço %</th>
                                    <th class="text-md-body-1 font-weight-black">Aplicado %</th>
                                </tr>
                            </thead>
                            <tbody class="body-2 font-weight-regular grey--text text--darken-2">
                                <tr v-for="(item, index) in itens_custo_venda" :key="`custo-venda-${index}`">
                                    <td :class="item.total ? 'text-md-body-1 font-weight-black' : ''">
                                        {{ item.nome }}
                                    </td>
                                    <td :class="item.total ? 'text-md-body-1 font-weight-black' : ''">
                                        {{ formataMoeda(item.valor) }}
                                    </td>
                                    <td :class="item.total ? 'text-md-body-1 font-weight-black' : ''">
                                        {{ formataPercentual(item.servico_percentual) }}
                                    </td>
                                    <td :class="item.total ? 'text-md-body-1 font-weight-black' : ''">
                                        {{ formataPercentual(item.aplicado_percentual) }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

        <v-divider class="ma-3"></v-divider>

        <v-row>
            <v-col cols="6">
                <div>
                    <div class="text-lg-h5 font-weight-bold orange--text text--darken-4">
                        RECEITA VENDA
                    </div>

                    <v-simple-table>
                        <template v-slot:default>
                            <thead class="title font-weight-bold">
                                <tr>
                                    <th class="text-md-body-1 font-weight-black">Serviço</th>
                                    <th class="text-md-body-1 font-weight-black">Valor R$</th>
                                    <th class="text-md-body-1 font-weight-black">Serviço %</th>
                                    <th class="text-md-body-1 font-weight-black">Aplicado %</th>
                                </tr>
                            </thead>
                            <tbody class="body-2 font-weight-regular grey--text text--darken-2">
                                <tr v-for="(item, index) in itens_revenda_venda" :key="`custo-venda-${index}`">
                                    <td :class="item.total ? 'text-md-body-1 font-weight-black' : ''">{{ item.nome }}</td>
                                    <td :class="item.total ? 'text-md-body-1 font-weight-black' : ''">
                                        {{ formataMoeda(item.valor) }}
                                    </td>
                                    <td :class="item.total ? 'text-md-body-1 font-weight-black' : ''">
                                        {{ formataPercentual(item.servico_percentual) }}
                                    </td>
                                    <td :class="item.total ? 'text-md-body-1 font-weight-black' : ''">
                                        {{ formataPercentual(item.aplicado_percentual) }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <div>
                    <div class="text-lg-h5 font-weight-bold orange--text text--darken-4">
                        DESPESA OPERACIONAL
                    </div>

                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-md-body-1 font-weight-black">Serviço</th>
                                    <th class="text-md-body-1 font-weight-black">Valor R$</th>
                                    <th class="text-md-body-1 font-weight-black">Serviço %</th>
                                    <th class="text-md-body-1 font-weight-black">Aplicado %</th>
                                </tr>
                            </thead>
                            <tbody class="body-2 font-weight-regular grey--text text--darken-2">
                                <tr v-for="(item, index) in itens_despesa_administrativa" :key="`custo-venda-${index}`">
                                    <td :class="item.total ? 'text-md-body-1 font-weight-black' : ''">{{ item.nome }}</td>
                                    <td :class="item.total ? 'text-md-body-1 font-weight-black' : ''">
                                        {{ formataMoeda(item.valor) }}
                                    </td>
                                    <td :class="item.total ? 'text-md-body-1 font-weight-black' : ''">
                                        {{ formataPercentual(item.servico_percentual) }}
                                    </td>
                                    <td :class="item.total ? 'text-md-body-1 font-weight-black' : ''">
                                        {{ formataPercentual(item.aplicado_percentual) }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <div>
                    <div class="text-lg-h5 font-weight-bold orange--text text--darken-4">
                        IMPOSTO
                    </div>

                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-md-body-1 font-weight-black">Serviço</th>
                                    <th class="text-md-body-1 font-weight-black">Valor R$</th>
                                    <th class="text-md-body-1 font-weight-black">Serviço %</th>
                                    <th class="text-md-body-1 font-weight-black">Aplicado %</th>
                                </tr>
                            </thead>
                            <tbody class="body-2 font-weight-regular grey--text text--darken-2">
                                <tr v-for="(item, index) in itens_imposto" :key="`custo-venda-${index}`">
                                    <td :class="item.total ? 'text-md-body-1 font-weight-black' : ''">{{ item.nome }}</td>
                                    <td :class="item.total ? 'text-md-body-1 font-weight-black' : ''">
                                        {{ formataMoeda(item.valor) }}
                                    </td>
                                    <td :class="item.total ? 'text-md-body-1 font-weight-black' : ''">
                                        {{ formataPercentual(item.servico_percentual) }}
                                    </td>
                                    <td :class="item.total ? 'text-md-body-1 font-weight-black' : ''">
                                        {{ formataPercentual(item.aplicado_percentual) }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="12" class="text-right">
                <span class="text-md-body-1 font-weight-black">
                    Custo Venda
                </span>

                <v-chip class="ma-2 text-sm-body-1" label small>
                    {{ formataMoeda(custo_venda.total_valor) }}
                </v-chip>

                <v-chip class="ma-2 text-sm-body-1" label small>
                    {{ formataPercentual(custo_venda.total_percentual) }} <strong>%</strong>
                </v-chip>

                <v-spacer></v-spacer>

                <span class="text-md-body-1 font-weight-black">
                    Receita Venda
                </span>

                <v-chip class="ma-2 text-sm-body-1" label small>
                    {{ formataMoeda(receita_venda.total_valor) }}
                </v-chip>

                <v-spacer></v-spacer>

                <span class="text-md-body-1 font-weight-black">
                    Imposto
                </span>

                <v-chip class="ma-2 text-sm-body-1" label small>
                    {{ formataMoeda(impostoTotal()) }}
                </v-chip>

                <v-spacer></v-spacer>

                <span class="text-md-body-1 font-weight-black">
                    Lucro Operacional
                </span>

                <v-chip class="ma-2 text-sm-body-1" label small>
                    {{ custoVendaTotalFormatado }}
                </v-chip>

                <v-chip class="ma-2 text-sm-body-1" label small>
                    {{ custoVendaPercentualFormatado }}
                </v-chip>

                <v-spacer></v-spacer>

                <span class="text-md-body-1 font-weight-black">
                    Lucro Líquido
                </span>

                <v-chip class="ma-2 text-sm-body-1" label small>
                    {{ lucroLiquidoTotalFormatado }}
                </v-chip>

                <v-chip class="ma-2 text-sm-body-1" label small>
                    {{ lucroLiquidoPercentualFormatado }}
                </v-chip>
            </v-col>

            <v-col cols="12" sm="12" v-if="reprovados">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr class="text-md-body-1 font-weight-black">
                                <td>Recusa</td>
                                <td>Usuário</td>
                                <td>Data</td>
                            </tr>
                        </thead>
                        <tbody class="body-2 font-weight-regular grey--text text--darken-2">
                            <tr v-for="(item, index) in reprovados" :key="`reprovado-${index}`">
                                <td>
                                    {{ item.recusa }}
                                </td>
                                <td>
                                    {{ item.nome_completo }}
                                </td>
                                <td>
                                    {{ formataDataHora(item.data_criacao) }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import moment from 'moment';

import { FormataMoeda } from '@/helpers/Funcoes.js';

import CardInfo from '@/components/Default/CardInfo.vue';

import InputTextMoedaV3 from '@/components/Form/InputTextMoedaV3.vue';

export default {
    name: 'FormRevisao',
    components: {
        CardInfo,
        InputTextMoedaV3
    },
    props: {
        situacao: {
            type: Number,
            required: true
        },
        parceiro_razao_social: {
            type: String,
            required: true
        },
        tipo_operacao_descricao: {
            type: String,
            required: true
        },
        rota_km: {
            type: Number,
            default: () => 0
        },
        endereco_origem: {
            type: String,
            default: () => ''
        },
        endereco_destino: {
            type: String,
            default: () => ''
        },
        cliente: {
            type: Object,
            default: () => {}
        },
        custo_venda: {
            type: Object,
            default: () => {}
        },
        receita_venda: {
            type: Object,
            default: () => {}
        },
        despesa_operacional: {
            type: Object,
            default: () => {}
        },
        reprovados: {
            type: Array,
            default: () => []
        },
        quantidade_embarques: {
            type: String,
            default: () => ''
        }
    },
    watch: {},
    created() {
        const itensCustoVenda = [
            {
                nome: 'Frete Custo',
                valor: this.custo_venda.frete_custo_valor,
                servico_percentual: this.custo_venda.frete_custo_percentual,
                aplicado_percentual: 0
            },
            {
                nome: 'Pedágio',
                valor: this.custo_venda.pedagio_valor,
                servico_percentual: this.custo_venda.pedagio_percentual,
                aplicado_percentual: 0
            },
            {
                nome: 'Seguro',
                valor: this.custo_venda.seguro_valor,
                servico_percentual: this.custo_venda.seguro_percentual,
                aplicado_percentual: 0
            },
            {
                nome: 'Licença',
                valor: this.custo_venda.licenca_valor,
                servico_percentual: this.custo_venda.licenca_percentual,
                aplicado_percentual: 0
            },
            {
                nome: 'Taxa IMO Anvisa',
                valor: this.custo_venda.taxa_imo_anvisa_valor,
                servico_percentual: this.custo_venda.taxa_imo_anvisa_percentual,
                aplicado_percentual: 0
            },
            {
                nome: 'Devolução Container Vázio',
                valor: this.custo_venda.devolucao_container_vazio_valor,
                servico_percentual: this.custo_venda.devolucao_container_vazio_percentual,
                aplicado_percentual: 0
            },
            {
                nome: 'Ajudante',
                valor: this.custo_venda.ajudante_valor,
                servico_percentual: this.custo_venda.ajudante_percentual,
                aplicado_percentual: 0
            },
            {
                nome: 'Escolta',
                valor: this.custo_venda.escolta_valor,
                servico_percentual: this.custo_venda.escolta_percentual,
                aplicado_percentual: 0
            },
            {
                nome: 'Total Cost of Sales',
                valor: this.custo_venda.total_valor,
                servico_percentual: this.custo_venda.total_percentual,
                aplicado_percentual: 0,
                total: true
            }
        ];

        const itensReceitaaVenda = [
            {
                nome: 'Frete Peso',
                valor: this.receita_venda.frete_peso_valor,
                servico_percentual: 0,
                aplicado_percentual: 0
            },
            {
                nome: 'Pedágio',
                valor: this.receita_venda.pedagio_valor,
                servico_percentual: 0,
                aplicado_percentual: 0
            },
            {
                nome: 'Seguro',
                valor: this.receita_venda.seguro_valor,
                servico_percentual: 0,
                aplicado_percentual: this.receita_venda.seguro_percentual
            },
            {
                nome: 'GRIS',
                valor: this.receita_venda.gris_valor,
                servico_percentual: 0,
                aplicado_percentual: this.receita_venda.gris_percentual
            },
            {
                nome: 'Licença',
                valor: this.receita_venda.licenca_valor,
                servico_percentual: 0,
                aplicado_percentual: 0
            },
            {
                nome: 'Taxa IMO Anvisa',
                valor: this.receita_venda.taxa_imo_anvisa_valor,
                servico_percentual: 0,
                aplicado_percentual: this.receita_venda.taxa_imo_anvisa_percentual
            },
            {
                nome: 'Despacho',
                valor: this.receita_venda.despacho_valor,
                servico_percentual: 0,
                aplicado_percentual: 0
            },
            {
                nome: 'Profit Calculado',
                valor: this.receita_venda.profit_calculado_valor,
                servico_percentual: 0,
                aplicado_percentual: this.receita_venda.profit_calculado_percentual
            },
            {
                nome: 'Ajudante',
                valor: this.receita_venda.ajudante_valor,
                servico_percentual: 0,
                aplicado_percentual: 0
            },
            {
                nome: 'Escolta',
                valor: this.receita_venda.escolta_valor,
                servico_percentual: 0,
                aplicado_percentual: 0
            },
            {
                nome: 'Repasse Profit',
                valor: this.receita_venda.repasse_profit_valor,
                servico_percentual: 0,
                aplicado_percentual: this.receita_venda.repasse_profit_percentual
            },
            {
                nome: 'Total Sales Revenue',
                valor: this.receita_venda.total_valor,
                servico_percentual: 0,
                aplicado_percentual: 0,
                total: true
            }
        ];

        const itensDespesaAdminsitrativa = [
            {
                nome: 'Despesas Gerais Administrativas',
                valor: this.despesa_operacional.geral_administrativa_valor,
                servico_percentual: this.despesa_operacional.geral_administrativa_percentual,
                aplicado_percentual: 0
            },
            {
                nome: 'Comissão sobre as Vendas',
                valor: this.despesa_operacional.comissao_venda_valor,
                servico_percentual: this.despesa_operacional.geral_administrativa_percentual,
                aplicado_percentual: 0
            },
            {
                nome: 'Repasse Profit',
                valor: this.despesa_operacional.repasse_profit_valor,
                servico_percentual: this.despesa_operacional.repasse_profit_percentual,
                aplicado_percentual: 0
            },
            {
                nome: 'Despesas Financeiras (Capital de Giro)',
                valor: this.despesa_operacional.financeira_capital_giro_valor,
                servico_percentual: this.despesa_operacional.financeira_capital_giro_percentual,
                aplicado_percentual: 0
            },
            {
                nome: 'Total General and Administrative',
                valor: this.despesa_operacional.total_valor,
                servico_percentual: this.despesa_operacional.total_percentual,
                aplicado_percentual: 0,
                total: true
            }
        ];

        const itensImposto = [
            {
                nome: 'ICMS',
                valor: this.receita_venda.icms_valor,
                servico_percentual: 0,
                aplicado_percentual: 100 - 100 * this.receita_venda.icms_percentual
            },
            {
                nome: 'PIS + COFINS',
                valor: this.receita_venda.pis_cofins_valor,
                servico_percentual: this.receita_venda.pis_cofins_percentual,
                aplicado_percentual: 0
            },
            {
                nome: 'INSS Empresa',
                valor: this.receita_venda.inss_empresa_valor,
                servico_percentual: this.receita_venda.inss_empresa_percentual,
                aplicado_percentual: 0
            },
            {
                nome: 'IR + CSLL',
                valor: this.receita_venda.ir_csll_valor,
                servico_percentual: this.receita_venda.ir_csll_percentual,
                aplicado_percentual: 0
            }
        ];

        this.itens_custo_venda = itensCustoVenda;
        this.itens_revenda_venda = itensReceitaaVenda;
        this.itens_despesa_administrativa = itensDespesaAdminsitrativa;
        this.itens_imposto = itensImposto;
    },
    computed: {
        grossMarginValorFormatado() {
            return FormataMoeda(
                this.receita_venda.total_valor - (this.custo_venda.total_valor + this.receita_venda.total_imposto),
                {},
                true
            );
        },
        grossMarginPercentualFormatado() {
            return FormataMoeda(
                (this.receita_venda.total_valor - (this.custo_venda.total_valor + this.receita_venda.total_imposto)) /
                    this.receita_venda.total_valor,
                {},
                false,
                true
            );
        },
        grossProfitValorFormatado() {
            return FormataMoeda(this.despesa_operacional.total_valor, {}, false, true);
        },
        valorMercadoriaFormatado() {
            return FormataMoeda(this.cliente.valor_mercadoria, {}, true);
        },
        custoVendaTotalFormatado() {
            let valor =
                this.receita_venda.total_valor -
                (this.custo_venda.total_valor + this.receita_venda.total_imposto + this.despesa_operacional.total_valor);

            return FormataMoeda(valor, {}, true);
        },
        custoVendaPercentualFormatado() {
            let valor =
                this.receita_venda.total_valor -
                (this.custo_venda.total_valor + this.receita_venda.total_imposto + this.despesa_operacional.total_valor);

            return FormataMoeda(valor / this.receita_venda.total_valor, {}, false, true);
        },
        lucroLiquidoTotalFormatado() {
            let valor = this.lucroLiquidoTotal();

            return FormataMoeda(valor, {}, true);
        },
        lucroLiquidoPercentualFormatado() {
            let valor =
                this.receita_venda.total_valor -
                this.receita_venda.ir_csll_valor -
                (this.custo_venda.total_valor + this.receita_venda.total_imposto + this.despesa_operacional.total_valor);

            return FormataMoeda(valor / this.receita_venda.total_valor, {}, false, true);
        }
    },
    methods: {
        lucroBrutoTotal() {
            return this.receita_venda.total_valor - (this.custo_venda.total_valor + this.receita_venda.total_imposto);
        },
        lucroBrutoPercentual() {
            return (this.lucroBrutoTotal() / this.receita_venda.total_valor) * 100;
        },
        lucroLiquidoTotal() {
            return (
                this.receita_venda.total_valor -
                this.receita_venda.ir_csll_valor -
                (this.custo_venda.total_valor + this.receita_venda.total_imposto + this.despesa_operacional.total_valor)
            );
        },
        impostoTotal() {
            return this.receita_venda.icms_valor + this.receita_venda.pis_cofins_valor + this.receita_venda.inss_empresa_valor;
        },
        formataMoeda(valor) {
            return FormataMoeda(valor, {}, true);
        },
        formataPercentual(valor) {
            return FormataMoeda(valor, {}, false);
        },
        situacaoDescricao() {
            let descricao = 'REVISÃO';

            if (this.situacao == this.situacaoAprovacao) {
                descricao = 'APROVAÇÃO';
            }

            if (this.situacao == this.situacaoReprovado) {
                descricao = 'REPROVADO';
            }

            return descricao;
        },
        formataDataHora(data) {
            return moment(data).format('DD/MM/YYYY HH:mm');
        }
    },
    data() {
        return {
            form: {},
            itens_custo_venda: [],
            itens_revenda_venda: [],
            itensDespesaAdminsitrativa: [],
            situacaoAberto: 1,
            situacaoAprovacao: 2,
            situacaoReprovado: 4
        };
    }
};
</script>

<style scoped>
.v-data-table__wrapper > table > tbody > tr {
    background: inherit !important;
}
</style>
