<template>
    <v-container fluid>
        <v-overlay :absolute="true" opacity="0.8" :value="carregando">
            <CarregandoLogoEmpresa :carregando="carregando" :mensagem="mensagemCarregando" />
        </v-overlay>

        <TituloModulo />

        <v-card outlined elevation="2" class="mb-4 pl-2">
            <v-card-title>
                FILTROS

                <v-spacer></v-spacer>

                <TempoExecucao
                    :inicia="inicia_tempo_execucao"
                    :tempo_total="900"
                    :minutos_execucao="tempo_total_proxima_execucao"
                />

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="green" v-bind="attrs" v-on="on">
                            mdi-help
                        </v-icon>
                    </template>
                    <span>
                        Painel é sincronizado de forma automática a cada 15 min. com a API da Automata.
                        <br />
                        <br />

                        A contagem indica o tempo restante para a próxima coleta dos dados.
                    </span>
                </v-tooltip>
            </v-card-title>

            <v-spacer></v-spacer>

            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="2">
                        <DateTimePickerV2
                            v-model="filtros.data_de"
                            :dataInicial="filtros.data_de"
                            label="Data de:"
                            data-vv-delay="1000"
                        />
                    </v-col>

                    <v-col cols="12" md="2">
                        <DateTimePickerV2
                            v-model="filtros.data_ate"
                            :dataInicial="filtros.data_ate"
                            label="Data até:"
                            data-vv-delay="1000"
                        />
                    </v-col>

                    <v-col cols="12" md="2">
                        <InputSelectV3
                            v-model="filtros.auditado"
                            rotulo="Auditados ?"
                            :itens="opcaoSimNao"
                            itemTexto="label"
                            itemValor="value"
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-chip
                            class="ma-2"
                            text-color="white"
                            :color="corSelecionada == 'green lighten-4' ? 'green darken-1' : 'grey darken-1'"
                            @click="filtraPorCor('green lighten-4')"
                        >
                            <v-icon small left>
                                mdi-thumb-up-outline
                            </v-icon>

                            <strong>Verde</strong>
                        </v-chip>

                        <v-chip
                            class="ma-2"
                            text-color="white"
                            :color="corSelecionada == 'red lighten-4' ? 'red darken-1' : 'grey darken-1'"
                            @click="filtraPorCor('red lighten-4')"
                        >
                            <v-icon small left>
                                mdi-thumb-down-outline
                            </v-icon>

                            <strong>Vermelho</strong>
                        </v-chip>

                        <v-chip
                            class="ma-2"
                            text-color="white"
                            :color="corSelecionada == 'yellow lighten-4' ? 'yellow darken-1' : 'grey darken-1'"
                            @click="filtraPorCor('yellow lighten-4')"
                        >
                            <v-icon small left>
                                mdi-alert-circle-outline
                            </v-icon>

                            <strong>Amarelo</strong>
                        </v-chip>
                    </v-col>

                    <v-col cols="4" md="12" class="mb-1">
                        <v-btn elevation="2" color="warning" @click="buscaPainelAuditoria()">
                            <v-icon dense left>
                                mdi-refresh
                            </v-icon>
                            Buscar
                        </v-btn>

                        <v-btn color="red darken-1" class="ml-2" @click="limpaFiltroTabela">
                            <v-icon dense left> mdi-filter-off </v-icon>
                            Remove Filtros
                        </v-btn>

                        <v-btn @click="exportaTabelaParaExcel()" color="grey darken-2" class="ml-2" dark>
                            <v-icon dense left>
                                mdi-file-excel-outline
                            </v-icon>
                            Gerar Excel
                        </v-btn>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn @click="confirmarAuditoria()" color="green darken-2" class="ml-2" dark>
                                    <v-icon dense left>
                                        mdi-thumb-up-outline
                                    </v-icon>
                                    Confirmar Auditoria
                                </v-btn>
                            </template>
                            <span>Confirmar Auditoria</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>

        <v-card elevation="5">
            <TabelaVuetify
                v-model="datatable.itensSelecionados"
                ref="tabela"
                :cabecalho="datatable.cabecalho"
                :itens="datatable.itens"
                :total_registros="datatable.totalRegistros"
                :por_pagina="datatable.por_pagina"
                :itens_por_pagina="[100, 200, 500, -1]"
                :carregando="datatable.carregando"
                :ordenar_direcao="true"
                :mostra_selecao="true"
                :item_class="regraPintaLinha"
                :single_expand="true"
                :mostra_expand="true"
                ordenar_por="id_automata"
                chave_primaria="id_automata"
                class="elevation-1"
            >
                <template v-slot:item.acao="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="green darken-2"
                                class="mr-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="abreModalObservacao(item.programacao_carga_rodopar, item.id_automata, item.observacao)"
                                small
                            >
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Adicionar observação</span>
                    </v-tooltip>
                </template>

                <template v-slot:item.etapa="{ item }">
                    {{ etapaDescricao(item.etapa) }}
                </template>

                <template v-slot:item.ocorrencia_finalizada="{ item }">
                    {{ ocorrenciaFinalizadaDescricao(item.ocorrencia_finalizada) }}
                </template>

                <template v-slot:item.data_pedido="{ item }">
                    {{ formataData(item.data_pedido) }}
                </template>

                <template v-slot:item.data_auditado="{ item }">
                    {{ formataData(item.data_auditado) }}
                </template>

                <template v-slot:item.data_liberado="{ item }">
                    {{ formataData(item.data_liberado) }}
                </template>

                <template v-slot:item.data_criacao="{ item }">
                    {{ formataData(item.data_criacao) }}
                </template>

                <template v-slot:item.data_ultima_alteracao="{ item }">
                    {{ formataData(item.data_ultima_alteracao) }}
                </template>

                <template v-slot:item.peso_calculado="{ item }">
                    {{ formataMoeda(item.peso_calculado) }}
                </template>

                <template v-slot:item.valor_pedagio="{ item }">
                    {{ formataMoeda(item.valor_pedagio) }}
                </template>

                <template v-slot:item.valor_frete="{ item }">
                    {{ formataMoeda(item.valor_frete) }}
                </template>

                <template v-slot:item.total_prestacao="{ item }">
                    {{ formataMoeda(item.total_prestacao) }}
                </template>

                <template v-slot:item.valor_adc="{ item }">
                    {{ formataMoeda(item.valor_adc) }}
                </template>

                <template v-slot:item.ad_valor_em="{ item }">
                    {{ formataMoeda(item.ad_valor_em) }}
                </template>

                <template v-slot:item.total_motivos="{ item }">
                    <v-chip
                        class="ma-2"
                        :color="item.total_motivos ? 'red darken-1' : 'green darken-1'"
                        text-color="white"
                        small
                    >
                        <v-icon v-if="item.total_motivos" small left>
                            mdi-alert-circle-outline
                        </v-icon>
                        <v-icon v-else small left>
                            mdi-thumb-up-outline
                        </v-icon>
                        <strong>{{ item.total_motivos }}</strong>
                    </v-chip>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                    <td
                        v-if="
                            totalObjetos(item.motivos) ||
                                totalObjetos(item.taxas_extras) ||
                                totalObjetos(item.cte_complementar) ||
                                totalObjetos(item.descricao_rateio)
                        "
                        :colspan="headers.length"
                    >
                        <v-card v-if="totalObjetos(item.motivos)" elevation="5" class="ma-5" width="1000">
                            <v-card-title>
                                MOTIVO(S)
                            </v-card-title>
                            <v-card-text>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    CAMPO
                                                </th>
                                                <th class="text-left">
                                                    VALOR PRÉ CTE
                                                </th>
                                                <th class="text-left">
                                                    VALOR CTE
                                                </th>
                                                <th class="text-left">
                                                    MOTIVO
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(itemMotivo, index) in item.motivos">
                                                <td>{{ itemMotivo.campo }}</td>
                                                <td>{{ formataMoeda(itemMotivo.valor_conferencia) }}</td>
                                                <td>{{ formataMoeda(itemMotivo.valor_cte) }}</td>
                                                <td>{{ formataMoeda(itemMotivo.conferencia_descricao) }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>

                        <v-card v-if="totalObjetos(item.taxas_extras)" elevation="5" class="ma-5" width="1000">
                            <v-card-title>
                                TAXAS EXTRAS(S)
                            </v-card-title>
                            <v-card-text>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    CÓDIGO TAXA
                                                </th>
                                                <th class="text-left">
                                                    NOME
                                                </th>
                                                <th class="text-left">
                                                    VALOR
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(itemTaxaExtra, index) in item.taxas_extras">
                                                <td>{{ itemTaxaExtra.codigo_taxa }}</td>
                                                <td>{{ itemTaxaExtra.nome }}</td>
                                                <td>{{ formataMoeda(itemTaxaExtra.valor) }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>

                        <v-card v-if="totalObjetos(item.cte_complementar)" elevation="5" class="ma-5" width="1000">
                            <v-card-title>
                                <span v-if="item.status == 'COMPLEMENTAR'">CT-e Complementar</span>
                                <span v-else>CT-e Original</span>
                            </v-card-title>
                            <v-card-text>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th
                                                    class="text-left"
                                                    v-for="(coluna, index) in Object.keys(item.cte_complementar)"
                                                    :key="`item-cte-complementar-${index}`"
                                                >
                                                    {{ cteComplementarNomeColuna(coluna) }}
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td
                                                    v-for="(coluna, index) in Object.values(item.cte_complementar)"
                                                    :key="`item-cte-complementar-body-${index}`"
                                                >
                                                    <span v-if="index == 0 || index == 1">{{ coluna }}</span>
                                                    <span v-else>{{ formataMoeda(parseFloat(coluna)) }}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>

                        <v-card v-if="totalObjetos(item.descricao_rateio)" elevation="5" class="ma-5" width="1000">
                            <v-card-title>
                                DESCRIÇÃO DO RATEIO
                            </v-card-title>
                            <v-card-text>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    IDs CTEs RODOPAR
                                                </th>
                                                <th class="text-left">
                                                    TIPO DE RATEIO
                                                </th>
                                                <th class="text-left">
                                                    CAMPOS RATEADOS
                                                </th>
                                                <th class="text-left">
                                                    VALOR PERCENTUAL
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(itemRateio, index) in item.descricao_rateio">
                                                <td>{{ itemRateio.id_ctes_rodopar }}</td>
                                                <td>{{ itemRateio.tipo_rateio }}</td>
                                                <td>{{ itemRateio.campos_rateados }}</td>
                                                <td>{{ itemRateio.descricao_percentual }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                        
                    </td>
                    <td v-else :colspan="headers.length">
                        NENHUM MOTIVO INFORMADO
                    </td>
                </template>

                <template v-slot:footer.prepend>
                    <v-row>
                        <v-col cols="12" sm="4" class="text-left">
                            <v-icon size="70" color="red lighten-3">
                                mdi-circle-small
                            </v-icon>
                            <strong>Quando há divergências</strong>
                        </v-col>
                    </v-row>
                </template>
            </TabelaVuetify>

            <div class="text-center">
                <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                    <span v-html="notificacao.mensagem"></span>

                    <template v-slot:action="{ attrs }">
                        <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                            Fechar
                        </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </v-card>

        <v-dialog v-model="modalObservacao.abre" v-if="modalObservacao.abre" persistent>
            <form-observacao
                ref="formObservacao"
                :id_programacao_carga_rodopar="modalObservacao.id_programacao_carga_rodopar"
                :id_automata="modalObservacao.id_automata"
                :observacao="modalObservacao.observacao"
                @cancelar="modalObservacao.abre = false"
                @salva="salvaObservacao"
            />
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import moment from 'moment';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { FormataMoeda, ExportaArrayObjectsParaExcel, sleep } from '@/helpers/Funcoes.js';
import { listaDescricao, descricao as descricaoEtapa } from '@/Modulo/Cte/Enum/AuditoriaEtapaEnum.js';
import AuditoriaSituacaoEnum from '@/Modulo/Cte/Enum/AuditoriaSituacaoEnum.js';
import { listaDescricao as listaDescricaoSimNao } from '@/Enum/Shared/SimNaoEnum.js';

import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import TempoExecucao from '@/components/Default/TempoExecucao.vue';
import TabelaVuetify from '@/components/Default/TabelaVuetify.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';
import DateTimePickerV2 from '@/components/Form/DateTimePickerV2.vue';
import FormObservacao from '@/Modulo/Cte/Componente/FormObservacao.vue';

export default {
    name: 'PainelAuditoria',
    components: {
        BarraNavegacaoBotaoV3,
        CarregandoLogoEmpresa,
        TituloModulo,
        TabelaVuetify,
        InputSelectV3,
        TempoExecucao,
        DateTimePickerV2,
        FormObservacao
    },
    mounted() {
        this.montaNavegacao({
            botoes: [
                {
                    id_permissao: 1,
                    nome: 'Atualizar',
                    acao: 'buscaPainelAuditoria',
                    icone: 'mdi-refresh',
                    cor: 'green darken-5',
                    form: ['TODOS']
                }
            ],
            permissoes: [
                {
                    id_permissao: 1,
                    nome: 'atualiza',
                    permitido: 1
                }
            ]
        });

        this.buscaPainelAuditoria();
    },
    watch: {
        'modalObservacao.abre': {
            handler: function(dadosAtual) {
                if (dadosAtual == false) {
                    this.modalObservacao.abre = false;
                    this.modalObservacao.id_programacao_carga_rodopar = null;
                    this.modalObservacao.id_automata = null;
                    this.modalObservacao.observacao = null;
                }
            },
            immediate: true
        }
    },
    computed: {
        ...mapGetters({
            botoesComPermissao: 'botoesComPermissao'
        }),
        podeRemoverFiltros() {
            let verdadeiroFalso = false;

            const filtros = this.$refs.tabela?.filtros ?? {};

            Object.values(filtros).forEach(function(valor) {
                if (valor || valor != '') {
                    verdadeiroFalso = true;
                }
            });

            return verdadeiroFalso;
        }
    },
    methods: {
        ...mapActions(['montaNavegacao']),
        async buscaPainelAuditoria() {
            try {
                this.validaBusca();

                this.mensagemCarregando = 'Buscando dados na API da Automata, aguarde...';

                this.datatable.carregando = true;
                this.carregando = true;

                this.datatable.itensSelecionados = [];
                this.datatable.itens = [];
                this.corSelecionada = null;

                let query = this.montaFiltros();

                query = `data_de=${this.filtros.data_de}&data_ate=${this.filtros.data_ate}&auditado=${this.filtros.auditado}&${query}`;

                await ApiService({
                    method: 'GET',
                    url: `${API_URL.erp.cte.painelAuditoria}/?${query}`,
                    mostraCarregando: false
                })
                    .then(resposta => {
                        if (resposta?.data?.data) {
                            this.preenchePainel(resposta.data.data.atributos);

                            this.datatable.carregando = false;

                            /**
                             * Carregando tem que ser aqui porque
                             * Se for no Finally ele passa 2 vezes nos dados do Datatable
                             */
                            this.carregando = false;
                        }
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        montaFiltros() {
            const filtros = this.$refs.tabela.filtros;

            return filtros
                ? Object.keys(filtros)
                      ?.map((filtro, indice) => {
                          const valor = filtros[filtro];

                          if (!valor) {
                              return;
                          }

                          return `${filtro}=${valor}`;
                      })
                      .filter(notUndefined => notUndefined !== undefined)
                      .join('&')
                : '';
        },
        preenchePainel(dados) {
            if (dados == undefined || dados.length == 0) {
                this.datatable.itens = [];
            }

            const total = dados.length;

            this.datatable.itens = dados;
            this.datatable.totalRegistros = total;

            this.calculaTempoExecucao(dados.data_criacao);
        },
        calculaTempoExecucao(data_criacao) {
            const minuto = moment(data_criacao).format('mm');

            let tempoDiferenca = 0;

            if (minuto <= 15) {
                tempoDiferenca = 15 - minuto;
            }

            if (minuto >= 15) {
                tempoDiferenca = 30 - minuto;
            }

            if (minuto >= 30) {
                tempoDiferenca = 45 - minuto;
            }

            if (minuto >= 45) {
                tempoDiferenca = 60 - minuto;
            }

            this.inicia_tempo_execucao = true;
            this.tempo_total_proxima_execucao = tempoDiferenca * 60;
        },
        etapaDescricao(etapa) {
            return descricaoEtapa(etapa);
        },
        ocorrenciaFinalizadaDescricao(ocorrencia) {
            if (ocorrencia == 'N') {
                return 'Não';
            }

            return 'Sim';
        },
        regraPintaLinha(item) {
            if (item.situacao == AuditoriaSituacaoEnum.RED) {
                return 'red lighten-4';
            }

            if (item.situacao == AuditoriaSituacaoEnum.YELLOW || item.ocorrencia_finalizada == 'N') {
                return 'yellow lighten-4';
            }

            if (item.situacao == AuditoriaSituacaoEnum.GREEN) {
                return 'green lighten-4';
            }

            return 'white';
        },
        async filtraPorCor(situacaoSelecionada) {
            if (!this.datatableItensOriginais.length) {
                this.datatableItensOriginais = this.datatable.itens;
            }

            this.mensagemCarregando = 'Filtrando por cor, aplicado apenas aos registros já carregados, aguarde...';
            this.datatable.carregando = true;
            this.carregando = true;
            this.datatable.itensSelecionados = [];

            await sleep(1000);

            this.corSelecionada = situacaoSelecionada;
            this.datatable.itens = [];

            this.datatable.itens = this.datatableItensOriginais.filter(item => {
                const cor = this.regraPintaLinha(item);

                if (cor == situacaoSelecionada) {
                    return item;
                }
            });

            this.datatable.totalRegistros = this.datatable.itens.length;

            this.datatable.carregando = false;
            this.carregando = false;
        },
        formataData(data) {
            if (!data) {
                return '';
            }

            return moment(data).format('DD/MM/YYYY');
        },
        formataMoeda(valor) {
            if (!valor) {
                return 0;
            }

            return FormataMoeda(valor, {}, true);
        },
        async confirmarAuditoria() {
            try {
                if (!this.datatable.itensSelecionados.length) {
                    this.notificacao = {
                        mensagem: 'Selecione um registro na tabela para liberar o faturamento.',
                        tipo: 'error',
                        mostra: true
                    };

                    return;
                }

                let confirmaMsg = 'Confirmar auditoria ?';
                let confirmaCor = 'green';
                let ocorrenciaFinalizada = false;
                let totalMotivos = 0;

                const idsAuditoria = this.datatable.itensSelecionados.map(item => {
                    if (item.ocorrencia_finalizada == 'N') {
                        ocorrenciaFinalizada = true;
                    }

                    if (item.total_motivos > 0) {
                        totalMotivos += item.total_motivos;
                    }

                    return {
                        id_automata: item.id_automata,
                        id_programacao_carga_rodopar: item.programacao_carga_rodopar
                    };
                });

                const programacaCargaRodoparLiberadas = idsAuditoria.map(item => {
                    return item.id_programacao_carga_rodopar;
                });

                confirmaMsg += `\r\n <span class="font-weight-bold orange--text text--darken-2">Programação de carga: ${programacaCargaRodoparLiberadas.join(
                    ', '
                )} </span>`;

                if (ocorrenciaFinalizada) {
                    confirmaMsg +=
                        '\r\n <span class="font-weight-bold red--text text--darken-2"> Atenção: Ocorrência finalizada não informada. </span>';
                    confirmaCor = 'yellow darken-2';
                }

                if (totalMotivos) {
                    confirmaMsg += `\r\n <span class="font-weight-bold red--text text--darken-2"> Atenção: Auditoria com pendência em ${totalMotivos} motivo(s). </span>`;
                    confirmaCor = 'red darken-2';
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', confirmaMsg, {
                        cor: confirmaCor
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        this.mensagemCarregando = 'Confirmando auditoria CTE na API Automata, aguarde...';

                        this.carregando = true;

                        let url = `${API_URL.erp.cte.confirmaAuditoria}`;

                        await ApiService({
                            method: 'post',
                            url: url,
                            data: {
                                auditoria: idsAuditoria
                            },
                            mostraCarregando: false
                        })
                            .then(resposta => {})
                            .catch(erro => {
                                throw new Error(erro.message);
                            });

                        this.datatable.itensSelecionados = [];

                        await this.buscaPainelAuditoria();

                        this.notificacao = {
                            mensagem: 'Auditoria confirmado.',
                            tipo: 'success',
                            mostra: true
                        };
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.carregando = false;
            }
        },
        abreModalObservacao(id_programacao_carga_rodopar, id_automata, observacao) {
            this.modalObservacao.id_programacao_carga_rodopar = id_programacao_carga_rodopar;
            this.modalObservacao.id_automata = id_automata;
            this.modalObservacao.observacao = observacao;
            this.modalObservacao.abre = true;
        },
        salvaObservacao({ id_programacao_carga_rodopar, observacao }) {
            const item = this.datatable.itens.find(item => item.programacao_carga_rodopar === id_programacao_carga_rodopar);

            if (!item) {
                this.notificacao = {
                    mensagem: 'Linha não encontrada para editar.',
                    tipo: 'error',
                    mostra: true
                };

                return;
            }

            item.observacao = observacao;

            this.modalObservacao.abre = false;
        },
        exportaTabelaParaExcel() {
            try {
                let tabela = _.cloneDeep(this.datatable.itens);

                const itensFormatados = tabela.map(item => {
                    item.etapa = descricaoEtapa(item.etapa);

                    return item;
                });

                ExportaArrayObjectsParaExcel(itensFormatados, this.datatable.cabecalho, 'painel-auditoria-cte');
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        validaBusca() {
            if (!this.filtros.data_de) {
                throw new Error('Preencha o filtro data de');
            }

            if (!this.filtros.data_ate) {
                throw new Error('Preencha o filtro data ate');
            }

            if (!this.filtros.auditado) {
                throw new Error('Preencha o filtro auditado');
            }
        },
        totalObjetos(itens) {
            return itens && Object.keys(itens).length;
        },
        cteComplementarNomeColuna(coluna) {
            const colunas = {
                id_cte_rodopar: 'CT-e Original',
                id_cte_complementar: 'CT-e Complementar',
                vlr_adic: 'VLR. Adicional',
                aliq_icms: 'Aliq. ICMS',
                tot_prestacao: 'Total Prestação',
                base_icms: 'Base ICMS',
                vrl_icms: 'Valor ICMS'
            };

            if (coluna in colunas) {
                return colunas[coluna];
            }

            return coluna.toUpperCase();
        },
        descricaoRateioNomeColuna(coluna) {
            const colunas = {
                numero_log: 'Número Log',
                tipo_rateio: 'Tipo de Rateio',
                campos_ratedos: 'Campos Rateados',
                descricao_percentual: 'Descrição percentual',
            };

            if (coluna in colunas) {
                return colunas[coluna];
            }

            return coluna.toUpperCase();
        },
        async limpaFiltroTabela() {
            this.mensagemCarregando = 'Limpando filtros, aguarde...';
            this.datatable.carregando = true;
            this.carregando = true;
            this.datatable.itensSelecionados = [];

            await sleep(1000);

            this.corSelecionada = null;
            this.datatable.itens = this.datatableItensOriginais;
            this.datatableItensOriginais = [];
            this.$refs.tabela.limpaFiltros();

            this.datatable.totalRegistros = this.datatable.itens.length;

            this.datatable.carregando = false;
            this.carregando = false;
        }
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            carregando: false,
            mensagemCarregando: 'Buscando dados na API da Automata, aguarde...',
            modalObservacao: {
                abre: false,
                id_programacao_carga_rodopar: null,
                id_automata: null,
                observacao: null
            },
            filtros: {
                data_de: moment()
                    .startOf('month')
                    .subtract(1, 'months')
                    .format('YYYY-MM-DD'),
                data_ate: moment().format('YYYY-MM-DD'),
                auditado: 'N'
            },
            opcaoSimNao: listaDescricaoSimNao(),
            inicia_tempo_execucao: false,
            tempo_total_proxima_execucao: 0,
            datatableItensOriginais: [],
            corSelecionada: null,
            datatable: {
                itensSelecionados: [],
                carregando: false,
                itens: [],
                totalRegistros: 0,
                ultima_pagina: 0,
                pagina_atual: 1,
                por_pagina: 500,
                ordenarPor: 'cte_data_criacao',
                ordenarDirecao: true,
                cabecalho: [
                    { text: '', value: 'data-table-expand', width: '2%', showFilter: false },
                    {
                        text: 'AÇÃO',
                        value: 'acao',
                        align: 'start',
                        width: '100',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'TOTAL MOTIVOS',
                        value: 'total_motivos',
                        width: '100',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'ETAPA',
                        value: 'etapa',
                        width: '150',
                        type: 'select',
                        multiple: false,
                        filterData: listaDescricao(),
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'STATUS',
                        value: 'status_descricao',
                        width: '180',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'ID AUTOMATA',
                        value: 'id_automata',
                        width: '190',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'CTE',
                        value: 'numero_cte',
                        width: '150',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'PROGRAMAÇÃO CARGA',
                        value: 'programacao_carga_rodopar',
                        width: '225',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'PROGRAMAÇÃO VEÍCULO',
                        value: 'programacao_veiculo_rodopar',
                        width: '225',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'O.S.T',
                        value: 'id_ordem_servico_transporte_rodopar',
                        width: '180',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'OCORRÊNCIA FINALIZADA',
                        value: 'ocorrencia_finalizada',
                        width: '170',
                        type: 'select',
                        multiple: false,
                        filterData: listaDescricaoSimNao(),
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'CLIENTE CNPJ',
                        value: 'cnpj_cliente',
                        width: '180',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'CÓD. PAGADOR',
                        value: 'codigo_pagador',
                        width: '160',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'RAZÃO SOCIAL',
                        value: 'razao_social',
                        width: '230',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'TIPO OPERAÇÃO',
                        value: 'tipo_operacao_descricao',
                        width: '180',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'OBSERVAÇÃO',
                        value: 'observacao',
                        width: '300',
                        type: 'input',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'PLACA CAVALO',
                        value: 'placa_cavalo',
                        width: '180',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'PLACA CARRETA',
                        value: 'placa_carreta',
                        width: '185',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'TOTAL EIXOS',
                        value: 'numero_eixos_total',
                        width: '170',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'NÚMERO DI',
                        value: 'numero_di',
                        width: '160',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'NÚMERO DTA E',
                        value: 'numero_dta_e',
                        width: '175',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'REFERÊNCIA CLIENTE',
                        value: 'referencia_cliente',
                        width: '195',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'DATA PEDIDO',
                        value: 'data_pedido',
                        width: '170',
                        type: 'date',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'DATA AUDITADO',
                        value: 'data_auditado',
                        width: '170',
                        type: 'date',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'DATA LIBERADO',
                        value: 'data_liberado',
                        width: '170',
                        type: 'date',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'TERMINAL COLETA',
                        value: 'terminal_coleta',
                        width: '210',
                        type: 'input',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'TERMINAL ENTREGA',
                        value: 'terminal_entrega',
                        width: '210',
                        type: 'input',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'PESO CALCULADO',
                        value: 'peso_calculado',
                        width: '200',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'VALOR FRETE',
                        value: 'valor_frete',
                        width: '180',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'TOTAL PRESTAÇÃO',
                        value: 'total_prestacao',
                        width: '180',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'VALOR PEDÁGIO',
                        value: 'valor_pedagio',
                        width: '180',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'VALOR ADICIONAL',
                        value: 'valor_adc',
                        width: '200',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'VALOR ADICIONAL EM',
                        value: 'ad_valor_em',
                        width: '215',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'TOTAL PRESTAÇÃO',
                        value: 'total_prestacao',
                        width: '180',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'DATA CRIAÇÃO',
                        value: 'data_criacao',
                        width: '170',
                        type: 'date',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'DATA ÚLTIMA ALTERAÇÃO',
                        value: 'data_ultima_alteracao',
                        width: '170',
                        type: 'date',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'USUÁRIO ALTERAÇÃO PROG. CARGA',
                        value: 'usuario_alteracao_programacao_carga',
                        width: '180',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'USUÁRIO ALTERAÇÃO CT-e',
                        value: 'usuario_alteracao_cte',
                        width: '170',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'USUÁRIO AUDITORIA',
                        value: 'usuario_tnsys_auditoria',
                        width: '190',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'UNIDADE',
                        value: 'unidade_rodopar',
                        width: '150',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    }
                ]
            }
        };
    }
};
</script>
