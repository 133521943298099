<template>
    <v-card elevation="1">
        <v-card-title class="">
            <span class="text-h5">Aguardando Resposta</span>

            <v-spacer></v-spacer>

            <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item link>
                        <v-list-item-title @click="buscaDashboard">
                            RECARREGAR
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-card-title>

        <v-card-text>
            <v-chart
                :option="opcoes"
                :init-options="initOptions"
                :loading="barLoading"
                :loadingOptions="barLoadingOptions"
                ref="avaliacaoDesempenhoAguardandoFinalizarPorSetor"
                style="width: 100%; height: 200px;"
            />
        </v-card-text>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-card>
</template>

<script>
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import VChart from 'vue-echarts';

export default {
    name: 'AvaliacaoDesempenhoPorSetor',
    components: {
        VChart
    },
    async mounted() {
        this.buscaDashboard();
    },
    computed: {},
    watch: {},
    methods: {
        adicionaDadosAoGrafico(dados) {
            let valores = [];
            let legendas = [];

            dados.forEach(function(item) {
                valores.push({ value: item.dados.valor, name: item.dados.nome });
                legendas.push(item.legenda);
            });

            this.opcoes.title.show = valores.length ? false : true;
            this.opcoes.legend.data = legendas;
            this.opcoes.series[0].data = valores;
        },
        async buscaDashboard() {
            this.barLoading = true;

            await ApiService({
                method: 'get',
                url: `${API_URL.erp.dashboard.avaliacaoDesempenhoAguardandoFinalizarPorSetor}`,
                data: {},
                mostraCarregando: false
            })
                .then(resposta => {
                    this.adicionaDadosAoGrafico(resposta.data);

                    this.barLoading = false;
                })
                .catch(erro => {
                    this.barLoading = false;

                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        }
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            initOptions: {
                renderer: 'canvas'
            },
            barLoading: true,
            barLoadingOptions: {
                text: 'Carregando, aguarde...',
                color: '#EB7403',
                textColor: '#000',
                maskColor: 'rgba(255, 255, 255, 0.8)',
                zlevel: 0,
                // Font size. Available since `v4.8.0`.
                fontSize: 12,
                // Show an animated "spinner" or not. Available since `v4.8.0`.
                showSpinner: true,
                // Radius of the "spinner". Available since `v4.8.0`.
                spinnerRadius: 10,
                // Line width of the "spinner". Available since `v4.8.0`.
                lineWidth: 5,
                // Font thick weight. Available since `v5.0.1`.
                fontWeight: 'normal',
                // Font style. Available since `v5.0.1`.
                fontStyle: 'normal',
                // Font family. Available since `v5.0.1`.
                fontFamily: 'sans-serif'
            },
            opcoes: {
                textStyle: {
                    fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                    top: 'top',
                    data: []
                },
                title: {
                    show: false,
                    textStyle: {
                        color: 'grey',
                        fontSize: 20
                    },
                    text: 'Sem dado disponível',
                    left: 'center',
                    top: 'center'
                },
                series: [
                    {
                        name: 'Aguardando Resposta',
                        type: 'pie',
                        radius: '75%',
                        center: ['50%', '50%'],
                        data: this.itens2,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        };
    }
};
</script>

<style></style>
