<template>
    <v-form ref="form">
        <v-card-text>
            <v-row>
                <v-col cols="12" md="3">
                    <v-text-field
                        dense
                        v-model="contato.id_parceiro_contato"
                        label="ID Contato"
                        data-vv-delay="1000"
                        disabled
                        outlined
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                        dense
                        v-model="contato.tipo_contato"
                        :items="itemsTipoContato"
                        label="Tipo do Contato *"
                        item-text="label"
                        item-value="value"
                        clearable
                        outlined
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        dense
                        v-model="contato.nome"
                        label="Nome *"
                        data-vv-delay="1000"
                        :rules="regraNome"
                        counter="100"
                        clearable
                        outlined
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        dense
                        v-model="contato.email"
                        label="Email"
                        data-vv-delay="1000"
                        counter="100"
                        :rules="regraEmail"
                        clearable
                        outlined
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        dense
                        v-model="contato.telefone_celular"
                        label="Celular"
                        data-vv-delay="1000"
                        counter="15"
                        v-maska="telefoneCelularMask"
                        clearable
                        outlined
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        dense
                        v-model="contato.telefone_fixo"
                        label="Telefone Fixo"
                        data-vv-delay="1000"
                        counter="14"
                        v-maska="telefoneFixoMask"
                        clearable
                        outlined
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        dense
                        v-model="contato.ramal"
                        label="Ramal"
                        data-vv-delay="1000"
                        v-maska="'##########'"
                        :rules="regraRamal"
                        clearable
                        outlined
                    />
                </v-col>

                <v-col cols="12" sm="12">
                    <v-textarea
                        v-model="contato.observacao"
                        label="Observação"
                        data-vv-delay="1000"
                        :rules="regraObservacao"
                        clearable
                        counter="500"
                        outlined
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-form>
</template>

<script>
import { mask } from 'maska';

export default {
    name: 'ContatoForm',
    props: {
        itemsTipoContato: {
            type: Array,
            default() {
                return [];
            }
        },
        contatoData: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    created() {
        this.preencheDados(this.contatoData);
    },
    watch: {
        contatoData: {
            handler: function(dadosAtual) {
                this.preencheDados(dadosAtual);
            }
        }
    },
    data() {
        return {
            telefoneCelularMask: '(##) #####-####',
            telefoneFixoMask: '(##) ####-####',
            regraObservacao: [v => _.isEmpty(v) || v.length <= 500 || 'Observação deve ter até 500 caracteres'],
            regraNome: [
                v => !!v || 'Nome é obrigatório',
                v => (v && v?.length > 3 && v?.length <= 100) || 'Nome deve ter entre 3 e 100 caracteres'
            ],
            regraEmail: [v => _.isEmpty(v) || /.+@.+\..+/.test(v) || 'E-mail inválido'],
            regraRamal: [v => _.isEmpty(v) || v.length <= 10 || 'Ramal deve ter até 10 caracteres'],
            contato: {
                id_parceiro_contato: '',
                id_parceiro: '',
                tipo_contato: 1,
                nome: '',
                email: '',
                telefone_celular: '',
                telefone_fixo: '',
                ramal: '',
                envia_xml_pdf: false,
                observacao: ''
            }
        };
    },
    methods: {
        preparaDados() {
            const contato = { ...this.contato };

            contato.ramal = parseInt(contato.ramal);

            return contato;
        },
        preencheDados(contato) {
            this.contato = { ...contato };

            this.contato.telefone_celular = contato.telefone_celular
                ? mask(contato.telefone_celular, this.telefoneCelularMask)
                : null;
            this.contato.telefone_fixo = contato.telefone_fixo ? mask(contato.telefone_fixo, this.telefoneFixoMask) : null;
        }
    }
};
</script>

<style scoped></style>
