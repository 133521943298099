<template>
    <div>
        <TituloModulo />

        <AlertaMensagem dense style="width: 100%" />

        <v-overlay :absolute="true" :value="carregando.mostraCarregando">
            <CarregandoLogoEmpresa :mensagem="mensagemCarregando" />
        </v-overlay>

        <BarraNavegacaoBotaoV3
            v-if="!carregando.mostraCarregando && permissao"
            :botoes="botoesComPermissao(whatsappMarketingSituacao)"
        />

        <v-card>
            <v-tabs v-model="tabComponente" background-color="orange accent-2" dense centered dark icons-and-text>
                <v-tabs-slider></v-tabs-slider>

                <v-tab>
                    <v-badge
                        :content="tabDadosTotalErros > 0 ? tabDadosTotalErros : ''"
                        :color="tabDadosTotalErros > 0 ? 'red accent-4' : ''"
                        overlap
                    >
                        <v-icon>mdi-file-sign</v-icon>
                        <v-spacer></v-spacer>
                        Dados
                    </v-badge>
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabComponente">
                <v-form ref="formWhatsappMarketing">
                    <v-tab-item eager>
                        <v-container fluid>
                            <form-dados
                                :id_whatsapp_marketing="form.id_whatsapp_marketing"
                                :id_whatsapp_numero_atendimento="form.id_whatsapp_numero_atendimento"
                                :id_whatsapp_mensagem_template="form.id_whatsapp_mensagem_template"
                                :situacao="form.situacao"
                                :descricao="form.descricao"
                                :template="form.template"
                                :observacao="form.observacao"
                                :data_hora_envio="form.data_hora_envio"
                                :contatos="form.contatos"
                                ref="formDados"
                            />
                        </v-container>
                    </v-tab-item>
                </v-form>
            </v-tabs-items>
        </v-card>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import FormMixins from '@/Modulo/WhatsappMarketing/Dominio/Servico/FormMixins.js';

import BuscaModal from '@/components/Default/BuscaModal.vue';
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';

import FormDados from './FormDados.vue';

export default {
    components: {
        AlertaMensagem,
        BuscaModal,
        TituloModulo,
        BarraNavegacaoBotaoV3,
        CarregandoLogoEmpresa,
        FormDados
    },
    mixins: [FormMixins]
};
</script>
