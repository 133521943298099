<template>
    <div>
        <TituloModulo />

        <v-overlay :absolute="true" :value="carregando.mostraCarregando">
            <CarregandoLogoEmpresa :mensagem="mensagemCarregando" />
        </v-overlay>

        <AlertaMensagem dense style="width: 100%" />

        <v-row v-if="permissao">
            <v-col cols="12"></v-col>

            <v-col cols="4">
                <card-info
                    iconeBoxCor="blue"
                    icone="mdi-file-sign"
                    titulo="Processos"
                    :value="totais.processo"
                    :carregando="false"
                    rodape-texto="Quantidade de processos"
                />
            </v-col>

            <v-col cols="4">
                <card-info
                    iconeBoxCor="red"
                    icone="mdi-card-account-details-outline"
                    titulo="Motoristas"
                    :value="totais.motorista_nao_identificado"
                    :carregando="false"
                    rodape-texto="Com motorista não identificado"
                />
            </v-col>

            <v-col cols="4">
                <card-info
                    iconeBoxCor="green"
                    icone="mdi-check-decagram-outline"
                    titulo="Auditar"
                    :value="totais.auditoria_pronta"
                    :carregando="false"
                    rodape-texto="Prontos para auditoria"
                />
            </v-col>

            <v-col cols="12" v-for="item in auditoriaProcesso" :key="`devolucao-vazio-${item.id_devolucao_vazio}`">
                <v-card elevation="2" :color="!item.imagem.length ? 'red lighten-4 pa-2' : 'grey lighten-3 pa-2'">
                    <v-card-title>
                        <v-row>
                            <v-col cols="10">
                                {{ item.cliente_razao_social }}
                            </v-col>

                            <v-col cols="12">
                                <v-badge color="white" transition="slide-x-transition" offset-x="15" offset-y="15">
                                    <template v-slot:badge>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon color="green" v-bind="attrs" v-on="on" small> mdi-help </v-icon>
                                            </template>
                                            <span>ID Devolução Vazio</span>
                                        </v-tooltip>
                                    </template>

                                    <v-chip class="ma-2" color="pink" label text-color="white">
                                        <v-icon class="pa-1" left> mdi-train-car-container </v-icon>
                                        {{ item.id_devolucao_vazio }}
                                    </v-chip>
                                </v-badge>

                                <v-badge color="white" transition="slide-x-transition" offset-x="15" offset-y="15">
                                    <template v-slot:badge>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon color="green" v-bind="attrs" v-on="on" small> mdi-help </v-icon>
                                            </template>
                                            <span>Programação de Carga</span>
                                        </v-tooltip>
                                    </template>

                                    <v-chip class="ma-2" color="cyan" label text-color="white">
                                        <v-icon lass="pa-1" left> mdi-truck </v-icon>
                                        {{ item.id_programacao_carga_rodopar }}
                                    </v-chip>
                                </v-badge>

                                <v-badge color="white" transition="slide-x-transition" offset-x="15" offset-y="15">
                                    <template v-slot:badge>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon color="green" v-bind="attrs" v-on="on" small> mdi-help </v-icon>
                                            </template>
                                            <span>Placa</span>
                                        </v-tooltip>
                                    </template>

                                    <v-chip class="ma-2" color="orange darken-1" label text-color="white">
                                        <v-icon lass="pa-1" left> mdi-car-estate </v-icon>
                                        {{ item.placa_ocr }}
                                    </v-chip>
                                </v-badge>

                                <v-badge color="white" transition="slide-x-transition" offset-x="15" offset-y="15">
                                    <template v-slot:badge>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon color="green" v-bind="attrs" v-on="on" small> mdi-help </v-icon>
                                            </template>
                                            <span>Quantidade de imagens</span>
                                        </v-tooltip>
                                    </template>

                                    <v-chip class="ma-2" color="deep-purple darken-1" label text-color="white">
                                        <v-icon lass="pa-1" left> mdi-image-multiple </v-icon>
                                        {{ item.imagem.length }}
                                    </v-chip>
                                </v-badge>

                                <v-badge color="white" transition="slide-x-transition" offset-x="15" offset-y="15">
                                    <template v-slot:badge>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon color="green" v-bind="attrs" v-on="on" small> mdi-help </v-icon>
                                            </template>
                                            <span>
                                                Motorista responsável pelo envio da imagem. <br />
                                                Ao auditar a baixa, será considerado como motorista que realizou a devolução no
                                                terminal.
                                            </span>
                                        </v-tooltip>
                                    </template>

                                    <v-chip class="ma-2" color="blue darken-4" label text-color="white">
                                        <v-icon lass="pa-1" left> mdi-card-account-details-outline </v-icon>
                                        {{ item.motorista_terminal_entrega || 'MOTORISTA NÃO IDENTIFICADO' }}
                                    </v-chip>
                                </v-badge>

                                <v-badge color="white" transition="slide-x-transition" offset-x="15" offset-y="15">
                                    <template v-slot:badge>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon color="green" v-bind="attrs" v-on="on" small> mdi-help </v-icon>
                                            </template>
                                            <span> Nome de perfil do Whatsapp que enviou a imagem </span>
                                        </v-tooltip>
                                    </template>

                                    <v-chip class="ma-2" color="green darken-1" label text-color="white">
                                        <v-icon lass="pa-1" left> mdi-whatsapp </v-icon>
                                        {{ item.whatsapp_nome_perfil }}
                                    </v-chip>
                                </v-badge>

                                <v-badge
                                    v-if="item.status"
                                    color="white"
                                    transition="slide-x-transition"
                                    offset-x="15"
                                    offset-y="15"
                                >
                                    <template v-slot:badge>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon color="green" v-bind="attrs" v-on="on" small> mdi-help </v-icon>
                                            </template>
                                            <span> Inconsistência na leitura da imagem </span>
                                        </v-tooltip>
                                    </template>

                                    <v-chip class="ma-2" color="red darken-1" label text-color="white">
                                        <v-icon lass="pa-1" left> mdi-comment-processing-outline </v-icon>
                                        {{ item.status }}
                                    </v-chip>
                                </v-badge>
                            </v-col>
                        </v-row>

                        <v-btn
                            class="mx-2"
                            color="green"
                            @click="auditarBaixa(item.id_devolucao_vazio)"
                            :disabled="!item.imagem.length"
                            dark
                        >
                            <v-icon dark> mdi-check-decagram-outline </v-icon>

                            <span v-if="item.imagem.length"> Auditar baixa </span>

                            <span v-else> Sem imagem </span>
                        </v-btn>
                    </v-card-title>

                    <v-row>
                        <v-col cols="6">
                            <v-card class="overflow-auto" height="350">
                                <v-carousel
                                    v-if="item.imagem.length"
                                    hide-delimiter-background
                                    show-arrows-on-hover
                                    height="1000"
                                >
                                    <v-carousel-item v-for="(imagem, i) in item.imagem" :key="`carousel-${i}`">
                                        <ImagemComAutenticacao
                                            :url_arquivo="imagem.url"
                                            :mime_type="imagem.mime_type"
                                            @abre-imagem="abreImagem"
                                        />
                                    </v-carousel-item>
                                </v-carousel>

                                <v-img v-else src="@/assets/image/padrao/sem_imagem.gif" />
                            </v-card>
                        </v-col>

                        <v-col cols="6">
                            <v-row>
                                <v-col cols="6">
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th colspan="2" class="text-center">
                                                        Número Container

                                                        <v-icon
                                                            v-if="
                                                                comparaContainer(
                                                                    item.numero_container_tnsys,
                                                                    item.numero_container_ocr
                                                                )
                                                            "
                                                            class="ml-1"
                                                            color="red"
                                                            dark
                                                        >
                                                            mdi-thumb-down-outline
                                                        </v-icon>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <thead>
                                                <tr>
                                                    <th class="text-left">TNSYS</th>
                                                    <th class="text-left">OCR</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>{{ item.numero_container_tnsys }}</td>
                                                    <td>{{ item.numero_container_ocr }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>

                                <v-col cols="6">
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th colspan="2" class="text-center">
                                                        Data Devolução

                                                        <v-icon
                                                            v-if="
                                                                comparaDataDevolucao(
                                                                    item.data_devolucao_tnsys,
                                                                    item.data_devolucao_ocr
                                                                )
                                                            "
                                                            class="ml-1"
                                                            color="red"
                                                            dark
                                                        >
                                                            mdi-thumb-down-outline
                                                        </v-icon>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">TNSYS</th>
                                                    <th class="text-left">OCR</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{ formataData(item.data_devolucao_tnsys) }}</td>
                                                    <td>{{ formataData(item.data_devolucao_ocr) }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>

                                <v-col cols="6">
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th colspan="2" class="text-center">
                                                        Parceiro Baixa

                                                        <v-icon
                                                            v-if="
                                                                comparaParceiroBaixa(
                                                                    item.parceiro_baixa_tnsys,
                                                                    item.parceiro_baixa_ocr
                                                                )
                                                            "
                                                            class="ml-1"
                                                            color="red"
                                                            dark
                                                        >
                                                            mdi-thumb-down-outline
                                                        </v-icon>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">TNSYS</th>
                                                    <th class="text-left">OCR</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{ item.parceiro_baixa_tnsys }}</td>
                                                    <td>{{ item.parceiro_baixa_ocr }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>

                                <v-col cols="6">
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th colspan="2" class="text-center">
                                                        Terminal Devolução

                                                        <v-icon
                                                            v-if="
                                                                comparaTerminalDevolucao(
                                                                    item.terminal_devolucao_tnsys,
                                                                    item.terminal_devolucao_ocr
                                                                )
                                                            "
                                                            class="ml-1"
                                                            color="red"
                                                            dark
                                                        >
                                                            mdi-thumb-down-outline
                                                        </v-icon>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">TNSYS</th>
                                                    <th class="text-left">OCR</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{ item.terminal_devolucao_tnsys }}</td>
                                                    <td>{{ item.terminal_devolucao_ocr }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <v-col cols="12">
                <v-btn
                    v-show="true"
                    @click="atualizaDevolucaoVazioItens()"
                    class="mb-15"
                    color="green lighten-1"
                    dark
                    absolute
                    right
                    bottom
                    fixed
                    fab
                >
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="modalAuditarProcesso.abre" persistent>
            <AuditarBaixa
                v-if="modalAuditarProcesso.abre"
                :id_devolucao_vazio="modalAuditarProcesso.id_devolucao_vazio"
                @cancelar="modalAuditoriaProcessoCancelar"
                @auditar="modalAuditoriaProcessoAuditar"
            />
        </v-dialog>

        <ModalImagem v-if="modalImagem.abre" :abre="modalImagem.abre" :imagem="modalImagem.imagem" @fecha="fechaImagem" />

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false"> Fechar </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { Alerta, sleep } from '@/helpers/Funcoes.js';

import moment from 'moment';

import SituacaoForm from '@/Enum/DevolucaoVazio/SituacaoForm.js';

import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import CardInfo from '@/components/Default/CardInfo.vue';
import ModalImagem from '@/Modulo/Shared/Componente/ModalImagem.vue';

import ImagemComAutenticacao from '@/Modulo/Shared/Componente/ImagemComAutenticacao.vue';

import AuditarBaixa from '@/views/DevolucaoVazio/AuditarBaixa.vue';

export default {
    name: 'Grid',
    components: {
        AlertaMensagem,
        CarregandoLogoEmpresa,
        TituloModulo,
        ImagemComAutenticacao,
        AuditarBaixa,
        CardInfo,
        ModalImagem,
    },
    async mounted() {
        this.mostraCarregando(true);

        await this.dadosPaginaInicial();

        this.devolucaoVazioSituacao = SituacaoForm.GRID;

        this.mostraCarregando(false);

        if (this.$route.params.mensagem) {
            Alerta([this.$route.params.mensagem], 'success');
        }
    },
    computed: {
        ...mapState(['carregando']),
    },
    watch: {
        auditoriaProcesso: {
            handler() {
                this.calculaTotais();
            },
            immediate: true,
        },
    },
    methods: {
        ...mapActions(['montaNavegacao', 'mostraCarregando']),
        async buscaPermissoes() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.devolucaovazio.permissao}`,
                data: {},
                mostraCarregando: false,
            })
                .then(async (resposta) => {
                    this.devolucaoVazioSituacao = SituacaoForm.GRID;
                })
                .catch((error) => {
                    throw new Error(error);
                });
        },
        async dadosPaginaInicial() {
            try {
                await ApiService({
                    method: 'get',
                    url: `${API_URL.erp.devolucaovazio.dadosPaginaInicial}`,
                    data: {},
                    mostraCarregando: false,
                })
                    .then(async (resposta) => {})
                    .catch((erro) => {
                        throw new Error(erro.message);
                    });

                await this.buscaPermissoes();
                await this.buscaDevolucaoVazioItens();

                this.permissao = true;
            } catch (erro) {
                Alerta([erro.message], 'error');

                this.permissao = false;
            }
        },
        async buscaDevolucaoVazioItens() {
            this.auditoriaProcesso = [];

            await ApiService({
                method: 'get',
                url: `${API_URL.erp.devolucaovazio.auditoriaProcesso}`,
                data: {},
                mostraCarregando: false,
            })
                .then((resposta) => {
                    this.preencheDados(resposta.data.data.atributos);
                })
                .catch((erro) => {
                    throw new Error(erro.message);
                });
        },
        async atualizaDevolucaoVazioItens() {
            try {
                this.mostraCarregando(true);

                await this.buscaDevolucaoVazioItens();
            } catch (erro) {
                Alerta([erro.message], 'error');
            } finally {
                this.mostraCarregando(false);
            }
        },
        preencheDados(dados) {
            const processos = dados.map((item) => {
                const imagens =
                    item.imagem?.map((imagem) => {
                        return {
                            id_whatsapp_mensagem_media: imagem.id_whatsapp_mensagem_media,
                            url: imagem.url,
                            mime_type: imagem.mime_type,
                        };
                    }) ?? [];

                return {
                    id_devolucao_vazio: item.id_devolucao_vazio,
                    id_programacao_carga_rodopar: item.id_programacao_carga_rodopar,
                    cliente_razao_social: item.cliente_razao_social,
                    numero_container_tnsys: item.numero_container_tnsys,
                    numero_container_ocr: item.numero_container_ocr,
                    data_devolucao_tnsys: item.data_devolucao_tnsys,
                    data_devolucao_ocr: item.data_devolucao_ocr,
                    parceiro_baixa_tnsys: item.parceiro_baixa_tnsys,
                    parceiro_baixa_ocr: item.parceiro_baixa_ocr,
                    terminal_devolucao_tnsys: item.terminal_devolucao_tnsys,
                    terminal_devolucao_ocr: item.terminal_devolucao_ocr,
                    placa_ocr: item.placa_ocr,
                    id_motorista_terminal_entrega: item.id_motorista_terminal_entrega,
                    motorista_terminal_entrega: item.motorista_terminal_entrega,
                    whatsapp_nome_perfil: item.whatsapp_nome_perfil,
                    status: item.status,
                    imagem: imagens,
                };
            });

            this.auditoriaProcesso = processos.sort(function (a, b) {
                if (a.imagem === null && b.imagem !== null) return 1;
                if (a.imagem !== null && b.imagem === null) return -1;
                if (a.imagem === null && b.imagem === null) return 0;

                if (a.imagem < b.imagem) return 1;
                if (a.imagem > b.imagem) return -1;

                const valorA = a.data_devolucao_tnsys;
                const valorB = b.data_devolucao_tnsys;

                if (valorA < valorB) {
                    return -1;
                } else {
                    return true;
                }
            });
        },
        formataData(data) {
            if (!data) {
                return '';
            }

            return moment(data).format('DD/MM/YYYY HH:mm');
        },
        async auditarBaixa(id_devolucao_vazio) {
            try {
                const processo = this.auditoriaProcesso.find(
                    (auditoriaProcesso) => auditoriaProcesso.id_devolucao_vazio === id_devolucao_vazio
                );

                if (!processo) {
                    throw new Error(`Devolução Vazio ${id_devolucao_vazio} não encontrado`);
                }

                if (!processo.id_motorista_terminal_entrega) {
                    this.modalAuditarProcesso.abre = true;
                    this.modalAuditarProcesso.id_devolucao_vazio = id_devolucao_vazio;

                    this.notificacao = {
                        mensagem: `Devolução vazio ID <strong>${id_devolucao_vazio}</strong> sem motorista de entrega, obrigatório preenchimento`,
                        tipo: 'error',
                        mostra: true,
                    };

                    return;
                }

                const msg = `Confirmar a auditoria do ID Devolução Vazio - <strong>${id_devolucao_vazio}</strong> ?
                    \r\n <span class="font-weight-bold text--darken-2"> Programação Carga Rodopar: ${processo.id_programacao_carga_rodopar} </span>
                    \r\n <span class="font-weight-bold text--darken-2"> Motorista: ${processo.id_motorista_terminal_entrega} - ${processo.motorista_terminal_entrega}</span>
                    \r\n <span class="font-weight-bold text--darken-2"> Número Container: ${processo.numero_container_tnsys} </span>
                    \r\n <span class="font-weight-bold text--darken-2"> Terminal Devolução: ${processo.terminal_devolucao_tnsys} </span>
                    `;

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Auditar', msg, {
                        cor: 'green',
                    })
                    .then(async (confirma) => {
                        if (!confirma) {
                            return false;
                        }

                        this.mensagemCarregando = 'Aguarde, auditando processo...';

                        this.mostraCarregando(true);

                        const imagens = processo.imagem.map((item) => {
                            return { id_whatsapp_mensagem_media: item.id_whatsapp_mensagem_media };
                        });

                        let conteudoFormulario = {
                            id_motorista_terminal_entrega: processo.id_motorista_terminal_entrega,
                            imagem: imagens,
                        };

                        const url = `${API_URL.erp.devolucaovazio.auditarProcesso}`.replace(
                            '{id_devolucao_vazio}',
                            id_devolucao_vazio
                        );

                        let urlConfig = {
                            method: 'POST',
                            url: url,
                            data: conteudoFormulario,
                            mostraCarregando: false,
                        };

                        await ApiService(urlConfig)
                            .then((resposta) => {
                                this.notificacao = {
                                    mensagem: `Processo com Devolução vazio ID <strong>${id_devolucao_vazio}</strong> auditado`,
                                    tipo: 'success',
                                    mostra: true,
                                };

                                this.atualizaDevolucaoVazioItens();
                            })
                            .catch((erro) => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            } finally {
                this.mostraCarregando(false);
            }
        },
        modalAuditoriaProcessoCancelar() {
            this.modalAuditarProcesso.id_devolucao_vazio = null;
            this.modalAuditarProcesso.abre = false;
        },
        async modalAuditoriaProcessoAuditar({ id_devolucao_vazio, id_motorista, nome_motorista }) {
            try {
                const processo = this.auditoriaProcesso.find(
                    (auditoriaProcesso) => auditoriaProcesso.id_devolucao_vazio === id_devolucao_vazio
                );

                if (!processo) {
                    throw new Error(`Devolução Vazio ${id_devolucao_vazio} não encontrado`);
                }

                processo.id_motorista_terminal_entrega = id_motorista;
                processo.motorista_terminal_entrega = nome_motorista;

                this.modalAuditarProcesso.abre = false;

                this.auditarBaixa(id_devolucao_vazio);
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            } finally {
                this.mostraCarregando(false);
            }
        },
        comparaContainer(numero_container_tnsys, numero_container_ocr) {
            if (numero_container_tnsys !== numero_container_ocr) {
                return true;
            }

            return false;
        },
        comparaDataDevolucao(data_devolucao_tnsys, data_devolucao_ocr) {
            const dataTnsys = moment(data_devolucao_tnsys).format('YYYY/MM/DD');
            const dataOcr = moment(data_devolucao_ocr).format('YYYY/MM/DD');

            if (dataTnsys !== dataOcr) {
                return true;
            }

            return false;
        },
        comparaParceiroBaixa(parceiro_baixa_tnsys, parceiro_baixa_ocr) {
            if (!!parceiro_baixa_tnsys && parceiro_baixa_tnsys !== parceiro_baixa_ocr) {
                return true;
            }

            return false;
        },
        comparaTerminalDevolucao(terminal_devolucao_tnsys, terminal_devolucao_ocr) {
            if (terminal_devolucao_tnsys !== terminal_devolucao_ocr) {
                return true;
            }

            return false;
        },
        calculaTotais() {
            this.zeraTotais();

            this.auditoriaProcesso.map((processo) => {
                this.totais.processo += 1;

                if (!processo.id_motorista_terminal_entrega) {
                    this.totais.motorista_nao_identificado += 1;
                }

                if (processo.imagem?.length) {
                    this.totais.auditoria_pronta += 1;
                }
            });
        },
        zeraTotais() {
            this.totais.processo = 0;
            this.totais.motorista_nao_identificado = 0;
            this.totais.auditoria_pronta = 0;
        },
        abreImagem({ imagem }) {
            this.modalImagem.imagem = imagem;
            this.modalImagem.abre = true;
        },
        fechaImagem() {
            this.modalImagem.abre = false;
            this.modalImagem.imagem = null;
        },
    },
    data() {
        return {
            permissao: false,
            devolucaoVazioSituacao: null,
            mensagemCarregando: 'Buscando, aguarde...',
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success',
            },
            modalAuditarProcesso: {
                abre: false,
                id_devolucao_vazio: null,
            },
            filtros: {
                id_devolucao_vazio: [],
                id_programacao_carga_rodopar: [],
                situacao: [],
            },
            modalImagem: {
                abre: false,
                imagem: null,
            },
            auditoriaProcesso: [],
            totais: {
                processo: 0,
                motorista_nao_identificado: 0,
                auditoria_pronta: 0,
            },
            auditoriaProcessoMock: [
                {
                    id_devolucao_vazio: 5589,
                    id_programacao_carga_rodopar: 1459987,
                    cliente_razao_social: 'ALSTOM BRASIL ENERGIA E TRANSPORTE LTDA',
                    numero_container_tnsys: 'CMAU9464497',
                    numero_container_ocr: 'CMAU9464497',
                    data_devolucao_tnsys: '2024-03-01 22:01',
                    data_devolucao_ocr: '2024-03-01 22:15',
                    parceiro_baixa_tnsys: null,
                    parceiro_baixa_ocr: 'EXPORTAÇÃO',
                    terminal_devolucao_tnsys: 'LECHMAN DEPOT (Antiga RochaTop)',
                    terminal_devolucao_ocr: 'LECHMAN DEPOT (Antiga RochaTop)',
                    placa_ocr: 'FDR-8B91',
                    id_motorista_terminal_entrega: 7,
                    motorista_terminal_entrega: 'SIDNEY AUGUSTO RODRIGUES',
                    whatsapp_nome_perfil: 'SIDNEY AUGUSTO RODRIGUES',
                    imagem: [],
                },
                {
                    id_devolucao_vazio: 7790,
                    id_programacao_carga_rodopar: 1459987,
                    cliente_razao_social: 'ALSTOM BRASIL ENERGIA E TRANSPORTE LTDA',
                    numero_container_tnsys: 'CMAU9464497',
                    numero_container_ocr: 'CMAU9464497',
                    data_devolucao_tnsys: '2024-03-01 22:20',
                    data_devolucao_ocr: '2024-03-01 22:15',
                    parceiro_baixa_tnsys: 'G3',
                    parceiro_baixa_ocr: 'Exportação',
                    terminal_devolucao_tnsys: 'LECHMAN DEPOT (Antiga RochaTop)',
                    terminal_devolucao_ocr: 'LECHMAN DEPOT (Antiga RochaTop)',
                    placa_ocr: 'FDR-8B91',
                    id_motorista_terminal_entrega: 7,
                    motorista_terminal_entrega: 'RODINEILDO',
                    whatsapp_nome_perfil: 'SIDNEY AUGUSTO RODRIGUES',
                    imagem: [
                        {
                            id_whatsapp_mensagem_media: 216,
                            url: 'http://api-tnsys-dev.tnlog.com.br/api/V1/imagem/whatsapp/whatsapp_20240226191105.jpeg',
                            mime_type: 'image/jpeg',
                        },
                        {
                            id_whatsapp_mensagem_media: 217,
                            url: 'http://api-tnsys-dev.tnlog.com.br/api/V1/imagem/whatsapp/whatsapp_20240226195625.jpeg',
                            mime_type: 'image/jpeg',
                        },
                    ],
                },
                {
                    id_devolucao_vazio: 5591,
                    id_programacao_carga_rodopar: 1459987,
                    cliente_razao_social: 'ALSTOM BRASIL ENERGIA E TRANSPORTE LTDA',
                    numero_container_tnsys: 'CMAU9464497',
                    numero_container_ocr: 'CMAU9464497',
                    data_devolucao_tnsys: '2024-03-01 22:30',
                    data_devolucao_ocr: '2024-03-01 22:15',
                    parceiro_baixa_tnsys: 'Exportação',
                    parceiro_baixa_ocr: 'Exportação',
                    terminal_devolucao_tnsys: 'LECHMAN DEPOT (Antiga RochaTop)',
                    terminal_devolucao_ocr: 'LECHMAN DEPOT (Antiga RochaTop)',
                    placa_ocr: 'FDR-8B91',
                    id_motorista_terminal_entrega: 7,
                    motorista_terminal_entrega: 'ALONSO PHP',
                    whatsapp_nome_perfil: 'SIDNEY AUGUSTO RODRIGUES',
                    imagem: [
                        {
                            url: 'http://api-tnsys-dev.tnlog.com.br/api/V1/imagem/whatsapp/whatsapp_20240226191105.jpeg',
                            mime_type: 'image/jpeg',
                        },
                        {
                            url: 'http://api-tnsys-dev.tnlog.com.br/api/V1/imagem/whatsapp/whatsapp_20240226195625.jpeg',
                            mime_type: 'image/jpeg',
                        },
                    ],
                },
            ],
        };
    },
};
</script>
