const state = {};

const actions = {
    async browserNotificacaoHabilitado() {
        try {
            await Notification.requestPermission().then(result => {
                if (result == 'denied') {
                    throw new Error('Você bloqueou a notificação e não irá receber aleta de nova mensagem');
                }

                return true;
            }).catch(erro => {
                throw new Error(erro.message);
            });
        } catch (erro) {
            throw new Error(erro.message);
        }
    },
    notificacaoBrowser({ commit }, { titulo, mensagem, tag }) {
        if (Notification?.permission === 'denied') {
            return;
        }

        if (!tag) {
            tag = null;
        }

        new Notification(
            titulo,
            {
                body: mensagem,
                renotify: true,
                tag: tag,
                icon: 'https://terranovalogistica.com.br/wp-content/uploads/2023/07/logo_tnsys.png',
                vibrate: true
            }
        );
    }
};

const mutations = {};

const getters = {};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
};
