export default {
    state: {
        loading: false,
        mostraCarregando: false,
    },

    mutations: {
        CARREGANDO(state, loading) {
            state.loading = loading;
        },
        mostraCarregando(state, loading) {
            state.mostraCarregando = loading;
        },
    },

    actions: {
        mostraCarregando({commit}, carregando) {
            commit('mostraCarregando', carregando);
        }
    },

    getters: {
        carregando(state) {
            return state.loading;
        }
    }
}
