<template>
    <v-row justify="center">
        <v-dialog
            v-model="abreModal"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card width="100vw">
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="fechaModal()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Total de Cargas</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>

                <v-list three-line dense subheader class="pa-2">
                    <v-col cols="12" class="text-right">
                        <v-data-table
                            :headers="cabecalho"
                            :items="itens"
                            :sort-by="['id_programacao_carga', 'motorista']"
                            :sort-desc="[true, true]"
                            item-key="id_programacao_carga"
                            :items-per-page="50"
                            height="70vh"
                            loading-text="Buscando, aguarde..."
                            show-select
                            multi-sort
                            class="elevation-5"
                            calculate-widths
                            fixed-header
                            dense
                        >
                        </v-data-table>
                    </v-col>
                </v-list>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    components: {},
    props: {
        programacaoCargas: {
            type: Array,
            default: () => [],
            required: false,
        },
    },
    computed: {},
    watch: {
        programacaoCargas: {
            handler: function (dadosAtual, dadosAntigo) {
                this.preencheProgramacao(dadosAtual);
            },
            immediate: true,
            deep: true,
        },
    },
    data: () => ({
        abreModal: false,
        cabecalho: [
            { text: 'ID', value: 'id_programacao_carga' },
            { text: 'SOLICITANTE', value: 'solicitante' },
            { text: 'TIPO OPERAÇÃO', value: 'tipo_carga' },
            { text: 'MANIFESTO', value: 'manifesto' },
            { text: 'CTE', value: 'cte' },
            { text: 'Motorista', value: 'motorista' },
            { text: 'Placa 1', value: 'veiculo_destino_placa_1' },
            { text: 'Placa 2', value: 'veiculo_destino_placa_2' },
        ],
        itens: []
    }),
    computed: {},
    methods: {
        fechaModal() {
            this.abreModal = false;
        },
        preencheProgramacao(dados) {
            this.itens = dados;
        }
    },
};
</script >

<style scoped>
</style>
