<template>
    <v-data-table
        :value="value"
        @input="$emit('input', $event)"
        :headers="cabecalho"
        :items="itensTabela"
        :options.sync="compOpcoes"
        :server-items-length="total_registros"
        :fixed-header="true"
        :items-per-page="por_pagina"
        :footer-props="{
            'items-per-page-options': itens_por_pagina
        }"
        :show-current-page="true"
        :loading="carregando"
        :loading-text="mensagem_carregando"
        :item-key="chave_primaria"
        :page-count="ultima_pagina"
        :page="pagina_atual"
        :sort-by.sync="compOrdenarPor"
        :sort-desc.sync="compOrdenarDirecao"
        :show-select="mostra_selecao"
        :single-select="!multipla_selecao"
        :item-class="item_class"
        :show-expand="mostra_expand"
        :single-expand="single_expand"
        :height="altura"
        class="elevation-4"
        multi-sort
        dense
    >
        <template v-slot:loading>
            <br />
            {{ mensagem_carregando }}
            <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
        </template>

        <template v-for="header in cabecalho" :slot="`header.${header.value}`" slot-scope="{ item }">
            {{ header.text }}

            <v-menu
                offset-y
                :close-on-content-click="false"
                :key="`tabelaVuetify-menu-${header.text}`"
                v-if="!header.showFilter == false && header.type == 'input'"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon small color="green darken-1" v-if="filtroExiste(header.value)">
                            mdi-filter-check
                        </v-icon>
                        <v-icon small v-else>
                            mdi-filter-multiple
                        </v-icon>
                    </v-btn>
                </template>
                <div style="background-color: white; width: 500px" class="font-weight-medium">
                    <InputTextV3
                        v-model="filtros[header.value]"
                        :rotulo="header.text"
                        :contador="200"
                        estiloCampo="solo"
                        class="pa-4 pt-10"
                    />

                    <v-btn @click="filtros[header.value] = []" small text color="primary" class="ml-2 mb-2">
                        Limpar
                    </v-btn>
                </div>
            </v-menu>

            <v-menu
                offset-y
                :close-on-content-click="false"
                :key="`tabelaVuetify-menu-${header.text}`"
                v-if="header.type == 'select'"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon small color="green darken-1" v-if="filtroExiste(header.value)">
                            mdi-filter-check
                        </v-icon>
                        <v-icon small v-else>
                            mdi-filter-multiple
                        </v-icon>
                    </v-btn>
                </template>

                <div style="background-color: white; width: 500px" class="font-weight-medium">
                    <InputAutoCompleteLocalV3
                        v-model="filtros[header.value]"
                        :tituloBusca="header.text"
                        :itemsOpcoes="header.filterData"
                        :smallChips="true"
                        :deletableChips="true"
                        :multiple="selectMultiplo(header.multiple)"
                        estiloCampo="solo"
                        itemValor="value"
                        itemTexto="label"
                        class="pa-4 pt-10"
                    />
                    <v-btn @click="filtros[header.value] = []" small text color="primary" class="ml-2 mb-2">
                        Limpar
                    </v-btn>
                </div>
            </v-menu>

            <v-menu
                offset-y
                :close-on-content-click="false"
                :key="`tabelaVuetify-menu-${header.value}`"
                v-if="header.type == 'date'"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon small color="green darken-1" v-if="filtroExiste(header.value)">
                            mdi-filter-check
                        </v-icon>
                        <v-icon small v-else>
                            mdi-filter-multiple
                        </v-icon>
                    </v-btn>
                </template>

                <div
                    style="background-color: white; width: 250px"
                    :key="`tabelaVuetify-menu-${header.text}`"
                    class="font-weight-medium"
                >
                    <v-container class="pa-4 pt-10 font-weight-medium" style="background-color: white;">
                        <DateTimePickerV2
                            v-model="filtros[header.value]"
                            :label="header.text"
                            @input="filtros[header.value] = $event"
                            data-vv-delay="1000"
                        />

                        <v-btn @click="filtros[header.value] = null" small text color="red" class="ml-2 mb-2">Limpar</v-btn>
                    </v-container>
                </div>
            </v-menu>

            <v-menu
                offset-y
                :close-on-content-click="false"
                :key="`tabelaVuetify-menu-${header.text}`"
                v-if="header.type == 'combobox' || (header.showFilter != false && !header.hasOwnProperty('type'))"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon small color="green darken-1" v-if="filtroExiste(header.value)">
                            mdi-filter-check
                        </v-icon>
                        <v-icon small v-else>
                            mdi-filter-multiple
                        </v-icon>
                    </v-btn>
                </template>
                <div style="background-color: white; width: 500px" class="font-weight-medium">
                    <ComboBoxPreenchimentoV3
                        v-model="filtros[header.value]"
                        :tituloBusca="header.text"
                        :smallChips="true"
                        :deletableChips="true"
                        :multiple="true"
                        estiloCampo="solo"
                        class="pa-4 pt-10"
                    />
                    <v-btn @click="filtros[header.value] = []" small text color="primary" class="ml-2 mb-2">
                        Limpar
                    </v-btn>
                </div>
            </v-menu>
        </template>

        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data"></slot>
        </template>
    </v-data-table>
</template>

<script>
import ComboBoxPreenchimentoV3 from '@/components/Form/ComboBoxPreenchimentoV3.vue';
import InputAutoCompleteLocalV3 from '@/components/Form/InputAutoCompleteLocalV3.vue';
import DateTimePickerV2 from '@/components/Form/DateTimePickerV2.vue';
import InputTextV3 from '@/components/Form/InputTextV3.vue';

export default {
    name: 'TabelaVuetify',
    components: {
        ComboBoxPreenchimentoV3,
        InputAutoCompleteLocalV3,
        DateTimePickerV2,
        InputTextV3
    },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        cabecalho: {
            type: Array,
            default: () => []
        },
        itens: {
            type: Array,
            default: () => []
        },
        total_registros: {
            type: Number,
            default: () => 0
        },
        por_pagina: {
            type: Number,
            default: () => 50
        },
        itens_por_pagina: {
            type: Array,
            default: () => [50, 100, 200, -1]
        },
        ultima_pagina: {
            type: Number,
            default: () => 0
        },
        pagina_atual: {
            type: Number,
            default: () => 1
        },
        carregando: {
            type: Boolean,
            default: () => false
        },
        mensagem_carregando: {
            type: String,
            default: () => 'Atualizando itens, aguarde...'
        },
        chave_primaria: {
            type: String,
            required: true
        },
        ordenar_por: {
            type: String,
            default: () => ''
        },
        ordenar_direcao: {
            type: Boolean,
            default: () => false
        },
        mostra_selecao: {
            type: Boolean,
            default: () => true
        },
        multipla_selecao: {
            type: Boolean,
            default: () => true
        },
        item_class: {
            type: Function,
            default() {
                return null;
            }
        },
        mostra_expand: {
            type: Boolean,
            default: () => false
        },
        single_expand: {
            type: Boolean,
            default: () => true
        },
        altura: {
            type: String,
            default: () => '60vh'
        }
    },
    async created() {},
    mounted() {},
    data() {
        return {
            compOpcoes: {},
            compOrdenarPor: this.ordenar_por,
            compOrdenarDirecao: this.ordenar_direcao,
            filtros: {},
            itensTabela: this.itens
        };
    },
    computed: {
        valorFiltro() {
            return campo => {
                if (!this.filtros[campo]) {
                    return null;
                }

                return this.filtros?.[campo];
            };
        }
    },
    watch: {
        compOpcoes: {
            handler() {
                this.$emit('opcoes', this.compOpcoes);
            },
            deep: true
        },
        carregando: {
            handler(valor) {
                // se for true
                if (valor) {
                    this.itensTabela = [];

                    return;
                }
            },
            deep: true
        },
        itens: {
            handler(valor) {
                this.itensTabela = valor;
            },
            deep: true
        }
    },
    methods: {
        filtroExiste(campo) {
            return !!this.filtros[campo]?.length;
        },
        limpaFiltros() {
            this.filtros = [];
        },
        selectMultiplo(valor) {
            if (typeof valor !== 'boolean') {
                return true;
            }

            return valor;
        },
        queryFiltros() {
            const { page, itemsPerPage, sortBy, sortDesc } = this.compOpcoes;

            let query = '';

            query += page ? `pagina_atual=${page}` : '';
            query += itemsPerPage ? `&por_pagina=${itemsPerPage}` : '';
            query += sortBy
                ? sortBy
                      ?.map((ordem, indice) => {
                          const direcao = sortDesc[indice] ? 'desc' : 'asc';
                          const ligacao = indice == 0 ? '&' : '';

                          return `${ligacao}ordem[${ordem}]=${direcao}`;
                      })
                      .filter(notUndefined => notUndefined !== undefined)
                      .join('&')
                : '';
            query += this.filtros
                ? Object.keys(this.filtros)
                      ?.map((filtro, indice) => {
                          if (typeof this.filtros[filtro] == 'string') {
                              const valor = this.filtros[filtro];

                              if (!valor) {
                                  return;
                              }

                              return `&filtro[${filtro}]=${valor}`;
                          }

                          return this.filtros[filtro]
                              .map((valor, indiceValor) => {
                                  const ligacao = indiceValor == 0 ? '&' : '';

                                  return `${ligacao}filtro[${filtro}][]=${valor}`;
                              })
                              .filter(notUndefined => notUndefined !== undefined)
                              .join('&');
                      })
                      .filter(notUndefined => notUndefined !== undefined)
                      .join('&')
                : '';

            return query;
        }
    }
};
</script>
