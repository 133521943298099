<template>
    <v-container class="pa-0">
        <v-sheet color="" height="100%">
            <v-layout class="pt-10" justify-center align-center>
                <v-flex shrink>
                    <v-img max-width="300" src="@/assets/image/padrao/whatsapp_transporte_2.png"></v-img>
                </v-flex>
            </v-layout>

            <div class="pt-10 text-h4 font-weight-bold text-center">
                TNSYS e Whatsapp integrados
            </div>

            <div class="pt-10 text-xl-h4 text-center">
                Envie e receba mensagens para para os contatos permitidos. <br />
                Poderá visualizar as mensagens enviadas de forma automática, exemplo dos romaneios da programação de carga.
            </div>
        </v-sheet>
    </v-container>
</template>

<script>
import { sleep } from '@/helpers/Funcoes.js';

import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import Mensagem from './Mensagem.vue';

export default {
    name: 'Informacoes',
    components: {
        CarregandoLogoEmpresa,
        Mensagem
    },
    props: {},
    mounted() {},
    watch: {
        contatos: {
            deep: true,
            handler(novoValor) {
                this.contatosFiltrados = novoValor;
            }
        }
    },
    computed: {},
    methods: {
        infiniteScrolling(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.$emit('carrega-mensagens-anteriores');
            }
        },
        async scrollFinalMensagens() {
            await sleep(0);

            let container = document.getElementById('mensagens');

            container.scrollTop = container.scrollHeight;
        },
        enviaMensagem() {
            this.$emit('envia-mensagem', { texto: this.mensagemTexto });

            this.limpaMensagem();
            this.scrollFinalMensagens();
        },
        limpaMensagem() {
            this.mensagemTexto = '';
        }
    },
    data() {
        return {
            carregandoMensagem: 'Aguarde, carregando mensagens anteriores...',
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            mensagemTexto: ''
        };
    }
};
</script>
