const state = {
    botoes: [],
    formAcao: 'form-grid',
    botoesAdicionais: [
        {
            ativo: true,
            acao: 'recarregaTabela',
            cor: 'grey',
            form: '["form-grid"]',
            icone: 'mdi-cached',
            identificador: 'btnRecarregaTabela',
            nome: 'recarrega tabela',
            componente: 'datatable'
        },
        {
            ativo: true,
            acao: 'recarregaTabela',
            cor: 'grey',
            form: '["grid"]',
            icone: 'mdi-cached',
            identificador: 'btnRecarregaTabela',
            nome: 'recarrega tabela',
            componente: 'datatable'
        },
        {
            ativo: true,
            acao: 'fechaForm',
            cor: 'warning',
            form: '["form-novo", "form-atualiza"]',
            icone: 'close_fullscreen',
            identificador: 'btnFecha',
            nome: 'Fecha',
            componente: ''
        }
    ]
};

const actions = {
    async montaBarraNavegacao(context, botoes) {
        try {
            state.botoes = [];

            botoes.forEach(function(btn, i) {
                if (btn.nome == 'consulta') {
                    return;
                }

                /**
                 * Adiciona um botão extra
                 * Permissão de NOVO então pode SALVAR um novo registro,
                 * para mudar o LABEL
                 */
                if (btn.nome == 'novo') {
                    let btnAdicional = { ...btn };

                    btnAdicional.nome = 'salva';
                    btnAdicional.acao = 'salvaForm';
                    btnAdicional.form = '["form-novo"]';

                    state.botoes.push(btnAdicional);
                }

                state.botoes.push(btn);
            });

            state.botoesAdicionais.forEach(function(btn, i) {
                if (btn.ativo) {
                    state.botoes.push(btn);
                }
            });
        } catch (e) {
            return e.message;
        }
    },
    async montaNavegacao({ context }, { botoes, permissoes }) {
        try {
            state.botoes = [];

            botoes.forEach(function(btn, i) {
                btn.desativado = true;
                let botaoPermissao = [];

                if (btn.id_permissao instanceof Array) {
                    botaoPermissao = btn.id_permissao;
                }

                if (!(btn.id_permissao instanceof Array)) {
                    botaoPermissao.push(btn.id_permissao);
                }

                const permissaoEncontrada = permissoes.find(
                    permissao =>
                        (botaoPermissao.includes(permissao.id_permissao) && permissao.permitido) || btn.form.includes('todos')
                );

                if (permissaoEncontrada) {
                    btn.desativado = false;
                }

                state.botoes.push(btn);
            });
        } catch (e) {
            return e.message;
        }
    }
};

const mutations = {
    FORM_ACAO(state, formAcao) {
        state.formAcao = formAcao;
    }
};

const getters = {
    todosBotoes: state => {
        return state.botoes;
    },
    botoesPorForm: state => {
        return form =>
            state.botoes.filter(botao => {
                let forms = JSON.parse(botao.form);

                let btn = forms.filter(formAcao => {
                    if (formAcao == state.formAcao) {
                        return formAcao;
                    }
                });

                if (btn.length > 0) {
                    return btn;
                }
            });
    },
    botoesComPermissao: state => {
        return situacao =>
            state.botoes.filter(botao => {
                if (botao.form.includes(situacao) || botao.form.includes('todos')) {
                    return botao;
                }
            });
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
