<template>
    <v-card outlined elevation="2">
        <v-card-title class="">
            <span class="text-h6"> Demanda {{ formataData(dataDe) }} - {{ formataData(dataAte) }} </span>

            <v-spacer></v-spacer>

            <span class="pr-2">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="blue darken-1" dark v-bind="attrs" v-on="on" @click="downloadImagem(dataDe, dataAte)">
                            mdi-cloud-download
                        </v-icon>
                    </template>
                    <span>Baixar imagem</span>
                </v-tooltip>
            </span>

            <span class="pr-2">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="blue-grey darken-1"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="carregaDashboard(dataDe, dataAte)"
                        >
                            mdi-refresh
                        </v-icon>
                    </template>
                    <span>Recarregar</span>
                </v-tooltip>
            </span>

            <span class="pr-2">
                <v-tooltip max-width="200" left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="warning darken-1" dark v-bind="attrs" v-on="on" @click="ajuda = true">
                            mdi-account-question
                        </v-icon>
                    </template>
                    <span>
                        Ajuda com a composição do gráfico ? Clique para saber mais.
                    </span>
                </v-tooltip>
            </span>
        </v-card-title>

        <v-card-text>
            <v-chart
                :option="opcoes"
                :init-options="initOptions"
                :loading="carregando"
                :loadingOptions="carregandoOpcoes"
                :style="tamanhoPixel"
                ref="Grafico"
                autoresize
            />
        </v-card-text>

        <div class="text-center">
            <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                {{ notificacao.mensagem }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>

        <v-dialog v-model="ajuda" max-width="800">
            <v-card>
                <v-card-title class="text-h5">
                    Demanda De: {{ formataData(dataDe) }} Até: {{ formataData(dataAte) }}
                </v-card-title>

                <v-card-text>
                    Programações de cargas com o status Previsto e Confirmado em percentual, de acordo com o filtro da data de
                    e até.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="blue darken-1" text @click="ajuda = false">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import moment from 'moment';
import VChart from 'vue-echarts';

export default {
    name: 'DemandaTotal',
    components: {
        VChart
    },
    watch: {
        dadosDemandaTotal: {
            handler: function(dadosAtual) {
                this.adicionaDadosAoGrafico(dadosAtual);
            },
            deep: true
        }
    },
    async mounted() {
        this.opcoes.color = this.cor;
    },
    computed: {
        ...mapGetters({
            carregando: 'demanda/carregando',
            carregandoOpcoes: 'demanda/carregandoOpcoes',
            dataDe: 'demanda/dataDe',
            dataAte: 'demanda/dataAte',
            dadosDemandaTotal: 'demanda/dadosDemandaTotal',
            tamanhoDashboard: 'dashboard/tamanhoDashboard'
        }),
        ...mapState('demanda', {
            cor(state, getters) {
                return getters['cor'];
            }
        }),
        tamanhoPixel() {
            return this.tamanhoDashboard({ id_dashboard: this.id_dashboard });
        }
    },
    methods: {
        ...mapActions({
            buscaDashboard: 'demanda/buscaDashboard',
            baixarImagem: 'demanda/baixarImagem'
        }),
        carregaDashboard(data_de, data_ate) {
            this.buscaDashboard({ data_de: data_de, data_ate: data_ate })
                .then(async () => {
                    console.log('buscado');
                    this.adicionaDadosAoGrafico(this.dadosDemandaTotal);
                })
                .catch(erro => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        },
        formataData(data) {
            return moment(data).format('DD/MM/YYYY');
        },
        downloadImagem() {
            this.baixarImagem(this.$refs['Grafico']);
        },
        adicionaDadosAoGrafico(graficoDados) {
            const total = (graficoDados.previsto ?? 0) + (graficoDados.confirmado ?? 0);

            this.opcoes.series[0].data[0].value = ((graficoDados.previsto / total) * 100).toFixed(2);
            this.opcoes.series[0].data[1].value = ((graficoDados.confirmado / total) * 100).toFixed(2);
        }
    },
    data() {
        return {
            id_dashboard: 9,
            ajuda: false,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            initOptions: {
                renderer: 'png'
            },
            opcoes: {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                grid: {
                    top: '20%',
                    bottom: '20%'
                },
                series: [
                    {
                        name: 'Demanda em percentual Total',
                        type: 'pie',
                        radius: '50%',
                        bottom: '5%',
                        label: {
                            formatter: function(value) {
                                return `${value.data.name} \r\n\r\n ${value.value} %`;
                            }
                        },
                        data: [
                            { value: 0, name: 'Previsto' },
                            { value: 0, name: 'Confirmado' }
                        ],
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 12,
                                fontWeight: 'bold'
                            },
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        };
    }
};
</script>

<style scoped>
.echarts {
    width: 100%;
    height: 300px;
}
</style>
