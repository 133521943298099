<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md5>
                <div class="loginLogo">
                    <v-img
                        slot="icon"
                        contain
                        src="../assets/image/logo/logo_tnsys.png"
                    />
                </div>

                <v-card class="elevation-12" outlined>
                    <v-toolbar dark color="tnlogLaranja">
                        <v-toolbar-title
                            >Digite a sua conta para
                            autenticação</v-toolbar-title
                        >
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form" v-model="formularioValidado">
                            <v-card-title class="red--text" v-if="mensagemErro">
                                <v-icon left color="red">warning</v-icon>
                                {{ mensagemErro }}
                            </v-card-title>

                            <v-text-field
                                prepend-icon="person"
                                name="login"
                                label="Login"
                                type="text"
                                v-model="login"
                                :rules="validaLogin"
                                required
                            >
                            </v-text-field>

                            <v-text-field
                                prepend-icon="lock"
                                name="senha"
                                label="Senha"
                                type="password"
                                v-model="senha"
                                :rules="validaSenha"
                                required
                            >
                            </v-text-field>

                            <InputSelectV3
                                v-model="autenticacapTipo"
                                :regra="validaAutenticacaoTipo"
                                :itens="autenticacapTipoItens"
                                estiloCampo="solo"
                                iconePrepend="mdi-login"
                                rotulo="Autenticação"
                                itemTexto="nome"
                                itemValor="id_autenticacao_ldap"
                            />
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="grey darken-1"
                            class="white--text"
                            :disabled="!formularioValidado"
                            @click="submit"
                            >Entrar</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="notificacao.mostra = false"
                    >
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-container>
</template>

<script>
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import InputTextV3 from '@/components/Form/InputTextV3.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';

export default {
    name: 'login',
    components: {
        InputTextV3,
        InputSelectV3,
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success',
            },
            btnCarregando: false,
            formularioValidado: false,
            mensagemErro: '',
            login: '',
            senha: '',
            autenticacapTipo: null,
            autenticacapTipoItens: [],
            validaLogin: [
                (v) => !!v || 'Login é obrigatório',
                (v) => v.length >= 5 || 'Senha tem que ser maior que 5',
            ],
            validaSenha: [
                (v) => !!v || 'Senha é obrigatório',
                (v) => v.length >= 4 || 'Senha tem que ser maior que 4',
            ],
            validaAutenticacaoTipo: [
                (v) => !!v || 'Autenticação é obrigatório',
            ],
        };
    },
    async mounted() {
        await this.getAutenticacaoLdap();
    },
    methods: {
        async submit() {
            if (this.$refs.form.validate()) {
                this.$store
                    .dispatch('autenticacao/entra', {
                        login: this.login,
                        senha: this.senha,
                        autenticacao_tipo: this.autenticacapTipo,
                    })
                    .then(async () => {
                        this.$router.push({ name: 'admin' });
                    })
                    .catch((erro) => {
                        this.mensagemErro = erro;
                    });
            }
        },
        async getAutenticacaoLdap() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.autenticacaoLdap.listaTipoCombo}`,
                data: {},
            })
                .then((resposta) => {
                    this.autenticacapTipoItens =
                        resposta.data.data.atributos.map((autenticacaoLdap) => {
                            return autenticacaoLdap;
                        });

                    const autenticacaoTipoPrincipal =
                        this.autenticacapTipoItens.find(
                            (autenticacaoLdap) =>
                                autenticacaoLdap.principal == 'S'
                        );

                    if (autenticacaoTipoPrincipal) {
                        this.autenticacapTipo =
                            autenticacaoTipoPrincipal.id_autenticacao_ldap;
                    }
                })
                .catch((erro) => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true,
                    };
                });
        },
    },
};
</script>

<style scoped>
.loginLogo {
    margin: auto;
    margin-top: -90px;
    max-width: 400px;
    vertical-align: middle;
    padding: 10px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
</style>
