<template>
    <v-container fluid>
        <v-form ref="form">
            <v-row>
                <v-col cols="12" sm="4">
                    <InputTextV3 v-model="form.id_profit_less_markup_custo_venda" rotulo="ID" :contador="200" v-show="false" />

                    <InputTextMoedaV3
                        v-model="form.frete_custo_valor"
                        prefixo="R$"
                        rotulo="Frete Custo"
                        :regra="regraPadrao"
                        :desativado="desativaCampoFreteCusto"
                        ajudaMensagem="Quando o tipo de frete for Terceiro será considerado conforme QUALP, se o tipo de frete for Frota poderá alterar o valor"
                    />
                </v-col>

                <v-col cols="12" sm="2">
                    <InputTextMoedaV3
                        v-model="form.frete_custo_percentual"
                        rotulo="Frete Custo Percentual"
                        sufixo="%"
                        :tamanho="4"
                        :desativado="true"
                    />
                </v-col>

                <v-col cols="12" sm="4">
                    <InputTextMoedaV3
                        v-model="form.pedagio_valor"
                        prefixo="R$"
                        rotulo="Pedágio"
                        :desativado="true"
                        ajudaMensagem="Valor do pedágio conforme QUALP"
                    />
                </v-col>

                <v-col cols="12" sm="2">
                    <InputTextMoedaV3
                        v-model="form.pedagio_percentual"
                        rotulo="Pedágio Percentual"
                        sufixo="%"
                        :tamanho="4"
                        :desativado="true"
                    />
                </v-col>

                <v-col cols="12" sm="2">
                    <InputTextMoedaV3
                        v-model="form.seguro_valor"
                        prefixo="R$"
                        rotulo="Seguro"
                        :desativado="true"
                        ajudaMensagem="Custo de seguro (apólice TERRA NOVA)"
                    />
                </v-col>

                <v-col cols="12" sm="2">
                    <InputTextMoedaV3
                        v-model="form.seguro_percentual"
                        rotulo="Seguro Percentual"
                        sufixo="%"
                        :tamanho="4"
                        :desativado="true"
                    />
                </v-col>

                <v-col cols="12" sm="2">
                    <InputTextMoedaV3
                        v-model="seguro_percentual_fixo"
                        rotulo="Seguro Percentual Fixo"
                        sufixo="%"
                        :tamanho="4"
                        :casa_decimal="3"
                        :desativado="true"
                        ajudaMensagem="Percentual fixo"
                    />
                </v-col>

                <v-col cols="12" sm="4">
                    <InputTextMoedaV3
                        v-model="form.licenca_valor"
                        prefixo="R$"
                        rotulo="Licença"
                        :desativado="desativaCampos"
                        :regra="regraPadrao"
                        ajudaMensagem="Custo com licenças adicionais (cargas especiais e excedentes)"
                    />
                </v-col>

                <v-col cols="12" sm="2">
                    <InputTextMoedaV3
                        v-model="form.licenca_percentual"
                        rotulo="Licença Percentual"
                        sufixo="%"
                        :tamanho="4"
                        :desativado="true"
                    />
                </v-col>

                <v-col cols="12" sm="4">
                    <InputTextMoedaV3
                        v-model="form.taxa_imo_anvisa_valor"
                        prefixo="R$"
                        rotulo="Taxa IMO Anvisa"
                        :desativado="desativaCampos"
                        :regra="regraPadrao"
                        ajudaMensagem="Custo para cargas perigosas ou destinadas à saúde"
                    />
                </v-col>

                <v-col cols="12" sm="2">
                    <InputTextMoedaV3
                        v-model="form.taxa_imo_anvisa_percentual"
                        rotulo="Taxa IMO Anvisa Percentual"
                        sufixo="%"
                        :tamanho="4"
                        :desativado="true"
                    />
                </v-col>

                <v-col cols="12" sm="4">
                    <InputTextMoedaV3
                        v-model="form.devolucao_container_vazio_valor"
                        prefixo="R$"
                        rotulo="Devolução Container Vazio"
                        :desativado="desativaCampos"
                        :regra="regraPadrao"
                        ajudaMensagem="Custo para devolução contêiner vazio margem esquerda"
                    />
                </v-col>

                <v-col cols="12" sm="2">
                    <InputTextMoedaV3
                        v-model="form.devolucao_container_vazio_percentual"
                        rotulo="Devolução Container Vazio Percentual"
                        sufixo="%"
                        :tamanho="4"
                        :desativado="true"
                    />
                </v-col>

                <v-col cols="12" sm="4">
                    <InputTextMoedaV3
                        v-model="form.ajudante_valor"
                        prefixo="R$"
                        rotulo="Ajudante"
                        :desativado="desativaCampos"
                        :regra="regraPadrao"
                        ajudaMensagem="Custo com ajudantes para carga ou descarga"
                    />
                </v-col>

                <v-col cols="12" sm="2">
                    <InputTextMoedaV3
                        v-model="form.ajudante_percentual"
                        rotulo="Ajudante Percentual"
                        sufixo="%"
                        :tamanho="4"
                        :desativado="true"
                    />
                </v-col>

                <v-col cols="12" sm="4">
                    <InputTextMoedaV3
                        v-model="form.escolta_valor"
                        prefixo="R$"
                        rotulo="Escolta"
                        :desativado="desativaCampos"
                        :regra="regraPadrao"
                        ajudaMensagem="Custo com escolta, cargas de alto valor agregados"
                    />
                </v-col>

                <v-col cols="12" sm="2">
                    <InputTextMoedaV3
                        v-model="form.escolta_percentual"
                        rotulo="Escolta Percentual"
                        sufixo="%"
                        :tamanho="4"
                        :desativado="true"
                    />
                </v-col>

                <v-col cols="12" sm="12" class="text-right">
                    <span class="text-md-body-1 font-weight-black">
                        Total Cost of Sales
                    </span>

                    <v-chip class="ma-2 text-sm-body-1" label small>
                        {{ totalValoresFormatado }}
                    </v-chip>

                    <v-chip class="ma-2 text-sm-body-1" label small>
                        {{ totalPercentuaisFormatado }}<strong>%</strong>
                    </v-chip>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import { Minimo, Obrigatorio } from '@/helpers/ValidadorCampo.js';

import { FormataMoeda } from '@/helpers/Funcoes.js';
import TipoFrete from './../Dominio/Enum/TipoFrete.js';

import InputTextMoedaV3 from '@/components/Form/InputTextMoedaV3.vue';
import InputTextV3 from '@/components/Form/InputTextV3.vue';

export default {
    name: 'FormCustoVenda',
    components: {
        InputTextMoedaV3,
        InputTextV3
    },
    props: {
        id_profit_less_markup_custo_venda: {
            type: Number,
            default: () => null
        },
        valor_mercadoria: {
            type: Number,
            default: () => 0
        },
        frete_custo_valor: {
            type: Number,
            default: () => 0
        },
        frete_custo_percentual: {
            type: Number,
            default: () => 0
        },
        pedagio_valor: {
            type: Number,
            default: () => 0
        },
        pedagio_percentual: {
            type: Number,
            default: () => 0
        },
        seguro_valor: {
            type: Number,
            default: () => 0
        },
        seguro_percentual: {
            type: Number,
            default: () => 0
        },
        licenca_valor: {
            type: Number,
            default: () => 0
        },
        licenca_percentual: {
            type: Number,
            default: () => 0
        },
        taxa_imo_anvisa_valor: {
            type: Number,
            default: () => 0
        },
        taxa_imo_anvisa_percentual: {
            type: Number,
            default: () => 0
        },
        devolucao_container_vazio_valor: {
            type: Number,
            default: () => 0
        },
        devolucao_container_vazio_percentual: {
            type: Number,
            default: () => 0
        },
        ajudante_valor: {
            type: Number,
            default: () => 0
        },
        ajudante_percentual: {
            type: Number,
            default: () => 0
        },
        escolta_valor: {
            type: Number,
            default: () => 0
        },
        escolta_percentual: {
            type: Number,
            default: () => 0
        },
        total_percentual: {
            type: Number,
            default: () => 0
        },
        total_valor: {
            type: Number,
            default: () => 0
        },
        tipo_frete: {
            type: Number,
            default: () => null
        }
    },
    created() {
        this.calculaPercentuais();
    },
    watch: {
        frete_custo_valor: {
            handler(valor) {
                this.form.frete_custo_valor = valor;
            }
        },
        'form.frete_custo_valor': {
            handler() {
                this.calculaPercentuais();
            }
        },
        pedagio_valor: {
            handler(valor) {
                this.form.pedagio_valor = valor;
            }
        },
        'form.pedagio_valor': {
            handler() {
                this.calculaPercentuais();
            }
        },
        'form.licenca_valor': {
            handler() {
                this.calculaPercentuais();
            }
        },
        'form.taxa_imo_anvisa_valor': {
            handler() {
                this.calculaPercentuais();
            }
        },
        'form.devolucao_container_vazio_valor': {
            handler() {
                this.calculaPercentuais();
            }
        },
        'form.ajudante_valor': {
            handler() {
                this.calculaPercentuais();
            }
        },
        'form.escolta_valor': {
            handler() {
                this.calculaPercentuais();
            }
        }
    },
    computed: {
        totalValoresFormatado() {
            return FormataMoeda(this.form.total_valor, {}, true);
        },
        totalPercentuaisFormatado() {
            return FormataMoeda(this.form.total_percentual, {}, false);
        },
        desativaCampoFreteCusto() {
            return this.tipo_frete == TipoFrete.TERCEIRO ? true : false;
        },
        desativaCampos() {
            return this.form.frete_custo_valor <= 0 ? true : false;
        }
    },
    methods: {
        calculaPercentuais() {
            this.form.seguro_valor = (this.valor_mercadoria * this.seguro_percentual_fixo) / 100;

            this.totalValores();

            this.form.frete_custo_percentual = parseFloat(
                this.calculaPercentual(this.form.frete_custo_valor, this.form.total_valor)
            );
            this.form.pedagio_percentual = parseFloat(this.calculaPercentual(this.form.pedagio_valor, this.form.total_valor));
            this.form.seguro_percentual = parseFloat(this.calculaPercentual(this.form.seguro_valor, this.form.total_valor));
            this.form.licenca_percentual = parseFloat(this.calculaPercentual(this.form.licenca_valor, this.form.total_valor));
            this.form.taxa_imo_anvisa_percentual = parseFloat(
                this.calculaPercentual(this.form.taxa_imo_anvisa_valor, this.form.total_valor)
            );
            this.form.devolucao_container_vazio_percentual = parseFloat(
                this.calculaPercentual(this.devolucao_container_vazio_valor, this.form.total_valor)
            );
            this.form.ajudante_percentual = parseFloat(
                this.calculaPercentual(this.form.ajudante_valor, this.form.total_valor)
            );
            this.form.escolta_percentual = parseFloat(this.calculaPercentual(this.form.escolta_valor, this.form.total_valor));

            this.totalPercentuais();
        },
        calculaPercentual(valor, valor_total) {
            return parseFloat((valor / valor_total) * 100).toFixed(2);
        },
        totalValores() {
            this.form.total_valor = 0;

            const valores =
                parseFloat(this.form.frete_custo_valor) +
                parseFloat(this.form.pedagio_valor) +
                parseFloat(this.form.seguro_valor) +
                parseFloat(this.form.licenca_valor) +
                parseFloat(this.form.taxa_imo_anvisa_valor) +
                parseFloat(this.form.devolucao_container_vazio_valor) +
                parseFloat(this.form.ajudante_valor) +
                parseFloat(this.form.escolta_valor);

            if (!valores) {
                return;
            }

            this.form.total_valor = valores;
        },
        totalPercentuais() {
            const percentuais =
                parseFloat(this.form.frete_custo_percentual) +
                parseFloat(this.form.pedagio_percentual) +
                parseFloat(this.form.seguro_percentual) +
                parseFloat(this.form.licenca_percentual) +
                parseFloat(this.form.taxa_imo_anvisa_percentual) +
                parseFloat(this.form.devolucao_container_vazio_percentual) +
                parseFloat(this.form.ajudante_percentual) +
                parseFloat(this.form.escolta_percentual);

            this.form.total_percentual = percentuais;
        }
    },
    data() {
        return {
            form: {
                id_profit_less_markup_custo_venda: this.id_profit_less_markup_custo_venda,
                frete_custo_valor: this.frete_custo_valor,
                frete_custo_percentual: this.frete_custo_percentual,
                pedagio_valor: this.pedagio_valor,
                pedagio_percentual: this.pedagio_percentual,
                seguro_valor: this.seguro_valor,
                seguro_percentual: this.seguro_percentual,
                licenca_valor: this.licenca_valor,
                licenca_percentual: this.licenca_percentual,
                taxa_imo_anvisa_valor: this.taxa_imo_anvisa_valor,
                taxa_imo_anvisa_percentual: this.taxa_imo_anvisa_percentual,
                devolucao_container_vazio_valor: this.devolucao_container_vazio_valor,
                devolucao_container_vazio_percentual: this.devolucao_container_vazio_percentual,
                ajudante_valor: this.ajudante_valor,
                ajudante_percentual: this.ajudante_percentual,
                escolta_valor: this.escolta_valor,
                escolta_percentual: this.escolta_percentual,
                total_percentual: this.total_percentual,
                total_valor: this.total_valor
            },
            seguro_percentual_fixo: 0.017,
            regraPadrao: [Obrigatorio(), Minimo(1)],
            regraFreteCusto: [Obrigatorio(), Minimo(1), v => parseFloat(v) <= 0 || `Valor deve ser maior que 0`]
        };
    }
};
</script>
