const Obrigatorio = () => {
    return (v) =>
        !!v || 'Campo obrigatório'
}

const Minimo = (valor_minimo) => {
    return (v) =>
        (v || '').toString().length >= valor_minimo ||
        `Mínimo ${valor_minimo}`
}

const Maximo = (valor_maximo) => {
    return (v) =>
        (v || '').toString().length <= valor_maximo ||
        `Máximo ${valor_maximo}`
}

const Email = () => {
    return (v) => /.+@.+\..+/.test(v) || 'E-mail inválido';
}

export { Maximo, Minimo, Obrigatorio, Email };
