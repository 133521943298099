<template>
    <span> {{ params.value.toUpperCase() }} ANTERIOR</span>
</template>

<script>
import moment from 'moment';

export default {
    name: 'SaldoInicial',
    data() {
        return {};
    },
    computed: {
        saldo() {
            console.log(this.params);

            const dataColuna = this.params?.colDef.field;

            const diaAnterior = moment(dataColuna)
                .add(-1, 'days')
                .format('YYYY-MM-DD');

            console.log('dataColuna', this.params.node?.parent?.aggData[diaAnterior]);

            return 'OI2 - ';
        }
    },
    methods: {
        cellStyle: params => {
            console.log('TUDO NOOSSSO', params);

            return { color: 'white', backgroundColor: 'red' };

            const isRootLevel = params.node.level === -1;

            if (isRootLevel && params.value < 0 && params.node.footer) {
                return { color: 'white', backgroundColor: 'red' };
            }

            return null;
        }
    }
};
</script>
