import { mapGetters, mapActions, mapState } from 'vuex';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { Alerta, normalizeRelations } from '@/helpers/Funcoes.js';

import SituacaoForm from '@/Enum/Colaborador/SituacaoForm.js';
import Status from '@/Enum/Colaborador/Status.js';

import TabelaAcao from '@/Modulo/Compartilhado/Tabela/Dominio/Servico/Acao';

const gridMixins = {
    mixins: [TabelaAcao],
    async mounted() {
        this.mostraCarregando(true);

        await this.dadosPaginaInicial();

        this.mostraCarregando(false);

        if (this.$route.params.mensagem) {
            Alerta([this.$route.params.mensagem], 'success');
        }
    },
    computed: {
        ...mapGetters(['botoesComPermissao']),
        ...mapState(['carregando'])
    },
    watch: {},
    methods: {
        ...mapActions(['montaNavegacao', 'mostraCarregando']),
        async dadosPaginaInicial() {
            try {
                await ApiService({
                    method: 'get',
                    url: `${API_URL.erp.colaborador.dadosPaginaInicial}`,
                    data: {},
                    mostraCarregando: false
                })
                    .then(async resposta => {
                        await this.buscaPermissoes();
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });

                this.permissao = true;
            } catch (erro) {
                Alerta([erro.message], 'error');
            }
        },
        async buscaPermissoes() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.colaborador.permissao}`,
                data: {},
                mostraCarregando: false
            })
                .then(async resposta => {
                    const permissoes = resposta.data.data.atributos;

                    this.colaboradorSituacao = SituacaoForm.GRID;

                    await this.montaNavegacao({ botoes: this.botoes, permissoes });
                })
                .catch(error => {
                    throw new Error(error);
                });
        },
        async buscaColaboradorItens() {
            this.datatable.carregando = true;

            const query = this.$refs.tabela.queryFiltros();

            await ApiService({
                method: 'get',
                url: `${API_URL.erp.colaborador.datatable}/?${query}`,
                data: {},
                mostraCarregando: false
            })
                .then(resposta => {
                    this.datatable.itens = resposta.data.conteudo.datatable.itens;

                    this.datatable.totalRegistros = resposta.data.conteudo.datatable.total;
                    this.datatable.ultima_pagina = resposta.data.conteudo.datatable.ultima_pagina;
                    this.datatable.carregando = false;
                })
                .catch(erro => {
                    Alerta([erro.message], 'error');
                });
        },
        novoItem() {
            this.$router.push({
                name: 'colaborador-form'
            });
        },
        editaItem(item) {
            this.$router.push({
                name: 'colaborador-form',
                params: { id_colaborador: item.id_colaborador }
            });
        },
        async apagaItem(item) {
            try {
                const ids = this.datatable.itensSelecionados
                    .map(function(item) {
                        return item.id_colaborador;
                    })
                    .join();

                if (!ids) {
                    Alerta(['Selecione um ou mais Colaborador no grid para apagar.'], 'error');

                    return;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Apagar', `Colaborador(es) ${ids} ?`, {
                        cor: 'red'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        this.mensagemCarregando = 'Aguarde, apagando...';

                        this.mostraCarregando(true);

                        let urlConfig = {
                            method: 'DELETE',
                            url: `${API_URL.erp.colaborador.apaga}/${ids}`,
                            data: {},
                            mostraCarregando: false
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                Alerta([`Colaborador(es) ${ids} apagado(s).`], 'success');

                                this.mostraCarregando(false);
                                this.buscaColaboradorItens();
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });

                        this.datatable.itensSelecionados = [];
                    });
            } catch (erro) {
                this.mostraCarregando(false);

                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        selecionaCor(status) {
            if (Status.ABERTO == status) {
                return 'grey darken-1';
            }

            if (Status.SUSPENSO == status) {
                return 'red darken-1';
            }

            return 'grey darken-1';
        },
        imagemAvatar(imagemAvatar) {
            return imagemAvatar ?? require('@/assets/image/perfil/img_avatar.png');
        }
    },
    data() {
        return {
            permissao: false,
            permissoes: [],
            botoes: [
                {
                    id_permissao: 6,
                    nome: 'Exportar Excel',
                    acao: 'exportaTabelaParaExcel',
                    parametros: { arquivoNome: 'colaborador' },
                    icone: 'mdi-file-excel-outline',
                    cor: 'green darken-5',
                    form: [SituacaoForm.GRID]
                },
                {
                    id_permissao: 2,
                    nome: 'Novo',
                    acao: 'novoItem',
                    icone: 'mdi-newspaper-variant-multiple',
                    cor: 'green lighten-1',
                    form: [SituacaoForm.GRID]
                },
                {
                    id_permissao: 4,
                    nome: 'Apaga',
                    acao: 'apagaItem',
                    icone: 'mdi-trash-can-outline',
                    cor: 'red lighten-1',
                    form: [SituacaoForm.GRID]
                },
                {
                    id_permissao: 6,
                    nome: '',
                    acao: 'buscaColaboradorItens',
                    icone: 'mdi-reload',
                    cor: 'grey darken-5',
                    form: [SituacaoForm.GRID]
                },
                {
                    id_permissao: 6,
                    nome: '',
                    acao: 'limpaFiltro',
                    icone: 'mdi-filter-multiple',
                    cor: 'red darken-5',
                    form: [SituacaoForm.GRID]
                }
            ],
            colaboradorSituacao: null,
            mensagemCarregando: 'Buscando, aguarde...',
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            datatable: {
                carregando: false,
                mensagemCarregando: 'Buscando, aguarde...',
                chave_primaria: 'id_colaborador',
                itens: [],
                totalRegistros: 0,
                ultima_pagina: 0,
                pagina_atual: 1,
                por_pagina: 50,
                ordenarPor: 'id_colaborador',
                ordenarDirecao: true,
                opcoes: {},
                itensSelecionados: [],
                cabecalho: [
                    {
                        text: 'AÇÃO',
                        value: 'acao',
                        align: 'start',
                        width: '100',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'ID',
                        value: 'id_colaborador',
                        width: '100'
                    },
                    {
                        text: 'AVATAR',
                        value: 'imagem_avatar',
                        width: '100',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'Ativo',
                        value: 'ativo',
                        width: '100',
                        type: 'select',
                        filterData: [
                            {
                                value: 1,
                                label: 'Sim'
                            },
                            {
                                value: 0,
                                label: 'Não'
                            }
                        ]
                    },
                    {
                        text: 'Nome',
                        value: 'nome',
                        width: '250'
                    },
                    {
                        text: 'CPF',
                        value: 'cpf',
                        width: '100'
                    },
                    {
                        text: 'CNPJ',
                        value: 'cnpj',
                        width: '100'
                    },
                    {
                        text: 'Gestor',
                        value: 'colaborador_gestor',
                        width: '250'
                    },
                    {
                        text: 'Data Adminissão',
                        value: 'data_admissao',
                        width: '150'
                    },
                    {
                        text: 'Usuário Criação',
                        value: 'id_usuario_criacao',
                        width: '150'
                    },
                    {
                        text: 'Usuário Última Alteração',
                        value: 'id_usuario_ultima_alteracao',
                        width: '150'
                    },
                    {
                        text: 'Data Última Alteração',
                        value: 'data_ultima_alteracao',
                        width: '150'
                    }
                ]
            },
            form: {
                colaborador: {}
            }
        };
    }
};

export default gridMixins;
