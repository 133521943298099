<template>
    <v-container fluid>
        <v-data-iterator
            :items="items"
            :search="search"
            hide-default-footer
            disable-sort
            disable-pagination
        >
            <template v-slot:header>
                <v-toolbar dark color="blue-grey lighten-1" class="mb-1">
                    <v-text-field
                        v-model="search"
                        clearable
                        flat
                        solo-inverted
                        placeholder=""
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        label="Search"
                    ></v-text-field>
                </v-toolbar>
            </template>

            <template v-slot:default="props">
                <v-row>
                    <v-col
                        v-for="item in props.items"
                        :key="item.id_registro"
                        cols="12"
                        sm="12"
                        md="4"
                        lg="3"
                    >
                        <v-card
                            class="mx-auto mt-1 py-2 px-2"
                            max-width="300"
                            min-height="300"
                        >
                            <center>
                                <v-avatar color="orange" size="64">
                                    <v-icon dark>
                                        mdi-account-circle
                                    </v-icon>
                                </v-avatar>
                            </center>
                            <v-card-title style="min-height:150px">
                                {{ item.nome }}
                            </v-card-title>

                            <v-divider class="mx-4"></v-divider>

                            <v-card-subtitle>
                                Ramal: {{ item.ramal }}
                            </v-card-subtitle>

                            <v-card-subtitle class="pt-0">
                                Celular: {{ item.celular }}
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </v-data-iterator>
    </v-container>
</template>

<script>
import ApiService from '@/services/ApiService.js';
import { mask } from 'maska';
import { isEmpty } from 'lodash';

export default {
    name: 'ListaRamal',
    mounted() {
        this.getListaRamal();
    },

    data() {
        return {
            search: '',
            sortDesc: false,
            page: 1,
            itemsPerPage: 4,
            sortBy: 'nome',
            keys: ['Nome', 'Ramal', 'Celular'],
            items: []
        };
    },
    methods: {
        async getListaRamal() {
            await ApiService({
                method: 'get',
                url: `V1/lista-ramal/lista?fields=nome,celular,ramal`,
                data: {}
            })
                .then(resposta => {
                    this.items = resposta.data.data.map(item => {
                        let celular = null;

                        if (
                            !isEmpty(item.atributos.celular) &&
                            item.atributos.celular !== 0
                        ) {
                            celular = mask(
                                item.atributos.celular,
                                '(##) #####-####'
                            );
                        }

                        return {
                            id_registro: item.id_registro,
                            nome: item.atributos.nome,
                            ramal: item.atributos.ramal,
                            celular
                        };
                    });
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        }
    }
};
</script>

<style></style>
