<template>
    <v-form ref="form">
        <v-row>
            <v-col cols="12" sm="2">
                <InputTextV3 v-model="form.id_whatsapp_marketing" rotulo="ID" :contador="100" desativado clearable />
            </v-col>

            <v-col cols="12" sm="10">
                <InputTextV3 v-model="form.descricao" :regra="regrasDescricao" rotulo="Descrição" :contador="200" />
            </v-col>

            <v-col cols="12" sm="2">
                <InputSelectV3
                    v-model="form.situacao"
                    rotulo="Situação"
                    :itens="opcaoSituacao"
                    itemTexto="label"
                    itemValor="value"
                    :desativado="true"
                />
            </v-col>

            <v-col cols="12" sm="4">
                <InputSelectV3
                    v-model="form.id_whatsapp_numero_atendimento"
                    :itens="opcaoNumeroAtendimento"
                    :carregando="carregandoWhatsappNumeroAtendimento"
                    :desativado="carregandoWhatsappNumeroAtendimento"
                    :regra="regrasNumeroAtendimento"
                    rotulo="Número Atendimento"
                    itemTexto="texto"
                    itemValor="valor"
                />
            </v-col>

            <v-col cols="12" sm="4">
                <InputSelectV3
                    v-model="form.id_whatsapp_mensagem_template"
                    :itens="opcaoTemplate"
                    rotulo="Whatsapp Template"
                    itemTexto="texto"
                    itemValor="valor"
                />
            </v-col>

            <v-col cols="12" sm="2">
                <v-datetime-picker
                    v-model="form.data_hora_envio"
                    clearText="Limpar"
                    okText="OK"
                    label="Data Hora Envio"
                    :dateFormat="'dd/MM/yyyy'"
                    :timeFormat="'HH:mm'"
                    :loading="false"
                    :textFieldProps="{
                        outlined: true,
                        dense: true,
                        clearable: true,
                        'prepend-inner-icon': 'mdi-calendar'
                    }"
                    :timePickerProps="{
                        format: '24hr'
                    }"
                >
                </v-datetime-picker>
            </v-col>

            <v-col cols="12" sm="6">
                <InputTextV3 v-model="form.template.nome" :regras="regrasNome" rotulo="Nome" :contador="200" />
            </v-col>

            <v-col cols="12" sm="6">
                <InputTextV3 v-model="form.template.empresa" :regras="regrasEmpresa" rotulo="Empresa" :contador="200" />
            </v-col>

            <v-col cols="12" sm="6">
                <TextAreaV3
                    v-model="form.template.mensagem"
                    :contador="1024"
                    :linhas="12"
                    rotulo="Whatsapp texto"
                    ajudaMensagem="Será preenchido no corpo do e-mail, será exibido um exemplo ao lado."
                />
            </v-col>

            <v-col cols="12" sm="6">
                <v-card elevation="5">
                    <v-card-title>
                        Previa da mensagem &nbsp;

                        <v-chip
                            :color="mensagemTotal > 1024 ? 'red darken-1' : 'light-green darken-1'"
                            class="font-weight-bold text-md-body-1"
                            dark
                        >
                            {{ this.mensagemTotal }}
                        </v-chip>
                    </v-card-title>

                    <v-card-text style="height: 280px;">
                        <span v-html="textoTemplate"></span>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" sm="12">
                <TextAreaV3 v-model="form.observacao" :contador="3000" rotulo="Observação" />
            </v-col>

            <v-col cols="12">
                <v-row>
                    <v-col cols="2">
                        <InputTextV3
                            v-model="celular"
                            :contador="20"
                            :regra="regrasCelular"
                            campoFormatacao="+55 (##) # ####-####"
                            rotulo="Celular"
                            ajudaMensagem="Exemplo: +(55) 11 9 4575-7856"
                        />
                    </v-col>

                    <v-col cols="4">
                        <v-badge
                            :content="totalRegistros"
                            :value="totalRegistros"
                            icon="mdi-numeric-positive-1"
                            color="deep-purple accent-4"
                            overlap
                        >
                            <v-btn tile color="success" @click="adicionaCelularManual()">
                                <v-icon left> mdi-plus </v-icon>
                                Celular
                            </v-btn>
                        </v-badge>

                        <v-btn tile color="indigo lighten-1" class="ml-2" @click="abreImportarExcel()" dark>
                            <v-icon left> mdi-file-excel </v-icon>
                            Importar excel
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left" style="width: 100px;">
                                    AÇÃO
                                </th>
                                <th class="text-left" style="width: 150px;">
                                    ID
                                </th>
                                <th class="text-left" style="width: 500px;">
                                    Celular
                                </th>
                                <th class="text-left" style="width: 70%;">
                                    Nome Whatsapp Profile
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in form.contatos" :key="`contato-${index}`">
                                <td>
                                    <v-icon color="red" small @click="removeContato(index)">mdi-trash-can-outline</v-icon>
                                </td>
                                <td>{{ item.id_whatsapp_marketing_contato }}</td>
                                <td>{{ item.telefone | VMask('+## (##) # ####-####') }}</td>
                                <td>{{ item.whatsapp_nome_profile }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>

        <v-dialog v-model="modalImportarExcel" persistent>
            <importar-excel
                ref="importarExcel"
                @cancelar="modalImportarExcel = false"
                @adiciona-celular="adicionaImportarExcel"
            />
        </v-dialog>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-form>
</template>

<script>
import { API_URL, API_URL_BASE } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import { mask } from 'maska';

import { Obrigatorio, Minimo, Maximo } from '@/helpers/ValidadorCampo.js';
import SituacaoEnum, { situacaoDescricao } from '@/Modulo/WhatsappMarketing/Dominio/Enum/Situacao.js';

import InputTextV3 from '@/components/Form/InputTextV3.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';
import TextAreaV3 from '@/components/Form/TextAreaV3.vue';

import ImportarExcel from '@/Modulo/WhatsappMarketing/Componente/ImportarExcel.vue';

export default {
    name: 'FormDados',
    components: {
        InputTextV3,
        InputSelectV3,
        TextAreaV3,
        ImportarExcel
    },
    props: {
        id_whatsapp_marketing: {
            type: Number,
            default: () => null
        },
        id_whatsapp_numero_atendimento: {
            type: Number,
            default: () => null
        },
        id_whatsapp_mensagem_template: {
            type: Number,
            default: () => 4 // Mensagem de boas vindas
        },
        situacao: {
            type: Number,
            default: () => null
        },
        descricao: {
            type: String,
            default: () => null
        },
        template: {
            type: Object,
            default: () => {}
        },
        observacao: {
            type: String,
            default: () => null
        },
        data_hora_envio: {
            type: String,
            default: () => null
        },
        contatos: {
            type: Array,
            required: true,
            default() {
                return {
                    contatos: [
                        {
                            id_whatsapp_marketing_contato: null,
                            telefone: null
                        }
                    ]
                };
            }
        }
    },
    async mounted() {
        this.carregaWhatsappNumeroAtendimento();
    },
    watch: {
        id_whatsapp_marketing: {
            deep: true,
            handler(novoValor) {
                this.form.id_whatsapp_marketing = novoValor;
            }
        },
        id_whatsapp_numero_atendimento: {
            deep: true,
            handler(novoValor) {
                this.form.id_whatsapp_numero_atendimento = novoValor;
            }
        },
        id_whatsapp_mensagem_template: {
            deep: true,
            handler(novoValor) {
                this.form.id_whatsapp_mensagem_template = novoValor;
            }
        },
        situacao: {
            deep: true,
            handler(novoValor) {
                this.form.situacao = novoValor;
            }
        },
        descricao: {
            deep: true,
            handler(novoValor) {
                this.form.descricao = novoValor;
            }
        },
        template: {
            deep: true,
            handler(novoValor) {
                if (typeof novoValor != 'object' && Object.keys(novoValor).length === 0) {
                    return;
                }

                this.form.template = novoValor;
            }
        },
        observacao: {
            deep: true,
            handler(novoValor) {
                this.form.observacao = novoValor;
            }
        },
        data_hora_envio: {
            deep: true,
            handler(novoValor) {
                this.form.data_hora_envio = novoValor;
            }
        },
        contatos: {
            deep: true,
            handler(novoValor) {
                this.form.contatos = novoValor;
            }
        }
    },
    computed: {
        totalRegistros() {
            return this.form.contatos.length;
        },
        textoTemplate() {
            const mensagem = this.form.template?.mensagem ?? 'Digite a mensagem ao lado';
            const nome = this.form.template?.nome ?? '{{preencha o campo nome}}';
            const empresa = this.form.template?.empresa ?? '{{preencha o campo empresa}}';

            let templateSelecionado = this.opcaoTemplate.find(
                objeto => objeto.valor === this.form.id_whatsapp_mensagem_template
            );

            if (!templateSelecionado) {
                return;
            }

            let valores = [nome, empresa, mensagem];

            let templateAlterado = templateSelecionado.template.replace(/{{(\d+)}}/g, (match, indice) => {
                return valores[indice - 1] || match;
            });

            if (templateSelecionado.botoes) {
                templateAlterado += `<br /><br /> <b>BOTÕES:</b> <br /><br />`;

                const btns = Object.values(templateSelecionado.botoes).map(item => {
                    return (templateAlterado += `${item}<br />`);
                });
            }

            return templateAlterado;
        },
        mensagemTotal() {
            return this.textoTemplate.length;
        }
    },
    methods: {
        async carregaWhatsappNumeroAtendimento() {
            try {
                this.carregandoWhatsappNumeroAtendimento = true;

                this.opcaoNumeroAtendimento = [];

                let urlConfig = {
                    method: 'GET',
                    url: `${API_URL.erp.whatsappmarketing.comboWhatsappNumeroAtendimento}`,
                    data: {},
                    mostraCarregando: false
                };

                ApiService(urlConfig)
                    .then(async resposta => {
                        if (!resposta.data.data.atributos.length) {
                            return;
                        }

                        this.opcaoNumeroAtendimento = resposta.data.data.atributos;
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                throw new Error(erro.message);
            } finally {
                this.carregandoWhatsappNumeroAtendimento = false;
            }
        },
        adicionaCelularManual() {
            this.adicionaCelular(this.celular);

            this.celular = '';
        },
        adicionaCelular(celular) {
            if (!celular || celular.length < 20) {
                this.notificacao = {
                    mensagem: `Telefone ${celular} preenchido de forma incorreta.`,
                    tipo: 'error',
                    mostra: true
                };

                return;
            }

            const contato = {
                id_whatsapp_marketing_contato: null,
                telefone: celular
            };

            this.form.contatos.push(contato);
        },
        removeContato(posicao) {
            const listaContatos = this.form.contatos.filter((item, index) => index !== posicao);

            this.form.contatos = [];

            listaContatos.forEach((item, index) => {
                this.form.contatos.push(item);
            });
        },
        abreImportarExcel() {
            this.modalImportarExcel = true;
        },
        adicionaImportarExcel({ dados }) {
            dados.map(celular => {
                const celularFormatado = mask(celular, '+## (##) # ####-####');

                this.adicionaCelular(celularFormatado);
            });

            this.modalImportarExcel = false;

            this.notificacao = {
                mensagem: `Lista validada, dados importados.`,
                tipo: 'success',
                mostra: true
            };
        }
    },
    data() {
        return {
            form: {
                id_whatsapp_marketing: null,
                id_whatsapp_numero_atendimento: null,
                id_whatsapp_mensagem_template: 4,
                situacao: SituacaoEnum.ABERTO,
                descricao: null,
                template: {
                    nome: null,
                    empresa: null,
                    mensagem: null
                },
                observacao: null,
                data_hora_envio: null,
                contatos: []
            },
            modalImportarExcel: false,
            celular: null,
            regrasCelular: [Maximo(20)],
            regrasDescricao: [Obrigatorio(), Minimo(5)],
            regrasNumeroAtendimento: [Obrigatorio(), Minimo(1)],
            regrasNome: [Obrigatorio(), Minimo(5), Maximo(50)],
            regrasEmpresa: [Obrigatorio(), Minimo(2), Maximo(100)],
            opcaoSituacao: situacaoDescricao(),
            opcaoNumeroAtendimento: [],
            opcaoTemplate: [
                {
                    texto: 'tnsys_boas_vindas',
                    valor: 4,
                    template: `Olá,<br /><br />

Meu nome é *{{1}}* e represento a *{{2}}*.<br /><br />

{{3}}`,
                    botoes: null
                },
                {
                    texto: 'tnsys_intermodal_2024',
                    valor: 5,
                    template: `Olá, sou o {{1}} da empresa {{2}}, estaremos presentes na Intermodal 2024. <br /><br />

{{3}}`,
                    botoes: { '1': '05/03', '2': '06/03', '7': '07/03' }
                }
            ],
            carregandoWhatsappNumeroAtendimento: false,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            autoCompleta: {
                usuario: {
                    datatable: {
                        id_datatable: 'usuario_id_usuario',
                        chave_primaria: 'usuario_id_usuario',
                        url: `${API_URL_BASE}${API_URL.erp.whatsappatendente.datatable}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.whatsappatendente.buscaUsuario}`,
                        colunas: [
                            {
                                id: 'id_usuario',
                                nome: 'id_usuario',
                                descricao: 'ID Usuário'
                            },
                            {
                                id: 'nome_completo',
                                nome: 'nome_completo',
                                descricao: 'Nome'
                            }
                        ]
                    }
                }
            }
        };
    }
};
</script>
