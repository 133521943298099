<template>
    <v-menu
        ref="menu1"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="auto"
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :value="dateFormatted"
                :label="label"
                :rules="rules"
                :disabled="disabled"
                v-maska="campoFormatacao"
                :masked="!!campoFormatacao"
                v-bind="attrs"
                v-on="on"
                @click:clear="$emit('input', null)"
                @input="dataManual"
                prepend-inner-icon="mdi-calendar"
                outlined
                clearable
                dense
            ></v-text-field>
        </template>

        <v-date-picker v-model="date" :value="date" @input="atualizaValor" landscape show-adjacent-months show-current>
        </v-date-picker>
    </v-menu>
</template>

<script>
import moment from 'moment';
import { mask } from 'vue-the-mask';

export default {
    directives: { mask },
    data() {
        return {
            campoFormatacao: '##/##/####',
            date: this.dataInicial || null,
            menu: false
        };
    },
    watch: {
        '$attrs.value': function() {
            if (!this.$attrs.value) {
                this.date = null;

                return;
            }

            this.date = this.$attrs.value;
        }
    },
    computed: {
        dateFormatted() {
            return this.date
                ? moment(this.date)
                      .locale('pt-br')
                      .format('L')
                : '';
        }
    },
    props: {
        form: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        },
        campo: {
            type: String
        },
        label: {
            type: String,
            default: ''
        },
        dataInicial: {
            type: String,
            default: ''
        },
        rules: {
            type: Array,
            default: function() {
                return [];
            }
        }
    },
    methods: {
        dataManual(data) {
            if (moment(data, 'DD/MM/YYYY', true).isValid()) {
                this.date = moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD');

                this.atualizaValor(this.date);
            }
        },
        atualizaValor(valor) {
            this.menu = false;
            this.$emit('input', valor);
        }
    }
};
</script>
