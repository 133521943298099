<template>
    <v-dialog v-model="abre" persistent>
        <v-card class="mx-auto" outlined>
            <v-card-title class="text-h5">
                Arquivo

                <v-spacer></v-spacer>

                <v-icon color="red" @click="$emit('fecha')">
                    mdi-close-thick
                </v-icon>
            </v-card-title>

            <v-card-text>
                <v-img :src="imagem" :lazy-src="imagem" class="grey lighten-2 ma-2"></v-img>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ModalImagem',
    props: {
        abre: {
            type: Boolean,
            required: false
        },
        imagem: {
            type: String,
            required: true
        }
    },
    methods: {},
    data() {
        return {};
    }
};
</script>
