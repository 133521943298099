import { API_URL_BASE, API_URL } from '@/config/configs';

const Noticia = {
    datatable: {
        id_datatable: 'noticia',
        chave_primaria: 'id_noticia',
        url: `${API_URL_BASE}${API_URL.erp.noticia.datatable}`,
        colunas: false,
    },
    formRefsComponente: 'NoticiaForm',
    formNome: 'formNoticia',
    moduloNome: 'noticia',
    chavePrimaria: 'id_noticia',
    componenteArquivo: 'Noticia/Form.vue',
    tabRefs: 'tabNoticia',
    urlNovoRegistro: '',
    urlConsultaRegistro: 'listaPorIdForm',
    tabs: []
}

export { Noticia };