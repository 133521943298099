<template>
    <v-container fluid>
        <TituloModulo />

        <AlertaMensagem dense style="width: 100%" />

        <CarregandoLogoEmpresa :carregando="carregando.mostraCarregando" :mensagem="mensagemCarregando" />

        <form-dados
            v-if="permissao"
            v-show="!carregando.mostraCarregando"
            ref="formDadosRef"
            :passoForm="passoForm"
            :id_profit_less_markup="form.id_profit_less_markup"
            :margem_lucro_percentual="form.receita_venda.margem_lucro_percentual"
            :situacao="form.situacao"
            :cliente="form.cliente"
            :custo_venda="form.custo_venda"
            :receita_venda="form.receita_venda"
            :despesa_operacional="form.despesa_operacional"
            :reprovados="form.reprovados"
            @terminar="salvaForm"
            @aprovar="aprovar"
            @recusar="recusar"
        />

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import { API_URL } from '@/config/configs';
import { Alerta } from '@/helpers/Funcoes.js';
import ApiService from '@/services/ApiService.js';
import { sleep } from '@/helpers/Funcoes';

import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';

import FormDados from '@/Modulo/ProfitLessMarkup/Componente/FormDados.vue';

export default {
    components: {
        CarregandoLogoEmpresa,
        TituloModulo,
        BarraNavegacaoBotaoV3,
        AlertaMensagem,
        FormDados
    },
    async mounted() {
        this.id_profit_less_markup = parseInt(this.$route.params.id_profit_less_markup) ?? null;
        this.url = this.$route.params.url ?? null;

        if (this.id_profit_less_markup) {
            this.passoForm = 5;
        }

        await this.carregaDados();
    },
    computed: {
        ...mapGetters(['botoesComPermissao']),
        ...mapState(['carregando'])
    },
    methods: {
        ...mapActions(['montaNavegacao', 'mostraCarregando']),
        async carregaDados() {
            try {
                this.mostraCarregando(true);

                await this.buscaPermissoes();

                if (this.id_profit_less_markup && this.url == null) {
                    await this.buscaForm();
                }

                if (this.id_profit_less_markup && this.url == 'aprovacao') {
                    await this.buscaFormAprovacao();
                }

                if (this.id_profit_less_markup && this.url == 'reprovado') {
                    await this.buscaFormReprovado();
                }

                this.permissao = true;
            } catch (erro) {
                Alerta([erro.message], 'error');
            } finally {
                this.mostraCarregando(false);
            }
        },
        async buscaPermissoes() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.profitlessmarkup.permissao}`,
                data: {},
                mostraCarregando: false
            })
                .then(async resposta => {
                    this.permissoes = resposta.data.data.atributos;
                })
                .catch(error => {
                    throw new Error(error);
                });
        },
        async buscaForm() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.profitlessmarkup.busca}/${this.id_profit_less_markup}`,
                data: {},
                mostraCarregando: false
            })
                .then(async resposta => {
                    if (resposta.data.data?.atributos) {
                        this.preencheDados(resposta.data.data.atributos);
                    }
                })
                .catch(error => {
                    throw new Error(error);
                });
        },
        async buscaFormAprovacao() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.profitlessmarkup.busca}/${this.id_profit_less_markup}/aprovacao`,
                data: {},
                mostraCarregando: false
            })
                .then(async resposta => {
                    if (resposta.data.data?.atributos) {
                        this.preencheDados(resposta.data.data.atributos);
                    }
                })
                .catch(error => {
                    throw new Error(error);
                });
        },
        async buscaFormReprovado() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.profitlessmarkup.busca}/${this.id_profit_less_markup}/reprovado`,
                data: {},
                mostraCarregando: false
            })
                .then(async resposta => {
                    if (resposta.data.data?.atributos) {
                        this.preencheDados(resposta.data.data.atributos);
                    }
                })
                .catch(error => {
                    throw new Error(error);
                });
        },
        preencheDados(dadosForm) {
            this.form.id_profit_less_markup = dadosForm.id_profit_less_markup;
            this.form.situacao = dadosForm.situacao;

            this.form.cliente = {
                id_profit_less_markup: dadosForm.id_profit_less_markup,
                lucro_bruto_valor: dadosForm.lucro_bruto_valor,
                lucro_bruto_percentual: dadosForm.lucro_bruto_percentual,
                margem_bruta_valor: dadosForm.margem_bruta_valor,
                margem_bruta_percentual: dadosForm.margem_bruta_percentual,
                situacao: dadosForm.situacao,
                id_parceiro: dadosForm.id_parceiro,
                tipo_operacao: dadosForm.tipo_operacao,
                parceiro: {
                    id_parceiro: dadosForm.parceiro.id_parceiro,
                    razao_social: dadosForm.parceiro.razao_social
                },
                valor_mercadoria: dadosForm.valor_mercadoria,
                endereco_origem: dadosForm.endereco_origem,
                endereco_destino: dadosForm.endereco_destino,
                rota_km: dadosForm.rota_km,
                rota_duracao: dadosForm.rota_duracao,
                rota_pedagio: dadosForm.rota_pedagio,
                rota_pedagio_eixo: dadosForm.rota_pedagio_eixo,
                quantidade_embarques: dadosForm.quantidade_embarques,
                quantidade_eixos: dadosForm.quantidade_eixos,
                calcular_ida_volta: dadosForm.calcular_ida_volta,
                tipo_carga: dadosForm.tipo_carga,
                tipo_frete: dadosForm.tipo_frete
            };

            this.form.custo_venda = dadosForm.custo_venda;
            this.form.receita_venda = dadosForm.receita_venda;
            this.form.despesa_operacional = dadosForm.despesa_operacional;
            this.form.reprovados = dadosForm.reprovados;
        },
        FormataDadosParaEnviarForm() {
            const dados = this.$refs.formDadosRef.preparaDadosEnvio();

            let dadosFormatados = { ...dados.cliente, ...dados };

            delete dadosFormatados['cliente'];

            if (this.id_profit_less_markup) {
                dadosFormatados.id_profit_less_markup = this.id_profit_less_markup;
            }

            return dadosFormatados;
        },
        async salvaForm() {
            try {
                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', 'Salvar registro ?', {
                        cor: 'green'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        this.mostraCarregando(true);

                        this.mensagemCarregando = 'Salvando Markup, aguarde...';

                        let conteudoFormulario = this.FormataDadosParaEnviarForm();

                        let urlForm = this.id_profit_less_markup
                            ? `${API_URL.erp.profitlessmarkup.salva}/${this.id_profit_less_markup}`
                            : `${API_URL.erp.profitlessmarkup.salva}`;

                        let urlConfig = {
                            method: this.id_profit_less_markup ? 'PUT' : 'POST',
                            url: urlForm,
                            data: conteudoFormulario,
                            mostraCarregando: false
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                let situacaoDescricao = 'aprovado';

                                if (resposta.data.data.atributos?.situacao == 2) {
                                    situacaoDescricao = 'em aprovação';
                                }

                                this.$router.push({
                                    name: 'profit-less-markup-grid',
                                    params: {
                                        mensagem: `Markup com ID ${resposta.data.data.atributos.id_profit_less_markup} está ${situacaoDescricao}.`
                                    }
                                });
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (erro) {
                Alerta([erro.message], 'error');
            } finally {
                this.mostraCarregando(false);
            }
        },
        async aprovar() {
            try {
                await this.$root.$refs.$confirmaAcaoModal
                    .open('Aprovar', 'Aprovar Markup ?', {
                        cor: 'green'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        this.mostraCarregando(true);

                        this.mensagemCarregando = 'Aprovando Markup, aguarde...';

                        let urlForm = `${API_URL.erp.profitlessmarkup.salva}/${this.id_profit_less_markup}/aprovacao`;

                        let urlConfig = {
                            method: 'POST',
                            url: urlForm,
                            data: {},
                            mostraCarregando: false
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                this.$router.push({
                                    name: 'profit-less-markup-grid',
                                    params: {
                                        mensagem: `Markup aprovado, se o processo não foi aprovado, aguarde os outros aprovadores.`
                                    }
                                });
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (erro) {
                Alerta([erro.message], 'error');
            }

            this.mostraCarregando(false);
        },
        async recusar(dados) {
            try {
                this.mostraCarregando(true);

                this.mensagemCarregando = 'Registrando a recusa, aguarde...';

                const urlForm = `${API_URL.erp.profitlessmarkup.reprova}`.replace(
                    '${id_registro}',
                    this.id_profit_less_markup
                );

                dados.condicional = dados.condicional == true ? 'S' : 'N';

                let urlConfig = {
                    method: 'POST',
                    url: urlForm,
                    data: {
                        recusa: dados.recusa,
                        condicional: dados.condicional
                    },
                    mostraCarregando: false
                };

                await ApiService(urlConfig)
                    .then(resposta => {
                        this.$router.push({
                            name: 'profit-less-markup-grid',
                            params: { mensagem: `Markup com ID ${this.id_profit_less_markup} registrado a recusa.` }
                        });
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                Alerta([erro.message], 'error');
            } finally {
                this.mostraCarregando(false);
            }
        }
    },
    data() {
        return {
            id_profit_less_markup: null,
            url: null,
            passoForm: 1,
            tabComponente: 0,
            tabDadosTotalErros: 0,
            mensagemCarregando: 'Aguarde, preparando Markup...',
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            permissao: false,
            permissoes: [],
            botoes: [
                {
                    id_permissao: [2, 3],
                    nome: 'Salvar',
                    acao: 'salvaForm',
                    icone: 'mdi-newspaper-variant-multiple',
                    cor: 'green',
                    form: ['todos']
                }
            ],
            form: {
                id_profit_less_markup: null,
                cliente: {},
                custo_venda: {},
                despesa_operacional: {},
                receita_venda: {},
                reprovados: []
            }
        };
    }
};
</script>
