<template>
    <div>
        <center>
            <my-upload
                field="imagem"
                @crop-success="cropSuccess"
                @crop-upload-success="cropUploadSuccess"
                @crop-upload-fail="cropUploadFail"
                v-model="show"
                :width="200"
                :height="200"
                :headers="headers"
                :langExt="langExt"
                img-format="png"
                disabled
            ></my-upload>
            <img :src="imgSrc" style="caret-color: transparent" :width="150" :height="150" class="rounded-circle" />

            <v-btn style="position: absolute" fab dark small color="orange darken-3" @click="toggleShow">
                <v-icon dark> mdi-camera </v-icon>
            </v-btn>
            <h6 class="subtitle-2">
                {{ nome || nomePadrao }}
            </h6>
            <h6 class="subtitle-2">
                {{ cargo }}
            </h6>
        </center>
    </div>
</template>

<script>
import myUpload from 'vue-image-crop-upload/upload-2.vue';
import { isEmpty } from 'lodash';
import { mapGetters } from 'vuex';

export default {
    name: 'Avatar',
    components: {
        'my-upload': myUpload
    },

    watch: {
        fotoUrl(newValue) {
            this.imgDataUrl = null;
        }
    },

    computed: {
        ...mapGetters({ token: 'autenticacao/getToken' }),

        headers() {
            return {
                Authorization: 'Bearer ' + this.token
            };
        },

        imgSrc() {
            if (!isEmpty(this.imgDataUrl)) {
                return this.imgDataUrl;
            }

            if (!isEmpty(this.fotoUrl)) {
                return this.fotoUrl;
            }

            return require('@/assets/image/perfil/avatar_default.jpg');
        }
    },

    props: {
        url: {
            type: String,
            required: true
        },
        nome: {
            type: String,
            required: false
        },
        imgDefault: {
            type: String,
            required: false
        },
        fotoUrl: {
            type: String,
            required: false
        },
        cargo: {
            type: String,
            required: false
        },
        nomePadrao: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            show: false,
            imgDataUrl: '', // the datebase64 url of created image
            langExt: {
                hint: 'Clique ou arraste o arquivo para fazer upload',
                loading: 'Fazendo upload…',
                noSupported: 'Navegador não suportado, utilize um navegador mais atualizado',
                success: 'Upload realizado com sucesso',
                fail: 'Upload falhou',
                preview: 'visualização',
                btn: {
                    off: 'Cancelar',
                    close: 'Fechar',
                    back: 'Voltar',
                    save: 'Salvar'
                },
                error: {
                    onlyImg: 'Apenas imagens',
                    outOfSize: 'Imagem excedeu o tamanho limite: ',
                    lowestPx: 'Imagem é muito pequena. É esperado no minimo: '
                }
            }
        };
    },

    methods: {
        toggleShow() {
            this.show = !this.show;
        },
        cropSuccess(imgDataUrl, field) {
            this.imgDataUrl = imgDataUrl;

            this.$emit('upload', imgDataUrl);
        },
        cropUploadSuccess(jsonData) {
            //this.$emit('upload', jsonData.data.id_registro);
        },
        cropUploadFail(status, field) {}
    }
};
</script>
