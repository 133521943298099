<template>
    <v-container fluid class="pa-0 ma-0">
        <v-list-item v-if="envioRemetente">
            <v-spacer></v-spacer>

            <v-list-item-content>
                <v-list-item-content class="body-1 pl-8">
                    <v-card max-width="350px" color="grey lighten-3" class="ml-16 mx-auto" elevation="2">
                        <v-spacer></v-spacer>

                        <v-card-text>
                            <div v-if="media" class="mb-2">
                                <mensagem-arquivo
                                    :url_arquivo="media.imagem"
                                    :mime_type="media.mime_type"
                                    @abre-imagem="$emit('abre-imagem', $event)"
                                />
                            </div>

                            <div class="mb-2 quebraLinha">
                                <span v-html="formataTexto(texto)"> </span>
                            </div>

                            <div class="text-caption text-end">
                                {{ dataEnvioFormatada }}

                                <v-icon small color="green" v-if="dataLeitura"> mdi-check-all </v-icon>

                                <v-icon small v-else-if="dataRecebe"> mdi-check </v-icon>

                                <v-icon small v-else> mdi-clock </v-icon>

                                <v-tooltip bottom v-if="mensagemComErro">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="red accent-3" class="pl-1" v-bind="attrs" v-on="on" small>
                                            mdi-alert-circle
                                        </v-icon>
                                    </template>
                                    <span>{{ mensagemErro }}</span>
                                </v-tooltip>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-list-item-content>
            </v-list-item-content>
        </v-list-item>

        <v-list-item v-else>
            <v-list-item-content>
                <v-list-item-content class="body-1">
                    <v-card color="indigo accent-4" max-width="350px" elevation="2">
                        <v-card-text>
                            <div v-if="media" class="mb-2">
                                <mensagem-arquivo
                                    :url_arquivo="media.imagem"
                                    :mime_type="media.mime_type"
                                    @abre-imagem="$emit('abre-imagem', $event)"
                                />
                            </div>

                            <div class="mb-2 white--text quebraLinha">
                                {{ texto }}
                            </div>

                            <div class="text-caption text-end white--text">
                                {{ dataEnvioFormatada }}
                            </div>
                        </v-card-text>
                    </v-card>
                </v-list-item-content>
            </v-list-item-content>
        </v-list-item>
    </v-container>
</template>

<script>
import moment from 'moment';

import MensagemArquivo from './MensagemArquivo.vue';

export default {
    name: 'Mensagem',
    components: {
        MensagemArquivo,
    },
    props: {
        texto: {
            type: String,
            default: () => '',
        },
        dataEnvio: {
            type: String,
            default: () => '',
        },
        dataRecebe: {
            type: String,
            default: () => '',
        },
        dataLeitura: {
            type: String,
            default: () => '',
        },
        envioRemetente: {
            type: Boolean,
            required: true,
        },
        mensagemErro: {
            type: String,
            default: () => '',
        },
        media: {
            type: Object,
            default: () => {
                return {
                    id_whatsapp_mensagem: null,
                    imagem: null,
                    mime_type: null,
                };
            },
        },
    },
    computed: {
        dataEnvioFormatada() {
            return moment(this.dataEnvio).format('DD/MM/YYYY HH:mm');
        },
        mensagemComErro() {
            return this.mensagemErro.length ? true : false;
        },
    },
    methods: {
        formataTexto(texto) {
            let regras = [
                { padrao: /\*(.*?)\*/g, repassar: '<b>$1</b>' },
                { padrao: /\_(.*?)\_/g, repassar: '<i>$1</i>' },
            ];

            regras.forEach(function (regra) {
                texto = texto.replace(regra.padrao, regra.repassar);
            });

            return texto;
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped>
.quebraLinha {
    white-space: pre-line;
}
</style>
