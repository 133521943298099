<template>
    <v-form ref="form">
        <v-row>
            <v-col cols="12" sm="8">
                <InputTextV3 v-model="form.id_profit_less_markup" rotulo="ID" :contador="200" v-show="false" />

                <InputSelectV3
                    v-model="form.tipo_operacao"
                    rotulo="Tipo Operação"
                    :itens="opcaoTipoOperacao"
                    :regra="regraTipoOperacao"
                    itemTexto="descricao"
                    itemValor="tipo_operacao"
                />
            </v-col>

            <v-col cols="12" sm="4">
                <InputTextMoedaV3 v-model="form.valor_mercadoria" rotulo="Valor Mercadoria" prefixo="R$" />
            </v-col>

            <v-col cols="12" sm="6">
                <InputAutoCompleteV3
                    v-model="form.id_parceiro"
                    :datatableConfiguracao="autoCompleta.parceiro.datatable"
                    :itemsOpcoes="itensParceiro"
                    :regra="regraParceiro"
                    tituloBusca="Cliente Busca..."
                    itemValor="id_parceiro"
                    itemTexto="razao_social"
                    ref="parceiro"
                />
            </v-col>

            <v-col cols="12" sm="6">
                <InputTextV3 v-model="form.quantidade_embarques" rotulo="Quantidade Embarques" :contador="200" clearable />
            </v-col>

            <v-col cols="12" sm="3">
                <InputSelectV3
                    v-model="form.tipo_frete"
                    :itens="opcaoTipoFrete"
                    :regra="regrasTipoFrete"
                    rotulo="Tipo Frete"
                    itemTexto="descricao"
                    itemValor="id"
                />
            </v-col>

            <v-col cols="12" sm="3">
                <InputSelectV3
                    v-model="form.tipo_carga"
                    :itens="opcaoTipoCarga"
                    :regra="regrasTipoCarga"
                    rotulo="Tipo Carga"
                    itemTexto="descricao"
                    itemValor="id"
                    @change="mudaQuantidadeEixos()"
                />
            </v-col>

            <v-col cols="12" sm="3">
                <InputSelectV3
                    v-model="form.quantidade_eixos"
                    :itens="opcaoQuantidadeEixos"
                    :regra="regrasQuantidadeEixos"
                    @change="mudaQuantidadeEixos()"
                    rotulo="Quantidade Eixos"
                    itemTexto="descricao"
                    itemValor="quantidade"
                />
            </v-col>

            <v-col cols="12" sm="3">
                <ButtonSwitchV3 v-model="form.calcular_ida_volta" rotulo="Calcula ida e volta ?" :mostraIcone="false" />
            </v-col>

            <v-col cols="12" sm="6">
                <InputAutoCompleteV3
                    v-model="form.endereco_origem"
                    :datatableConfiguracao="autoCompleta.endereco.datatable"
                    :itemsOpcoes="itensEnderecoOrigem"
                    :regra="regraEnderecoOrigem"
                    tituloBusca="Endereço Origem Busca..."
                    itemValor="endereco_completo"
                    itemTexto="endereco_completo"
                    ajudaMensagem="Consulta na plataforma QUALP"
                    ref="endereco_origem"
                />
            </v-col>

            <v-col cols="12" sm="6">
                <InputAutoCompleteV3
                    v-model="form.endereco_destino"
                    :datatableConfiguracao="autoCompleta.endereco.datatable"
                    :itemsOpcoes="itensEnderecoDestino"
                    :regra="regraEnderecoDestino"
                    tituloBusca="Endereço Destino Busca..."
                    itemValor="endereco_completo"
                    itemTexto="endereco_completo"
                    ajudaMensagem="Consulta na plataforma QUALP"
                    ref="endereco_destino"
                />
            </v-col>

            <v-col cols="12" sm="12" class="pt-0 mt-0 body-1 font-weight-bold grey--text text--darken-2">
                Selecione a Rota:
                <v-chip-group v-model="rotaSelecionada" v-if="rotas.length" active-class="orange lighten-3" column mandatory>
                    <v-chip v-for="(rota, index) in rotas" :key="`rota-${index}`" @click="mudaRota(index)" class="pa-10" label>
                        <v-icon left>
                            mdi-routes
                        </v-icon>
                        {{ rota.descricao }} <br />
                        {{ rota.distancia_texto }} <br />
                        {{ rota.duracao }}
                    </v-chip>
                </v-chip-group>

                <v-chip v-else class="ma-2" color="warning" label text-color="white">
                    <v-icon left>
                        mdi-routes
                    </v-icon>
                    Opção indisponível, calcule a rota no QUALP.
                </v-chip>
            </v-col>

            <v-col cols="12" sm="3">
                <InputTextV3
                    v-model="form.rota_km"
                    tipo="number"
                    rotulo="KM"
                    :contador="10"
                    :regra="regrasRotaPedagio"
                    :desativado="desativaSeTiverOrigemDestino"
                    ajudaMensagem="KM calculado com a rota dos campos Endereço de Origem x Endereço de Destino"
                />
            </v-col>

            <v-col cols="12" sm="3">
                <InputTextV3
                    v-model="form.rota_duracao"
                    rotulo="Duração"
                    :desativado="true"
                    ajudaMensagem="Tempo calculado com a rota dos campos Endereço de Origem x Endereço de Destino"
                />
            </v-col>

            <v-col cols="12" sm="3">
                <InputTextMoedaV3
                    v-model="form.pedagio"
                    rotulo="Pedágio"
                    :contador="10"
                    :regra="regrasRotaPedagio"
                    :desativado="desativaSeTiverOrigemDestino"
                    ajudaMensagem="Valor total das praças de pedágio referente a rota dos campos Endereço de Origem x Endereço de Destino"
                />
            </v-col>

            <v-col cols="12" sm="3">
                <InputTextMoedaV3
                    v-model="form.pedagio_eixo"
                    rotulo="Pedágio por eixo"
                    :desativado="true"
                    ajudaMensagem='Valor médio da tarifa de pedágio por eixo do valor total do pedágio (Soma das tarifas de
                pedágio / eixos). <br />
                Para saber o valor exato por eixo de cada praça utilize a tabela "Praças de Pedágios" abaixo.'
                />
            </v-col>

            <v-col cols="12" sm="6">
                <InputTextMoedaV3
                    v-model="form.valor_carga"
                    rotulo="Valor Carga - Tabela A"
                    :desativado="true"
                    ajudaMensagem="RESOLUÇÃO ANTT Nº 5.867, DE 28/08/2023 <br /><br />
De acordo com a ANTT, a tabela frete é composta de 4 tabelas com preços diferentes. Segue uma breve explicação sobre elas: <br /><br />
TABELA A - TRANSPORTE RODOVIÁRIO DE CARGA LOTAÇÃO: Esta tabela é a mais utilizada no Brasil, referente as operações completas, onde é utilizado o conjunto (caminhão + carreta) de um transportadora ou motorista autônomo.<br /><br />
TABELA B - OPERAÇÕES EM QUE HAJA A CONTRATAÇÃO APENAS DO VEÍCULO AUTOMOTOR DE CARGAS: Como o próprio nome já diz, quando há contratação apenas do veículo. Resumindo em caso de AGREGADOS. Um exemplo: Pessoa A tem um caminhão e fecha acordo com uma empresa B para usar sua carreta, sendo AGREGADO.<br /><br />
As tabelas C e D são montadas com base nas duas primeiras, mas levando em conta operações de ALTO DESEMPENHO, que levam um tempo menor para carga e descarga do produto. Na resolução anterior essa distinção não existia.<br /><br />
Observação: Os preços informados não levam em consideração os valores de pedágios e impostos."
                />
            </v-col>

            <v-col cols="12" sm="12" v-if="pedagioItens.length">
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            PEDÁGIO(S)
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-md-body-1 font-weight-black">Praça</th>
                                            <th class="text-md-body-1 font-weight-black">Tarifa</th>
                                        </tr>
                                    </thead>
                                    <tbody class="body-2 font-weight-regular grey--text text--darken-2">
                                        <tr v-for="(item, index) in pedagioItens" :key="`pedagios-${index}`">
                                            <td>
                                                <span class="text-md-body-1 font-weight-black">
                                                    {{ item.nome }}
                                                </span>

                                                <br />

                                                {{ item.rodovia }} - KM {{ item.km }}
                                            </td>
                                            <td>
                                                {{ formataMoeda(item.tarifa[form.quantidade_eixos]) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { API_URL, API_URL_BASE } from '@/config/configs';
import { Minimo, Obrigatorio } from '@/helpers/ValidadorCampo.js';

import { FormataMoeda } from '@/helpers/Funcoes.js';

import TipoOperacaoEnum, {
    descricao as TipoOperacaoDescricao
} from '@/Modulo/ProfitLessMarkup/Dominio/Enum/TipoOperacaoEnum.js';

import InputTextMoedaV3 from '@/components/Form/InputTextMoedaV3.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';
import InputTextV3 from '@/components/Form/InputTextV3.vue';
import InputAutoCompleteV3 from '@/components/Form/InputAutoCompleteV3.vue';
import ButtonSwitchV3 from '@/components/Form/ButtonSwitchV3.vue';

export default {
    name: 'FormDespesaOperacional',
    components: {
        InputTextMoedaV3,
        InputSelectV3,
        InputTextV3,
        InputAutoCompleteV3,
        ButtonSwitchV3
    },
    props: {
        id_profit_less_markup: {
            type: Number,
            default: () => null
        },
        tipo_operacao: {
            type: Number,
            default: () => null
        },
        valor_mercadoria: {
            type: Number,
            default: () => 0
        },
        quantidade_embarques: {
            type: String,
            default: () => ''
        },
        endereco_origem: {
            type: String,
            default: () => ''
        },
        endereco_destino: {
            type: String,
            default: () => ''
        },
        rota_km: {
            type: Number,
            default: () => 0
        },
        rota_duracao: {
            type: String,
            default: () => ''
        },
        quantidade_eixos: {
            type: Number,
            default: () => 2
        },
        tipo_frete: {
            type: Number,
            default: () => null
        },
        pedagio: {
            type: Number,
            default: () => 0
        },
        pedagio_eixo: {
            type: Number,
            default: () => 0
        },
        tipo_carga: {
            type: Number,
            default: () => 3
        },
        calcular_ida_volta: {
            type: Boolean,
            default: () => false
        },
        valor_carga: {
            type: Number,
            default: () => 0
        },
        id_parceiro: {
            type: Number,
            default: () => 0
        },
        parceiroItens: {
            type: Array,
            default: () => []
        },
        enderecoOrigemItens: {
            type: Array,
            default: () => []
        },
        enderecoDestinoItens: {
            type: Array,
            default: () => []
        },
        rotaDescricao: {
            type: Array,
            default: () => []
        },
        pedagioItens: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        pedagio: {
            handler() {
                this.form.pedagio = this.pedagio;
            },
            immediate: true
        },
        pedagio_eixo: {
            handler() {
                this.form.pedagio_eixo = this.pedagio_eixo;
            },
            immediate: true
        },
        quantidade_eixos: {
            handler() {
                this.form.quantidade_eixos = this.quantidade_eixos;
            },
            immediate: true
        },
        valor_carga: {
            handler() {
                this.form.valor_carga = this.valor_carga;
            },
            immediate: true
        },
        tipo_carga: {
            handler(novoValor) {
                if (!novoValor) {
                    this.form.tipo_carga = 3;
                }
            },
            immediate: true
        },
        rota_km: {
            handler() {
                this.form.rota_km = this.rota_km;
            },
            immediate: true
        },
        rota_duracao: {
            handler() {
                this.form.rota_duracao = this.rota_duracao;
            },
            immediate: true
        },
        parceiroItens: {
            handler() {
                this.itensParceiro = this.parceiroItens;
            },
            immediate: true
        },
        enderecoOrigemItens: {
            handler(data) {
                this.itensEnderecoOrigem = data;
            },
            immediate: true
        },
        enderecoDestinoItens: {
            handler(data) {
                this.itensEnderecoDestino = data;
            },
            immediate: true
        },
        rotaDescricao: {
            handler(data) {
                this.rotas = data;
            },
            immediate: true
        },
        'form.valor_carga': {
            handler(data) {
                this.mudaValorCarga();
            },
            deep: true
        }
    },
    computed: {
        desativaSeTiverOrigemDestino() {
            if (this.form.endereco_origem && this.form.endereco_destino) {
                return true;
            }

            return false;
        }
    },
    methods: {
        limpaDadosRota() {
            this.form.rota_km = 0;
            this.form.rota_duracao = '';
            this.form.pedagio = 0;
            this.form.pedagio_eixo = 0;
        },
        parceiroSelecionado() {
            const parceiro = this.$refs.parceiro.respBusca.filter((item, index) => item.id_parceiro == this.form.id_parceiro);

            if (!parceiro) {
                return [];
            }

            return parceiro[0];
        },
        tipoOperacaoSelecionado() {
            const tipoOperacao = this.opcaoTipoOperacao.filter((item, index) => item.tipo_operacao == this.form.tipo_operacao);

            if (!tipoOperacao) {
                return [];
            }

            return tipoOperacao[0];
        },
        enderecoOrigemSelecionado() {
            const endereco = this.$refs.endereco_origem.respBusca.filter(
                (item, index) => item.endereco_completo == this.form.endereco_origem
            );

            if (!endereco) {
                return [];
            }

            return endereco[0];
        },
        enderecoDestinoSelecionado() {
            const endereco = this.$refs.endereco_destino.respBusca.filter(
                (item, index) => item.endereco_completo == this.form.endereco_destino
            );

            if (!endereco) {
                return [];
            }

            return endereco[0];
        },
        formataMoeda(valor) {
            return FormataMoeda(valor, {}, true);
        },
        mudaQuantidadeEixos() {
            this.$emit('muda-quantidade-eixos');
        },
        mudaValorCarga() {
            this.$emit('muda-valor-carga', this.form.valor_carga);
        },
        mudaRota(id_rota) {
            this.$emit('muda-rota', id_rota);
        }
    },
    data() {
        return {
            form: {
                id_profit_less_markup: this.id_profit_less_markup,
                endereco_origem: this.endereco_origem,
                endereco_destino: this.endereco_destino,
                rota_km: this.rota_km,
                rota_duracao: this.rota_duracao,
                pedagio: this.pedagio,
                pedagio_eixo: this.pedagio_eixo,
                tipo_operacao: this.tipo_operacao,
                valor_mercadoria: this.valor_mercadoria,
                quantidade_embarques: this.quantidade_embarques,
                id_parceiro: this.id_parceiro,
                parceiro_razao_social: '',
                quantidade_eixos: this.quantidade_eixos,
                tipo_frete: this.tipo_frete,
                calcular_ida_volta: this.calcular_ida_volta,
                tipo_carga: this.tipo_carga,
                valor_carga: this.valor_carga
            },
            itensEnderecoOrigem: [],
            itensEnderecoDestino: [],
            itensParceiro: [],
            rotaSelecionada: 0,
            rotas: [],
            opcaoTipoCarga: [
                { descricao: 'Conteineirizada', id: 1 },
                { descricao: 'Frigorificada', id: 2 },
                { descricao: 'Geral', id: 3 },
                { descricao: 'Granel Liquido', id: 4 },
                { descricao: 'Granel Pressurizada', id: 5 },
                { descricao: 'Granel Solido', id: 6 },
                { descricao: 'Neogranel', id: 7 },
                { descricao: 'Perigosa Conteineirizada', id: 8 },
                { descricao: 'Perigosa Frigorificada', id: 9 },
                { descricao: 'Perigosa Geral', id: 10 },
                { descricao: 'Perigosa Granel Líquido', id: 11 },
                { descricao: 'Perigosa Granel Solido', id: 12 }
            ],
            opcaoQuantidadeEixos: [
                { descricao: '2 Eixos', quantidade: 2 },
                { descricao: '3 Eixos', quantidade: 3 },
                { descricao: '4 Eixos', quantidade: 4 },
                { descricao: '5 Eixos', quantidade: 5 },
                { descricao: '6 Eixos', quantidade: 6 },
                { descricao: '7 Eixos', quantidade: 7 },
                { descricao: '8 Eixos', quantidade: 8 },
                { descricao: '9 Eixos', quantidade: 9 },
                { descricao: '10 Eixos', quantidade: 10 },
                { descricao: '11 Eixos', quantidade: 11 },
                { descricao: '12 Eixos', quantidade: 12 },
                { descricao: '13 Eixos', quantidade: 13 },
                { descricao: '14 Eixos', quantidade: 14 },
                { descricao: '15 Eixos', quantidade: 15 }
            ],
            opcaoTipoFrete: [
                { descricao: 'Frota', id: 1 },
                { descricao: 'Terceiro', id: 2 }
            ],
            opcaoTipoOperacao: TipoOperacaoDescricao(),
            regraEnderecoOrigem: [],
            regraEnderecoDestino: [],
            regraParceiro: [Obrigatorio(), Minimo(1)],
            regraTipoOperacao: [Obrigatorio(), Minimo(1)],
            regrasRotaKm: [Obrigatorio(), Minimo(1)],
            regrasRotaPedagio: [Obrigatorio(), Minimo(1)],
            regrasTipoCarga: [Obrigatorio(), Minimo(1)],
            regrasQuantidadeEixos: [Obrigatorio(), Minimo(1)],
            regrasTipoFrete: [Obrigatorio(), Minimo(1)],
            autoCompleta: {
                parceiro: {
                    datatable: {
                        id_datatable: 'parceiro_id_parceiro',
                        chave_primaria: 'parceiro_id_parceiro',
                        url: `${API_URL_BASE}${API_URL.erp.profitlessmarkup.datatable}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.profitlessmarkup.buscaParceiroRodopar}`,
                        colunas: [
                            {
                                id: 'id_parceiro',
                                nome: 'id_parceiro',
                                descricao: 'ID Rodopar'
                            },
                            {
                                id: 'razao_social',
                                nome: 'razao_social',
                                descricao: 'Razão Social'
                            }
                        ]
                    }
                },
                endereco: {
                    datatable: {
                        id_datatable: 'endereco',
                        chave_primaria: 'endereco',
                        url: `${API_URL_BASE}${API_URL.erp.profitlessmarkup.datatable}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.profitlessmarkup.buscaEnderecoQualp}`,
                        colunas: [
                            {
                                id: 'endereco_completo',
                                nome: 'endereco_completo',
                                descricao: 'Endereço'
                            }
                        ]
                    }
                }
            }
        };
    }
};
</script>
