<template>
    <v-dialog v-model="abreFechaModal" persistent max-width="800px">
        <v-card>
            <v-toolbar color="indigo darken-2" dark>
                <v-toolbar-title>Finaliza Atendimento e Chamado</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon @click="fechar">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="pt-5">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="12">
                            Finalizar chamado ? <br />
                            <br />
                            Se habilitado o chamado será encerrado junto com o atendimento. <br />
                            Se não for habilitado, somente o atendimento será encerrado, o chamado ficará com a situação em
                            atendimento.
                            <ButtonSwitchV3
                                v-model="finaliza_chamado"
                                :mostraIcone="true"
                                :desativado="finalizaChamadoDesativado"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="fechar">
                    Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="salvar">
                    Finalizar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { Minimo, Obrigatorio } from '@/helpers/ValidadorCampo.js';

import TipoAtendimento from '../Enum/TipoAtendimento';

import ButtonSwitchV3 from '@/components/Form/ButtonSwitchV3.vue';

export default {
    name: 'FinalizaModal',
    components: {
        ButtonSwitchV3
    },
    data() {
        return {
            abreFechaModal: false,
            id_whatsapp_fluxo_conversa: null,
            tipo_atendimento: null,
            finaliza_chamado: false,
            regrasConsideracao: [Obrigatorio(), Minimo(10)]
        };
    },
    watch: {
        abreFechaModal: {
            handler: function(novoValor) {
                if (!novoValor) {
                    this.resetaCampos();
                }
            },
            deep: true
        }
    },
    computed: {
        finalizaChamadoDesativado() {
            const desativa = this.tipo_atendimento == TipoAtendimento.FLUXO ? false : true;

            this.finaliza_chamado = desativa ? true : false;

            return desativa;
        }
    },
    methods: {
        async salvar() {
            this.$emit('finalizar', {
                id_whatsapp_fluxo_conversa: this.id_whatsapp_fluxo_conversa,
                finaliza_chamado: this.finaliza_chamado
            });

            this.fechar();
        },
        fechar() {
            this.abreFechaModal = false;
        },
        resetaCampos() {
            this.abreFechaModal = false;
            this.id_whatsapp_fluxo_conversa = null;
            this.tipo_atendimento = null;
            this.finaliza_chamado = false;
        }
    }
};
</script>
