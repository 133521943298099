<template>
    <v-form ref="formDados">
        <v-row>
            <v-col cols="12">
                <v-badge
                    :content="totalRegistros"
                    :value="totalRegistros"
                    icon="mdi-numeric-positive-1"
                    color="deep-purple accent-4"
                    overlap
                >
                    <v-btn tile color="success" @click="adicionaAfirmacao">
                        <v-icon left> mdi-plus </v-icon>
                        Afirmações
                    </v-btn>
                </v-badge>
            </v-col>

            <v-col cols="12">
                <v-expansion-panels v-for="(afirmacao, i) in this.form.afirmacoes" :key="i" class="mt-3">
                    <v-expansion-panel>
                        <v-btn class="mt-3 mr-3 float-right" fab dark x-small color="red" @click="removeAfirmacao(i)">
                            <v-icon dark> mdi-close </v-icon>
                        </v-btn>

                        <v-expansion-panel-header>
                            <h3>Afirmação #{{ i + 1 }}</h3>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content eager>
                            <AfirmacaoForm ref="afirmacaoRef" :afirmacaoData="afirmacao" />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>

            <v-col cols="12">
                <v-badge
                    v-if="totalRegistros > 0"
                    :content="totalRegistros"
                    :value="totalRegistros"
                    icon="mdi-numeric-positive-1"
                    color="deep-purple accent-4"
                    overlap
                >
                    <v-btn tile color="success" @click="adicionaAfirmacao">
                        <v-icon left> mdi-plus </v-icon>
                        Afirmações
                    </v-btn>
                </v-badge>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { PreencheDadosMesmaPropriedade } from '@/helpers/Funcoes.js';

import AfirmacaoForm from '@/views/AvaliacaoDesempenho/AfirmacaoForm.vue';

import TextAreaV3 from '@/components/Form/TextAreaV3.vue';

export default {
    name: 'AfirmacoesForm',
    components: {
        AfirmacaoForm,
        TextAreaV3
    },
    props: {
        afirmacaoData: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    created() {
        this.afirmacaoModelo = this.form.afirmacoes[0];
    },
    mounted() {},
    computed: {
        totalRegistros() {
            return this.form.afirmacoes.length;
        }
    },
    watch: {},
    methods: {
        adicionaAfirmacao: function(dadosAfirmacao) {
            let afirmacao = {};

            if (dadosAfirmacao.hasOwnProperty('id_avaliacao_desempenho_afirmacao')) {
                afirmacao = { ...dadosAfirmacao };
            }

            this.form.afirmacoes.push(afirmacao);
        },
        removeAfirmacao(posicao) {
            const listaAfirmacoes = this.$refs.afirmacaoRef.filter((item, index) => index !== posicao);

            this.form.afirmacoes = [];

            listaAfirmacoes.forEach((dados, index) => {
                this.form.afirmacoes.push(dados.afirmacao);
            });
        },
        preparaDados() {
            let afirmacoes = [];

            this.$refs.afirmacaoRef.forEach((dados, index) => {
                afirmacoes.push(dados.afirmacao);
            });

            return afirmacoes;
        },
        preencheDados(dados) {
            this.form.afirmacoes = [];

            let self = this;

            if (dados.afirmacoes) {
                Object.keys(dados.afirmacoes).forEach(function(key) {
                    self.form.afirmacoes.push(PreencheDadosMesmaPropriedade(self.afirmacaoModelo, dados.afirmacoes[key]));
                });
            }
        }
    },
    data() {
        return {
            afirmacaoModelo: {},
            form: {
                afirmacoes: [
                    {
                        id_avaliacao_desempenho_afirmacao: null,
                        nome: null,
                        palavra_chave: null,
                        consideracao: null
                    }
                ]
            },
            regraAfirmacao: [v => _.isEmpty(v) || v.length <= 500 || 'Observação deve ter até 1000 caracteres']
        };
    }
};
</script>

<style></style>
