<template>
    <div>
        <CarregandoOverlay :overlay="overlay" mensagem="Carregando perfil, aguarde..." />

        <v-fab-transition>
            <v-btn
                v-show="!config"
                class="config-btn"
                color="purple darken-3"
                @click="config = true"
                dark
                absolute
                right
                fab
                small
                app
                fixed
                style="margin-right: -15px"
            >
                <v-icon>mdi-cog</v-icon>
            </v-btn>
        </v-fab-transition>

        <v-navigation-drawer v-model="config" class="mt-12" width="400" temporary right app fixed dark>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h5">
                        <v-icon color="warning darken-1">
                            mdi-view-dashboard
                        </v-icon>
                        Dashboard
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-h6">
                        Personalize seu dashboard
                    </v-list-item-subtitle>
                </v-list-item-content>

                <v-icon color="red darken-1" @click="config = false" right>
                    mdi-close
                </v-icon>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        Organizar painel
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="warning darken-1" dark small v-bind="attrs" v-on="on">
                                    mdi-account-question
                                </v-icon>
                            </template>
                            Use esse botão para editar o dashboard. <br />Clique sobre o dashboard e arraste, ao soltar irá
                            definir a posição.<br />
                            No canto inferir direito é possível arrastar para definir o tamanho.
                        </v-tooltip>
                    </v-list-item-title>

                    <v-list-item-subtitle>
                        <span class="pr-2">
                            <v-icon color="green lighten-2" @click="dashboardSetEdita(true)" v-if="!dashboardEdita">
                                mdi-view-dashboard-edit
                            </v-icon>

                            <v-icon color="green darken-1" @click="salvaDashboard()" v-if="dashboardEdita" left>
                                mdi-check-underline-circle
                            </v-icon>

                            <v-icon color="red darken-1" @click="dashboardSetEdita(false)" v-if="dashboardEdita" left>
                                mdi-close-circle
                            </v-icon>

                            Editar ?
                        </span>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-navigation-drawer>

        <v-row class="pa-2" v-if="overlay">
            <v-col cols="12" md="6">
                <v-skeleton-loader class="mx-auto" type="table-heading, divider, list-item-three-line"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="6">
                <v-skeleton-loader class="mx-auto" type="table-heading, divider, list-item-three-line"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="12">
                <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
            </v-col>
        </v-row>

        <v-row class="pa-2" v-if="!overlay">
            <v-col cols="12" md="12">
                <grid-layout
                    :layout.sync="paineis"
                    :col-num="12"
                    :row-height="30"
                    :is-draggable="dashboardEdita"
                    :is-resizable="dashboardEdita"
                    :responsive="true"
                    :is-mirrored="false"
                    :vertical-compact="true"
                    :margin="[10, 10]"
                    :use-css-transforms="true"
                    @layout-updated="atualizaLayout"
                >
                    <grid-item
                        v-for="dashboard in paineis"
                        :x="dashboard.x"
                        :y="dashboard.y"
                        :w="dashboard.w"
                        :h="dashboard.h"
                        :i="dashboard.i"
                        :key="dashboard.id_dashboard"
                        @resized="atualizaDimensao"
                    >
                        <OrderToDeliveryAno v-if="dashboard.id_dashboard == 1 && dashboard.ativo" />
                        <OrderToDeliveryAnoTotal v-if="dashboard.id_dashboard == 2 && dashboard.ativo" />
                        <OrderToDeliveryAnoPorUnidadeEmpredimento v-if="dashboard.id_dashboard == 3 && dashboard.ativo" />
                        <OrderToDeliveryAnoTopGrupoCliente v-if="dashboard.id_dashboard == 4 && dashboard.ativo" />
                        <OrderToDeliveryAnoTopGrupoClienteTotal v-if="dashboard.id_dashboard == 6 && dashboard.ativo" />
                        <OrderToDeliveryAnoTopRota v-if="dashboard.id_dashboard == 5 && dashboard.ativo" />
                        <OrderToDeliveryAnoTopRotaTotal v-if="dashboard.id_dashboard == 7 && dashboard.ativo" />
                        <Demanda v-if="dashboard.id_dashboard == 8 && dashboard.ativo" />
                        <DemandaTotal v-if="dashboard.id_dashboard == 9 && dashboard.ativo" />
                        <DemandaTopClientes v-if="dashboard.id_dashboard == 10 && dashboard.ativo" />
                        <DemandaProximoDia v-if="dashboard.id_dashboard == 11 && dashboard.ativo" />
                    </grid-item>
                </grid-layout>
            </v-col>

            <v-col cols="12" md="12" v-if="false">
                <OrderToDeliveryAno />
            </v-col>

            <v-col cols="6" v-if="false">
                <EmAndamento />
            </v-col>

            <v-col cols="6" v-if="false">
                <AvaliacaoDesempenhoPorSetor />
            </v-col>

            <v-col cols="12">
                <NoticiaLinhaDoTempo />
            </v-col>
        </v-row>

        <div class="text-center">
            <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                {{ notificacao.mensagem }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import VueGridLayout from 'vue-grid-layout';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import CarregandoOverlay from '@/components/Default/CarregandoOverlay.vue';
import ButtonSwitchV3 from '@/components/Form/ButtonSwitchV3.vue';

import NoticiaLinhaDoTempo from '@/components/Dashboard/NoticiaLinhaDoTempo.vue';
import AvaliacaoDesempenhoPorSetor from '@/views/Shared/Dashboard/AvaliacaoDesempenho/AvaliacaoDesempenhoPorSetor.vue';
import EmAndamento from '@/views/Shared/Dashboard/AvaliacaoDesempenho/EmAndamento.vue';
import OrderToDeliveryAno from '@/Modulo/Dashboard/OrderToDeliveryAno.vue';
import OrderToDeliveryAnoTotal from '@/Modulo/Dashboard/OrderToDeliveryAnoTotal.vue';
import OrderToDeliveryAnoPorUnidadeEmpredimento from '@/Modulo/Dashboard/OrderToDeliveryAnoPorUnidadeEmpredimento.vue';
import OrderToDeliveryAnoTopGrupoCliente from '@/Modulo/Dashboard/OrderToDeliveryAnoTopGrupoCliente.vue';
import OrderToDeliveryAnoTopGrupoClienteTotal from '@/Modulo/Dashboard/OrderToDeliveryAnoTopGrupoClienteTotal.vue';
import OrderToDeliveryAnoTopRota from '@/Modulo/Dashboard/OrderToDeliveryAnoTopRota.vue';
import OrderToDeliveryAnoTopRotaTotal from '@/Modulo/Dashboard/OrderToDeliveryAnoTopRotaTotal.vue';
import Demanda from '@/Modulo/Dashboard/Demanda.vue';
import DemandaTotal from '@/Modulo/Dashboard/DemandaTotal.vue';
import DemandaTopClientes from '@/Modulo/Dashboard/DemandaTopClientes.vue';
import DemandaProximoDia from '@/Modulo/Dashboard/DemandaProximoDia.vue';

export default {
    name: 'dashboard',
    components: {
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
        CarregandoOverlay,
        ButtonSwitchV3,
        NoticiaLinhaDoTempo,
        AvaliacaoDesempenhoPorSetor,
        EmAndamento,
        OrderToDeliveryAno,
        OrderToDeliveryAnoTotal,
        OrderToDeliveryAnoPorUnidadeEmpredimento,
        OrderToDeliveryAnoTopGrupoCliente,
        OrderToDeliveryAnoTopGrupoClienteTotal,
        OrderToDeliveryAnoTopRota,
        OrderToDeliveryAnoTopRotaTotal,
        Demanda,
        DemandaTotal,
        DemandaTopClientes,
        DemandaProximoDia
    },
    async created() {},
    async mounted() {
        this.buscaPerfilDashboard();
    },
    computed: {
        ...mapGetters(['carregando']),
        ...mapState('dashboard', {
            dashboards(state, getters) {
                return getters['dashboards'];
            },
            dashboardEdita(state, getters) {
                return getters['edita'];
            }
        }),
        paineis: {
            get() {
                return this.dashboards;
            },
            set(novoValor) {
                this.setDashboards(novoValor);
            }
        }
    },
    methods: {
        ...mapActions({
            habilitaPainel: 'dashboard/habilitaPainel',
            setDashboards: 'dashboard/dashboards',
            setDimensao: 'dashboard/dimensao',
            dashboardSetEdita: 'dashboard/edita',
            dashboardSalvaPerfil: 'dashboard/salvaPerfil'
        }),
        async buscaPerfilDashboard() {
            try {
                this.overlay = true;

                ApiService({
                    method: 'get',
                    url: `${API_URL.erp.dashboard.perfil}`,
                    params: {},
                    mostraCarregando: false
                })
                    .then(resposta => {
                        this.habilitaPainel(resposta.data.data);
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.overlay = false;
            }
        },
        atualizaLayout(novoLayout) {
            this.setDashboards(novoLayout);
        },
        atualizaDimensao(i, newH, newW, newHPx, newWPx) {
            this.dashboards.map(item => {
                if (item.i == i) {
                    item.largura_pixel = newWPx;
                    item.altura_pixel = newHPx;
                }

                return item;
            });

            this.setDashboards(this.dashboards);
        },
        salvaDashboard() {
            this.dashboardSalvaPerfil()
                .then(async () => {
                    this.notificacao = {
                        mensagem: 'Configuração para o Dashboard salvo.',
                        tipo: 'success',
                        mostra: true
                    };
                })
                .catch(erro => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        }
    },
    data() {
        return {
            overlay: true,
            config: false,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            }
        };
    }
};
</script>

<style scoped>
.config-btn {
    margin-top: 10%;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 0px !important;
}
</style>
