<template>
    <v-form ref="formDados">
        <v-row>
            <v-col cols="2">
                <InputTextV3 v-model="form.id_avaliacao_desempenho" rotulo="ID" :contador="100" desativado clearable />
            </v-col>

            <v-col cols="10">
                <InputTextV3 v-model="form.nome" rotulo="Nome" :contador="200" :obrigatorio="true" :regra="regrasNome" />
            </v-col>

            <v-col cols="4">
                <DateTimePickerV2
                    v-model="form.periodo_de"
                    :dataInicial="dataInicial"
                    :regra="regrasPeriodoDe"
                    label="Período de:"
                    data-vv-delay="1000"
                />
            </v-col>

            <v-col cols="4">
                <DateTimePickerV2
                    v-model="form.periodo_ate"
                    :dataInicial="dataInicial"
                    :regra="regrasPeriodoAte"
                    label="Período até:"
                    data-vv-delay="1000"
                />
            </v-col>

            <v-col cols="4">
                <InputSelectV3
                    v-model="form.situacao"
                    rotulo="Situação"
                    :regra="regrasSituacao"
                    :itens="opcaoSituacao"
                    :desativado="true"
                    itemTexto="descricao"
                    itemValor="id_situacao"
                />
            </v-col>

            <v-col cols="12">
                <QuillEditorV2 v-model="form.orientacao" titulo="Orientação" />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { Maximo, Minimo, Obrigatorio } from '@/helpers/ValidadorCampo.js';

import moment from 'moment';
import { PreencheDadosMesmaPropriedade } from '@/helpers/Funcoes.js';

import InputTextV3 from '@/components/Form/InputTextV3.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';
import TextAreaV3 from '@/components/Form/TextAreaV3.vue';
import DateTimePickerV2 from '@/components/Form/DateTimePickerV2.vue';
import QuillEditorV2 from '@/components/Form/QuillEditorV2.vue';

export default {
    name: 'DadosForm',
    components: {
        InputTextV3,
        InputSelectV3,
        TextAreaV3,
        DateTimePickerV2,
        QuillEditorV2
    },
    async mounted() {
        await this.buscaSituacaoAvaliacao();
    },
    computed: {},
    watch: {},
    methods: {
        async buscaSituacaoAvaliacao() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.avaliacaodesempenho.buscaSituacaoAvaliacao}`,
                data: {}
            })
                .then(resposta => {
                    this.opcaoSituacao = resposta.data.data.map(situacao => {
                        const dados = {};

                        dados.id_situacao = situacao.id_registro;
                        dados.descricao = situacao.atributos.descricao;

                        return dados;
                    });
                })
                .catch(erro => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        },
        preencheDados(dados) {
            this.form = PreencheDadosMesmaPropriedade(this.form, dados);
        }
    },
    data() {
        return {
            form: {
                id_avaliacao_desempenho: null,
                nome: null,
                periodo_de: null,
                periodo_ate: null,
                orientacao: null,
                situacao: 1
            },
            dataInicial: moment().format('YYYY-MM-DD'),
            opcaoSituacao: [],
            regrasNome: [Obrigatorio(), Minimo(5), Maximo(200)],
            regrasSituacao: [],
            regrasOrientacao: [Obrigatorio(), Minimo(100), Maximo(3000)],
            regrasPeriodoDe: [v => !!v || 'Campo é obrigatório'],
            regrasPeriodoAte: [v => !!v || 'Campo é obrigatório']
        };
    }
};
</script>

<style></style>
