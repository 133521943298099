import _ from 'lodash';
import { mapGetters, mapActions, mapState } from 'vuex';

import { API_URL } from '@/config/configs';
import { Alerta, AlertaEsconde } from '@/helpers/Funcoes.js';
import ApiService from '@/services/ApiService.js';
import DatatableHelper from '@/helpers/Datatables.js';

const FormMixins = {
    async mounted() {
        this.id_whatsapp_departamento = parseInt(this.$route.params.id_whatsapp_departamento) ?? null;

        await this.dadosPaginaInicial();

        this.mostraCarregando(false);
    },
    computed: {
        ...mapGetters(['botoesComPermissao']),
        ...mapState(['carregando'])
    },
    methods: {
        ...mapActions(['montaNavegacao', 'mostraCarregando']),
        async dadosPaginaInicial() {
            try {
                await this.buscaPermissoes();

                if (this.id_whatsapp_departamento) {
                    await this.buscaForm();
                }

                this.permissao = true;
            } catch (erro) {
                Alerta([erro.message], 'error');
            }
        },
        async buscaPermissoes() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.whatsappdepartamento.permissao}`,
                data: {},
                mostraCarregando: false
            })
                .then(async resposta => {
                    const permissoes = resposta.data.data.atributos;

                    await this.montaNavegacao({ botoes: this.botoes, permissoes });
                })
                .catch(error => {
                    throw new Error(error);
                });
        },
        async buscaForm() {
            try {
                let urlConfig = {
                    method: 'GET',
                    url: `${API_URL.erp.whatsappdepartamento.busca}/${this.id_whatsapp_departamento}`,
                    data: {},
                    mostraCarregando: false
                };

                await ApiService(urlConfig)
                    .then(async resposta => {
                        resposta.data.data.atributos.id_whatsapp_departamento = resposta.data.data.id_registro;

                        this.preencheDados(resposta.data.data.atributos);
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                throw new Error(erro.message);
            }
        },
        preencheDados(dadosForm) {
            this.form = {
                id_whatsapp_departamento: dadosForm.id_whatsapp_departamento,
                nome: dadosForm.nome
            };
        },
        FormataDadosParaEnviarForm() {
            return {
                id_whatsapp_departamento: this.$refs.formDados.form.id_whatsapp_departamento,
                nome: this.$refs.formDados.form.nome
            };
        },
        fechaForm() {
            this.$router.push({ name: 'whatsapp-departamento' });
        },
        async salvaForm() {
            try {
                AlertaEsconde();

                this.validationMessage = [];
                this.tabDadosTotalErros = 0;

                if (!this.$refs.formDados.$refs.form.validate()) {
                    this.tabDadosTotalErros += 1;

                    this.validationMessage.push('Dados contém campos inválidos');
                }

                if (this.validationMessage.length > 0) {
                    Alerta(this.validationMessage, 'error');

                    return;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', 'Salvar registro ?', {
                        cor: 'green'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        this.mostraCarregando(true);

                        this.mensagemCarregando = 'Salvando dados do departamento, aguarde...';

                        const id_whatsapp_departamento = this.$refs.formDados.form.id_whatsapp_departamento;

                        let conteudoFormulario = this.FormataDadosParaEnviarForm();

                        let urlForm = API_URL.erp.whatsappdepartamento.salva;
                        let metodo = 'POST';

                        if (id_whatsapp_departamento) {
                            urlForm = `${API_URL.erp.whatsappdepartamento.salva}/${id_whatsapp_departamento}`;
                            metodo = 'PUT';
                        }

                        let urlConfig = {
                            method: metodo,
                            url: urlForm,
                            data: conteudoFormulario,
                            mostraCarregando: false
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                this.notificacao = {
                                    mensagem: `Departamento salvo.`,
                                    tipo: 'success',
                                    mostra: true
                                };

                                resposta.data.data.atributos.id_whatsapp_departamento = resposta.data.data.id_registro;

                                this.preencheDados(resposta.data.data.atributos);
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (erro) {
                Alerta([erro.message], 'error');
            } finally {
                this.mostraCarregando(false);
            }
        },
        async apagaForm() {
            try {
                let dados = DatatableHelper.datatableGetLinhaSelecionadas(this.moduloNome);

                let ids = dados
                    .map(function(dado) {
                        return dado.DT_RowId;
                    })
                    .join();

                if (!ids) {
                    this.$emit('mensagemAlerta', 'Selecione um registro antes de apagar.', 'error');

                    return false;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Deleta', `Apagar registro(s) ?\n IDS - ${ids}`, {
                        cor: 'red'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        let urlConfig = {
                            method: 'DELETE',
                            url: `${API_URL.erp.whatsappdepartamento.apaga}/${ids}`
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                this.$emit('datatableRecarrega');

                                this.$emit('mensagemAlerta', `Registro ${ids} deletado com sucesso!`, 'success');
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        }
    },
    data() {
        return {
            tabDadosTotalErros: 0,
            mensagemCarregando: 'Aguarde, buscando dados...',
            permissao: false,
            permissoes: [],
            botoes: [
                {
                    id_permissao: [2, 3],
                    nome: 'Salvar',
                    acao: 'salvaForm',
                    icone: 'mdi-newspaper-variant-multiple',
                    cor: 'green',
                    form: ['todos']
                },
                {
                    id_permissao: [1],
                    nome: 'Fechar',
                    acao: 'fechaForm',
                    icone: 'mdi-file-cancel',
                    cor: 'orange',
                    form: ['todos']
                }
            ],
            id_whatsapp_departamento: null,
            tabComponente: 'Dados',
            chavePrimaria: 'id_whatsapp_departamento',
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            form: {
                id_whatsapp_departamento: null,
                nome: null
            }
        };
    }
};

export default FormMixins;
