<template>
    <v-form ref="form">
        <v-row>
            <v-col cols="12">
                <v-badge
                    :content="totalRegistros"
                    :value="totalRegistros"
                    icon="mdi-numeric-positive-1"
                    color="deep-purple accent-4"
                    overlap
                >
                    <v-btn class="white--text" color="green" depressed @click="adicionaEmailDestinatario">
                        <v-icon large left> mdi-numeric-positive-1 </v-icon>
                    </v-btn>
                </v-badge>
            </v-col>

            <v-col cols="12">
                <v-expansion-panels v-for="(destinatario, i) in this.formPreenchido" :key="i" class="mt-3">
                    <v-expansion-panel>
                        <v-btn class="mt-3 mr-3 float-right" fab dark x-small color="red" @click="removeEmailDestinatario(i)">
                            <v-icon dark> mdi-close </v-icon>
                        </v-btn>

                        <v-expansion-panel-header>
                            <h3>Destinatário #{{ i + 1 }}</h3>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content eager>
                            <EmailDestinatarioForm ref="emailDestinatarioRef" :emailDestinatario="destinatario" />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { PreencheDadosMesmaPropriedade } from '@/helpers/Funcoes.js';

import EmailDestinatarioForm from '@/views/TrackingEmail/EmailDestinatarioForm.vue';

export default {
    name: 'EmailsDestinatariosForm',
    components: {
        EmailDestinatarioForm
    },
    props: {
        emailsDestinatarios: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    mounted() {},
    computed: {
        totalRegistros() {
            return this.formPreenchido.length;
        }
    },
    watch: {
        emailsDestinatarios: {
            handler: function(dadosAtual, dadosAntigo) {
                this.preencheDados(dadosAtual);
            }
            //deep: false
        }
    },
    methods: {
        adicionaEmailDestinatario(dadosEmailDestinatario) {
            let emailDestinatario = {};

            if (dadosEmailDestinatario.hasOwnProperty('id_tracking_email_destinatario')) {
                emailDestinatario = { ...dadosEmailDestinatario };
            }

            this.formPreenchido.push(emailDestinatario);
        },
        removeEmailDestinatario(posicao) {
            const listaEmailDestinatario = this.$refs.emailDestinatarioRef.filter((item, index) => index !== posicao);

            this.formPreenchido = [];

            listaEmailDestinatario.forEach((dados, index) => {
                this.formPreenchido.push(dados.form);
            });
        },
        preparaDados() {
            let emailsDestinatarios = [];

            this.$refs.emailDestinatarioRef.forEach((dados, index) => {
                emailsDestinatarios.push(dados.form);
            });

            return emailsDestinatarios;
        },
        preencheDados(dados) {
            this.formPreenchido = [];

            let self = this;

            if (dados) {
                Object.keys(dados).forEach(function(key) {
                    self.formPreenchido.push(PreencheDadosMesmaPropriedade(self.form, dados[key]));
                });
            }
        }
    },
    data() {
        return {
            form: {
                id_tracking_email_destinatario: null,
                email: null,
                observacao: null
            },
            formPreenchido: []
        };
    }
};
</script>

<style></style>
