<template>
    <div>
        <TituloModulo />

        <AlertaMensagem dense style="width: 100%" />

        <v-fab-transition>
            <v-btn
                v-show="!filtros.abreFecha"
                class="config-btn"
                color="purple darken-3"
                @click="filtros.abreFecha = true"
                style="margin-right: -15px"
                app
                fab
                right
                small
                fixed
                dark
                absolute
            >
                <v-icon>mdi-cog</v-icon>
            </v-btn>
        </v-fab-transition>

        <v-card v-if="permissao" outlined elevation="2" class="mb-4">
            <v-card-text dense>
                <ag-grid-vue
                    @grid-ready="onGridReady"
                    :rowData="rowData"
                    :localeText="AgGridTraducao"
                    :columnDefs="columnDefs"
                    :columnTypes="columnTypes"
                    :columnHoverHighlight="true"
                    :defaultColDef="defaultColDef"
                    :autoGroupColumnDef="autoGroupColumnDef"
                    :sideBar="sideBar"
                    :rowSelection="rowSelection"
                    :statusBar="statusBar"
                    :aggFuncs="aggFuncs"
                    :animateRows="true"
                    :enableRangeSelection="true"
                    :groupRowsSticky="true"
                    :pivotMode="true"
                    :removePivotHeaderRowWhenSingleValueColumn="true"
                    :groupIncludeFooter="true"
                    :groupIncludeTotalFooter="true"
                    :getRowStyle="getRowStyle"
                    @filter-changed="filtroAlterado"
                    rowGroupPanelShow="always"
                    class="ag-theme-alpine"
                    style="width: 100%; height: 75vh;"
                ></ag-grid-vue>
            </v-card-text>
        </v-card>

        <v-navigation-drawer v-model="filtros.abreFecha" class="mt-12" width="850" temporary right app fixed>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h5">
                        <v-icon color="warning darken-1">
                            mdi-cash-multiple
                        </v-icon>
                        Fluxo de Caixa
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-h6">
                        Configurações
                    </v-list-item-subtitle>
                </v-list-item-content>

                <v-icon color="red darken-1" @click="filtros.abreFecha = false" right>
                    mdi-close
                </v-icon>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        Filtros
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="warning darken-1" dark small v-bind="attrs" v-on="on">
                                    mdi-account-question
                                </v-icon>
                            </template>
                            Selecione o período e filial para exibição dos registros. <br />
                            Essa etapa irá definir o período em dias de exibição.
                        </v-tooltip>
                    </v-list-item-title>

                    <v-list-item-subtitle class="pr-2">
                        <InputSelectV3
                            v-model="filtros.id_filial"
                            rotulo="Filial"
                            :itens="opcaoFilial"
                            :multiplo="true"
                            :smallChips="true"
                            :obrigatorio="true"
                            @clear="clearIdFilial"
                            itemTexto="razao_social"
                            itemValor="id_filial"
                            ref="filial"
                            class="pa-1"
                        />

                        <InputSelectV3
                            v-model="filtros.conta_banco"
                            rotulo="Conta Banco"
                            :itens="opcaoContaBanco"
                            :multiplo="true"
                            :smallChips="true"
                            :obrigatorio="true"
                            @clear="clearContaBanco"
                            itemTexto="banco"
                            itemValor="conta"
                            ref="conta_banco"
                            class="pa-1"
                        />

                        <FiltroData v-model="filtros.data_de" rotulo="Data de:" />
                        <FiltroData v-model="filtros.data_ate" rotulo="Data até:" />

                        <v-btn :loading="overlay" elevation="2" color="warning" @click="buscaFluxoCaixa()"> Buscar </v-btn>

                        <v-chip class="ma-2" color="pink" label text-color="white">
                            <v-icon class="pr-1" start>mdi-timer-sync-outline</v-icon>
                            <span v-if="overlay">Tempo execução: </span>
                            <span v-else>Executado em: </span>
                            {{ tempoExecucao }} (s)
                        </v-chip>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        Filial por grupo de empresa
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="warning darken-1" dark small v-bind="attrs" v-on="on">
                                    mdi-account-question
                                </v-icon>
                            </template>
                            Preenche o filtro <strong>Filial</strong> de acordo com o grupo da empresas. <br />
                        </v-tooltip>
                    </v-list-item-title>

                    <v-list-item-subtitle class="pr-2">
                        <v-chip-group v-model="chipGrupoEmpresa" active-class="green--text" column>
                            <v-chip filter outlined @click="filtroGrupoEmpresa('terranova')">
                                Terra Nova
                            </v-chip>
                            <v-chip filter outlined @click="filtroGrupoEmpresa('tnlog')">
                                TN Log
                            </v-chip>
                            <v-chip filter outlined @click="filtroGrupoEmpresa('lima')">
                                Lima
                            </v-chip>
                            <v-chip filter outlined @click="filtroGrupoEmpresa('cargo3')">
                                Cargo 3
                            </v-chip>
                        </v-chip-group>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        Conta banco por grupo de empresa
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="warning darken-1" dark small v-bind="attrs" v-on="on">
                                    mdi-account-question
                                </v-icon>
                            </template>
                            Preenche o filtro <strong>Conta Banco</strong> de acordo com as contas do grupo da empresas. <br />
                            EX: Terra Nova 27146-2 (ITAÚ) 18871-9(BB), TN Log 35612-5 (ITAÚ) e etc... <br />
                        </v-tooltip>
                    </v-list-item-title>

                    <v-list-item-subtitle class="pr-2">
                        <v-chip-group v-model="chipBancoContaGrupoEmpresa" active-class="green--text" column>
                            <v-chip filter outlined @click="filtroBancoContaGrupoEmpresa('terranova')">
                                Terra Nova
                            </v-chip>
                            <v-chip filter outlined @click="filtroBancoContaGrupoEmpresa('tnlog')">
                                TN Log
                            </v-chip>
                            <v-chip filter outlined @click="filtroBancoContaGrupoEmpresa('lima')">
                                Lima
                            </v-chip>
                            <v-chip filter outlined @click="filtroBancoContaGrupoEmpresa('cargo3')">
                                Cargo 3
                            </v-chip>
                        </v-chip-group>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        Defina um resumo
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="warning darken-1" dark small v-bind="attrs" v-on="on">
                                    mdi-account-question
                                </v-icon>
                            </template>
                            Define a visualização da tabela. <br />
                            Por padrão a seleção está como diário, mas pode ser definido a sua escolha. <br />
                            Quando selecionado ficará destacado em verde e não é necessário clicar no botão buscar.
                        </v-tooltip>
                    </v-list-item-title>

                    <v-list-item-subtitle class="pr-2">
                        <v-chip-group v-model="chipResumo" active-class="green--text" column>
                            <v-chip filter outlined @click="resumoDiario()">
                                Diário
                            </v-chip>
                            <v-chip filter outlined @click="resumoSemanal()">
                                Semanal
                            </v-chip>
                            <v-chip filter outlined @click="resumoMensal()">
                                Mensal
                            </v-chip>
                        </v-chip-group>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        Data pré-definida
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="warning darken-1" dark small v-bind="attrs" v-on="on">
                                    mdi-account-question
                                </v-icon>
                            </template>
                            Atalho para definir um período na Data de: e Data Até:. <br />
                        </v-tooltip>
                    </v-list-item-title>

                    <v-list-item-subtitle class="pr-2">
                        <span v-if="mesAtualDescricao">
                            <v-chip-group :value="0" active-class="green--text" column>
                                <v-chip filter outlined @click="dataPreDefinida('mesAtual')">
                                    {{ mesAtualDescricao }}
                                </v-chip>
                                <v-chip filter outlined @click="dataPreDefinida('', 1)">
                                    {{ mesAtualDescricao }} + 1 meses
                                </v-chip>
                                <v-chip filter outlined @click="dataPreDefinida('', 2)">
                                    {{ mesAtualDescricao }} + 2 meses
                                </v-chip>
                                <v-chip filter outlined @click="dataPreDefinida('', 3)">
                                    {{ mesAtualDescricao }} + 3 meses
                                </v-chip>
                                <v-chip filter outlined @click="dataPreDefinida('', 4)">
                                    {{ mesAtualDescricao }} + 4 meses
                                </v-chip>
                                <v-chip filter outlined @click="dataPreDefinida('', 5)">
                                    {{ mesAtualDescricao }} + 5 meses
                                </v-chip>
                                <v-chip filter outlined @click="dataPreDefinida('', 6)">
                                    {{ mesAtualDescricao }} + 6 meses
                                </v-chip>
                            </v-chip-group>
                        </span>
                        <span v-else>
                            Define a data inicial para disponibilizar filtros de data pré-definidas.
                        </span>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        Exportar
                    </v-list-item-title>

                    <v-list-item-subtitle>
                        <v-btn elevation="2" color="green" dark @click="exportaExcel()">
                            <v-icon color="white">mdi-file-excel-outline</v-icon>

                            Excel
                        </v-btn>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-navigation-drawer>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :color="notificacao.tipo"
                :multi-line="true"
                elevation="10"
                timeout="3000"
                text
                right
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn text v-bind="attrs" @click="notificacao.mostra = false"> Fechar </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { Alerta, FormataMoeda, sleep } from '@/helpers/Funcoes.js';

import FluxoCaixaDados from './FluxoCaixaDados.js';

import _ from 'lodash';
import moment from 'moment';

import { AG_GRID_LOCALE_PT_BR } from '@/plugins/AgGrid/Traducao.js';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';

import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';
import SaldoInicial from './SaldoInicial.vue';
import SaldoInicialValor from './SaldoInicialValor.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import FiltroData from '@/components/Filtro/DataV1.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';

export default {
    name: 'FluxoCaixa',
    components: {
        AlertaMensagem,
        SaldoInicial,
        SaldoInicialValor,
        TituloModulo,
        AgGridVue,
        FiltroData,
        InputSelectV3
    },
    async mounted() {
        await this.dadosIniciais();
    },
    async created() {
        this.AgGridTraducao = AG_GRID_LOCALE_PT_BR;
        this.autoGroupColumnDef = {
            headerName: 'CONTA CONTÁBIL',
            minWidth: 300,
            pinned: 'left',
            cellRendererParams: {
                footerValueGetter: params => {
                    const isRootLevel = params.node.level === -1;

                    if (isRootLevel) {
                        return 'DISPONÍVEL';
                    }

                    return `Total (${params.value.toUpperCase()})`;
                }
            },
            cellRendererSelector: params => {
                if (['saldo'].includes(params.node.key)) {
                    return {
                        component: 'SaldoInicial'
                    };
                }

                return {
                    component: 'agGroupCellRenderer'
                };
            }
        };
        this.rowSelection = 'multiple';
        this.statusBar = {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agTotalRowCountComponent', align: 'center' },
                { statusPanel: 'agFilteredRowCountComponent' },
                { statusPanel: 'agSelectedRowCountComponent' },
                { statusPanel: 'agAggregationComponent' }
            ]
        };

        this.sideBar = {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel'
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel'
                }
            ],
            defaultToolPanel: 'filters',
            hiddenByDefault: false
        };

        this.columnTypes = {
            numberValue: {
                enableValue: true,
                aggFunc: 'sum',
                editable: true,
                valueParser: this.numberParser
            },
            dimension: {
                enablerowGroup: false,
                enablePivot: true
            }
        };

        this.getRowStyle = params => {
            if (params.node.footer) {
                return { fontWeight: 'bold', backgroundColor: '#f4a460' };
            }
        };

        this.aggFuncs = {};
    },
    watch: {
        overlay: function(dataAtual) {
            if (!dataAtual) {
                clearInterval(this.intervaloExecucao);
            } else {
                this.minutosExecucao = 0;

                this.intervaloExecucao = setInterval(this.incrementaTempo, 1000);
            }
        },
        dados2: function(valores) {
            this.calculaTotais(valores);
        },
        chipResumo: function(valor) {
            if (valor == null) {
                this.chipResumo = 2;
                this.resumoMensal();
            }
        },
        chipGrupoEmpresa: function(valor) {
            if (valor == null) {
                this.filtros.id_filial = [];
            }
        },
        chipBancoContaGrupoEmpresa: function(valor) {
            if (valor == null) {
                this.filtros.conta_banco = [];
            }
        }
    },
    computed: {
        tempoExecucao() {
            return moment.utc(this.minutosExecucao * 1000).format('mm:ss');
        },
        mesAtualDescricao() {
            let mesDescricao = null;

            if (this.filtros.data_de) {
                mesDescricao = moment(this.filtros.data_de)
                    .locale('pt-br')
                    .format('MMMM')
                    .toUpperCase();

                return mesDescricao;
            }

            return null;
        }
    },
    methods: {
        async onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            this.gridApi.closeToolPanel();
        },
        async dadosIniciais() {
            try {
                await this.getFilial();
                await this.buscaFluxoCaixa();

                this.permissao = true;
            } catch (erro) {
                Alerta([erro.message], 'error');

                this.permissao = false;

                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.overlay = false;
            }
        },
        async buscaFluxoCaixa() {
            try {
                if (!this.validaFiltros()) {
                    return;
                }

                this.overlay = true;
                this.gridApi.setRowData([]);
                this.gridApi.showLoadingOverlay();

                this.limpaSaldoInicial();

                const parametros = {
                    id_filial: this.filtros.id_filial,
                    conta_banco: this.filtros.conta_banco,
                    data_de: this.filtros.data_de,
                    data_ate: this.filtros.data_ate
                };

                this.intervaloDatas = this.intervaloEntreDatas(this.filtros.data_de, this.filtros.data_ate, 'days');

                if (this.fakeData) {
                    //await sleep(2000);

                    this.carregaDados(FluxoCaixaDados);

                    this.gridApi.setRowData(this.dados);

                    return;
                }

                await ApiService({
                    method: 'GET',
                    url: API_URL.erp.fluxoCaixa.extrato,
                    mostraCarregando: false,
                    params: parametros
                })
                    .then(resposta => {
                        if (resposta.data.data) {
                            this.carregaDados(resposta.data.data);

                            this.gridApi.setRowData(this.dados);
                        }

                        this.notificacao = {
                            mensagem: 'Tabela atualizada',
                            tipo: 'warning',
                            mostra: true
                        };
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });

                if (this.chipResumo == 0) {
                    this.resumoDiario();
                }

                if (this.chipResumo == 1) {
                    this.resumoSemanal();
                }

                if (this.chipResumo == 2) {
                    this.resumoMensal();
                }

                this.overlay = false;
            } catch (erro) {
                this.overlay = false;
                this.gridApi.setRowData([]);
                this.gridApi.showLoadingOverlay();

                throw new Error(erro.message);
            }
        },
        async getFilial() {
            try {
                await ApiService({
                    method: 'get',
                    url: `V1/filial/lista?fields[filial]=id_filial,filial_descricao`,
                    data: {}
                })
                    .then(resposta => {
                        this.opcaoFilial = resposta.data.data.map(filial => {
                            const dados = {};

                            dados.id_filial = filial.id_registro;
                            dados.razao_social = filial.atributos.filial_descricao;

                            return dados;
                        });
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                throw new Error(erro.message);
            }
        },
        carregaDados(dadosApi) {
            this.criaColunas();

            this.saldoInicial = dadosApi.saldo_anterior;

            this.dados = dadosApi.lancamentos;

            const saldosRealizado = this.adicionaSaldosRealizado(
                dadosApi.saldo_dia_realizado,
                dadosApi.saldo_semana_realizado,
                dadosApi.saldo_mes_realizado
            );

            this.adicionaSaldosPrevisao(
                dadosApi.saldo_dia_previsto,
                dadosApi.saldo_semana_previsto,
                dadosApi.saldo_mes_previsto
            );

            this.dados = saldosRealizado.concat(this.dados);
        },
        validaFiltros() {
            try {
                if (!this.filtros.data_de) {
                    throw new Error('Campo <strong>Data de</strong> é obrigatório');
                }

                if (!this.filtros.data_ate) {
                    throw new Error('Campo <strong>Data até</strong> é obrigatório');
                }

                return true;
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'red',
                    mostra: true
                };
            }
        },
        formataMoeda(params) {
            if (!params.value) {
                return 0;
            }

            return FormataMoeda(params.value, {}, true);
        },
        mascaraPercentual(valor) {
            return `${valor} %`;
        },
        numberParser(params) {
            return parseFloat(params.newValue);
        },
        toggleTempoExecucao() {
            if (this.overlay) {
                clearInterval(this.intervaloExecucao);
            } else {
                this.intervaloExecucao = setInterval(this.incrementaTempo, 1000);
            }
        },
        incrementaTempo() {
            this.minutosExecucao = parseInt(this.minutosExecucao) + 1;
        },
        totalGeralNegativo(params) {
            const isRootLevel = params.node.level === -1;

            if (isRootLevel && params.value < 0 && params.node.footer) {
                return { color: 'white', backgroundColor: 'red' };
            }

            return null;
        },
        adicionaSaldosRealizado(saldoDia, saldoSemana, saldoMes) {
            let saldoInicialDia = [];

            Object.keys(saldoDia).map(item => {
                saldoInicialDia.push({
                    tipo_documento: 'saldo',
                    valor_rateado: saldoDia[item],
                    data_previsao: item,
                    data_pagamento: item,
                    semana_pagamento: this.semanaData(item),
                    semana_previsao: this.semanaData(saldoDia[item].data_previsao),
                    lancamento: 'realizado'
                });
            });

            this.saldoInicialDiaRealizado = saldoDia;
            this.saldoInicialSemanalRealizado = saldoSemana;
            this.saldoInicialMesRealizado = saldoMes;

            return saldoInicialDia;
        },
        adicionaSaldosPrevisao(saldoDia, saldoSemana, saldoMes) {
            this.saldoInicialDiaPrevisto = saldoDia;
            this.saldoInicialSemanalPrevisao = saldoSemana;
            this.saldoInicialMesPrevisao = saldoMes;
        },
        encontraValorDia(params) {
            if (!params.data) {
                return params.node.aggData[params.colDef.field];
            }

            let { valor_rateado, valor_pagamento, valor_previsao } = params.data;

            let valorRateado = valor_rateado;
            let valorPagamento = valor_pagamento;
            let valorPrevisao = valor_previsao;

            if (params.data?.tipo_documento == 'saida') {
                valorRateado = parseFloat(-valorRateado);
                valorPagamento = parseFloat(-valorPagamento);
                valorPrevisao = parseFloat(-valorPrevisao);
            }

            if (params.data?.tipo_documento == 'saldo' && params.colDef.field == `${params.data.data_previsao}_previsto`) {
                if (this.saldoPrevistoCarregado[params.data.data_previsao]) {
                    return 0;
                }

                this.saldoPrevistoCarregado[params.data.data_previsao] = true;

                return this.saldoInicialDiaRealizado[params.data.data_previsao];
            }

            if (params.data?.tipo_documento == 'saldo' && params.colDef.field == `${params.data.data_previsao}_realizado`) {
                if (this.saldoRealizadoCarregado[params.data.data_previsao]) {
                    return 0;
                }

                this.saldoRealizadoCarregado[params.data.data_previsao] = true;

                return valor_rateado;
            }

            if (params.data?.tipo_documento == 'saldo' && params.colDef.field == `${params.data.data_previsao}_variacao`) {
                if (this.saldoVariacaoCarregado[params.data.data_previsao]) {
                    return 0;
                }

                this.saldoVariacaoCarregado[params.data.data_previsao] = true;

                return valor_rateado;
            }

            if (params.colDef.field == `${params.data.data_previsao}_previsto`) {
                if (['entrada', 'saida'].includes(params.data.tipo_documento)) {
                    /**
                     * SE HOUVER UM ADIANTAMENTO NO PAGAMENTO
                     * ELE NÃO PODE APARECER NAS DATAS POSTERIORES
                     * OU SEJA, PAGAMENTO 02/06/2023 E PREVISAO 05/06/2023
                     */
                    if (params.data.data_pagamento != null && params.data.data_previsao > params.data.data_pagamento) {
                        //return 0;
                    }

                    return valorPrevisao;
                }
            }

            if (params.colDef.field == `${params.data.data_pagamento}_realizado`) {
                if (['entrada', 'saida'].includes(params.data.tipo_documento) && params.data.data_pagamento != null) {
                    return valorPagamento;
                }
            }

            if (['entrada', 'saida'].includes(params.data.tipo_documento)) {
                if (params.colDef.field == `${params.data.data_previsao}_variacao`) {
                    if (params.data.lancamento == 'PREVISAO') {
                        return -valorPrevisao;
                    }
                }

                if (params.colDef.field == `${params.data.data_pagamento}_variacao`) {
                    if (params.data.lancamento == 'REALIZADO') {
                        return valorPagamento;
                    }
                }
            }

            return 0;
        },
        encontraValorSemana(params) {
            if (!params.data) {
                return params.node.aggData[params.colDef.field];
            }

            const anoPrevisao = moment(params.data.data_previsao).format('YYYY');
            const anoPagamento = moment(params.data.data_pagamento).format('YYYY');

            const semanaPrevisao = params.data.semana_previsao;
            const semanaRealizado = params.data.semana_pagamento;

            const mesPrevisao = moment(params.data.data_previsao)
                .locale('pt-br')
                .format('MMMM')
                .toLowerCase();

            const mesPagamento = moment(params.data.data_pagamento)
                .locale('pt-br')
                .format('MMMM')
                .toLowerCase();

            const campoSemanaPrevisto = `${anoPrevisao}_${semanaPrevisao}_semana_${mesPrevisao}_previsto`;
            const campoSemanaPagamento = `${anoPagamento}_${semanaRealizado}_semana_${mesPagamento}_realizado`;

            // INDICE PREVISTO ESTÁ NA MÃO E NÃO ESTÁ USANDO campoSemanaPrevisto PORQUE ELE TEM QUE PEGAR O REALIZADO
            if (
                params.data.tipo_documento == 'saldo' &&
                params.colDef.field == `${anoPagamento}_${semanaRealizado}_semana_${mesPagamento}_previsto`
            ) {
                const mesNumerico = moment(params.data.data_pagamento)
                    .format('MM')
                    .toLowerCase();

                const indiceAnoMesSemanPagamento = `${anoPagamento}-${mesNumerico}-${semanaRealizado}`;

                if (this.saldoSemanalCarregadoPrevisao[indiceAnoMesSemanPagamento]) {
                    return 0;
                }

                this.saldoSemanalCarregadoPrevisao[indiceAnoMesSemanPagamento] = true;

                return this.saldoInicialSemanalRealizado[indiceAnoMesSemanPagamento];
            }

            /**
             * COMPRAÇÃO COM O CAMPO DE MESPREVISAO_REALIZADO
             * POIS NEM SEMPRE NO JSON COM OS DADOS TEM A DATA DE PAGAMENTO
             * PRECISA APENAS DA DATA DO DIA PARA PEGAR O SALDO DA SEMANA
             */
            if (params.data.tipo_documento == 'saldo' && params.colDef.field == campoSemanaPagamento) {
                const mesNumerico = moment(params.data.data_pagamento)
                    .format('MM')
                    .toLowerCase();

                const indiceAnoMesSemanPagamento = `${anoPagamento}-${mesNumerico}-${semanaRealizado}`;

                if (this.saldoSemanalCarregadoRealizado[indiceAnoMesSemanPagamento]) {
                    return 0;
                }

                this.saldoSemanalCarregadoRealizado[indiceAnoMesSemanPagamento] = true;

                return this.saldoInicialSemanalRealizado[indiceAnoMesSemanPagamento];
            }

            let { valor_rateado, valor_pagamento, valor_previsao } = params.data;

            let valorRateado = valor_rateado;
            let valorPagamento = valor_pagamento;
            let valorPrevisao = valor_previsao;

            if (params.data?.tipo_documento == 'saida') {
                valorRateado = parseFloat(-valorRateado);
                valorPagamento = parseFloat(-valorPagamento);
                valorPrevisao = parseFloat(-valorPrevisao);
            }

            if (
                [1, 2, 3, 4, 5].includes(params.data.semana_pagamento) &&
                params.colDef.field == campoSemanaPagamento &&
                params.data.data_pagamento >= this.filtros.data_de &&
                params.data.data_pagamento <= this.filtros.data_ate
            ) {
                return valorPagamento;
            }

            if (
                [1, 2, 3, 4, 5].includes(params.data.semana_previsao) &&
                params.colDef.field == campoSemanaPrevisto &&
                params.data.data_previsao >= this.filtros.data_de &&
                params.data.data_previsao <= this.filtros.data_ate
            ) {
                /**
                 * SE HOUVER UM ADIANTAMENTO NO PAGAMENTO
                 * ELE NÃO PODE APARECER NAS DATAS POSTERIORES
                 * OU SEJA, PAGAMENTO 02/06/2023 E PREVISAO 05/06/2023
                 */
                if (params.data.data_pagamento != null && params.data.data_previsao <= params.data.data_pagamento) {
                    //return valorPrevisao;
                }

                return valorPrevisao;
            }

            return 0;
        },
        encontraValorMes(params) {
            if (!params.data) {
                return params.node.aggData[params.colDef.field];
            }

            const anoPrevisao = moment(params.data.data_previsao).format('YYYY');
            const anoPagamento = moment(params.data.data_pagamento).format('YYYY');

            const mesPrevisao = moment(params.data.data_previsao)
                .locale('pt-br')
                .format('MMMM')
                .toLowerCase();

            const mesPagamento = moment(params.data.data_pagamento)
                .locale('pt-br')
                .format('MMMM')
                .toLowerCase();

            const campoMesPrevisaoPrevisto = `${anoPrevisao}_${mesPrevisao}_previsto`;
            const campoMesPagamentoRealizado = `${anoPagamento}_${mesPagamento}_realizado`;

            if (params.data.tipo_documento == 'saldo' && params.colDef.field == campoMesPrevisaoPrevisto) {
                const anoMesPagamento = `${anoPagamento}-${mesPagamento}`;

                if (this.saldoMesCarregadoPrevisao[anoMesPagamento]) {
                    return 0;
                }

                this.saldoMesCarregadoPrevisao[anoMesPagamento] = true;

                return this.saldoInicialMesRealizado[anoMesPagamento];
            }

            if (params.data.tipo_documento == 'saldo' && params.colDef.field == campoMesPagamentoRealizado) {
                const anoMesPagamento = `${anoPagamento}-${mesPagamento}`;

                if (this.saldoMesCarregadoRealizado[anoMesPagamento]) {
                    return 0;
                }

                this.saldoMesCarregadoRealizado[anoMesPagamento] = true;

                return this.saldoInicialMesRealizado[anoMesPagamento];
            }

            let { valor_pagamento, valor_previsao } = params.data;

            let valorPagamento = valor_pagamento;
            let valorPrevisao = valor_previsao;

            if (params.data?.tipo_documento == 'saida') {
                valorPagamento = parseFloat(-valorPagamento);
                valorPrevisao = parseFloat(-valorPrevisao);
            }

            if (
                params.colDef.field == campoMesPrevisaoPrevisto &&
                params.data.data_previsao != null &&
                params.data.data_previsao >= this.filtros.data_de &&
                params.data.data_previsao <= this.filtros.data_ate
            ) {
                return valorPrevisao;
            }

            if (
                params.colDef.field == campoMesPagamentoRealizado &&
                params.data.data_pagamento != null &&
                params.data.data_pagamento >= this.filtros.data_de &&
                params.data.data_pagamento <= this.filtros.data_ate
            ) {
                return valorPagamento;
            }

            return 0;
        },
        encontraValorReal(params) {
            if (!params.data) {
                return 0;
            }

            if (params.data?.tipo_documento == 'saldo') {
                if (this.saldoTotalRealCarregado) {
                    return 0;
                }

                this.saldoTotalRealCarregado = true;

                return this.saldoInicial;
            }

            let { valor_pagamento } = params.data;

            let valorPagamento = valor_pagamento;

            if (params.data?.tipo_documento == 'saida') {
                valorPagamento = parseFloat(-valorPagamento);
            }

            if (
                params.colDef.field == 'total_real' &&
                params.data.data_pagamento != null &&
                params.data.data_pagamento >= this.filtros.data_de &&
                params.data.data_pagamento <= this.filtros.data_ate
            ) {
                return valorPagamento;
            }

            return 0;
        },
        semanaData(m) {
            m = moment(m);

            return (
                m.week() -
                moment(m)
                    .startOf('month')
                    .week() +
                1
            );
        },
        resumoDiario() {
            const colunaEstado = this.gridColumnApi.getColumnGroupState();

            colunaEstado.map(coluna => {
                coluna.open = false;

                if (coluna.groupId.search(/GrupoMensal/) >= 0) {
                    coluna.open = true;
                }

                if (coluna.groupId.search(/GrupoSemanal/) >= 0) {
                    coluna.open = true;
                }

                return coluna;
            });

            this.gridColumnApi.setColumnGroupState(colunaEstado);
        },
        resumoSemanal() {
            const colunaEstado = this.gridColumnApi.getColumnGroupState();

            colunaEstado.map(coluna => {
                coluna.open = false;

                if (coluna.groupId.search(/GrupoMensal/) >= 0) {
                    coluna.open = true;
                }

                return coluna;
            });

            this.gridColumnApi.setColumnGroupState(colunaEstado);
        },
        resumoMensal() {
            const colunaEstado = this.gridColumnApi.getColumnGroupState();

            colunaEstado.map(coluna => {
                coluna.open = false;

                return coluna;
            });

            this.gridColumnApi.setColumnGroupState(colunaEstado);
        },
        intervaloEntreDatas(dataInicial, dataFinal, tipo) {
            let dataDe = moment(dataInicial);
            let dataAte = moment(dataFinal).add(1, 'days');
            let diff = dataAte.diff(dataDe, tipo);
            let intervalo = [];

            for (let i = 0; i < diff; i++) {
                const data = moment(dataInicial)
                    .add(i, tipo)
                    .locale('pt-br');

                intervalo.push({
                    data: data.format('YYYY-MM-DD'),
                    data_ptbr: data.format('DD/MM/YYYY'),
                    mes: data.format('M'),
                    mes_descricao: data.format('MMMM'),
                    semana: this.semanaData(data)
                });
            }

            return intervalo;
        },
        criaColunas() {
            let colunaFinal = _.cloneDeep(this.columnDefsPadrao);

            const meses = _.groupBy(this.intervaloDatas, item => {
                return item['mes'];
            });

            Object.values(meses).map(mes => {
                const anoPrevisao = moment(mes[0].data_previsao).format('YYYY');

                const semanasAgrupadas = _.groupBy(mes, semana => {
                    return semana['semana'];
                });

                let colunaMes = {
                    field: `${mes[0].mes_descricao}`,
                    headerName: `${mes[0].mes_descricao.toUpperCase()}`,
                    columnGroupShow: 'open',
                    groupId: 'GrupoMensal'
                };

                colunaMes.children = Object.values(semanasAgrupadas).map(semana => {
                    const colunaSemana = {
                        field: `${anoPrevisao}_${semana[0].semana}_semana_${semana[0].mes_descricao}`,
                        headerName: `${semana[0].semana}º SEMANA`,
                        columnGroupShow: 'open',
                        groupId: 'GrupoSemanal'
                    };

                    colunaSemana.children = semana.map(itemSemana => {
                        const nomeDia = moment(itemSemana.data)
                            .locale('pt-br')
                            .format('dddd')
                            .toUpperCase();

                        return {
                            field: itemSemana.data,
                            headerName: `${itemSemana.data_ptbr} - ${nomeDia}`,
                            columnGroupShow: 'open',
                            groupId: 'GrupoDiario',
                            children: [
                                {
                                    field: `${itemSemana.data}_previsto`,
                                    headerName: 'PREVISTO',
                                    aggFunc: 'sum',
                                    type: 'numberValue',
                                    valueFormatter: this.formataMoeda,
                                    valueGetter: this.encontraValorDia,
                                    filter: 'agNumberColumnFilter',
                                    cellStyle: this.totalGeralNegativo
                                },
                                {
                                    field: `${itemSemana.data}_realizado`,
                                    headerName: 'REALIZADO',
                                    aggFunc: 'sum',
                                    type: 'numberValue',
                                    valueFormatter: this.formataMoeda,
                                    valueGetter: this.encontraValorDia,
                                    filter: 'agNumberColumnFilter',
                                    cellStyle: this.totalGeralNegativo
                                },
                                {
                                    field: `${itemSemana.data}_variacao`,
                                    headerName: 'VARIAÇÃO',
                                    aggFunc: 'sum',
                                    type: 'numberValue',
                                    valueFormatter: this.formataMoeda,
                                    valueGetter: this.encontraValorDia,
                                    filter: 'agNumberColumnFilter',
                                    cellStyle: this.totalGeralNegativo
                                }
                            ]
                        };
                    });

                    const semanaPrevistoRealizado = {
                        field: `${anoPrevisao}_${semana[0].semana}_semana_${semana[0].mes_descricao}_total`,
                        headerName: 'Total Semana',
                        columnGroupShow: 'closed',
                        groupId: 'GrupoSemanal',
                        children: [
                            {
                                field: `${anoPrevisao}_${semana[0].semana}_semana_${semana[0].mes_descricao}_previsto`,
                                headerName: 'PREVISTO',
                                aggFunc: 'sum',
                                type: 'numberValue',
                                valueFormatter: this.formataMoeda,
                                valueGetter: this.encontraValorSemana,
                                filter: 'agNumberColumnFilter',
                                cellStyle: this.totalGeralNegativo
                            },
                            {
                                field: `${anoPrevisao}_${semana[0].semana}_semana_${semana[0].mes_descricao}_realizado`,
                                headerName: 'REALIZADO',
                                aggFunc: 'sum',
                                type: 'numberValue',
                                valueFormatter: this.formataMoeda,
                                valueGetter: this.encontraValorSemana,
                                filter: 'agNumberColumnFilter',
                                cellStyle: this.totalGeralNegativo
                            }
                        ]
                    };

                    colunaSemana.children.push(semanaPrevistoRealizado);

                    return colunaSemana;
                });

                colunaMes.children.push(
                    {
                        field: `${anoPrevisao}_${mes[0].mes_descricao}_previsto`,
                        headerName: 'PREVISTO',
                        columnGroupShow: 'closed',
                        aggFunc: 'sum',
                        type: 'numberValue',
                        valueFormatter: this.formataMoeda,
                        valueGetter: this.encontraValorMes,
                        filter: 'agNumberColumnFilter',
                        cellStyle: this.totalGeralNegativo
                    },
                    {
                        field: `${anoPrevisao}_${mes[0].mes_descricao}_realizado`,
                        headerName: 'REALIZADO',
                        columnGroupShow: 'closed',
                        aggFunc: 'sum',
                        type: 'numberValue',
                        valueFormatter: this.formataMoeda,
                        valueGetter: this.encontraValorMes,
                        filter: 'agNumberColumnFilter',
                        cellStyle: this.totalGeralNegativo
                    }
                );

                colunaFinal.push(colunaMes);
            });

            colunaFinal.push({
                field: 'total_real',
                headerName: 'Total Real',
                aggFunc: 'sum',
                type: 'numberValue',
                valueFormatter: this.formataMoeda,
                valueGetter: this.encontraValorReal,
                filter: 'agNumberColumnFilter',
                cellStyle: this.totalGeralNegativo
            });

            this.gridApi.setColumnDefs(colunaFinal);
        },
        dataPreDefinida(filtro, qtdMeses) {
            if (filtro == 'mesAtual') {
                this.filtros.data_de = moment()
                    .startOf('month')
                    .format('YYYY-MM-DD');
                this.filtros.data_ate = moment()
                    .endOf('month')
                    .format('YYYY-MM-DD');

                return;
            }

            this.filtros.data_de = moment()
                .startOf('month')
                .format('YYYY-MM-DD');
            this.filtros.data_ate = moment(this.filtros.data_de)
                .add(qtdMeses, 'months')
                .endOf('month')
                .format('YYYY-MM-DD');
        },
        filtroAlterado() {
            this.limpaSaldoInicial();
        },
        limpaSaldoInicial() {
            this.saldoRealizadoCarregado = [];
            this.saldoVariacaoCarregado = [];
            this.saldoSemanalCarregadoRealizado = [];
            this.saldoMesCarregadoRealizado = [];
            this.saldoTotalRealCarregado = false;
            this.saldoPrevistoCarregado = [];
            this.saldoSemanalCarregadoPrevisao = [];
            this.saldoMesCarregadoPrevisao = [];
        },
        exportaExcel() {
            this.gridApi.exportDataAsExcel({
                skipGroups: true,
                skipRowGroups: true
            });
        },
        filtroGrupoEmpresa(grupo_empresa) {
            this.filtros.id_filial = [];

            if (grupo_empresa == 'terranova') {
                this.filtros.id_filial = [1, 2, 7, 8, 9];
            }

            if (grupo_empresa == 'tnlog') {
                this.filtros.id_filial = [3, 10];
            }

            if (grupo_empresa == 'lima') {
                this.filtros.id_filial = [4];
            }

            if (grupo_empresa == 'cargo3') {
                this.filtros.id_filial = [6];
            }
        },
        filtroBancoContaGrupoEmpresa(grupo_empresa) {
            this.filtros.conta_banco = [];

            if (grupo_empresa == 'terranova') {
                this.filtros.conta_banco = ['27146-2', '18871-9', '39488-4', '18871-A', '587292-6'];
            }

            if (grupo_empresa == 'tnlog') {
                this.filtros.conta_banco = ['35612-5', '587291-8'];
            }

            if (grupo_empresa == 'lima') {
                this.filtros.conta_banco = ['34025-2'];
            }

            if (grupo_empresa == 'cargo3') {
                this.filtros.conta_banco = ['48339-1'];
            }
        },
        clearIdFilial() {
            this.chipGrupoEmpresa = null;
        },
        clearContaBanco() {
            this.chipBancoContaGrupoEmpresa = null;
        }
    },
    data() {
        return {
            permissao: true,
            intervaloDatas: [],
            dados: [],
            dadosTotaisPosicao: [],
            saldoInicial: 0,
            saldoInicialDia: [],
            saldoInicialDiaRealizado: [],
            saldoInicialSemanalRealizado: [],
            saldoInicialMesRealizado: [],
            saldoInicialDiaPrevisto: [],
            saldoInicialSemanalPrevisto: [],
            saldoInicialMesPrevisao: [],
            saldoRealizadoCarregado: [],
            saldoVariacaoCarregado: [],
            saldoSemanalCarregadoRealizado: [],
            saldoMesCarregado: [],
            saldoMesCarregadoPrevisao: [],
            saldoTotalRealCarregado: false,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            overlay: false,
            filtros: {
                abreFecha: false,
                id_filial: [],
                conta_banco: [],
                data_de: moment()
                    .startOf('day')
                    .format('YYYY-MM-DD'),
                data_ate: moment()
                    .add(5, 'days')
                    .format('YYYY-MM-DD')
            },
            chipResumo: 2,
            chipGrupoEmpresa: null,
            chipBancoContaGrupoEmpresa: null,
            opcaoFilial: [],
            opcaoContaBanco: [
                {
                    banco: '18871-9 - BANCO DO BRASIL CONTA CORRENTE',
                    conta: '18871-9'
                },
                {
                    banco: '18871-A - BANCO DO BRASIL APLICAÇÃO',
                    conta: '18871-A'
                },
                {
                    banco: '27146-2 - ITAÚ CONTA CORRENTE',
                    conta: '27146-2'
                },
                {
                    banco: '34025-2 - ITAÚ CONTA CORRENTE',
                    conta: '34025-2'
                },
                {
                    banco: '35612-5 - ITAÚ CONTA CORRENTE',
                    conta: '35612-5'
                },
                {
                    banco: '39488-4 - ITAÚ CONTA GARANTIDA',
                    conta: '39488-4'
                },
                {
                    banco: '48339-1 - ITAÚ CONTA CORRENTE',
                    conta: '48339-1'
                },
                {
                    banco: '587292-6 - SAFRA S.A',
                    conta: '587292-6'
                },
                {
                    banco: '587291-8 - SAFRA S.A',
                    conta: '587291-8'
                }
            ],
            columnDefsPadrao: [
                {
                    field: 'tipo_documento',
                    headerName: 'TIPO DOCUMENTO',
                    rowGroup: true,
                    rowGroupIndex: 0,
                    hide: true,
                    minWidth: 170,
                    filter: 'agSetColumnFilter',
                    valueFormatter: params => {
                        return params?.value?.toUpperCase();
                    }
                },
                {
                    field: 'documento',
                    headerName: 'DOCUMENTO',
                    rowGroup: true,
                    rowGroupIndex: 3,
                    hide: true,
                    minWidth: 150,
                    filter: 'agSetColumnFilter'
                },
                {
                    field: 'conta_contabil_codigo',
                    headerName: 'CONTA CONTÁBIL',
                    rowGroup: true,
                    rowGroupIndex: 1,
                    hide: true,
                    minWidth: 150,
                    filter: 'agSetColumnFilter',
                    valueGetter: params => {
                        if (params.data.tipo_documento == 'saldo') {
                            return null;
                        }

                        let conta_contabil_codigo = params.data.conta_contabil_codigo;
                        let conta_contabil_descricao = params.data.conta_contabil_descricao;

                        if (params.data.tipo_documento == 'entrada') {
                            conta_contabil_codigo = params.data.codigo_banco;
                            conta_contabil_descricao = params.data.banco_descricao;
                        }

                        return `${conta_contabil_codigo} - ${conta_contabil_descricao}`;
                    }
                },
                {
                    field: 'codigo_cliente_fornecedor',
                    headerName: 'PARCEIRO CÓDIGO',
                    rowGroup: false,
                    hide: true,
                    minWidth: 150,
                    filter: 'agSetColumnFilter'
                },
                {
                    field: 'razao_social',
                    headerName: 'PARCEIRO',
                    rowGroup: true,
                    rowGroupIndex: 2,
                    hide: true,
                    minWidth: 150,
                    filter: 'agSetColumnFilter',
                    valueGetter: params => {
                        if (params.data.tipo_documento == 'saldo') {
                            return null;
                        }

                        return `${params.data.codigo_cliente_fornecedor} - ${params.data.razao_social}`;
                    }
                }
            ],
            columnDefs: [],
            gridApi: null,
            rowData: null,
            columnApi: null,
            defaultColDef: {
                flex: 1,
                minWidth: 170,
                resizable: true,
                enableValue: true,
                enableRowGroup: true,
                enablePivot: true,
                sortable: true,
                floatingFilter: false
            },
            autoGroupColumnDef: null,
            rowSelection: null,
            statusBar: null,
            sideBar: null,
            columnTypes: null,
            aggFuncs: null,
            AgGridTraducao: null,
            getRowStyle: null,
            minutosExecucao: 0,
            intervaloExecucao: null,
            fakeData: false
        };
    }
};
</script>
