<template>
    <v-form ref="formDados">
        <v-row>
            <v-col cols="12">
                <v-badge
                    :content="totalRegistros"
                    :value="totalRegistros"
                    icon="mdi-numeric-positive-1"
                    color="deep-purple accent-4"
                    overlap
                >
                    <v-btn tile color="success" @click="adicionaAvaliado">
                        <v-icon left> mdi-plus </v-icon>
                        Avaliados
                    </v-btn>
                </v-badge>
            </v-col>

            <v-col cols="12">
                <v-expansion-panels v-for="(avaliado, i) in this.form.avaliados" :key="i" class="mt-3">
                    <v-expansion-panel>
                        <v-btn class="mt-3 mr-3 float-right" fab dark x-small color="red" @click="removeAvaliado(i)">
                            <v-icon dark> mdi-close </v-icon>
                        </v-btn>

                        <v-expansion-panel-header>
                            <h3>Avaliado #{{ i + 1 }}</h3>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content eager>
                            <AvaliadoForm ref="avaliadoRef" :itensAvaliados="itensAvaliados" :avaliadoData="avaliado" />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>

            <v-col cols="12">
                <v-badge
                    v-if="totalRegistros > 0"
                    :content="totalRegistros"
                    :value="totalRegistros"
                    icon="mdi-numeric-positive-1"
                    color="deep-purple accent-4"
                    overlap
                >
                    <v-btn tile color="success" @click="adicionaAvaliado">
                        <v-icon left> mdi-plus </v-icon>
                        Avaliados
                    </v-btn>
                </v-badge>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import { PreencheDadosMesmaPropriedade } from '@/helpers/Funcoes.js';

import AvaliadoForm from '@/views/AvaliacaoDesempenho/AvaliadoForm.vue';

export default {
    name: 'AvaliadosForm',
    components: {
        AvaliadoForm
    },
    created() {
        this.avaliadoModelo = this.form.avaliados[0];
    },
    async mounted() {
        await this.buscaColaborador();
    },
    props: {
        avaliadoData: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    computed: {
        totalRegistros() {
            return this.form.avaliados.length;
        }
    },
    watch: {},
    methods: {
        async buscaColaborador() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.avaliacaodesempenho.buscaColaborador}`,
                data: {}
            })
                .then(resposta => {
                    this.colaborador = resposta.data.map(colaborador => {
                        const dados = {};
                        dados.id_colaborador = colaborador.id_colaborador;
                        dados.nome_completo = colaborador.nome_completo;

                        return dados;
                    });
                })
                .catch(erro => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        },
        adicionaAvaliado: function(dadosAvaliado) {
            let avaliado = {};

            if (dadosAvaliado.hasOwnProperty('id_avaliacao_desempenho_avaliados')) {
                avaliado = { ...dadosAvaliado };
            }

            this.form.avaliados.push(avaliado);
        },
        removeAvaliado(posicao) {
            const listaAvaliados = this.$refs.avaliadoRef.filter((item, index) => index !== posicao);

            this.form.avaliados = [];

            listaAvaliados.forEach((dados, index) => {
                this.form.avaliados.push(dados.avaliado);
            });
        },
        preparaDados() {
            let avaliados = [];

            this.$refs.avaliadoRef.forEach((dados, index) => {
                avaliados.push(dados.avaliado);
            });

            return avaliados;
        },
        preencheDados(dados) {
            const self = this;

            this.form.avaliados = [];

            if (dados.avaliados) {
                Object.keys(dados.avaliados).forEach(function(key) {
                    self.itensAvaliados.push({
                        id_colaborador: dados.avaliados[key].id_colaborador,
                        descricao: `${dados.avaliados[key].id_colaborador} - ${dados.avaliados[key].parceiro.nome}`
                    });

                    self.form.avaliados.push(PreencheDadosMesmaPropriedade(self.avaliadoModelo, dados.avaliados[key]));
                });
            }
        }
    },
    data() {
        return {
            avaliadoModelo: {},
            form: {
                avaliados: [
                    {
                        id_avaliacao_desempenho_avaliados: null,
                        id_colaborador: null
                    }
                ]
            },
            itensAvaliados: [],
            colaborador: []
            // regraOrientacao: [v => _.isEmpty(v) || v.length <= 500 || 'Observação deve ter até 1000 caracteres']
        };
    }
};
</script>

<style></style>
