<template>
    <div>
        <v-alert
            v-model="mostrarAlerta"
            dismissible
            outlined
            :type="tipo"
            text
            class="multi-line"
            v-for="(msg, index) in mensagem"
            :key="index"
        >
            {{ msg }} <span v-if="tempoExpira > 0">{{ tempoExpira }} </span>
        </v-alert>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        tempoExpiraMensagem: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            mostrarAlerta: false,
            tipoMensagem: 'success',
            tempoExpira: 0
        };
    },
    computed: {
        mostrar() {
            this.mostrarAlerta = this.$store.state.alerta.alerta.mostrar;

            if (this.tempoExpiraMensagem > 0) {
                var self = this;
                var tempoRestante = this.tempoExpiraMensagem;

                var tempoRegressivo = setInterval(function() {
                    if (tempoRestante <= 0) {
                        clearInterval(tempoRegressivo);
                    }
                    console.log('tempoRestante --->', tempoRestante);
                    self.tempoExpira = tempoRestante;
                    tempoRestante -= 1;
                }, 1000);
            }
        },
        mensagem() {
            let msg = this.$store.state.alerta.alerta.mensagem;

            return Array.isArray(msg) ? msg : [msg];
        },
        tipo() {
            return this.$store.state.alerta.alerta.tipo ? this.$store.state.alerta.alerta.tipo : this.tipoMensagem;
        }
    },
    watch: {
        mostrar() {}
    }
};
</script>
<style scoped>
.multi-line {
    white-space: pre-line;
}
</style>
