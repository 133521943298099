const TipoOperacaoEnum = {
    EXPORTACAO: 1,
    IMPORTACAO_E_NACIONAL: 2
};

export function descricao() {
    return [
        {
            tipo_operacao: 1,
            descricao: 'Exportação'
        },
        {
            tipo_operacao: 2,
            descricao: 'Importação e Nacional'
        }
    ];
}

export default TipoOperacaoEnum;
