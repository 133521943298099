<template>
    <v-container fluid class="pa-0 ma-0">
        <v-img :src="imagemFinal" class="grey lighten-2" style="cursor: pointer" @click="abreImagem()">
            <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
            </template>
        </v-img>
    </v-container>
</template>

<script>
import ApiService from '@/services/ApiService.js';

import { imagemArquivo } from '@/utils/media-arquivo.js';

export default {
    name: 'ImagemComAutenticacao',
    props: {
        url_arquivo: {
            type: String,
            required: true
        },
        mime_type: {
            type: String,
            required: true
        }
    },
    watch: {
        url_arquivo: {
            deep: true,
            immediate: true,
            handler() {
                this.checkImgLoad();
            }
        }
    },
    methods: {
        async checkImgLoad() {
            try {
                if (!imagemArquivo(this.url_arquivo)) return;

                await ApiService({
                    method: 'GET',
                    url: this.url_arquivo,
                    data: {},
                    mostraCarregando: false,
                    responseType: 'blob'
                })
                    .then(resposta => {
                        const blob = new Blob([resposta.data], { type: this.mime_type });

                        this.imagemFinal = URL.createObjectURL(blob);
                    })
                    .catch(error => {
                        throw new Error(error);
                    });
            } catch (erro) {
                console.log('ERRO NO DOWNLOAD', erro.message);
            }
        },
        abreImagem() {
            if (!this.imagemFinal) {
                return;
            }

            this.$emit('abre-imagem', { imagem: this.imagemFinal });
        }
    },
    data() {
        return {
            imageLoading: false,
            imagemFinal: null,
            modalImagem: false
        };
    }
};
</script>
