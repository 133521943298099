<template>
    <v-combobox
        v-model="model"
        :filter="filter"
        :hide-no-data="!search"
        :items="items"
        :search-input.sync="search"
        :label="tituloBusca"
        :multiple="multiple"
        :small-chips="smallChips"
        :required="obrigatorio"
        :rules="regra"
        :outlined="estilo('outlined')"
        :menu-props="{ offsetY: true }"
        @change="$emit('change', $event)"
        hide-selected
        dense
        :clearable="clearable"
    >
        <template v-slot:no-data>
            <v-list-item>
                <span class="subheading">Buscar por:</span>
                <v-chip :color="`${colors[nonce - 1]} lighten-3`" label small>
                    {{ search }}
                </v-chip>
            </v-list-item>
        </template>
        <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
                v-if="item === Object(item)"
                v-bind="attrs"
                :color="`${item.color} lighten-3`"
                :input-value="selected"
                label
                small
            >
                <span class="pr-2">
                    {{ item.text }}
                </span>
                <v-icon small @click="parent.selectItem(item)">
                    $delete
                </v-icon>
            </v-chip>
        </template>
        <template v-slot:item="{ index, item }">
            <v-text-field
                v-if="editing === item"
                v-model="editing.text"
                autofocus
                flat
                background-color="transparent"
                hide-details
                solo
                @keyup.enter="edit(index, item)"
            ></v-text-field>
            <v-chip v-else :color="`${item.color} lighten-3`" dark label small>
                {{ item.text }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-list-item-action @click.stop>
                <v-btn icon @click.stop.prevent="edit(index, item)">
                    <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
                </v-btn>
            </v-list-item-action>
        </template>
    </v-combobox>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default: () => []
        },
        datatableConfiguracao: {
            type: Object,
            default: () => {}
        },
        tituloBusca: {
            type: String,
            default: () => ''
        },
        itemValor: {
            type: String,
            default: () => ''
        },
        itemTexto: {
            type: String,
            default: () => ''
        },
        regra: {
            type: Array,
            default: () => []
        },
        formatoRetornado: {
            type: Object,
            default() {
                return { descricao: '%s - %s' };
            }
        },
        desativado: {
            type: Boolean,
            default: () => false
        },
        obrigatorio: {
            type: Boolean,
            default: () => false
        },
        iconeAppend: {
            type: String,
            default: () => 'search'
        },
        smallChips: {
            type: Boolean,
            default: () => false
        },
        deletableChips: {
            type: Boolean,
            default: () => false
        },
        multiple: {
            type: Boolean,
            default: () => false
        },
        estiloCampo: {
            type: String,
            default: () => 'outlined'
        },
        clearable: {
            type: Boolean,
            default: () => false
        }
    },
    data: () => ({
        estaCarregando: false,
        itemsOpcoes: [],
        activator: null,
        attach: null,
        colors: [
            'green',
            'pink',
            'purple',
            'deep-purple',
            'blue',
            'indigo',
            'cyan',
            'teal',
            'orange',
            'light-blue',
            'cyan',
            'teal',
            'yellow',
            'amber',
            'deep-orange',
            'brown',
            'blue-grey'
        ],
        editing: null,
        editingIndex: -1,
        items: [{ header: 'Selecione uma opção ou digite uma nova' }],
        nonce: 1,
        menu: false,
        model: [],
        x: 0,
        search: null,
        y: 0
    }),
    async created() {},
    computed: {},
    watch: {
        value(val, prev) {
            if (val.length == 0) {
                this.model = [];
            }
        },
        model(val, prev) {
            if (val.length === prev.length) return;

            this.model = val.map(v => {
                if (typeof v === 'string') {
                    v = {
                        text: v,
                        color: this.colors[this.nonce - 1]
                    };

                    this.items.push(v);

                    this.nonce++;
                }

                return v;
            });

            let valorAlterado = Object.keys(this.model).map(val => this.model[val].text);

            this.$emit('input', valorAlterado);
        }
    },
    mounted() {},
    methods: {
        edit(index, item) {
            if (!this.editing) {
                this.editing = item;
                this.editingIndex = index;
            } else {
                this.editing = null;
                this.editingIndex = -1;
            }
        },
        filter(item, queryText, itemText) {
            if (item.header) return false;

            const hasValue = val => (val != null ? val : '');

            const text = hasValue(itemText);
            const query = hasValue(queryText);

            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            );
        },

        estilo(estiloDefinido) {
            if (this.estiloCampo == estiloDefinido) {
                return true;
            }

            return false;
        }
    }
};
</script>

<style scoped></style>
