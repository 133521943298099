import { API_URL_BASE, API_URL } from '@/config/configs';

const UnidadeEmpreendimento = {
    datatable: {
        id_datatable: 'unidadeempreendimento',
        chave_primaria: 'id_unidade_empreendimento',
        url: `${API_URL_BASE}${API_URL.erp.unidadeempreendimento.datatable}`,
        colunas: false,
    },
    formRefsComponente: 'UnidadeEmpreendimentoForm',
    formNome: 'formUnidadeEmpreendimento',
    moduloNome: 'unidadeempreendimento',
    chavePrimaria: 'id_unidade_empreendimento',
    componenteArquivo: 'UnidadeEmpreendimento/Form.vue',
    tabRefs: 'tabUnidadeEmpreendimento',
    urlNovoRegistro: '',
    urlConsultaRegistro: 'listaPorIdForm',
    tabs: []
}

export { UnidadeEmpreendimento };

