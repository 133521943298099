<template>
    <v-container fluid>
        <v-row>
            <v-col cols="6">
                <v-card outlined elevation="2" class="mb-4">
                    <v-card-title>
                        Filtros:

                        <v-chip class="ma-2" color="deep-purple accent-4 white--text" label text-color="white">
                            {{ filtrosTotal }}
                            <v-icon right>
                                mdi-account-filter
                            </v-icon>
                        </v-chip>

                        <v-spacer></v-spacer>

                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon>
                                    <v-icon>mdi-help</v-icon>
                                </v-btn>
                            </template>
                            <span>
                                <strong>Regra:</strong> Romaneios sem lançamento das ocorrências
                                <strong>1 - FINALIZADA</strong> e
                                <strong>105 - CHEGADA CLIENTE</strong>

                                <br />

                                Filtro na data criação do romaneio maior que 14/08/2023.

                                <br /><br />

                                <strong>Exibição:</strong> Será exibido de forma agrupada por dia de criação do romaneio
                                ordenado pelo mais recente
                            </span>
                        </v-tooltip>
                    </v-card-title>

                    <v-card-text dense>
                        <v-row>
                            <v-col cols="7">
                                <InputAutoCompleteLocalV3
                                    v-model="filtros.id_alocacao_carga_romaneio"
                                    :itemsOpcoes="opcaoIdAlocacaoCargaRomaneio"
                                    :smallChips="true"
                                    :deletableChips="true"
                                    :multiple="true"
                                    tituloBusca="ID Romaneio"
                                    itemValor="id_alocacao_carga_romaneio"
                                    itemTexto="id_alocacao_carga_romaneio"
                                />
                            </v-col>

                            <v-col cols="5">
                                <InputSelectV3
                                    v-model="filtros.mensagem_enviada"
                                    rotulo="Notificado por Whatsapp ?"
                                    :itens="opcaoNotificacaoSimNao"
                                    itemTexto="descricao"
                                    itemValor="valor"
                                    ref="notificacaoSimNao"
                                />
                            </v-col>

                            <v-col cols="12">
                                <InputAutoCompleteLocalV3
                                    v-model="filtros.motorista"
                                    :itemsOpcoes="motoristas"
                                    :smallChips="true"
                                    :deletableChips="true"
                                    :multiple="true"
                                    tituloBusca="Motorista"
                                    itemValor="id_motorista"
                                    itemTexto="id_motorista"
                                />
                            </v-col>

                            <v-col cols="12" class="text-right">
                                <v-btn
                                    elevation="2"
                                    color="warning"
                                    :loading="carregandoDados"
                                    :disabled="carregandoDados"
                                    @click="buscaRomaneiosDisponiveis()"
                                >
                                    Buscar Romaneios
                                    <v-icon right> mdi-magnify </v-icon>
                                    <template v-slot:loader>
                                        <span>Buscando... </span>
                                        <v-progress-circular
                                            indeterminate
                                            color="warning"
                                            :size="20"
                                            :width="2"
                                        ></v-progress-circular>
                                    </template>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="6">
                <v-row>
                    <v-col cols="6">
                        <card-info
                            iconeBoxCor="info"
                            icone="mdi-file-document-edit"
                            titulo="Romaneios"
                            :value="totais.romaneios"
                            rodape-texto="Quantidade total de romaneios"
                            :carregando="carregandoDados"
                        />
                    </v-col>

                    <v-col cols="6">
                        <card-info
                            iconeBoxCor="red"
                            icone="mdi-package-variant-closed-check"
                            titulo="Cargas"
                            :value="totais.cargas"
                            rodape-texto="Quantidade total de cargas"
                            :carregando="carregandoDados"
                        />
                    </v-col>

                    <v-col cols="6">
                        <card-info
                            iconeBoxCor="grey accent-4"
                            icone="mdi-check"
                            titulo="Notificações"
                            :value="totais.notificado_nao"
                            rodape-texto="Notificações não enviadas"
                            :carregando="carregandoDados"
                        />
                    </v-col>

                    <v-col cols="6">
                        <card-info
                            iconeBoxCor="green accent-4"
                            icone="mdi-check-all"
                            titulo="Notificações"
                            :value="totais.notificado_sim"
                            rodape-texto="Notificações enviadas"
                            :carregando="carregandoDados"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <CarregandoLogoEmpresa :carregando="carregandoDados" :mensagem="carregandoDadosMensagem" />

        <span v-if="!carregandoDados">
            <v-card
                class="mt-5"
                v-for="(romaneiosFiltrados, index) in romaneiosFiltrados"
                :key="`romaneios-filtrados-${index}`"
            >
                <v-toolbar color="green darken-2" flat>
                    <v-toolbar-title>
                        <span class="white--text">
                            {{ romaneiosFiltrados.data_criacao_formatada }} - {{ romaneiosFiltrados.total_cargas }} processos
                            com {{ romaneiosFiltrados.total_mensagem_enviada }} notificado(s) por whatsapp e
                            {{ romaneiosFiltrados.total_mensagem_nao_enviada }} não notificado(s)
                        </span>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-title>
                    <v-col
                        cols="4"
                        v-for="(romaneio, indexRomaneio) in romaneiosFiltrados.romaneios"
                        :key="`romaneios-${indexRomaneio}`"
                    >
                        <romaneio-card
                            :dados="romaneio"
                            :mensagemErro="romaneio.mensagem_erro"
                            @romaneio-disponivel="romaneioDisponivel"
                        />
                    </v-col>
                </v-card-title>
            </v-card>
        </span>

        <v-row>
            <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                {{ notificacao.mensagem }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>

            <v-dialog v-model="modalDataHoraInicio" persistent max-width="800px">
                <v-card>
                    <v-toolbar color="orange" dark>
                        <v-toolbar-title>Defina a data e hora de início</v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon @click="cancelaDataHora()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text class="pt-5">
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12">
                                    <v-datetime-picker
                                        v-model="data_hora_inicio"
                                        clearText="Limpar"
                                        okText="OK"
                                        label="Data hora início"
                                        :dateFormat="'dd/MM/yyyy'"
                                        :timeFormat="'HH:mm'"
                                        :loading="false"
                                        :textFieldProps="{
                                            outlined: true,
                                            dense: true,
                                            clearable: true,
                                            'prepend-inner-icon': 'mdi-calendar'
                                        }"
                                        :timePickerProps="{
                                            format: '24hr'
                                        }"
                                        ref="dataHoraInicio"
                                    >
                                    </v-datetime-picker>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="cancelaDataHora()">
                            Cancelar
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="confirmaDataHora()">
                            Notificar ?
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import moment from 'moment';

import { sleep } from '@/helpers/Funcoes.js';

import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import DateTimePickerV2 from '@/components/Form/DateTimePickerV2.vue';

import RomaneioCard from './RomaneioCard.vue';
import InputAutoCompleteLocalV3 from '@/components/Form/InputAutoCompleteLocalV3.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';
import InputTextV3 from '@/components/Form/InputTextV3.vue';
import CardInfo from '@/components/Default/CardInfo.vue';

export default {
    name: 'RomaneiosDisponiveis',
    components: {
        CarregandoLogoEmpresa,
        DateTimePickerV2,
        RomaneioCard,
        InputAutoCompleteLocalV3,
        InputSelectV3,
        InputTextV3,
        CardInfo
    },
    mounted() {
        this.buscaRomaneiosDisponiveis();
    },
    watch: {
        romaneios: {
            deep: true,
            handler(novoValor) {
                this.filtroPreDefinidos();
            }
        },
        'filtros.id_alocacao_carga_romaneio': {
            deep: true,
            handler(novoValor) {
                this.filtroPreDefinidos();
            }
        },
        'filtros.mensagem_enviada': {
            deep: true,
            handler(novoValor) {
                this.filtroPreDefinidos();
            }
        },
        'filtros.motorista': {
            deep: true,
            handler(novoValor) {
                this.filtroPreDefinidos();
            }
        }
    },
    computed: {
        filtrosTotal() {
            let total = 0;

            Object.values(this.filtros).map(function(item) {
                if (item != null && item.length > 0) {
                    total += 1;
                }
            });

            return total;
        }
    },
    methods: {
        confirmaDataHora() {
            this.modalDataHoraInicio = false;

            this.confirmaModalDataHoraInicio(true);
        },
        cancelaDataHora() {
            this.modalDataHoraInicio = false;
            this.$refs.dataHoraInicio.clearHandler();

            this.confirmaModalDataHoraInicio(false);
        },
        async buscaRomaneiosDisponiveis() {
            try {
                this.carregandoDados = true;

                this.carregandoDadosMensagem = 'Aguarde, buscando romaneios disponíveis...';

                await ApiService({
                    method: 'GET',
                    url: `${API_URL.erp.alocacaocargaromaneio.romaneiosDisponiveis}`,
                    mostraCarregando: false
                })
                    .then(resposta => {
                        if (resposta?.data?.data) {
                            const romaneios = resposta.data.data.map(function(item) {
                                const resultado = item.atributos;

                                resultado.data_criacao = moment(resultado.data_criacao).format('YYYY-MM-DD');
                                resultado.data_hora_inicio = !resultado.data_hora_inicio
                                    ? null
                                    : moment(resultado.data_hora_inicio).format('DD/MM/YYYY HH:mm');
                                resultado.mensagem_enviada = resultado.mensagem_enviada == null ? 'N' : 'S';

                                return resultado;
                            });

                            this.criaRomaneiosFiltrados(romaneios);
                        }
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });

                this.calculaTotais();
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.carregandoDados = false;
            }
        },
        criaRomaneiosFiltrados(romaneios) {
            // GRUPA OS ROMANEIOS POR DATA_CRIACAO
            romaneios.sort((a, b) => {
                if (a.data_criacao > b.data_criacao) return -1;
                if (a.data_criacao < b.data_criacao) return 1;
                return 0;
            });

            this.romaneios = _(romaneios)
                .groupBy('data_criacao')
                .map(romaneio => {
                    const romaneioMotorista = _(romaneio)
                        .groupBy('id_alocacao_carga_romaneio')
                        .map(romaneioAgrupado => {
                            const cargas = romaneioAgrupado.map(function(item) {
                                return {
                                    ordem: item.ordem,
                                    id_programacao_carga_rodopar: item.id_programacao_carga_rodopar,
                                    numero_cte: item.numero_cte,
                                    terminal_coleta: item.terminal_coleta,
                                    terminal_entrega: item.terminal_entrega,
                                    observacao_gerais: item.observacao_gerais,
                                    cliente: item.nome_tomador_servico
                                };
                            });

                            cargas.sort((a, b) => {
                                if (a.ordem < b.ordem) return -1;
                                if (a.ordem > b.ordem) return 1;
                                return 0;
                            });

                            this.adicionaOpcaoIdAlocacaCargaRomaneio(romaneioAgrupado[0].id_alocacao_carga_romaneio);
                            this.adicionaOpcaoMotorista(romaneioAgrupado[0].motorista);

                            return {
                                id_alocacao_carga_romaneio: parseInt(romaneioAgrupado[0].id_alocacao_carga_romaneio),
                                placa_veiculo_cavalo: romaneioAgrupado[0].placa_veiculo_cavalo,
                                veiculo_frota: romaneioAgrupado[0].veiculo_frota,
                                veiculo_modelo: romaneioAgrupado[0].veiculo_modelo,
                                motorista: romaneioAgrupado[0].motorista,
                                imagem_avatar: romaneioAgrupado[0].imagem_avatar,
                                telefone_celular: romaneioAgrupado[0].telefone_celular,
                                data_criacao: romaneioAgrupado[0].data_criacao,
                                data_hora_inicio: romaneioAgrupado[0].data_hora_inicio,
                                mensagem_enviada: romaneioAgrupado[0].mensagem_enviada,
                                enviando_mensagem: false,
                                cargas: cargas
                            };
                        })
                        .value();

                    const romaneioFinal = {
                        data_criacao_formatada: this.formataData(romaneio[0].data_criacao),
                        data_criacao: romaneio[0].data_criacao,
                        total_cargas: romaneioMotorista.length,
                        total_mensagem_enviada: 0,
                        total_mensagem_nao_enviada: 0,
                        romaneios: romaneioMotorista
                    };

                    return romaneioFinal;
                })
                .value();

            this.romaneiosFiltrados = _.cloneDeep(this.romaneios);
        },
        async filtroPreDefinidos() {
            var filtros = { ...this.filtros };
            let aplicaFiltro = false;

            Object.values(filtros).map(function(item) {
                if (item != null && item.length > 0) {
                    aplicaFiltro = true;
                }
            });

            const romaneiosOriginal = _.cloneDeep(this.romaneios);

            if (!aplicaFiltro) {
                this.romaneiosFiltrados = romaneiosOriginal;

                return false;
            }

            this.carregandoDadosMensagem = 'filtando os dados, aguarde...';

            this.carregandoDados = true;

            await sleep(1000);

            this.romaneiosFiltrados = romaneiosOriginal.map(function(romaneioFiltrado) {
                let romaneiosFiltrados = romaneioFiltrado.romaneios;

                if (filtros.id_alocacao_carga_romaneio.length) {
                    romaneiosFiltrados = Object.values(romaneiosFiltrados).filter(function(item) {
                        let mostra = false;

                        if (
                            filtros.id_alocacao_carga_romaneio &&
                            filtros.id_alocacao_carga_romaneio.includes(item.id_alocacao_carga_romaneio)
                        ) {
                            mostra = true;
                        }

                        return mostra;
                    });
                }

                if (filtros.mensagem_enviada) {
                    romaneiosFiltrados = Object.values(romaneiosFiltrados).filter(function(item) {
                        let mostra = false;

                        if (filtros.mensagem_enviada && item.mensagem_enviada == filtros.mensagem_enviada) {
                            mostra = true;
                        }

                        return mostra;
                    });
                }

                if (filtros.motorista.length) {
                    romaneiosFiltrados = Object.values(romaneiosFiltrados).filter(function(item) {
                        let mostra = false;

                        if (filtros.motorista && filtros.motorista.includes(item.motorista)) {
                            mostra = true;
                        }

                        return mostra;
                    });
                }

                romaneioFiltrado.romaneios = romaneiosFiltrados;

                return romaneioFiltrado;
            });

            this.carregandoDados = false;
        },
        async romaneioDisponivel(romaneio_disponivel) {
            try {
                if (!romaneio_disponivel.telefone_celular) {
                    throw new Error(
                        `Motorista ${romaneio_disponivel.motorista} sem cadastro de celular para enviar notificação.`
                    );
                }

                let msgConfirmacao = false;

                if (romaneio_disponivel.mensagem_enviada == 'S') {
                    msgConfirmacao = await this.$root.$refs.$confirmaAcaoModal
                        .open(
                            'Enviar Notificação Whatsapp',
                            `Romaneio <strong>${romaneio_disponivel.id_alocacao_carga_romaneio}</strong> já foi notificado anteriormente para o motorista <strong>${romaneio_disponivel.motorista}</strong>, deseja reenviar ?`,
                            {
                                cor: 'warning'
                            }
                        )
                        .then(async confirma => {
                            return true;
                        });

                    if (!msgConfirmacao) {
                        return;
                    }
                }

                this.modalDataHoraInicio = true;

                const confirmaModal = await new Promise((resolve, reject) => {
                    this.confirmaModalDataHoraInicio = resolve;
                });

                if (!confirmaModal) {
                    return;
                }

                this.carregandoDadosMensagem = 'Aguarde, enviando mensagem no Whatsapp...';

                if (romaneio_disponivel.mensagem_enviada == 'N' || msgConfirmacao) {
                    let romaneio = await this.buscaRomaneioExistente(romaneio_disponivel.id_alocacao_carga_romaneio);

                    // SE NÃO ENCONTRAR O ROMANEIO NA LISTA ATUAL, NÃO FAZ NADA
                    if (!romaneio) {
                        return;
                    }

                    romaneio.enviando_mensagem = true;

                    const url = `${API_URL.erp.alocacaocargaromaneio.whatsappRomaneioDisponivel}`.replace(
                        '${id_alocacao_carga_romaneio}',
                        romaneio_disponivel.id_alocacao_carga_romaneio
                    );

                    const parametros = {
                        data_hora_inicio: moment(this.data_hora_inicio, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss')
                    };

                    await ApiService({
                        method: 'POST',
                        url: url,
                        params: parametros,
                        mostraCarregando: false
                    })
                        .then(resposta => {
                            romaneio.mensagem_enviada = 'S';
                            romaneio.enviando_mensagem = false;
                            romaneio.data_hora_inicio = moment(this.data_hora_inicio).format('DD/MM/YYYY HH:mm');
                        })
                        .catch(erro => {
                            romaneio.mensagem_erro = erro.message;
                            romaneio.mensagem_enviada = 'N';
                            romaneio.enviando_mensagem = false;

                            throw new Error(erro.message);
                        });

                    this.calculaTotais();
                }
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.carregandoContatoLista = false;
            }
        },
        async buscaRomaneioExistente(id_alocacao_carga_romaneio) {
            var romaneio = null;

            this.romaneios.forEach(async function(romaneioFiltrado) {
                const romaneioAtual = Object.values(romaneioFiltrado.romaneios).find(
                    romaneio => romaneio.id_alocacao_carga_romaneio === id_alocacao_carga_romaneio
                );

                if (romaneioAtual) {
                    romaneio = romaneioAtual;
                }
            });

            return romaneio;
        },
        async marcaMensagemEnviada(id_alocacao_carga_romaneio) {
            var romaneio = null;

            this.romaneios.forEach(async function(romaneioFiltrado) {
                const romaneioAtual = Object.values(romaneioFiltrado.romaneios).find(
                    romaneio => romaneio.id_alocacao_carga_romaneio === id_alocacao_carga_romaneio
                );

                if (romaneioAtual) {
                    romaneio = romaneioAtual;
                }
            });

            return romaneio;
        },
        formataData(dataValor) {
            return moment(dataValor).format('DD/MM/YYYY');
        },
        calculaTotais() {
            this.totais.romaneios = 0;
            this.totais.cargas = 0;
            this.totais.notificado_sim = 0;
            this.totais.notificado_nao = 0;

            let total_romaneios = 0;
            let total_cargas = 0;
            let total_notificado_sim = 0;
            let total_notificado_nao = 0;

            this.romaneiosFiltrados.forEach(function(romaneioFiltrado) {
                let total_mensagem_enviada = 0;
                let total_mensagem_nao_enviada = 0;

                Object.values(romaneioFiltrado.romaneios).forEach(function(romaneio) {
                    if (romaneio.mensagem_enviada == 'S') {
                        total_mensagem_enviada += 1;
                    }

                    if (romaneio.mensagem_enviada == 'N') {
                        total_mensagem_nao_enviada += 1;
                    }

                    total_romaneios += 1;
                    total_cargas += romaneio.cargas.length;
                });

                total_notificado_sim += total_mensagem_enviada;
                total_notificado_nao += total_mensagem_nao_enviada;

                romaneioFiltrado.total_mensagem_enviada = total_mensagem_enviada;
                romaneioFiltrado.total_mensagem_nao_enviada = total_mensagem_nao_enviada;
            });

            this.totais.romaneios = total_romaneios;
            this.totais.cargas = total_cargas;
            this.totais.notificado_sim = total_notificado_sim;
            this.totais.notificado_nao = total_notificado_nao;
        },
        buscaCardMotorista() {
            this.filtroPreDefinidos();
        },
        adicionaOpcaoIdAlocacaCargaRomaneio(id_alocacao_carga_romaneio) {
            if (this.opcaoIdAlocacaoCargaRomaneio[id_alocacao_carga_romaneio]) {
                return;
            }

            this.opcaoIdAlocacaoCargaRomaneio.push(id_alocacao_carga_romaneio);
        },
        adicionaOpcaoMotorista(motorista) {
            if (this.motoristas[motorista]) {
                return;
            }

            this.motoristas.push(motorista);
        }
    },
    data() {
        return {
            show: false,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            carregandoDados: true,
            carregandoDadosMensagem: 'Aguarde, buscando romaneios disponíveis...',
            modalDataHoraInicio: false,
            data_hora_inicio: null,
            confirmaModalDataHoraInicio: null,
            filtros: {
                id_alocacao_carga_romaneio: [],
                motorista: [],
                mensagem_enviada: null
            },
            cabecalho: [
                {
                    text: 'ID ROMANEIO',
                    value: 'id_alocacao_carga_romaneio',
                    width: '80'
                },
                {
                    text: 'ORDEM',
                    value: 'ordem',
                    width: '50'
                },
                {
                    text: 'ID',
                    value: 'motorista',
                    width: '80'
                }
            ],
            romaneios: [],
            romaneiosFiltrados: [],
            opcaoNotificacaoSimNao: [
                { valor: 'S', descricao: 'Sim' },
                { valor: 'N', descricao: 'Não' }
            ],
            motoristas: [],
            opcaoIdAlocacaoCargaRomaneio: [],
            totais: {
                romaneios: 0,
                cargas: 0,
                notificado_nao: 0,
                notificado_sim: 0
            }
        };
    }
};
</script>
