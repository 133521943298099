<template>
    <v-container fluid>
        <v-card class="mx-auto" max-width="300" outlined>
            <v-list-item three-line>
                <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1 text-wrap" v-html="descricao"> </v-list-item-title>

                    <v-chip class="font-weight-bold" color="blue-grey lighten-4 white--black" label>
                        <v-icon class="mr-2" color="green">mdi-whatsapp</v-icon>
                        {{ numero_telefone }}
                    </v-chip>

                    <v-list-item-content>
                        {{ observacao }}
                    </v-list-item-content>
                </v-list-item-content>
            </v-list-item>

            <v-card-actions>
                <v-btn class="pl-2" color="green" outlined rounded text @click="selecionar()">
                    <v-icon color="green"> mdi-check-decagram-outline </v-icon>

                    <span class="ml-2"> Usar </span>
                </v-btn>
            </v-card-actions>
        </v-card>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false"> Fechar </v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-container>
</template>

<script>
export default {
    name: 'NumeroAtendimentoDisponivel',
    methods: {
        cancelar() {
            this.$emit('cancelar');
        },
        selecionar() {
            this.$emit('selecionar', { id_whatsapp_numero_atendimento: this.id_whatsapp_numero_atendimento });
        },
    },
    props: {
        id_whatsapp_numero_atendimento: {
            type: Number,
            required: true,
        },
        descricao: {
            type: String,
            required: true,
        },
        observacao: {
            type: String,
            default: () => '',
        },
        numero_telefone: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success',
            },
        };
    },
};
</script>
