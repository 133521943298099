const state = {
    alerta: {
        mostrar: false,
        mensagem: '',
        tipo: '',
        tempoExpira: 5000
    }
}

const mutations = {
    setAlerta(state, mostraAlerta) {
        state.alerta = { ...mostraAlerta };
    },
    escondeAlerta(state) {
        state.alerta.mostrar = false;
    }
}

const actions = {}

const getters = {}

export default {
    state,
    mutations,
    actions,
    getters
}
