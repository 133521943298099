<template>
    <v-card class="mx-auto" outlined>
        <v-card-title class="text-h5">
            Auditar Processo - Devolução Vazio
        </v-card-title>

        <v-card-subtitle>
            Seleciona o motorista da devolução no terminal
        </v-card-subtitle>

        <v-card-text>
            <InputAutoCompleteV3
                v-model="form.motorista"
                :datatableConfiguracao="autoCompleta.motorista.datatable"
                tituloBusca="Motorista Busca..."
                :itemsOpcoes="itensMotorista"
                itemValor="id_motorista"
                itemTexto="nome"
                :returnObject="true"
            />
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="red darken-1" text @click="cancelar()">Cancelar</v-btn>

            <v-btn color="green darken-1" text @click="auditar()">Auditar ?</v-btn>
        </v-card-actions>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-card>
</template>

<script>
import { API_URL, API_URL_BASE } from '@/config/configs';

import InputAutoCompleteV3 from '@/components/Form/InputAutoCompleteV3.vue';

export default {
    name: 'AuditarBaixa',
    components: {
        InputAutoCompleteV3
    },
    props: {
        id_devolucao_vazio: {
            type: Number,
            required: true
        }
    },
    methods: {
        cancelar() {
            this.$emit('cancelar');
        },
        auditar() {
            if (!this.form.motorista) {
                this.notificacao = {
                    mensagem: 'Obrigatório selecionar o motorista da devolução no terminal',
                    tipo: 'error',
                    mostra: true
                };

                return;
            }

            this.$emit('auditar', {
                id_devolucao_vazio: this.id_devolucao_vazio,
                id_motorista: this.form.motorista.id_motorista,
                nome_motorista: this.form.motorista.nome
            });
        }
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            form: {
                motorista: null
            },
            itensMotorista: [],
            autoCompleta: {
                motorista: {
                    datatable: {
                        id_datatable: 'motorista_id_parceiro_motorista',
                        chave_primaria: 'motorista_id_parceiro_motorista',
                        url: `${API_URL_BASE}${API_URL.erp.devolucaovazio.datatable}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.devolucaovazio.buscaMotorista}`,
                        colunas: [
                            {
                                id: 'id_motorista',
                                nome: 'id_motorista',
                                descricao: 'ID'
                            },
                            {
                                id: 'nome',
                                nome: 'nome',
                                descricao: 'Nome'
                            }
                        ]
                    }
                }
            }
        };
    }
};
</script>
