import { API_URL_BASE, API_URL } from '@/config/configs';

const AtestadoMotivo = {
    datatable: {
        id_datatable: 'atestadomotivo',
        chave_primaria: 'id_atestado_motivo',
        url: `${API_URL_BASE}${API_URL.erp.atestadomotivo.datatable}`,
        colunas: false,
    },
    formRefsComponente: 'AtestadoMotivoForm',
    formNome: 'formAtestadoMotivo',
    moduloNome: 'atestadomotivo',
    chavePrimaria: 'id_atestado_motivo',
    componenteArquivo: 'AtestadoMotivo/Form.vue',
    tabRefs: 'tabAtestadoMotivo',
    urlNovoRegistro: '',
    urlConsultaRegistro: 'listaPorIdForm',
    tabs: []
}

export { AtestadoMotivo };

