import { API_URL_BASE, API_URL } from '@/config/configs';

const Usuario = {
    datatable: {
        id_datatable: 'usuario',
        chave_primaria: 'id_usuario',
        url: `${API_URL_BASE}${API_URL.erp.usuario.datatable}`,
        colunas: false,
    },
    formRefsComponente: 'UsuarioForm',
    formNome: 'formUsuario',
    moduloNome: 'usuario',
    chavePrimaria: 'id_usuario',
    componenteArquivo: 'Usuario/Form.vue',
    tabRefs: 'tabUsuario',
    urlNovoRegistro: '',
    urlConsultaRegistro: 'listaPorIdForm',
    tabs: []
}

export { Usuario };

