import store from '@/vuex/store.js';
import { json2excel } from 'js2excel';

const sleep = async ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

const Alerta = (mensagem, tipo) => {
    store.commit('setAlerta', {
        mostrar: true,
        mensagem: mensagem,
        tipo: tipo
    });
};

const AlertaEsconde = () => {
    store.commit('escondeAlerta', {});
};

const SnackbarMensagem = mensagem => {
    store.commit('setSnack', {
        show: true,
        message: mensagem
    });
};

const MetodoNovoOuAtualizaApi = dado => {
    /**
     * Padrão REST API
     *
     * Se tiver ID atualiza, se não tiver cria
     *
     * PUT = ATUALIZA
     * POST = NOVO
     */
    return dado !== '' && dado > 0 ? 'put' : 'post';
};

const RemoverAcentos = texto => {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

/*
const JuntaObjetosRecursivo = function (to, from) {
    for (var n in from) {
        if (typeof to[n] != 'object') {
            if(to.hasOwnProperty(n)){
                to[n] = from[n];
            }
        } else if (typeof from[n] == 'object') {
            to[n] = JuntaObjetosFormaRecursiva(to[n], from[n]);
        }
    }
    return to;
};
*/

const JuntaObjetosRecursivoCompleto = function(form, dados, excecao) {
    excecao = excecao || [];

    Object.keys(form).map((colunas, indice2) => {
        let indice = 0;

        if (excecao.includes(colunas)) {
            return;
        }

        if (form[colunas].length == 0) {
            let dado = dados[colunas];
            form[colunas][indice] = dado == undefined ? '' : dado;
        }
        console.log('colunas', colunas);
        for (const coluna in form[colunas][indice]) {
            console.log('coluna', coluna);
            if (excecao.includes(coluna)) {
                continue;
            }

            /**
             * Se não existir o index nos dados retornados
             */
            if (!dados[colunas][indice]) {
                continue;
            }

            /**
             * Se não encontrar o indice no form
             * Ele preenche com o dado original
             */
            if (!dados[colunas][indice].hasOwnProperty(coluna)) {
                //indice++;
                continue;
            }

            form[colunas][indice][coluna] = dados[colunas][indice][coluna];
        }
    });

    return form;
};

const JuntaObjetosRecursivo = function(form, dados, excecao) {
    excecao = excecao || [];

    form.forEach(function(colunas, indice) {
        for (coluna in colunas) {
            if (excecao.includes(coluna)) {
                continue;
            }

            /**
             * Se não existir o index nos dados retornados
             */
            if (!dados[indice]) {
                continue;
            }

            if (Array.isArray(colunas[coluna])) {
                let arrayColunas = form[indice][coluna];

                if (arrayColunas.length == 0) {
                    arrayColunas = dados[indice][coluna];
                }

                form[indice][coluna] = JuntaObjetosRecursivo(arrayColunas, dados[indice][coluna], excecao);
                continue;
            }

            /**
             * Se não encontrar o indice no form
             * Ele preenche com o dado original
             */
            if (!dados[indice].hasOwnProperty(coluna)) {
                continue;
            }

            form[indice][coluna] = dados[indice][coluna];
        }
    });

    return form;
};

/**
 *
 * @param {*} form
 * @returns Object
 */
const FormataDadosParaEnviarForm = function(form) {
    let conteudoFormulario = {};

    Object.keys(form).map((chave, idx) => {
        if (chave == 'dados') {
            if (typeof form[chave][0] != undefined) {
                conteudoFormulario.dados = form[chave][0];

                return;
            }
        }

        conteudoFormulario.dados[chave] = form[chave];

        // já retorna aqui, pq vai so copiar tudo para a chave de dados
        return;

        if (form[chave].length > 0) {
            conteudoFormulario.dados[chave] = form[chave];

            return;
        }

        Object.values(form[chave]).map((coluna, indice) => {
            Object.keys(coluna).map((coluna2, indice2) => {
                conteudoFormulario.dados[coluna2] = form[chave][0][coluna2];
            });
        });
    });

    return conteudoFormulario;
};

const FormataMoeda = function(valor, opcoes, usarPrefixo, usarPrefixoPercentual) {
    if (valor == 0 || valor == undefined) {
        return 0;
    }

    let opc = { minimumFractionDigits: 2 };

    opc = opc !== 'undefined' ? { ...opc, opcoes } : {};

    if (usarPrefixo) {
        opc.style = 'currency';
        opc.currency = 'BRL';
    }

    if (usarPrefixoPercentual) {
        opc.style = 'percent';
        opc.currency = 'BRL';
    }

    return valor.toLocaleString('pt-br', opc);
};

const DownloadArquivo = function(arquivo, nomeArquivo) {
    const url = window.URL.createObjectURL(new Blob([arquivo]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', nomeArquivo);
    document.body.appendChild(link);
    link.click();
};

const DownloadArquivoBse64 = function(arquivo, nomeArquivo) {
    var a = document.createElement('a');
    a.href = arquivo;
    a.download = nomeArquivo;

    a.click();
};

const PreencheDadosMesmaPropriedade = function(dadosOriginais, dadosCopia) {
    return Object.keys(dadosOriginais).reduce((a, key) => ({ ...a, [key]: dadosCopia[key] }), {});
};

const ExportaArrayObjectsParaExcel = function(dados, colunas, nomeArquivo) {
    if (!dados.length) {
        throw new Error('Não há itens para exportar');
    }

    const colunasFiltradas = [];

    Object.values(colunas).forEach(coluna => {
        if (!coluna.escondeExportacao) {
            colunasFiltradas.push(coluna.value);
        }
    });

    const data = dados.map(item => {
        return Object.keys(item)
            .filter(key => colunasFiltradas.includes(key))
            .reduce((obj, key) => {
                const coluna = colunas.find(cabecalhoColuna => cabecalhoColuna.value === key);

                obj[coluna.text] = item[key];

                return obj;
            }, {});
    });

    json2excel({
        data,
        name: nomeArquivo
    });
};

export function normalizeRelations(data, fields) {
    return {
        ...data,
        ...fields.reduce(
            (prev, field) => ({
                ...prev,
                [field]: Array.isArray(data[field]) ? data[field].map(x => x.id) : data[field].id
            }),
            {}
        )
    };
}

export function resolveRelations(data, fields, rootGetters) {
    return {
        ...data,
        ...fields.reduce(
            (prev, field) => ({
                ...prev,
                [field]: Array.isArray(data[field])
                    ? data[field].map(x => rootGetters[`${field}/find`](x))
                    : rootGetters[`${field}/find`](data[field])
            }),
            {}
        )
    };
}

export {
    sleep,
    Alerta,
    AlertaEsconde,
    SnackbarMensagem,
    MetodoNovoOuAtualizaApi,
    RemoverAcentos,
    JuntaObjetosRecursivoCompleto,
    JuntaObjetosRecursivo,
    FormataDadosParaEnviarForm,
    FormataMoeda,
    DownloadArquivo,
    DownloadArquivoBse64,
    PreencheDadosMesmaPropriedade,
    ExportaArrayObjectsParaExcel
};
