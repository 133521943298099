const SIM = 'S';
const NAO = 'N';

const SimNaoEnum = {
    SIM: 'S',
    NAO: 'N'
};

export function listaDescricao() {
    return [
        {
            value: SIM,
            label: 'Sim'
        },
        {
            value: NAO,
            label: 'Não'
        }
    ];
}

export function descricao(simNao) {
    switch (simNao) {
        case SIM:
            return 'Sim';
        case NAO:
            return 'Não';
    }
}

export default SimNaoEnum;
