import moment from 'moment';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { DownloadArquivoBse64, sleep } from '@/helpers/Funcoes.js';

const state = {
    carregando: false,
    cor: ['#f9a825', '#388e3c'],
    dataDe: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
    dataAte: moment()
        .add(5, 'days')
        .format('YYYY-MM-DD'),
    container: false,
    dadosDemanda: {},
    dadosDemandaTotal: {},
    dadosTopClientes: {},
    dadosDemandaProximoDia: [],
    emphasis: {
        label: {
            show: true,
            fontSize: 12,
            fontWeight: 'bold'
        },
        itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
    }
};

const actions = {
    async buscaDashboard({ commit, state, getters }, { data_de, data_ate, container }) {
        try {
            if (moment(data_ate).isSameOrBefore(moment(data_de))) {
                const dataDe = moment(data_de).format('DD/MM/YYYY');
                const dataAte = moment(data_ate).format('DD/MM/YYYY');

                this.notificacao = {
                    mensagem: `Data Até: ${dataAte} não pode ser menor que Data De: ${dataDe}`,
                    tipo: 'error',
                    mostra: true
                };

                return;
            }

            const params = {};

            params.data_de = data_de;
            params.data_ate = data_ate;
            params.container = container != undefined ? container : false;

            commit('atualizaDataDe', params.data_de);
            commit('atualizaDataAte', params.data_ate);
            commit('atualizaContainer', params.container);

            commit('dadosDemanda', {});
            commit('dadosDemandaTotal', {});
            commit('dadosTopClientes', {});
            commit('dadosDemandaProximoDia', []);

            commit('carregando', true);

            await ApiService({
                method: 'get',
                url: `${API_URL.erp.dashboard.demanda}`,
                params: params,
                mostraCarregando: false
            })
                .then(resposta => {
                    if (resposta.data.data) {
                        commit('dadosDemanda', resposta.data.data.previstosEConfirmados);
                        commit('dadosDemandaTotal', resposta.data.data.totais);
                        commit('dadosTopClientes', resposta.data.data.porCliente);
                        commit('dadosDemandaProximoDia', resposta.data.data.demandaProximoDia);
                    }
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        } catch (erro) {
            throw new Error(erro.message);
        } finally {
            commit('carregando', false);
        }
    },
    atualizaDataDe({ commit }, data) {
        commit('atualizaDataDe', data);
    },
    atualizaDataAte({ commit }, data) {
        commit('atualizaDataAte', data);
    },
    atualizaContainer({ commit }, data) {
        commit('atualizaContainer', data);
    },
    baixarImagem({ state }, graficoComponente) {
        const grafico = graficoComponente.getDataURL({
            pixelRatio: 2,
            backgroundColor: '#fff'
        });

        const nomeArquivo = `Demanda-de-${state.dataDe}-ate-${state.dataAte}.png`;

        DownloadArquivoBse64(grafico, nomeArquivo);
    }
};

const mutations = {
    carregando(state, estado) {
        state.carregando = estado;
    },
    dadosDemanda(state, estado) {
        state.dadosDemanda = estado;
    },
    dadosDemandaTotal(state, estado) {
        state.dadosDemandaTotal = estado;
    },
    dadosTopClientes(state, estado) {
        state.dadosTopClientes = estado;
    },
    dadosDemandaProximoDia(state, estado) {
        state.dadosDemandaProximoDia = estado;
    },
    atualizaDataDe(state, estado) {
        state.dataDe = estado;
    },
    atualizaDataAte(state, estado) {
        state.dataAte = estado;
    },
    atualizaContainer(state, estado) {
        state.container = estado;
    }
};

const getters = {
    carregando: state => {
        return state.carregando;
    },
    carregandoOpcoes: state => {
        return {
            text: 'Carregando, aguarde...',
            color: '#EB7403',
            textColor: '#000',
            maskColor: 'rgba(255, 255, 255, 0.8)',
            zlevel: 0,
            fontSize: 12,
            showSpinner: true,
            spinnerRadius: 15,
            lineWidth: 5,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'sans-serif'
        };
    },
    dadosDemanda: state => {
        return state.dadosDemanda;
    },
    dadosDemandaTotal: state => {
        return state.dadosDemandaTotal;
    },
    dadosTopClientes: state => {
        return state.dadosTopClientes;
    },
    dadosDemandaProximoDia: state => {
        return state.dadosDemandaProximoDia;
    },
    dataDe: state => {
        return state.dataDe;
    },
    dataAte: state => {
        return state.dataAte;
    },
    container: state => {
        return state.container;
    },
    cor: state => {
        return state.cor;
    },
    emphasis: state => {
        return state.emphasis;
    }
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
};
