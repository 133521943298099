<template>
    <v-form ref="form">
        <v-row>
            <v-col cols="12" sm="12">
                <v-card>
                    <v-card-title>{{ anexos.length }} Anexo(s)</v-card-title>

                    <v-card-text>
                        <CarregandoLogoEmpresa :carregando="carregando" :mensagem="mensagemCarregando" />

                        <span v-if="!carregando">
                            <v-chip
                                v-for="(anexo, i) in anexos"
                                :key="i"
                                :color="selecionaCor()"
                                @click="downloadAnexo(anexo.id_documento_anexo)"
                                class="ma-2"
                                dark
                                label
                                small
                                close
                                @click:close="removeAnexo(anexo, i)"
                            >
                                {{ anexo.nome }} ({{ tipoDocumento(anexo.tipo_documento) }})
                            </v-chip>
                        </span>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-card>
            <v-card-title>Arquivos</v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="8">
                        <InputFileV4
                            v-model="form.anexo.arquivos"
                            :regra="regrasArquivo"
                            :multiplo="true"
                            icone="upload"
                            rotulo="Selecione o arquivo (PDF,JPG,PNG)"
                            tipoArquivo=".pdf"
                            :tipoArquivoPermitido="['application/pdf', 'image/jpeg', 'image/jpg', 'image/png']"
                            v-on:change="adicionaArquivos($event)"
                        />
                    </v-col>

                    <v-col cols="12" sm="4">
                        <InputSelectV3
                            v-model="form.anexo.tipo_documento"
                            rotulo="Tipo Documento"
                            :itens="opcaoTipoDocumento"
                            :regra="regraTipoDocumento"
                            itemTexto="descricao"
                            itemValor="id"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-form>
</template>

<script>
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import { Obrigatorio } from '@/helpers/ValidadorCampo.js';

import TipoDocumentoEnum from '@/Enum/DevolucaoVazio/TipoDocumentoEnum.js';

import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import InputFileV4 from '@/components/Form/InputFileV4.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';

export default {
    name: 'FormAnexos',
    components: {
        CarregandoLogoEmpresa,
        InputFileV4,
        InputSelectV3
    },
    props: {
        idDevolucaoVazio: {
            type: Number
        },
        dadosAnexo: {
            type: Object | Array,
            default() {
                return {};
            }
        }
    },
    async created() {},
    async mounted() {},
    computed: {},
    watch: {
        dadosAnexo: {
            handler: function(dadosAtual) {
                this.anexos = dadosAtual;
            },
            deep: true
        }
    },
    methods: {
        adicionaAnexo: function(dadosAnexo) {
            let arquivo = { arquivo: null };

            this.form.anexo.arquivos.push(arquivo);
        },
        async removeAnexo(documento, posicao) {
            try {
                await this.$root.$refs.$confirmaAcaoModal
                    .open('Apaga', `Apagar o documento ${documento.nome} ?`, {
                        cor: 'red'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        this.mensagemCarregando = 'Apagando anexo, aguarde...';
                        this.carregando = true;

                        let urlConfig = {
                            method: 'DELETE',
                            url: `${API_URL.erp.devolucaovazio.apagaDocumento}/${this.idDevolucaoVazio}/${documento.id_documento_anexo}`,
                            data: {},
                            mostraCarregando: false
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                const listaAnexos = this.anexos.filter(
                                    (item, index) => item.id_documento_anexo !== documento.id_documento_anexo
                                );

                                this.anexos = [];

                                listaAnexos.forEach((dados, index) => {
                                    this.anexos.push(dados);
                                });

                                this.notificacao = {
                                    mensagem: `Documento ${documento.nome} apagado.`,
                                    tipo: 'success',
                                    mostra: true
                                };
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });

                        this.carregando = false;
                    });
            } catch (erro) {
                this.carregando = false;

                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        adicionaArquivos(arquivo) {
            this.form.anexo.arquivos = arquivo;
        },
        selecionaCor() {
            const posicao = parseInt(Math.random() * (this.cores.length - 1) + 1);

            return `${this.cores[posicao]}`;
        },
        preparaDados() {
            return {
                arquivos: this.form.anexo.arquivos,
                tipo_documento: this.form.anexo.tipo_documento
            };
        },
        async downloadAnexo(id) {
            await ApiService({
                method: 'GET',
                url: `${API_URL.erp.devolucaovazio.downloadAnexo}/${id}`,
                responseType: 'blob',
                mostraCarregando: false
            })
                .then(resposta => {
                    const blob = new Blob([resposta.data], { type: resposta.data.type });
                    const url = window.URL.createObjectURL(blob);
                    window.open(url);
                })
                .catch(erro => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        },
        tipoDocumento(tipoDocumento) {
            if (TipoDocumentoEnum.NORMAL == tipoDocumento) {
                return 'NORMAL';
            }

            if (TipoDocumentoEnum.INTERCAMBIO == tipoDocumento) {
                return 'INTERCAMBIO';
            }

            return 'NÃO DEFINIDO';
        }
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            anexos: {},
            regrasArquivo: [
                files => !files || !files.some(file => file.size > 3000000) || 'Arquivo não pode ser maior que 3 MB!'
            ],
            form: {
                anexo: {
                    arquivos: [],
                    tipo_documento: 1
                }
            },
            mensagemCarregando: null,
            carregando: false,
            cores: [
                'green lighten-3',
                'pink lighten-3',
                'purple lighten-3',
                'deep-purple lighten-3',
                'blue lighten-3',
                'indigo lighten-3',
                'teal lighten-3',
                'orange lighten-3',
                'light-blue lighten-3',
                'cyan darken-1',
                'teal lighten-3',
                'yellow darken-1',
                'amber lighten-3',
                'deep-orange lighten-3',
                'brown lighten-3',
                'blue-grey'
            ],
            opcaoTipoDocumento: [
                { id: 1, descricao: 'Normal' },
                { id: 2, descricao: 'Intercambio' }
            ],
            regraTipoDocumento: [Obrigatorio()]
        };
    }
};
</script>

<style></style>
