<template>
    <v-select
        :value="value"
        @input="$emit('input', $event)"
        :items="itens"
        :label="rotulo"
        :rules="regra"
        :disabled="desativado"
        :required="obrigatorio"
        :item-value="itemValor"
        :item-text="itemTexto"
        :loading="carregando"
        :suffix="carregandoMensagem"
        :small-chips="smallChips"
        :multiple="multiplo"
        :append-icon="iconeAppend"
        :prepend-icon="iconePrepend"
        :outlined="estilo('outlined')"
        :menu-props="{ offsetY: true }"
        @click:clear="$emit('clear', null)"
        @change="$emit('change', $event)"
        v-bind="$attrs"
        clearable
        dense
    >
    </v-select>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        value: {
            default: () => ''
        },
        itens: {
            type: Array,
            default: () => []
        },
        itemValor: {
            type: String,
            default: () => ''
        },
        itemTexto: {
            type: String,
            default: () => ''
        },
        regra: {
            type: Array,
            default: () => []
        },
        rotulo: {
            type: String,
            default: () => ''
        },
        desativado: {
            type: Boolean,
            default: () => false
        },
        obrigatorio: {
            type: Boolean,
            default: () => false
        },
        carregando: {
            type: Boolean,
            default: () => false
        },
        smallChips: {
            type: Boolean,
            default: () => false
        },
        multiplo: {
            type: Boolean,
            default: () => false
        },
        estiloCampo: {
            type: String,
            default: () => 'outlined'
        },
        iconeAppend: {
            type: String,
            default: () => 'mdi-menu-down'
        },
        iconePrepend: {
            type: String,
            default: () => null
        }
    },
    created() {},
    watch: {},
    computed: {
        carregandoMensagem() {
            return this.carregando ? 'Aguarde...' : '';
        }
    },
    mounted() {},
    methods: {
        estilo(estiloDefinido) {
            if (this.estiloCampo == estiloDefinido) {
                return true;
            }

            return false;
        }
    },
    data() {
        return {};
    }
};
</script>

<style scoped></style>
