<template>
    <v-card elevation="5">
        <v-card-actions>
            <v-list-item>
                <v-list-item-avatar size="100">
                    <v-img :src="imagemAvatar(imagem_avatar)"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-content class="body-1">
                        <strong>{{ motorista }}</strong>
                    </v-list-item-content>

                    <v-list-item-subtitle>
                        <v-chip color="pink darken-1" text-color="white" label>
                            <v-icon left>
                                mdi-cellphone-message
                            </v-icon>
                            <span v-if="telefone_celular">
                                {{ telefone_celular | VMask('+55 (##) # ####-####') }}
                            </span>
                            <span v-else>
                                SEM CADASTRO
                            </span>
                        </v-chip>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-card-actions>

        <v-card-actions>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="subtitle-1">
                        <span class="font-weight-bold body-1">
                            CPF:
                        </span>

                        <span v-if="cpf">
                            {{ cpfMascara }}
                        </span>

                        <span v-else>
                            <v-icon color="red">
                                mdi-close-octagon
                            </v-icon>
                        </span>
                    </v-list-item-title>

                    <v-list-item-title class="subtitle-1">
                        <span class="font-weight-bold body-1">
                            RG:
                        </span>

                        <span v-if="rg">
                            {{ rg }}
                        </span>

                        <span v-else>
                            <v-icon color="red">
                                mdi-close-octagon
                            </v-icon>
                        </span>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-card-actions>
    </v-card>
</template>

<script>
import { isEmpty } from 'lodash';
import { mask } from 'maska';

export default {
    name: 'PainelCard',
    props: {
        motorista: {
            type: String,
            required: true
        },
        cpf: {
            type: String,
            default: () => null
        },
        rg: {
            type: String,
            default: () => null
        },
        imagem_avatar: {
            type: String,
            default: () => null
        },
        telefone_celular: {
            type: String,
            default: () => null
        },
        data_hora_inicio: {
            type: String,
            default: () => null
        }
    },
    computed: {
        cpfMascara() {
            return isEmpty(this.cpf) ? '' : mask(this.cpf, '###.###.###-##');
        }
    },
    methods: {
        imagemAvatar(imagemAvatar) {
            return imagemAvatar ?? require('@/assets/image/perfil/img_avatar.png');
        }
    }
};
</script>
