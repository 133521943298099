<template>
    <v-card outlined elevation="2">
        <v-card-title class="">
            <span class="text-h6"> Demanda de amanhã {{ proximoDia }}</span>

            <v-spacer></v-spacer>

            <span class="pr-2">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="blue-grey darken-1"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="carregaDashboard(dataDe, dataAte)"
                        >
                            mdi-refresh
                        </v-icon>
                    </template>
                    <span>Recarregar</span>
                </v-tooltip>
            </span>

            <span class="pr-2">
                <v-tooltip max-width="200" left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="warning darken-1" dark v-bind="attrs" v-on="on" @click="ajuda = true">
                            mdi-account-question
                        </v-icon>
                    </template>
                    <span>
                        Ajuda com a composição do gráfico ? Clique para saber mais.
                    </span>
                </v-tooltip>
            </span>
        </v-card-title>

        <v-card-text>
            <TabelaVuetify
                ref="tabela"
                :cabecalho="datatable.cabecalho"
                :itens="datatable.itens"
                :total_registros="datatable.totalRegistros"
                :por_pagina="datatable.por_pagina"
                :ultima_pagina="datatable.ultima_pagina"
                :carregando="datatable.carregando"
                :chave_primaria="datatable.chave_primaria"
                :ordenar_por="datatable.ordenarPor"
                :ordenar_direcao="datatable.ordenarDirecao"
                :mostra_selecao="false"
                altura="54vh"
            >
            </TabelaVuetify>
        </v-card-text>

        <div class="text-center">
            <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                {{ notificacao.mensagem }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>

        <v-dialog v-model="ajuda" max-width="800">
            <v-card>
                <v-card-title class="text-h5">
                    Demanda Top 15 Clientes
                </v-card-title>

                <v-card-text>
                    Programações de cargas com o status Previsto e Confirmado em percentual, de acordo com o filtro da data de
                    e até.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="blue darken-1" text @click="ajuda = false">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import moment from 'moment';

import TabelaVuetify from '@/components/Default/TabelaVuetify.vue';

export default {
    name: 'DemandaProximoDia',
    components: {
        TabelaVuetify
    },
    watch: {
        dadosDemandaProximoDia: {
            handler: function(dadosAtual) {
                this.adicionaDadosAoGrafico(dadosAtual);
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters({
            carregando: 'demanda/carregando',
            carregandoOpcoes: 'demanda/carregandoOpcoes',
            dataDe: 'demanda/dataDe',
            dataAte: 'demanda/dataAte',
            dadosDemandaProximoDia: 'demanda/dadosDemandaProximoDia',
            tamanhoDashboard: 'dashboard/tamanhoDashboard'
        }),
        ...mapState('demanda', {
            cor(state, getters) {
                return getters['cor'];
            }
        }),
        tamanhoPixel() {
            return this.tamanhoDashboard({ id_dashboard: this.id_dashboard });
        },
        proximoDia() {
            return moment()
                .add(1, 'days')
                .format('DD/MM/YYYY');
        }
    },
    methods: {
        ...mapActions({
            buscaDashboard: 'demanda/buscaDashboard',
            baixarImagem: 'demanda/baixarImagem'
        }),
        carregaDashboard(data_de, data_ate) {
            this.buscaDashboard({ data_de: data_de, data_ate: data_ate })
                .then(async () => {
                    this.adicionaDadosAoGrafico(this.dadosDemandaProximoDia);
                })
                .catch(erro => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        },
        formataData(data) {
            return moment(data).format('DD/MM/YYYY');
        },
        downloadImagem() {
            this.baixarImagem(this.$refs['Grafico']);
        },
        adicionaDadosAoGrafico(graficoDados) {
            if (!graficoDados) {
                return;
            }

            const total = graficoDados?.length;

            this.datatable.itens = graficoDados;

            this.datatable.totalRegistros = total;
            this.datatable.ultima_pagina = total;
            this.datatable.carregando = false;
        }
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            ajuda: false,
            datatable: {
                carregando: false,
                mensagemCarregando: 'Buscando, aguarde...',
                chave_primaria: 'cliente',
                itens: [],
                totalRegistros: 0,
                ultima_pagina: 0,
                pagina_atual: 1,
                por_pagina: 500,
                ordenarPor: 'confirmado',
                ordenarDirecao: true,
                opcoes: {},
                cabecalho: [
                    {
                        text: 'Cliente',
                        value: 'cliente',
                        width: '100%',
                        showFilter: false
                    },
                    {
                        text: 'Previsto',
                        value: 'previsto',
                        width: '100',
                        showFilter: false
                    },
                    {
                        text: 'Confirmado',
                        value: 'confirmado',
                        width: '100',
                        showFilter: false
                    }
                ]
            }
        };
    }
};
</script>

<style scoped>
.echarts {
    width: 100%;
    height: 300px;
}
</style>
