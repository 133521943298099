<template>
    <div>
        <v-row>
            <v-col cols="12" sm="10">
                <TituloModulo />
            </v-col>

            <v-col cols="12" sm="2" class="text-right">
                <v-tooltip v-if="!broadcastConectado" bottom color="warning">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon size="60" color="warning" v-bind="attrs" v-on="on">info</v-icon>
                    </template>
                    <span>Falha na conexão em tempo real</span>
                </v-tooltip>
            </v-col>
        </v-row>

        <BarraNavegacaoBotaoV3 :botoes="botoesComPermissao('TODOS')" />

        <v-card elevation="5">
            <TabelaVuetify
                ref="tabela"
                :cabecalho="datatable.cabecalho"
                :itens="datatable.itens"
                :total_registros="datatable.totalRegistros"
                :por_pagina="datatable.porPagina"
                :itens_por_pagina="[200, 500, -1]"
                :ultima_pagina="datatable.ultimaPagina"
                :carregando="false"
                :ordenar_direcao="true"
                :mostra_selecao="false"
                :item_class="regraPintaLinha"
                ordenar_por="cte_data_criacao"
                chave_primaria=""
                @opcoes="buscaPainelEntrega"
            >
                <template v-slot:item.acao="{ item }">
                    <v-icon color="yellow darken-2" @click="modalNotaFiscalOcorrencia(item.numero_cte)">
                        mdi-alert-circle-outline
                    </v-icon>
                </template>

                <template v-slot:item.cte_data_criacao="{ item }">
                    {{ formataData(item.cte_data_criacao) }}
                </template>

                <template v-slot:item.telefone_celular="{ item }">
                    <the-mask
                        mask="(##) # ####-####"
                        :value="item.telefone_celular"
                        type="text"
                        :masked="false"
                        disabled
                    ></the-mask>
                </template>

                <template v-slot:footer.prepend>
                    <v-row>
                        <v-col cols="12" sm="4" class="text-left">
                            <v-icon size="70" color="red lighten-3"> mdi-circle-small </v-icon>
                            Maior ou igual a 3
                        </v-col>

                        <v-col cols="12" sm="4" class="text-left">
                            <v-icon size="70" color="yellow lighten-3"> mdi-circle-small </v-icon>
                            Maior ou igual a 2
                        </v-col>
                    </v-row>
                </template>
            </TabelaVuetify>

            <div class="text-center">
                <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                    <span v-html="notificacao.mensagem"></span>

                    <template v-slot:action="{ attrs }">
                        <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false"> Fechar </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </v-card>

        <NotaFiscalOcorrencia ref="NotaFiscalOcorrencia" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { TheMask } from 'vue-the-mask';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { ExportaArrayObjectsParaExcel } from '@/helpers/Funcoes.js';

import TituloModulo from '@/components/Default/TituloModulo.vue';
import TabelaVuetify from '@/components/Default/TabelaVuetify.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import NotaFiscalOcorrencia from '@/Modulo/AlocacaoCarga/Componente/NotaFiscalOcorrencia.vue';

import TabelaAcao from '@/Modulo/Compartilhado/Tabela/Dominio/Servico/Acao';

export default {
    name: 'PainelEntrega',
    mixins: [TabelaAcao],
    components: {
        TituloModulo,
        TabelaVuetify,
        TheMask,
        BarraNavegacaoBotaoV3,
        NotaFiscalOcorrencia,
    },
    mounted() {
        this.montaNavegacao({
            botoes: [
                {
                    id_permissao: 1,
                    nome: 'Exportar Excel',
                    acao: 'exportaTabelaParaExcel',
                    parametros: { arquivoNome: 'painel-entrega' },
                    icone: 'mdi-file-excel-outline',
                    cor: 'green darken-5',
                    form: ['TODOS'],
                },
            ],
            permissoes: [
                {
                    id_permissao: 1,
                    nome: 'excel',
                    permitido: 1,
                },
            ],
        });

        window.Echo.channel('alocacao-carga-painel-entrega').listen('.AlocacaoCargaPainelEntrega', (e) => {
            this.preencheDados(e);
        });
    },
    computed: {
        ...mapGetters({
            broadcastConectado: 'broadcast/conectado',
            botoesComPermissao: 'botoesComPermissao',
        }),
    },
    watch: {},
    methods: {
        ...mapActions(['montaNavegacao']),
        async buscaPainelEntrega() {
            const query = this.$refs.tabela.queryFiltros();

            await ApiService({
                method: 'GET',
                url: `${API_URL.erp.alocacaocarga.painelEntrega}/?${query}`,
                params: {},
            })
                .then((resposta) => {
                    this.preencheDados(resposta.data.data);
                })
                .catch((erro) => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true,
                    };
                });
        },
        preencheDados(dados) {
            if (dados.painelEntrega.length == 0 || dados.painelEntrega.atributos.length == 0) {
                this.datatable.itens = [];

                return;
            }

            this.datatable.itens = dados.painelEntrega.atributos.itens;
            this.datatable.totalRegistros = dados.painelEntrega.atributos.total;
            this.datatable.ultimaPagina = dados.painelEntrega.atributos.ultima_pagina;
        },
        regraPintaLinha(item) {
            const diferenca = moment().diff(moment(item.cte_data_criacao), 'days');

            if (diferenca >= 3) {
                return 'red lighten-4';
            }

            if (moment().diff(moment(item.cte_data_criacao), 'days') >= 2) {
                return 'yellow lighten-4';
            }

            return 'white';
        },
        formataData(data) {
            return moment(data).format('DD/MM/YYYY');
        },
        modalNotaFiscalOcorrencia(numero_cte) {
            this.$refs['NotaFiscalOcorrencia'].numero_cte = numero_cte;
            this.$refs['NotaFiscalOcorrencia'].abreModal = true;
        },
        exportaTabelaParaExcel() {
            try {
                const nomeExcel = `painel-${moment().format('DD-MM-YYYY_HH-MM-SS')}`;

                const cabecalho = _.cloneDeep(this.datatable.cabecalho);

                cabecalho.push(
                    {
                        text: 'OBSERVAÇÃO',
                        value: 'observacao',
                    },
                    {
                        text: 'DATA RETIRADA',
                        value: 'data_retirada',
                    },
                    {
                        text: 'PARTICULARIDADE CLIENTE',
                        value: 'particularidade_cliente',
                    }
                );

                ExportaArrayObjectsParaExcel(this.datatable.itens, cabecalho, nomeExcel);
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success',
            },
            datatable: {
                itens: [],
                totalRegistros: 0,
                ultima_pagina: 0,
                pagina_atual: 1,
                por_pagina: 500,
                ordenarPor: 'cte_data_criacao',
                ordenarDirecao: true,
                cabecalho: [
                    {
                        text: '',
                        value: 'acao',
                        width: '50',
                        showFilter: false,
                    },
                    {
                        text: 'PLACA VEÍCULO',
                        value: 'veiculo_placa_cavalo_entrega',
                        width: '100',
                        showFilter: false,
                    },
                    {
                        text: 'MOTORISTA',
                        value: 'nome_motorista',
                        width: '200',
                        showFilter: false,
                    },
                    {
                        text: 'ITINERÁRIO ORIGEM',
                        value: 'itinerario_origem',
                        width: '100',
                        showFilter: false,
                    },
                    {
                        text: 'ITINERÁRIO DESTINO',
                        value: 'itinerario_destino',
                        width: '100',
                        showFilter: false,
                    },
                    {
                        text: 'CT-E',
                        value: 'numero_cte',
                        width: '100',
                        showFilter: false,
                    },
                    {
                        text: 'MANIFESTO',
                        value: 'rodopar_id_manifesto',
                        width: '150',
                        showFilter: false,
                    },
                    {
                        text: 'CLIENTE',
                        value: 'cliente',
                        width: '30%',
                        showFilter: false,
                    },
                    {
                        text: 'TIPO OPERAÇÃO',
                        value: 'tipo_carga',
                        width: '15%',
                        showFilter: false,
                    },
                    {
                        text: 'CTE DATA CRIAÇÃO',
                        value: 'cte_data_criacao',
                        width: '155',
                        showFilter: false,
                    },
                    {
                        text: 'NÚMERO CONTAINER',
                        value: 'container_numero',
                        width: '155',
                        showFilter: false,
                    },
                    {
                        text: 'NÚMERO DI',
                        value: 'numero_di',
                        width: '155',
                        showFilter: false,
                    },
                    {
                        text: 'NÚMERO DTA',
                        value: 'numero_dta',
                        width: '155',
                        showFilter: false,
                    },
                    {
                        text: 'REF. CLIENTE',
                        value: 'referencia_cliente',
                        width: '155',
                        showFilter: false,
                    },
                    {
                        text: 'QUANTIDADE',
                        value: 'quantidade',
                        width: '155',
                        showFilter: false,
                    },
                    {
                        text: 'PESO KG',
                        value: 'cte_peso_kg',
                        width: '155',
                        showFilter: false,
                    },
                    {
                        text: 'Nº CELULAR',
                        value: 'telefone_celular',
                        width: '100',
                        showFilter: false,
                    },
                    {
                        text: 'UNIDADE',
                        value: 'unidade_solicitante',
                        width: '100',
                        showFilter: false,
                    },
                ],
            },
        };
    },
};
</script>
