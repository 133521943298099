<template>
    <v-form ref="form">
        <v-card-text>
            <v-row>
                <v-col cols="10" sm="4">
                    <v-text-field
                        dense
                        v-model="endereco.id_parceiro_endereco"
                        label="ID Endereco"
                        outlined
                        data-vv-delay="1000"
                        disabled
                    />
                </v-col>

                <v-col cols="10" sm="4">
                    <v-autocomplete
                        dense
                        v-model="endereco.id_estado"
                        :items="itemsEstado"
                        label="Estado *"
                        outlined
                        autocomplete="new"
                        item-text="label"
                        item-value="value"
                        clearable
                        :rules="[v => !!v || 'Estado é obrigatório']"
                    />
                </v-col>
                <v-col cols="10" sm="4">
                    <v-autocomplete
                        dense
                        v-model="endereco.id_cidade"
                        :items="itemsCidade"
                        label="Cidade *"
                        outlined
                        autocomplete="new"
                        item-text="label"
                        item-value="value"
                        clearable
                        :rules="[v => !!v || 'Cidade é obrigatório']"
                    />
                </v-col>
                <v-col cols="12" sm="8">
                    <v-text-field
                        dense
                        v-model="endereco.logradouro"
                        label="Logradouro *"
                        outlined
                        data-vv-delay="1000"
                        counter="100"
                        :rules="regraLogradouro"
                        clearable
                    />
                </v-col>
                <v-col cols="12" sm="4">
                    <v-text-field
                        dense
                        v-model="endereco.numero"
                        label="Número *"
                        outlined
                        data-vv-delay="1000"
                        counter="9"
                        v-maska="'#########'"
                        :rules="regraNumero"
                        clearable
                    />
                </v-col>
                <v-col cols="12" sm="8">
                    <v-text-field
                        dense
                        v-model="endereco.bairro"
                        label="Bairro *"
                        outlined
                        data-vv-delay="1000"
                        :rules="regraBairro"
                        clearable
                        counter="50"
                    />
                </v-col>
                <v-col cols="12" sm="4">
                    <v-text-field
                        dense
                        v-model="endereco.cep"
                        label="CEP *"
                        outlined
                        data-vv-delay="1000"
                        v-maska="cepMask"
                        :rules="regraCep"
                        clearable
                        counter="9"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        dense
                        v-model="endereco.complemento"
                        label="Complemento"
                        outlined
                        data-vv-delay="1000"
                        :rules="regraComplemento"
                        clearable
                        counter="100"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        dense
                        v-model="endereco.referencia"
                        label="Referência"
                        outlined
                        data-vv-delay="1000"
                        :rules="regraReferencia"
                        clearable
                        counter="100"
                    />
                </v-col>
                <v-col cols="12" sm="12">
                    <v-textarea
                        outlined
                        v-model="endereco.observacao"
                        label="Observacao"
                        data-vv-delay="1000"
                        :rules="regraObservacao"
                        clearable
                        counter="500"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-form>
</template>

<script>
import ApiService from '@/services/ApiService.js';
import { mask } from 'maska';

export default {
    name: 'EnderecoForm',

    mounted() {
        this.getCidades();
    },

    props: {
        tipoEndereco: Number,
        itemsEstado: {
            type: Array,
            default() {
                return [];
            }
        },
        enderecoData: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    watch: {
        enderecoData: {
            handler: function(dadosAtual) {
                this.preencheDados(dadosAtual);
            }
        },
        'endereco.id_estado': function() {
            this.getCidades();
        }
    },
    created() {
        this.preencheDados(this.enderecoData);
    },
    data() {
        return {
            cepMask: '#####-###',

            regraLogradouro: [
                v => !!v || 'Logradouro é obrigatório',
                v => (v && v.length <= 100) || 'Logradouro deve ter até 100 caracteres'
            ],

            regraNumero: [v => !!v || 'Numero é obrigatório', v => (v && v.length <= 9) || 'Numero deve ter até 9 caracteres'],

            regraBairro: [
                v => !!v || 'Bairro é obrigatório',
                v => (v && v.length <= 50) || 'Bairro deve ter até 50 caracteres'
            ],

            regraCep: [v => !!v || 'CEP é obrigatório', v => (v && v.length <= 100) || 'CEP deve ter até 9 caracteres'],

            regraComplemento: [v => _.isEmpty(v) || v.length <= 100 || 'Complemento deve ter até 100 caracteres'],

            regraReferencia: [v => _.isEmpty(v) || v.length <= 100 || 'Referência deve ter até 100 caracteres'],

            regraObservacao: [v => _.isEmpty(v) || v.length <= 500 || 'Observação deve ter até 500 caracteres'],

            itemsCidade: [],

            endereco: {
                id_parceiro_endereco: '',
                id_pais: 34,
                id_estado: 26,
                id_cidade: null,
                logradouro: '',
                tipo_endereco: this.tipoEndereco,
                numero: '',
                bairro: '',
                cep: '',
                complemento: '',
                referencia: '',
                observacao: ''
            }
        };
    },

    methods: {
        limpaDados() {
            this.endereco = {
                id_parceiro_endereco: '',
                id_pais: 34,
                id_estado: 26,
                id_cidade: null,
                logradouro: '',
                tipo_endereco: this.tipoEndereco,
                numero: '',
                bairro: '',
                cep: '',
                complemento: '',
                referencia: '',
                observacao: ''
            };
        },

        preparaDados() {
            return { ...this.endereco };
        },

        preencheDados(endereco) {
            if (_.isEmpty(endereco)) {
                return;
            }

            this.endereco = { ...endereco };

            this.endereco.numero = String(endereco.numero);
            this.endereco.cep = endereco.cep ? mask(endereco.cep, this.cepMask) : null;
        },

        async getCidades() {
            if (!this.endereco.id_estado) {
                this.itemsCidade = [];
                return;
            }

            await ApiService({
                method: 'get',
                url: `V1/cidade/lista-tipo-combo?id_estado=${this.endereco.id_estado}`,
                data: {}
            })
                .then(resposta => {
                    this.itemsCidade = resposta.data.data.atributos.map(item => {
                        return {
                            label: item.nome,
                            value: item.id_cidade
                        };
                    });
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        }
    }
};
</script>

<style scoped></style>
