<template>
    <v-badge color="white" transition="slide-x-transition" style="width: 100%;" :overlap="overlap">
        <template v-if="ajudaMensagem" v-slot:badge>
            <v-tooltip v-if="ajudaMensagem" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="green" v-bind="attrs" v-on="on" small>
                        mdi-help
                    </v-icon>
                </template>
                <span>{{ ajudaMensagem }}</span>
            </v-tooltip>
        </template>

        <v-text-field
            :value="value"
            @input="retornoValor($event)"
            :rules="regra"
            :counter="contador"
            :label="rotulo"
            :disabled="desativado"
            :required="obrigatorio"
            v-maska="campoFormatacao"
            :masked="!!campoFormatacao"
            :type="tipo"
            :maxlength="contador"
            :append-icon="iconeAppend"
            :prepend-icon="iconePrepend"
            @click:append="clickAppend"
            @keyup.enter="$emit('onEnter', $event)"
            data-vv-delay="1000"
            :outlined="estilo('outlined')"
            :background-color="backgroundColor"
            :clearable="clearable"
            v-bind="$attrs"
            dense
        >
        </v-text-field>
    </v-badge>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
    directives: { mask },
    inheritAttrs: false,
    props: {
        value: {
            default: () => ''
        },
        regra: {
            type: Array,
            default: () => []
        },
        contador: {
            type: Number,
            default: () => 0
        },
        rotulo: {
            type: String,
            default: () => ''
        },
        desativado: {
            type: Boolean,
            default: () => false
        },
        obrigatorio: {
            type: Boolean,
            default: () => false
        },
        campoFormatacao: {
            type: String,
            default: () => ''
        },
        tipo: {
            type: String,
            default: () => 'text'
        },
        iconeAppend: {
            type: String,
            default: () => ''
        },
        iconePrepend: {
            type: String,
            default: () => ''
        },
        estiloCampo: {
            type: String,
            default: () => 'outlined'
        },
        clickAppend: {
            type: Function,
            default: () => null
        },
        backgroundColor: {
            type: String,
            default: () => null
        },
        ajudaMensagem: {
            type: String,
            default: () => null
        },
        ajudaMensagem: {
            type: String,
            default: () => null
        },
        clearable: {
            type: Boolean,
            default: () => true
        }
    },
    async created() {},
    computed: {
        overlap() {
            if (this.ajudaMensagem) {
                return true;
            }

            return false;
        }
    },
    watch: {},
    mounted() {},
    methods: {
        estilo(estiloDefinido) {
            if (this.estiloCampo == estiloDefinido) {
                return true;
            }

            return false;
        },
        retornoValor(valor) {
            if (this.tipo == 'number') {
                valor = parseFloat(valor);
            }

            this.$emit('input', valor);
        }
    },
    data() {
        return {
            campoMascara: '',
            nomask: {
                mask: '*'.repeat(1000),
                tokens: {
                    '*': { pattern: /./ }
                }
            },
            hover: false
        };
    }
};
</script>

<style scoped></style>
