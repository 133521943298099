<template>
    <v-dialog v-model="dialog" persistent max-width="800px">
        <div class="text-center">
            <v-snackbar
                v-model="mostraNotificacao"
                :multi-line="true"
                :color="corSnack"
                outlined
            >
                {{ mensagemSnack }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="mostraNotificacao = false"
                    >
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>

        <v-card>
            <v-toolbar color="orange" dark>
                <v-toolbar-title
                    >Editar Particularidade Cliente</v-toolbar-title
                >

                <v-spacer></v-spacer>
                <v-btn icon @click="fechar">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="pt-5">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <TextAreaV3
                                v-model="campo"
                                :contador="500"
                                rotulo="Particularidade Cliente"
                                :obriatorio="true"
                                :regra="regraParticularidadeCliente"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="fechar">
                    Fechar
                </v-btn>
                <v-btn color="blue darken-1" text @click="salvar">
                    Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ApiService from '@/services/ApiService.js';

import TextAreaV3 from '@/components/Form/TextAreaV3.vue';

export default {
    name: 'EditaParticularidadeClienteModal',
    components: {
        TextAreaV3
    },
    props: {
        campoSelecionado: {
            type: String,
            default: '',
        },
        programacaoCarga: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    watch: {
        'programacaoCarga': function (data) {
            if (this.campoSelecionado != 'particularidade_cliente') {
                return;
            }

            const campoDados = data[this.campoSelecionado];

            if (!_.isEmpty(campoDados)) {
                this.campo = campoDados;

                return;
            }

            this.campo = '';
        },
    },
    computed: {
        mensagemSnack() {
            if (_.isEmpty(this.mensagemNoticicacao)) {
                return;
            }

            this.mostraNotificacao = true;

            setTimeout(() => {
                this.limpaSnack();
            }, 2000);

            return this.mensagemNoticicacao;
        }
    },
    data() {
        return {
            dialog: false,
            campo: '',
            mostraNotificacao: false,
            mensagemNoticicacao: '',
            corSnack: 'success',
            regraParticularidadeCliente: [
                (v) =>
                    v.length <= 500 ||
                    'Não pode ser maior que 500 caracteres'
            ]
        };
    },
    methods: {
        limpaSnack() {
            this.mostraNotificacao = false;
            this.mensagemNoticicacao = '';
            this.corSnack = 'success';
        },
        async salvar() {
            const respObs = await this.salvaDados(
                this.programacaoCarga.id_programacao_carga,
                this.campo
            );

            if (!respObs) {
                return;
            }

            this.$emit('salvar', {
                campo: this.campo,
                programacaoCarga: this.programacaoCarga,
                nomeCampo: this.campoSelecionado,
            });

            this.fechar();
        },
        fechar() {
            this.limpaSnack();

            this.dialog = false;
            this.campo = '';
            this.$emit('fechouModal');
        },
        async salvaDados(id_programacao_carga, particularidade_cliente) {
            return await ApiService({
                method: 'post',
                url: `V1/alocacao-carga/particularidadeCliente`,
                data: {
                    dados: {
                        id_programacao_carga: id_programacao_carga,
                        particularidade_cliente: particularidade_cliente,
                    },
                },
            })
                .then((resposta) => {
                    return true;
                })
                .catch((erro) => {
                    this.corSnack            = 'red';
                    this.mensagemNoticicacao = erro.message;

                    return false;
                });
        }
    },
};
</script>
