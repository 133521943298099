<template>
    <dialog-editar-permissoes ref="componenteFilho">
        <v-icon right small @click="disparEventoAbrirOuFecharModal(), dispararEventoUsuarioSelecionadoParaEdicao()">
            mdi-pencil
        </v-icon>
    </dialog-editar-permissoes>
</template>

<script>
import DialogEditarPermissoes from '../Scaffold/DialogEditarPermissoes.vue';

export default {
    name: 'UsuarioPerfilLapisEditar',
    components: {
        DialogEditarPermissoes
    },
    props: {
        usuario: {
            type: Object,
            required: true
        },
        perfisExistentes : {
            required: true
        }
    },
    methods: {

        dispararEventoUsuarioSelecionadoParaEdicao() {
            // prepararemos os dados no componente filho
            let usuario = {
                id_usuario: this.usuario.id_usuario,
                nome: this.usuario.nome_completo
            }

            this.$refs.componenteFilho.usuario = usuario
            this.$refs.componenteFilho.perfisExistentes = this.perfisExistentes
            this.$refs.componenteFilho.buscaPerfisDoUsuario(this.usuario.id_usuario)
        },

        disparEventoAbrirOuFecharModal() {
            this.$refs.componenteFilho.eventoAbrirOuFecharModal();
        }

    }
}
</script>