<template>
    <div>
        <TituloModulo />

        <AlertaMensagem dense style="width: 100%" />

        <CarregandoLogoEmpresa :mensagem="mensagemCarregando" />

        <BarraNavegacaoBotaoV3
            v-if="!carregando.mostraCarregando && permissao"
            :botoes="botoesComPermissao(avaliacaoSituacao)"
        />

        <v-row v-if="!carregando.mostraCarregando && permissao">
            <v-col cols="12" class="text-right">
                <TabelaVuetify
                    v-model="datatable.itensSelecionados"
                    :cabecalho="datatable.cabecalho"
                    :itens="datatable.itens"
                    :total_registros="datatable.totalRegistros"
                    :por_pagina="datatable.por_pagina"
                    :ultima_pagina="datatable.ultima_pagina"
                    :carregando="datatable.carregando"
                    :chave_primaria="datatable.chave_primaria"
                    :ordenar_por="datatable.ordenarPor"
                    :ordenar_direcao="datatable.ordenarDirecao"
                    :multipla_selecao="false"
                    @opcoes="buscaAvaliacaoDesempenhoItens"
                    ref="tabela"
                >
                    <template v-slot:item.acao="{ item }">
                        <v-icon color="light-green darken-2" class="mr-2" @click="editaItem(item)" small>
                            mdi-pencil
                        </v-icon>
                        <v-icon color="red lighten-1" @click="apagaItem(item)" small>
                            mdi-delete
                        </v-icon>
                    </template>

                    <template v-slot:item.situacao="{ item }">
                        <v-chip small class="ma-2" :color="selecionaCor(item.id_situacao)" text-color="white">
                            <v-icon small left>
                                mdi-alert-circle-outline
                            </v-icon>
                            {{ item.situacao }}
                        </v-chip>
                    </template>
                </TabelaVuetify>
            </v-col>
        </v-row>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { Alerta } from '@/helpers/Funcoes.js';

import AvaliacaoEnum from '@/Enum/AvaliacaoDesempenho/AvaliacaoEnum.js';
import SituacaoForm from '@/Enum/AvaliacaoDesempenho/SituacaoForm.js';

import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import TabelaVuetify from '@/components/Default/TabelaVuetify.vue';

export default {
    name: 'AvaliacaoDesempenho',
    components: {
        AlertaMensagem,
        CarregandoLogoEmpresa,
        TituloModulo,
        BarraNavegacaoBotaoV3,
        TabelaVuetify
    },
    async mounted() {
        await this.dadosPaginaInicial();
    },
    computed: {
        ...mapGetters(['botoesComPermissao']),
        ...mapState(['carregando'])
    },
    watch: {},
    methods: {
        ...mapActions(['montaNavegacao', 'mostraCarregando']),
        async buscaPermissoes() {
            try {
                await ApiService({
                    method: 'get',
                    url: `${API_URL.erp.avaliacaodesempenho.permissao}`,
                    data: {},
                    mostraCarregando: false
                })
                    .then(async resposta => {
                        const permissoes = resposta.data.data.atributos;

                        this.avaliacaoSituacao = SituacaoForm.GRID;

                        await this.montaNavegacao({ botoes: this.botoes, permissoes });
                    })
                    .catch(error => {
                        throw new Error(error);
                    });

                return true;
            } catch (error) {
                Alerta([error.message], 'error');

                return false;
            }
        },
        async dadosPaginaInicial() {
            try {
                this.mostraCarregando(true);

                await ApiService({
                    method: 'get',
                    url: `${API_URL.erp.avaliacaodesempenho.dadosPaginaInicial}`,
                    data: {},
                    mostraCarregando: false
                })
                    .then(async resposta => {
                        await this.buscaPermissoes();
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });

                this.permissao = true;
            } catch (erro) {
                Alerta([erro.message], 'error');
            } finally {
                this.mostraCarregando(false);
            }
        },
        async inicia() {
            try {
                const id_avaliacao_desempenho = this.datatable.itensSelecionados[0]
                    ? this.datatable.itensSelecionados[0].id_avaliacao_desempenho
                    : null;

                if (!id_avaliacao_desempenho) {
                    Alerta(['Selecione uma avaliação no grid para iniciar.'], 'error');

                    return;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open(
                        'Iniciar',
                        'Deseja iniciar Avaliação de Desempenho ? \r\n Será enviado e-mail para o Colaborador e Gestor para preenchimento.',
                        {
                            cor: 'green'
                        }
                    )
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        this.mensagemCarregando = 'Aguarde, validando os dados para iniciar...';

                        this.mostraCarregando(true);

                        let urlConfig = {
                            method: 'PUT',
                            url: `${API_URL.erp.avaliacaodesempenho.inicia}/${id_avaliacao_desempenho}`,
                            data: {},
                            mostraCarregando: false
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                Alerta([`Avaliação de Desempenho ${id_avaliacao_desempenho} iniciada.`], 'success');

                                this.buscaAvaliacaoDesempenhoItens();
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });

                        this.datatable.itensSelecionados = [];
                        this.mostraCarregando(false);
                    });
            } catch (erro) {
                this.mostraCarregando(false);

                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        async apagaItem() {
            try {
                const id_avaliacao_desempenho = this.datatable.itensSelecionados[0]
                    ? this.datatable.itensSelecionados[0].id_avaliacao_desempenho
                    : null;

                if (!id_avaliacao_desempenho) {
                    Alerta(['Selecione uma avaliação no grid para apagar.'], 'error');

                    return;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Apagar', `Avaliação de Desempenho ${id_avaliacao_desempenho} ?`, {
                        cor: 'red'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        this.mensagemCarregando = 'Aguarde, apagando...';

                        this.mostraCarregando(true);

                        let urlConfig = {
                            method: 'DELETE',
                            url: `${API_URL.erp.avaliacaodesempenho.apaga}/${id_avaliacao_desempenho}`,
                            data: {},
                            mostraCarregando: false
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                Alerta([`Avaliação de Desempenho ${id_avaliacao_desempenho} apagada.`], 'success');

                                this.buscaAvaliacaoDesempenhoItens();
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });

                        this.datatable.itensSelecionados = [];
                        this.mostraCarregando(false);
                    });
            } catch (erro) {
                this.mostraCarregando(false);

                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        async buscaAvaliacaoDesempenhoItens() {
            this.datatable.carregando = true;

            const query = this.$refs.tabela.queryFiltros();

            await ApiService({
                method: 'get',
                url: `${API_URL.erp.avaliacaodesempenho.datatable}/?${query}`,
                data: {},
                mostraCarregando: false
            })
                .then(resposta => {
                    this.mostraCarregando(false);

                    this.datatable.itens = resposta.data.conteudo.datatable.itens;

                    this.datatable.totalRegistros = resposta.data.conteudo.datatable.total;
                    this.datatable.ultima_pagina = resposta.data.conteudo.datatable.ultima_pagina;
                    this.datatable.carregando = false;
                })
                .catch(erro => {
                    this.datatable.carregando = false;

                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        },
        limpaFiltro() {
            this.$refs.tabela.limpaFiltros();
        },
        novoItem(item) {
            this.mostraCarregando(true);
            this.$router.push({
                name: 'avaliacao-desempenho-form',
                params: {}
            });
        },
        editaItem(item) {
            this.mostraCarregando(true);

            this.$router.push({
                name: 'avaliacao-desempenho-form',
                params: { id_avaliacao_desempenho: item.id_avaliacao_desempenho, permissoes: this.permissoes }
            });
        },
        selecionaCor(situacao) {
            if (AvaliacaoEnum.ABERTO == situacao) {
                return 'grey darken-1';
            }

            if (AvaliacaoEnum.FINALIZADO == situacao) {
                return 'green darken-1';
            }

            if (AvaliacaoEnum.INICIADA == situacao) {
                return 'orange darken-1';
            }

            return 'yellow darken-1';
        }
    },
    data() {
        return {
            permissao: false,
            permissoes: [],
            botoes: [
                {
                    id_permissao: 2,
                    nome: 'Novo',
                    acao: 'novoItem',
                    icone: 'mdi-newspaper-variant-multiple',
                    cor: 'green lighten-1',
                    form: [SituacaoForm.GRID]
                },
                {
                    id_permissao: 22,
                    nome: 'Iniciar',
                    acao: 'inicia',
                    icone: 'mdi-fast-forward-outline',
                    cor: 'blue lighten-1',
                    form: [SituacaoForm.GRID]
                },
                {
                    id_permissao: 4,
                    nome: 'Apaga',
                    acao: 'apagaItem',
                    icone: 'mdi-trash-can-outline',
                    cor: 'red lighten-1',
                    form: [SituacaoForm.GRID]
                },
                {
                    id_permissao: 6,
                    nome: '',
                    acao: 'buscaAvaliacaoDesempenhoItens',
                    icone: 'mdi-reload',
                    cor: 'grey darken-5',
                    form: [SituacaoForm.GRID]
                },
                {
                    id_permissao: 6,
                    nome: '',
                    acao: 'limpaFiltro',
                    icone: 'mdi-filter-multiple',
                    cor: 'red darken-5',
                    form: [SituacaoForm.GRID]
                }
            ],
            avaliacaoSituacaoAberto: AvaliacaoEnum.ABERTO,
            avaliacaoSituacao: null,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            mensagemCarregando: 'Buscando, aguarde...',
            datatable: {
                carregando: false,
                mensagemCarregando: 'Buscando, aguarde...',
                chave_primaria: 'id_avaliacao_desempenho',
                itens: [],
                totalRegistros: 0,
                ultima_pagina: 0,
                pagina_atual: 1,
                por_pagina: 50,
                ordenarPor: 'id_avaliacao_desempenho',
                ordenarDirecao: true,
                opcoes: {},
                itensSelecionados: [],
                cabecalho: [
                    {
                        text: 'AÇÃO',
                        value: 'acao',
                        align: 'start',
                        width: '100',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'ID',
                        value: 'id_avaliacao_desempenho',
                        width: '100'
                    },
                    {
                        text: 'Nome',
                        value: 'nome',
                        width: '50%'
                    },
                    {
                        text: 'Situação',
                        value: 'situacao',
                        width: '50%',
                        type: 'select',
                        filterData: [
                            {
                                value: 1,
                                label: 'Aberto'
                            },
                            {
                                value: 2,
                                label: 'Iniciar'
                            },
                            {
                                value: 3,
                                label: 'Iniciada'
                            },
                            {
                                value: 4,
                                label: 'Finalizado'
                            }
                        ]
                    },
                    {
                        text: 'Período de',
                        value: 'periodo_de',
                        width: '150'
                    },
                    {
                        text: 'Período até',
                        value: 'periodo_ate',
                        width: '150'
                    }
                ]
            }
        };
    }
};
</script>

<style></style>
