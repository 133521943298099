<template>
    <v-card-text>
        <v-row>
            <v-col cols="2">
                <InputTextV3
                    v-model="criterio.id_avaliacao_desempenho_criterio"
                    :contador="100"
                    rotulo="ID"
                    desativado
                    clearable
                />
            </v-col>

            <v-col cols="2">
                <InputTextV3 v-model="criterio.peso" tipo="number" :regra="regrasPeso" rotulo="Peso" :contador="2" />
            </v-col>

            <v-col cols="8">
                <InputTextV3 v-model="criterio.nome" :regra="regrasNome" rotulo="Nome" :contador="80" />
            </v-col>
        </v-row>
    </v-card-text>
</template>

<script>
import { isEmpty } from 'lodash';
import { Maximo, Minimo, Obrigatorio } from '@/helpers/ValidadorCampo.js';

import InputTextV3 from '@/components/Form/InputTextV3.vue';

export default {
    name: 'CriterioForm',
    components: {
        InputTextV3
    },
    props: {
        criterioData: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    watch: {
        criterioData: {
            handler: function(dadosAtual, dadosAntigo) {
                this.preencheDados(dadosAtual);
            }
        }
    },
    created() {
        if (!isEmpty(this.criterioData)) {
            this.preencheDados(this.criterioData);
        }
    },
    data() {
        return {
            criterio: {
                id_avaliacao_desempenho_criterio: null,
                orientacao: null
            },
            regrasPeso: [Obrigatorio(), Minimo(1), Maximo(2)],
            regrasNome: [Obrigatorio(), Minimo(5), Maximo(200)]
        };
    },
    methods: {
        preparaDados() {
            const criterio = { ...this.criterio };

            return criterio;
        },
        preencheDados(criterio) {
            this.criterio = { ...criterio };
        }
    }
};
</script>
