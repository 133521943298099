<template>
    <v-card outlined elevation="2">
        <CarregandoOverlay :overlay="overlay" mensagem="Preparando relatório, aguarde..." />

        <v-card-title class="">
            <span class="text-h6"> OTD {{ ano }} e TOP Grupo Clientes {{ quantidadeTopGrupoClienteSelecionada }} </span>

            <v-spacer></v-spacer>

            <span class="pr-2">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="blue darken-1" dark v-bind="attrs" v-on="on" @click="downloadImagem()">
                            mdi-cloud-download
                        </v-icon>
                    </template>
                    <span>Baixar imagem</span>
                </v-tooltip>
            </span>

            <span class="pr-2">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="blue-grey darken-1" dark v-bind="attrs" v-on="on" @click="carregaDashboard()">
                            mdi-refresh
                        </v-icon>
                    </template>
                    <span>Recarregar</span>
                </v-tooltip>
            </span>

            <span class="pr-2">
                <v-tooltip max-width="200" left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="warning darken-1" dark v-bind="attrs" v-on="on" @click="ajuda = true">
                            mdi-account-question
                        </v-icon>
                    </template>
                    <span>
                        Ajuda com a composição do gráfico ? Clique para saber mais.
                    </span>
                </v-tooltip>
            </span>
        </v-card-title>

        <v-card-text>
            <v-chart
                :option="opcoes"
                :init-options="initOptions"
                :loading="carregando"
                :loadingOptions="carregandoOpcoes"
                :style="tamanhoPixel"
                ref="Grafico"
                autoresize
            />
        </v-card-text>

        <div class="text-center">
            <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                {{ notificacao.mensagem }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>

        <v-dialog v-model="ajuda" max-width="800">
            <v-card>
                <v-card-title class="text-h5"> OTD por ano {{ ano }} e unidade </v-card-title>

                <v-card-text>
                    Adicionar texto de ajuda
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="blue darken-1" text @click="ajuda = false">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import VChart from 'vue-echarts';

import CarregandoOverlay from '@/components/Default/CarregandoOverlay.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';

export default {
    name: 'OrderToDeliveryAnoTopGrupoClienteTotal',
    components: {
        VChart,
        CarregandoOverlay,
        InputSelectV3
    },
    async mounted() {
        this.opcoes.series[0].emphasis = this.emphasis;
    },
    watch: {
        dadosGraficoTopGrupoCliente: {
            handler: function() {
                this.adicionaDadosAoGrafico();
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        ...mapGetters({
            carregando: 'orderToDelivery/carregando',
            carregandoOpcoes: 'orderToDelivery/carregandoOpcoes',
            ano: 'orderToDelivery/ano',
            anosPesquisa: 'orderToDelivery/anosPesquisa',
            quantidadeTopGrupoClienteSelecionada: 'orderToDelivery/quantidadeTopGrupoClienteSelecionada',
            quantidadeTopGrupoClienteLista: 'orderToDelivery/quantidadeTopGrupoClienteLista',
            dadosGraficoTopGrupoCliente: 'orderToDelivery/dadosGraficoTopGrupoCliente',
            tamanhoDashboard: 'dashboard/tamanhoDashboard'
        }),
        ...mapState('orderToDelivery', {
            emphasis(state, getters) {
                return getters['emphasis'];
            }
        }),
        tamanhoPixel() {
            return this.tamanhoDashboard({ id_dashboard: this.id_dashboard });
        }
    },
    methods: {
        ...mapActions({
            buscaDashboard: 'orderToDelivery/buscaDashboard',
            baixarImagem: 'orderToDelivery/baixarImagem'
        }),
        carregaDashboard() {
            this.buscaDashboard({ ano: this.ano })
                .then(async () => {})
                .catch(erro => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        },
        downloadImagem() {
            this.baixarImagem(this.$refs['Grafico']);
        },
        adicionaDadosAoGrafico() {
            if (!this.dadosGraficoTopGrupoCliente?.grupo) {
                return;
            }

            let totalProcessos = [];
            let categoria = [];
            var graficoDados = this.dadosGraficoTopGrupoCliente;

            Object.keys(graficoDados?.grupo).forEach(function(grupo, key) {
                Object.keys(graficoDados.grupo[grupo]).forEach(function(item, itemKey) {
                    const grupoCliente =
                        graficoDados.grupo[grupo][item] !== null
                            ? graficoDados.grupo[grupo]['descricao']?.substring(0, 15)
                            : 'NÃO DEFINIDO';

                    if (item == 'descricao') {
                        categoria.push(grupoCliente);
                    }

                    if (item == 'total_processos') {
                        totalProcessos.push({
                            value: graficoDados.grupo[grupo][item],
                            name: grupoCliente
                        });
                    }
                });
            });

            this.opcoes.series[0].data = totalProcessos;
            this.opcoes.legend.data = categoria;
        }
    },
    data() {
        return {
            id_dashboard: 6,
            ajuda: false,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            overlay: false,
            initOptions: {
                renderer: 'png'
            },
            opcoes: {
                tooltip: {},
                legend: {
                    itemGap: 10,
                    left: 'left',
                    data: []
                },
                toolbox: {
                    feature: {
                        dataView: { show: false, readOnly: false },
                        restore: { show: false },
                        saveAsImage: { show: false }
                    }
                },
                series: [
                    {
                        type: 'pie',
                        data: [{ value: 0, name: '' }],
                        bottom: '5%',
                        roseType: 'area',
                        radius: ['5%', '50%'],
                        center: ['50%', '80%'],
                        emphasis: this.emphasis,
                        itemStyle: {
                            borderRadius: 8
                        },
                        label: {
                            formatter: function(value) {
                                return `${value.data.name} \r\n\r\n ${value.value}`;
                            }
                        }
                    }
                ]
            }
        };
    }
};
</script>
