<template>
    <v-container fluid>
        <v-overlay :absolute="true" opacity="0.8" :value="carregando">
            <CarregandoLogoEmpresa :carregando="carregando" :mensagem="mensagemCarregando" />
        </v-overlay>

        <TituloModulo />

        <v-row>
            <v-col cols="4">
                <card-info
                    iconeBoxCor="blue"
                    icone="mdi-file-sign"
                    titulo="Processos"
                    :value="datatable.itens.length"
                    :carregando="false"
                    rodape-texto="Quantidade de processos"
                />
            </v-col>

            <v-col cols="4">
                <card-info
                    iconeBoxCor="warning"
                    icone="mdi-currency-brl"
                    titulo="Total Geral"
                    :value="totais.total"
                    :carregando="false"
                    rodape-texto="Valor total de todos processos"
                />
            </v-col>

            <v-col cols="4">
                <card-info
                    iconeBoxCor="green"
                    icone="mdi-currency-brl"
                    titulo="Total Selecionado"
                    :value="totais.total_selecionado"
                    :carregando="false"
                    rodape-texto="Valor total dos processos selecionados"
                />
            </v-col>
        </v-row>

        <v-card outlined elevation="2" class="mb-4 pl-2 mt-5">
            <v-card-title>
                FILTROS

                <v-spacer></v-spacer>

                <TempoExecucao
                    :inicia="inicia_tempo_execucao"
                    :tempo_total="900"
                    :minutos_execucao="tempo_total_proxima_execucao"
                />

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="green" v-bind="attrs" v-on="on">
                            mdi-help
                        </v-icon>
                    </template>
                    <span>
                        Painel é sincronizado de forma automática a cada 15 min. com a API da Automata.
                        <br />
                        <br />

                        A contagem indica o tempo restante para a próxima coleta dos dados.
                    </span>
                </v-tooltip>
            </v-card-title>

            <v-spacer></v-spacer>

            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="2">
                        <DateTimePickerV2
                            v-model="filtros.data_de"
                            :dataInicial="filtros.data_de"
                            label="Data de:"
                            data-vv-delay="1000"
                        />
                    </v-col>

                    <v-col cols="12" md="2">
                        <DateTimePickerV2
                            v-model="filtros.data_ate"
                            :dataInicial="filtros.data_ate"
                            label="Data até:"
                            data-vv-delay="1000"
                        />
                    </v-col>

                    <v-col cols="12" md="2">
                        <InputSelectV3
                            v-model="filtros.liberado"
                            rotulo="Faturamento liberado ?"
                            :itens="opcaoSimNao"
                            itemTexto="label"
                            itemValor="value"
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-chip
                            class="ma-2"
                            text-color="white"
                            :color="corSelecionada == 'green lighten-4' ? 'green darken-1' : 'grey darken-1'"
                            @click="filtraPorCor('green lighten-4')"
                        >
                            <v-icon small left>
                                mdi-thumb-up-outline
                            </v-icon>

                            <strong>Verde</strong>
                        </v-chip>

                        <v-chip
                            class="ma-2"
                            text-color="white"
                            :color="corSelecionada == 'red lighten-4' ? 'red darken-1' : 'grey darken-1'"
                            @click="filtraPorCor('red lighten-4')"
                        >
                            <v-icon small left>
                                mdi-thumb-down-outline
                            </v-icon>

                            <strong>Vermelho</strong>
                        </v-chip>

                        <v-chip
                            class="ma-2"
                            text-color="white"
                            :color="corSelecionada == 'yellow lighten-4' ? 'yellow darken-1' : 'grey darken-1'"
                            @click="filtraPorCor('yellow lighten-4')"
                        >
                            <v-icon small left>
                                mdi-alert-circle-outline
                            </v-icon>

                            <strong>Amarelo</strong>
                        </v-chip>
                    </v-col>

                    <v-col cols="4" md="12" class="mb-1">
                        <v-btn elevation="2" color="warning" @click="buscaPainelFaturamento()">
                            <v-icon dense left>
                                mdi-refresh
                            </v-icon>
                            Buscar
                        </v-btn>

                        <v-btn color="red darken-1" class="ml-2" @click="limpaFiltroTabela">
                            <v-icon dense left> mdi-filter-off </v-icon>
                            Remove Filtros
                        </v-btn>

                        <v-btn @click="exportaTabelaParaExcel()" color="grey darken-2" class="ml-2" dark>
                            <v-icon dense left>
                                mdi-file-excel-outline
                            </v-icon>

                            Gerar Excel
                        </v-btn>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn @click="liberarFaturamento()" color="green darken-2" class="ml-2" dark>
                                    <v-icon dense left>
                                        mdi-thumb-up-outline
                                    </v-icon>
                                    Liberar Faturamento
                                </v-btn>
                            </template>
                            <span>Liberar Faturamento</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>

        <v-card elevation="5">
            <TabelaVuetify
                ref="tabela"
                v-model="datatable.itensSelecionados"
                :cabecalho="datatable.cabecalho"
                :itens="datatable.itens"
                :total_registros="datatable.totalRegistros"
                :por_pagina="datatable.por_pagina"
                :itens_por_pagina="[100, 200, 500, -1]"
                :carregando="datatable.carregando"
                :ordenar_direcao="true"
                :mostra_selecao="true"
                :item_class="regraPintaLinha"
                :single_expand="true"
                :mostra_expand="true"
                :ordenar_por="datatable.ordenarPor"
                chave_primaria="id_automata"
                class="elevation-1"
            >
                <template v-slot:item.etapa="{ item }">
                    {{ etapaDescricao(item.etapa) }}
                </template>

                <template v-slot:item.valor_frete="{ item }">
                    {{ formataMoeda(item.valor_frete) }}
                </template>

                <template v-slot:item.total_prestacao="{ item }">
                    {{ formataMoeda(item.total_prestacao) }}
                </template>

                <template v-slot:item.data_pedido="{ item }">
                    {{ formataData(item.data_pedido) }}
                </template>

                <template v-slot:item.data_auditado="{ item }">
                    {{ formataData(item.data_auditado) }}
                </template>

                <template v-slot:item.data_liberado="{ item }">
                    {{ formataData(item.data_liberado) }}
                </template>

                <template v-slot:item.data_faturamento="{ item }">
                    {{ formataData(item.data_faturamento) }}
                </template>

                <template v-slot:item.data_criacao="{ item }">
                    {{ formataData(item.data_criacao) }}
                </template>

                <template v-slot:item.data_ultima_alteracao="{ item }">
                    {{ formataData(item.data_ultima_alteracao) }}
                </template>

                <template v-slot:item.total_motivos="{ item }">
                    <v-chip
                        class="ma-2"
                        :color="item.total_motivos ? 'red darken-1' : 'green darken-1'"
                        text-color="white"
                        small
                    >
                        <v-icon v-if="item.total_motivos" small left>
                            mdi-alert-circle-outline
                        </v-icon>
                        <v-icon v-else small left>
                            mdi-thumb-up-outline
                        </v-icon>
                        <strong>{{ item.total_motivos }}</strong>
                    </v-chip>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                    <td v-if="item.motivos" :colspan="headers.length">
                        <v-card v-if="item.motivos" elevation="5" class="ma-5" width="1000">
                            <v-card-title>
                                MOTIVO(S)
                            </v-card-title>
                            <v-card-text>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    CAMPO
                                                </th>
                                                <th class="text-left">
                                                    DATA
                                                </th>
                                                <th class="text-left">
                                                    DESCRIÇÃO
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(itemMotivo, index) in item.motivos" :key="`item-motivo-${index}`">
                                                <td>{{ itemMotivo.campo }}</td>
                                                <td>{{ formataData(itemMotivo.data) }}</td>
                                                <td>{{ itemMotivo.descricao }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                    </td>
                    <td v-else :colspan="headers.length">
                        NENHUM MOTIVO INFORMADO
                    </td>
                </template>

                <template v-slot:footer.prepend>
                    <v-row>
                        <v-col cols="12" sm="4" class="text-left">
                            <v-icon size="70" color="red lighten-3">
                                mdi-circle-small
                            </v-icon>
                            Quando há divergências
                        </v-col>
                    </v-row>
                </template>
            </TabelaVuetify>

            <div class="text-center">
                <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                    <span v-html="notificacao.mensagem"></span>

                    <template v-slot:action="{ attrs }">
                        <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                            Fechar
                        </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import moment from 'moment';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { FormataMoeda, ExportaArrayObjectsParaExcel, sleep } from '@/helpers/Funcoes.js';
import {
    listaDescricao as etapaFaturamentoListaDescricao,
    descricao as etapaFaturamentoDescricao
} from '@/Modulo/Faturamento/Enum/EtapaFaturamentoEnum.js';
import { listaDescricao as listaDescricaoSimNao } from '@/Enum/Shared/SimNaoEnum.js';

import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import CardInfo from '@/components/Default/CardInfo.vue';
import TempoExecucao from '@/components/Default/TempoExecucao.vue';
import TabelaVuetify from '@/components/Default/TabelaVuetify.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';
import DateTimePickerV2 from '@/components/Form/DateTimePickerV2.vue';

export default {
    name: 'PainelAuditoria',
    components: {
        BarraNavegacaoBotaoV3,
        CarregandoLogoEmpresa,
        TituloModulo,
        CardInfo,
        TabelaVuetify,
        InputSelectV3,
        TempoExecucao,
        DateTimePickerV2
    },
    mounted() {
        this.montaNavegacao({
            botoes: [
                {
                    id_permissao: 1,
                    nome: 'Atualizar',
                    acao: 'buscaPainelFaturamento',
                    icone: 'mdi-refresh',
                    cor: 'green darken-5',
                    form: ['TODOS']
                }
            ],
            permissoes: [
                {
                    id_permissao: 1,
                    nome: 'atualiza',
                    permitido: 1
                }
            ]
        });

        this.buscaPainelFaturamento();
    },
    computed: {
        ...mapGetters({
            botoesComPermissao: 'botoesComPermissao'
        })
    },
    watch: {
        'datatable.itens': {
            handler() {
                this.calculaTotais();
            },
            immediate: true
        },
        'datatable.itensSelecionados': {
            handler() {
                this.calculaTotais();
            },
            immediate: true
        }
    },
    methods: {
        ...mapActions(['montaNavegacao']),
        async buscaPainelFaturamento() {
            try {
                this.validaBusca();

                this.mensagemCarregando = 'Buscando dados na API Automata, aguarde...';

                this.datatable.carregando = true;
                this.carregando = true;

                this.datatable.itensSelecionados = [];
                this.datatable.itens = [];
                this.corSelecionada = null;

                let query = this.montaFiltros();

                query = `data_de=${this.filtros.data_de}&data_ate=${this.filtros.data_ate}&liberado=${this.filtros.liberado}&${query}`;

                await ApiService({
                    method: 'GET',
                    url: `${API_URL.erp.faturamento.painelFaturamento}/?${query}`,
                    mostraCarregando: false
                })
                    .then(resposta => {
                        if (resposta?.data?.data) {
                            this.preenchePainel(resposta.data.data.atributos);

                            this.datatable.carregando = false;

                            /**
                             * Carregando tem que ser aqui porque
                             * Se for no Finally ele passa 2 vezes nos dados do Datatable
                             */
                            this.carregando = false;
                        }
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        preenchePainel(dados) {
            if (dados == undefined || dados.length == 0) {
                this.datatable.itens = [];
            }

            const total = dados.length;

            this.datatable.itens = dados;
            this.datatable.totalRegistros = total;

            this.calculaTempoExecucao(dados.data_criacao);
        },
        montaFiltros() {
            const filtros = this.$refs.tabela.filtros;

            return filtros
                ? Object.keys(filtros)
                      ?.map((filtro, indice) => {
                          const valor = filtros[filtro];

                          if (!valor) {
                              return;
                          }

                          return `${filtro}=${valor}`;
                      })
                      .filter(notUndefined => notUndefined !== undefined)
                      .join('&')
                : '';
        },
        calculaTempoExecucao(data_criacao) {
            const minuto = moment(data_criacao).format('mm');

            let tempoDiferenca = 0;

            if (minuto <= 15) {
                tempoDiferenca = 15 - minuto;
            }

            if (minuto >= 15) {
                tempoDiferenca = 30 - minuto;
            }

            if (minuto >= 30) {
                tempoDiferenca = 45 - minuto;
            }

            if (minuto >= 45) {
                tempoDiferenca = 60 - minuto;
            }

            this.inicia_tempo_execucao = true;
            this.tempo_total_proxima_execucao = tempoDiferenca * 60;
        },
        etapaDescricao(etapa) {
            return etapaFaturamentoDescricao(etapa);
        },
        regraPintaLinha(item) {
            if (item.situacao == 'statusRed') {
                return 'red lighten-4';
            }

            if (item.situacao == 'statusYellow') {
                return 'yellow lighten-4';
            }

            if (item.situacao == 'statusGreen') {
                return 'green lighten-4';
            }

            return 'white';
        },
        async filtraPorCor(situacaoSelecionada) {
            if (!this.datatableItensOriginais.length) {
                this.datatableItensOriginais = this.datatable.itens;
            }

            this.mensagemCarregando = 'Filtrando por cor, aplicado apenas aos registros já carregados, aguarde...';
            this.datatable.carregando = true;
            this.carregando = true;
            this.datatable.itensSelecionados = [];

            await sleep(1000);

            this.corSelecionada = situacaoSelecionada;
            this.datatable.itens = [];

            this.datatable.itens = this.datatableItensOriginais.filter(item => {
                const cor = this.regraPintaLinha(item);

                if (cor == situacaoSelecionada) {
                    return item;
                }
            });

            this.datatable.totalRegistros = this.datatable.itens.length;

            this.datatable.carregando = false;
            this.carregando = false;
        },
        formataData(data) {
            if (!data) {
                return '';
            }

            return moment(data).format('DD/MM/YYYY');
        },
        formataMoeda(valor) {
            if (!valor) {
                return 0;
            }

            return FormataMoeda(valor, {}, true);
        },
        async liberarFaturamento() {
            try {
                if (!this.datatable.itensSelecionados.length) {
                    this.notificacao = {
                        mensagem: 'Selecione um registro na tabela para liberar o faturamento.',
                        tipo: 'error',
                        mostra: true
                    };

                    return;
                }

                const idsFaturamento = this.datatable.itensSelecionados.map(item => {
                    return {
                        id_automata: item.id_automata,
                        id_programacao_carga_rodopar: item.programacao_carga_rodopar
                    };
                });

                const programacaCargaRodoparLiberadas = idsFaturamento.map(item => {
                    return item.id_programacao_carga_rodopar;
                });

                const msg = `Liberar o faturamento ? <br /> \r\n <span class="font-weight-bold orange--text text--darken-2">Programação de carga: ${programacaCargaRodoparLiberadas.join(
                    ', '
                )} </span>`;

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', msg, {
                        cor: 'green'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        this.mensagemCarregando = 'Liberando faturamento na API Automata, aguarde...';

                        this.carregando = true;

                        let url = `${API_URL.erp.faturamento.liberaFaturamento}`;

                        await ApiService({
                            method: 'POST',
                            url: url,
                            data: {
                                faturamento: idsFaturamento
                            },
                            mostraCarregando: false
                        })
                            .then(resposta => {})
                            .catch(erro => {
                                throw new Error(erro.message);
                            });

                        this.datatable.itensSelecionados = [];

                        await this.buscaPainelFaturamento();

                        this.notificacao = {
                            mensagem: 'Faturamento liberado.',
                            tipo: 'success',
                            mostra: true
                        };
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.carregando = false;
            }
        },
        exportaTabelaParaExcel() {
            try {
                let tabela = _.cloneDeep(this.datatable.itens);

                const itensFormatados = tabela.map(item => {
                    item.etapa = etapaFaturamentoDescricao(item.etapa);

                    return item;
                });

                ExportaArrayObjectsParaExcel(itensFormatados, this.datatable.cabecalho, 'painel-faturamento');
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        validaBusca() {
            if (!this.filtros.data_de) {
                throw new Error('Preencha o filtro data de');
            }

            if (!this.filtros.data_ate) {
                throw new Error('Preencha o filtro data ate');
            }

            if (!this.filtros.liberado) {
                throw new Error('Preencha o filtro liberado');
            }
        },
        async limpaFiltroTabela() {
            this.mensagemCarregando = 'Limpando filtros, aguarde...';
            this.datatable.carregando = true;
            this.carregando = true;
            this.datatable.itensSelecionados = [];

            await sleep(1000);

            this.corSelecionada = null;
            this.datatable.itens = this.datatableItensOriginais;
            this.datatableItensOriginais = [];
            this.$refs.tabela.limpaFiltros();

            this.datatable.totalRegistros = this.datatable.itens.length;

            this.datatable.carregando = false;
            this.carregando = false;
        },
        calculaTotais() {
            this.zeraTotais();

            const total = this.datatable.itens.reduce((total, processo) => total + processo.total_prestacao, 0);

            this.totais.total = this.formataMoeda(total);

            const total_selecionado = this.datatable.itensSelecionados.reduce(
                (total, processo) => total + processo.total_prestacao,
                0
            );

            this.totais.total_selecionado = this.formataMoeda(total_selecionado);
        },
        zeraTotais() {
            this.totais.total = 0;
            this.totais.total_selecionado = 0;
        }
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            carregando: false,
            mensagemCarregando: 'Buscando dados na API Automata, aguarde...',
            opcaoSimNao: listaDescricaoSimNao(),
            filtros: {
                data_de: moment()
                    .startOf('month')
                    .subtract(1, 'months')
                    .format('YYYY-MM-DD'),
                data_ate: moment().format('YYYY-MM-DD'),
                liberado: 'N'
            },
            inicia_tempo_execucao: false,
            tempo_total_proxima_execucao: 0,
            datatableItensOriginais: [],
            corSelecionada: null,
            totais: {
                total: 0,
                total_selecionado: 0
            },
            datatable: {
                itensSelecionados: [],
                carregando: false,
                itens: [],
                totalRegistros: 0,
                ultima_pagina: 0,
                pagina_atual: 1,
                por_pagina: 500,
                ordenarPor: 'id_automata',
                ordenarDirecao: true,
                cabecalho: [
                    {
                        text: 'TOTAL MOTIVOS',
                        value: 'total_motivos',
                        width: '120',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'ETAPA',
                        value: 'etapa',
                        width: '150',
                        type: 'select',
                        filterData: etapaFaturamentoListaDescricao()
                    },
                    {
                        text: 'ID AUTOMATA',
                        value: 'id_automata',
                        width: '190',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'CTE',
                        value: 'numero_cte',
                        width: '150',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'PROGRAMAÇÃO CARGA',
                        value: 'programacao_carga_rodopar',
                        width: '225',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'O.S.T',
                        value: 'id_ordem_servico_transporte_rodopar',
                        width: '180',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'CLIENTE CNPJ',
                        value: 'cnpj_cliente',
                        width: '180',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'CÓD. PAGADOR',
                        value: 'codigo_pagador',
                        width: '160',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'RAZÃO SOCIAL',
                        value: 'razao_social',
                        width: '230',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'NÚMERO DI',
                        value: 'numero_di',
                        width: '160',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'NÚMERO DTA E',
                        value: 'numero_dta_e',
                        width: '175',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'REFERÊNCIA CLIENTE',
                        value: 'referencia_cliente',
                        width: '195',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'VALOR FRETE',
                        value: 'valor_frete',
                        width: '180',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'TOTAL PRESTAÇÃO',
                        value: 'total_prestacao',
                        width: '180',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'DATA PEDIDO',
                        value: 'data_pedido',
                        width: '170',
                        type: 'date',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'DATA AUDITADO',
                        value: 'data_auditado',
                        width: '170',
                        type: 'date',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'DATA LIBERADO',
                        value: 'data_liberado',
                        width: '170',
                        type: 'date',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'DATA FATURAR',
                        value: 'data_faturamento',
                        width: '180',
                        type: 'date',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'DATA CRIAÇÃO',
                        value: 'data_criacao',
                        width: '170',
                        type: 'date',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'DATA ÚLTIMA ALTERAÇÃO',
                        value: 'data_ultima_alteracao',
                        width: '170',
                        type: 'date',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'USUÁRIO ALTERAÇÃO PROG. CARGA',
                        value: 'usuario_alteracao_programacao_carga',
                        width: '180',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'USUÁRIO ALTERAÇÃO CT-e',
                        value: 'usuario_alteracao_cte',
                        width: '170',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'USUÁRIO AUDITORIA',
                        value: 'usuario_tnsys_auditoria',
                        width: '190',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    },
                    {
                        text: 'UNIDADE',
                        value: 'unidade_rodopar',
                        width: '150',
                        type: 'input',
                        sortable: false,
                        showFilter: true
                    }
                ]
            }
        };
    }
};
</script>
