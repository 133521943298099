import _ from 'lodash';
import { mapGetters, mapActions, mapState } from 'vuex';

import { API_URL } from '@/config/configs';
import { Alerta, MetodoNovoOuAtualizaApi } from '@/helpers/Funcoes.js';
import ApiService from '@/services/ApiService.js';
import DatatableHelper from '@/helpers/Datatables.js';

const FormMixins = {
    async mounted() {
        this.mostraCarregando(true);

        this.id_colaborador = parseInt(this.$route.params.id_colaborador) ?? null;

        await this.dadosPaginaInicial();

        this.mostraCarregando(false);
    },
    computed: {
        ...mapGetters(['botoesComPermissao']),
        ...mapState(['carregando']),
        totalContato() {
            return this.contato.length;
        }
    },
    data() {
        return {
            tabDadosTotalErros: 0,
            tabEnderecoTotalErros: 0,
            tabContatoTotalErros: 0,
            tabAtestadoTotalErros: 0,
            mensagemCarregando: 'Aguarde, buscando colaborador...',
            permissao: false,
            permissoes: [],
            botoes: [
                {
                    id_permissao: [2, 3],
                    nome: 'Salvar',
                    acao: 'salvaForm',
                    icone: 'mdi-newspaper-variant-multiple',
                    cor: 'green',
                    form: ['todos']
                },
                {
                    id_permissao: [1],
                    nome: 'Fechar',
                    acao: 'fechaForm',
                    icone: 'mdi-file-cancel',
                    cor: 'orange',
                    form: ['todos']
                }
            ],
            inputDesativado: {
                id: '',
                label: 'ID Colaborador'
            },
            id_colaborador: null,
            atestados: [],
            atestadoData: [],
            enderecoEntregaData: {},
            enderecoCobrancaData: {},
            itensGestor: [],
            itemsEstado: [],
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2
            },
            apiUrl: API_URL,
            enderecoEntregaAtivo: false,
            enderecoCobrancaAtivo: false,
            tabComponente: 'Dados',
            formNome: 'formColaborador',
            moduloNome: 'colaborador',
            chavePrimaria: 'id_colaborador',
            urlNovoRegistro: '',
            urlConsultaRegistro: 'busca',
            formOriginal: {},
            itemsTipoContato: [],
            itemsTipoAtestado: [],
            validationMessage: [],
            itemsTipoAtestadoMotivo: [],
            itemsTipoAtestadoCID: [],
            colaborador: {
                ativo: 1
            },
            parceiro: {},
            endereco: {
                principal: {}
            },
            contato: [],
            colaborador_atestados: [],
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            }
        };
    },
    methods: {
        ...mapActions(['montaNavegacao', 'mostraCarregando']),
        async dadosPaginaInicial() {
            try {
                await this.buscaPermissoes();
                this.getTipoContato();
                this.getEstados();
                this.getAtestadoCID();
                this.getAtestadoMotivo();

                if (this.id_colaborador) {
                    await this.buscaForm();
                }

                this.permissao = true;
            } catch (erro) {
                Alerta([erro.message], 'error');
            }
        },
        async buscaPermissoes() {
            try {
                await ApiService({
                    method: 'get',
                    url: `${API_URL.erp.colaborador.permissao}`,
                    data: {},
                    mostraCarregando: false
                })
                    .then(async resposta => {
                        const permissoes = resposta.data.data.atributos;

                        await this.montaNavegacao({ botoes: this.botoes, permissoes });
                    })
                    .catch(error => {
                        throw new Error(error);
                    });

                return true;
            } catch (error) {
                Alerta([error.message], 'error');

                return false;
            }
        },
        async buscaForm() {
            try {
                let urlConfig = {
                    method: 'GET',
                    url: `${API_URL.erp.colaborador.busca}/${this.id_colaborador}?include=foto`,
                    data: {},
                    mostraCarregando: false
                };

                await ApiService(urlConfig)
                    .then(async resposta => {
                        resposta.data.data.atributos.id_colaborador = resposta.data.data.id_registro;

                        this.preencheDados(resposta.data.data.atributos);
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                throw new Error(erro.message);
            }
        },
        preencheDados(dadosForm) {
            this.colaborador = {
                id_colaborador: dadosForm.id_colaborador,
                id_parceiro: dadosForm.id_parceiro,
                id_colaborador_gestor: dadosForm.id_colaborador_gestor,
                id_filial: dadosForm.id_filial,
                id_unidade_empreendimento: dadosForm.id_unidade_empreendimento,
                id_departamento: dadosForm.id_departamento,
                id_tipo_contratacao: dadosForm.id_tipo_contratacao,
                id_cargo: dadosForm.id_cargo,
                id_sindicato: dadosForm.id_sindicato,
                pis: dadosForm.pis,
                numero_cnh: dadosForm.numero_cnh,
                data_nascimento: dadosForm.data_nascimento,
                data_vencimento_cnh: dadosForm.data_vencimento_cnh,
                nome_mae: dadosForm.nome_mae,
                nome_pai: dadosForm.nome_pai,
                data_admissao: dadosForm.data_admissao,
                data_prevista_experiencia: dadosForm.data_prevista_experiencia,
                matricula: dadosForm.matricula,
                status_contrato: dadosForm.status_contrato,
                salario: dadosForm.salario,
                data_vencimento_toxicologico: dadosForm.data_vencimento_toxicologico,
                data_vencimento_movimentacao_produto_perigoso: dadosForm.data_vencimento_movimentacao_produto_perigoso,
                data_ultimo_exame_periodico: dadosForm.data_ultimo_exame_periodico,
                data_proximo_exame_periodico: dadosForm.data_proximo_exame_periodico,
                data_saida_afastamento: dadosForm.data_saida_afastamento,
                pesquisa_gerenciador_risco: dadosForm.pesquisa_gerenciador_risco ? true : false,
                data_retorno_afastamento: dadosForm.data_retorno_afastamento,
                data_saida_suspensao: dadosForm.data_saida_suspensao,
                data_retorno_suspensao: dadosForm.data_retorno_suspensao,
                data_desligamento: dadosForm.data_desligamento,
                observacao: dadosForm.observacao,
                ativo: dadosForm.ativo ? true : false,
                status: dadosForm.status,
                imagem_avatar: dadosForm.imagem_avatar ?? require('@/assets/image/perfil/img_avatar.png')
            };

            this.parceiro = {
                id_parceiro: dadosForm.parceiro.id_parceiro,
                id_uf_rg: dadosForm.parceiro.id_uf_rg,
                tipo_parceiro: 1,
                nome: dadosForm.parceiro.nome,
                tipo_pessoa: dadosForm.parceiro.tipo_pessoa,
                razao_social: dadosForm.parceiro.razao_social,
                nome_fantasia: dadosForm.parceiro.nome_fantasia,
                representante_legal: dadosForm.parceiro.representante_legal,
                cpf: dadosForm.parceiro.cpf,
                cnpj: dadosForm.parceiro.cnpj,
                inscricao_estadual: dadosForm.parceiro.inscricao_estadual,
                inscricao_municipal: dadosForm.parceiro.inscricao_municipal,
                cnae: dadosForm.parceiro.cnae,
                rg: dadosForm.parceiro.rg,
                data_emissao_rg: dadosForm.parceiro.data_emissao_rg,
                orgao_emissor: dadosForm.parceiro.orgao_emissor
            };

            this.endereco = {
                principal: dadosForm.parceiro.enderecos.find(endereco => endereco.tipo_endereco === 1),
                entrega: dadosForm.parceiro.enderecos.find(endereco => endereco.tipo_endereco === 2),
                cobranca: dadosForm.parceiro.enderecos.find(endereco => endereco.tipo_endereco === 3)
            };

            this.contato = dadosForm.parceiro.contatos;
            this.colaborador_atestados = [];

            if (dadosForm.colaborador_gestor) {
                this.itensGestor = [
                    {
                        id_colaborador: dadosForm.colaborador_gestor.id_colaborador,
                        descricao: dadosForm.colaborador_gestor.parceiro_gestor.nome
                    }
                ];
            }
        },
        handleEnderecoEntrega() {
            this.enderecoEntregaAtivo = !this.enderecoEntregaAtivo;
        },
        contatoAdiciona() {
            this.contato.push({});
        },
        contatoRemove(posicao) {
            const contatosLista = this.$refs.contatoRef.filter((item, index) => index !== posicao);

            this.contato = [];

            contatosLista.forEach((dados, index) => {
                this.contato.push(dados.contato);
            });
        },
        handleAtestado() {
            this.atestados.push(true);
        },
        handleRemoveAtestado(i) {
            const atestados = this.atestados;
            atestados[i] = false;

            this.atestados = [];

            this.atestados = atestados;
        },
        handleEnderecoCobranca() {
            this.enderecoCobrancaAtivo = !this.enderecoCobrancaAtivo;
        },
        async getTipoContato() {
            await ApiService({
                method: 'get',
                url: `V1/parceiro/contato/lista/tipo`,
                data: {}
            })
                .then(resposta => {
                    this.itemsTipoContato = resposta.data.data.map(item => {
                        return {
                            label: item.atributos.descricao,
                            value: item.id_registro
                        };
                    });
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        },

        async getAtestadoMotivo() {
            await ApiService({
                method: 'get',
                url: `V1/atestadomotivo/lista?fields=motivo`,
                data: {}
            })
                .then(resposta => {
                    this.itemsTipoAtestadoMotivo = resposta.data.data.map(item => {
                        return {
                            label: `${item.atributos.motivo}`,
                            value: item.id_registro
                        };
                    });
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        },

        async getAtestadoCID() {
            await ApiService({
                method: 'get',
                url: `V1/atestadocid/lista?fields=codigo,descricao`,
                data: {}
            })
                .then(resposta => {
                    this.itemsTipoAtestadoCID = resposta.data.data.map(item => {
                        return {
                            label: `${item.atributos.codigo} - ${item.atributos.descricao}`,
                            value: item.id_registro
                        };
                    });
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        },

        async getEstados() {
            await ApiService({
                method: 'get',
                url: `V2/estado/lista?fields=uf`,
                data: {}
            })
                .then(resposta => {
                    this.itemsEstado = resposta.data.data.map(item => {
                        return {
                            label: item.atributos.uf,
                            value: item.id_registro
                        };
                    });
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        },
        FormataDadosParaEnviarForm() {
            const contatos = [];
            const atestados = [];

            this.$refs.contatoRef?.forEach((item, i) => {
                contatos.push(this.$refs.contatoRef[i].preparaDados());
            });

            this.atestados?.forEach((active, i) => {
                if (active) {
                    atestados.push(this.$refs.atestadoRef[i].preparaDados());
                }
            });

            return {
                dados: {
                    colaborador: this.$refs.colaboradorRef.preparaDados(),
                    parceiro: this.$refs.colaboradorRef.preparaDadosParceiro(),
                    endereco: {
                        principal: this.$refs.enderecoPrincipalRef?.preparaDados() ?? {},
                        entrega: this.$refs.enderecoEntregaRef?.preparaDados() ?? {},
                        cobranca: this.$refs.enderecoCobrancaRef?.preparaDados() ?? {}
                    },
                    contato: contatos,
                    atestado: atestados
                }
            };
        },
        fechaForm() {
            this.$router.push({ name: 'colaborador' });
        },
        async salvaForm() {
            try {
                this.validationMessage = [];
                this.tabDadosTotalErros = 0;
                this.tabEnderecoTotalErros = 0;
                this.tabContatoTotalErros = 0;

                if (!this.$refs.colaboradorRef.$refs.form.validate()) {
                    this.tabDadosTotalErros += 1;

                    this.validationMessage.push('Dados contem campos inválidos');
                }

                if (!this.$refs.enderecoPrincipalRef.$refs.form.validate()) {
                    this.tabEnderecoTotalErros += 1;
                    this.validationMessage.push('Endereço Principal contem campos inválidos');
                }

                if (this.$refs.enderecoCobrancaRef?.$refs.form.validate() === false) {
                    this.tabEnderecoTotalErros += 1;
                    this.validationMessage.push('Endereço de Cobrança contem campos inválidos');
                }

                if (this.$refs.enderecoEntregaRef?.$refs.form.validate() === false) {
                    this.tabEnderecoTotalErros += 1;
                    this.validationMessage.push('Endereço de Entrega contem campos inválidos');
                }

                this.$refs.contatoRef?.forEach((contato, i) => {
                    if (!this.$refs.contatoRef[i].$refs.form.validate()) {
                        this.tabEnderecoTotalErros += 1;
                        this.validationMessage.push(`Contato #${i + 1} contem campos inválidos`);
                    }
                });

                this.atestados?.forEach((active, i) => {
                    if (active && !this.$refs.atestadoRef[i].$refs.form.validate()) {
                        this.validationMesconteudosage.push(`Atestado#${i + 1} contem campos inválidos`);
                    }
                });

                if (this.validationMessage.length > 0) {
                    Alerta(this.validationMessage, 'error');

                    return;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', 'Salvar registro ?', {
                        cor: 'green'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        this.mostraCarregando(true);

                        this.mensagemCarregando = 'Salvando colaborador, aguarde...';

                        let conteudoFormulario = this.FormataDadosParaEnviarForm();

                        let urlForm = this.$refs.colaboradorRef.colaborador.id_colaborador
                            ? `${API_URL.erp[this.moduloNome].salva}/${this.$refs.colaboradorRef.colaborador.id_colaborador}`
                            : `${API_URL.erp[this.moduloNome].salva}`;

                        let urlConfig = {
                            method: this.$refs.colaboradorRef.colaborador.id_colaborador ? 'PUT' : 'POST',
                            url: urlForm,
                            data: conteudoFormulario,
                            mostraCarregando: false
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                this.notificacao = {
                                    mensagem: `Colaborador ${resposta.data.data.id_registro} - ${
                                        this.$refs.colaboradorRef.parceiro.nome
                                    } ${
                                        this.$refs.colaboradorRef.colaborador.id_colaborador ? 'atualizado' : 'criado'
                                    } com sucesso`,
                                    tipo: 'success',
                                    mostra: true
                                };

                                resposta.data.data.atributos.id_colaborador = resposta.data.data.id_registro;

                                this.preencheDados(resposta.data.data.atributos);
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });

                        this.mensagemCarregando = 'Salvando imagem do avatar, aguarde...';

                        if (this.$refs.colaboradorRef.imagemColaborador) {
                            const extensao = this.$refs.colaboradorRef.imagemColaborador.split(';')[0].split('/')[1];
                            const base64Response = await fetch(`${this.$refs.colaboradorRef.imagemColaborador}`);
                            const blob = await base64Response.blob();

                            const arquivoImagem = new File([blob], `avatar.${extensao}`, {
                                type: `image/${extensao}`
                            });

                            await this.salvaImagem(this.$refs.colaboradorRef.colaborador.id_colaborador, arquivoImagem);

                            this.$refs.colaboradorRef.avatarUploadLimpa();
                        }
                    });
            } catch (erro) {
                Alerta([erro.message], 'error');
            }

            this.mostraCarregando(false);
        },
        async salvaImagem(id_colaborador, arquivo_imagem) {
            try {
                let formData = new FormData();

                formData.append('dados[id_colaborador]', id_colaborador);
                formData.append('imagem', arquivo_imagem);

                let urlConfig = {
                    method: 'POST',
                    url: `${API_URL.erp.imagem.avatarColaborador}`,
                    data: formData,
                    mostraCarregando: false,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                };

                await ApiService(urlConfig)
                    .then(resposta => {
                        this.notificacao = {
                            mensagem: `Imagem de avatar salva`,
                            tipo: 'success',
                            mostra: true
                        };
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                Alerta([erro.message], 'error');
            }
        },
        async apagaForm() {
            try {
                let dados = DatatableHelper.datatableGetLinhaSelecionadas(this.moduloNome);

                let ids = dados
                    .map(function(dado) {
                        return dado.DT_RowId;
                    })
                    .join();

                if (!ids) {
                    this.$emit('mensagemAlerta', 'Selecione um registro antes de apagar.', 'error');

                    return false;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Deleta', `Apagar registro(s) ?\n IDS - ${ids}`, {
                        cor: 'red'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        let urlConfig = {
                            method: 'DELETE',
                            url: `${API_URL.erp[this.moduloNome].apaga}/${ids}`
                        };

                        let dados = await ApiService(urlConfig)
                            .then(resposta => {
                                this.$emit('datatableRecarrega');

                                this.$emit('mensagemAlerta', `Registro ${ids} deletado com sucesso!`, 'success');
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        }
    }
};

export default FormMixins;
