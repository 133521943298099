<template>
    <v-card-text>
        <v-row>
            <v-col cols="2">
                <InputTextV3
                    v-model="avaliado.id_avaliacao_desempenho_avaliados"
                    :contador="100"
                    rotulo="ID"
                    desativado
                    clearable
                />
            </v-col>

            <v-col cols="10">
                <InputAutoCompleteV3
                    v-model="avaliado.id_colaborador"
                    :datatableConfiguracao="autoCompleta.avaliados.datatable"
                    tituloBusca="Avaliado Busca..."
                    :itemsOpcoes="itensAvaliados"
                    itemValor="id_colaborador"
                    itemTexto="descricao"
                    :regras="[v => !!v || 'Colaborador é obrigatório']"
                />
            </v-col>
        </v-row>
    </v-card-text>
</template>

<script>
import { API_URL, API_URL_BASE } from '@/config/configs';

import InputAutoCompleteV3 from '@/components/Form/InputAutoCompleteV3.vue';
import InputTextV3 from '@/components/Form/InputTextV3.vue';

export default {
    name: 'AvaliadoForm',
    components: {
        InputAutoCompleteV3,
        InputTextV3
    },
    async mounted() {},
    props: {
        avaliadoData: {
            type: Object,
            default() {
                return {};
            }
        },
        itensAvaliados: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    watch: {
        avaliadoData: {
            handler: function(dadosAtual, dadosAntigo) {
                this.preencheDados(dadosAtual);
            }
        }
    },
    created() {
        if (!_.isEmpty(this.avaliadoData)) {
            this.preencheDados(this.avaliadoData);
        }
    },
    data() {
        return {
            avaliado: {
                id_avaliacao_desempenho_avaliados: null,
                id_colaborador: null
            },
            autoCompleta: {
                avaliados: {
                    datatable: {
                        id_datatable: 'avaliados_id_colaborador',
                        chave_primaria: 'avaliados_id_avaliados',
                        url: `${API_URL_BASE}${API_URL.erp.avaliacaodesempenho.datatable}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.avaliacaodesempenho.autoCompletaCampo}`,
                        colunas: [
                            {
                                id: 'id_colaborador',
                                nome: 'id_colaborador',
                                descricao: 'ID'
                            },
                            {
                                id: 'descricao',
                                nome: 'descricao',
                                descricao: 'Descrição'
                            }
                        ]
                    }
                }
            }
        };
    },
    methods: {
        preparaDados() {
            const avaliado = { ...this.avaliado };

            return avaliado;
        },
        preencheDados(avaliado) {
            this.avaliado = { ...avaliado };
        }
    }
};
</script>
