<template>
    <div style="height: 85vh">
        <v-card class="mt-5" elevation="3" style="height: 100%">
            <v-overlay :absolute="true" :value="carregando.mostraCarregando">
                <CarregandoLogoEmpresa :mensagem="mensagemCarregando" />
            </v-overlay>

            <AlertaMensagem dense style="width: 100%" />

            <v-row v-if="permissao" style="height: inherit">
                <v-col cols="12" sm="4" class="pt-0 pr-0 bordaDireita" style="height: 100%">
                    <v-tabs
                        v-model="tab"
                        background-color="blue-grey lighten-5"
                        color="indigo darken-3"
                        centered
                        icons-and-text
                    >
                        <v-tabs-slider></v-tabs-slider>

                        <v-tab href="#tab-contatos" class="text-sm-body-2 font-weight-bold">
                            <v-badge color="green" :content="totalEmAntedimento" offset-x="30" offset-y="-15">
                                contatos &nbsp;

                                <v-chip right x-small class="text-sm-body-2 font-weight-bold" color="red lighten-1" dark>
                                    {{ totalMensagensNaoLidas }}
                                </v-chip>
                            </v-badge>

                            <v-icon>mdi-human-queue</v-icon>
                        </v-tab>
                    </v-tabs>

                    <v-toolbar dense tile flat>
                        <v-text-field
                            v-model="contatoBusca"
                            hide-details
                            prepend-icon="mdi-magnify"
                            placeholder="Pesquisar ou começar uma nova conversa"
                            single-line
                        ></v-text-field>

                        <v-btn icon @click="modalAdicionaConversa = true">
                            <v-icon>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-tabs-items v-model="tab" style="overflow: auto; height: 84%">
                        <v-tab-item value="tab-contatos">
                            <v-card>
                                <v-card-text class="pl-0 pr-0">
                                    <template v-for="(contato, index) in contatos">
                                        <contato
                                            :key="`contato-em-atendimento-${contato.id_whatsapp_conversa}`"
                                            :tipo_atendimento="atendimentoWhatsapp"
                                            :id_whatsapp_conversa="contato.id_whatsapp_conversa"
                                            :id_whatsapp_chamado="contato.id_whatsapp_chamado"
                                            :whatsapp_nome_profile="contato.whatsapp_nome_profile"
                                            :ultima_mensagem="contato.ultima_mensagem"
                                            :hora_recebe_ultima_mensagem="contato.hora_recebe_ultima_mensagem"
                                            :total_mensagens="contato.total_mensagens"
                                            :imagem_texto="contato.imagem_texto"
                                            :imagem_texto_cor="contato.imagem_texto_cor"
                                            :iniciar_atendimento="false"
                                            :carregando="contato.carregando"
                                            @carregaMensagens="carregaMensagens"
                                        />

                                        <v-divider
                                            v-if="index < contatos.length - 1"
                                            :key="`contato-em-atendimento${index}`"
                                        ></v-divider>
                                    </template>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>

                <v-col cols="12" sm="8" class="pt-0 pl-0 pb-0" style="height: inherit">
                    <atendimento-conversa
                        v-if="atual_id_whatsapp_conversa"
                        :tipo_atendimento="atendimentoWhatsapp"
                        :id_whatsapp_conversa="conversa.id_whatsapp_conversa"
                        :id_whatsapp_atendente="conversa.id_whatsapp_atendente"
                        :id_whatsapp_chamado="conversa.id_whatsapp_chamado"
                        :whatsapp_nome_profile="conversa.whatsapp_nome_profile"
                        :nome_completo="conversa.nome_completo"
                        :telefone_envio="conversa.telefone_envio"
                        :avatar="conversa.avatar"
                        :mensagens="conversaMensagens"
                        :carregando="carregandoMensagens"
                        :setor_texto="conversa.setor_texto"
                        :setor_texto_cor="conversa.setor_texto_cor"
                        @envia_mensagem="enviaMensagem"
                        @carrega-mensagens-anteriores="carregaMensagensAnteriores"
                        @abre-imagem="abreImagem"
                    />

                    <informacoes v-else />
                </v-col>
            </v-row>

            <v-dialog v-model="modalNumeroAtendimentoDisponivel" persistent>
                <v-card class="mx-auto" outlined>
                    <v-card-title class="text-h5"> Número de Atendimento Disponível </v-card-title>

                    <v-card-text>
                        <v-row>
                            <v-col
                                cols="12"
                                md="4"
                                v-for="(item, index) in listaNumeroAtendimentoDisponivel"
                                :key="`numero-disponivel-${index}`"
                            >
                                <numero-atendimento-disponivel
                                    ref="modalNumeroAtendimentoDisponivel"
                                    :id_whatsapp_numero_atendimento="item.id_whatsapp_numero_atendimento"
                                    :descricao="item.descricao"
                                    :observacao="item.observacao"
                                    :numero_telefone="item.numero_telefone"
                                    @cancelar="modalNumeroAtendimentoDisponivel = false"
                                    @selecionar="selecioanrNumeroAtendimentoDisponivel"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <AdicionaConversa
                v-if="modalAdicionaConversa"
                :abreModal="modalAdicionaConversa"
                :id_whatsapp_numero_atendimento="idWhatsappNumeroAtendimento"
                @adicionar="modalAdicionaConversa = false"
                @cancelar="modalAdicionaConversa = false"
            />

            <ModalImagem v-if="modalImagem.abre" :abre="modalImagem.abre" :imagem="modalImagem.imagem" @fecha="fechaImagem" />

            <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                {{ notificacao.mensagem }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false"> Fechar </v-btn>
                </template>
            </v-snackbar>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import moment from 'moment';

import { Alerta } from '@/helpers/Funcoes.js';

import TipoAtendimento from '../Enum/TipoAtendimento';

import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import Conversa from './Conversa.vue';
import Informacoes from './Informacoes.vue';
import Contato from './Contato.vue';
import AtendimentoConversa from './AtendimentoConversa.vue';
import NumeroAtendimentoDisponivel from './NumeroAtendimentoDisponivel.vue';
import AdicionaConversa from './AdicionaConversa.vue';
import ModalImagem from './ModalImagem.vue';

export default {
    name: 'Atendimento',
    components: {
        AlertaMensagem,
        CarregandoLogoEmpresa,
        Conversa,
        Informacoes,
        Contato,
        AtendimentoConversa,
        NumeroAtendimentoDisponivel,
        AdicionaConversa,
        ModalImagem,
    },
    async mounted() {
        this.verificaNotificacoesHabilitadas();

        await this.carregaAtendimentos();
    },
    computed: {
        ...mapState(['carregando']),
        ...mapGetters({
            id_usuario: 'autenticacao/getIdUsuario',
        }),
        totalEmAntedimento() {
            let total = 0;

            this.contatos.map((item) => {
                total += 1;
            });

            return total.toString();
        },
        totalMensagensNaoLidas() {
            let total = 0;

            this.contatos.map((item) => {
                if (item.total_mensagens > 0) {
                    total += item.total_mensagens;
                }
            });

            return total;
        },
    },
    methods: {
        ...mapActions(['mostraCarregando']),
        ...mapActions('notificacaoBrowser', ['notificacaoBrowser', 'browserNotificacaoHabilitado']),
        async verificaNotificacoesHabilitadas() {
            try {
                await this.browserNotificacaoHabilitado();

                this.notificacao = {
                    mensagem: 'Você habilitou as notificações e irá receber alerta quando houver nova mensagem',
                    tipo: 'success',
                    mostra: true,
                };
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        async carregaAtendimentos() {
            try {
                this.mostraCarregando(true);

                this.mensagemCarregando = 'Carregando lista de números disponíveis, aguarde...';

                await this.carregaNumeroAtendimentoDisponivel();

                this.permissao = true;
            } catch (erro) {
                Alerta([erro.message], 'error');

                this.permissao = false;

                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            } finally {
                this.mostraCarregando(false);
            }
        },
        async carregaDados() {
            try {
                if (!this.idWhatsappNumeroAtendimento) {
                    throw new Error('Nenhum número de atendimento selecionado para carregar os dados');
                }

                this.mostraCarregando(true);

                await this.carregaPerfilAtendente();
                await this.carregaContatos();
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            } finally {
                this.mostraCarregando(false);
            }
        },
        async carregaNumeroAtendimentoDisponivel() {
            try {
                await ApiService({
                    method: 'get',
                    url: `${API_URL.erp.whatsapp.numeroAtendimentoDisponivel}`,
                    params: {
                        fluxo_atendimento: 'N',
                    },
                    mostraCarregando: false,
                })
                    .then(async (resposta) => {
                        if (!resposta.data.data?.length) {
                            throw new Error('Nenhum número de atendimento está disponível no seu perfil');
                        }

                        this.listaNumeroAtendimentoDisponivel = resposta.data.data.map((item) => {
                            return {
                                id_whatsapp_numero_atendimento: item.id_whatsapp_numero_atendimento,
                                descricao: item.descricao,
                                observacao: item.observacao,
                                numero_telefone: item.telefone_atendimento,
                            };
                        });

                        this.modalNumeroAtendimentoDisponivel = true;
                    })
                    .catch((error) => {
                        throw new Error(error);
                    });
            } catch (erro) {
                throw new Error(erro.message);
            }
        },
        async carregaPerfilAtendente() {
            try {
                this.mostraCarregando(true);

                this.mensagemCarregando = 'Configurando perfil.';

                const perfil = await ApiService({
                    method: 'get',
                    url: `${API_URL.erp.whatsapp.perfilAtendente}`,
                    data: {},
                    mostraCarregando: false,
                })
                    .then(async (resposta) => {
                        return resposta.data.data;
                    })
                    .catch((error) => {
                        throw new Error(error);
                    });
            } catch (erro) {
                throw new Error(erro.message);
            } finally {
                this.carregandoMensagens = false;
            }
        },
        async carregaContatos() {
            try {
                this.mostraCarregando(true);

                this.mensagemCarregando = 'Carregando lista de contatos.';

                /**
                 * NUMERO DE ATENDIMENTO ESTÁ NA MÃO
                 * PRECISA DE UM DIALOG INICIAL PARA INFORMAR QUAL NÚMERO ELE PODE ATENDER
                 */
                const contatos = await ApiService({
                    method: 'get',
                    url: `${API_URL.erp.whatsapp.contatoAtendimento}/${this.idWhatsappNumeroAtendimento}`,
                    data: {},
                    mostraCarregando: false,
                })
                    .then(async (resposta) => {
                        return resposta.data.data;
                    })
                    .catch((error) => {
                        throw new Error(error);
                    });

                this.preencheContato(contatos);
            } catch (erro) {
                throw new Error(erro.message);
            } finally {
                this.carregandoMensagens = false;
            }
        },
        preencheContato(contatos) {
            contatos.sort(this.contatosOrdena);

            contatos.map((contato) => {
                this.adicionaContato(contato);
            });
        },
        contatosOrdena(a, b) {
            if (!a.mensagem) {
                return -1;
            }

            if (!b.mensagem) {
                return 1;
            }

            const dataA = new Date(a.mensagem.data_envio);
            const dataB = new Date(b.mensagem.data_envio);

            return dataA - dataB;
        },
        contatosOrdenaDesc(a, b) {
            if (!a.mensagem) {
                return -1;
            }

            if (!b.mensagem) {
                return 1;
            }

            const dataA = new Date(a.mensagem.data_envio);
            const dataB = new Date(b.mensagem.data_envio);

            return dataB - dataA;
        },
        adicionaContato(contato) {
            let msg =
                !contato.mensagem?.media && contato.mensagem?.mensagem == '' ? 'Arquivo de media' : contato.mensagem?.mensagem;

            msg = msg ?? '';

            const dataHoraRecebe = contato.mensagem?.data_envio
                ? moment(contato.mensagem.data_envio).format('DD/MM/YY HH:mm')
                : '';

            const dados = {
                id_whatsapp_conversa: contato.id_whatsapp_conversa,
                id_whatsapp_atendente: contato.id_whatsapp_atendente,
                whatsapp_nome_profile: contato.whatsapp_nome_profile,
                nome_completo: contato.nome_completo,
                telefone_envio: contato.telefone_envio,
                ultima_mensagem: msg,
                hora_recebe_ultima_mensagem: dataHoraRecebe,
                data_envio: contato.mensagem?.data_envio,
                total_mensagens: contato.mensagem?.total ?? 0,
                carregando: false,
            };

            this.contatos.unshift(dados);
        },
        async carregaMensagens({ id_whatsapp_conversa }) {
            try {
                const conversa = this.encontraContato(id_whatsapp_conversa);

                if (!conversa) {
                    throw new Error('Conversa não encontrada.');
                }

                if (conversa.carregando) {
                    return;
                }

                this.limpaMensagens();

                this.conversa = {
                    id_whatsapp_conversa: conversa.id_whatsapp_conversa,
                    whatsapp_nome_profile: conversa.whatsapp_nome_profile,
                    nome_completo: conversa.nome_completo,
                    telefone_envio: conversa.telefone_envio,
                    avatar: null,
                    setor_texto: conversa.imagem_texto,
                    setor_texto_cor: conversa.imagem_texto_cor,
                    carregando: true,
                };

                this.atual_id_whatsapp_conversa = id_whatsapp_conversa;

                const mensagens = await this.buscaMensagens(id_whatsapp_conversa);

                this.adicionaMensagens(mensagens);

                this.marcaConversaVisualizada(conversa);
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        async buscaMensagens(id_whatsapp_conversa, ultima_anterior_id_whatsapp_mensagem) {
            try {
                this.carregandoMensagens = true;

                const parametros = {};

                if (!ultima_anterior_id_whatsapp_mensagem) {
                    this.limpaMensagens();
                }

                if (ultima_anterior_id_whatsapp_mensagem) {
                    parametros.ultima_id_whatsapp_mensagem = ultima_anterior_id_whatsapp_mensagem;
                }

                let url = `${API_URL.erp.whatsapp.conversaMensagens}`.replace(
                    '${id_whatsapp_numero_atendimento}',
                    this.idWhatsappNumeroAtendimento
                );

                url = url.replace('${id_whatsapp_conversa}', id_whatsapp_conversa);

                const mensagens = await ApiService({
                    method: 'GET',
                    url: url,
                    mostraCarregando: false,
                    params: parametros,
                })
                    .then((resposta) => {
                        return resposta?.data?.data ?? [];
                    })
                    .catch((erro) => {
                        throw new Error(erro.message);
                    });

                return mensagens;
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            } finally {
                this.carregandoMensagens = false;
            }
        },
        marcaConversaVisualizada(conversa) {
            try {
                ApiService({
                    method: 'PUT',
                    url: `${API_URL.erp.whatsapp.conversaVisualizada}/${conversa.id_whatsapp_conversa}`,
                    mostraCarregando: false,
                })
                    .then((resposta) => {
                        // LIMPA ULTIMA TOTAL DE MENSAGENS DO CONTATO, POIS FORAM VISUALIZADAS
                        conversa.total_mensagens = 0;

                        return true;
                    })
                    .catch((erro) => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        mensagemBroadcast(mensagem_broadcast) {
            try {
                if (!mensagem_broadcast?.atributos) {
                    return;
                }

                mensagem_broadcast = mensagem_broadcast?.atributos;

                let id_whatsapp_mensagem = mensagem_broadcast.id_whatsapp_mensagem;
                let id_whatsapp_conversa = mensagem_broadcast.id_whatsapp_conversa;

                const contatoAtual = this.encontraContato(id_whatsapp_conversa);

                if (!contatoAtual) {
                    return;
                }

                this.notificacaoBrowser({
                    titulo: `Whatsapp - ${contatoAtual.whatsapp_nome_profile}`,
                    mensagem: mensagem_broadcast.mensagem,
                    tag: id_whatsapp_mensagem,
                });

                /**
                 * ATUALIZA A ÚLTIMA MENSAGEM ENVIADA NO CONTATO
                 */
                if (this.conversa.id_whatsapp_conversa != id_whatsapp_conversa) {
                    const msg = {
                        id_whatsapp_mensagem: id_whatsapp_mensagem,
                        id_whatsapp_conversa: id_whatsapp_conversa,
                        mensagem: mensagem_broadcast,
                        total: mensagem_broadcast.total,
                    };

                    this.atualizaContatoUltimaMensagem(msg, id_whatsapp_conversa);

                    return;
                }

                const envio_remetente = mensagem_broadcast.envio_remetente == 'S' ? true : false;

                const mensagemNova = {
                    id_whatsapp_mensagem: mensagem_broadcast.id_whatsapp_mensagem,
                    id_whatsapp_conversa: mensagem_broadcast.id_whatsapp_conversa,
                    mensagem: mensagem_broadcast.mensagem,
                    data_envio: mensagem_broadcast.data_envio,
                    data_recebe: mensagem_broadcast.data_recebe,
                    data_leitura: mensagem_broadcast.data_leitura,
                    envio_remetente: envio_remetente,
                    media: mensagem_broadcast.media,
                };

                this.adicionaMensagem(mensagemNova);
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        statusMensagemBroadcast(mensagem_broadcast) {
            try {
                if (!mensagem_broadcast?.atributos) {
                    return;
                }

                const id_whatsapp_conversa = mensagem_broadcast.atributos.id_whatsapp_conversa;
                const id_whatsapp_mensagem = mensagem_broadcast.atributos.id_whatsapp_mensagem;

                const contatoAtual = this.encontraContato(id_whatsapp_conversa);

                if (!contatoAtual) {
                    return;
                }

                const mensagemAtual = this.conversaMensagens.find(
                    (mensagem) => mensagem.id_whatsapp_mensagem === id_whatsapp_mensagem
                );

                if (mensagemAtual) {
                    mensagemAtual.data_envio = mensagem_broadcast.atributos?.data_envio;
                    mensagemAtual.data_recebe = mensagem_broadcast.atributos?.data_recebe;
                    mensagemAtual.data_leitura = mensagem_broadcast.atributos?.data_leitura;
                    mensagemAtual.mensagem_erro = mensagem_broadcast.atributos.falha?.erro ?? '';
                }

                const msg = {
                    id_whatsapp_conversa: id_whatsapp_conversa,
                    id_whatsapp_mensagem: id_whatsapp_mensagem,
                    mensagem: mensagem_broadcast.atributos,
                    total: mensagem_broadcast.atributos?.total,
                };

                this.atualizaContatoUltimaMensagem(msg, id_whatsapp_conversa);
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        conversaBroadcast(contato_broadcast) {
            try {
                if (!contato_broadcast?.atributos) {
                    return;
                }

                const contatoBroadcast = contato_broadcast.atributos;

                let id_whatsapp_conversa = contatoBroadcast.id_whatsapp_conversa;

                const contatoAtual = this.encontraContato(id_whatsapp_conversa);

                if (!contatoAtual) {
                    const conversaNova = {
                        id_whatsapp_conversa: contatoBroadcast.id_whatsapp_conversa,
                        id_whatsapp_atendente: contatoBroadcast.id_whatsapp_atendente,
                        whatsapp_nome_profile: contatoBroadcast.whatsapp_nome_profile,
                        nome_completo: contatoBroadcast.nome_completo,
                        telefone_envio: contatoBroadcast.telefone_envio,
                        mensagem: contatoBroadcast.mensagem,
                    };

                    this.adicionaContato(conversaNova);

                    return;
                }

                if (contatoAtual) {
                    const msg = {
                        id_whatsapp_mensagem: contatoBroadcast.mensagem.id_whatsapp_mensagem,
                        id_whatsapp_conversa: contatoBroadcast.id_whatsapp_conversa,
                        mensagem: contatoBroadcast.mensagem,
                        total: contatoBroadcast.total,
                    };

                    this.atualizaContatoUltimaMensagem(msg, contatoBroadcast.id_whatsapp_conversa);
                }
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        limpaMensagens() {
            this.conversaMensagens = [];
        },
        adicionaMensagens(mensagens) {
            mensagens.forEach((mensagem) => {
                const envio_remetente = mensagem.envio_remetente == 'S' ? true : false;

                this.adicionaMensagem(
                    {
                        id_whatsapp_mensagem: mensagem.id_whatsapp_mensagem,
                        id_whatsapp_conversa: mensagem.id_whatsapp_conversa,
                        mensagem: mensagem.mensagem,
                        falha: mensagem.falha,
                        data_envio: mensagem.data_envio,
                        data_recebe: mensagem.data_recebe,
                        data_leitura: mensagem.data_leitura,
                        envio_remetente: envio_remetente,
                        mensagem_erro: mensagem.falha?.erro ?? '',
                        media: mensagem.media,
                    },
                    true
                );
            });
        },
        adicionaMensagem(mensagem, anterior) {
            const msg = {
                id_whatsapp_mensagem: mensagem.id_whatsapp_mensagem,
                id_whatsapp_conversa: mensagem.id_whatsapp_conversa,
                mensagem: mensagem.mensagem,
                data_envio: mensagem.data_envio,
                data_recebe: mensagem?.data_recebe,
                data_leitura: mensagem?.data_leitura,
                envio_remetente: mensagem.envio_remetente,
                mensagem_erro: mensagem.mensagem_erro ?? '',
                media: mensagem?.media ?? null,
            };

            this.ultimaAnteriorIdWhatsappMensagem = msg.id_whatsapp_mensagem;

            if (anterior) {
                this.conversaMensagens.unshift(msg);

                return;
            }

            this.conversaMensagens.push(msg);

            const ultimaMensagem = {
                id_whatsapp_mensagem: mensagem.id_whatsapp_mensagem,
                id_whatsapp_conversa: mensagem.id_whatsapp_conversa,
                mensagem: mensagem,
                total: mensagem.total,
            };

            this.atualizaContatoUltimaMensagem(ultimaMensagem, this.atual_id_whatsapp_conversa);
        },
        atualizaContatoUltimaMensagem(ultima_mensagem, id_whatsapp_conversa) {
            const contato = this.encontraContato(id_whatsapp_conversa);

            if (!contato) {
                return;
            }

            const dataHoraRecebe = ultima_mensagem.mensagem?.data_envio
                ? moment(ultima_mensagem.mensagem.data_envio).format('DD/MM/YY HH:mm')
                : '';

            const msg =
                !ultima_mensagem.mensagem?.media && ultima_mensagem?.mensagem == ''
                    ? 'Arquivo de media'
                    : ultima_mensagem?.mensagem.mensagem;

            contato.ultima_mensagem = msg;
            contato.total_mensagens = ultima_mensagem?.total ?? 0;
            contato.hora_recebe_ultima_mensagem = dataHoraRecebe;

            const index = this.contatos.findIndex((obj) => obj.id_whatsapp_conversa === id_whatsapp_conversa);

            if (index > -1) {
                const objetoRemovido = this.contatos.splice(index, 1)[0];

                this.contatos.unshift(objetoRemovido);
            }
        },
        async enviaMensagem(atendimento) {
            try {
                let url = `${API_URL.erp.whatsapp.enviaMensagem}/${this.conversa.id_whatsapp_conversa}`;

                let formData = new FormData();

                atendimento?.arquivos.map((arquivo, index) => {
                    formData.append('arquivos[][arquivo]', arquivo.arquivo);
                });

                formData.append('mensagem', atendimento.mensagem);

                const mensagemEnviada = await ApiService({
                    method: 'POST',
                    url: url,
                    data: formData,
                    mostraCarregando: false,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                    .then(async (resposta) => {
                        return resposta.data.data;
                    })
                    .catch((error) => {
                        throw new Error(error);
                    });

                if (this.conversa.id_whatsapp_conversa == atendimento.id_whatsapp_conversa) {
                    Array.from(mensagemEnviada).forEach(async (mensagem) => {
                        const mensagemMontada = {
                            id_whatsapp_mensagem: mensagem.id_whatsapp_mensagem,
                            id_whatsapp_conversa: mensagem.id_whatsapp_conversa,
                            mensagem: mensagem.mensagem,
                            falha: null,
                            media: mensagem?.media,
                            data_envio: moment(mensagem.data_envio).format('YYYY-MM-DD HH:mm:ss'),
                            data_recebe: null,
                            data_leitura: null,
                            envio_remetente: mensagem.envio_remetente == 'S' ? true : false,
                            mensagem_erro: '',
                        };

                        if (this.conversa.id_whatsapp_fluxo_conversa == atendimento.id_whatsapp_fluxo_conversa) {
                            this.adicionaMensagem(mensagemMontada);
                        }
                    });
                }

                this.notificacao = {
                    mensagem: 'Mensagem enviada.',
                    tipo: 'success',
                    mostra: true,
                };
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            } finally {
                this.carregandoMensagens = false;
            }
        },
        infiniteScrolling(entries, observer, isIntersecting) {
            if (isIntersecting && this.atual_id_whatsapp_conversa) {
                this.carregaMensagensAnteriores();
            }
        },
        async carregaMensagensAnteriores() {
            try {
                if (this.carregandoMensagens) {
                    return;
                }

                const mensagens = await this.buscaMensagens(
                    this.atual_id_whatsapp_conversa,
                    this.ultimaAnteriorIdWhatsappMensagem
                );

                this.adicionaMensagens(mensagens);
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        encontraContato(id_whatsapp_conversa) {
            let contatoAtual = this.contatos.find((conversa) => conversa.id_whatsapp_conversa === id_whatsapp_conversa);

            return contatoAtual;
        },
        selecioanrNumeroAtendimentoDisponivel({ id_whatsapp_numero_atendimento }) {
            this.idWhatsappNumeroAtendimento = id_whatsapp_numero_atendimento;

            this.modalNumeroAtendimentoDisponivel = false;

            window.Echo.channel(`whatsapp-mensagem-atendimento-${this.idWhatsappNumeroAtendimento}`).listen(
                '.WhatsappMensagem',
                (e) => {
                    this.mensagemBroadcast(e.whatsapp_mensagem);
                }
            );

            window.Echo.channel(`whatsapp-status-mensagem-atendimento-${this.idWhatsappNumeroAtendimento}`).listen(
                '.WhatsappStatusMensagem',
                (e) => {
                    this.statusMensagemBroadcast(e.whatsapp_mensagem);
                }
            );

            window.Echo.channel(`whatsapp-conversa-atendimento-${this.idWhatsappNumeroAtendimento}`).listen(
                '.WhatsappConversa',
                (e) => {
                    this.conversaBroadcast(e.whatsappConversa);
                }
            );

            this.carregaDados();
        },
        abreImagem({ imagem }) {
            this.modalImagem.imagem = imagem;
            this.modalImagem.abre = true;
        },
        fechaImagem() {
            this.modalImagem.abre = false;
            this.modalImagem.imagem = null;
        },
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success',
            },
            permissao: false,
            idWhatsappNumeroAtendimento: null,
            atual_id_whatsapp_conversa: null,
            carregandoMensagens: false,
            mensagemCarregando: 'Aguarde...',
            atendimentoWhatsapp: TipoAtendimento.ATENDIMENTO,
            tab: null,
            conversa: [],
            conversaMensagens: [],
            ultimaAnteriorIdWhatsappMensagem: null,
            contatos: [],
            modalNumeroAtendimentoDisponivel: false,
            listaNumeroAtendimentoDisponivel: [],
            modalAdicionaConversa: false,
            modalImagem: {
                abre: false,
                imagem: null,
            },
            contatoBusca: '',
        };
    },
};
</script>

<style scoped>
.bordaDireita {
    border-right: solid #d7dce0 0.1em;
}
/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(225, 162, 135);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #eb7405;
}
</style>
