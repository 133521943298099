import moment from 'moment';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { DownloadArquivoBse64 } from '@/helpers/Funcoes.js';

const state = {
    carregando: false,
    ano: moment().year(),
    anosPesquisa: [moment().year()],
    quantidadeTopGrupoClienteSelecionada: 15,
    quantidadeTopGrupoClienteLista: [10, 15, 20, 25, 30],
    quantidadeTopRotaSelecionada: 15,
    quantidadeTopRotaLista: [10, 15, 20, 25, 30],
    cor: ['#1976D2', '#C62828', '#F9A825'],
    unidadeEmpreendimento: [],
    dadosGraficoPorAno: [],
    dadosGraficoUnidadeEmpreendimento: [],
    dadosGraficoTopGrupoCliente: [],
    dadosGraficoTopRota: [],
    emphasis: {
        label: {
            show: true,
            fontSize: 12,
            fontWeight: 'bold'
        },
        itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
    }
};

const actions = {
    async buscaDashboard({ commit, state }, { ano, quantidadeTopGrupoClienteSelecionada, quantidadeTopRotaSelecionada }) {
        try {
            const anoSelecionado = ano ?? state.ano;
            const qtdTopGrupoClienteSelecionado =
                quantidadeTopGrupoClienteSelecionada ?? state.quantidadeTopGrupoClienteSelecionada;
            const qtdTopRotaSelecionado = quantidadeTopRotaSelecionada ?? state.quantidadeTopRotaSelecionada;

            commit('ano', anoSelecionado);
            commit('quantidadeTopGrupoClienteSelecionada', qtdTopGrupoClienteSelecionado);
            commit('quantidadeTopRotaSelecionada', qtdTopRotaSelecionado);
            commit('carregando', true);

            const params = {};

            params.ano = anoSelecionado;
            params.quantidade_top_cliente = qtdTopGrupoClienteSelecionado;
            params.quantidade_top_rota = qtdTopRotaSelecionado;

            await ApiService({
                method: 'get',
                url: `${API_URL.erp.dashboard.orderToDeliveryAno}`,
                params: params,
                mostraCarregando: false
            })
                .then(resposta => {
                    if (resposta.data.data) {
                        const anosPesquisa = resposta.data.data.anos_pesquisa?.map(anosPesquisa => {
                            return anosPesquisa.ano;
                        });

                        const unidadeEmpreendimento = resposta.data.data.unidade_empreendimento?.map(anosPesquisa => {
                            return anosPesquisa.codigo;
                        });

                        commit('anosPesquisa', anosPesquisa);
                        commit('unidadeEmpreendimento', unidadeEmpreendimento);
                        commit('dadosGraficoPorAno', resposta.data.data.dados.por_ano);
                        commit('dadosGraficoUnidadeEmpreendimento', resposta.data.data.dados.por_ano_unidade_empreendimento);
                        commit('dadosGraficoTopGrupoCliente', resposta.data.data.dados.top_grupo_cliente);
                        commit('dadosGraficoTopRota', resposta.data.data.dados.top_rota);
                    }
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        } catch (erro) {
            throw new Error(erro.message);
        } finally {
            commit('carregando', false);
        }
    },
    baixarImagem({ state }, graficoComponente) {
        const grafico = graficoComponente.getDataURL({
            pixelRatio: 2,
            backgroundColor: '#fff'
        });

        const nomeArquivo = `OrderToDeliveryAno-${state.ano}.png`;

        DownloadArquivoBse64(grafico, nomeArquivo);
    }
};

const mutations = {
    carregando(state, estado) {
        state.carregando = estado;
    },
    ano(state, estado) {
        state.ano = estado;
    },
    quantidadeTopGrupoClienteSelecionada(state, estado) {
        state.quantidadeTopGrupoClienteSelecionada = estado;
    },
    quantidadeTopRotaSelecionada(state, estado) {
        state.quantidadeTopRotaSelecionada = estado;
    },
    anosPesquisa(state, estado) {
        state.anosPesquisa = !estado.length ? [state.ano] : estado;
    },
    unidadeEmpreendimento(state, estado) {
        state.unidadeEmpreendimento = estado;
    },
    dadosGraficoPorAno(state, estado) {
        state.dadosGraficoPorAno = estado;
    },
    dadosGraficoUnidadeEmpreendimento(state, estado) {
        state.dadosGraficoUnidadeEmpreendimento = estado;
    },
    dadosGraficoTopGrupoCliente(state, estado) {
        state.dadosGraficoTopGrupoCliente = estado;
    },
    dadosGraficoTopRota(state, estado) {
        state.dadosGraficoTopRota = estado;
    }
};

const getters = {
    carregando: state => {
        return state.carregando;
    },
    carregandoOpcoes: state => {
        return {
            text: 'Carregando, aguarde...',
            color: '#EB7403',
            textColor: '#000',
            maskColor: 'rgba(255, 255, 255, 0.8)',
            zlevel: 0,
            fontSize: 12,
            showSpinner: true,
            spinnerRadius: 15,
            lineWidth: 5,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'sans-serif'
        };
    },
    ano: state => {
        return state.ano;
    },
    anosPesquisa: state => {
        return state.anosPesquisa;
    },
    quantidadeTopGrupoClienteSelecionada: state => {
        return state.quantidadeTopGrupoClienteSelecionada;
    },
    quantidadeTopGrupoClienteLista: state => {
        return state.quantidadeTopGrupoClienteLista;
    },
    quantidadeTopRotaSelecionada: state => {
        return state.quantidadeTopRotaSelecionada;
    },
    quantidadeTopRotaLista: state => {
        return state.quantidadeTopRotaLista;
    },
    cor: state => {
        return state.cor;
    },
    emphasis: state => {
        return state.emphasis;
    },
    unidadeEmpreendimento: state => {
        return state.unidadeEmpreendimento;
    },
    dadosGraficoPorAno: state => {
        return state.dadosGraficoPorAno;
    },
    dadosGraficoUnidadeEmpreendimento: state => {
        return state.dadosGraficoUnidadeEmpreendimento;
    },
    dadosGraficoTopGrupoCliente: state => {
        return state.dadosGraficoTopGrupoCliente;
    },
    dadosGraficoTopRota: state => {
        return state.dadosGraficoTopRota;
    }
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
};
