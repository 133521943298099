<template>
    <v-row dense>
        <v-container dense>
            <BarraNavegacaoBotao
                dense
                v-if="dadosPaginaInicial"
                :titulo="tituloModulo"
                :botoes="botoesPorForm('form-grid')"
                :referenciaComponenete="formRefsComponente"
            ></BarraNavegacaoBotao>
        </v-container>

        <AlertaMensagem dense style="width: 100%" />

        <v-card class="pa-0" dense elevation="2" outlined v-show="dadosPaginaInicial">
            <Splitpanes vertical="vertical" class="default-theme">
                <Pane :size="painelTamanho">
                    <v-col cols="12" v-if="datatable.colunas">
                        <Datatables
                            v-if="dadosPaginaInicial"
                            ref="Datatable"
                            :id_datatable="datatable.id_datatable"
                            :chave_primaria="datatable.chave_primaria"
                            :url="datatable.url"
                            :colunas="datatable.colunas"
                            @consultaRegistro="consultaRegistro"
                            @mensagemAlerta="mensagemAlerta"
                        />
                    </v-col>
                </Pane>
                <Pane :size="painelEscondidoTamanho">
                    <v-col cols="12">
                        <!--                        <PainelBotaoAbreFecha />-->

                        <component
                            v-show="dadosPaginaInicial"
                            :is="camponenteImport"
                            :ref="formRefsComponente"
                            :formComplemento="form.complemento"
                            :urlNovoRegistro="urlNovoRegistro"
                            :urlConsultaRegistro="urlConsultaRegistro"
                            :tabRefs="tabRefs"
                            :tabs="tabs"
                            :formNome="formNome"
                            :moduloNome="moduloNome"
                            :chavePrimaria="chavePrimaria"
                            @mensagemAlerta="mensagemAlerta"
                            @datatableRecarrega="datatableRecarrega"
                        />
                    </v-col>
                </Pane>
            </Splitpanes>
        </v-card>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { API_URL_BASE, API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import { Alerta } from '@/helpers/Funcoes.js';
import DatatableHelper from '@/helpers/Datatables.js';

import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';
import BarraNavegacaoBotao from '@/components/Default/BarraNavegacaoBotao.vue';
import Datatables from '@/components/Default/Datatables.vue';
import PainelBotaoAbreFecha from '@/components/Default/PainelBotaoAbreFecha.vue';
import { Splitpanes, Pane } from 'splitpanes';

export default {
    components: {
        AlertaMensagem,
        BarraNavegacaoBotao,
        Datatables,
        Splitpanes,
        Pane,
        PainelBotaoAbreFecha
    },
    props: [
        'formNome',
        'moduloNome',
        'chavePrimaria',
        'datatable',
        'formRefsComponente',
        'componenteArquivo',
        'tabs',
        'tabRefs',
        'urlNovoRegistro',
        'urlConsultaRegistro'
    ],
    mounted() {},
    beforeMount() {
        this.$store.commit('CARREGANDO', true);
    },
    watch: {
        $route(to, from) {
            this.dadosPaginaInicial = false;
            this.layoutPaginaPrincipal();
            this.carregaDadosPrincipal();
        }
    },
    async created() {
        this.carregaDadosPrincipal();
    },
    computed: {
        ...mapGetters(['botoesPorForm', 'painelTamanho', 'painelEscondidoTamanho']),
        camponenteImport() {
            let comp = this.componenteArquivo;
            return () => import(`@/views/${comp}`);
        }
    },
    methods: {
        ...mapActions(['montaBarraNavegacao']),
        async carregaDadosPrincipal() {
            try {
                let urlConfig = {
                    method: 'post',
                    url: `${API_URL_BASE}${API_URL.erp[this.moduloNome].dadosPaginaInicial}`,
                    data: { id_modulo: API_URL.erp[this.moduloNome].id_modulo }
                };

                let dados = await ApiService(urlConfig)
                    .then(resposta => {
                        this.tituloModulo = resposta.data.conteudo.dados.nome_modulo;
                        this.dadosPaginaInicial = true;

                        if (resposta.data.conteudo.complemento) {
                            this.$refs[this.formRefsComponente].atualizaFormComplemento(resposta.data.conteudo.complemento);
                        }

                        this.montaBarraNavegacao(resposta.data.conteudo.permissoes);

                        this.datatable.colunas = DatatableHelper.montaColunasDatatable(
                            resposta.data.conteudo.datatable.colunas
                        );
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (e) {
                Alerta(e.message, 'error');
            }
        },
        layoutPaginaPrincipal() {
            this.$store.commit('FORM_ACAO', 'form-grid');
            this.$store.dispatch('painelFecha');
        },
        mensagemAlerta(mensagem, tipo) {
            Alerta(mensagem, tipo);
        },
        consultaRegistro(id_registro) {
            this.$refs[this.formRefsComponente].consultaRegistro(id_registro);
        },
        datatableRecarrega() {
            this.$refs.Datatable.recarregaTabela();
        }
    },
    data() {
        return {
            abreModal: false,
            dadosPaginaInicial: false,
            tituloModulo: '',
            form: {
                complemento: {}
            }
        };
    }
};
</script>

<style scoped></style>
