<template>
    <v-app id="App">
        <router-view />

        <ConfirmacaoModal ref="confirmaAcaoModal" />
    </v-app>
</template>

<script>
import ConfirmacaoModal from '@/components/Default/ConfirmacaoModal.vue';

export default {
    name: 'App',
    components: {
        ConfirmacaoModal,
    },
    mounted() {
        this.$root.$refs.$confirmaAcaoModal = this.$refs.confirmaAcaoModal;
    },
    async created() {
        /*
        let self = this;

        await self.$store
            .dispatch('autenticacao/verificaAutenticacao')
            .then((respostaApi) => {
                if (self.$route.name == 'login' || self.$route.name == null) {
                    self.$router.push({ name: 'admin' });
                }
            })
            .catch((error) => {
                if (self.$route.name != 'login') {
                    self.$router.push({ name: 'login' });
                }
            });
        */
    },
};
</script>

<style></style>
