<template>
    <div style="height: 85vh">
        <v-card class="mt-5" elevation="3" style="height: 100%">
            <v-overlay :value="carregando.mostraCarregando">
                <CarregandoLogoEmpresa :mensagem="mensagemCarregando" />
            </v-overlay>

            <AlertaMensagem dense style="width: 100%" />

            <v-row v-if="permissao" style="height: inherit">
                <v-col cols="12" sm="4" class="pt-0 pr-0 bordaDireita" style="height: 100%">
                    <v-tabs
                        v-model="tab"
                        background-color="blue-grey lighten-5"
                        color="indigo darken-3"
                        centered
                        icons-and-text
                    >
                        <v-tabs-slider></v-tabs-slider>

                        <v-tab href="#tab-em-atendimento" class="text-caption font-weight-bold">
                            <v-badge color="green" :content="totalEmAntedimento" offset-x="7" offset-y="-8">
                                EM <br />
                                ATENDIMENTO
                            </v-badge>
                            <v-icon size="27">mdi-face-agent</v-icon>
                        </v-tab>

                        <v-tab href="#tab-aguardando-atendimento" class="text-caption font-weight-bold">
                            <v-badge color="red" :content="totalAguardandoAntedimento" offset-x="7" offset-y="-8">
                                AGUARDANDO <br />
                                ATENDIMENTO
                            </v-badge>
                            <v-icon size="27">mdi-human-queue</v-icon>
                        </v-tab>

                        <v-tab href="#tab-atendimento-automatico" class="text-caption font-weight-bold">
                            <v-badge color="blue" :content="totalAntedimentoAutomatico" offset-x="7" offset-y="-8">
                                ATENDIMENTO <br />
                                ROBÔ
                            </v-badge>
                            <v-icon size="27">mdi-human-queue</v-icon>
                        </v-tab>

                        <v-tab href="#tab-chamado-aberto" class="text-caption font-weight-bold">
                            <v-badge color="warning" :content="totalChamadoAberto" offset-x="7" offset-y="-8">
                                CHAMADO <br />
                                ABERTO
                            </v-badge>
                            <v-icon size="27">mdi-ticket-account</v-icon>
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab" style="overflow: auto; height: 100%">
                        <v-tab-item value="tab-em-atendimento">
                            <v-card>
                                <v-card-text class="pl-0 pr-0">
                                    <template v-for="(contato, index) in contatos">
                                        <contato
                                            v-if="contato.id_whatsapp_atendente == idUsuarioWhatsappAtendente"
                                            :key="`contato-em-atendimento-${contato.id_whatsapp_fluxo_conversa}`"
                                            :tipo_atendimento="atendimentoWhatsapp"
                                            :id_whatsapp_fluxo_conversa="contato.id_whatsapp_fluxo_conversa"
                                            :id_whatsapp_chamado="contato.id_whatsapp_chamado"
                                            :whatsapp_nome_profile="contato.whatsapp_nome_profile"
                                            :ultima_mensagem="contato.ultima_mensagem"
                                            :hora_recebe_ultima_mensagem="contato.hora_recebe_ultima_mensagem"
                                            :total_mensagens="contato.total_mensagens"
                                            :imagem_texto="contato.imagem_texto"
                                            :imagem_texto_cor="contato.imagem_texto_cor"
                                            :iniciar_atendimento="false"
                                            :carregando="contato.carregando"
                                            @carregaMensagens="carregaMensagens"
                                        />

                                        <v-divider
                                            v-if="index < contatos.length - 1"
                                            :key="`contato-em-atendimento${index}`"
                                        ></v-divider>
                                    </template>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item value="tab-aguardando-atendimento">
                            <v-card flat>
                                <v-card-text>
                                    <template v-for="(contato, index) in contatos">
                                        <contato
                                            v-if="
                                                contato.id_whatsapp_atendente == null && contato.fluxo_conversa_situacao == 3
                                            "
                                            :key="`contato-em-atendimento-${index}`"
                                            :tipo_atendimento="atendimentoWhatsapp"
                                            :id_whatsapp_fluxo_conversa="contato.id_whatsapp_fluxo_conversa"
                                            :whatsapp_nome_profile="contato.whatsapp_nome_profile"
                                            :ultima_mensagem="contato.ultima_mensagem"
                                            :hora_recebe_ultima_mensagem="contato.hora_recebe_ultima_mensagem"
                                            :total_mensagens="contato.total_mensagens"
                                            :imagem_texto="contato.imagem_texto"
                                            :imagem_texto_cor="contato.imagem_texto_cor"
                                            :iniciar_atendimento="true"
                                            :carregando="contato.carregando"
                                            @inicia_atendimento="iniciaAtendimento"
                                        />

                                        <v-divider v-if="index < contatos.length - 1"></v-divider>
                                    </template>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item value="tab-atendimento-automatico">
                            <v-card flat>
                                <v-card-text>
                                    <template v-for="(contato, index) in contatos">
                                        <contato
                                            v-if="
                                                contato.id_whatsapp_atendente == idWhatsappAtendenteAutomatico &&
                                                [1, 3].includes(contato.chamado_situacao)
                                            "
                                            :key="`contato-em-atendimento-${index}`"
                                            :tipo_atendimento="atendimentoWhatsapp"
                                            :id_whatsapp_fluxo_conversa="contato.id_whatsapp_fluxo_conversa"
                                            :whatsapp_nome_profile="contato.whatsapp_nome_profile"
                                            :ultima_mensagem="contato.ultima_mensagem"
                                            :hora_recebe_ultima_mensagem="contato.hora_recebe_ultima_mensagem"
                                            :total_mensagens="contato.total_mensagens"
                                            :imagem_texto="contato.imagem_texto"
                                            :imagem_texto_cor="contato.imagem_texto_cor"
                                            :carregando="contato.carregando"
                                            @carregaMensagens="carregaMensagens"
                                        />

                                        <v-divider v-if="index < contatos.length - 1"></v-divider>
                                    </template>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item value="tab-chamado-aberto">
                            <v-card v-if="!carregandoChamado" flat>
                                <v-card-text class="pl-0 pr-0">
                                    <template v-for="(chamado, index) in chamados">
                                        <contato
                                            v-if="chamado.id_whatsapp_atendente == idUsuarioWhatsappAtendente"
                                            :key="`chamado-em-atendimento-${chamado.id_whatsapp_fluxo_conversa}`"
                                            :tipo_atendimento="atendimentoWhatsapp"
                                            :id_whatsapp_fluxo_conversa="chamado.id_whatsapp_fluxo_conversa"
                                            :id_whatsapp_chamado="chamado.id_whatsapp_chamado"
                                            :whatsapp_nome_profile="chamado.whatsapp_nome_profile"
                                            :ultima_mensagem="chamado.ultima_mensagem"
                                            :hora_recebe_ultima_mensagem="chamado.hora_recebe_ultima_mensagem"
                                            :total_mensagens="chamado.total_mensagens"
                                            :imagem_texto="chamado.imagem_texto"
                                            :imagem_texto_cor="chamado.imagem_texto_cor"
                                            :iniciar_atendimento="false"
                                            :carregando="chamado.carregando"
                                            @carregaMensagens="carregaMensagens"
                                        />

                                        <v-divider v-if="index < chamados.length - 1"></v-divider>
                                    </template>
                                </v-card-text>
                            </v-card>

                            <span v-else> Carregando chamados em aberto </span>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>

                <v-col cols="12" sm="8" class="pt-0 pl-0 pb-0" style="height: inherit">
                    <atendimento-conversa
                        v-if="atual_id_whatsapp_fluxo_conversa"
                        :tipo_atendimento="atendimentoWhatsapp"
                        :id_whatsapp_fluxo_conversa="conversa.id_whatsapp_fluxo_conversa"
                        :id_whatsapp_atendente="conversa.id_whatsapp_atendente"
                        :id_whatsapp_chamado="conversa.id_whatsapp_chamado"
                        :whatsapp_nome_profile="conversa.whatsapp_nome_profile"
                        :nome_completo="conversa.nome_completo"
                        :telefone_envio="conversa.telefone_envio"
                        :avatar="conversa.avatar"
                        :mensagens="conversaMensagens"
                        :carregando="carregandoMensagens"
                        :setor_texto="conversa.setor_texto"
                        :setor_texto_cor="conversa.setor_texto_cor"
                        :desabilita_finaliza_atendimento="desabilita_finaliza_atendimento"
                        :desabilita_transferir_atendimento="desabilita_transferir_atendimento"
                        :desabilita_enviar_mensagem="desabilita_enviar_mensagem"
                        @transferir_atendimento="transferirAtendimento"
                        @finalizar_atendimento="finalizarAtendimentoModal"
                        @envia_mensagem="enviaMensagem"
                        @carrega-mensagens-anteriores="carregaMensagensAnteriores"
                        @abre-imagem="abreImagem"
                    />

                    <informacoes v-else />
                </v-col>
            </v-row>

            <FinalizaModal ref="finalizaModal" @finalizar="finalizarAtendimento" />

            <v-dialog v-model="modalNumeroAtendimentoDisponivel" persistent>
                <v-card class="mx-auto" outlined>
                    <v-card-title class="text-h5"> Número de Atendimento Disponível </v-card-title>

                    <v-card-text>
                        <v-row>
                            <v-col
                                cols="12"
                                md="4"
                                v-for="(item, index) in listaNumeroAtendimentoDisponivel"
                                :key="`numero-disponivel-${index}`"
                            >
                                <numero-atendimento-disponivel
                                    ref="modalNumeroAtendimentoDisponivel"
                                    :id_whatsapp_numero_atendimento="item.id_whatsapp_numero_atendimento"
                                    :descricao="item.descricao"
                                    :observacao="item.observacao"
                                    :numero_telefone="item.numero_telefone"
                                    @cancelar="modalNumeroAtendimentoDisponivel = false"
                                    @selecionar="selecioanrNumeroAtendimentoDisponivel"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                {{ notificacao.mensagem }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false"> Fechar </v-btn>
                </template>
            </v-snackbar>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import moment from 'moment';

import { Alerta } from '@/helpers/Funcoes.js';

import TipoAtendimento from '../Enum/TipoAtendimento';

import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import Conversa from './Conversa.vue';
import Informacoes from './Informacoes.vue';
import Contato from './Contato.vue';
import AtendimentoConversa from './AtendimentoConversa.vue';
import FinalizaModal from './FinalizaModal.vue';
import NumeroAtendimentoDisponivel from './NumeroAtendimentoDisponivel.vue';

export default {
    name: 'AtendimentoFluxo',
    components: {
        AlertaMensagem,
        CarregandoLogoEmpresa,
        Conversa,
        Informacoes,
        Contato,
        AtendimentoConversa,
        FinalizaModal,
        NumeroAtendimentoDisponivel,
    },
    async mounted() {
        try {
            this.verificaNotificacoesHabilitadas();

            await this.carregaAtendimentos();
        } catch (erro) {
            this.notificacao = {
                mensagem: erro.message,
                tipo: 'error',
                mostra: true,
            };
        }
    },
    watch: {},
    computed: {
        ...mapState(['carregando']),
        ...mapGetters({
            id_usuario: 'autenticacao/getIdUsuario',
        }),
        totalEmAntedimento() {
            let total = 0;

            this.contatos.map((item) => {
                if (item.id_whatsapp_atendente == this.idUsuarioWhatsappAtendente) {
                    total += 1;
                }
            });

            return total.toString();
        },
        totalAguardandoAntedimento() {
            let total = 0;

            this.contatos.map((item) => {
                if (item.id_whatsapp_atendente == null) {
                    total += 1;
                }
            });

            return total.toString();
        },
        totalAntedimentoAutomatico() {
            let total = 0;

            this.contatos.map((item) => {
                if (
                    item.id_whatsapp_atendente == this.idWhatsappAtendenteAutomatico &&
                    [1, 3].includes(item.chamado_situacao)
                ) {
                    total += 1;
                }
            });

            return total.toString();
        },
        totalChamadoAberto() {
            let total = 0;

            this.chamados.map((item) => {
                total += 1;
            });

            return total.toString();
        },
    },
    methods: {
        ...mapActions(['mostraCarregando']),
        ...mapActions('notificacaoBrowser', ['notificacaoBrowser', 'browserNotificacaoHabilitado']),
        async verificaNotificacoesHabilitadas() {
            try {
                await this.browserNotificacaoHabilitado();

                this.notificacao = {
                    mensagem: 'Você habilitou as notificações e irá receber alerta quando houver nova mensagem',
                    tipo: 'success',
                    mostra: true,
                };
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        async carregaAtendimentos() {
            try {
                this.mostraCarregando(true);

                this.mensagemCarregando = 'Carregando lista de números disponíveis, aguarde...';

                await this.carregaNumeroAtendimentoDisponivel();

                this.permissao = true;
            } catch (erro) {
                Alerta([erro.message], 'error');

                this.permissao = false;

                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            } finally {
                this.mostraCarregando(false);
            }
        },
        async carregaDados() {
            try {
                if (!this.idWhatsappNumeroAtendimento) {
                    throw new Error('Nenhum número de atendimento selecionado para carregar os dados');
                }

                this.mostraCarregando(true);

                await this.carregaPerfilAtendente();

                this.carregaContatos();
                this.carregaChamadosAbertos();
            } catch (erro) {
                Alerta([erro.message], 'error');

                this.permissao = false;

                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            } finally {
                this.mostraCarregando(false);
            }
        },
        async carregaNumeroAtendimentoDisponivel() {
            try {
                await ApiService({
                    method: 'get',
                    url: `${API_URL.erp.whatsapp.numeroAtendimentoDisponivel}`,
                    params: {
                        fluxo_atendimento: 'S',
                    },
                    mostraCarregando: false,
                })
                    .then(async (resposta) => {
                        if (!resposta.data.data?.length) {
                            throw new Error('Nenhum número de atendimento está disponível no seu perfil');
                        }

                        this.listaNumeroAtendimentoDisponivel = resposta.data.data.map((item) => {
                            return {
                                id_whatsapp_numero_atendimento: item.id_whatsapp_numero_atendimento,
                                descricao: item.descricao,
                                observacao: item.observacao,
                                numero_telefone: item.telefone_atendimento,
                            };
                        });

                        this.modalNumeroAtendimentoDisponivel = true;
                    })
                    .catch((error) => {
                        throw new Error(error);
                    });
            } catch (erro) {
                throw new Error(erro.message);
            }
        },
        async carregaPerfilAtendente() {
            try {
                this.mostraCarregando(true);

                this.mensagemCarregando = 'Configurando perfil atendente.';

                const perfil = await ApiService({
                    method: 'get',
                    url: `${API_URL.erp.whatsapp.perfilAtendente}`,
                    data: {},
                    mostraCarregando: false,
                })
                    .then(async (resposta) => {
                        return resposta.data.data;
                    })
                    .catch((error) => {
                        throw new Error(error);
                    });

                this.idUsuarioWhatsappAtendente = perfil.id_whatsapp_atendente;
            } catch (erro) {
                throw new Error(erro.message);
            } finally {
                this.carregandoMensagens = false;
            }
        },
        async carregaContatos() {
            try {
                this.mostraCarregando(true);

                this.mensagemCarregando = 'Carregando lista de atendimentos.';

                const contatos = await ApiService({
                    method: 'get',
                    url: `${API_URL.erp.whatsapp.contatoAtendimentoFluxo}/${this.idWhatsappNumeroAtendimento}`,
                    data: {},
                    mostraCarregando: false,
                })
                    .then(async (resposta) => {
                        return resposta.data.data;
                    })
                    .catch((error) => {
                        throw new Error(error);
                    });

                this.preencheContato(contatos, TipoAtendimento.FLUXO);
            } catch (erro) {
                throw new Error(erro.message);
            } finally {
                this.carregandoMensagens = false;
            }
        },
        async carregaChamadosAbertos() {
            try {
                this.carregandoChamado = true;

                const chamadosAbertos = await ApiService({
                    method: 'get',
                    url: `${API_URL.erp.whatsapp.chamadoAtendimento}/${this.idWhatsappNumeroAtendimento}`,
                    data: {},
                    mostraCarregando: false,
                })
                    .then(async (resposta) => {
                        return resposta.data.data;
                    })
                    .catch((error) => {
                        throw new Error(error);
                    });

                this.preencheContato(chamadosAbertos, TipoAtendimento.CHAMADO);
            } catch (erro) {
                throw new Error(erro.message);
            } finally {
                this.carregandoChamado = false;
            }
        },
        preencheContato(contatos, tipo_atendimento) {
            contatos.map((contato) => {
                this.adicionaContato(contato, tipo_atendimento);
            });
        },
        adicionaContato(contato, tipo_atendimento) {
            const contatosPermitidos = [this.idUsuarioWhatsappAtendente, this.idWhatsappAtendenteAutomatico];

            if (contato.id_whatsapp_atendente != null && !contatosPermitidos.includes(contato.id_whatsapp_atendente)) {
                this.removeContato(contato.id_whatsapp_fluxo_conversa);

                return;
            }

            const msg =
                !contato.mensagem?.media && contato.mensagem.mensagem == '' ? 'Arquivo de media' : contato.mensagem.mensagem;

            const corDepartamento = this.selecionaCorDepartamento(contato.departamento);

            const dados = {
                id_whatsapp_fluxo_conversa: contato.id_whatsapp_fluxo_conversa,
                id_whatsapp_chamado: contato.id_whatsapp_chamado,
                id_whatsapp_conversa: contato.id_whatsapp_conversa,
                id_whatsapp_atendente: contato.id_whatsapp_atendente,
                tipo_atendimento: tipo_atendimento,
                fluxo_conversa_situacao: contato.fluxo_conversa_situacao,
                chamado_situacao: contato.chamado_situacao,
                whatsapp_nome_profile: contato.whatsapp_nome_profile,
                nome_completo: contato.nome_completo,
                telefone_envio: contato.telefone_envio,
                ultima_mensagem: msg,
                hora_recebe_ultima_mensagem: moment(contato.mensagem.data_envio).format('DD/MM/YY HH:mm'),
                total_mensagens: contato.mensagem.total ?? 0,
                imagem_texto: contato.departamento,
                imagem_texto_cor: corDepartamento,
                carregando: false,
            };

            if (tipo_atendimento == TipoAtendimento.FLUXO) {
                this.contatos.unshift(dados);
            }

            if (tipo_atendimento == TipoAtendimento.CHAMADO) {
                this.chamados.unshift(dados);
            }
        },
        selecionaCorDepartamento(departamento) {
            const corSelecionada = this.cores.find((cor) => cor.departamento === departamento);

            if (!corSelecionada) {
                return 'blue-grey lighten-1';
            }

            return corSelecionada.cor;
        },
        async carregaMensagens({ id_whatsapp_fluxo_conversa }) {
            try {
                const conversa = this.encontraContato(id_whatsapp_fluxo_conversa);

                if (!conversa) {
                    throw new Error('Conversa não encontrada.');
                }

                if (conversa.carregando) {
                    return;
                }

                this.desabilita_finaliza_atendimento = false;
                this.desabilita_transferir_atendimento = false;
                this.desabilita_enviar_mensagem = false;

                if (conversa.id_whatsapp_atendente == this.idWhatsappAtendenteAutomatico) {
                    this.desabilita_finaliza_atendimento = true;
                    this.desabilita_transferir_atendimento = true;
                    this.desabilita_enviar_mensagem = true;
                }

                this.limpaMensagens();

                this.conversa = {
                    id_whatsapp_fluxo_conversa: conversa.id_whatsapp_fluxo_conversa,
                    id_whatsapp_conversa: conversa.id_whatsapp_conversa,
                    id_whatsapp_chamado: conversa.id_whatsapp_chamado,
                    whatsapp_nome_profile: conversa.whatsapp_nome_profile,
                    nome_completo: conversa.nome_completo,
                    telefone_envio: conversa.telefone_envio,
                    avatar: null,
                    setor_texto: conversa.imagem_texto,
                    setor_texto_cor: conversa.imagem_texto_cor,
                    carregando: true,
                    tipo_atendimento: conversa.tipo_atendimento,
                };

                this.atual_id_whatsapp_fluxo_conversa = id_whatsapp_fluxo_conversa;

                const mensagens = await this.buscaMensagens(id_whatsapp_fluxo_conversa);

                this.adicionaMensagens(mensagens);

                this.marcaConversaVisualizada(conversa);
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            } finally {
            }
        },
        async buscaMensagens(id_whatsapp_fluxo_conversa, ultima_anterior_id_whatsapp_mensagem) {
            try {
                this.carregandoMensagens = true;

                const parametros = {};

                if (!ultima_anterior_id_whatsapp_mensagem) {
                    this.limpaMensagens();
                }

                if (ultima_anterior_id_whatsapp_mensagem) {
                    parametros.ultima_id_whatsapp_mensagem = ultima_anterior_id_whatsapp_mensagem;
                }

                let url = `${API_URL.erp.whatsapp.fluxoConversaMensagens}`.replace(
                    '${id_whatsapp_numero_atendimento}',
                    this.idWhatsappNumeroAtendimento
                );

                url = url.replace('${id_whatsapp_fluxo_conversa}', id_whatsapp_fluxo_conversa);

                const mensagens = await ApiService({
                    method: 'GET',
                    url: url,
                    mostraCarregando: false,
                    params: parametros,
                })
                    .then((resposta) => {
                        return resposta?.data?.data ?? [];
                    })
                    .catch((erro) => {
                        throw new Error(erro.message);
                    });

                return mensagens;
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };

                throw new Error(erro.message);
            } finally {
                this.carregandoMensagens = false;
            }
        },
        marcaConversaVisualizada(conversa) {
            try {
                ApiService({
                    method: 'PUT',
                    url: `${API_URL.erp.whatsapp.conversaVisualizada}/${conversa.id_whatsapp_conversa}`,
                    mostraCarregando: false,
                })
                    .then((resposta) => {
                        // LIMPA ULTIMA TOTAL DE MENSAGENS DO CONTATO, POIS FORAM VISUALIZADAS
                        conversa.total_mensagens = 0;

                        return true;
                    })
                    .catch((erro) => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        mensagemBroadcast(mensagem_broadcast) {
            try {
                if (!mensagem_broadcast?.atributos) {
                    return;
                }

                mensagem_broadcast = mensagem_broadcast?.atributos;

                let id_whatsapp_mensagem = mensagem_broadcast.id_whatsapp_mensagem;
                let id_whatsapp_conversa = mensagem_broadcast.id_whatsapp_conversa;
                const id_whatsapp_fluxo_conversa = mensagem_broadcast.id_whatsapp_fluxo_conversa;

                const contatoAtual = this.encontraContato(id_whatsapp_fluxo_conversa);

                if (!contatoAtual) {
                    return;
                }

                this.notificacaoBrowser({
                    titulo: `Whatsapp - ${contatoAtual.whatsapp_nome_profile}`,
                    mensagem: mensagem_broadcast.mensagem,
                    tag: id_whatsapp_mensagem,
                });

                /**
                 * ATUALIZA A ÚLTIMA MENSAGEM ENVIADA NO CONTATO
                 */
                if (this.conversa.id_whatsapp_fluxo_conversa != id_whatsapp_fluxo_conversa) {
                    const msg = {
                        id_whatsapp_mensagem: id_whatsapp_mensagem,
                        id_whatsapp_conversa: id_whatsapp_conversa,
                        mensagem: mensagem_broadcast.mensagem,
                        total: mensagem_broadcast.total,
                    };

                    this.atualizaContatoUltimaMensagem(msg, id_whatsapp_fluxo_conversa);

                    return;
                }

                const envio_remetente = mensagem_broadcast.envio_remetente == 'S' ? true : false;

                const mensagemNova = {
                    id_whatsapp_mensagem: mensagem_broadcast.id_whatsapp_mensagem,
                    id_whatsapp_conversa: mensagem_broadcast.id_whatsapp_conversa,
                    mensagem: mensagem_broadcast.mensagem,
                    data_envio: mensagem_broadcast.data_envio,
                    data_recebe: mensagem_broadcast.data_recebe,
                    data_leitura: mensagem_broadcast.data_leitura,
                    envio_remetente: envio_remetente,
                    media: mensagem_broadcast.media,
                };

                this.adicionaMensagem(mensagemNova);
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        statusMensagemBroadcast(mensagem_broadcast) {
            try {
                if (!mensagem_broadcast?.atributos) {
                    return;
                }

                const id_whatsapp_fluxo_conversa = mensagem_broadcast.atributos.id_whatsapp_fluxo_conversa;
                const id_whatsapp_mensagem = mensagem_broadcast.atributos.id_whatsapp_mensagem;

                const contatoAtual = this.encontraContato(id_whatsapp_fluxo_conversa);

                if (!contatoAtual) {
                    return;
                }

                const msg = {
                    id_whatsapp_fluxo_conversa: mensagem_broadcast.atributos.id_whatsapp_fluxo_conversa,
                    id_whatsapp_mensagem: mensagem_broadcast.atributos.id_whatsapp_mensagem,
                    id_whatsapp_conversa: mensagem_broadcast.atributos.id_whatsapp_conversa,
                    id_whatsapp_atendente: mensagem_broadcast.atributos.id_whatsapp_atendente,
                    fluxo_conversa_situacao: mensagem_broadcast.atributos.fluxo_conversa_situacao,
                    chamado_situacao: mensagem_broadcast.atributos.chamado_situacao,
                    mensagem: mensagem_broadcast.atributos.mensagem,
                    total: mensagem_broadcast.atributos?.total,
                };

                const mensagemAtual = this.conversaMensagens.find(
                    (mensagem) => mensagem.id_whatsapp_mensagem === id_whatsapp_mensagem
                );

                if (mensagemAtual) {
                    mensagemAtual.data_envio = mensagem_broadcast.atributos?.data_envio;
                    mensagemAtual.data_recebe = mensagem_broadcast.atributos?.data_recebe;
                    mensagemAtual.data_leitura = mensagem_broadcast.atributos?.data_leitura;
                    mensagemAtual.mensagem_erro = mensagem_broadcast.atributos.falha?.erro ?? '';
                }

                this.atualizaContatoUltimaMensagem(msg, id_whatsapp_fluxo_conversa);

                return;
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        conversaBroadcast(contato_broadcast) {
            try {
                if (!contato_broadcast?.atributos) {
                    return;
                }

                const contatoBroadcast = contato_broadcast.atributos;

                let id_whatsapp_fluxo_conversa = contatoBroadcast.id_whatsapp_fluxo_conversa;
                let tipo_atendimento =
                    contatoBroadcast.fluxo_conversa_situacao != 2 ? TipoAtendimento.FLUXO : TipoAtendimento.CHAMADO;

                const contatoAtual = this.encontraContato(id_whatsapp_fluxo_conversa);

                if (!contatoAtual) {
                    const conversaNova = {
                        id_whatsapp_fluxo_conversa: contatoBroadcast.id_whatsapp_fluxo_conversa,
                        id_whatsapp_chamado: contatoBroadcast.id_whatsapp_chamado,
                        id_whatsapp_conversa: contatoBroadcast.id_whatsapp_conversa,
                        id_whatsapp_atendente: contatoBroadcast.id_whatsapp_atendente,
                        fluxo_conversa_situacao: contatoBroadcast.fluxo_conversa_situacao,
                        chamado_situacao: contatoBroadcast.chamado_situacao,
                        tipo_atendimento: contatoBroadcast.tipo_atendimento,
                        whatsapp_nome_profile: contatoBroadcast.whatsapp_nome_profile,
                        nome_completo: contatoBroadcast.nome_completo,
                        telefone_envio: contatoBroadcast.telefone_envio,
                        departamento: contatoBroadcast.departamento,
                        mensagem: contatoBroadcast.mensagem,
                    };

                    this.adicionaContato(conversaNova, tipo_atendimento);

                    return;
                }

                if (
                    contatoBroadcast.id_whatsapp_atendente != null &&
                    this.idUsuarioWhatsappAtendente != contatoBroadcast.id_whatsapp_atendente &&
                    contatoBroadcast.id_whatsapp_atendente != this.idWhatsappAtendenteAutomatico
                ) {
                    this.removeContato(contatoBroadcast.id_whatsapp_fluxo_conversa);

                    return;
                }

                if (contatoAtual) {
                    contatoAtual.total_mensagens = contatoBroadcast.mensagem.total;
                }
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        limpaMensagens() {
            this.conversaMensagens = [];
        },
        adicionaMensagens(mensagens) {
            mensagens.forEach((mensagem) => {
                const envio_remetente = mensagem.envio_remetente == 'S' ? true : false;

                this.adicionaMensagem(
                    {
                        id_whatsapp_mensagem: mensagem.id_whatsapp_mensagem,
                        id_whatsapp_conversa: mensagem.id_whatsapp_conversa,
                        mensagem: mensagem.mensagem,
                        falha: mensagem.falha,
                        data_envio: mensagem.data_envio,
                        data_recebe: mensagem.data_recebe,
                        data_leitura: mensagem.data_leitura,
                        envio_remetente: envio_remetente,
                        mensagem_erro: mensagem.falha?.erro ?? '',
                        media: mensagem.media,
                    },
                    true
                );
            });
        },
        adicionaMensagem(mensagem, anterior) {
            const msg = {
                id_whatsapp_mensagem: mensagem.id_whatsapp_mensagem,
                id_whatsapp_conversa: mensagem.id_whatsapp_conversa,
                mensagem: mensagem.mensagem,
                data_envio: mensagem.data_envio,
                data_recebe: mensagem?.data_recebe,
                data_leitura: mensagem?.data_leitura,
                envio_remetente: mensagem.envio_remetente,
                mensagem_erro: mensagem.mensagem_erro ?? '',
                media: mensagem?.media ?? null,
            };

            this.ultimaAnteriorIdWhatsappMensagem = msg.id_whatsapp_mensagem;

            if (anterior) {
                this.conversaMensagens.unshift(msg);

                return;
            }

            this.conversaMensagens.push(msg);

            this.atualizaContatoUltimaMensagem(msg, this.atual_id_whatsapp_fluxo_conversa);
        },
        atualizaContatoUltimaMensagem(ultima_mensagem, id_whatsapp_fluxo_conversa) {
            const contato = this.encontraContato(id_whatsapp_fluxo_conversa);

            if (!contato) {
                return;
            }

            const msg =
                !ultima_mensagem.mensagem?.media && ultima_mensagem.mensagem == ''
                    ? 'Arquivo de media'
                    : ultima_mensagem.mensagem;

            contato.ultima_mensagem = msg;
            contato.total_mensagens = ultima_mensagem?.total ?? 0;

            if (ultima_mensagem?.fluxo_conversa_situacao) {
                contato.id_whatsapp_atendente = ultima_mensagem.id_whatsapp_atendente;
                contato.fluxo_conversa_situacao = ultima_mensagem.fluxo_conversa_situacao;
                contato.chamado_situacao = ultima_mensagem.chamado_situacao;
            }
        },
        async iniciaAtendimento(id_whatsapp_fluxo_conversa) {
            try {
                const conversa = this.contatos.find(
                    (contato) => contato.id_whatsapp_fluxo_conversa === id_whatsapp_fluxo_conversa
                );

                if (!conversa) {
                    throw new Error('Conversa não encontrada para iniciar.');
                }

                conversa.carregando = true;

                await ApiService({
                    method: 'PUT',
                    url: `${API_URL.erp.whatsapp.iniciaAtendimento}/${conversa.id_whatsapp_fluxo_conversa}`,
                    data: {},
                    mostraCarregando: false,
                })
                    .then(async (resposta) => {
                        conversa.id_whatsapp_atendente = resposta.data.data.id_whatsapp_atendente;
                    })
                    .catch((error) => {
                        conversa.carregando = false;

                        throw new Error(error);
                    });

                conversa.carregando = false;
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        async transferirAtendimento(atendimento) {
            try {
                const conversa = this.encontraContato(atendimento.id_whatsapp_fluxo_conversa);

                if (!conversa) {
                    throw new Error('Conversa não encontrada para enviar mensagem.');
                }

                conversa.carregando = true;

                let url = `${API_URL.erp.whatsapp.transferenciaAtendimento}`.replace(
                    '{id_whatsapp_fluxo_conversa}',
                    conversa.id_whatsapp_fluxo_conversa
                );

                url = url.replace('{id_whatsapp_atendente}', atendimento.id_whatsapp_atendente);

                await ApiService({
                    method: 'PUT',
                    url: url,
                    data: {},
                    mostraCarregando: false,
                })
                    .then(async (resposta) => {
                        this.atual_id_whatsapp_fluxo_conversa = null;
                        this.limpaMensagens();
                        this.removeContato(atendimento.id_whatsapp_fluxo_conversa);
                    })
                    .catch((error) => {
                        conversa.carregando = false;

                        throw new Error(error);
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        finalizarAtendimentoModal(atendimento) {
            const conversa = this.encontraContato(atendimento.id_whatsapp_fluxo_conversa);

            this.$refs.finalizaModal.abreFechaModal = true;
            this.$refs.finalizaModal.id_whatsapp_fluxo_conversa = conversa.id_whatsapp_fluxo_conversa;
            this.$refs.finalizaModal.tipo_atendimento = conversa.tipo_atendimento;
        },
        async finalizarAtendimento(atendimento) {
            try {
                let conversa = this.contatos.find(
                    (contato) => contato.id_whatsapp_fluxo_conversa === atendimento.id_whatsapp_fluxo_conversa
                );

                if (!conversa) {
                    conversa = this.chamados.find(
                        (chamado) => chamado.id_whatsapp_fluxo_conversa === atendimento.id_whatsapp_fluxo_conversa
                    );

                    if (!conversa) {
                        throw new Error('Conversa não encontrada para enviar mensagem.');
                    }
                }

                conversa.carregando = true;

                let url = `${API_URL.erp.whatsapp.finalizaAtendimento}`.replace(
                    '{id_whatsapp_fluxo_conversa}',
                    conversa.id_whatsapp_fluxo_conversa
                );

                await ApiService({
                    method: 'PUT',
                    url: url,
                    data: {
                        finaliza_chamado: atendimento.finaliza_chamado ? 'S' : 'N',
                    },
                    mostraCarregando: false,
                })
                    .then(async (resposta) => {
                        this.atual_id_whatsapp_fluxo_conversa = null;
                        this.limpaMensagens();
                        this.removeContato(atendimento.id_whatsapp_fluxo_conversa);
                    })
                    .catch((error) => {
                        conversa.carregando = false;

                        throw new Error(error);
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        removeContato(id_whatsapp_fluxo_conversa) {
            const contatosFiltrados = this.contatos.filter((contato) => {
                if (contato.id_whatsapp_fluxo_conversa !== id_whatsapp_fluxo_conversa) {
                    return contato;
                }
            });

            this.contatos = contatosFiltrados;

            const chamadosFiltrados = this.chamados.filter((contato) => {
                if (contato.id_whatsapp_fluxo_conversa !== id_whatsapp_fluxo_conversa) {
                    return contato;
                }
            });

            this.chamados = chamadosFiltrados;
        },
        async enviaMensagem(atendimento) {
            try {
                let url = `${API_URL.erp.whatsapp.mensagemAtendimento}`.replace(
                    '{id_whatsapp_fluxo_conversa}',
                    this.conversa.id_whatsapp_fluxo_conversa
                );

                url = url.replace('{id_whatsapp_numero_atendimento}', this.idWhatsappNumeroAtendimento);

                let formData = new FormData();

                atendimento?.arquivos.map((arquivo, index) => {
                    formData.append('arquivos[][arquivo]', arquivo.arquivo);
                });

                formData.append('mensagem', atendimento.mensagem);
                formData.append('tipo_atendimento', this.conversa.tipo_atendimento);

                const mensagemEnviada = await ApiService({
                    method: 'POST',
                    url: url,
                    data: formData,
                    mostraCarregando: false,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                    .then(async (resposta) => {
                        return resposta.data.data;
                    })
                    .catch((error) => {
                        throw new Error(error);
                    });

                Array.from(mensagemEnviada).forEach(async (mensagem) => {
                    const mensagemMontada = {
                        id_whatsapp_mensagem: mensagem.id_whatsapp_mensagem,
                        id_whatsapp_conversa: mensagem.id_whatsapp_conversa,
                        mensagem: mensagem.mensagem,
                        falha: null,
                        media: mensagem?.media,
                        data_envio: moment(mensagem.data_envio).format('YYYY-MM-DD HH:mm:ss'),
                        data_recebe: null,
                        data_leitura: null,
                        envio_remetente: mensagem.envio_remetente == 'S' ? true : false,
                        mensagem_erro: '',
                    };

                    if (this.conversa.id_whatsapp_fluxo_conversa == atendimento.id_whatsapp_fluxo_conversa) {
                        this.adicionaMensagem(mensagemMontada);
                    }
                });

                this.notificacao = {
                    mensagem: 'Mensagem enviada.',
                    tipo: 'success',
                    mostra: true,
                };
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            } finally {
                this.carregandoMensagens = false;
            }
        },
        infiniteScrolling(entries, observer, isIntersecting) {
            if (isIntersecting && this.atual_id_whatsapp_fluxo_conversa) {
                this.carregaMensagensAnteriores();
            }
        },
        async carregaMensagensAnteriores() {
            try {
                if (this.carregandoMensagens) {
                    return;
                }

                const mensagens = this.buscaMensagens(
                    this.atual_id_whatsapp_fluxo_conversa,
                    this.ultimaAnteriorIdWhatsappMensagem
                );

                this.adicionaMensagens(mensagens);
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        encontraContato(id_whatsapp_fluxo_conversa) {
            let contatoAtual = this.contatos.find(
                (conversa) => conversa.id_whatsapp_fluxo_conversa === id_whatsapp_fluxo_conversa
            );

            if (!contatoAtual) {
                contatoAtual = this.chamados.find(
                    (chamado) => chamado.id_whatsapp_fluxo_conversa === id_whatsapp_fluxo_conversa
                );
            }

            return contatoAtual;
        },
        selecioanrNumeroAtendimentoDisponivel({ id_whatsapp_numero_atendimento }) {
            this.idWhatsappNumeroAtendimento = id_whatsapp_numero_atendimento;

            this.modalNumeroAtendimentoDisponivel = false;

            window.Echo.channel(`whatsapp-mensagem-atendimento-${this.idWhatsappNumeroAtendimento}`).listen(
                '.WhatsappMensagem',
                (e) => {
                    this.mensagemBroadcast(e.whatsapp_mensagem);
                }
            );

            window.Echo.channel(`whatsapp-status-mensagem-atendimento-${this.idWhatsappNumeroAtendimento}`).listen(
                '.WhatsappStatusMensagem',
                (e) => {
                    this.statusMensagemBroadcast(e.whatsapp_mensagem);
                }
            );

            window.Echo.channel(`whatsapp-fluxo-conversa-${this.idWhatsappNumeroAtendimento}`).listen(
                '.WhatsappFluxoConversa',
                (e) => {
                    this.conversaBroadcast(e.whatsapp_fluxo_conversa);
                }
            );

            this.carregaDados();
        },
        abreImagem({ imagem }) {
            this.modalImagem.imagem = imagem;
            this.modalImagem.abre = true;
        },
        fechaImagem() {
            this.modalImagem.abre = false;
            this.modalImagem.imagem = null;
        },
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success',
            },
            permissao: false,
            idUsuarioWhatsappAtendente: null,
            idWhatsappNumeroAtendimento: null,
            idWhatsappAtendenteAutomatico: 23,
            atual_id_whatsapp_fluxo_conversa: null,
            carregandoMensagens: false,
            mensagemCarregando: 'Aguarde...',
            carregandoChamado: false,
            atendimentoWhatsapp: TipoAtendimento.FLUXO,
            tab: null,
            conversa: [],
            conversaMensagens: [],
            ultimaAnteriorIdWhatsappMensagem: null,
            desabilita_finaliza_atendimento: false,
            desabilita_transferir_atendimento: false,
            desabilita_enviar_mensagem: false,
            modalNumeroAtendimentoDisponivel: false,
            listaNumeroAtendimentoDisponivel: [],
            contatos: [],
            chamados: [],
            cores: [
                {
                    departamento: 'BOT',
                    cor: 'red lighten-1',
                },
                {
                    departamento: 'T.I',
                    cor: 'warning lighten-1',
                },
                {
                    departamento: 'COMERCIAL',
                    cor: 'deep-orange lighten-1',
                },
                {
                    departamento: 'CCO',
                    cor: 'green lighten-1',
                },
            ],
        };
    },
};
</script>

<style scoped>
.bordaDireita {
    border-right: solid #d7dce0 0.1em;
}
/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(225, 162, 135);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #eb7405;
}
</style>
