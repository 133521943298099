import { API_URL_BASE, API_URL } from '../../../config/configs'
import ApiService from '../../../services/ApiService';

const state = {}

const actions = {}

const mutations = {}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
