<template>
    <v-container fluid>
        <CarregandoLogoEmpresa class="mt-10" :carregando="carregandoDados" :mensagem="carregandoDadosMensagem" />

        <span v-if="!carregandoDados">
            <v-row>
                <v-col cols="12" md="12" class="pl-0 pr-0">
                    <v-card color="blue-grey lighten-5">
                        <v-toolbar color="yellow darken-3">
                            <v-toolbar-title style="width: 100%;"
                            >
                                <span class="white--text">
                                    <v-icon left dark large>
                                        mdi-truck-check-outline
                                    </v-icon>

                                    PARADA DE ESPERA / PARADA
                                </span>

                                <v-chip class="ma-2 font-weight-bold" color="blue-grey lighten-4 white--black" label>
                                    {{ totalDeJornadas }}
                                    <v-icon right>
                                        mdi-card-account-details
                                    </v-icon>
                                </v-chip>
                            </v-toolbar-title>

                            <v-card right class="d-flex align-center">
                                <div class="px-3">
                                    Transição
                                </div>
                                <div>
                                    <v-btn @click="ativarEDesativartransicaoDasTabsDeJornada()"  dark tile>
                                        <div v-if="!transicaoDasTabsDeJornada">
                                            Iniciar
                                            <v-icon>
                                                play_arrow
                                            </v-icon>
                                        </div>
                                        
                                        <div v-else>
                                            Pausar
                                            <v-icon>
                                                pause
                                            </v-icon>
                                        </div>
                                    </v-btn>
                                </div>
                            </v-card>
                        </v-toolbar>

                        <template>
                            <v-card min-height="890px" elevation="0" class="pt-3">
                                <v-tabs
                                    v-model="tabAtualDaJornada"
                                    color="grey darken-3"
                                    centered
                                >
                                    <v-tabs-slider color="black grey-3"></v-tabs-slider>
                                    <v-tab>
                                        <v-card  class="d-flex font-weight-bold px-2 py-6 align-center caption" color="grey lighten-2" style="gap: 10px; height: 24px;">
                                            JORNADA EXCEDIDA
                                            <v-btn color="red lighten-4" fab x-small elevation="3" class="subtitle-1 font-weight-bold">{{jornadas.motoristasComJornadaExcedida.length}}</v-btn>
                                        </v-card>
                                    </v-tab>

                                    <v-tab>
                                        <v-card  class="d-flex font-weight-bold px-2 py-6 align-center caption" color="grey lighten-2" style="gap: 10px; height: 24px;">
                                            JORNADA QUASE EXCEDIDA
                                            <v-btn color="orange lighten-4" fab x-small elevation="5" class="subtitle-1 font-weight-bold">{{jornadas.motoristasComJornadaQuaseExcedida.length}}</v-btn>
                                        </v-card>
                                    </v-tab>

                                    <v-tab>
                                        <v-card  class="d-flex font-weight-bold px-2 py-6 align-center caption" color="grey lighten-2" style="gap: 10px; height: 24px;">
                                            JORNADA NORMAL
                                            <v-btn color="white" fab x-small elevation="5" class="subtitle-1 font-weight-bold">{{jornadas.motoristasComJornadaComum.length}}</v-btn>
                                        </v-card>
                                    </v-tab>

                                    <v-tab-item class="pt-3"
                                        v-for="tiposDeJornada, key in jornadas"
                                        :key="tiposDeJornada[key]"
                                    >
                                        <v-container fluid>
                                            <v-row>
                                    
                                                <v-col  
                                                    xl="3"
                                                    lg="4"
                                                    md="6"
                                                    sm="12"
                                                    v-for="(jornada, indexJornada) in tiposDeJornada"
                                                    :key="`jornada-${indexJornada}`"
                                                >
                                                    <motorista-card-v2
                                                        :imagem_avatar="jornada.imagem_avatar"
                                                        :motorista="jornada.motorista_nome"
                                                        :telefone_celular="jornada.telefone_celular"
                                                        :status_tempo_total="jornada.direcao"
                                                        :status_tempo_geral="jornada.tempo_geral"
                                                        :mostraTempoJornada="false"
                                                        :corDoCardDeAcordoComTempoDaJornada = corDoCardDeAcordoComTempoDaJornada(jornada.ctrl)
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-tab-item>
                                </v-tabs>
                            </v-card>
                        </template>
                    </v-card>
                </v-col>
            </v-row>
        </span>

        <v-row>
            <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                {{ notificacao.mensagem }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </v-row>
    </v-container>
</template>

<script>
import { API_URL, API_URL_BASE } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import { UltimaMacroEnum } from '@/Modulo/Monitoramento/Motorista/Dominio/Enum/UltimaMacroEnum.js';

import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import MotoristaCardV2 from '@/Modulo/Monitoramento/Motorista/Componente/MotoristaCardV2.vue';
import CardInfo from '@/components/Default/CardInfo.vue';

export default {
    name: 'PainelJornadaParadaEspera',
    components: {
        CarregandoLogoEmpresa,
        MotoristaCardV2,
        CardInfo
    },
    mounted() {
        this.buscaJornadaMotorista();
    },
    beforeDestroy() {
        if (this.tabAtualDaJornada !== null) {
            clearInterval(this.tabAtualDaJornada);
        }
    },
    methods: {
        async buscaJornadaMotorista() {
            try {
                this.carregandoDados = true;

                this.carregandoDadosMensagem = 'Aguarde, buscando jornada com para de espera...';

                this.painelJornada = [];

                const parametros = {
                    tipo_viagem: UltimaMacroEnum.PARADA_ESPERA
                };

                await ApiService({
                    method: 'GET',
                    url: `${API_URL.erp.motorista.painelJornadaMotorista}`,
                    mostraCarregando: false,
                    params: parametros
                })
                    .then(resposta => {
                        this.preenchePainelJornada(resposta?.data?.data);
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.carregandoDados = false;
            }
        },
        preenchePainelJornada(jornadas) {

            jornadas.sort((a, b) => {
                if (a.motorista_nome < b.motorista_nome) {
                    return -1;
                }
                if (a.motorista_nome > b.motorista_nome) {
                    return 1;
                }
                return 0;
            });

            const oitoHorasEmSegundos = 8 * 3600;
            const onzeHorasEmSegundos = 11 * 3600;

            jornadas.map((item) => {
                
                let jornadaDoMotoristaEmSegundos = this.ConvertetempoEmSegundos(item.tempo_geral);

                if(jornadaDoMotoristaEmSegundos > oitoHorasEmSegundos && jornadaDoMotoristaEmSegundos < onzeHorasEmSegundos) {
                    this.CTRLDosMotoristasComJornadaQuaseExcedida.push(item.ctrl)
                    this.jornadas.motoristasComJornadaQuaseExcedida.push(item)
                    return
                }

                if(jornadaDoMotoristaEmSegundos > onzeHorasEmSegundos) {
                    this.CTRLDosMotoristasComJornadaExcedida.push(item.ctrl)
                    this.jornadas.motoristasComJornadaExcedida.push(item)
                    return
                }

                if(!this.CTRLDosMotoristasComJornadaExcedida.includes(item.ctrl) || !this.CTRLDosMotoristasComJornadaQuaseExcedida.includes(item.ctrl)) {
                    this.jornadas.motoristasComJornadaComum.push(item)
                    return
                }

            });
        },

        ConvertetempoEmSegundos(time) {
            const [hours, minutes, seconds] = time.split(':').map(Number);
            return (hours * 3600) + (minutes * 60) + seconds;
        },

        corDoCardDeAcordoComTempoDaJornada(ctrl) {
            if (this.CTRLDosMotoristasComJornadaQuaseExcedida.includes(ctrl)) {
                return 'orange lighten-4'
            }
            if ( this.CTRLDosMotoristasComJornadaExcedida.includes(ctrl)) {
                return 'red lighten-4'
            } 
            return 'white'
        },

        alterarATabDaJornadaAtualAutomaticamente() {

            if (this.intervaloTransicao !== null) {
                clearInterval(this.intervaloTransicao);
            }

            if (this.transicaoDasTabsDeJornada) {
                const tabsDisponiveis = [0, 1, 2];
                const tempoAteAlterarATab = 11000;
                let tabAtual = 0;

                this.intervaloTransicao = setInterval(() => {
                    tabAtual = (tabAtual + 1) % tabsDisponiveis.length; // Atualiza o índice da aba
                    this.tabAtualDaJornada = tabsDisponiveis[tabAtual]; // Altera a aba atual
                }, tempoAteAlterarATab);
            }
        },

        ativarEDesativartransicaoDasTabsDeJornada() {
            this.transicaoDasTabsDeJornada = !this.transicaoDasTabsDeJornada
            this.alterarATabDaJornadaAtualAutomaticamente()
        },
    },

    computed: {
        totalDeJornadas() {
            return this.jornadas.motoristasComJornadaExcedida.length + 
                   this.jornadas.motoristasComJornadaQuaseExcedida.length + 
                   this.jornadas.motoristasComJornadaComum.length
        }
    },

    data() {
        return {
            show: false,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            carregandoDados: true,
            carregandoDadosMensagem: 'Aguarde, identificando parada de espera...',
            buscas: {
                motorista: {
                    valor: '',
                    complemento: [{ id_motorista: null, nome: '' }],
                    datatable: {
                        id_datatable: 'motorista_busca',
                        chave_primaria: 'motorista',
                        url: `${API_URL_BASE}${API_URL.erp.motorista.completaCampo}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.motorista.completaCampo}`,
                        colunas: [
                            {
                                id: 'id_motorista',
                                nome: 'id_motorista',
                                descricao: 'ID'
                            },
                            {
                                id: 'nome',
                                nome: 'nome',
                                descricao: 'Nome'
                            }
                        ]
                    }
                }
            },
            totais: {
                fim_viagem: 0
            },
            model: 0,
            tabAtualDaJornada: 0,
            transicaoDasTabsDeJornada: false,
            intervaloTransicao: null,
            jornadas: {
                motoristasComJornadaExcedida: [],
                motoristasComJornadaQuaseExcedida: [],
                motoristasComJornadaComum: [],
            },
            CTRLDosMotoristasComJornadaQuaseExcedida: [],
            CTRLDosMotoristasComJornadaExcedida: [],
        };
    }
};
</script>
