<template>
    <v-container>
        <CarregandoLogoEmpresa mensagem="Carregando perfil do usuário, aguarde..." />

        <v-card v-if="!carregando.mostraCarregando" color="grey lighten-5" elevation="5" width="420">
            <v-img class="" height="100px" src="@/assets/image/logo/transporte.png"> </v-img>

            <v-row justify="center">
                <v-col align-self="start" class="d-flex justify-center align-center pa-0" cols="12">
                    <v-avatar class="profile avatar-center-heigth avatar-shadow" color="grey" size="100">
                        <input ref="uploader" class="d-none" type="file" accept="image/*" />

                        <img :src="eu.avatar" />
                    </v-avatar>
                </v-col>
            </v-row>

            <v-list-item color="#0000" class="profile-text-name ma-4 pt-10">
                <v-list-item-content>
                    <v-list-item-title class="text-h7">
                        {{ eu.nome_completo }}
                    </v-list-item-title>

                    <v-list-item-subtitle>{{ eu.cargo }} | {{ eu.email }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-text-field
                :value="eu.celular"
                :disabled="true"
                v-mask="'(##) # ####-####'"
                label="Celular"
                class="pa-6"
                prepend-icon="mdi-phone"
            ></v-text-field>
        </v-card>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { sleep } from '@/helpers/Funcoes.js';

import { mask } from 'vue-the-mask';

import Avatar from '@/components/Upload/Avatar.vue';
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';

export default {
    directives: { mask },
    name: 'PerfilModal',
    components: {
        Avatar,
        CarregandoLogoEmpresa
    },
    props: {
        id_usuario: {
            type: Number,
            required: true
        }
    },
    async mounted() {
        await this.dadosUsuario();
    },
    computed: {
        ...mapState(['carregando'])
    },
    methods: {
        ...mapActions(['mostraCarregando']),
        async dadosUsuario() {
            try {
                this.mostraCarregando(true);

                await sleep(1000);

                const url = `${API_URL.erp.usuario.usuarioPerfil}`.replace('$id_usuario', this.id_usuario);

                await ApiService({
                    method: 'get',
                    url: url,
                    data: {},
                    mostraCarregando: false
                })
                    .then(resposta => {
                        this.eu.nome_completo = resposta.data.data.atributos.nome_completo;
                        this.eu.cargo = resposta.data.data.atributos.cargo;
                        this.eu.email = resposta.data.data.atributos.email;
                        this.eu.celular = resposta.data.data.atributos.celular;
                        this.eu.avatar =
                            resposta.data.data.atributos.avatar ?? require('@/assets/image/perfil/img_avatar.png');

                        if (!_.isEmpty(resposta.data.data.atributos.enderecos)) {
                            const enderecoPrincipal = resposta.data.data.atributos.enderecos.find(
                                endereco => endereco.tipo_endereco === 1
                            );

                            this.$refs.enderecoPrincipalRef.preencheDados(enderecoPrincipal);
                        }
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.mostraCarregando(false);
            }
        }
    },
    data: () => ({
        notificacao: {
            mostra: false,
            mensagem: '',
            tipo: 'success'
        },
        eu: {
            nome_completo: '',
            cargo: '',
            email: '',
            avatar: null,
            celular: null
        }
    })
};
</script>

<style scoped>
.avatar-center-heigth {
    position: absolute;
}

.avatar-shadow {
    box-shadow: 0px 0px 10px 0px rgba(50, 12, 112, 0.75);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(50, 12, 112, 0.75);
    -moz-box-shadow: 0px 0px 10px 0px rgba(50, 12, 112, 0.75);
}
</style>
