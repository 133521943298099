<template>
    <div>
        <TituloModulo />

        <v-card outlined elevation="2" class="mb-4">
            <v-card-title>Filtros:</v-card-title>
            <v-card-text dense>
                <v-row>
                    <v-col cols="3">
                        <DateTimePickerV2
                            v-model="dataDe"
                            :dataInicial="dataDe"
                            label="Data criação de:"
                            data-vv-delay="1000"
                        />
                    </v-col>

                    <v-col cols="3">
                        <DateTimePickerV2
                            v-model="dataAte"
                            :dataInicial="dataAte"
                            label="Data criação até:"
                            data-vv-delay="1000"
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <InputSelectV3
                            v-model="filial"
                            rotulo="Filial"
                            :regra="regrasFilial"
                            :itens="opcaoFilial"
                            itemTexto="razao_social"
                            itemValor="id_filial"
                            ref="filial"
                        />
                    </v-col>

                    <v-col cols="12" md="4">
                        <ComboBoxPreenchimentoV3
                            v-model="filtros.numero_cte"
                            tituloBusca="Número CTe"
                            :smallChips="true"
                            :deletableChips="true"
                            :multiple="true"
                            itemValor="numero_cte"
                            itemTexto="numero_cte"
                            clearable
                        />
                    </v-col>

                    <v-col cols="12" md="2">
                        <InputSelectV3
                            v-model="filtros.cte_sim_nao"
                            rotulo="Com CTe"
                            :itens="opcaoCteSimNao"
                            itemTexto="descricao"
                            itemValor="valor"
                            ref="cteSimNao"
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <ComboBoxPreenchimentoV3
                            v-model="filtros.numero_container"
                            tituloBusca="Número Container"
                            :smallChips="true"
                            :deletableChips="true"
                            :multiple="true"
                            itemValor="numero_container"
                            itemTexto="numero_container"
                            clearable
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <ComboBoxPreenchimentoV3
                            v-model="filtros.id_programacao_carga_rodopar"
                            tituloBusca="ID Programação de Carga Rodopar"
                            :smallChips="true"
                            :deletableChips="true"
                            :multiple="true"
                            itemValor="id_programacao_carga_rodopar"
                            itemTexto="id_programacao_carga_rodopar"
                            clearable
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <InputSelectV3
                            v-model="filtros.tipo_operacao"
                            rotulo="Tipo Operação"
                            :itens="opcaoTipoOperacao"
                            itemTexto="descricao"
                            itemValor="valor"
                            :smallChips="true"
                            :multiplo="true"
                            iconeAppend="mdi-filter-multiple"
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <InputAutoCompleteV3
                            v-model="filtros.motorista_romaneio.valor"
                            :datatableConfiguracao="filtros.motorista_romaneio.datatable"
                            tituloBusca="Motorista Romaneio Busca..."
                            :itemsOpcoes="filtros.motorista_romaneio.complemento"
                            itemValor="id_motorista"
                            itemTexto="nome"
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <InputAutoCompleteV3
                            v-model="filtros.motorista_entrega.valor"
                            :datatableConfiguracao="filtros.motorista_entrega.datatable"
                            tituloBusca="Motorista Entrega Busca..."
                            :itemsOpcoes="filtros.motorista_entrega.complemento"
                            itemValor="id_motorista"
                            itemTexto="nome"
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <InputAutoCompleteV3
                            v-model="filtros.motorista_coleta.valor"
                            :datatableConfiguracao="filtros.motorista_coleta.datatable"
                            tituloBusca="Motorista Coleta Busca..."
                            :itemsOpcoes="filtros.motorista_coleta.complemento"
                            itemValor="id_motorista"
                            itemTexto="nome"
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <InputSelectV3
                            v-model="filtros.id_unidade_empreendimento"
                            rotulo="Filtro por unidade do solicitante"
                            :itens="opcaoUnidadeEmpreendimento"
                            itemTexto="codigo"
                            itemValor="id_unidade_empreendimento"
                            :smallChips="true"
                            :multiplo="true"
                            iconeAppend="mdi-filter-multiple"
                        />
                    </v-col>

                    <v-col cols="4">
                        <v-btn elevation="2" color="warning" @click="getAlocacaoCargaRomaneio()">Buscar</v-btn>

                        <v-btn @click="exportaTabelaParaExcel()" color="green darken-2" class="ml-2" dark>
                            <v-icon dense left> mdi-file-excel-outline </v-icon>
                            Gerar Excel
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-row>
            <v-col cols="12" class="text-right">
                <v-data-table
                    :headers="cabecalho"
                    :items="alocacaoCargaRomaneioItens"
                    :search="alocacaoCargaBuscar"
                    :items-per-page="50"
                    :fixed-header="true"
                    item-key="id_programacao_carga_romaneio_item"
                    loading-text="Buscando, aguarde..."
                    group-by="id_alocacao_carga_romaneio"
                    height="60vh"
                    class="elevation-5"
                    dense
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title
                                >Romaneios com Programação de Carga prontos para geração no Rodopar</v-toolbar-title
                            >
                        </v-toolbar>
                        <v-row class="pa-4">
                            <v-col cols="12" md="12">
                                <InputSelectV3 v-model="alocacaoCargaBuscar" rotulo="Solicitante" :itens="opcaoSolicitante" />
                            </v-col>
                        </v-row>
                    </template>

                    <template v-slot:group.header="{ group, items, headers, isOpen, toggle }">
                        <th :colspan="headers.length" class="blue-grey lighten-4">
                            <v-icon fab dark class="ml-5" @click="toggle" :color="isOpen ? 'red darken-4' : 'green darken-4'"
                                >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                            </v-icon>

                            <v-btn fab dark x-small class="ml-2" color="red lighten-1" @click="excluirRomaneio(group)">
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>

                            <v-btn fab dark x-small class="ml-2" color="purple lighten-1" @click="imprimir(group)">
                                <v-icon>mdi-printer-outline</v-icon>
                            </v-btn>

                            <span class="font-weight-black text-lg-h6 ml-2">
                                ID Romaneio:
                                {{ group }} - Motorista:
                                {{ items[0].motorista }}
                            </span>
                        </th>
                    </template>

                    <template v-slot:item.tipo_coleta_descricao="{ item }">
                        {{ item.tipo_coleta_descricao.toUpperCase() }}
                    </template>

                    <template v-slot:item.itinerario_destino_estado="{ item }">
                        {{ item.itinerario_destino }} /
                        {{ item.itinerario_destino_estado }}
                    </template>

                    <template v-slot:item.valor_mercadoria="{ item }">
                        {{ mascaraMoeda(item.valor_mercadoria) }}
                    </template>

                    <template v-slot:item.placa_destino="{ item }">
                        <span v-if="item.tipo_coleta == tipoColetaCrossdocking">{{ item.placa_entrega }}</span>
                        <span v-if="item.tipo_coleta == tipoColetaEntregaCliente">{{ item.placa_coleta }}</span>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <div class="text-center">
            <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false"> Fechar </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { API_URL, API_URL_BASE } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { FormataMoeda, ExportaArrayObjectsParaExcel } from '@/helpers/Funcoes.js';
import AlocacaoCargaTipoColetaEnum from '@/Enum/AlocacaoCargaTipoColetaEnum';

import moment from 'moment';

import TituloModulo from '@/components/Default/TituloModulo.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';
import InputTextV3 from '@/components/Form/InputTextV3.vue';
import DateTimePickerV2 from '@/components/Form/DateTimePickerV2.vue';
import ComboBoxPreenchimentoV3 from '@/components/Form/ComboBoxPreenchimentoV3.vue';
import InputAutoCompleteV3 from '@/components/Form/InputAutoCompleteV3.vue';

export default {
    name: 'Romaneios',
    components: {
        TituloModulo,
        InputSelectV3,
        InputTextV3,
        DateTimePickerV2,
        ComboBoxPreenchimentoV3,
        InputAutoCompleteV3,
    },
    async mounted() {
        await this.getFilial();
        await this.getUnidadeEmpreendimento();
        await this.getAlocacaoCargaRomaneio();
    },
    computed: {
        alocacaoCargaRomaneioItens() {
            return this.alocacaoCargaRomaneios;
        },
    },
    watch: {},
    methods: {
        mascaraMoeda(valor) {
            if (valor == null) {
                return 0;
            }

            return FormataMoeda(valor, {}, true);
        },
        async getAlocacaoCargaRomaneio() {
            if (!this.$refs['filial'].$children[0].validate()) {
                this.$refs['filial'].$children[0].validate(true);

                return false;
            }

            let formData = {};

            if (this.filtros.motorista_romaneio.valor) {
                formData.id_motorista_romaneio = [this.filtros.motorista_romaneio.valor];
            }

            if (this.filtros.motorista_entrega.valor) {
                formData.id_motorista_entrega = [this.filtros.motorista_entrega.valor];
            }

            if (this.filtros.motorista_coleta.valor) {
                formData.id_motorista_coleta = [this.filtros.motorista_coleta.valor];
            }

            if (this.filtros.id_programacao_carga_rodopar.length) {
                formData.id_programacao_carga_rodopar = this.filtros.id_programacao_carga_rodopar;
            }

            if (this.filtros.tipo_operacao.length) {
                formData.tipo_operacao = this.filtros.tipo_operacao;
            }

            if (!!this.filtros.numero_cte.length) {
                formData.numero_cte = this.filtros.numero_cte;
            }

            if (!!this.filtros.cte_sim_nao) {
                formData.cte_sim_nao = this.filtros.cte_sim_nao;
            }

            if (!!this.filtros.id_unidade_empreendimento) {
                formData.id_unidade_empreendimento = this.filtros.id_unidade_empreendimento;
            }

            if (this.filtros.numero_container.length) {
                formData.numero_container = this.filtros.numero_container;
            }

            await ApiService({
                method: 'GET',
                url: `${API_URL.erp.alocacaocargaromaneio.romaneios}?id_filial=${this.filial}&data_de=${this.dataDe}&data_ate=${this.dataAte}`,
                params: formData,
            })
                .then((resposta) => {
                    this.alocacaoCargaRomaneios = resposta.data.data;

                    this.alocacaoCargaRomaneios = this.alocacaoCargaRomaneios.map((alocacaoCarga) => {
                        const id = alocacaoCarga.id_registro;

                        alocacaoCarga = alocacaoCarga.atributos;
                        alocacaoCarga.id_alocacao_carga_romaneio = id;

                        this.opcaoSolicitante.push(alocacaoCarga.solicitante);

                        return alocacaoCarga;
                    });
                })
                .catch((erro) => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true,
                    };
                });
        },
        async getFilial() {
            await ApiService({
                method: 'get',
                url: `V1/filial/lista?fields[filial]=id_filial,filial_descricao`,
                data: {},
            })
                .then((resposta) => {
                    this.opcaoFilial = resposta.data.data.map((filial) => {
                        const dados = {};

                        dados.id_filial = filial.id_registro;
                        dados.razao_social = filial.atributos.filial_descricao;

                        return dados;
                    });

                    this.filial = this.opcaoFilial[0].id_filial;
                })
                .catch((erro) => {
                    throw new Error(erro.message);
                });
        },
        async getUnidadeEmpreendimento() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.unidadeempreendimento.lista}?fields[unidade_empreendimento]=id_unidade_empreendimento,codigo`,
                data: {},
            })
                .then((resposta) => {
                    this.opcaoUnidadeEmpreendimento = resposta.data.data.map((unidade) => {
                        const dados = [];

                        dados.id_unidade_empreendimento = unidade.id_registro;
                        dados.codigo = unidade.atributos.codigo;

                        return dados;
                    });

                    this.opcaoUnidadeEmpreendimento.push({
                        id_unidade_empreendimento: '',
                        codigo: 'NÃO DEFINIDO',
                    });
                })
                .catch((erro) => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true,
                    };
                });
        },
        async excluirRomaneio(idAlocacaoCargaRomaneio) {
            await this.$root.$refs.$confirmaAcaoModal
                .open('Apagar', `Apagar o romaneio ${idAlocacaoCargaRomaneio} ?`, {
                    cor: 'red darken-4',
                })
                .then(async (confirma) => {
                    if (!confirma) {
                        return false;
                    }

                    await ApiService({
                        method: 'DELETE',
                        url: `${API_URL.erp.alocacaocargaromaneio.apaga}/${idAlocacaoCargaRomaneio}`,
                    })
                        .then((resposta) => {
                            this.alocacaoCargaRomaneios = this.alocacaoCargaRomaneios.filter((programacaoCarga) => {
                                if (programacaoCarga.id_alocacao_carga_romaneio != idAlocacaoCargaRomaneio) {
                                    return programacaoCarga;
                                }
                            });

                            this.notificacao = {
                                mensagem: `Romaneio <strong>${idAlocacaoCargaRomaneio}</strong> excluido.`,
                                tipo: 'success',
                                mostra: true,
                            };
                        })
                        .catch((erro) => {
                            this.notificacao = {
                                mensagem: erro.message,
                                tipo: 'error',
                                mostra: true,
                            };
                        });
                });
        },
        async imprimir(idAlocacaoCargaRomaneio) {
            await ApiService({
                method: 'GET',
                url: `${API_URL.erp.alocacaocargaromaneio.imprimir}/${idAlocacaoCargaRomaneio}`,
                responseType: 'blob',
            })
                .then((resposta) => {
                    const blob = new Blob([resposta.data], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);
                    window.open(url);
                })
                .catch((erro) => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true,
                    };
                });
        },
        exportaTabelaParaExcel() {
            try {
                const nomeExcel = `programados-${moment().format('DD-MM-YYYY_HH-MM-SS')}`;

                const itens = this.alocacaoCargaRomaneioItens.map((item) => {
                    item.valor_mercadoria = this.mascaraMoeda(item.valor_mercadoria);
                    item.placa_entrega =
                        item.tipo_coleta == this.tipoColetaCrossdocking ? item.placa_entrega : item.placa_coleta;

                    return item;
                });

                const cabecalho = _.cloneDeep(this.cabecalho);

                cabecalho.push({
                    text: 'MOTORISTA',
                    value: 'motorista',
                });

                ExportaArrayObjectsParaExcel(itens, cabecalho, nomeExcel);
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
    },
    data() {
        return {
            dataDe: moment().format('YYYY-MM-DD'),
            dataAte: moment().add('1', 'days').startOf('day').format('YYYY-MM-DD'),
            alocacaoCargaRomaneios: [],
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success',
            },
            opcaoSolicitante: [],
            filial: null,
            opcaoFilial: [],
            opcaoUnidadeEmpreendimento: [],
            opcaoCteSimNao: [
                { valor: 'S', descricao: 'Sim' },
                { valor: 'N', descricao: 'Não' },
            ],
            opcaoTipoOperacao: [
                { valor: 'Carga Fechada', descricao: 'Carga Fechada' },
                { valor: 'COLETA TBE', descricao: 'COLETA TBE' },
                { valor: 'COLETA VCP', descricao: 'COLETA VCP' },
                { valor: 'CROSSDOCKING TBE', descricao: 'CROSSDOCKING TBE' },
                { valor: 'DEVOLUCAO DE VAZIO', descricao: 'DEVOLUCAO DE VAZIO' },
                { valor: 'EXPORTACAO SSZ', descricao: 'EXPORTACAO SSZ' },
                { valor: 'EXPORTACAO TBE', descricao: 'EXPORTACAO TBE' },
                { valor: 'IMPORTACAO GRU', descricao: 'IMPORTACAO GRU' },
                { valor: 'IMPORTACAO SSZ', descricao: 'IMPORTACAO SSZ' },
                { valor: 'IMPORTACAO TBE', descricao: 'IMPORTACAO TBE' },
                { valor: 'IMPORTACAO VCP', descricao: 'IMPORTACAO VCP' },
                { valor: 'INCLUIR', descricao: 'INCLUIR' },
                { valor: 'RETIRADA DE VAZIO', descricao: 'RETIRADA DE VAZIO' },
                { valor: 'TRANSFERENCIA', descricao: 'TRANSFERENCIA' },
            ],
            regrasFilial: [(v) => !!v || 'Campo é obrigatório'],
            cabecalho: [
                {
                    text: 'ID ROMANEIO',
                    value: 'id_alocacao_carga_romaneio',
                    width: '80',
                },
                {
                    text: 'ORDEM',
                    value: 'ordem',
                    width: '50',
                },
                {
                    text: 'ID',
                    value: 'id_programacao_carga',
                    width: '80',
                },
                {
                    text: 'Programação de Carga Rodopar',
                    value: 'id_programacao_carga_rodopar',
                    align: 'start',
                    width: '200',
                },
                {
                    text: 'SOLICITANTE',
                    value: 'solicitante',
                    width: '200',
                },
                {
                    text: 'TIPO COLETA',
                    value: 'tipo_coleta_descricao',
                    width: '200',
                },
                {
                    text: 'TIPO OPERAÇÃO',
                    value: 'tipo_solicitacao',
                    width: '200',
                },
                {
                    text: 'TERMINAL COLETA',
                    value: 'terminal_coleta',
                    width: '200',
                },
                { text: 'OS', value: 'id_ordem_servico_transporte', width: '200' },
                { text: 'CTE', value: 'numero_cte', width: '200' },
                {
                    text: 'N° DI',
                    value: 'numero_di',
                    width: '200',
                },
                {
                    text: 'N° DTA',
                    value: 'numero_dta',
                    width: '200',
                },
                {
                    text: 'REFERÊNCIA',
                    value: 'referencia',
                    width: '200',
                },
                {
                    text: 'NÚMERO CONTAINER',
                    value: 'numero_container',
                    width: '200',
                },
                {
                    text: 'OBS. ENTREGA',
                    value: 'observacao',
                    width: '200',
                },
                {
                    text: 'OBS. GERAIS',
                    value: 'observacao_gerais',
                    width: '200',
                },
                {
                    text: 'CLIENTE',
                    value: 'nome_tomador_servico',
                    width: '200',
                },
                { text: 'ORIGEM', value: 'terminal_coleta', width: '200' },
                {
                    text: 'ORIGEM ESTADO',
                    value: 'itinerario_origem_estado',
                    width: '200',
                },
                { text: 'DESTINO', value: 'terminal_entrega', width: '200' },
                {
                    text: 'DESTINO ESTADO',
                    value: 'itinerario_destino_estado',
                    width: '200',
                },
                { text: 'TIPO CARGA', value: 'tipo_carga', width: '200' },
                { text: 'QTD.', value: 'quantidade_itens', width: '200' },
                { text: 'ALTURA', value: 'altura', width: '200' },
                { text: 'LARGURA', value: 'largura', width: '200' },
                { text: 'PROFUNDIDADE', value: 'profundidade', width: '200' },
                { text: 'PESO', value: 'peso_calculado', width: '200' },
                {
                    text: 'VALOR CARGA',
                    value: 'valor_mercadoria',
                    width: '200',
                },
                {
                    text: 'PARTICULARIDADE CLIENTE',
                    value: 'particularidade_cliente',
                    width: '200',
                },
                {
                    text: 'PLACA',
                    value: 'placa',
                    width: '200',
                    sortable: false,
                },
                {
                    text: 'PLACA CARRETA',
                    value: 'placa_carreta',
                    width: '200',
                    sortable: false,
                },
                {
                    text: 'PLACA CARRETA 2',
                    value: 'placa_carreta_2',
                    width: '200',
                    sortable: false,
                },
                {
                    text: 'UNIDADE SOLICITANTE',
                    value: 'unidade_solicitante',
                    width: '200',
                },
            ],
            alocacaoCargaBuscar: '',
            tipoColetaCrossdocking: AlocacaoCargaTipoColetaEnum.CROSSDOCKING,
            tipoColetaEntregaCliente: AlocacaoCargaTipoColetaEnum.ENTREGA_CLIENTE,
            filtros: {
                id_programacao_carga_rodopar: [],
                numero_cte: [],
                cte_sim_nao: null,
                tipo_operacao: [],
                id_unidade_empreendimento: [],
                numero_container: [],
                motorista_romaneio: {
                    valor: '',
                    complemento: [{ id_motorista: null, nome: '' }],
                    datatable: {
                        id_datatable: 'motorista_busca',
                        chave_primaria: 'motorista',
                        url: `${API_URL_BASE}${API_URL.erp.motorista.completaCampo}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.motorista.completaCampo}`,
                        colunas: [
                            {
                                id: 'id_motorista',
                                nome: 'id_motorista',
                                descricao: 'ID',
                            },
                            {
                                id: 'nome',
                                nome: 'nome',
                                descricao: 'Nome',
                            },
                        ],
                    },
                },
                motorista_entrega: {
                    valor: '',
                    complemento: [{ id_motorista: null, nome: '' }],
                    datatable: {
                        id_datatable: 'motorista_busca',
                        chave_primaria: 'motorista',
                        url: `${API_URL_BASE}${API_URL.erp.motorista.completaCampo}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.motorista.completaCampo}`,
                        colunas: [
                            {
                                id: 'id_motorista',
                                nome: 'id_motorista',
                                descricao: 'ID',
                            },
                            {
                                id: 'nome',
                                nome: 'nome',
                                descricao: 'Nome',
                            },
                        ],
                    },
                },
                motorista_coleta: {
                    valor: '',
                    complemento: [{ id_motorista: null, nome: '' }],
                    datatable: {
                        id_datatable: 'motorista_busca',
                        chave_primaria: 'motorista',
                        url: `${API_URL_BASE}${API_URL.erp.motorista.completaCampo}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.motorista.completaCampo}`,
                        colunas: [
                            {
                                id: 'id_motorista',
                                nome: 'id_motorista',
                                descricao: 'ID',
                            },
                            {
                                id: 'nome',
                                nome: 'nome',
                                descricao: 'Nome',
                            },
                        ],
                    },
                },
            },
        };
    },
};
</script>

<style>
.pointer {
    cursor: pointer;
}

.v-data-table__wrapper > table > tbody > tr {
    height: 60px;
}
</style>
