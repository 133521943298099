<template>
    <v-form ref="form">
        <v-row>
            <v-col cols="12" sm="2">
                <InputTextV3 v-model="form.id_devolucao_vazio" rotulo="ID" :contador="100" desativado clearable />
            </v-col>

            <v-col cols="12" sm="2">
                <InputTextV3
                    v-model="form.id_programacao_carga_rodopar"
                    rotulo="Programação de Carga Rodopar"
                    :contador="100"
                    desativado
                    clearable
                />
            </v-col>

            <v-col cols="12" sm="8">
                <InputSelectV3
                    v-model="form.situacao"
                    rotulo="Situação"
                    :itens="opcaoSituacao"
                    :desativado="true"
                    itemTexto="descricao"
                    itemValor="situacao"
                />
            </v-col>

            <v-col cols="12" sm="12">
                <InputAutoCompleteV3
                    v-model="form.id_parceiro_tomador"
                    :datatableConfiguracao="autoCompleta.armador.datatable"
                    tituloBusca="Cliente Busca..."
                    :itemsOpcoes="itensParceiro"
                    itemValor="id_parceiro"
                    itemTexto="razao_social"
                    desativado
                />
            </v-col>

            <v-col cols="12" sm="4">
                <InputTextV3 v-model="form.numero_container" rotulo="Número Container" :contador="100" desativado clearable />
            </v-col>

            <v-col cols="12" sm="4">
                <InputTextV3
                    v-model="form.container_comprimento"
                    rotulo="Comprimento Container"
                    :contador="6"
                    desativado
                    clearable
                />
            </v-col>

            <v-col cols="12" sm="2">
                <InputTextV3 v-model="form.container_tipo" rotulo="Tipo Container" :contador="6" desativado clearable />
            </v-col>

            <v-col cols="12" sm="2">
                <InputTextV3 v-model="form.container_tara" rotulo="Tara Container" :contador="5" desativado clearable />
            </v-col>

            <v-col cols="12" sm="4">
                <InputTextV3 v-model="dataPedido" rotulo="Data Pedido" :contador="100" desativado clearable />
            </v-col>

            <v-col cols="12" sm="4">
                <InputTextV3 v-model="dataRetirada" rotulo="Data Retirada" :contador="100" desativado clearable />
            </v-col>

            <v-col cols="12" sm="4">
                <InputTextV3 v-model="dataEntrega" rotulo="Data Entrega" :contador="100" desativado clearable />
            </v-col>

            <v-col cols="12" sm="6">
                <TextAreaV3
                    v-model="form.programacao_carga_observacao"
                    :contador="3000"
                    rotulo="Programação Carga Observação"
                    desativado
                />
            </v-col>

            <v-col cols="12" sm="6">
                <TextAreaV3
                    v-model="form.programacao_carga_particularidade_cliente"
                    :contador="3000"
                    rotulo="Particularidade Cliente"
                    desativado
                />
            </v-col>

            <v-col cols="12" sm="6">
                <InputAutoCompleteV3
                    v-model="form.id_placa_cavalo"
                    :datatableConfiguracao="autoCompleta.placaCavalo.datatable"
                    tituloBusca="Placa Cavalo Busca..."
                    :itemsOpcoes="itensPlacaCavalo"
                    itemValor="id_veiculo"
                    itemTexto="placa"
                />
            </v-col>

            <v-col cols="12" sm="6">
                <InputAutoCompleteV3
                    v-model="form.id_placa_carreta"
                    :datatableConfiguracao="autoCompleta.placaCarreta.datatable"
                    tituloBusca="Placa Carreta Busca..."
                    :itemsOpcoes="itensPlacaCarreta"
                    itemValor="id_veiculo"
                    itemTexto="placa"
                />
            </v-col>

            <v-col cols="12" sm="12">
                <InputAutoCompleteV3
                    v-model="form.id_motorista"
                    :datatableConfiguracao="autoCompleta.motorista.datatable"
                    tituloBusca="Motorista Busca..."
                    :itemsOpcoes="itensMotorista"
                    itemValor="id_motorista"
                    itemTexto="nome"
                />
            </v-col>

            <v-col cols="12" sm="12">
                <InputAutoCompleteV3
                    v-model="form.id_motorista_terminal_entrega"
                    :datatableConfiguracao="autoCompleta.motorista.datatable"
                    :desativado="true"
                    tituloBusca="Motorista Terminal Entrega Busca..."
                    :itemsOpcoes="itensMotorista"
                    itemValor="id_motorista"
                    itemTexto="nome"
                />
            </v-col>

            <v-col cols="12" sm="6">
                <InputAutoCompleteV3
                    v-model="form.id_parceiro_armador"
                    :datatableConfiguracao="autoCompleta.armador.datatable"
                    tituloBusca="Armador Busca..."
                    :itemsOpcoes="itensParceiro"
                    itemValor="id_parceiro"
                    itemTexto="razao_social"
                />
            </v-col>

            <v-col cols="12" sm="6">
                <InputAutoCompleteV3
                    v-model="form.id_parceiro_depot"
                    :datatableConfiguracao="autoCompleta.depot.datatable"
                    tituloBusca="Depot Busca..."
                    :itemsOpcoes="itensParceiro"
                    itemValor="id_parceiro"
                    itemTexto="razao_social"
                />
            </v-col>

            <v-col class="pb-0" cols="12" sm="6">
                <InputSelectV3
                    v-model="form.parceiro_baixa"
                    rotulo="Parceiro Baixa"
                    :itens="opcaoParceiroBaixa"
                    itemTexto="label"
                    itemValor="value"
                />
            </v-col>

            <v-col class="pb-0" cols="12" sm="6">
                <InputSelectV3
                    v-model="form.motivo_baixa"
                    rotulo="Motivo Baixa"
                    :itens="opcaoMotivoBaixa"
                    itemTexto="label"
                    itemValor="value"
                />
            </v-col>

            <v-col class="pa-0 pe-4" cols="12" sm="12">
                <div class="d-flex flex-row-reverse">
                    <v-checkbox
                        class="mt-0 mb-2"
                        append-icon="mdi-account-question"
                        v-model="form.cobrar_cliente"
                        label="Cobrar o cliente"
                        :false-value="2"
                        :true-value="1"
                        disabled
                    ></v-checkbox>
                </div>
            </v-col>

            <v-col cols="12" sm="3">
                <DateTimePickerV2
                    v-model="form.data_parceiro_baixa"
                    :dataInicial="form.data_parceiro_baixa"
                    label="Data Parceiro Baixa"
                    data-vv-delay="1000"
                />
            </v-col>

            <v-col cols="12" sm="3">
                <DateTimePickerV2
                    v-model="form.data_vencimento_demurrage"
                    :dataInicial="form.data_vencimento_demurrage"
                    label="Data Vencimento Demurrage"
                    data-vv-delay="1000"
                    :regra="[(v) => !!v || 'Campo é obrigatório']"
                />
            </v-col>

            <v-col cols="12" sm="3">
                <DateTimePickerV2
                    v-model="form.data_freetime"
                    :dataInicial="form.data_freetime"
                    label="Data Freetime"
                    data-vv-delay="1000"
                />
            </v-col>

            <v-col cols="12" sm="3">
                <v-datetime-picker
                    v-model="form.data_devolucao"
                    clearText="Limpar"
                    okText="OK"
                    label="Data Devolução"
                    :dateFormat="'dd/MM/yyyy'"
                    :timeFormat="'HH:mm'"
                    :loading="false"
                    :textFieldProps="{
                        outlined: true,
                        dense: true,
                        clearable: true,
                        'prepend-inner-icon': 'mdi-calendar',
                    }"
                    :timePickerProps="{
                        format: '24hr',
                    }"
                >
                </v-datetime-picker>
            </v-col>

            <v-col cols="12" sm="12">
                <TextAreaV3 v-model="form.observacao" :contador="3000" rotulo="Observação" />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { API_URL, API_URL_BASE } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { PreencheDadosMesmaPropriedade } from '@/helpers/Funcoes.js';

import moment from 'moment';

import { descricao as motivoBaixaDescricao } from '@/Enum/DevolucaoVazio/MotivoBaixa.js';
import { descricao as parceiroBaixaDescricao } from '@/Enum/DevolucaoVazio/ParceiroBaixa.js';
import cobrar from '@/Enum/DevolucaoVazio/CobrarCliente.js';

import InputTextV3 from '@/components/Form/InputTextV3.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';
import InputAutoCompleteV3 from '@/components/Form/InputAutoCompleteV3.vue';
import DateTimePickerV2 from '@/components/Form/DateTimePickerV2.vue';
import TextAreaV3 from '@/components/Form/TextAreaV3.vue';

export default {
    name: 'FormDados',
    components: {
        InputTextV3,
        InputSelectV3,
        InputAutoCompleteV3,
        DateTimePickerV2,
        TextAreaV3,
    },
    props: {
        dadosData: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    async created() {},
    async mounted() {
        await this.buscaSituacao();
    },
    computed: {
        itensParceiro() {
            return this.itensPreenchimento.parceiros ?? [];
        },
        itensMotorista() {
            return this.itensPreenchimento.motoristas ?? [];
        },
        itensPlacaCavalo() {
            return this.itensPreenchimento.placa_cavalo ?? [];
        },
        itensPlacaCarreta() {
            return this.itensPreenchimento.placa_carreta ?? [];
        },
        dataPedido() {
            return moment(this.form.data_pedido, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY hh:mm');
        },
        dataEntrega() {
            return moment(this.form.data_entrega, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY hh:mm');
        },
        dataRetirada() {
            return moment(this.form.data_retirada, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY hh:mm');
        },
    },
    watch: {
        dadosData: {
            handler: function (dadosAtual) {
                this.preencheDados(dadosAtual);
            },
            immediate: true,
        },
        'form.motivo_baixa'(motivoBaixaSelecionadoValue) {
            
            if(!motivoBaixaSelecionadoValue) {
                this.form.cobrar_cliente = null
                return
            }
            this.cobrarClienteSimOuNao(motivoBaixaSelecionadoValue)
        }

    },
    methods: {
        cobrarClienteSimOuNao(motivoBaixaSelecionadoValue) {
            const motivoBaixaSelecionado = motivoBaixaDescricao().find(motivoBaixa => motivoBaixa.value === motivoBaixaSelecionadoValue);
            this.form.cobrar_cliente = motivoBaixaSelecionado.cobrar_cliente
        },
        preencheDados(dados) {
            this.itensPreenchimento.parceiros = dados?.parceiros;
            this.itensPreenchimento.motoristas = dados?.motoristas;
            this.itensPreenchimento.placa_cavalo = dados?.placa_cavalo;
            this.itensPreenchimento.placa_carreta = dados?.placa_carreta;
            this.itensPreenchimento.cobrar_cliente = dados?.cobrar_cliente;

            if (dados.data_devolucao) {
                dados.data_devolucao = moment(dados.data_devolucao, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm');
            }

            this.form = PreencheDadosMesmaPropriedade(this.form, dados);
        },
        preparaDados() {
            const dados = { ...this.form };

            if (dados.data_devolucao) {
                dados.data_devolucao = moment(dados.data_devolucao, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
            }

            return dados;
        },
        async buscaSituacao() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.devolucaovazio.situacao}`,
                data: {},
                mostraCarregando: false,
            })
                .then((resposta) => {
                    this.opcaoSituacao = resposta.data.data.map((situacao) => {
                        const dados = {};

                        dados.situacao = situacao.id_registro;
                        dados.descricao = situacao.atributos.descricao;

                        return dados;
                    });
                })
                .catch((erro) => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true,
                    };
                });
        },
    },
    data() {
        return {
            form: {
                id_devolucao_vazio: null,
                id_programacao_carga: null,
                id_programacao_carga_rodopar: null,
                id_parceiro_tomador: null,
                id_parceiro_armador: null,
                id_parceiro_depot: null,
                id_placa_cavalo: null,
                id_placa_carreta: null,
                parceiro_baixa: null,
                motivo_baixa: null,
                cobrar_cliente: null,
                numero_container: null,
                container_comprimento: null,
                container_tipo: null,
                container_tara: null,
                id_motorista: null,
                id_motorista_terminal_entrega: null,
                situacao: null,
                data_parceiro_baixa: null,
                data_freetime: null,
                data_devolucao: null,
                data_vencimento_demurrage: null,
                data_pedido: null,
                data_retirada: null,
                data_entrega: null,
                data_pedido: null,
                data_entrega: null,
                data_retirada: null,
                programacao_carga_observacao: null,
                programacao_carga_particularidade_cliente: null,
                observacao: null,
            },
            itensPreenchimento: {
                parceiros: [],
                motoristas: [],
                placa_cavalo: [],
                placa_carreta: [],
                cobrar_cliente: null,
            },
            opcaoSituacao: [],
            opcaoParceiroBaixa: parceiroBaixaDescricao(),
            opcaoMotivoBaixa: motivoBaixaDescricao(),
            autoCompleta: {
                depot: {
                    datatable: {
                        id_datatable: 'depot_id_parceiro_depot',
                        chave_primaria: 'depot_id_parceiro_depot',
                        url: `${API_URL_BASE}${API_URL.erp.devolucaovazio.datatable}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.devolucaovazio.buscaParceiroRodopar}`,
                        colunas: [
                            {
                                id: 'id_parceiro',
                                nome: 'id_parceiro',
                                descricao: 'ID Rodopar',
                            },
                            {
                                id: 'razao_social',
                                nome: 'razao_social',
                                descricao: 'Razão Social',
                            },
                        ],
                    },
                },
                armador: {
                    datatable: {
                        id_datatable: 'armador_id_parceiro_armador',
                        chave_primaria: 'armador_id_parceiro_armador',
                        url: `${API_URL_BASE}${API_URL.erp.devolucaovazio.datatable}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.devolucaovazio.buscaParceiroRodopar}`,
                        colunas: [
                            {
                                id: 'id_parceiro',
                                nome: 'id_parceiro',
                                descricao: 'ID Rodopar',
                            },
                            {
                                id: 'razao_social',
                                nome: 'razao_social',
                                descricao: 'Razão Social',
                            },
                        ],
                    },
                },
                motorista: {
                    datatable: {
                        id_datatable: 'motorista_id_parceiro_motorista',
                        chave_primaria: 'motorista_id_parceiro_motorista',
                        url: `${API_URL_BASE}${API_URL.erp.devolucaovazio.datatable}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.devolucaovazio.buscaMotorista}`,
                        colunas: [
                            {
                                id: 'id_motorista',
                                nome: 'id_motorista',
                                descricao: 'ID',
                            },
                            {
                                id: 'nome',
                                nome: 'nome',
                                descricao: 'Nome',
                            },
                        ],
                    },
                },
                placaCavalo: {
                    datatable: {
                        id_datatable: 'placa_cavalo_id_parceiro_placa_cavalo',
                        chave_primaria: 'placa_cavalo_id_parceiro_placa_cavalo',
                        url: `${API_URL_BASE}${API_URL.erp.devolucaovazio.datatable}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.devolucaovazio.buscaPlaca}`,
                        colunas: [
                            {
                                id: 'id_veiculo',
                                nome: 'id_veiculo',
                                descricao: 'ID',
                            },
                            {
                                id: 'placa',
                                nome: 'placa',
                                descricao: 'Placa',
                            },
                        ],
                    },
                },
                placaCarreta: {
                    datatable: {
                        id_datatable: 'placa_carreta_id_parceiro_placa_carreta',
                        chave_primaria: 'placa_carreta_id_parceiro_placa_carreta',
                        url: `${API_URL_BASE}${API_URL.erp.devolucaovazio.datatable}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.devolucaovazio.buscaPlaca}`,
                        colunas: [
                            {
                                id: 'id_veiculo',
                                nome: 'id_veiculo',
                                descricao: 'ID',
                            },
                            {
                                id: 'placa',
                                nome: 'placa',
                                descricao: 'Placa',
                            },
                        ],
                    },
                },
            },
        };
    },
};
</script>

<style></style>
