<template>
    <v-form ref="form">
        <v-card color="grey lighten-3">
            <v-card-title v-if="form.tipo == 1">Pontos fortes (A seguir cite 3 pontos fortes do colaborador)</v-card-title>
            <v-card-title v-if="form.tipo == 2">
                Pontos a desenvolver (Cite 3 pontos a desenvolver do colaborador)
            </v-card-title>

            <v-card-text>
                <v-card elevation="2" class="pa-2 mb-5">
                    <v-card-title>
                        #1
                    </v-card-title>

                    <v-card-text>
                        <InputTextV3
                            v-model="form.pontos[0].ponto"
                            rotulo="Ponto"
                            :contador="200"
                            :obrigatorio="true"
                            :regra="regrasPonto"
                            background-color="white"
                        />

                        <p>
                            {{ mensagemOrientacao() }}
                        </p>

                        <TextAreaV3
                            v-model="form.pontos[0].descricao"
                            :contador="3000"
                            :obriatorio="true"
                            :regra="regrasDescricao"
                            rotulo="Descrição"
                        />
                    </v-card-text>
                </v-card>

                <v-card elevation="2" class="pa-2 mb-5">
                    <v-card-title>
                        #2
                    </v-card-title>

                    <v-card-text>
                        <InputTextV3
                            v-model="form.pontos[1].ponto"
                            rotulo="Ponto"
                            :contador="250"
                            :obrigatorio="true"
                            :regra="regrasPonto"
                            background-color="white"
                        />

                        <p>
                            {{ mensagemOrientacao() }}
                        </p>

                        <TextAreaV3
                            v-model="form.pontos[1].descricao"
                            :contador="3000"
                            :obriatorio="true"
                            :regra="regrasDescricao"
                            rotulo="Ponto"
                        />
                    </v-card-text>
                </v-card>

                <v-card elevation="2" class="pa-2 mb-5">
                    <v-card-title>
                        #3
                    </v-card-title>

                    <v-card-text>
                        <InputTextV3
                            v-model="form.pontos[2].ponto"
                            rotulo="Ponto"
                            :contador="250"
                            :obrigatorio="true"
                            :regra="regrasPonto"
                            background-color="white"
                        />

                        <p>
                            {{ mensagemOrientacao() }}
                        </p>

                        <TextAreaV3
                            v-model="form.pontos[2].descricao"
                            :contador="3000"
                            :obriatorio="true"
                            :regra="regrasDescricao"
                            rotulo="Ponto"
                        />
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
import { Maximo, Minimo, Obrigatorio } from '@/helpers/ValidadorCampo.js';

import TextAreaV3 from '@/components/Form/TextAreaV3.vue';
import InputTextV3 from '@/components/Form/InputTextV3.vue';

export default {
    name: 'FormPontosFortesEDesenvolver',
    components: {
        TextAreaV3,
        InputTextV3
    },
    props: {
        tipo: {
            type: Number,
            default() {
                return null;
            }
        }
    },
    async created() {},
    async mounted() {},
    computed: {},
    watch: {},
    methods: {
        preparaDados() {
            return this.form.pontos;
        },
        mensagemOrientacao() {
            return this.form.tipo == 1
                ? 'Cite ao menos um exemplo que justifique o ponto forte citado acima.'
                : 'Proponha / Sugira ao menos 1 ação a ser realizada para tratativa do ponto citado acima (Treinamentos, cursos, reuniões, etc).';
        }
    },
    data() {
        return {
            form: {
                tipo: this.tipo,
                pontos: [
                    { ponto: null, descricao: null },
                    { ponto: null, descricao: null },
                    { ponto: null, descricao: null }
                ]
            },
            regrasPonto: [Obrigatorio(), Minimo(5), Maximo(200)],
            regrasDescricao: [Obrigatorio(), Minimo(10), Maximo(3000)]
        };
    }
};
</script>

<style></style>
