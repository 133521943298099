import { API_URL_BASE, API_URL } from '@/config/configs';

const TipoContratacao = {
    datatable: {
        id_datatable: 'tipocontratacao',
        chave_primaria: 'id_tipo_contratacao',
        url: `${API_URL_BASE}${API_URL.erp.tipocontratacao.datatable}`,
        colunas: false,
    },
    formRefsComponente: 'TipoContratacaoForm',
    formNome: 'formTipoContratacao',
    moduloNome: 'tipocontratacao',
    chavePrimaria: 'id_tipo_contratacao',
    componenteArquivo: 'TipoContratacao/Form.vue',
    tabRefs: 'tabTipoContratacao',
    urlNovoRegistro: '',
    urlConsultaRegistro: 'listaPorIdForm',
    tabs: []
}

export { TipoContratacao };

