<template>
    <span> {{ params.value }}</span>
</template>

<script>
import moment from 'moment';

export default {
    name: 'SaldoInicialValor',
    data() {
        return {};
    },
    computed: {
        saldo() {
            const params = this.params;
            const dataColunaAtual = params?.colDef.field;

            let valor = null;

            if (params?.node?.key == 'saldo') {
                const chavesDatas = Object.keys(params.node?.parent?.aggData);
                const posicaoAtual = chavesDatas.indexOf(dataColunaAtual);
                const dataColunaAnterior = chavesDatas[posicaoAtual - 1];

                valor = params.node?.parent?.aggData[dataColunaAnterior];

                console.log('ENTREI NÉ', params.node?.parent?.aggData, valor);
            }

            console.log('------------> encontraValorSaldo', valor, params?.node?.key, params);

            return valor ?? params.value;
        },
        saldo1() {
            console.log('AQUUI --------->', this.params.value);
            /*
            console.log('SaldoInicialValor', this.params);

            const dataColuna = this.params?.colDef.field;

            const diaAnterior = moment(dataColuna)
                .add(-1, 'days')
                .format('YYYY-MM-DD');

            console.log('dataColuna', this.params.node?.parent?.aggData[diaAnterior]);

            return 'OI2 - ';
            */
        }
    }
};
</script>
