<template>
    <div class="pa-2">
        <TituloModulo />

        <AlertaMensagem dense style="width: 100%" />

        <BarraNavegacaoBotaoV3 v-if="!carregando.mostraCarregando && permissao" :botoes="botoesComPermissao(pdiSituacao)" />

        <CarregandoLogoEmpresa :mensagem="mensagemCarregando" />

        <v-card v-if="!carregando.mostraCarregando" class="pb-10">
            <v-tabs v-model="tabComponente" background-color="orange accent-2" dense centered dark icons-and-text>
                <v-tabs-slider></v-tabs-slider>

                <v-tab>
                    <span>
                        <v-icon>mdi-file-sign </v-icon>
                        <v-spacer></v-spacer>
                        Dados
                    </span>
                </v-tab>

                <v-tab>
                    <v-badge v-if="tabAnexoTotalErros" :content="`${tabAnexoTotalErros}`" color="red accent-4" overlap>
                        <v-icon>mdi-attachment-plus</v-icon>
                        <v-spacer></v-spacer>
                        Anexos
                    </v-badge>

                    <span v-else>
                        <v-icon>mdi-attachment-plus </v-icon>
                        <v-spacer></v-spacer>
                        Anexos
                    </span>
                </v-tab>

                <v-tab :disabled="form.avaliacao.length > 0 ? false : true">
                    <v-badge v-if="tabAvaliacaoTotalErros" :content="`${tabAvaliacaoTotalErros}`" color="red accent-4" overlap>
                        <v-icon>mdi-account-question-outline</v-icon>
                        <v-spacer></v-spacer>
                        Avaliação
                    </v-badge>

                    <span v-else>
                        <v-icon>mdi-account-question-outline </v-icon>
                        <v-spacer></v-spacer>
                        Avaliação
                    </span>
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabComponente">
                <v-form ref="planoDesenvolvimentoIndividualForm">
                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-text>
                                <FormDados ref="dadosRef" :dadosData="form" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-text>
                                <FormAnexos ref="formAnexosRef" :dadosAnexo="form.anexo" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-text>
                                <FormAvaliacao ref="formAvaliacaoRef" :dados="form.avaliacao" :situacaoPdi="pdiSituacao" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-form>
            </v-tabs-items>
        </v-card>

        <br />

        <BarraNavegacaoBotaoV3 v-if="!carregando.mostraCarregando && permissao" :botoes="botoesComPermissao(pdiSituacao)" />

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { Maximo, Minimo, Obrigatorio } from '@/helpers/ValidadorCampo.js';
import { Alerta, PreencheDadosMesmaPropriedade, sleep } from '@/helpers/Funcoes.js';

import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';

import FormDados from '@/views/PlanoDesenvolvimentoIndividual/FormDados.vue';
import FormAnexos from '@/views/PlanoDesenvolvimentoIndividual/FormAnexos.vue';
import FormAvaliacao from '@/views/PlanoDesenvolvimentoIndividual/FormAvaliacao.vue';

import SituacaoForm from '@/Enum/PlanoDesenvolvimentoIndividual/SituacaoForm.js';
import SituacaoEnum from '@/Enum/PlanoDesenvolvimentoIndividual/SituacaoEnum.js';
import PontoTipoEnum from '@/Enum/PlanoDesenvolvimentoIndividual/PontoTipoEnum.js';

import TextAreaV3 from '@/components/Form/TextAreaV3.vue';
import InputTextV3 from '@/components/Form/InputTextV3.vue';

export default {
    name: 'Form',
    components: {
        AlertaMensagem,
        TituloModulo,
        CarregandoLogoEmpresa,
        BarraNavegacaoBotaoV3,
        FormDados,
        FormAnexos,
        FormAvaliacao,
        TextAreaV3,
        InputTextV3
    },
    async created() {},
    async mounted() {
        this.id_plano_desenvolvimento_individual = parseInt(this.$route.params.id_plano_desenvolvimento_individual) ?? null;

        await this.buscaForm();
    },
    computed: {
        ...mapGetters(['botoesComPermissao']),
        ...mapState(['carregando'])
    },
    watch: {},
    methods: {
        ...mapActions(['montaNavegacao', 'mostraCarregando']),
        async buscaPermissoes() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.planodesenvolvimentoindividual.permissao}`,
                data: {},
                mostraCarregando: false
            })
                .then(async resposta => {
                    const permissoes = resposta.data.data.atributos;

                    await this.montaNavegacao({ botoes: this.botoes, permissoes });
                })
                .catch(error => {
                    throw new Error(error);
                });
        },
        preencheDados(dadosForm) {
            const plano = PreencheDadosMesmaPropriedade(this.plano_desenvolvimento_individual, dadosForm.pdi);

            plano.ponto = [];

            let forte = [];
            let desenvolver = [];
            let avaliacao = [];

            dadosForm.pdi.ponto.map(ponto => {
                if (ponto.tipo_ponto == PontoTipoEnum.FORTE) {
                    forte.push(PreencheDadosMesmaPropriedade(this.plano_desenvolvimento_individual.ponto.forte, ponto));
                }

                if (ponto.tipo_ponto == PontoTipoEnum.DESENVOLVER) {
                    desenvolver.push(PreencheDadosMesmaPropriedade(this.plano_desenvolvimento_individual.ponto.forte, ponto));
                }
            });

            const anexo = dadosForm.pdi.documento_anexo.map(anexo => {
                return PreencheDadosMesmaPropriedade(this.plano_desenvolvimento_individual.anexo, anexo);
            });

            avaliacao = dadosForm.avaliacao.map(avaliacao => {
                avaliacao.selecionado = false;

                const encontrouAvaliacaoPdi = dadosForm.pdi.avaliacao_pdi.find(
                    avaliacaoPdi =>
                        avaliacaoPdi.id_plano_desenvolvimento_individual_avaliacao ===
                            avaliacao.id_plano_desenvolvimento_individual_avaliacao && avaliacaoPdi.selecionado == 'S'
                );

                if (encontrouAvaliacaoPdi) {
                    avaliacao.selecionado = true;
                }

                return PreencheDadosMesmaPropriedade(this.plano_desenvolvimento_individual.avaliacao, avaliacao);
            });

            this.form = plano;
            this.form.ponto.forte = forte;
            this.form.ponto.desenvolver = desenvolver;
            this.form.anexo = anexo;
            this.form.avaliacao = avaliacao;
        },
        async buscaForm() {
            try {
                this.mostraCarregando(true);

                let urlConfig = {
                    method: 'GET',
                    url: `${API_URL.erp.planodesenvolvimentoindividual.busca}/${this.id_plano_desenvolvimento_individual}`,
                    data: {},
                    mostraCarregando: false
                };

                await ApiService(urlConfig)
                    .then(async resposta => {
                        this.permissao = true;

                        await this.buscaPermissoes();

                        this.pdiSituacao = resposta.data.data.atributos.pdi.situacao;

                        this.preencheDados(resposta.data.data.atributos);
                    })
                    .catch(erro => {
                        Alerta([erro.message], 'error');
                    });
            } catch (erro) {
                Alerta([erro.message], 'error');
            } finally {
                this.mostraCarregando(false);
            }
        },
        async salvaForm() {
            try {
                this.tabAnexoTotalErros = 0;

                if (!this.$refs.formAnexosRef.$refs.form.validate()) {
                    this.tabAnexoTotalErros = 1;
                    throw new Error('Preencha pelo menos 1 anexo para salvar.');
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', 'Salvar ?', {
                        cor: 'green'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        let conteudoFormulario = this.FormataDadosParaEnviarForm();

                        this.mensagemCarregando = 'Aguarde, salvando os anexos...';

                        this.mostraCarregando(true);

                        await sleep(2000);

                        let urlConfig = {
                            method: 'POST',
                            url: `${API_URL.erp.planodesenvolvimentoindividual.enviaDocumentos}`,
                            data: conteudoFormulario,
                            mostraCarregando: false,
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                const anexo = resposta.data.conteudo.documento_anexo.map(anexo => {
                                    return PreencheDadosMesmaPropriedade(this.plano_desenvolvimento_individual.anexo, anexo);
                                });

                                this.form.anexo = anexo;

                                this.notificacao = {
                                    mensagem: 'Arquivo(s) salvo(s)',
                                    tipo: 'success',
                                    mostra: true
                                };
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });

                        this.mostraCarregando(false);
                    });
            } catch (erro) {
                this.mostraCarregando(false);

                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        async concluirForm() {
            try {
                await this.$root.$refs.$confirmaAcaoModal
                    .open(
                        'Salvar',
                        'Deseja concluir o PDI ? \r\n O mesmo será encerrado e ficará pendete da aprovação do gestor',
                        {
                            cor: 'green'
                        }
                    )
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        this.mensagemCarregando = 'Aguarde, salvando os dados...';

                        this.mostraCarregando(true);

                        await sleep(2000);

                        let urlConfig = {
                            method: 'PUT',
                            url: `${API_URL.erp.planodesenvolvimentoindividual.conclui}/${this.id_plano_desenvolvimento_individual}`,
                            data: {},
                            mostraCarregando: false
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                this.$router.push({
                                    name: 'plano-desenvolvimento-individual',
                                    params: {
                                        mensagem: `PDI ${this.id_plano_desenvolvimento_individual} concluído, enviado e-mail de notificação para o gestor aprovar.`
                                    }
                                });
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });

                        this.mostraCarregando(false);
                    });
            } catch (erro) {
                this.mostraCarregando(false);

                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        async aprovarForm() {
            try {
                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', 'Deseja aprovar o PDI ?', {
                        cor: 'green'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        const form = this.$refs.formAvaliacaoRef.preparaDados();

                        this.mensagemCarregando = 'Aguarde, aprovando o PDI...';

                        this.mostraCarregando(true);

                        await sleep(2000);

                        let urlConfig = {
                            method: 'PUT',
                            url: `${API_URL.erp.planodesenvolvimentoindividual.aprova}/${this.id_plano_desenvolvimento_individual}`,
                            data: {
                                dados: {
                                    avaliacao: form
                                }
                            },
                            mostraCarregando: false
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                this.$router.push({
                                    name: 'plano-desenvolvimento-individual',
                                    params: {
                                        mensagem: `PDI ${this.id_plano_desenvolvimento_individual} aprovado e foi finalizado.`
                                    }
                                });
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });

                        this.mostraCarregando(false);
                    });
            } catch (erro) {
                this.mostraCarregando(false);

                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        FormataDadosParaEnviarForm() {
            let formData = new FormData();

            formData.append('id_registro', this.form.id_plano_desenvolvimento_individual);

            const anexos = this.$refs.formAnexosRef.preparaDados();

            anexos.map(anexo => {
                formData.append('caminho[]', anexo);
            });

            return formData;
        },
        cancelaForm() {
            this.$router.push({ name: 'plano-desenvolvimento-individual' });
        }
    },
    data() {
        return {
            permissao: false,
            permissoes: [],
            mensagemCarregando: 'Aguarde, preparando plano desenvolvimento individual...',
            botoes: [
                {
                    id_permissao: 3,
                    nome: 'Salvar',
                    acao: 'salvaForm',
                    icone: 'mdi-newspaper-variant-multiple',
                    cor: 'green',
                    form: [SituacaoForm.NOVO, SituacaoForm.ATUALIZA]
                },
                {
                    id_permissao: 26,
                    nome: 'Aprovar',
                    acao: 'aprovarForm',
                    icone: 'mdi-alert-decagram-outline',
                    cor: 'blue',
                    form: [SituacaoForm.NOVO, SituacaoForm.ATUALIZA, SituacaoForm.AGUARDANDO_APROVACAO]
                },
                {
                    id_permissao: 3,
                    nome: 'Fechar',
                    acao: 'cancelaForm',
                    icone: 'mdi-file-cancel',
                    cor: 'orange',
                    form: [SituacaoForm.TODOS]
                }
            ],
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            tabComponente: 0,
            tabAnexoTotalErros: 0,
            tabAvaliacaoTotalErros: 0,
            pdiSituacao: null,
            form: {
                id_plano_desenvolvimento_individual: null,
                situacao: null,
                ponto: {
                    forte: {},
                    desenvolver: {}
                },
                anexo: [],
                avaliacao: []
            },
            situacaoAberto: SituacaoEnum.ABERTO,
            anexoData: [],
            regrasPontoForte: [Obrigatorio(), Minimo(10), Maximo(3000)],
            id_plano_desenvolvimento_individual: null,
            plano_desenvolvimento_individual: {
                id_plano_desenvolvimento_individual: null,
                nome_colaborador: null,
                nome_colaborador_gestor: null,
                nome: null,
                orientacao: null,
                orientacao_criterio: null,
                situacao: null,
                ponto: {
                    forte: {
                        id_plano_desenvolvimento_individual_ponto: null,
                        id_plano_desenvolvimento_individual: null,
                        ponto: null,
                        descricao: null,
                        tipo_ponto: null
                    },
                    desenvolver: {
                        id_plano_desenvolvimento_individual_ponto: null,
                        id_plano_desenvolvimento_individual: null,
                        ponto: null,
                        descricao: null,
                        tipo_ponto: null
                    }
                },
                anexo: {
                    id_documento_anexo: null,
                    caminho: null,
                    nome: null
                },
                avaliacao: {
                    id_plano_desenvolvimento_individual_avaliacao: null,
                    descricao: null,
                    selecionado: false
                },
                avaliacao_pdi: {
                    id_plano_desenvolvimento_individual_avaliacao: null,
                    selecionado: null
                }
            }
        };
    }
};
</script>

<style></style>
