<template>
    <div class="quillEditorPosicao">
        <strong>{{ titulo }}</strong>
        <input type="file" id="file" accept="image/png, image/gif, image/jpeg" hidden />

        <quillEditor
            :value="value"
            @input="$emit('input', $event)"
            :options="editorOption"
            class="editor"
            ref="quillEdit"
        ></quillEditor>
    </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';

export default {
    name: 'QuillEditorV2',
    props: {
        value: {
            type: String | null,
            default: ''
        },
        titulo: {
            type: String,
            default: () => ''
        }
    },
    components: {
        quillEditor
    },
    data: () => ({
        editorOption: {
            modules: {
                toolbar: {
                    container: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ script: 'sub' }, { script: 'super' }],
                        [{ indent: '-1' }, { indent: '+1' }],
                        [{ direction: 'rtl' }],
                        [{ size: ['small', false, 'large', 'huge'] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ font: [] }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['clean'],
                        ['link', 'image', 'video']
                    ],
                    handlers: {
                        image: function() {
                            document.getElementById('file').click();
                        }
                    }
                },
                syntax: {
                    highlight: text => hljs.highlightAuto(text).value
                }
            }
        }
    }),
    methods: {},
    computed: {}
};
</script>

<style>
.quillEditorPosicao {
    display: flex;
    flex-direction: column;
}
.editor {
    height: 20rem;
}

.ql-editor > p > img {
    width: 300px;
}
</style>
