import { IMAGEM_TIPO } from './media-tipos.js';

function mediaTipo(tipos, mime_type) {
    if (!mime_type || !mime_type) return;
    return tipos.some(t => mime_type.toLowerCase().includes(t));
}

export function imagemArquivo(mime_type) {
    return mediaTipo(IMAGEM_TIPO, mime_type);
}
