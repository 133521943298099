<template>
    <div>
        <TituloModulo />

        <CarregandoOverlay :overlay="overlay" mensagem="Preparando relatório, aguarde..." />

        <v-card outlined elevation="2" class="mb-4">
            <v-card-title>
                Filtros:
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-badge color="error" overlap offset-y="20" offset-x="18">
                            <v-icon @click="limpaFiltros()" slot="badge">
                                mdi-close
                            </v-icon>

                            <v-chip v-bind="attrs" v-on="on" class="ma-2" color="warning" label text-color="white">
                                <v-icon left>
                                    mdi-filter-check
                                </v-icon>

                                {{ totalFiltrosAplicados }}
                            </v-chip>
                        </v-badge>
                    </template>
                    <span>Filtros aplicados, clique no botão X para remover todos</span>
                </v-tooltip>
            </v-card-title>

            <v-card-text dense>
                <v-row>
                    <v-col cols="12" md="12">
                        <InputSelectV3
                            v-model="id_filial"
                            rotulo="Filial"
                            :regra="regrasFilial"
                            :itens="opcaoFilial"
                            :carregando="opcaoFilial.length > 0 ? false : true"
                            itemTexto="razao_social"
                            itemValor="id_filial"
                            ref="filial"
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <DateTimePickerV2
                            v-model="dataDe"
                            :dataInicial="dataDe"
                            label="Data criação de:"
                            data-vv-delay="1000"
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <DateTimePickerV2
                            v-model="dataAte"
                            :dataInicial="dataAte"
                            label="Data criação até:"
                            data-vv-delay="1000"
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <ComboBoxPreenchimentoV3
                            v-model="filtros.numero_cte"
                            tituloBusca="Número CTe"
                            :smallChips="true"
                            :deletableChips="true"
                            :multiple="true"
                            itemValor="numero_cte"
                            itemTexto="numero_cte"
                            clearable
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <ComboBoxPreenchimentoV3
                            v-model="filtros.id_programacao_carga_rodopar"
                            tituloBusca="Programação Carga Rodopar"
                            :smallChips="true"
                            :deletableChips="true"
                            :multiple="true"
                            itemValor="id_programacao_carga_rodopar"
                            itemTexto="id_programacao_carga_rodopar"
                            clearable
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <InputAutoCompleteV3
                            v-model="filtros.id_tomador_servico"
                            :datatableConfiguracao="autocomplete.id_tomador_servico.datatable"
                            :itemsOpcoes="autocomplete.id_tomador_servico.complemento"
                            :contador="200"
                            tituloBusca="Cliente Rodopar Busca..."
                            itemValor="id_parceiro"
                            itemTexto="razao_social"
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <InputAutoCompleteV3
                            v-model="filtros.id_grupo_cliente"
                            :datatableConfiguracao="autocomplete.id_grupo_cliente.datatable"
                            :itemsOpcoes="autocomplete.id_grupo_cliente.complemento"
                            :contador="200"
                            tituloBusca="Grupo Cliente Rodopar Busca..."
                            itemValor="id_grupo_cliente"
                            itemTexto="descricao"
                        />
                    </v-col>

                    <v-col cols="12" align="right">
                        <v-btn :loading="overlay" elevation="2" color="warning" @click="downloadArquivo('xlsx')">
                            Gera Excel
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <div class="text-center">
            <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                {{ notificacao.mensagem }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { API_URL, API_URL_BASE } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { DownloadArquivo } from '@/helpers/Funcoes.js';

import moment from 'moment';

import CarregandoOverlay from '@/components/Default/CarregandoOverlay.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';
import InputTextV3 from '@/components/Form/InputTextV3.vue';
import InputAutoCompleteV3 from '@/components/Form/InputAutoCompleteV3.vue';
import DateTimePickerV2 from '@/components/Form/DateTimePickerV2.vue';
import ComboBoxPreenchimentoV3 from '@/components/Form/ComboBoxPreenchimentoV3.vue';

export default {
    name: 'OrderToDelivery',
    components: {
        CarregandoOverlay,
        TituloModulo,
        InputSelectV3,
        InputTextV3,
        DateTimePickerV2,
        ComboBoxPreenchimentoV3,
        InputAutoCompleteV3
    },
    mounted() {
        this.preencheFilial();
    },
    computed: {
        totalFiltrosAplicados() {
            var total = 0;

            var filtrosAplicados = this.filtros;

            Object.keys(filtrosAplicados).forEach(function(filtro, index) {
                if (Array.isArray(filtrosAplicados[filtro])) {
                    total += filtrosAplicados[filtro].length;

                    return;
                }

                total += filtrosAplicados[filtro] == null ? 0 : 1;
            });

            return total;
        }
    },
    methods: {
        limpaFiltros() {
            var filtrosAplicados = this.filtros;

            Object.keys(filtrosAplicados).forEach(function(filtro, index) {
                if (Array.isArray(filtrosAplicados[filtro])) {
                    filtrosAplicados[filtro] = [];

                    return;
                }

                filtrosAplicados[filtro] = null;
            });
        },
        async preencheFilial() {
            await ApiService({
                method: 'get',
                url: `V1/filial/lista?fields[filial]=id_filial,filial_descricao`,
                data: {},
                mostraCarregando: false
            })
                .then(resposta => {
                    this.opcaoFilial = resposta.data.data.map(filial => {
                        const dados = {};

                        dados.id_filial = filial.id_registro;
                        dados.razao_social = filial.atributos.filial_descricao;

                        return dados;
                    });

                    this.id_filial = this.opcaoFilial[0].id_filial;
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        },
        async downloadArquivo(tipoArquivo) {
            try {
                this.overlay = true;

                const parametros = {
                    id_filial: this.id_filial,
                    data_de: this.dataDe,
                    data_ate: this.dataAte
                };

                if (!!this.filtros.numero_cte.length) {
                    parametros.numero_cte = this.filtros.numero_cte;
                }

                if (!!this.filtros.id_programacao_carga_rodopar.length) {
                    parametros.id_programacao_carga_rodopar = this.filtros.id_programacao_carga_rodopar;
                }

                if (!!this.filtros.id_tomador_servico) {
                    parametros.id_tomador_servico = this.filtros.id_tomador_servico;
                }

                if (!!this.filtros.id_grupo_cliente) {
                    parametros.id_grupo_cliente = this.filtros.id_grupo_cliente;
                }

                await ApiService({
                    method: 'GET',
                    url: API_URL.erp.relatorio.programacaoCarga.orderToDelivery,
                    responseType: 'blob',
                    mostraCarregando: false,
                    params: parametros
                })
                    .then(resposta => {
                        DownloadArquivo(resposta.data, resposta.headers['x-suggested-filename']);

                        this.notificacao = {
                            mensagem: 'Relatório gerado, verifique a pasta de Downloads',
                            tipo: 'warning',
                            mostra: true
                        };
                    })
                    .catch(erro => {
                        this.notificacao = {
                            mensagem: erro.message,
                            tipo: 'error',
                            mostra: true
                        };
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.overlay = false;
            }
        }
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            form: {
                nome: '',
                razao_social: '',
                cpf: null,
                cnpj: null
            },
            filtros: {
                numero_cte: [],
                id_programacao_carga_rodopar: [],
                id_tomador_servico: null,
                id_grupo_cliente: null
            },
            id_filial: null,
            regrasFilial: [v => !!v || 'Campo é obrigatório'],
            opcaoFilial: [],
            autocomplete: {
                id_tomador_servico: {
                    id_registro: null,
                    complemento: [{ id_parceiro: null, razao_social: '' }],
                    datatable: {
                        id_datatable: 'parceiro_rodopar_busca',
                        chave_primaria: 'id_tomador_servico',
                        urlBusca: `${API_URL_BASE}${API_URL.erp.parceiro.autoCompletaParceiroRodopar}`,
                        colunas: [
                            {
                                id: 'id_parceiro',
                                nome: 'id_parceiro',
                                descricao: 'ID'
                            },
                            {
                                id: 'razao_social',
                                nome: 'razao_social',
                                descricao: 'Razão Social'
                            }
                        ]
                    }
                },
                id_grupo_cliente: {
                    id_registro: null,
                    complemento: [{ id_grupo_cliente: null, descricao: '' }],
                    datatable: {
                        id_datatable: 'grupo_cliente_rodopar_busca',
                        chave_primaria: 'id_grupo_cliente',
                        urlBusca: `${API_URL_BASE}${API_URL.erp.grupoCliente.autoCompletaRodopar}`,
                        colunas: [
                            {
                                id: 'id_grupo_cliente',
                                nome: 'id_grupo_cliente',
                                descricao: 'ID'
                            },
                            {
                                id: 'descricao',
                                nome: 'descricao',
                                descricao: 'Descrição'
                            }
                        ]
                    }
                }
            },
            dataDe: moment()
                .startOf('month')
                .format('YYYY-MM-DD'),
            dataAte: moment()
                .startOf('day')
                .format('YYYY-MM-DD'),
            overlay: false
        };
    }
};
</script>
