const IMPACTO         = 1;
const G3              = 2;
const POTENCIAL       = 3;
const STO_ANTONIO     = 4;
const SOBRE_RODAS_SSZ = 5;
const TRANSPARENCY    = 6;
const TACT_CARGO      = 7;
const EM_VIAGEM       = 8;
const EADI            = 9;
const EXPORTACAO      = 10;
const TRANSMARTYNA    = 11;
const SOBRE_RODAS_TBE = 12;
const CGM = 13;
const TSA = 14;
const GARPE = 15;
const PERSONNALITE = 16;
const NEXT = 17;
const TERRA_NOVA = 18;


export function descricao() {
    return [
        { label: 'CGM', value: CGM },
        { label: 'EM VIAGEM', value: EM_VIAGEM },
        { label: 'EADI', value: EADI },
        { label: 'EXPORTAÇÃO', value: EXPORTACAO },
        { label: 'GARPE', value: GARPE},
        { label: 'G3', value: G3 },
        { label: 'IMPACTO', value: IMPACTO },
        { label: 'NEXT', value: NEXT},
        { label: 'PERSONNALITE', value: PERSONNALITE},
        { label: 'POTENCIAL', value: POTENCIAL },
        { label: 'SOBRE RODAS TBE', value: SOBRE_RODAS_TBE },
        { label: 'SOBRE RODAS SSZ', value: SOBRE_RODAS_SSZ },
        { label: 'STO ANTONIO', value: STO_ANTONIO },
        { label: 'TACT CARGO', value: TACT_CARGO },
        { label: 'TERRA NOVA', value: TERRA_NOVA },
        { label: 'TRANSMARTYNA', value: TRANSMARTYNA },
        { label: 'TRANSPARENCY', value: TRANSPARENCY },
        { label: 'TSA', value: TSA },
    ];
}
