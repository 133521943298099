<template>
    <v-switch
        v-model="valor"
        inset
        :label="`${label}`"
        :disabled="desativado"
        color="green darken-3"
        @change="$emit('input', $event)"
    >
        <template v-if="mostraIcone" v-slot:label>
            <v-icon v-if="valor" color="green darken-3">mdi-thumb-up</v-icon>
            <v-icon v-else color="red darken-3">mdi-thumb-down</v-icon>
        </template>
    </v-switch>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: () => false
        },
        desativado: {
            type: Boolean,
            default: () => false
        },
        mostraIcone: {
            type: Boolean,
            default: () => false
        },
        rotulo: {
            type: String,
            default: () => null
        }
    },
    async created() {},
    computed: {
        label() {
            let texto = this.rotulo ?? '';

            if (this.valor) {
                return `${texto} Sim`;
            }

            return `${texto} Não`;
        }
    },
    watch: {
        value: {
            handler: function(novoValor) {
                this.valor = novoValor;
            },
            deep: true
        }
    },
    mounted() {},
    methods: {},
    data() {
        return {
            valor: this.value
        };
    }
};
</script>

<style scoped>
.v-input--selection-controls {
    margin-top: 5px;
}

.theme--light.v-input--switch .v-input--switch__thumb {
    color: red;
}

.theme--light.v-input--switch .v-input--switch__track {
    color: red;
}
</style>
