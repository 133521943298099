<template>
    <div style="padding-bottom: 30px">
        <v-app-bar
            :collapse="!collapseOnScroll"
            :collapse-on-scroll="collapseOnScroll"
            dense
            color="tnlogAzul"
            app
            scroll-target="#scrolling-techniques"
            style="margin-top: 48px; height: 45px"
        >
            <v-app-bar-nav-icon>
                <v-icon>mdi-newspaper-variant-multiple</v-icon>
            </v-app-bar-nav-icon>

            <v-toolbar-title>{{ titulo }}</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
                depressed
                v-on:click="botaoAcao(btn.acao, btn.componente)"
                v-bind:class="[
                    btn.cor ? btn.cor : btnCorPadrao,
                    'btnBarraNavegacao',
                ]"
                :key="btn.id_permissao"
                :id="`${btn.identificador}`"
                v-for="btn in botoes"
            >
                <v-icon left v-if="btn.icone">
                    {{ btn.icone }}
                </v-icon>
                {{ btn.nome }}
            </v-btn>
        </v-app-bar>
    </div>
</template>

<script>
export default {
    name: 'BarraNavegacaoBotao',
    props: {
        titulo: {
            type: String,
        },
        referenciaComponenete: {
            type: String,
        },
        botoes: {
            type: Array,
            default: [],
        },
    },
    components: {},
    async created() {},
    data: () => ({
        collapseOnScroll: true,
        btnCorPadrao: 'primary',
    }),
    methods: {
        botaoAcao(nome_funcao, componente) {
            if (componente == 'datatable') {
                this.$parent.$refs.Datatable[nome_funcao]();

                return false;
            }

            this.$parent.$refs[this.referenciaComponenete][nome_funcao]();
        },
    },
};
</script>
<style>
.btnBarraNavegacao {
    margin-right: 5px;
}
.fixed-bar {
    position: sticky;
    position: -webkit-sticky; /* for Safari */
    top: 6em;
    z-index: 2;
}
</style>
