<template>
    <v-dialog v-model="modal" :max-width="opcao.tamanho" :style="{ zIndex: opcao.zIndex }" @keydown.esc="cancel">
        <v-card>
            <v-toolbar dark :color="opcao.cor" dense flat>
                <v-toolbar-title class="white--text">{{ titulo }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text v-show="!!mensagem" class="pa-4">
                <div v-for="(text, index) in mensagem.split('\n ')" :key="index">
                    <p><span v-html="text"></span></p>
                </div>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click.native="cancela">Cancelar</v-btn>

                <v-btn color="green darken-1" text @click.native="concorda">Sim</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    data: () => ({
        modal: false,
        resolve: null,
        rejeita: null,
        mensagem: '',
        titulo: null,
        opcao: {
            cor: 'primary',
            tamanho: 500,
            zIndex: 200
        }
    }),
    computed: {},
    methods: {
        open(titulo, mensagem, opcao) {
            this.modal = true;
            this.titulo = titulo;
            this.mensagem = mensagem;
            this.opcao = Object.assign(this.opcao, opcao);
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.rejeita = reject;
            });
        },
        concorda() {
            this.resolve(true);
            this.modal = false;
        },
        cancela() {
            this.resolve(false);
            this.modal = false;
        }
    }
};
</script>
