import _ from 'lodash';
import { mapGetters, mapActions, mapState } from 'vuex';

import moment from 'moment';

import { API_URL } from '@/config/configs';
import { Alerta, AlertaEsconde } from '@/helpers/Funcoes.js';
import ApiService from '@/services/ApiService.js';
import DatatableHelper from '@/helpers/Datatables.js';
import SituacaoForm from '../Enum/SituacaoForm.js';

const FormMixins = {
    async mounted() {
        this.id_whatsapp_marketing = parseInt(this.$route.params.id_whatsapp_marketing) ?? null;

        this.mostraCarregando(true);

        await this.dadosPaginaInicial();

        this.mostraCarregando(false);
    },
    computed: {
        ...mapGetters(['botoesComPermissao']),
        ...mapState(['carregando'])
    },
    methods: {
        ...mapActions(['montaNavegacao', 'mostraCarregando']),
        async dadosPaginaInicial() {
            try {
                this.mensagemCarregando = "Lendo permissões"

                await this.buscaPermissoes();

                if (this.id_whatsapp_marketing) {
                    await this.buscaForm();
                }

                this.permissao = true;
            } catch (erro) {
                Alerta([erro.message], 'error');
            }
        },
        async buscaPermissoes() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.whatsappmarketing.permissao}`,
                data: {},
                mostraCarregando: false
            })
                .then(async resposta => {
                    const permissoes = resposta.data.data.atributos;

                    await this.montaNavegacao({ botoes: this.botoes, permissoes });
                })
                .catch(error => {
                    throw new Error(error);
                });
        },
        async buscaForm() {
            try {
                this.mensagemCarregando = "Carregando dados"

                let urlConfig = {
                    method: 'GET',
                    url: `${API_URL.erp.whatsappmarketing.busca}/${this.id_whatsapp_marketing}`,
                    data: {},
                    mostraCarregando: false
                };

                await ApiService(urlConfig)
                    .then(async resposta => {
                        if (!resposta.data.data.id_registro) {
                            throw new Error("Registro não encontrado");
                        }

                        resposta.data.data.atributos.id_whatsapp_marketing = resposta.data.data.id_registro;

                        this.preencheDados(resposta.data.data.atributos);
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                throw new Error(erro.message);
            }
        },
        preencheDados(dadosForm) {
            this.whatsappMarketingSituacao = parseInt(dadosForm.situacao);

            const contatos = dadosForm.contatos.map(function (item) {
                return {
                    id_whatsapp_marketing_contato: item.id_whatsapp_marketing_contato,
                    telefone: item.telefone
                };
            });

            this.form = {
                id_whatsapp_marketing: dadosForm.id_whatsapp_marketing,
                id_whatsapp_numero_atendimento: dadosForm.id_whatsapp_numero_atendimento,
                id_whatsapp_mensagem_template: dadosForm.id_whatsapp_mensagem_template,
                situacao: dadosForm.situacao,
                descricao: dadosForm.descricao,
                template: dadosForm.template,
                observacao: dadosForm.observacao,
                data_hora_envio: moment(dadosForm.data_hora_envio, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm'),
                contatos: contatos
            };
        },
        FormataDadosParaEnviarForm() {
            const contatos = this.$refs.formDados.form.contatos.map(item => {
                let telefone = item.telefone.toString();

                telefone = parseInt(telefone.replace(/\D/g, ''));

                return {
                    id_whatsapp_marketing_contato: item.id_whatsapp_marketing_contato,
                    telefone: telefone
                };
            });

            return {
                id_whatsapp_numero_atendimento: this.$refs.formDados.form.id_whatsapp_numero_atendimento,
                id_whatsapp_mensagem_template: this.$refs.formDados.form.id_whatsapp_mensagem_template,
                descricao: this.$refs.formDados.form.descricao,
                template: this.$refs.formDados.form.template,
                observacao: this.$refs.formDados.form.observacao,
                data_hora_envio: moment(this.$refs.formDados.form.data_hora_envio, 'DD/MM/YYYY hh:mm').format('YYYY-MM-DD HH:mm:ss'),
                contatos: contatos
            };
        },
        fechaForm() {
            this.$router.push({ name: 'whatsapp-marketing' });
        },
        async salvaForm() {
            try {
                AlertaEsconde();

                this.validationMessage = [];
                this.tabDadosTotalErros = 0;

                if (!this.$refs.formDados.$refs.form.validate()) {
                    this.tabDadosTotalErros += 1;

                    this.validationMessage.push('Dados contém campos inválidos');
                }

                if (this.validationMessage.length > 0) {
                    Alerta(this.validationMessage, 'error');

                    return;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', 'Salvar registro ?', {
                        cor: 'green'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        this.mostraCarregando(true);

                        this.mensagemCarregando = 'Salvando dados, aguarde...';

                        const id_whatsapp_marketing = this.$refs.formDados.form.id_whatsapp_marketing;

                        let conteudoFormulario = this.FormataDadosParaEnviarForm();

                        let urlForm = API_URL.erp.whatsappmarketing.salva;
                        let metodo = 'POST';

                        if (id_whatsapp_marketing) {
                            urlForm = `${API_URL.erp.whatsappmarketing.salva}/${id_whatsapp_marketing}`;
                            metodo = 'PUT';
                        }

                        let urlConfig = {
                            method: metodo,
                            url: urlForm,
                            data: conteudoFormulario,
                            mostraCarregando: false
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                this.notificacao = {
                                    mensagem: `Marketing salvo.`,
                                    tipo: 'success',
                                    mostra: true
                                };

                                resposta.data.data.atributos.id_whatsapp_marketing = resposta.data.data.id_registro;

                                this.preencheDados(resposta.data.data.atributos);
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (erro) {
                Alerta([erro.message], 'error');
            }

            this.mostraCarregando(false);
        },
        async apagaForm() {
            try {
                let dados = DatatableHelper.datatableGetLinhaSelecionadas(this.moduloNome);

                let ids = dados
                    .map(function (dado) {
                        return dado.DT_RowId;
                    })
                    .join();

                if (!ids) {
                    this.$emit('mensagemAlerta', 'Selecione um registro antes de apagar.', 'error');

                    return false;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Deleta', `Apagar registro(s) ?\n IDS - ${ids}`, {
                        cor: 'red'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        let urlConfig = {
                            method: 'DELETE',
                            url: `${API_URL.erp[this.moduloNome].apaga}/${ids}`
                        };

                        let dados = await ApiService(urlConfig)
                            .then(resposta => {
                                this.$emit('datatableRecarrega');

                                this.$emit('mensagemAlerta', `Registro ${ids} deletado com sucesso!`, 'success');
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        }
    },
    data() {
        return {
            tabDadosTotalErros: 0,
            mensagemCarregando: 'Aguarde, buscando dados...',
            permissao: false,
            permissoes: [],
            whatsappMarketingSituacao: 1,
            botoes: [
                {
                    id_permissao: [3],
                    nome: 'Salvar',
                    acao: 'salvaForm',
                    icone: 'mdi-newspaper-variant-multiple',
                    cor: 'green',
                    form: [SituacaoForm.ABERTO]
                },
                {
                    id_permissao: [1],
                    nome: 'Fechar',
                    acao: 'fechaForm',
                    icone: 'mdi-file-cancel',
                    cor: 'orange',
                    form: ['todos']
                }
            ],
            id_whatsapp_marketing: SituacaoForm.ABERTO,
            tabComponente: 'Dados',
            chavePrimaria: 'id_whatsapp_marketing',
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            form: {
                id_whatsapp_marketing: null,
                id_whatsapp_numero_atendimento: null,
                id_whatsapp_mensagem_template: null,
                situacao: null,
                descricao: null,
                template: null,
                observacao: null,
                data_hora_envio: null,
                contatos: []
            }
        };
    }
};

export default FormMixins;
