<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" sm="12">
                <v-stepper v-model="passo" alt-labels>
                    <v-stepper-header>
                        <v-stepper-step
                            :complete="passo > 1"
                            step="1"
                            :color="passo > 1 ? 'green darken-1' : 'deep-orange lighten-1'"
                            :complete-icon="passo > 1 ? 'mdi-check-all' : 'mdi-check'"
                        >
                            Cliente
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                            :complete="passo > 2"
                            step="2"
                            :color="passo > 2 ? 'green darken-1' : 'deep-orange lighten-1'"
                            :complete-icon="passo > 2 ? 'mdi-check-all' : 'mdi-check'"
                        >
                            Custo de Venda
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                            :complete="passo > 3"
                            step="3"
                            :color="passo > 3 ? 'green darken-1' : 'deep-orange lighten-1'"
                            :complete-icon="passo > 3 ? 'mdi-check-all' : 'mdi-check'"
                        >
                            Receita de Venda
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                            :complete="passo > 4"
                            step="4"
                            :color="passo > 4 ? 'green darken-1' : 'deep-orange lighten-1'"
                            :complete-icon="passo > 4 ? 'mdi-check-all' : 'mdi-check'"
                        >
                            Despesa Operacional
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step step="5" color="deep-orange lighten-1">
                            Revisão
                        </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-card elevation="0">
                                <v-overlay :absolute="true" opacity="0.8" :value="carregandoQualp">
                                    <CarregandoLogoEmpresa
                                        :carregando="carregandoQualp"
                                        mensagem="Calculando rota na API Qualp, aguarde..."
                                    />
                                </v-overlay>

                                <v-card-text>
                                    <form-cliente
                                        :id_profit_less_markup="id_profit_less_markup"
                                        :tipo_operacao="form.cliente.tipo_operacao"
                                        :valor_mercadoria="form.cliente.valor_mercadoria"
                                        :quantidade_embarques="form.cliente.quantidade_embarques"
                                        :rota_km="form.cliente.rota_km"
                                        :rota_duracao="form.cliente.rota_duracao"
                                        :pedagio="form.cliente.pedagio"
                                        :pedagio_eixo="form.cliente.pedagio_eixo"
                                        :quantidade_eixos="form.cliente.quantidade_eixos"
                                        :calcular_ida_volta="form.cliente.calcular_ida_volta"
                                        :valor_carga="form.cliente.valor_carga"
                                        :tipo_frete="form.cliente.tipo_frete"
                                        :tipo_carga="form.cliente.tipo_carga"
                                        :endereco_origem="form.cliente.endereco_origem"
                                        :endereco_destino="form.cliente.endereco_destino"
                                        :id_parceiro="form.cliente.id_parceiro"
                                        :parceiroItens="clienteParceiroItens"
                                        :enderecoOrigemItens="enderecoOrigemItens"
                                        :enderecoDestinoItens="enderecoDestinoItens"
                                        :rotaDescricao="rotaDescricao"
                                        :pedagioItens="pedagios"
                                        @muda-quantidade-eixos="mudaQuantidadeEixos"
                                        @muda-valor-carga="mudaValorCarga"
                                        @muda-rota="mudaRota"
                                        ref="cliente"
                                    />
                                </v-card-text>

                                <v-card-actions>
                                    <v-row>
                                        <v-col cols="12" class="text-right">
                                            <v-btn color="grey darken-1" @click="cancelar()" dark>
                                                <v-icon small>
                                                    mdi-close
                                                </v-icon>

                                                Fechar
                                            </v-btn>

                                            <v-btn
                                                v-if="
                                                    form.situacao == situacaoAberto ||
                                                        form.situacao == situacaoAguardandoAjuste ||
                                                        form.situacao == situacaoAprovacao
                                                "
                                                class="ml-2"
                                                color="warning"
                                                :loading="carregandoQualp"
                                                @click="calcularRota()"
                                            >
                                                <v-icon>
                                                    mdi-routes
                                                </v-icon>

                                                Calcular Rota Qualp
                                            </v-btn>

                                            <v-btn
                                                class="ml-2"
                                                color="primary"
                                                :loading="carregando_proximo_passo"
                                                @click="proximoPassoCliente()"
                                            >
                                                <v-icon>
                                                    mdi-chevron-right
                                                </v-icon>

                                                Próximo
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <v-card elevation="0">
                                <v-card-text>
                                    <form-custo-venda
                                        :id_profit_less_markup_custo_venda="form.cliente.id_profit_less_markup_custo_venda"
                                        :valor_mercadoria="form.cliente.valor_mercadoria"
                                        :frete_custo_valor="form.custo_venda.frete_custo_valor"
                                        :frete_custo_percentual="form.custo_venda.frete_custo_percentual"
                                        :pedagio_valor="form.custo_venda.pedagio_valor"
                                        :pedagio_percentual="form.custo_venda.pedagio_percentual"
                                        :seguro_valor="form.custo_venda.seguro_valor"
                                        :seguro_percentual="form.receita_venda.seguro_percentual"
                                        :licenca_valor="form.custo_venda.licenca_valor"
                                        :licenca_percentual="form.custo_venda.licenca_percentual"
                                        :taxa_imo_anvisa_valor="form.custo_venda.taxa_imo_anvisa_valor"
                                        :taxa_imo_anvisa_percentual="form.custo_venda.taxa_imo_anvisa_percentual"
                                        :devolucao_container_vazio_valor="form.custo_venda.devolucao_container_vazio_valor"
                                        :devolucao_container_vazio_percentual="
                                            form.custo_venda.devolucao_container_vazio_percentual
                                        "
                                        :ajudante_valor="form.custo_venda.ajudante_valor"
                                        :ajudante_percentual="form.custo_venda.ajudante_percentual"
                                        :escolta_valor="form.custo_venda.escolta_valor"
                                        :escolta_percentual="form.custo_venda.escolta_percentual"
                                        :total_percentual="form.custo_venda.total_percentual"
                                        :custo_venda="form.custo_venda.total_valor"
                                        :tipo_frete="form.cliente.tipo_frete"
                                        ref="custo_venda"
                                    />
                                </v-card-text>

                                <v-card-actions>
                                    <v-row>
                                        <v-col cols="12" class="text-right">
                                            <v-btn color="grey darken-1" @click="cancelar()" dark>
                                                <v-icon small>
                                                    mdi-close
                                                </v-icon>

                                                Fechar
                                            </v-btn>

                                            <v-btn class="ml-2" color="warning" @click="voltaPasso()">
                                                <v-icon>
                                                    mdi-chevron-left
                                                </v-icon>

                                                Voltar
                                            </v-btn>

                                            <v-btn
                                                class="ml-2"
                                                color="primary"
                                                :loading="carregando_proximo_passo"
                                                @click="proximoPassoCustoVenda()"
                                            >
                                                <v-icon>
                                                    mdi-chevron-right
                                                </v-icon>

                                                Próximo
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content step="3">
                            <v-card elevation="0">
                                <v-card-text>
                                    <form-receita-venda
                                        v-if="passo > 2"
                                        :id_profit_less_markup_receita_venda="
                                            receita_venda.id_profit_less_markup_receita_venda
                                        "
                                        :valor_mercadoria="form.cliente.valor_mercadoria"
                                        :frete_custo_valor="form.custo_venda.frete_custo_valor"
                                        :pedagio_valor="form.receita_venda.pedagio_valor"
                                        :custo_venda_total="form.custo_venda.total_valor"
                                        :margem_lucro_percentual="margem_lucro_percentual"
                                        :frete_peso_valor="form.receita_venda.frete_peso_valor"
                                        :seguro_valor="form.receita_venda.seguro_valor"
                                        :seguro_percentual="form.receita_venda.seguro_percentual"
                                        :gris_valor="form.receita_venda.gris_valor"
                                        :gris_percentual="form.receita_venda.gris_percentual"
                                        :licenca_valor="form.receita_venda.licenca_valor"
                                        :taxa_imo_anvisa_valor="form.receita_venda.taxa_imo_anvisa_valor"
                                        :taxa_imo_anvisa_percentual="form.receita_venda.taxa_imo_anvisa_percentual"
                                        :despacho_valor="form.receita_venda.despacho_valor"
                                        :profit_calculado_valor="form.receita_venda.profit_calculado_valor"
                                        :profit_calculado_percentual="form.receita_venda.profit_calculado_percentual"
                                        :ajudante_valor="form.receita_venda.ajudante_valor"
                                        :escolta_valor="form.receita_venda.escolta_valor"
                                        :icms_valor="form.receita_venda.icms_valor"
                                        :icms_percentual="form.receita_venda.icms_percentual"
                                        :pis_cofins_valor="form.receita_venda.pis_cofins_valor"
                                        :pis_cofins_percentual="form.receita_venda.pis_cofins_percentual"
                                        :inss_empresa_valor="form.receita_venda.inss_empresa_valor"
                                        :inss_empresa_percentual="form.receita_venda.inss_empresa_percentual"
                                        :ir_csll_valor="form.receita_venda.ir_csll_valor"
                                        :ir_csll_percentual="form.receita_venda.ir_csll_percentual"
                                        :repasse_profit_valor="form.receita_venda.repasse_profit_valor"
                                        :repasse_profit_percentual="form.receita_venda.repasse_profit_percentual"
                                        :total_imposto="form.receita_venda.total_imposto"
                                        :total_valor="form.receita_venda.total_receita_venda_valor"
                                        :tipo_operacao="form.cliente.tipo_operacao"
                                        ref="receita_venda"
                                    />
                                </v-card-text>

                                <v-card-actions>
                                    <v-row>
                                        <v-col cols="12" class="text-right">
                                            <v-btn color="grey darken-1" @click="cancelar()" dark>
                                                <v-icon small>
                                                    mdi-close
                                                </v-icon>

                                                Fechar
                                            </v-btn>

                                            <v-btn class="ml-2" color="warning" @click="voltaPasso()">
                                                <v-icon>
                                                    mdi-chevron-left
                                                </v-icon>

                                                Voltar
                                            </v-btn>

                                            <v-btn
                                                class="ml-2"
                                                color="primary"
                                                :loading="carregando_proximo_passo"
                                                @click="proximoPassoReceitaVenda()"
                                            >
                                                <v-icon>
                                                    mdi-chevron-right
                                                </v-icon>

                                                Próximo
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content step="4">
                            <v-card elevation="0">
                                <v-card-text>
                                    <form-despesa-operacional
                                        v-if="passo > 3"
                                        :receita_venda_total="form.receita_venda.total_valor"
                                        :repasse_profit_valor="form.receita_venda.profit_calculado_valor"
                                        :repasse_profit_percentual="form.receita_venda.profit_calculado_percentual"
                                        :geral_administrativa_valor="form.despesa_operacional.geral_administrativa_valor"
                                        :geral_administrativa_percentual="
                                            form.despesa_operacional.geral_administrativa_percentual
                                        "
                                        :comissao_venda_valor="form.despesa_operacional.comissao_venda_valor"
                                        :comissao_venda_percentual="form.despesa_operacional.comissao_venda_percentual"
                                        :financeira_capital_giro_valor="form.despesa_operacional.financeira_capital_giro_valor"
                                        :financeira_capital_giro_percentual="
                                            form.despesa_operacional.financeira_capital_giro_percentual
                                        "
                                        :total_valor="form.despesa_operacional.total_valor"
                                        :total_percentual="form.despesa_operacional.total_percentual"
                                        ref="despesa_operacional"
                                    />
                                </v-card-text>

                                <v-card-actions>
                                    <v-row>
                                        <v-col cols="12" class="text-right">
                                            <v-btn color="grey darken-1" @click="cancelar()" dark>
                                                <v-icon small>
                                                    mdi-close
                                                </v-icon>

                                                Fechar
                                            </v-btn>

                                            <v-btn class="ml-2" color="warning" @click="voltaPasso()">
                                                <v-icon>
                                                    mdi-chevron-left
                                                </v-icon>

                                                Voltar
                                            </v-btn>

                                            <v-btn
                                                class="ml-2"
                                                color="primary"
                                                :loading="carregando_proximo_passo"
                                                @click="proximoPassoRevisao()"
                                            >
                                                <v-icon>
                                                    mdi-chevron-right
                                                </v-icon>

                                                Próximo
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content step="5">
                            <v-card elevation="0">
                                <v-card-text>
                                    <form-revisao
                                        v-if="passo > 4"
                                        :situacao="form.situacao"
                                        :parceiro_razao_social="form.revisao.parceiro_razao_social"
                                        :tipo_operacao_descricao="form.revisao.tipo_operacao_descricao"
                                        :rota_km="form.cliente.rota_km"
                                        :endereco_origem="form.revisao.endereco_origem"
                                        :endereco_destino="form.revisao.endereco_destino"
                                        :cliente="form.cliente"
                                        :custo_venda="form.custo_venda"
                                        :receita_venda="form.receita_venda"
                                        :despesa_operacional="form.despesa_operacional"
                                        :quantidade_embarques="form.cliente.quantidade_embarques"
                                        :reprovados="reprovados"
                                    />
                                </v-card-text>

                                <v-card-actions>
                                    <v-row>
                                        <v-col cols="12" class="text-right">
                                            <v-btn color="grey darken-1" @click="cancelar()" dark>
                                                <v-icon small>
                                                    mdi-close
                                                </v-icon>

                                                Fechar
                                            </v-btn>

                                            <v-btn class="ml-2" color="warning" @click="voltaPasso()">
                                                <v-icon>
                                                    mdi-chevron-left
                                                </v-icon>

                                                Voltar
                                            </v-btn>

                                            <v-btn
                                                v-if="
                                                    form.situacao == situacaoAberto ||
                                                        form.situacao == situacaoAguardandoAjuste
                                                "
                                                class="ml-2"
                                                color="red"
                                                :loading="carregando_proximo_passo"
                                                @click="terminar()"
                                                dark
                                            >
                                                <v-icon>
                                                    mdi-alert-outline
                                                </v-icon>

                                                Terminar
                                            </v-btn>

                                            <v-btn
                                                v-if="form.situacao == situacaoAprovacao"
                                                class="ml-2"
                                                color="red darken-2"
                                                :loading="carregando_proximo_passo"
                                                @click="recusar()"
                                                dark
                                            >
                                                <v-icon>
                                                    mdi-alert-octagon-outline
                                                </v-icon>

                                                Recusar
                                            </v-btn>

                                            <v-btn
                                                v-if="form.situacao == situacaoAprovacao"
                                                class="ml-2"
                                                color="green darken-2"
                                                :loading="carregando_proximo_passo"
                                                @click="aprovar()"
                                                dark
                                            >
                                                <v-icon>
                                                    mdi-check-decagram-outline
                                                </v-icon>

                                                Aprovar
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-col>
        </v-row>

        <RecusaModal ref="recusaModal" @salvar="salvaRecusa" />

        <MapsQUALPModal ref="mapsQUALPModal" />

        <div class="text-center">
            <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                {{ notificacao.mensagem }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-container>
</template>

<script>
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { sleep, PreencheDadosMesmaPropriedade } from '@/helpers/Funcoes.js';

import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import CardInfo from '@/components/Default/CardInfo.vue';
import FormCliente from './FormCliente.vue';
import FormCustoVenda from './FormCustoVenda.vue';
import FormReceitaVenda from './FormReceitaVenda.vue';
import FormDespesaOperacional from './FormDespesaOperacional.vue';
import FormRevisao from './FormRevisao.vue';
import RecusaModal from './RecusaModal.vue';
import MapsQUALPModal from './MapsQUALPModal.vue';

export default {
    name: 'FormDados',
    components: {
        CarregandoLogoEmpresa,
        CardInfo,
        FormCliente,
        FormCustoVenda,
        FormReceitaVenda,
        FormDespesaOperacional,
        FormRevisao,
        RecusaModal,
        MapsQUALPModal
    },
    props: {
        passoForm: {
            type: Number,
            default: () => 1
        },
        id_profit_less_markup: {
            type: Number,
            default: () => 1
        },
        situacao: {
            type: Number,
            default: () => 1
        },
        margem_lucro_percentual: {
            type: Number,
            default: () => 45
        },
        cliente: {
            type: Object,
            default: () => {}
        },
        custo_venda: {
            type: Object,
            default: () => {}
        },
        receita_venda: {
            type: Object,
            default: () => {}
        },
        despesa_operacional: {
            type: Object,
            default: () => {}
        },
        reprovados: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        passoForm: {
            handler() {
                this.passo = this.passoForm;
            },
            immediate: true
        },
        situacao: {
            handler() {
                this.form.situacao = this.situacao;
            },
            immediate: true
        },
        cliente: {
            handler() {
                this.preencheCliente();
            },
            immediate: true
        },
        custo_venda: {
            handler() {
                this.preencheCustoVenda();
            },
            immediate: true
        },
        receita_venda: {
            handler() {
                this.preencheReceitaVenda();
            },
            immediate: true
        },
        despesa_operacional: {
            handler() {
                this.preencheDespesaOperacional();
            },
            immediate: true
        }
    },
    mounted() {
        if (this.passo == 5) {
            this.parametrosRevisao();
        }
    },
    methods: {
        preencheCliente() {
            if (!this.cliente?.parceiro?.id_parceiro) {
                return;
            }

            this.clienteParceiroItens = [
                {
                    id_parceiro: this.cliente.parceiro.id_parceiro,
                    razao_social: this.cliente.parceiro.razao_social
                }
            ];

            this.enderecoOrigemItens = [
                {
                    endereco_completo: this.cliente.endereco_origem
                }
            ];

            this.enderecoDestinoItens = [
                {
                    endereco_completo: this.cliente.endereco_destino
                }
            ];

            this.form.cliente = {
                id_parceiro: this.cliente.id_parceiro,
                tipo_operacao: this.cliente.tipo_operacao,
                valor_mercadoria: this.cliente.valor_mercadoria,
                quantidade_embarques: this.cliente.quantidade_embarques,
                rota_km: this.cliente.rota_km,
                rota_duracao: this.cliente.rota_duracao,
                pedagio: this.cliente.rota_pedagio,
                pedagio_eixo: this.cliente.rota_pedagio_eixo,
                quantidade_eixos: this.cliente.quantidade_eixos,
                calcular_ida_volta: this.cliente.calcular_ida_volta == 'S' ? true : false,
                tipo_carga: this.cliente.tipo_carga,
                tipo_frete: this.cliente.tipo_frete,
                valor_carga: this.custo_venda.frete_custo_valor,
                endereco_origem: this.cliente.endereco_origem,
                endereco_destino: this.cliente.endereco_destino
            };
        },
        preencheCustoVenda() {
            if (!this.custo_venda?.id_profit_less_markup_custo_venda) {
                return;
            }

            this.form.custo_venda = {
                id_profit_less_markup_custo_venda: this.custo_venda.id_profit_less_markup_custo_venda,
                frete_custo_valor: this.custo_venda.frete_custo_valor,
                frete_custo_percentual: this.custo_venda.frete_custo_percentual,
                pedagio_valor: this.custo_venda.pedagio_valor,
                pedagio_percentual: this.custo_venda.pedagio_percentual,
                seguro_valor: this.custo_venda.seguro_valor,
                seguro_percentual: this.custo_venda.seguro_percentual,
                licenca_valor: this.custo_venda.licenca_valor,
                licenca_percentual: this.custo_venda.licenca_percentual,
                taxa_imo_anvisa_valor: this.custo_venda.taxa_imo_anvisa_valor,
                taxa_imo_anvisa_percentual: this.custo_venda.taxa_imo_anvisa_percentual,
                devolucao_container_vazio_valor: this.custo_venda.devolucao_container_vazio_valor,
                devolucao_container_vazio_percentual: this.custo_venda.devolucao_container_vazio_percentual,
                ajudante_valor: this.custo_venda.ajudante_valor,
                ajudante_percentual: this.custo_venda.ajudante_percentual,
                escolta_valor: this.custo_venda.escolta_valor,
                escolta_percentual: this.custo_venda.escolta_percentual,
                total_valor: this.custo_venda.total_valor,
                total_percentual: this.custo_venda.total_percentual
            };
        },
        preencheReceitaVenda() {
            if (!this.receita_venda?.id_profit_less_markup_receita_venda) {
                return;
            }

            const dados = {
                id_profit_less_markup_receita_venda: this.receita_venda.id_profit_less_markup_receita_venda,
                margem_lucro_percentual: this.receita_venda.margem_lucro_percentual,
                frete_peso_valor: this.receita_venda.frete_peso_valor,
                pedagio_valor: this.receita_venda.pedagio_valor,
                seguro_valor: this.receita_venda.seguro_valor,
                seguro_percentual: this.receita_venda.seguro_percentual,
                gris_valor: this.receita_venda.gris_valor,
                gris_percentual: this.receita_venda.gris_percentual,
                licenca_valor: this.receita_venda.licenca_valor,
                taxa_imo_anvisa_valor: this.receita_venda.taxa_imo_anvisa_valor,
                taxa_imo_anvisa_percentual: this.receita_venda.taxa_imo_anvisa_percentual,
                despacho_valor: this.receita_venda.despacho_valor,
                profit_calculado_valor: this.receita_venda.profit_calculado_valor,
                profit_calculado_percentual: this.receita_venda.profit_calculado_percentual,
                ajudante_valor: this.receita_venda.ajudante_valor,
                escolta_valor: this.receita_venda.escolta_valor,
                icms_valor: this.receita_venda.icms_valor,
                icms_percentual: this.receita_venda.icms_percentual,
                pis_cofins_valor: this.receita_venda.pis_cofins_valor,
                pis_cofins_percentual: this.receita_venda.pis_cofins_percentual,
                inss_empresa_valor: this.receita_venda.inss_empresa_valor,
                inss_empresa_percentual: this.receita_venda.inss_empresa_percentual,
                ir_csll_valor: this.receita_venda.ir_csll_valor,
                ir_csll_percentual: this.receita_venda.ir_csll_percentual,
                repasse_profit_valor: this.receita_venda.repasse_profit_valor,
                repasse_profit_percentual: this.receita_venda.repasse_profit_percentual,
                total_imposto: this.receita_venda.total_imposto,
                total_valor: this.receita_venda.total_valor
            };

            this.form.receita_venda = { ...this.form.receita_venda, ...dados };
        },
        preencheDespesaOperacional() {
            this.form.despesa_operacional = PreencheDadosMesmaPropriedade(
                this.form.despesa_operacional,
                this.despesa_operacional
            );
        },
        async cancelar() {
            await this.$root.$refs.$confirmaAcaoModal
                .open('Fechar', `Ao fechar, os dados preenchidos não serão salvos.`, {
                    cor: 'warning'
                })
                .then(async confirma => {
                    if (!confirma) {
                        return false;
                    }

                    this.$router.push({
                        name: 'profit-less-markup-grid'
                    });
                });
        },
        async proximoPasso() {
            try {
                this.carregando_proximo_passo = true;

                await sleep(1500);

                this.validaPasso();

                this.passo += 1;
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.carregando_proximo_passo = false;
            }
        },
        voltaPasso() {
            this.passo -= 1;
        },
        validaPasso() {
            const mensagem = 'Preencha os campos obrigatórios. Verifique todos os campos em vermelho';

            if (this.passo == 1 && !this.$refs.cliente.$refs.form.validate()) {
                throw new Error(mensagem);
            }

            if (this.passo == 2 && !this.$refs.custo_venda.$refs.form.validate()) {
                throw new Error(mensagem);
            }

            if (this.passo == 3 && !this.$refs.receita_venda.$refs.form.validate()) {
                throw new Error(mensagem);
            }

            if (this.passo == 4 && !this.$refs.despesa_operacional.$refs.form.validate()) {
                throw new Error(mensagem);
            }
        },
        proximoPassoCliente() {
            this.form.cliente = { ...this.$refs.cliente.form };

            this.form.custo_venda.pedagio_valor = this.form.cliente.pedagio;

            this.proximoPasso();
        },
        proximoPassoCustoVenda() {
            this.form.custo_venda = { ...this.$refs.custo_venda.form };

            this.form.receita_venda.pedagio_valor = this.form.custo_venda.pedagio_valor;

            this.proximoPasso();
        },
        proximoPassoReceitaVenda() {
            this.form.receita_venda = { ...this.$refs.receita_venda.form };

            this.proximoPasso();
        },
        proximoPassoRevisao() {
            this.parametrosRevisao();

            this.proximoPasso();
        },
        parametrosRevisao() {
            let cliente_razao_social = this.$refs.cliente.parceiroSelecionado();
            let tipo_operacao = this.$refs.cliente.tipoOperacaoSelecionado();
            let endereco_origem = this.$refs.cliente.enderecoOrigemSelecionado();
            let endereco_destino = this.$refs.cliente.enderecoDestinoSelecionado();

            this.form.revisao.parceiro_razao_social = cliente_razao_social?.razao_social ?? '';
            this.form.revisao.tipo_operacao_descricao = tipo_operacao?.descricao ?? '';
            this.form.revisao.endereco_origem = endereco_origem?.endereco_completo ?? '';
            this.form.revisao.endereco_destino = endereco_destino?.endereco_completo ?? '';

            this.form.receita_venda = { ...this.$refs.receita_venda.form };
            this.form.despesa_operacional = { ...this.$refs.despesa_operacional.form };
        },
        terminar() {
            this.$emit('terminar');
        },
        aprovar() {
            this.$emit('aprovar');
        },
        recusar() {
            this.$refs.recusaModal.abreFechaModal = true;
        },
        mapsQUALPModal() {
            this.$refs.mapsQUALPModal.abreFechaModal = true;
        },
        salvaRecusa(dados) {
            this.$emit('recusar', dados);
        },
        preparaDadosEnvio() {
            let endereco_origem = this.$refs.cliente.enderecoOrigemSelecionado();
            let endereco_destino = this.$refs.cliente.enderecoDestinoSelecionado();

            endereco_origem = endereco_origem?.endereco_completo ?? '';
            endereco_destino = endereco_destino?.endereco_completo ?? '';

            return {
                cliente: {
                    endereco_origem: endereco_origem,
                    endereco_destino: endereco_destino,
                    rota_km: parseFloat(this.$refs.cliente.form.rota_km.toFixed(2)),
                    rota_duracao: this.$refs.cliente.form.rota_duracao,
                    rota_pedagio: parseFloat(this.$refs.cliente.form.pedagio.toFixed(2)),
                    rota_pedagio_eixo: this.$refs.cliente.form.pedagio_eixo,
                    tipo_operacao: this.$refs.cliente.form.tipo_operacao,
                    valor_mercadoria: parseFloat(this.$refs.cliente.form.valor_mercadoria.toFixed(2)),
                    quantidade_eixos: this.$refs.cliente.form.quantidade_eixos,
                    id_parceiro: this.$refs.cliente.form.id_parceiro,
                    quantidade_eixos: this.$refs.cliente.form.quantidade_eixos,
                    quantidade_embarques: this.$refs.cliente.form.quantidade_embarques,
                    calcular_ida_volta: this.$refs.cliente.form.calcular_ida_volta ? 'S' : 'N',
                    tipo_carga: this.$refs.cliente.form.tipo_carga,
                    tipo_frete: this.$refs.cliente.form.tipo_frete
                },
                custo_venda: {
                    id_profit_less_markup_custo_venda: this.$refs.custo_venda.form.id_profit_less_markup_custo_venda,
                    frete_custo_valor: parseFloat(this.$refs.custo_venda.form.frete_custo_valor.toFixed(2)),
                    frete_custo_percentual: parseFloat(this.$refs.custo_venda.form.frete_custo_percentual.toFixed(2)),
                    pedagio_valor: parseFloat(this.$refs.custo_venda.form.pedagio_valor.toFixed(2)),
                    pedagio_percentual: parseFloat(this.$refs.custo_venda.form.pedagio_percentual.toFixed(2)),
                    seguro_valor: parseFloat(this.$refs.custo_venda.form.seguro_valor.toFixed(2)),
                    seguro_percentual: parseFloat(this.$refs.custo_venda.form.seguro_percentual.toFixed(2)),
                    licenca_valor: parseFloat(this.$refs.custo_venda.form.licenca_valor.toFixed(2)),
                    licenca_percentual: parseFloat(this.$refs.custo_venda.form.licenca_percentual.toFixed(2)),
                    taxa_imo_anvisa_valor: parseFloat(this.$refs.custo_venda.form.taxa_imo_anvisa_valor.toFixed(2)),
                    taxa_imo_anvisa_percentual: parseFloat(this.$refs.custo_venda.form.taxa_imo_anvisa_percentual.toFixed(2)),
                    devolucao_container_vazio_valor: parseFloat(
                        this.$refs.custo_venda.form.devolucao_container_vazio_valor.toFixed(2)
                    ),
                    devolucao_container_vazio_percentual: parseFloat(
                        this.$refs.custo_venda.form.devolucao_container_vazio_percentual.toFixed(2)
                    ),
                    ajudante_valor: parseFloat(this.$refs.custo_venda.form.ajudante_valor.toFixed(2)),
                    ajudante_percentual: parseFloat(this.$refs.custo_venda.form.ajudante_percentual.toFixed(2)),
                    escolta_valor: parseFloat(this.$refs.custo_venda.form.escolta_valor.toFixed(2)),
                    escolta_percentual: parseFloat(this.$refs.custo_venda.form.escolta_percentual.toFixed(2)),
                    total_percentual: parseFloat(this.$refs.custo_venda.form.total_percentual),
                    total_valor: parseFloat(this.$refs.custo_venda.form.total_valor?.toFixed(2))
                },
                receita_venda: {
                    frete_peso_valor: parseFloat(this.$refs.receita_venda.form.frete_peso_valor.toFixed(2)),
                    pedagio_valor: parseFloat(this.$refs.receita_venda.form.pedagio_valor.toFixed(2)),
                    seguro_valor: parseFloat(this.$refs.receita_venda.form.seguro_valor.toFixed(2)),
                    seguro_percentual: parseFloat(this.$refs.receita_venda.form.seguro_percentual.toFixed(2)),
                    gris_valor: parseFloat(this.$refs.receita_venda.form.gris_valor.toFixed(2)),
                    gris_percentual: parseFloat(this.$refs.receita_venda.form.gris_percentual.toFixed(2)),
                    licenca_valor: parseFloat(this.$refs.receita_venda.form.licenca_valor.toFixed(2)),
                    taxa_imo_anvisa_valor: parseFloat(this.$refs.receita_venda.form.taxa_imo_anvisa_valor.toFixed(2)),
                    taxa_imo_anvisa_percentual: parseFloat(
                        this.$refs.receita_venda.form.taxa_imo_anvisa_percentual.toFixed(2)
                    ),
                    despacho_valor: parseFloat(this.$refs.receita_venda.form.despacho_valor.toFixed(2)),
                    profit_calculado_valor: parseFloat(this.$refs.receita_venda.form.profit_calculado_valor.toFixed(2)),
                    profit_calculado_percentual: parseFloat(
                        this.$refs.receita_venda.form.profit_calculado_percentual.toFixed(2)
                    ),
                    ajudante_valor: parseFloat(this.$refs.receita_venda.form.ajudante_valor.toFixed(2)),
                    escolta_valor: parseFloat(this.$refs.receita_venda.form.escolta_valor.toFixed(2)),
                    icms_valor: parseFloat(this.$refs.receita_venda.form.icms_valor.toFixed(2)),
                    icms_percentual: parseFloat(this.$refs.receita_venda.form.icms_percentual.toFixed(2)),
                    pis_cofins_valor: parseFloat(this.$refs.receita_venda.form.pis_cofins_valor.toFixed(2)),
                    pis_cofins_percentual: parseFloat(this.$refs.receita_venda.form.pis_cofins_percentual.toFixed(2)),
                    inss_empresa_valor: parseFloat(this.$refs.receita_venda.form.inss_empresa_valor.toFixed(2)),
                    inss_empresa_percentual: parseFloat(this.$refs.receita_venda.form.inss_empresa_percentual.toFixed(2)),
                    ir_csll_valor: parseFloat(this.$refs.receita_venda.form.ir_csll_valor.toFixed(2)),
                    ir_csll_percentual: parseFloat(this.$refs.receita_venda.form.ir_csll_percentual.toFixed(2)),
                    repasse_profit_valor: parseFloat(this.$refs.receita_venda.form.repasse_profit_valor.toFixed(2)),
                    repasse_profit_percentual: parseFloat(this.$refs.receita_venda.form.repasse_profit_percentual.toFixed(2)),
                    total_valor: parseFloat(this.$refs.receita_venda.form.total_valor.toFixed(2)),
                    total_imposto: parseFloat(this.$refs.receita_venda.form.total_imposto.toFixed(2)),
                    margem_lucro_percentual: parseFloat(this.$refs.receita_venda.form.margem_lucro_percentual.toFixed(2))
                },
                despesa_operacional: {
                    geral_administrativa_valor: parseFloat(
                        this.$refs.despesa_operacional.form.geral_administrativa_valor.toFixed(2)
                    ),
                    geral_administrativa_percentual: parseFloat(
                        this.$refs.despesa_operacional.form.geral_administrativa_percentual.toFixed(2)
                    ),
                    comissao_venda_valor: parseFloat(this.$refs.despesa_operacional.form.comissao_venda_valor.toFixed(2)),
                    comissao_venda_percentual: parseFloat(
                        this.$refs.despesa_operacional.form.comissao_venda_percentual.toFixed(2)
                    ),
                    repasse_profit_valor: parseFloat(this.$refs.despesa_operacional.form.repasse_profit_valor.toFixed(2)),
                    repasse_profit_percentual: parseFloat(
                        this.$refs.despesa_operacional.form.repasse_profit_percentual.toFixed(2)
                    ),
                    financeira_capital_giro_valor: parseFloat(
                        this.$refs.despesa_operacional.form.financeira_capital_giro_valor.toFixed(2)
                    ),
                    financeira_capital_giro_percentual: parseFloat(
                        this.$refs.despesa_operacional.form.financeira_capital_giro_percentual.toFixed(2)
                    ),
                    total_valor: parseFloat(this.$refs.despesa_operacional.form.total_valor.toFixed(2)),
                    total_percentual: parseFloat(this.$refs.despesa_operacional.form.total_percentual.toFixed(2))
                }
            };
        },
        async calcularRota() {
            try {
                const cliente = { ...this.$refs.cliente.form };

                let mensagem = null;

                if (!cliente.tipo_carga) {
                    mensagem = 'Tipo de carga é obrigatório';
                }

                if (!cliente.quantidade_eixos) {
                    mensagem = 'Quantidade de eixos é obrigatório';
                }

                if (!!!cliente.endereco_origem) {
                    mensagem = 'Endereço de orgem é obrigatório';
                }

                if (!!!cliente.endereco_destino) {
                    mensagem = 'Endereço de destino é obrigatório';
                }

                if (mensagem) {
                    this.notificacao = {
                        mensagem: mensagem,
                        tipo: 'error',
                        mostra: true
                    };

                    return;
                }

                this.carregandoQualp = true;

                let urlConfig = {
                    method: 'GET',
                    url: `${API_URL.erp.profitlessmarkup.calcularRotaQualp}`,
                    params: {
                        endereco_origem: cliente.endereco_origem,
                        endereco_destino: cliente.endereco_destino,
                        tipo_carga: cliente.tipo_carga,
                        quantidade_eixos: cliente.quantidade_eixos,
                        calcular_ida_volta: cliente.calcular_ida_volta == true ? 'S' : 'N'
                    },
                    mostraCarregando: false
                };

                const consultaRota = await ApiService(urlConfig)
                    .then(resposta => {
                        return resposta.data.data.atributos;
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });

                this.rotaDescricao = consultaRota.map((rota, index) => {
                    return {
                        descricao: `ROTA ${index + 1}`,
                        distancia_texto: rota.distancia_texto,
                        duracao: rota.duracao
                    };
                });

                this.rotaItens = consultaRota;

                this.selecionaRota();
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.carregandoQualp = false;
            }
        },
        selecionaRota(posicaoRota) {
            const quantidade_eixos = this.$refs.cliente.form.quantidade_eixos;
            const indexRota = posicaoRota ?? 0;

            if (!this.rotaItens[indexRota]) {
                this.notificacao = {
                    mensagem: `Rota ${indexRota + 1} não encontrada`,
                    tipo: 'error',
                    mostra: true
                };

                return;
            }

            this.pedagios = this.rotaItens[indexRota].pedagios;
            this.tabelaFrete = this.rotaItens[indexRota].tabela_frete;

            const totalPedagio = this.calculaTotalPedagio();
            const valor_carga = this.calculaValorCarga();

            this.form.cliente.rota_km = this.rotaItens[indexRota].distancia_km;
            this.form.cliente.rota_duracao = this.rotaItens[indexRota].duracao;
            this.form.cliente.pedagio = totalPedagio;
            this.form.cliente.pedagio_eixo = totalPedagio / quantidade_eixos;
            this.form.cliente.valor_carga = valor_carga;
        },
        calculaTotalPedagio() {
            const quantidade_eixos = this.$refs.cliente.form.quantidade_eixos;

            if (!quantidade_eixos) {
                return;
            }

            let totalPedagio = 0;

            this.pedagios.map(pedagio => {
                totalPedagio += pedagio.tarifa[quantidade_eixos];
            });

            return parseFloat(totalPedagio.toFixed(2));
        },
        calculaValorCarga() {
            const quantidade_eixos = this.$refs.cliente.form.quantidade_eixos;
            const tipo_carga = this.$refs.cliente.form.tipo_carga;

            if (!quantidade_eixos) {
                this.notificacao = {
                    mensagem: 'Quantidade de Eixos não selecionada',
                    tipo: 'error',
                    mostra: true
                };

                return;
            }

            let totalCarga = 0;
            let nomeTabela = null;

            switch (tipo_carga) {
                case 1:
                    nomeTabela = 'conteineirizada';
                    break;
                case 2:
                    nomeTabela = 'frigorificada';
                    break;
                case 3:
                    nomeTabela = 'geral';
                    break;
                case 4:
                    nomeTabela = 'granel_liquido';
                    break;
                case 5:
                    nomeTabela = 'granel_pressurizada';
                    break;
                case 6:
                    nomeTabela = 'granel_solido';
                    break;
                case 7:
                    nomeTabela = 'neogranel';
                    break;
                case 8:
                    nomeTabela = 'perigosa_conteineirizada';
                    break;
                case 9:
                    nomeTabela = 'perigosa_frigorificada';
                    break;
                case 10:
                    nomeTabela = 'perigosa_geral';
                    break;
                case 11:
                    nomeTabela = 'perigosa_granel_liquido';
                    break;
                case 12:
                    nomeTabela = 'perigosa_granel_solido';
                    break;
            }

            if (!nomeTabela) {
                this.notificacao = {
                    mensagem: 'Tabela de Frete não encontrada',
                    tipo: 'warning',
                    mostra: true
                };

                return;
            }

            if (!this.tabelaFrete['A'][quantidade_eixos]) {
                this.notificacao = {
                    mensagem: 'Tabela de Frete não encontrada com a quantidade de eixos selecionado',
                    tipo: 'warning',
                    mostra: true
                };
                return;
            }

            totalCarga += this.tabelaFrete['A'][quantidade_eixos][nomeTabela];

            return parseFloat(totalCarga.toFixed(2));
        },
        async mudaQuantidadeEixos() {
            if (!!!this.$refs.cliente.form.endereco_origem || !!!this.$refs.cliente.form.endereco_destino) {
                return;
            }

            if (!Object.keys(this.tabelaFrete).length) {
                await this.$root.$refs.$confirmaAcaoModal
                    .open('Busca QUALP', 'Tabela de Frete não carregada, deseja recalcular a rota do QUALP ?', {
                        cor: 'green'
                    })
                    .then(async confirma => {
                        if (confirma) {
                            this.calcularRota();
                        }
                    });

                return;
            }

            const rotaSelecionada = this.$refs.cliente.rotaSelecionada;

            this.selecionaRota(rotaSelecionada);

            this.notificacao = {
                mensagem: 'Valor da Carga alterado',
                tipo: 'warning',
                mostra: true
            };
        },
        mudaValorCarga(valor) {
            this.form.custo_venda.frete_custo_valor = valor;
        },
        mudaRota(valor) {
            this.selecionaRota(valor);
        }
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            passo: this.passoForm,
            carregando_proximo_passo: false,
            carregandoQualp: false,
            recusaModal: false,
            rotaItens: [],
            rotaDescricao: [],
            form: {
                id_profit_less_markup: null,
                id_parceiro: null,
                tipo_operacao: null,
                situacao: 1,
                cliente: {
                    valor_mercadoria: 0,
                    quilometragem: 0,
                    quantidade_embarques: '',
                    duracao: '',
                    pedagio: 0,
                    pedagio_eixo: 0,
                    rota_km: 0,
                    tipo_carga: null,
                    tipo_frete: null,
                    valor_carga: 0
                },
                custo_venda: {
                    id_profit_less_markup_custo_venda: null,
                    frete_custo_valor: 0,
                    frete_custo_percentual: 0,
                    pedagio_valor: 0,
                    pedagio_percentual: 0,
                    seguro_valor: 0,
                    seguro_percentual: 0,
                    licenca_valor: 0,
                    licenca_percentual: 0,
                    taxa_imo_anvisa_valor: 0,
                    taxa_imo_anvisa_percentual: 0,
                    devolucao_container_vazio_valor: 0,
                    devolucao_container_vazio_percentual: 0,
                    ajudante_valor: 0,
                    ajudante_percentual: 0,
                    escolta_valor: 0,
                    escolta_percentual: 0,
                    total: 0,
                    total_percentual: 0
                },
                receita_venda: {
                    id_profit_less_markup_receita_venda: null,
                    frete_peso_valor: 0,
                    pedagio_valor: 0,
                    seguro_valor: 0,
                    seguro_percentual: 0.1,
                    gris_valor: 0,
                    licenca_valor: 0,
                    taxa_imo_anvisa_valor: 0,
                    despacho_valor: 0,
                    profit_calculado_valor: 0,
                    profit_calculado_percentual: 10,
                    ajudante_valor: 0,
                    escolta_valor: 0,
                    icms_valor: 0,
                    ir_csll_valor: 0,
                    repasse_profit_valor: 0,
                    total_imposto: 0,
                    total_valor: 0
                },
                despesa_operacional: {
                    id_profit_less_markup_despesa_operacional: null,
                    geral_administrativa_valor: 0,
                    geral_administrativa_percentual: 1,
                    comissao_venda_valor: 0,
                    comissao_venda_percentual: 1,
                    repasse_profit_valor: 0,
                    repasse_profit_percentual: 1,
                    financeira_capital_giro_valor: 0,
                    financeira_capital_giro_percentual: 0,
                    total_valor: 0,
                    total_percentual: 0
                },
                revisao: {
                    gross_margin_valor: 0,
                    gross_margin_percentual: 0,
                    gross_profit: 0,
                    tipo_operacao: '',
                    custo_venda_total: 0,
                    receita_venda_total: 0,
                    parceiro_razao_social: '',
                    tipo_operacao_descricao: ''
                }
            },
            pedagios: [],
            tabelaFrete: [],
            clienteParceiroItens: [],
            enderecoOrigemItens: [],
            enderecoDestinoItens: [],
            situacaoAberto: 1,
            situacaoAprovacao: 2,
            situacaoAguardandoAjuste: 5
        };
    }
};
</script>
