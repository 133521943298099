<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-menu
                    ref="menu1"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="auto"
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="dateFormatted"
                            clearable
                            :label="dadosForm('rotulo')"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="date=null"
                            prepend-icon="mdi-calendar"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date"
                        @change="menu = false"
                        @input="updateValue(date)"
                    >
                    </v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { FormCampoRetornaDado } from '@/helpers/Componente.js';
import moment from 'moment'

export default {
    data: () => ({
        date: null,
        menu: false,
    }),

    computed: {
        dateFormatted() {
          return this.date ? moment(this.date).locale('pt-br').format('L') : ''
        },
    },

    props: {
        form: {
            type: Object,
        },
        campo: {
            type: String,
        },
    },

    methods: {

        updateValue(value) {
          this.$emit('input', value);
        },

        dadosForm(campoSelecionado) {
            return FormCampoRetornaDado(
                this.form,
                this.campo,
                campoSelecionado
            );
        },
    },
};
</script>