const FATURAMENTO = 1;

export function descricao(etapa) {
    switch (etapa) {
        case FATURAMENTO:
            return 'FATURAMENTO';
        default:
            return '';
    }
}

export function listaDescricao() {
    return [
        {
            value: FATURAMENTO,
            label: 'FATURAMENTO'
        }
    ];
}
