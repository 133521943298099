<template>
    <v-dialog v-model="abreFechaModal" persistent>
        <v-card>
            <v-toolbar color="warning darken-2" dark>
                <v-toolbar-title>Rota QUALP</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon @click="fechar">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="pt-2">
                <v-responsive>
                    <iframe
                        src="https://qualp.com.br/#/?data=NewYufmAxWsTXowBJUF2kWmXnEFKGaCKgamRWbGYQAAsYJDuBr6TOHcLVdcHHwtV6_lqMwaJCsZOqrqFJ24gN8SPGvZOl_UuB_1qz-XrIv4pYrY1qZ3ucwhaAhj8U5CxU3i2MLbLo0h9rww-ybbMa2OHr_PY7W4x8MzL3jjNfq93SVTKMBdynC8Z7UseI089DCC1q_1bt7-s42OmIuazr_BHSN5oL-4RF2A6L2NiK_Cx6kHogd8-x3UVFz_qsMwygX_mKRem_9EtjvhOI5KPCOMYesCWwM7bQv2AXpOGVoOiZfuknkZxeEePU889ZmobV_PDyJ2hDLBhlr6JEt3QXY5V2mP-eGqfSmltufosZij1IZS_VUThzJsXUTJsDpEouKnYhd37n04RULamhyOnTlXRG7mRuTPsDIDAjeUwfHVZ1KUVOP25eeAgLkAab_DcdB40tBc_sdvuYF88K-KYOipbYasq7ItutzmGzF0GDo1THbPtL_nqmE9IrdxG2xokJM-81a64MTTJeg"
                        width="100%"
                        height="900"
                        frameBorder="0"
                    ></iframe>
                </v-responsive>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'MapsQUALPModal',
    data() {
        return {
            abreFechaModal: false
        };
    },
    methods: {
        fechar() {
            this.abreFechaModal = false;
        }
    }
};
</script>
