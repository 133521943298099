<template>
    <div>
        <v-card outlined elevation="2" class="mb-4">
            <v-card-title>Filtros:</v-card-title>
            <v-card-text dense>
                <v-row>
                    <v-col cols="12" md="10">
                        <InputSelectV3
                            v-model="filial"
                            rotulo="Filial"
                            :regra="regrasFilial"
                            :itens="opcaoFilial"
                            itemTexto="razao_social"
                            itemValor="id_filial"
                            ref="filial"
                        />
                    </v-col>

                    <v-col cols="2">
                        <v-btn
                            elevation="2"
                            color="warning"
                            @click="getProgramacaoCarga()"
                            >Atualizar</v-btn
                        >
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-row>
            <v-col cols="12" class="text-right">
                <v-data-table
                    :headers="cabecalho"
                    :items="programacaoItens"
                    :sort-by="['solicitante', 'id_programacao_carga_rodopar']"
                    :sort-desc="[false, false]"
                    :items-per-page="50"
                    :search="programacaoCargaBuscar"
                    :fixed-header="true"
                    height="60vh"
                    item-key="id_programacao_carga"
                    loading-text="Buscando, aguarde..."
                    class="elevation-5"
                    multi-sort
                    dense
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title
                                >Programação de Carga com documentos prontos
                                para geração no Rodopar</v-toolbar-title
                            >
                        </v-toolbar>
                        <v-row class="pa-4">
                            <v-col cols="12" md="12">
                                <InputSelectV3
                                    v-model="programacaoCargaBuscar"
                                    rotulo="Solicitante"
                                    :itens="opcaoSolicitante"
                                />
                            </v-col>
                        </v-row>
                    </template>

                    <template v-slot:item.valor_mercadoria="{ item }">
                        {{ mascaraMoeda(item.valor_mercadoria) }}
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                outlined
                :color="notificacao.tipo"
                timeout="3000"
            >
                {{ notificacao.mensagem }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="notificacao.mostra = false"
                    >
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import ApiService from '@/services/ApiService.js';
import { FormataMoeda } from '@/helpers/Funcoes.js';

import moment from 'moment';

import InputSelectV3 from '@/components/Form/InputSelectV3.vue';
import InputTextV3 from '@/components/Form/InputTextV3.vue';

export default {
    name: 'DocumentoPronto',
    components: {
        InputSelectV3,
        InputTextV3
    },
    async mounted() {
        await this.getFilial();
        await this.getProgramacaoCarga();
    },
    computed: {
        programacaoItens() {
            return this.programacaoCargas;
        },
    },
    watch: {},
    methods: {
        mascaraMoeda(valor) {
            return FormataMoeda(valor, {}, true);
        },
        async getProgramacaoCarga() {
            if (!this.$refs['filial'].$children[0].validate()) {
                this.$refs['filial'].$children[0].validate(true);

                return false;
            }

            await ApiService({
                method: 'GET',
                url: `V1/relatorio/alocacaocarga/documento-pronto`,
                params: {
                    id_filial: this.filial,
                },
            })
                .then((resposta) => {
                    this.programacaoCargas = resposta.data.data;

                    this.programacaoCargas = this.programacaoCargas.map(
                        (programacaoCarga) => {
                            const id = programacaoCarga.id_registro;
                            programacaoCarga = programacaoCarga.atributos;
                            programacaoCarga.id_programacao_carga = id;

                            this.opcaoSolicitante.push(
                                programacaoCarga.solicitante
                            );

                            return programacaoCarga;
                        }
                    );
                })
                .catch((erro) => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true,
                    };
                });
        },
        async getFilial() {
            await ApiService({
                method: 'get',
                url: `V1/filial/lista?fields[filial]=id_filial,filial_descricao`,
                data: {},
            })
                .then((resposta) => {
                    this.opcaoFilial = resposta.data.data.map((filial) => {
                        const dados = {};

                        dados.id_filial = filial.id_registro;
                        dados.razao_social = filial.atributos.filial_descricao;

                        return dados;
                    });

                    this.filial = this.opcaoFilial[0].id_filial;
                })
                .catch((erro) => {
                    throw new Error(erro.message);
                });
        },
    },
    data() {
        return {
            programacaoCargas: [],
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success',
            },
            opcaoSolicitante: [],
            filial: null,
            opcaoFilial: [],
            regrasFilial: [(v) => !!v || 'Campo é obrigatório'],
            cabecalho: [
                {
                    text: 'ID',
                    value: 'id_programacao_carga',
                    width: '80',
                },
                {
                    text: 'Programação de Carga Rodopar',
                    value: 'id_programacao_carga_rodopar',
                    align: 'start',
                    width: '80',
                },
                { text: 'SOLICITANTE', value: 'solicitante', width: '200' },
                {
                    text: 'TIPO SOLICITACAO',
                    value: 'tipo_solicitacao',
                    width: '200',
                },
                {
                    text: 'TERMINAL COLETA',
                    value: 'terminal coleta',
                    width: '200',
                },
                { text: 'PLACA COLETA', value: 'placa_coleta', width: '200' },
                {
                    text: 'TIPO OPERAÇÃO',
                    value: 'tipo_operacao',
                    width: '200',
                },
                { text: 'N° DI', value: 'numero_di', width: '200' },
                { text: 'N° DTA', value: 'numero_dta', width: '200' },
                { text: 'REFERÊNCIA', value: 'referencia', width: '200' },
                { text: 'OBS. ENTREGA', value: 'observacao', width: '200' },
                {
                    text: 'OBS. GERAIS',
                    value: 'observacao_gerais',
                    width: '200',
                },
                {
                    text: 'CLIENTE',
                    value: 'nome_tomador_servico',
                    width: '200',
                },
                { text: 'ORIGEM', value: 'terminal_coleta', width: '200' },
                { text: 'DESTINO', value: 'terminal_entrega', width: '200' },
                { text: 'TIPO CARGA', value: 'tipo_carga', width: '200' },
                { text: 'QTD.', value: 'quantidade_itens', width: '200' },
                { text: 'ALTURA', value: 'altura', width: '200' },
                { text: 'LARGURA', value: 'largura', width: '200' },
                { text: 'PROFUNDIDADE', value: 'profundidade', width: '200' },
                { text: 'PESO', value: 'peso_calculado', width: '200' },
                {
                    text: 'VALOR CARGA',
                    value: 'valor_mercadoria',
                    width: '200',
                },
                {
                    text: 'PARCIULARIDADE CLIENTE',
                    value: 'particularidade_cliente',
                    width: '200',
                },
                { text: 'MOTORISTA', value: 'motorista', width: '200' },
                { text: 'AJUDANTE', value: 'ajudante', width: '200' },
                {
                    text: 'PLACA DESTINO',
                    value: 'veiculo_destino_placa_1',
                    width: '200',
                },
                {
                    text: 'REBOQUE',
                    value: 'veiculo_destino_placa_2',
                    width: '200',
                },
                { text: 'PERFIL', value: 'perfil', width: '200' },
                { text: 'FROTA', value: 'frota', width: '200' },
                {
                    text: 'CONTATO TEL.',
                    value: 'telefone_principal',
                    width: '200',
                },
            ],
            programacaoCargaBuscar: '',
        };
    },
};
</script>

<style>
.pointer {
    cursor: pointer;
}

.v-data-table__wrapper > table > tbody > tr {
    height: 60px;
}
</style>
