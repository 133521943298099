<template>
    <v-fab-transition>
        <v-btn
            key="mdi-arrow-collapse-left"
            color="success"
            fab
            dark
            small
            right
            class="v-btn--example"
            v-on:click="painelAbreFecha"
        >
            <v-icon>{{ painelIcone }}</v-icon>
        </v-btn>
    </v-fab-transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {};
    },
    computed: {
        ...mapGetters(['painelIcone']),
    },
    methods: {
        ...mapActions(['painelAbreFecha']),
    },
};
</script>

<style scoped>
.v-btn--example {
    position: absolute;
    margin: 0 0 16px 16px;
    z-index: 2;
}
</style>
