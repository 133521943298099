<template>
    <v-card outlined elevation="2">
        <v-card-title class="">
            <span class="text-h6"> OTD {{ ano }} </span>

            <v-spacer></v-spacer>

            <span class="pr-2">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="blue darken-1" dark v-bind="attrs" v-on="on" @click="downloadImagem(ano)">
                            mdi-cloud-download
                        </v-icon>
                    </template>
                    <span>Baixar imagem</span>
                </v-tooltip>
            </span>

            <span class="pr-2">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="blue-grey darken-1" dark v-bind="attrs" v-on="on" @click="carregaDashboard(ano)">
                            mdi-refresh
                        </v-icon>
                    </template>
                    <span>Recarregar</span>
                </v-tooltip>
            </span>

            <span class="pr-2">
                <v-tooltip max-width="200" left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="warning darken-1" dark v-bind="attrs" v-on="on" @click="ajuda = true">
                            mdi-account-question
                        </v-icon>
                    </template>
                    <span>
                        Ajuda com a composição do gráfico ? Clique para saber mais.
                    </span>
                </v-tooltip>
            </span>
        </v-card-title>

        <v-card-text>
            <v-chart
                :option="opcoes"
                :init-options="initOptions"
                :loading="carregando"
                :loadingOptions="carregandoOpcoes"
                :style="tamanhoPixel"
                ref="Grafico"
                autoresize
            />
        </v-card-text>

        <div class="text-center">
            <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                {{ notificacao.mensagem }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>

        <v-dialog v-model="ajuda" max-width="800">
            <v-card>
                <v-card-title class="text-h5"> OTD por ano {{ ano }} e unidade </v-card-title>

                <v-card-text>
                    Adicionar texto de ajuda
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="blue darken-1" text @click="ajuda = false">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import VChart from 'vue-echarts';

import InputSelectV3 from '@/components/Form/InputSelectV3.vue';

export default {
    name: 'OrderToDeliveryAnoTotal',
    components: {
        VChart,
        InputSelectV3
    },
    watch: {
        dadosGraficoPorAno: {
            handler: function(dadosAtual) {
                this.adicionaDadosAoGrafico(dadosAtual);
            },
            deep: true
        }
    },
    async mounted() {
        this.opcoes.color = this.cor;
    },
    computed: {
        ...mapGetters({
            carregando: 'orderToDelivery/carregando',
            carregandoOpcoes: 'orderToDelivery/carregandoOpcoes',
            ano: 'orderToDelivery/ano',
            anosPesquisa: 'orderToDelivery/anosPesquisa',
            dadosGraficoPorAno: 'orderToDelivery/dadosGraficoPorAno',
            tamanhoDashboard: 'dashboard/tamanhoDashboard'
        }),
        ...mapState('orderToDelivery', {
            cor(state, getters) {
                return getters['cor'];
            }
        }),
        tamanhoPixel() {
            return this.tamanhoDashboard({ id_dashboard: this.id_dashboard });
        }
    },
    methods: {
        ...mapActions({
            buscaDashboard: 'orderToDelivery/buscaDashboard',
            baixarImagem: 'orderToDelivery/baixarImagem'
        }),
        carregaDashboard(ano) {
            this.buscaDashboard(ano)
                .then(async () => {
                    this.adicionaDadosAoGrafico(this.dadosGrafico);
                })
                .catch(erro => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        },
        downloadImagem() {
            this.baixarImagem(this.$refs['Grafico']);
        },
        adicionaDadosAoGrafico(graficoDados) {
            if (!Object.keys(graficoDados).length) {
                return;
            }

            let total = graficoDados.no_prazo.valor.reduce((acumulador, object) => {
                return parseFloat(acumulador) + parseFloat(object);
            }, 0);

            total += graficoDados.fora_prazo.valor.reduce((acumulador, object) => {
                return parseFloat(acumulador) + parseFloat(object);
            }, 0);

            const no_prazo = graficoDados.no_prazo.valor.reduce((acumulador, object) => {
                return parseFloat(acumulador) + parseFloat(object);
            }, 0);

            const fora_prazo = graficoDados.fora_prazo.valor.reduce((acumulador, object) => {
                return parseFloat(acumulador) + parseFloat(object);
            }, 0);

            this.opcoes.series[0].data[0].value = ((no_prazo / total) * 100).toFixed(2);
            this.opcoes.series[0].data[1].value = ((fora_prazo / total) * 100).toFixed(2);
        }
    },
    data() {
        return {
            id_dashboard: 2,
            ajuda: false,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            opcaoAno: [],
            initOptions: {
                renderer: 'png'
            },
            opcoes: {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                grid: {
                    top: '20%',
                    bottom: '20%'
                },
                series: [
                    {
                        name: 'Order to Delivery Ano',
                        type: 'pie',
                        radius: '50%',
                        bottom: '5%',
                        label: {
                            formatter: function(value) {
                                return `${value.data.name} \r\n\r\n ${value.value} %`;
                            }
                        },
                        data: [
                            { value: 0, name: 'No Prazo' },
                            { value: 0, name: 'Fora Prazo' }
                        ],
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 12,
                                fontWeight: 'bold'
                            },
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        };
    }
};
</script>

<style scoped>
.echarts {
    width: 100%;
    height: 300px;
}
</style>
