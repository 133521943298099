<template>
    <v-textarea
        :label="rotulo"
        :value="value"
        @input="$emit('input', $event)"
        :rules="regra"
        :rows="linhas"
        counter
        clearable
    ></v-textarea>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: false,
        },
        rotulo: {
            type: String,
            default: false,
        },
        regra: {
            type: Array,
            default: false,
        },
        linhas: {
            type: Number,
            default: 5,
        },
    },
    data: () => ({}),
};
</script >

<style scoped>
</style>
