<template>
    <v-card>
        <CarregandoLogoEmpresa :carregando="carregando" :mensagem="carregandoMensagem" />

        <v-card-title class="text-h5">
            Celular - Importar por Excel
        </v-card-title>

        <v-card-text>
            <span class="font-weight-bold text-md-body-1">Exemplo:</span>
            <v-row>
                <v-col cols="6" right>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left" style="width: 100px;">
                                        Código País
                                    </th>
                                    <th class="text-left" style="width: 150px;">
                                        DDD
                                    </th>
                                    <th class="text-left" style="width: 500px;">
                                        Celular
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>55</td>
                                    <td>11</td>
                                    <td>945757856</td>
                                </tr>
                                <tr>
                                    <td>55</td>
                                    <td>11</td>
                                    <td>225544887</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <InputFileV4
                        v-model="arquivo"
                        :regra="regrasArquivo"
                        :multiplo="false"
                        icone="upload"
                        rotulo="Selecione o arquivo (XLS ou XLSX que são do formato Excel)"
                        tipoArquivo=".xlsX"
                        :tipoArquivoPermitido="[
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            'application/vnd.ms-excel'
                        ]"
                        v-on:change="adicionaArquivo($event)"
                    />
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="$emit('cancelar')">
                Cancelar
            </v-btn>

            <v-btn color="green darken-1" text @click="importaArquivo()" :disabled="!this.arquivo">
                Importar
            </v-btn>
        </v-card-actions>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-card>
</template>

<script>
import * as XLSX from 'xlsx';

import { sleep } from '@/helpers/Funcoes.js';

import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import InputFileV4 from '@/components/Form/InputFileV4.vue';

export default {
    name: 'ImportarExcel',
    components: {
        CarregandoLogoEmpresa,
        InputFileV4
    },
    methods: {
        adicionaArquivo(arquivo) {
            this.arquivo = arquivo;
        },
        async importaArquivo() {
            try {
                if (!!!this.arquivo) {
                    return;
                }

                this.carregando = true;
                this.carregandoMensagem = 'Lendo arquivo...';

                const dadosImportacao = await this.lerArquivo(this.arquivo);

                await sleep(1000);

                this.carregandoMensagem = 'Validando celular...';

                await sleep(500);

                dadosImportacao.map((telefone, index) => {
                    if (index == 0) {
                        return;
                    }

                    this.validarTelefoneEAdiciona(telefone[0], telefone[1], telefone[2]);
                });

                this.$emit('adiciona-celular', { dados: this.dadosImportacao });

                this.carregando = false;
            } catch (erro) {
                this.carregando = false;

                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        async lerArquivo(arquivo) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = e => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    const dadosImportacao = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                    resolve(dadosImportacao);
                };

                reader.readAsArrayBuffer(arquivo);
            });
        },
        validarTelefoneEAdiciona(codigoPais, ddd, telefone) {
            const numeroLimpo = telefone.toString().replace(/\D/g, '');

            if (!codigoPais) {
                throw new Error(`Código do País não informado para o telefone ${telefone}`);
            }

            if (!ddd) {
                throw new Error(`Código do DDD não informado para o telefone ${telefone}`);
            }

            if (ddd.length < 2) {
                throw new Error(`Código do DDD inválido, ${telefone}`);
            }

            if (codigoPais == '55') {
                if (numeroLimpo.length !== 9) {
                    throw new Error(`Quantidade de números não corresponde a um telefone valido para o telefone ${telefone}`);
                }
            }

            if (numeroLimpo.length < 9) {
                throw new Error(`Quantidade de números não corresponde a um telefone valido para o telefone ${telefone}`);
            }

            const telefoneFinal = `${codigoPais}${ddd}${telefone}`;

            this.dadosImportacao.push(telefoneFinal);
        }
    },
    data() {
        return {
            arquivo: [],
            dadosImportacao: [],
            regrasArquivo: [files => !files.size || files.size < 3000000 || 'Arquivo não pode ser maior que 3 MB!'],
            carregando: false,
            carregandoMensagem: null,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            }
        };
    }
};
</script>
