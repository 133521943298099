<template>
    <v-dialog v-model="abreFechaModal" persistent max-width="800px">
        <v-card>
            <v-toolbar color="red darken-2" dark>
                <v-toolbar-title>Recusar</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon @click="fechar">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="pt-5">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="12">
                            Se habilitado o Markup ficará em aberto para edição do usuário.
                            <ButtonSwitchV3 v-model="condicional" rotulo="Recusar condicional ?" :mostraIcone="true" />
                        </v-col>

                        <v-col cols="12">
                            <TextAreaV3
                                v-model="motivo"
                                :contador="4000"
                                :obrigatorio="true"
                                :regra="regrasConsideracao"
                                rotulo="Motivo de recusa"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="fechar">
                    Fechar
                </v-btn>
                <v-btn color="blue darken-1" text @click="salvar">
                    Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { Maximo, Minimo, Obrigatorio } from '@/helpers/ValidadorCampo.js';

import ButtonSwitchV3 from '@/components/Form/ButtonSwitchV3.vue';
import TextAreaV3 from '@/components/Form/TextAreaV3.vue';

export default {
    name: 'RecusaModal',
    components: {
        ButtonSwitchV3,
        TextAreaV3
    },
    data() {
        return {
            abreFechaModal: false,
            motivo: '',
            condicional: false,
            regrasConsideracao: [Obrigatorio(), Minimo(10), Maximo(4000)]
        };
    },
    methods: {
        async salvar() {
            this.$emit('salvar', {
                recusa: this.motivo,
                condicional: this.condicional
            });

            this.fechar();
        },
        fechar() {
            this.abreFechaModal = false;
            this.motivo = '';
            this.condicional = false;
        }
    }
};
</script>
