<template>
    <v-card class="pa-3 pt-5">
        <div class="d-flex grow flex-wrap">
            <v-sheet :color="iconeBoxCor" class="text-start v-card_heading pa-7" elevation="4" height="90" rounded>
                <v-icon color="white" large>
                    {{ icone }}
                </v-icon>
            </v-sheet>

            <v-spacer></v-spacer>

            <div>
                <div class="ml-auto text-right">
                    <div class="body-2 grey--text" v-text="titulo" />

                    <v-fade-transition leave-absolute>
                        <h3 class="text-lg-h6 font-weight-light text--primary" v-if="!carregando">
                            <span v-if="prefixo.length">
                                {{ prefixo }}
                            </span>

                            {{ value }}

                            <span v-if="sufixo.length">
                                {{ sufixo }}
                            </span>
                        </h3>
                    </v-fade-transition>

                    <v-progress-circular
                        v-if="carregando"
                        indeterminate
                        color="warning"
                        :size="20"
                        :width="2"
                    ></v-progress-circular>
                </div>
            </div>
        </div>

        <v-divider />

        <v-icon :color="rodapeIconeCor" size="16" class="ml-2 mr-1">
            {{ iconeRodape }}
        </v-icon>

        <span :class="rodapeTextoCor" class="caption grey--text font-weight-light" v-text="rodapeTexto" />
    </v-card>
</template>

<script>
export default {
    name: 'CardInfo',
    props: {
        icone: {
            type: String,
            required: true
        },
        iconeBoxCor: {
            type: String,
            required: true
        },
        iconeRodape: {
            type: String,
            default: 'mdi-help-circle'
        },
        rodapeIconeCor: {
            type: String,
            default: undefined
        },
        rodapeTextoCor: {
            type: String,
            default: undefined
        },
        rodapeTexto: {
            type: String,
            default: undefined
        },
        titulo: {
            type: String,
            default: undefined
        },
        value: {
            default: ''
        },
        carregando: {
            type: Boolean,
            default: true
        },
        prefixo: {
            type: String,
            default: ''
        },
        sufixo: {
            type: String,
            default: ''
        }
    }
};
</script>

<style scoped>
.v-card_heading {
    position: relative;
    top: -30px;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    z-index: 1;
}
</style>
