<template>
    <!-- <v-row justify=""> -->
    <v-dialog v-model="dialog" persistent max-width="1100px">
        <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-4" dark v-bind="attrs" v-on="on">
                mdi-phone
            </v-icon>
        </template>
        <v-card>
            <v-card-title>
                <span class="text-h5">Ramal</span>
            </v-card-title>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn text color="green" class="ma-2" @click="atualiza">
                    Atualizar
                    <v-icon right dark>mdi-cached</v-icon>
                </v-btn>

                <v-btn color="blue darken-1" text @click="dialog = false">
                    Fechar
                </v-btn>
            </v-card-actions>

            <v-card-text>
                <v-container fluid v-if="dialog">
                    <ListaRamal ref="listaRamal" />
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                    Fechar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- </v-row> -->
</template>

<script>
import ListaRamal from './ListaRamal.vue';

export default {
    name: 'RamalDialog',
    components: {
        ListaRamal
    },

    data() {
        return {
            dialog: false
        };
    },
    methods: {
        atualiza() {
            this.$refs.listaRamal.getListaRamal();
        }
    }
};
</script>
