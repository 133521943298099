<template>
    <div class="pb-5 text-right">
        <v-btn
            v-for="btn in botoes"
            :key="btn.acao"
            :id="`${btn.identificador}`"
            :disabled="btn.desativado"
            v-on:click="botaoAcao(btn.acao, btn.parametros)"
            v-bind:class="[btn.cor ? btn.cor : btnCorPadrao, 'btnBarraNavegacao']"
            depressed
        >
            <v-icon :left="!!btn.nome" v-if="btn.icone">
                {{ btn.icone }}
            </v-icon>

            {{ btn.nome }}
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'BarraNavegacaoBotaoV3',
    props: {
        botoes: {
            type: Array,
            default: []
        }
    },
    components: {},
    async created() {},
    data: () => ({
        btnCorPadrao: 'primary'
    }),
    methods: {
        botaoAcao(nome_funcao, parametros) {
            this.$parent[nome_funcao](parametros);
        }
    }
};
</script>
<style></style>
