<template>
    <div>
        <TituloModulo />

        <AlertaMensagem dense style="width: 100%" />

        <CarregandoLogoEmpresa :mensagem="mensagemCarregando" />

        <BarraNavegacaoBotaoV3 v-if="!carregando.mostraCarregando && permissao" :botoes="botoesComPermissao('grid')" />

        <v-row>
            <v-col cols="12" class="text-right">
                <TabelaVuetify
                    v-model="datatable.itensSelecionados"
                    :cabecalho="datatable.cabecalho"
                    :itens="datatable.itens"
                    :total_registros="datatable.totalRegistros"
                    :por_pagina="datatable.por_pagina"
                    :ultima_pagina="datatable.ultima_pagina"
                    :carregando="datatable.carregando"
                    :chave_primaria="datatable.chave_primaria"
                    :ordenar_por="datatable.ordenarPor"
                    :ordenar_direcao="datatable.ordenarDirecao"
                    :multipla_selecao="false"
                    @opcoes="buscaDatatableItens"
                    ref="tabela"
                >
                    <template v-slot:item.acao="{ item }">
                        <v-icon small class="mr-2" @click="editaItem(item)">
                            mdi-pencil
                        </v-icon>
                    </template>

                    <template v-slot:item.situacao="{ item }">
                        <v-chip small class="ma-2" :color="selecionaCor(item.id_situacao)" text-color="white">
                            <v-icon small left>
                                mdi-alert-circle-outline
                            </v-icon>
                            {{ item.situacao }}
                        </v-chip>
                    </template>
                </TabelaVuetify>
            </v-col>
        </v-row>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { Alerta } from '@/helpers/Funcoes.js';

import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import TabelaVuetify from '@/components/Default/TabelaVuetify.vue';

import SituacaoForm from '@/Enum/PlanoDesenvolvimentoIndividual/SituacaoForm.js';
import SituacaoEnum from '@/Enum/PlanoDesenvolvimentoIndividual/SituacaoEnum.js';

export default {
    name: 'PlanoDesenvolvimentoIndividual',
    components: {
        AlertaMensagem,
        CarregandoLogoEmpresa,
        TituloModulo,
        BarraNavegacaoBotaoV3,
        TabelaVuetify
    },
    async mounted() {
        await this.dadosPaginaInicial();

        if (this.$route.params.mensagem) {
            Alerta([this.$route.params.mensagem], 'success');
        }
    },
    computed: {
        ...mapGetters(['botoesComPermissao']),
        ...mapState(['carregando'])
    },
    watch: {},
    methods: {
        ...mapActions(['montaNavegacao', 'mostraCarregando']),
        async buscaPermissoes() {
            try {
                await ApiService({
                    method: 'get',
                    url: `${API_URL.erp.planodesenvolvimentoindividual.permissao}`,
                    data: {},
                    mostraCarregando: false
                })
                    .then(async resposta => {
                        const permissoes = resposta.data.data.atributos;

                        await this.montaNavegacao({ botoes: this.botoes, permissoes });
                    })
                    .catch(error => {
                        throw new Error(error);
                    });

                return true;
            } catch (error) {
                Alerta([error.message], 'error');

                return false;
            }
        },
        async dadosPaginaInicial() {
            try {
                this.mostraCarregando(true);

                await ApiService({
                    method: 'get',
                    url: `${API_URL.erp.planodesenvolvimentoindividual.dadosPaginaInicial}`,
                    data: {},
                    mostraCarregando: false
                })
                    .then(async resposta => {
                        await this.buscaPermissoes();
                    })
                    .catch(erro => {
                        this.mostraCarregando(false);
                        this.notificacao = {
                            mensagem: erro.message,
                            tipo: 'error',
                            mostra: true
                        };
                    });

                this.permissao = true;
            } catch (erro) {
                Alerta([erro.message], 'error');
            } finally {
                this.mostraCarregando(false);
            }
        },
        async buscaDatatableItens() {
            this.datatable.carregando = true;

            const query = this.$refs.tabela.queryFiltros();

            await ApiService({
                method: 'get',
                url: `${API_URL.erp.planodesenvolvimentoindividual.datatable}/?${query}`,
                data: {},
                mostraCarregando: false
            })
                .then(resposta => {
                    this.mostraCarregando(false);

                    this.datatable.itens = resposta.data.conteudo.datatable.itens;

                    this.datatable.totalRegistros = resposta.data.conteudo.datatable.total;
                    this.datatable.ultima_pagina = resposta.data.conteudo.datatable.ultima_pagina;
                    this.datatable.carregando = false;
                })
                .catch(erro => {
                    this.datatable.carregando = false;

                    throw new Error(erro.message);
                });
        },
        limpaFiltro() {
            this.$refs.tabela.limpaFiltros();
        },
        editaItem(item) {
            this.$router.push({
                name: 'plano-desenvolvimento-individual-form',
                params: { id_plano_desenvolvimento_individual: item.id_plano_desenvolvimento_individual }
            });
        },
        async imprimir(item) {
            const id_plano_desenvolvimento_individual = this.datatable.itensSelecionados[0]
                ? this.datatable.itensSelecionados[0].id_plano_desenvolvimento_individual
                : null;

            if (!id_plano_desenvolvimento_individual) {
                Alerta(['Selecione um PDI para imprimir.'], 'error');

                return;
            }

            await ApiService({
                method: 'GET',
                url: `${API_URL.erp.planodesenvolvimentoindividual.imprimir}/${id_plano_desenvolvimento_individual}`,
                responseType: 'blob'
            })
                .then(resposta => {
                    const blob = new Blob([resposta.data], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);
                    window.open(url);
                })
                .catch(erro => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        },
        selecionaCor(situacao) {
            if (SituacaoEnum.FINALIZADO == situacao) {
                return 'green darken-1';
            }

            if (SituacaoEnum.AGUARDANDO_APROVACAO == situacao) {
                return 'yellow darken-1';
            }

            return 'orange darken-1';
        }
    },
    data() {
        return {
            permissao: false,
            permissoes: [],
            botoes: [
                {
                    id_permissao: [31],
                    nome: 'Imprimir',
                    acao: 'imprimir',
                    icone: 'mdi-printer',
                    cor: 'blue-grey darken-5',
                    form: [SituacaoForm.GRID]
                },
                {
                    id_permissao: [6],
                    nome: '',
                    acao: 'buscaDatatableItens',
                    icone: 'mdi-reload',
                    cor: 'grey darken-5',
                    form: [SituacaoForm.GRID]
                },
                {
                    id_permissao: 6,
                    nome: '',
                    acao: 'limpaFiltro',
                    icone: 'mdi-filter-multiple',
                    cor: 'red darken-5',
                    form: [SituacaoForm.GRID]
                }
            ],
            mensagemCarregando: 'Buscando, aguarde...',
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            datatable: {
                carregando: false,
                mensagemCarregando: 'Buscando, aguarde...',
                chave_primaria: 'id_plano_desenvolvimento_individual',
                itens: [],
                itensSelecionados: [],
                totalRegistros: 0,
                ultima_pagina: 0,
                pagina_atual: 1,
                por_pagina: 50,
                ordenarPor: 'id_plano_desenvolvimento_individual',
                ordenarDirecao: true,
                opcoes: {},
                cabecalho: [
                    {
                        text: 'AÇÃO',
                        value: 'acao',
                        align: 'start',
                        width: '100',
                        sortable: false
                    },
                    {
                        text: 'ID',
                        value: 'id_plano_desenvolvimento_individual',
                        width: '100'
                    },
                    {
                        text: 'Avaliação',
                        value: 'avaliacao_desempenho_nome',
                        width: '20%'
                    },
                    {
                        text: 'Colaborador',
                        value: 'parceiro_colaborador',
                        width: '25%'
                    },
                    {
                        text: 'Gestor',
                        value: 'parceiro_gestor',
                        width: '25%'
                    },
                    {
                        text: 'Situação',
                        value: 'situacao',
                        width: '10%',
                        type: 'select',
                        filterData: [
                            {
                                value: 1,
                                label: 'Aberto'
                            },
                            {
                                value: 2,
                                label: 'Aguardando Aprovação'
                            },
                            {
                                value: 3,
                                label: 'Finalizado'
                            }
                        ]
                    },
                    {
                        text: 'Período de',
                        value: 'periodo_de',
                        width: '150'
                    },
                    {
                        text: 'Período até',
                        value: 'periodo_ate',
                        width: '150'
                    }
                ]
            }
        };
    }
};
</script>

<style></style>
