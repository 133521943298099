<template>
    <v-main class="pa-0">
        <v-app id="inspire">
            <Carregando />
            <Login2 />
        </v-app>
    </v-main>
</template>

<script>
import Carregando from '@/components/Carregando.vue';
import Login from '@/views/Login.vue';
import Login2 from '@/views/Login/Login.vue';

export default {
    name: 'loginLayout',
    components: {
        Carregando,
        Login,
        Login2
    },
    data: () => ({}),
    methods: {
        // DO NOTHING
    },
};
</script>

<style scoped>
.router-link-active {
    text-decoration: none;
    color: inherit;
}
</style>
