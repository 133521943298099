<template>
    <div>
        <TituloModulo />

        <AlertaMensagem dense style="width: 100%" />

        <BarraNavegacaoBotaoV3 :botoes="botoesPorForm('form-salva')" referenciaComponenete="TrackingEmailForm" />

        {{ botoesPorForm('form-salva') }}

        <v-card>
            <v-tabs v-model="tabComponente" background-color="orange accent-2" dense centered dark icons-and-text>
                <v-tabs-slider></v-tabs-slider>

                <v-tab>
                    Dados
                    <v-icon>mdi-file-sign </v-icon>
                </v-tab>

                <v-tab>
                    E-mails Destinatários
                    <v-icon>mdi-at</v-icon>
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabComponente">
                <v-form ref="trackingEmailForm">
                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-text>
                                <DadosForm ref="dadosRef" :dados="form.dados" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-text>
                                <EmailsDestinatariosForm
                                    ref="emailsDestinatariosRef"
                                    :emailsDestinatarios="form.email_destinatarios"
                                />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-form>
            </v-tabs-items>
        </v-card>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import { Alerta } from '@/helpers/Funcoes.js';
import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import DadosForm from '@/views/TrackingEmail/DadosForm.vue';
import EmailsDestinatariosForm from '@/views/TrackingEmail/EmailsDestinatariosForm.vue';

export default {
    name: 'TrackingEmailForm',
    components: {
        AlertaMensagem,
        TituloModulo,
        BarraNavegacaoBotaoV3,
        DadosForm,
        EmailsDestinatariosForm
    },
    async mounted() {
        await this.buscaForm(this.$route.params.id_tracking_email ?? null);
    },
    computed: {
        ...mapGetters(['botoesPorForm'])
    },
    watch: {},
    methods: {
        ...mapActions(['montaBarraNavegacao']),
        async buscaForm(id_tracking_email) {
            if (!id_tracking_email) {
                return;
            }

            await ApiService({
                method: 'get',
                url: `${API_URL.erp.trackingemail.busca}/${id_tracking_email}`,
                data: {}
            })
                .then(resposta => {
                    const item = resposta.data.data.atributos;

                    item.id_tracking_email = resposta.data.data.id_registro;

                    this.preencheFormulario(item);
                })
                .catch(erro => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        },
        preencheFormulario(dados) {
            const form = { ...dados };

            this.form.dados = form;
            this.form.email_destinatarios = form.destinatarios;
        },
        async salvaForm() {
            if (!this.validaForm()) {
                return;
            }

            try {
                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', 'Salvar registro ?', {
                        cor: 'green'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        let conteudoFormulario = this.FormataDadosParaEnviarForm();

                        let urlForm = conteudoFormulario.dados.id_tracking_email
                            ? `${API_URL.erp.trackingemail.salva}/${conteudoFormulario.dados.id_tracking_email}`
                            : `${API_URL.erp.trackingemail.salva}`;

                        let urlConfig = {
                            method: conteudoFormulario.dados.id_tracking_email ? 'PUT' : 'POST',
                            url: urlForm,
                            data: conteudoFormulario
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                Alerta(['Registro salvo'], 'success');

                                const item = resposta.data.data.atributos;

                                item.id_tracking_email = resposta.data.data.id_registro;

                                this.preencheFormulario(item);

                                this.tabComponente = 0;
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        fechaForm() {
            this.$router.push({
                name: 'tracking-email',
                params: {}
            });
        },
        validaForm() {
            let forms = [];

            if (!this.$refs.dadosRef.$refs.form.validate()) {
                forms.push('Dados');
            }

            if (!this.$refs.emailsDestinatariosRef.$refs.form.validate()) {
                forms.push('E-mail Destinatário');
            }

            if (forms.length > 0) {
                const abas = forms.join(', ');

                Alerta([`Campos inválidos nas abas ${abas}`], 'error');

                return false;
            }

            return true;
        },
        FormataDadosParaEnviarForm() {
            let dados = this.$refs.dadosRef.form;

            dados.destinatarios = this.$refs.emailsDestinatariosRef.preparaDados();

            return {
                dados
            };
        }
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            tabComponente: null,
            tabs: ['dados', 'e-mail destinatário'],
            form: {
                dados: null,
                email_destinatarios: null
            }
        };
    }
};
</script>

<style></style>
