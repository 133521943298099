<template>
    <v-autocomplete
        :value="value"
        @input="$emit('input', $event)"
        :items="itemsOpcoes"
        :loading="estaCarregando"
        :label="tituloBusca"
        :rules="regra"
        :disabled="desativado"
        :item-value="itemValor"
        :item-text="itemTexto"
        :small-chips="smallChips"
        :deletable-chips="deletableChips"
        :multiple="multiple"
        :append-icon="iconeAppend"
        :outlined="estilo('outlined')"
        v-bind="$attrs"
        loader-height="3"
        hide-no-data
        clearable
        dense
    >
        <template v-slot:append>
            <span v-if="estaCarregando"> Aguarde... </span>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    props: {
        value: {
            type: Number | String,
            default: () => null
        },
        datatableConfiguracao: {
            type: Object,
            default: () => {}
        },
        tituloBusca: {
            type: String,
            default: () => ''
        },
        itemsOpcoes: {
            type: Array,
            default: () => {}
        },
        itemValor: {
            type: String,
            default: () => ''
        },
        itemTexto: {
            type: String,
            default: () => ''
        },
        regra: {
            type: Array,
            default: () => []
        },
        formatoRetornado: {
            type: Object,
            default() {
                return { descricao: '%s - %s' };
            }
        },
        desativado: {
            type: Boolean,
            default: () => false
        },
        obrigatorio: {
            type: Boolean,
            default: () => false
        },
        iconeAppend: {
            type: String,
            default: () => 'search'
        },
        smallChips: {
            type: Boolean,
            default: () => false
        },
        deletableChips: {
            type: Boolean,
            default: () => false
        },
        multiple: {
            type: Boolean,
            default: () => false
        },
        estiloCampo: {
            type: String,
            default: () => 'outlined'
        }
    },
    data: () => ({
        estaCarregando: false
    }),
    async created() {},
    computed: {},
    watch: {},
    mounted() {},
    methods: {
        estilo(estiloDefinido) {
            if (this.estiloCampo == estiloDefinido) {
                return true;
            }

            return false;
        }
    }
};
</script>

<style scoped></style>
