<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="1800px">

      <usuario-perfil-tab-permissoes-selecionadas v-if="IDSperfisSelecionados.length > 0"
        :perfisSelecionados="perfisSelecionadosParaExibirNaTab"
        @eventoAtualizarPerfisSelecionados="atualizarPerfisSelecionados">
      </usuario-perfil-tab-permissoes-selecionadas>

      <template v-slot:activator="{ on, attrs }">
        <slot></slot>
      </template>

      <v-card>

        <v-card-title>
          <v-list-item class="grow">

            <span class="text-btn text-uppercase">Atribiur perfil a um usuário</span>

            <v-row justify="end">
              <v-icon class="mr-1" large color="red" @click="eventoAbrirOuFecharModal()">
                mdi-close-circle-outline
              </v-icon>
            </v-row>

          </v-list-item>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <div class="pt-4 pb-2">Selecione o usuário que receberá o(s) perfil(s)</div>

            <InputAutoCompleteV3 
              v-model="usuario" 
              :datatableConfiguracao="autoCompleta.usuarios.datatable"
              tituloBusca="Usuario busca..." :itemsOpcoes="itensUsuarios" itemValor="id_usuario" itemTexto="nome"
              return-object :regras="[v => !!v || 'Usuário é obrigatório']" />

            <v-row>
              <v-col cols="3" v-for="perfil, key in perfisExistentes" :key="key">
                <label :class="perfisQueOUsuarioPossui.includes(perfil.id_registro) ? '' : 'perfil-card-pointer'">
                  <input type="checkbox" class="invisible-checkbox" v-model="IDSperfisSelecionados"
                    :value="perfil.id_registro"
                    :disabled="perfisQueOUsuarioPossui.includes(perfil.id_registro) ? true : false">
                  <v-container fluid>
                    <v-card class="pa-2 align-center text-center v-card--hover" :color="cardDePermissoesCor(perfil.id_registro)"
                      style="min-height: 200px;">
                      <v-chip pill small style="min-width: 25px;"><strong>ID: {{ perfil.id_registro }}</strong></v-chip>
                      <v-row class="my-4 text-subtitle-1">
                        <v-col cols="12">
                          <v-chip color="primary" x-small>NÍVEL DE ACESSO:</v-chip>
                        </v-col>
                        <span class="text--success text-subtitle-2 mx-auto"
                          style="text-transform: uppercase;">{{ perfil.atributos.nome }}</span>
                        <v-col cols="12">
                          <span class="text--success caption">{{ perfil.atributos.descricao }}</span>
                        </v-col>
                      </v-row>

                    </v-card>

                  </v-container>
                </label>
              </v-col>
            </v-row>


          </v-container>
        </v-card-text>

        <v-card-subtitle>
          <v-btn text color="success" class="ma-2 white--text mb-10" @click="atribuirMuitosPerfis()">
            SALVAR
          </v-btn>

          <v-btn text plain color="red" class="ma-2 white--text mb-10" @click="eventoAbrirOuFecharModal()">
            CANCELAR
          </v-btn>
        </v-card-subtitle>

      </v-card>

    </v-dialog>

    <div>
      <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
        {{ notificacao.mensagem }}

        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
            Fechar
          </v-btn>
        </template>
      </v-snackbar>
    </div>

  </div>
</template>

<script>
import InputAutoCompleteV3 from '@/components/Form/InputAutoCompleteV3.vue';
import { API_URL, API_URL_BASE } from '@/config/configs';
import UsuarioPerfilTabPermissoesSelecionadas from '@/components/UsuarioPerfil/Embeeded/UsuarioPerfilTabPermissoesSelecionadas'
import ApiService from '@/services/ApiService.js';

export default {
  name: 'DialogAtribuirPermissoes',
  components: {
    InputAutoCompleteV3,
    UsuarioPerfilTabPermissoesSelecionadas
  },
  async mounted() {
    // await this.buscaPerfis()
  },
  data() {
    return {
      usuario: {
        id_usuario: null,
        nome: null
      },
      notificacao: {
        mostra: false,
        mensagem: '',
        tipo: 'success'
      },
      dialog: false,
      perfisExistentes: [],
      perfisQueOUsuarioPossui: [],
      IDSperfisSelecionados: [],
      perfisSelecionadosParaExibirNaTab: [],
      autoCompleta: {
        usuarios: {
          datatable: {
            id_datatable: 'id_usuario',
            chave_primaria: 'id_usuario',
            url: `${API_URL_BASE}${API_URL.erp.usuario.datatableVuetify}`,
            urlBusca: `${API_URL_BASE}${API_URL.erp.usuario.autoCompleta}`,
            colunas: [
              {
                id: 'id_usuario',
                nome: 'id_usuario',
                descricao: 'ID'
              },
              {
                id: 'nome',
                nome: 'nome',
                descricao: 'Nome Completo'
              }
            ]
          }
        }
      }
    }
  },
  props: {
    itensUsuarios: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  watch: {
    'usuario.id_usuario': {
      handler: function (dadosAtual, dadosAntigo) {
        this.limparCampos()
        if (this.usuario.id_usuario != null) {
          this.buscaPerfisDoUsuario(this.usuario.id_usuario);
        }
      }
    },
    IDSperfisSelecionados: {
      handler: function (dadosAtual, dadosAntigo) {
        this.perfisParaExibicaoNaTabDeModificacoes(dadosAtual)
      }
    }
  },
  created() {
    if (!_.isEmpty(this.usuarioData)) {
      this.preencheDados(this.usuarioData);
    }
  },
  methods: {
    eventoAbrirOuFecharModal() {
      this.dialog = !this.dialog

      this.limparCampos()
      // defino a variável do usuário aqui e nao dentro do limparCampos() pois ele gerava um bug no watcher e o usuario sempre ficava vazio
      this.usuario = {
        id_usuario : null,
        nome: null
      }
    },

    preparaDados() {
      const usuario = { ...this.usuario };

      return usuario;
    },

    // async buscaPerfis() {
    //   try {
    //     await ApiService({
    //       method: 'get',
    //       url: `${API_URL.erp.usuarioPerfil.buscaPerfis}`,
    //       data: {},
    //       mostraCarregando: true
    //     })
    //       .then(async resposta => {
    //         this.perfisExistentes = resposta.data.data
    //       })
    //   } catch (erro) {
    //     Alerta([erro.message], 'error');
    //   } finally {
    //     this.carregando = false;
    //   }
    // },

    async buscaPerfisDoUsuario(idDoUsuario) {
      try {
        await ApiService({
          method: 'get',
          url: `${API_URL.erp.usuarioPerfil.buscaPerfisDoUsuario}/${idDoUsuario}`,
          data: {},
          mostraCarregando: true
        })
          .then(async resposta => {
            let perfisQueOUsuarioPossui = []

            resposta.data[0].perfis.map(perfil => {
              perfisQueOUsuarioPossui.push(perfil.id_perfil);
            });

            this.perfisQueOUsuarioPossui = perfisQueOUsuarioPossui
          })
      } catch (erro) {
        this.notificacao = {
          mensagem: erro.message,
          tipo: 'error',
          mostra: true
        };
      } finally {
        this.carregando = false;
      }
    },

    async atribuirUmPerfil(idPerfil) {
      try {
        await ApiService({
          method: 'POST',
          url: `${API_URL.erp.usuarioPerfil.novo}`,
          data: {
            id_usuario: this.usuario.id_usuario,
            id_perfil: idPerfil,
          },
          mostraCarregando: true
        })
      } catch (erro) {
        this.notificacao = {
          mensagem: erro.message,
          tipo: 'error',
          mostra: true
        }
      }
    },

    async atribuirMuitosPerfis() {

      if (this.usuario.id_usuario == null) {
        this.notificacao = {
          mensagem: 'O usuário não foi selecionado',
          tipo: 'error',
          mostra: true
        }
        return
      }

      if (this.IDSperfisSelecionados.length == 0) {
        this.notificacao = {
          mensagem: 'Nenhuma alteração realizada',
          tipo: 'success',
          mostra: true
        }
        return
      }

      try {
        for (let IDperfil in this.IDSperfisSelecionados) {
          this.mostraCarregando = true
          await this.atribuirUmPerfil(this.IDSperfisSelecionados[IDperfil])
        }
        this.notificacao = {
          mensagem: 'Nova(s) permissõe(s) atribuída(s)',
          tipo: 'success',
          mostra: true
        }
        this.limparCampos()
      } catch (erro) {
        this.notificacao = {
          mensagem: erro.message,
          tipo: 'error',
          mostra: true
        }
      } finally {
        this.mostraCarregando = false
        // this.buscaPerfis()
        this.buscaPerfisDoUsuario(this.usuario.id_usuario)
        
      }
    },

    verificarSeOPerfilEstaDentroDoArray(arrayDePerfis, id) {
      for (let perfil in arrayDePerfis) {
        if (arrayDePerfis[perfil].id_perfil === id) {
          return true;
        }
      }
      return false;
    },

    perfisParaExibicaoNaTabDeModificacoes(dadosAtuaisDoWatcher) {
      let perfisSelecionadosParaExibirNaTab = [];

      /**
       * percorro o array de perfis existente e comparo se o id selecionado é igual ao do perfil existente, 
       * se for eu faço uma segunda validação para saber se esta sendo excluido ou adicionado e modifico suas propridades
      **/

      for (let key in dadosAtuaisDoWatcher) {
        const IDDoPerfilSelecionado = dadosAtuaisDoWatcher[key]

        this.perfisExistentes.map(perfil => {
          if ((perfil.id_registro == IDDoPerfilSelecionado)) {

            let acaoValue;

            if (this.verificarSeOPerfilEstaDentroDoArray(this.perfisQueOUsuarioPossui, IDDoPerfilSelecionado)) {
              acaoValue = 'apagar'
            }

            if (!this.verificarSeOPerfilEstaDentroDoArray(this.perfisQueOUsuarioPossui, IDDoPerfilSelecionado)) {
              acaoValue = 'adicionar'
            }
            const perfilParaTab = {
              acao: acaoValue,
              id: perfil.id_registro,
              titulo: perfil.atributos.nome,
              descricao: perfil.atributos.descricao
            }
            perfisSelecionadosParaExibirNaTab.push(perfilParaTab)
          }
        })
      }
      this.perfisSelecionadosParaExibirNaTab = perfisSelecionadosParaExibirNaTab
    },

    // atualiza a listagem do bottom sheet que exibe os perfis selecionados
    atualizarPerfisSelecionados(id) {
      const listaDePerfisAtualizada = this.IDSperfisSelecionados.filter(IDperfil => IDperfil !== id);
      this.IDSperfisSelecionados = listaDePerfisAtualizada
    },

    cardDePermissoesCor(id_registro) {
      if (this.perfisQueOUsuarioPossui.includes(id_registro)) {
        return 'grey lighten-3'
      }

      if (this.IDSperfisSelecionados.includes(id_registro)) {
        return 'blue lighten-5'
      }

      return 'white'
    },

    limparCampos() {
      this.perfisQueOUsuarioPossui = []
      this.IDSperfisSelecionados = []
      this.perfisSelecionadosParaExibirNaTab = []
    }

  }
}
</script>

<style scoped>
.invisible-checkbox {
  display: none;
}

.perfil-card-pointer {
  cursor: pointer;
}
</style>