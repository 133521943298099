import { API_URL_BASE, API_URL } from '@/config/configs';

const Permissao = {
    datatable: {
        id_datatable: 'permissao',
        chave_primaria: 'id_permissao',
        url: `${API_URL_BASE}${API_URL.erp.permissao.datatable}`,
        colunas: false,
    },
    formRefsComponente: 'PermissaoForm',
    formNome: 'formPermissao',
    moduloNome: 'permissao',
    chavePrimaria: 'id_permissao',
    componenteArquivo: 'Permissao/Form.vue',
    tabRefs: 'tabPermissao',
    urlNovoRegistro: '',
    urlConsultaRegistro: 'listaPorIdForm',
    tabs: []
}

export { Permissao };

