<template>
    <v-row dense>
        <v-col cols="12" sm="12" class="font-weight-black text-h6 text-center">
            {{ dadosData.nome }}
        </v-col>

        <v-col cols="12" sm="12">
            <v-card elevation="5" class="pa-3">
                <v-card-title class="pa-2 text-h5">
                    Avaliado
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                    <v-avatar>
                        <v-img src="@/assets/image/perfil/img_avatar.png"></v-img>
                    </v-avatar>

                    <v-chip small class="ma-2" color="pink darken-1" dark label>
                        <v-icon left>
                            mdi-account
                        </v-icon>
                        <span>{{ dadosData.nome_colaborador }}</span>
                    </v-chip>

                    <v-chip class="ma-2" color="blue-grey lighten-1" dark x-small>
                        Colaborador
                    </v-chip>
                </v-card-text>

                <v-card-text>
                    <v-avatar>
                        <v-img src="@/assets/image/perfil/img_avatar.png"></v-img>
                    </v-avatar>

                    <v-chip small class="ma-2" color="indigo darken-1" dark label>
                        <v-icon left>
                            mdi-account-multiple
                        </v-icon>
                        <span>{{ dadosData.nome_colaborador_gestor }}</span>
                    </v-chip>

                    <v-chip class="ma-2" color="blue-grey lighten-1" dark x-small>
                        Gestor
                    </v-chip>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12" sm="12" class="pt-10">
            <v-card elevation="5" color="orange lighten-3" class="pa-5">
                <v-card-title class="pa-2 text-h5">
                    3 Pontos Desenvolver
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                    <template v-for="(pontoDesenvolver, keyPontoDesenvolver) in dadosData.ponto.desenvolver">
                        <v-alert
                            :key="`ponto-desenvolver-${keyPontoDesenvolver}`"
                            border="left"
                            type="warning"
                            colored-border
                            elevation="2"
                            prominent
                            dense
                        >
                            <div class="text-h7">{{ `#${keyPontoDesenvolver + 1}` }} - {{ pontoDesenvolver.ponto }}</div>
                            <div>{{ pontoDesenvolver.descricao }}</div>
                        </v-alert>
                    </template>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12" sm="12" class="pt-10">
            <v-card elevation="5" color="blue-grey lighten-3" class="pa-5">
                <v-card-title class="pa-2 text-h5">
                    3 Pontos fortes
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                    <template v-for="(pontoForte, keyPontoForte) in dadosData.ponto.forte">
                        <v-alert
                            :key="`ponto-forte-${keyPontoForte}`"
                            border="left"
                            colored-border
                            color="green accent-4"
                            type="success"
                            elevation="2"
                            prominent
                            dense
                        >
                            <div class="text-h7">{{ `#${keyPontoForte + 1}` }} - {{ pontoForte.ponto }}</div>
                            <div>{{ pontoForte.descricao }}</div>
                        </v-alert>
                    </template>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'FormDados',
    components: {},
    props: {
        dadosData: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    async created() {},
    async mounted() {},
    computed: {},
    watch: {},
    methods: {},
    data() {
        return {};
    }
};
</script>

<style></style>
