const SituacaoForm = {
    NOVO: 1,
    ATUALIZA: 1,
    AGUARDANDO_APROVACAO: 2,
    FINALIZADO: 3,
    GRID: 'grid',
    TODOS: 'todos'
};

export default SituacaoForm;
