const state = {
    snack: {
        show: false,
        message: '',
        color: ''
    }
};

const mutations = {
    setSnack(state, showSnack) {
        state.snack = { ...showSnack };
    }
};

const actions = {
    mensagem({ commit, state }, { mensagem }) {
        try {
            commit('setSnack', mensagem);
        } catch (erro) {
            throw new Error(erro.message);
        }
    }
};

const getters = {};

export default {
    state,
    mutations,
    actions,
    getters
};
