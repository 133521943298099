<template>
    <v-card-text>
        <v-row>
            <v-col cols="2">
                <InputTextV3 v-model="form.id_tracking_email_destinatario" rotulo="ID" :contador="200" desativado />
            </v-col>

            <v-col cols="10">
                <InputTextV3 v-model="form.email" rotulo="E-mail" :contador="150" />
            </v-col>

            <v-col cols="12">
                <TextAreaV3 v-model="form.observacao" :contador="500" :obriatorio="false" rotulo="Observação" />
            </v-col>
        </v-row>
    </v-card-text>
</template>

<script>
import InputTextV3 from '@/components/Form/InputTextV3.vue';
import TextAreaV3 from '@/components/Form/TextAreaV3.vue';

export default {
    name: 'EmailDestinatarioForm',
    components: {
        InputTextV3,
        TextAreaV3
    },
    props: {
        emailDestinatario: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    watch: {
        emailDestinatario: {
            handler: function(dadosAtual, dadosAntigo) {
                this.preencheDados(dadosAtual);
            },
            immediate: true
        }
    },
    created() {},
    data() {
        return {
            form: {
                id_tracking_email_destinatario: null,
                email: null,
                observacao: null
            }
        };
    },
    methods: {
        preparaDados() {
            const emailDestinatario = { ...this.form };

            return emailDestinatario;
        },
        preencheDados(dados) {
            this.form = { ...dados };
        }
    }
};
</script>
