<template>
    <v-file-input
        v-model="arquivos"
        :accept="tipoArquivo"
        :label="rotulo"
        :prepend-icon="prependIcon"
        :rules="regraInterna"
        :multiple="multiploArquivos(false)"
        :truncate-length="tamanhoTexto"
        :show-size="1000"
        v-on:change="enviaEvento($event)"
        ref="inputFile"
        chips
        counter
        outlined
        dense
    >
        <template v-slot:selection="{ index, text }">
            <v-chip color="deep-purple accent-4" dark label small>
                {{ text }}
            </v-chip>
        </template>
    </v-file-input>
</template>

<script>
export default {
    props: {
        value: {},
        tipoArquivo: {
            type: String,
            default: 'image/*',
        },
        converteParaBase64: {
            type: Boolean,
            default: false,
        },
        rotulo: {
            type: String,
            default: 'Selecione o arquivo',
        },
        prependIcon: {
            type: String,
            default: 'mdi-file',
        },
        regra: {
            type: Array,
            default: () => [],
        },
        multiplo: {
            type: Boolean,
            default: () => false,
        },
        tamanhoTexto: {
            type: Number,
            default: () => 25,
        },
        tipoArquivoPermitido: {
            type: Array,
            default: () => [
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'image/png',
                'image/jpg',
                'image/jpeg',
                'image/gif',
                'application/pdf',
                'application/xml',
            ],
        },
        abreInputFile: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            arquivos: this.value,
            regraInterna: [],
        };
    },
    watch: {
        regra: {
            handler: function (dadosAtual) {
                //this.regraInterna = [...this.regraInterna, ...dadosAtual];
                this.adicionaRegra(dadosAtual);
            },
            immediate: true,
        },
        value: {
            handler: function (dadosAtual, dadosAntigo) {
                this.arquivos = dadosAtual ?? [];
            },
            deep: true,
        },
        abreInputFile: {
            handler: function (dadosAtual) {
                if (dadosAtual == true) {
                    this.$refs.inputFile.$refs.input.click();
                }
            },
            deep: true,
        },
    },
    mounted() {
        let regra = null;

        if (this.multiplo) {
            regra = [
                (v) =>
                    !v.length || v.some((file) => this.validaArquivosPermitidos(file.type)) || 'Tipo de arquivo não permitido',
            ];
        }

        if (!this.multiplo) {
            regra = [(v) => !v.length || this.validaArquivosPermitidos(v.type) || 'Tipo de arquivo não permitido'];
        }

        this.adicionaRegra(regra);
    },
    computed: {},
    methods: {
        multiploArquivos() {
            if (this.multiplo) {
                return true;
            }

            return false;
        },
        adicionaRegra(regra) {
            this.regraInterna = [...regra, ...this.regraInterna];
        },
        enviaEvento(arquivo) {
            this.$emit('change', arquivo);
        },
        validaArquivosPermitidos(tipo) {
            if (this.tipoArquivoPermitido.includes(tipo)) {
                return true;
            }

            return false;
        },
    },
};
</script>
