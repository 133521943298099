const state = {
  tamanho: 100,
  abreFecha: false,
  icone: "mdi-arrow-collapse-left",
  iconeEsquerda: 'mdi-arrow-collapse-left',
  iconeDireita: 'mdi-arrow-collapse-right'
}

const actions = {
  painelAbreFecha ({commit}) {
    commit('abreFecha');
  },
  painelAbre({commit}) {
    commit('abre');
    commit('abreFecha');
  },
  painelFecha({commit}) {
    commit('fecha');
    commit('abreFecha');
  }
}

const mutations = {
    abre() {
        state.abreFecha = false;
    },
    fecha() {
        state.abreFecha = true;
    },
    abreFecha () {
      state.tamanho = state.abreFecha ? 100 : 0;
      state.icone = state.abreFecha
          ? state.iconeEsquerda
          : state.iconeDireita;
       state.abreFecha = !state.abreFecha;
    }
}

const getters = {
  painelTamanho: state => state.tamanho,
  painelEscondidoTamanho: state => 100 - state.tamanho,
  painelIcone: state => state.icone
}

export default {
  state,
  mutations,
  actions,
  getters
}
