<template>
    <div v-show="preloader" class="preloader" style="padding: 15%">
        <img
            src="../assets/image/carregando/aguarde-7.gif"
            width="106"
            height="106"
            alt="Carregando..."
            class="preloader1"
        />
    </div>
</template>

<script>
export default {
    props: {
        carregando: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        preloader() {
            if (this.carregando) {
                return true;
            }

            return this.$store.state.carregando.loading;
        },
    },
};
</script>

<style scoped>
.preloader {
    text-align: center;
    background: #262626;
    color: #fff;
    /* padding: 28%; */
    position: absolute;
    z-index: 9999;
    opacity: 0.8;
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
}
img.preloader {
    width: 274px;
    height: auto;
    max-width: 100%;
    position: relative;
}
</style>
