const data = {
    drawflow: {
        Home: {
            data: {
                '4': {
                    id: 4,
                    data: {
                        itens: [
                            {
                                descricao: '1 - Sou colaborador',
                                id_whatsapp_fluxo_item: 114,
                                id_whatsapp_fluxo_item_questao: 99
                            },
                            {
                                descricao: '2 - Sou cliente',
                                id_whatsapp_fluxo_item: 114,
                                id_whatsapp_fluxo_item_questao: 100
                            },
                            {
                                descricao: '3 - Sou fornecedor',
                                id_whatsapp_fluxo_item: 114,
                                id_whatsapp_fluxo_item_questao: 101
                            }
                        ],
                        descricao:
                            'Olá sou Ana atendente virtual do Grupo Terra Nova. Para iniciarmos seu atendimento, escolha uma das opções abaixo:',
                        nome_node: 'Itens-6jcsb',
                        id_whatsapp_fluxo_item: 114
                    },
                    html: 'Itens-6jcsb',
                    name: 'Itens-6jcsb',
                    class: 'Itens-6jcsb',
                    pos_x: -166,
                    pos_y: -279,
                    inputs: {},
                    outputs: {
                        output_1: {
                            connections: [
                                {
                                    node: '5',
                                    output: 'input_1'
                                }
                            ]
                        },
                        output_2: {
                            connections: []
                        },
                        output_3: {
                            connections: []
                        }
                    },
                    typenode: 'vue'
                },
                '5': {
                    id: 5,
                    data: {
                        itens: [
                            {
                                descricao: '1 - Terra Nova (Transportes)',
                                id_whatsapp_fluxo_item: 115,
                                id_whatsapp_fluxo_item_questao: 102
                            },
                            {
                                descricao: '2 - TN Logistica (Armazém Geral)',
                                id_whatsapp_fluxo_item: 115,
                                id_whatsapp_fluxo_item_questao: 103
                            },
                            {
                                descricao: '3 - Cargo 3 (Frete Internacional)',
                                id_whatsapp_fluxo_item: 115,
                                id_whatsapp_fluxo_item_questao: 104
                            }
                        ],
                        descricao: 'Indique a empresa:',
                        nome_node: 'Itens-wk27r',
                        id_whatsapp_fluxo_item: 115
                    },
                    html: 'Itens-wk27r',
                    name: 'Itens-wk27r',
                    class: 'Itens-wk27r',
                    pos_x: 425,
                    pos_y: 111,
                    inputs: {
                        input_1: {
                            connections: [
                                {
                                    node: '4',
                                    input: 'output_1'
                                }
                            ]
                        }
                    },
                    outputs: {
                        output_1: {
                            connections: [
                                {
                                    node: '6',
                                    output: 'input_1'
                                }
                            ]
                        },
                        output_2: {
                            connections: [
                                {
                                    node: '7',
                                    output: 'input_1'
                                }
                            ]
                        },
                        output_3: {
                            connections: [
                                {
                                    node: '8',
                                    output: 'input_1'
                                }
                            ]
                        }
                    },
                    typenode: 'vue'
                },
                '6': {
                    id: 6,
                    data: {
                        itens: [
                            {
                                descricao: '1 - Frota',
                                id_whatsapp_fluxo_item: 116,
                                id_whatsapp_fluxo_item_questao: 105
                            },
                            {
                                descricao: '2 - Operações',
                                id_whatsapp_fluxo_item: 116,
                                id_whatsapp_fluxo_item_questao: 106
                            },
                            {
                                descricao: '3 - Financeiro, Fiscal ou Faturamento',
                                id_whatsapp_fluxo_item: 116,
                                id_whatsapp_fluxo_item_questao: 107
                            },
                            {
                                descricao: '5 - TI',
                                id_whatsapp_fluxo_item: 116,
                                id_whatsapp_fluxo_item_questao: 108
                            },
                            {
                                descricao: '6 - Qualidade e/ou Meio Ambiente',
                                id_whatsapp_fluxo_item: 116,
                                id_whatsapp_fluxo_item_questao: 109
                            },
                            {
                                descricao: '7 - Compras e Facilities',
                                id_whatsapp_fluxo_item: 116,
                                id_whatsapp_fluxo_item_questao: 110
                            },
                            {
                                descricao: '8 - Recursos Humanos',
                                id_whatsapp_fluxo_item: 116,
                                id_whatsapp_fluxo_item_questao: 111
                            }
                        ],
                        descricao: 'Qual seu departamento:',
                        nome_node: 'Itens-fk7bs',
                        id_whatsapp_fluxo_item: 116
                    },
                    html: 'Itens-fk7bs',
                    name: 'Itens-fk7bs',
                    class: 'Itens-fk7bs',
                    pos_x: 1082.25,
                    pos_y: -697,
                    inputs: {
                        input_1: {
                            connections: [
                                {
                                    node: '5',
                                    input: 'output_1'
                                }
                            ]
                        }
                    },
                    outputs: {
                        output_1: {
                            connections: []
                        },
                        output_2: {
                            connections: []
                        },
                        output_3: {
                            connections: []
                        },
                        output_4: {
                            connections: []
                        },
                        output_5: {
                            connections: []
                        },
                        output_6: {
                            connections: []
                        },
                        output_7: {
                            connections: []
                        }
                    },
                    typenode: 'vue'
                },
                '7': {
                    id: 7,
                    data: {
                        itens: [
                            {
                                descricao: '1 - Operações',
                                id_whatsapp_fluxo_item: 117,
                                id_whatsapp_fluxo_item_questao: 112
                            },
                            {
                                descricao: '2 - Financeiro, Fiscal ou Faturamento',
                                id_whatsapp_fluxo_item: 117,
                                id_whatsapp_fluxo_item_questao: 113
                            },
                            {
                                descricao: '3 - Comercial e/ou Vendas',
                                id_whatsapp_fluxo_item: 117,
                                id_whatsapp_fluxo_item_questao: 114
                            },
                            {
                                descricao: '4 - TI',
                                id_whatsapp_fluxo_item: 117,
                                id_whatsapp_fluxo_item_questao: 115
                            },
                            {
                                descricao: '5 - Qualidade e/ou Meio Ambiente',
                                id_whatsapp_fluxo_item: 117,
                                id_whatsapp_fluxo_item_questao: 116
                            },
                            {
                                descricao: '6 - Compras e Facilities',
                                id_whatsapp_fluxo_item: 117,
                                id_whatsapp_fluxo_item_questao: 117
                            },
                            {
                                descricao: '7 - Recursos Humanos',
                                id_whatsapp_fluxo_item: 117,
                                id_whatsapp_fluxo_item_questao: 118
                            }
                        ],
                        descricao: 'Qual seu departamento:',
                        nome_node: 'Itens-xv60m',
                        id_whatsapp_fluxo_item: 117
                    },
                    html: 'Itens-xv60m',
                    name: 'Itens-xv60m',
                    class: 'Itens-xv60m',
                    pos_x: 1086,
                    pos_y: 463,
                    inputs: {
                        input_1: {
                            connections: [
                                {
                                    node: '5',
                                    input: 'output_2'
                                }
                            ]
                        }
                    },
                    outputs: {
                        output_1: {
                            connections: []
                        },
                        output_2: {
                            connections: []
                        },
                        output_3: {
                            connections: []
                        },
                        output_4: {
                            connections: []
                        },
                        output_5: {
                            connections: []
                        },
                        output_6: {
                            connections: []
                        },
                        output_7: {
                            connections: []
                        }
                    },
                    typenode: 'vue'
                },
                '8': {
                    id: 8,
                    data: {
                        itens: [
                            {
                                descricao: 'Cargo',
                                id_whatsapp_fluxo_item: 118,
                                id_whatsapp_fluxo_item_questao: 119
                            }
                        ],
                        descricao: 'Qual seu departamento:',
                        nome_node: 'Itens-ib5xp',
                        id_whatsapp_fluxo_item: 118
                    },
                    html: 'Itens-ib5xp',
                    name: 'Itens-ib5xp',
                    class: 'Itens-ib5xp',
                    pos_x: 779.6666666666666,
                    pos_y: 1139,
                    inputs: {
                        input_1: {
                            connections: [
                                {
                                    node: '5',
                                    input: 'output_3'
                                }
                            ]
                        }
                    },
                    outputs: {
                        output_1: {
                            connections: []
                        }
                    },
                    typenode: 'vue'
                }
            }
        }
    }
};

export default data;
