<template>
    <v-card class="mt-5 mr-5 ml-5 mb-2 blue-grey lighten-5" elevation="3">
        <v-row>
            <v-col cols="12" sm="3" class="pr-0 bordaDireita">
                <v-app-bar class="pa-2" flat>
                    <v-avatar class="mt-n7" size="40">
                        <img src="@/assets/image/logo/logo_menor.png" />
                    </v-avatar>

                    <v-toolbar-title class="title pl-0 ml-2 mt-n4"> Terra Nova Programação Carga </v-toolbar-title>
                </v-app-bar>

                <v-app-bar class="pt-3" flat color="white">
                    <v-text-field
                        filled
                        rounded
                        clearable
                        light
                        solo
                        dense
                        class="pt-4"
                        label="Procurar conversa"
                        prepend-inner-icon="mdi-magnify"
                        @input="buscaConversa($event)"
                    ></v-text-field>

                    <v-spacer></v-spacer>

                    <v-icon color="blue" @click="">mdi-plus-circle</v-icon>

                    <v-spacer></v-spacer>
                </v-app-bar>

                <v-list two-line color="white" class="overflow-auto" style="min-height: 580px;">
                    <CarregandoLogoEmpresa :carregando="carregandoContatoLista" :mensagem="carregandoMensagemContatoLista" />

                    <v-list-item-group v-model="contatoSelecionado" active-class="blue-grey lighten-5">
                        <template v-for="(contato, index) in contatosFiltrados">
                            <v-list-item :key="index" @click="carregaMensagens(contato)">
                                <v-badge bordered bottom color="green" dot offset-x="22" offset-y="26">
                                    <v-list-item-avatar>
                                        <v-img src="@/assets/image/perfil/img_avatar.png"></v-img>
                                    </v-list-item-avatar>
                                </v-badge>

                                <template>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="contato.whatsapp_nome_profile"></v-list-item-title>

                                        <v-list-item-subtitle>
                                            {{ contato.ultima_mensagem }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action v-if="contato.total_mensagens">
                                        <v-list-item-subtitle class="text-caption text-end">
                                            {{ contato.hora_recebe_ultima_mensagem }}
                                        </v-list-item-subtitle>

                                        <v-chip right small class="subtitle-2" color="light-green lighten-3">
                                            {{ contato.total_mensagens }}
                                        </v-chip>
                                    </v-list-item-action>
                                </template>
                            </v-list-item>

                            <v-divider v-if="index < contatos.length - 1"></v-divider>
                        </template>
                    </v-list-item-group>
                </v-list>
            </v-col>

            <v-col cols="12" sm="9" class="pt-0 pl-0">
                <conversa
                    v-if="atual_id_whatsapp_conversa"
                    :conversa="conversa"
                    :mensagens="mensagens"
                    :carregando="carregandoMensagens"
                    @carrega-mensagens-anteriores="carregaMensagensAnteriores"
                    @envia-mensagem="enviaMensagem"
                />

                <informacoes v-else style="height: 100%;" />
            </v-col>
        </v-row>

        <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
            {{ notificacao.mensagem }}

            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import moment from 'moment';

import { sleep } from '@/helpers/Funcoes.js';

import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import Conversa from './Conversa.vue';
import Mensagem from './Mensagem.vue';
import Informacoes from './Informacoes.vue';

export default {
    name: 'Whatsapp',
    components: {
        CarregandoLogoEmpresa,
        Conversa,
        Mensagem,
        Informacoes
    },
    mounted() {
        window.Echo.channel('whatsapp-mensagem').listen('.WhatsappMensagem', e => {
            this.mensagemBroadcast(e.whatsapp_mensagem);
        });

        window.Echo.channel('whatsapp-conversa').listen('.WhatsappConversa', e => {
            this.conversaBroadcast(e.whatsapp_conversa);
        });

        this.buscaContatoLista();
    },
    watch: {
        contatos: {
            deep: true,
            handler(novoValor) {
                this.criaContatosFiltrados(novoValor);
            }
        },
        ultimaWhatsappMensagem: {
            deep: true,
            handler(ultima_mensagem) {
                this.atualizaContatoUltimaMensagem(ultima_mensagem);
            }
        }
    },
    computed: {},
    methods: {
        async enviaMensagem(mensagem) {
            try {
                //this.carregandoMensagens = true;

                const parametros = {
                    id_whatsapp_numero_atendimento: this.idWhatsappNumeroAtendimento,
                    telefone_envio: this.conversa.telefone_envio,
                    mensagem: mensagem.mensagem
                };

                let url = `${API_URL.erp.alocacaocargaromaneio.whatsappEnviaMensagem}`.replace(
                    '${id_whatsapp_numero_atendimento}',
                    this.idWhatsappNumeroAtendimento
                );

                const mensagemResposta = await ApiService({
                    method: 'POST',
                    url: url,
                    mostraCarregando: false,
                    params: parametros
                })
                    .then(resposta => {
                        return resposta.data.data;
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });

                const msg = {
                    id_whatsapp_mensagem: mensagemResposta.id_whatsapp_mensagem,
                    mensagem: mensagemResposta.mensagem,
                    data_envio: mensagemResposta.data_envio,
                    envio_remetente: true
                };

                this.adicionaMensagem(msg);
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                //this.carregandoMensagens = false;
            }
        },
        limpaMensagem() {
            this.mensagemTexto = '';
        },
        async scrollFinalMensagens() {
            await sleep(0);

            let container = document.getElementById('mensagens');

            container.scrollTop = container.scrollHeight;
        },
        infiniteScrolling(entries, observer, isIntersecting) {
            if (isIntersecting && this.atual_id_whatsapp_conversa) {
                this.carregaMensagensAnteriores();
            }
        },
        criaContatosFiltrados(contatos) {
            const cnt = [...contatos];

            // ORDENA OS CONTATOS COM A DATA MAIS RECENTE
            this.contatosFiltrados = cnt.sort(function(a, b) {
                const valorA = a.data_recebe ?? a.data_envio;
                const valorB = b.data_recebe ?? b.data_envio;

                if (valorA > valorB) {
                    return -1;
                } else {
                    return true;
                }
            });
        },
        async buscaContatoLista() {
            try {
                this.carregandoContatoLista = true;

                const contatos = await ApiService({
                    method: 'GET',
                    url: `${API_URL.erp.alocacaocargaromaneio.whatsappRomaneio}/${this.idWhatsappNumeroAtendimento}`,
                    mostraCarregando: false
                })
                    .then(resposta => {
                        return resposta?.data?.data ?? [];
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });

                contatos.forEach(contato => {
                    this.adicionaContato(contato);
                });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.carregandoContatoLista = false;
            }
        },
        adicionaContato(contato) {
            this.contatos.push({
                id_whatsapp_conversa: contato.id_whatsapp_conversa,
                whatsapp_nome_profile: contato.whatsapp_nome_profile,
                telefone_envio: contato.telefone_envio,
                data_envio: contato.mensagem.data_envio,
                data_recebe: contato.mensagem.data_recebe,
                ultima_mensagem_id_whatsapp_mensagem: contato.mensagem.id_whatsapp_mensagem,
                ultima_mensagem: contato.mensagem.mensagem,
                hora_recebe_ultima_mensagem: moment(contato.mensagem.data_recebe).format('HH:mm'),
                total_mensagens: contato.mensagem.total,
                avatar: ''
            });
        },
        async carregaMensagens(contato) {
            try {
                this.limpaMensagens();

                this.conversa = { ...contato };

                this.atual_id_whatsapp_conversa = contato.id_whatsapp_conversa;

                await this.buscaMensagens(contato.id_whatsapp_conversa);

                this.marcaConversaVisualizada(contato);

                this.scrollFinalMensagens();
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        marcaConversaVisualizada(contato) {
            try {
                let url = `${API_URL.erp.alocacaocargaromaneio.whatsappConversaVisualizada}`.replace(
                    '${id_whatsapp_conversa}',
                    contato.id_whatsapp_conversa
                );

                ApiService({
                    method: 'PUT',
                    url: url,
                    mostraCarregando: false
                })
                    .then(resposta => {
                        // LIMPA ULTIMA TOTAL DE MENSAGENS DO CONTATO, POIS FORAM VISUALIZADAS
                        contato.total_mensagens = null;

                        return true;
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        async carregaMensagensAnteriores() {
            try {
                if (this.carregandoMensagens) {
                    return;
                }

                this.buscaMensagens(this.atual_id_whatsapp_conversa, this.ultimaAnteriorIdWhatsappMensagem);
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        adicionaMensagem(mensagem, anterior) {
            const msg = {
                id_whatsapp_mensagem: mensagem.id_whatsapp_mensagem,
                mensagem: mensagem.mensagem,
                data_envio: mensagem.data_envio,
                data_recebe: mensagem?.data_recebe,
                data_leitura: mensagem?.data_leitura,
                envio_remetente: mensagem.envio_remetente,
                mensagem_erro: mensagem.mensagem_erro ?? ''
            };

            this.ultimaWhatsappMensagem = msg;

            if (anterior) {
                this.mensagens.unshift(msg);

                return;
            }

            this.mensagens.push(msg);
        },
        atualizaMensagemErro(id_whatsapp_mensagem, mensagem_erro) {
            let msg = this.mensagens.find(mensagem => mensagem.id_whatsapp_mensagem === id_whatsapp_mensagem);

            if (!msg) {
                this.notificacao = {
                    mensagem: `Mensagem não encontrada para adicionar o erro - ${mensagem_erro}`,
                    tipo: 'error',
                    mostra: true
                };

                return;
            }

            msg.mensagem_erro = mensagem_erro;
        },
        mensagemBroadcast(mensagem_broadcast) {
            if (!mensagem_broadcast?.atributos) {
                return;
            }

            let id_whatsapp_mensagem = mensagem_broadcast.atributos.id_whatsapp_mensagem;
            let id_whatsapp_conversa = mensagem_broadcast.atributos.id_whatsapp_conversa;

            if (this.conversa.id_whatsapp_conversa != id_whatsapp_conversa) {
                const msg = {
                    id_whatsapp_mensagem: id_whatsapp_mensagem,
                    id_whatsapp_conversa: id_whatsapp_conversa,
                    mensagem: mensagem_broadcast.atributos.mensagem
                };

                this.atualizaContatoUltimaMensagem(msg);

                return;
            }

            let mensagemAtual = this.mensagens.find(mensagem => mensagem.id_whatsapp_mensagem === id_whatsapp_mensagem);

            const envio_remetente = mensagem_broadcast.atributos.envio_remetente == 'S' ? true : false;

            const mensagemAtualizada = {
                id_whatsapp_mensagem: mensagem_broadcast.atributos.id_whatsapp_mensagem,
                mensagem: mensagem_broadcast.atributos.mensagem,
                data_envio: mensagem_broadcast.atributos.data_envio,
                data_recebe: mensagem_broadcast.atributos?.data_recebe,
                data_leitura: mensagem_broadcast.atributos?.data_leitura,
                envio_remetente: envio_remetente
            };

            if (!mensagemAtual) {
                this.adicionaMensagem(mensagemAtualizada);
            }

            if (mensagemAtual) {
                mensagemAtual.data_leitura = mensagemAtualizada.data_leitura;
            }
        },
        conversaBroadcast(contato_broadcast) {
            if (!contato_broadcast?.atributos) {
                return;
            }

            let id_whatsapp_conversa = contato_broadcast.atributos.id_whatsapp_conversa;

            let conversaAtual = this.contatos.find(contato => contato.id_whatsapp_conversa === id_whatsapp_conversa);

            const conversaNova = {
                id_whatsapp_conversa: contato_broadcast.atributos.id_whatsapp_conversa,
                whatsapp_nome_profile: contato_broadcast.atributos.whatsapp_nome_profile,
                telefone_envio: contato_broadcast.atributos.telefone_envio,
                ultima_mensagem_id_whatsapp_mensagem: contato_broadcast.atributos.mensagem.id_whatsapp_mensagem,
                ultima_mensagem: contato_broadcast.atributos.mensagem.mensagem,
                hora_recebe_ultima_mensagem: moment(contato_broadcast.atributos.mensagem.data_recebe).format('HH:mm'),
                total_mensagens: contato_broadcast.atributos.mensagem.total,
                avatar: ''
            };

            if (!conversaAtual) {
                this.adicionaContato(contato_broadcast.atributos);
            }

            if (conversaAtual) {
                conversaAtual.total_mensagens = conversaNova.total_mensagens;
            }
        },
        async buscaMensagens(id_whatsapp_conversa, ultima_anterior_id_whatsapp_mensagem) {
            try {
                this.carregandoMensagens = true;

                const parametros = {};

                if (!ultima_anterior_id_whatsapp_mensagem) {
                    this.limpaMensagens();
                }

                if (ultima_anterior_id_whatsapp_mensagem) {
                    parametros.ultima_id_whatsapp_mensagem = ultima_anterior_id_whatsapp_mensagem;
                }

                let url = `${API_URL.erp.alocacaocargaromaneio.whatsappConversaMensagens}`.replace(
                    '${id_whatsapp_numero_atendimento}',
                    this.idWhatsappNumeroAtendimento
                );

                url = url.replace('${id_whatsapp_conversa}', id_whatsapp_conversa);

                const mensagens = await ApiService({
                    method: 'GET',
                    url: url,
                    mostraCarregando: false,
                    params: parametros
                })
                    .then(resposta => {
                        return resposta?.data?.data ?? [];
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });

                mensagens.forEach(mensagem => {
                    const envio_remetente = mensagem.envio_remetente == 'S' ? true : false;

                    this.adicionaMensagem(
                        {
                            id_whatsapp_mensagem: mensagem.id_whatsapp_mensagem,
                            mensagem: mensagem.mensagem,
                            falha: mensagem.falha,
                            data_envio: mensagem.data_envio,
                            data_recebe: mensagem.data_recebe,
                            data_leitura: mensagem.data_leitura,
                            envio_remetente: envio_remetente,
                            mensagem_erro: mensagem.falha?.erro ?? ''
                        },
                        true
                    );

                    this.ultimaAnteriorIdWhatsappMensagem = mensagem.id_whatsapp_mensagem;
                });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.carregandoMensagens = false;
            }
        },
        atualizaContatoUltimaMensagem(ultima_mensagem) {
            const contato = this.contatos.find(
                contato => contato.id_whatsapp_conversa === ultima_mensagem.id_whatsapp_conversa
            );

            if (contato) {
                const atual_id_whatsapp_mensagem = ultima_mensagem.id_whatsapp_mensagem;

                if (contato.ultima_mensagem_id_whatsapp_mensagem > atual_id_whatsapp_mensagem) {
                    return;
                }

                contato.ultima_mensagem = ultima_mensagem?.mensagem;
            }
        },
        limpaMensagens() {
            this.mensagens = [];
        },
        buscaConversa(texto) {
            if (!texto || texto === '') {
                this.contatosFiltrados = this.contatos;

                return;
            }

            this.contatosFiltrados = this.contatos.filter(v => {
                return this.formatString(v.whatsapp_nome_profile).includes(this.formatString(texto));
            });
        },
        formatString(string) {
            return string
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
        }
    },
    data() {
        return {
            idWhatsappNumeroAtendimento: 1,
            carregandoContatoLista: false,
            carregandoMensagemContatoLista: 'Carregando contatos...',
            carregandoMensagens: true,
            carregandoMensagem: 'Aguarde, carregando mensagens anteriores...',
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            contatos: [],
            contatosFiltrados: [],
            conversa: {},
            mensagens: [],
            atual_id_whatsapp_conversa: null,
            ultimaWhatsappMensagem: null,
            ultimaAnteriorIdWhatsappMensagem: null,
            mensagemTexto: '',
            contatoSelecionado: [2]
        };
    }
};
</script>

<style scoped>
.bordaDireita {
    border-right: solid #d7dce0 0.1em;
}
</style>
