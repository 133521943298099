<template>
    <v-list-item @click="carregaMensagens()" class="pl-0 pr-2">
        <v-badge v-if="imagem_texto.length > 0" :color="imagemTextoCor" offset-x="80%" offset-y="24" bottom>
            <v-list-item-avatar size="45">
                <v-img src="@/assets/image/perfil/img_avatar.png"></v-img>
            </v-list-item-avatar>

            <template v-slot:badge>
                <div class="d-flex align-center justify-center font-weight-bold">
                    {{ imagem_texto }}
                </div>
            </template>
        </v-badge>

        <v-list-item-avatar v-else class="ma-2" size="45">
            <v-img src="@/assets/image/perfil/img_avatar.png"></v-img>
        </v-list-item-avatar>

        <template>
            <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                    <span v-if="id_whatsapp_chamado">
                        <v-chip x-small dark class="subtitle-2" color="deep-purple darken-3">
                            {{ id_whatsapp_chamado }}
                        </v-chip>
                        -
                    </span>

                    {{ whatsapp_nome_profile }}
                </v-list-item-title>

                <v-list-item-subtitle>
                    {{ ultima_mensagem }}
                </v-list-item-subtitle>
            </v-list-item-content>

            <v-tooltip bottom v-if="iniciar_atendimento">
                <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                        <v-icon
                            v-if="!carregando"
                            class="pl-5"
                            color="primary"
                            @click="$emit('inicia_atendimento', id_whatsapp_fluxo_conversa)"
                            dark
                        >
                            mdi-send
                        </v-icon>
                    </div>
                </template>
                <span>Iniciar atendimento</span>
            </v-tooltip>

            <v-progress-circular v-if="carregando" :size="20" :width="2" color="warning" indeterminate></v-progress-circular>

            <v-list-item-action>
                <v-list-item-subtitle class="text-caption text-end">
                    {{ hora_recebe_ultima_mensagem }}
                </v-list-item-subtitle>

                <v-chip v-if="total_mensagens" right small class="subtitle-2" color="light-green lighten-3">
                    {{ total_mensagens }}
                </v-chip>
            </v-list-item-action>
        </template>
    </v-list-item>
</template>

<script>
import TipoAtendimento from '../Enum/TipoAtendimento';

export default {
    name: 'Contato',
    props: {
        id_whatsapp_fluxo_conversa: {
            type: Number,
            default: () => null
        },
        id_whatsapp_conversa: {
            type: Number,
            default: () => null
        },
        id_whatsapp_chamado: {
            type: Number,
            default: () => 0
        },
        whatsapp_nome_profile: {
            type: String,
            required: true
        },
        ultima_mensagem: {
            type: String,
            required: true
        },
        hora_recebe_ultima_mensagem: {
            type: String,
            required: true
        },
        total_mensagens: {
            type: Number,
            required: true
        },
        imagem_texto: {
            type: String,
            default: () => ''
        },
        imagem_texto_cor: {
            type: String,
            default: () => 'red lighten-1'
        },
        iniciar_atendimento: {
            type: Boolean,
            default: () => false
        },
        carregando: {
            type: Boolean,
            default: () => false
        },
        tipo_atendimento: {
            type: Number,
            default: () => TipoAtendimento.ATENDIMENTO
        }
    },
    computed: {
        imagemTextoCor() {
            return !this.imagem_texto_cor ? 'red lighten-1' : this.imagem_texto_cor;
        }
    },
    methods: {
        async carregaMensagens() {
            this.$emit('carregaMensagens', {
                id_whatsapp_fluxo_conversa: this.id_whatsapp_fluxo_conversa,
                id_whatsapp_conversa: this.id_whatsapp_conversa
            });
        }
    },
    data() {
        return {};
    }
};
</script>
