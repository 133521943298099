<template>
    <v-form ref="form">
        <v-row>
            <v-col cols="12" sm="2">
                <InputTextV3 v-model="form.id_whatsapp_atendente" rotulo="ID" :contador="100" desativado clearable />
            </v-col>

            <v-col cols="12" sm="8">
                <InputAutoCompleteV3
                    v-model="form.id_usuario"
                    :datatableConfiguracao="autoCompleta.usuario.datatable"
                    :itemsOpcoes="itensUsuario"
                    tituloBusca="Usuário Busca..."
                    itemValor="id_usuario"
                    itemTexto="nome_completo"
                    :regra="regrasUsuario"
                />
            </v-col>

            <v-col cols="12" sm="2">
                <InputSelectV3
                    v-model="form.ativo"
                    rotulo="Ativo"
                    :itens="opcaoSimNao"
                    itemTexto="descricao"
                    itemValor="valor"
                />
            </v-col>

            <v-col cols="12">
                <v-badge
                    :content="totalRegistros"
                    :value="totalRegistros"
                    icon="mdi-numeric-positive-1"
                    color="deep-purple accent-4"
                    overlap
                >
                    <v-btn tile color="success" @click="adicionaDepartamentos()">
                        <v-icon left> mdi-plus </v-icon>
                        Departamento
                    </v-btn>
                </v-badge>
            </v-col>

            <v-col cols="12">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left" style="width: 100px;">
                                    AÇÃO
                                </th>
                                <th class="text-left" style="width: 150px;">
                                    ID
                                </th>
                                <th class="text-left" style="width: 100%;">
                                    Departamento
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in form.departamentos" :key="`departamento-${index}`">
                                <td>
                                    <v-icon color="red" small @click="removeDepartamento(index)">mdi-trash-can-outline</v-icon>
                                </td>
                                <td>{{ item.id_whatsapp_atendente_departamento }}</td>
                                <td>{{ item.nome }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>

            <v-col cols="12">
                <v-badge
                    v-if="totalRegistros > 0"
                    :content="totalRegistros"
                    :value="totalRegistros"
                    icon="mdi-numeric-positive-1"
                    color="deep-purple accent-4"
                    overlap
                >
                    <v-btn tile color="success" @click="adicionaDepartamentos()">
                        <v-icon left> mdi-plus </v-icon>
                        Departamento
                    </v-btn>
                </v-badge>
            </v-col>
        </v-row>

        <v-dialog v-model="modalDepartamento" persistent>
            <v-card>
                <v-card-title class="text-h5">
                    Whatsapp Departamento
                </v-card-title>

                <v-card-text>
                    <form-atendente-departamento ref="formAtendenteDepartamento" />
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="modalDepartamento = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="green darken-1" text @click="adicionaDepartamento()">
                        Adicionar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</template>

<script>
import { API_URL, API_URL_BASE } from '@/config/configs';

import { Obrigatorio } from '@/helpers/ValidadorCampo.js';

import InputTextV3 from '@/components/Form/InputTextV3.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';
import InputAutoCompleteV3 from '@/components/Form/InputAutoCompleteV3.vue';

import FormAtendenteDepartamento from './FormAtendenteDepartamento.vue';

export default {
    name: 'FormDados',
    components: {
        InputTextV3,
        InputSelectV3,
        InputAutoCompleteV3,
        FormAtendenteDepartamento
    },
    props: {
        id_whatsapp_atendente: {
            type: Number,
            default: () => null
        },
        id_usuario: {
            type: Number,
            default: () => null
        },
        ativo: {
            type: String,
            default: () => 'N'
        },
        usuario: {
            type: Array,
            default() {
                return [
                    {
                        id_usuario: null,
                        nome_completo: null
                    }
                ];
            }
        },
        departamentos: {
            type: Array,
            default() {
                return {
                    departamentos: [
                        {
                            id_whatsapp_atendente_departamento: null,
                            id_whatsapp_departamento: null,
                            nome: null
                        }
                    ]
                };
            }
        }
    },
    watch: {
        id_whatsapp_atendente: {
            deep: true,
            handler(novoValor) {
                this.form.id_whatsapp_atendente = novoValor;
            }
        },
        id_usuario: {
            deep: true,
            handler(novoValor) {
                this.form.id_usuario = novoValor;
            }
        },
        ativo: {
            deep: true,
            handler(novoValor) {
                this.form.ativo = novoValor;
            }
        },
        usuario: {
            deep: true,
            handler(novoValor) {
                this.itensUsuario = novoValor;
            }
        },
        departamentos: {
            deep: true,
            handler(novoValor) {
                this.form.departamentos = novoValor;
            }
        }
    },
    computed: {
        totalRegistros() {
            return this.form.departamentos.length;
        }
    },
    methods: {
        adicionaDepartamentos() {
            this.modalDepartamento = true;
        },
        removeDepartamento(posicao) {
            const listaDepartamentos = this.form.departamentos.filter((item, index) => index !== posicao);

            this.form.departamentos = [];

            listaDepartamentos.forEach((item, index) => {
                this.form.departamentos.push(item);
            });
        },
        adicionaDepartamento() {
            const id_whatsapp_departamento = this.$refs.formAtendenteDepartamento.id_whatsapp_departamento;

            if (!id_whatsapp_departamento) {
                return false;
            }

            const departamento = {
                id_whatsapp_atendente_departamento: null,
                id_whatsapp_departamento: this.$refs.formAtendenteDepartamento.id_whatsapp_departamento,
                nome: this.$refs.formAtendenteDepartamento.nome_departamento
            };

            this.form.departamentos.push(departamento);

            this.modalDepartamento = false;
        }
    },
    data() {
        return {
            form: {
                id_whatsapp_atendente: null,
                id_usuario: null,
                ativo: 'N',
                departamentos: []
            },
            opcaoSimNao: [
                { valor: 'S', descricao: 'Sim' },
                { valor: 'N', descricao: 'Não' }
            ],
            modalDepartamento: false,
            itensUsuario: [],
            regrasNomeCompleto: [Obrigatorio()],
            regrasUsuario: [Obrigatorio()],
            autoCompleta: {
                usuario: {
                    datatable: {
                        id_datatable: 'usuario_id_usuario',
                        chave_primaria: 'usuario_id_usuario',
                        url: `${API_URL_BASE}${API_URL.erp.whatsappatendente.datatable}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.whatsappatendente.buscaUsuario}`,
                        colunas: [
                            {
                                id: 'id_usuario',
                                nome: 'id_usuario',
                                descricao: 'ID Usuário'
                            },
                            {
                                id: 'nome_completo',
                                nome: 'nome_completo',
                                descricao: 'Nome'
                            }
                        ]
                    }
                }
            }
        };
    }
};
</script>
