import { API_URL_BASE, API_URL } from '@/config/configs';

const AtestadoCID = {
    datatable: {
        id_datatable: 'atestadocid',
        chave_primaria: 'id_atestado_cid',
        url: `${API_URL_BASE}${API_URL.erp.atestadocid.datatable}`,
        colunas: false,
    },
    formRefsComponente: 'AtestadoCIDForm',
    formNome: 'formAtestadoCID',
    moduloNome: 'atestadocid',
    chavePrimaria: 'id_atestado_cid',
    componenteArquivo: 'AtestadoCID/Form.vue',
    tabRefs: 'tabAtestadoCID',
    urlNovoRegistro: '',
    urlConsultaRegistro: 'listaPorIdForm',
    tabs: []
}

export { AtestadoCID };

