<template>
  <div>
    <v-banner
      icon="mdi-account-star"
      single-line
      elevation="1"
      color="white"
      class="mb-3"
    >

      <slot name="text"></slot>
    
      <template v-slot:actions>
        <slot name="buttons"></slot>
      </template>
      
    </v-banner>
  </div>
</template>

<script>
export default {
  name: 'TableBar'
}
</script>