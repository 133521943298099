<template>
    <v-card
        class="mx-auto"
        min-width="400"
        max-width="400"
        max-height="180px"
        elevation="6"
        :color="corDoCardDeAcordoComTempoDaJornada"
    >
    
        <div class="d-flex pa-1 mx-auto">
            <div class="d-flex flex-column justify-space-between align-center mr-auto">
                <v-img
                    :aspect-ratio="16/9"
                    width="100"
                    :src="imagemAvatar(imagem_avatar)"
                ></v-img>
            </div>
        
            <div class="d-flex flex-column text-center mx-auto align-center" style="width: 210px;">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                        x-small 
                        v-bind="attrs"
                        v-on="on"
                        elevation="0"
                        :color="corDoCardDeAcordoComTempoDaJornada"
                        class="d-flex ms-auto"
                        >
                            <v-icon small :color="statusTempoGeralDaJornadaTooltip.color">
                                mdi-help
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>{{statusTempoGeralDaJornadaTooltip.message}}</span>
                </v-tooltip>
                <div class="font-weight-bold text-subtitle-1">{{ nomeSobrenome }}</div>
                <v-card-text class="pt-0 pb-2">
                    <div v-if="telefone_celular">
                        <v-btn
                            class="font-weight-bold body-2 pa-3"
                            color="red accent-4"
                            dark
                            elevation="0"
                            x-small
                        >
                            <v-icon left small>
                                mdi-cellphone-wireless
                            </v-icon>
                            {{ telefone_celular | VMask('+55 (##) # ####-####') }}
                        </v-btn>
                    </div>
                    <div v-else>
                        <v-btn
                            class="font-weight-bold body-2 pa-3"
                            color="grey"
                            dark
                            elevation="0"
                            x-small
                        >
                            <v-icon left small>
                                mdi-cellphone-nfc-off
                            </v-icon>
                            SEM CADASTRO
                        </v-btn>
                    </div>
                </v-card-text>
            </div>
        </div>

        <div v-if="!mostraTempoJornada">
        
            <v-divider></v-divider>
        
            <v-card-text class="d-flex justify-space-around pb-0 pt-1">
                <div class=" font-weight-bold body-">
                    JORNADA
                </div>
                <div class="font-weight-bold body-2">
                    GERAL
                </div>
            </v-card-text>
        
            <div class="d-flex justify-space-around pb-1">
                <div>
                    <v-btn
                        color="green"
                        dark
                        min-width="180px"

                        elevation="0"
                        class="font-weight-bold body-2"
                        x-small
                    >
                    <v-icon left small>
                        hourglass_bottom
                    </v-icon>
                        {{ status_tempo_total }}
                    </v-btn>
                </div>
                <div>
                    <v-btn
                        color="warning"
                        dark
                        min-width="180px"
                        elevation="0"
                        class="font-weight-bold body-2"
                        x-small
                    >
                    <v-icon left small>
                        timer
                    </v-icon>
                    {{ status_tempo_geral }}
                    </v-btn>
                </div>
            </div>
        </div>
  </v-card>
</template>

<script>
export default {
    name: 'MotoristaCardV2',
    props: {
        mostraTempoJornada: {
            type: Boolean,
            default: () => true
        },
        imagem_avatar: {
            type: String,
            default: () => null
        },
        motorista: {
            type: String,
            default: () => null
        },
        telefone_celular: {
            type: String,
            default: () => null
        },
        status_tempo_total: {
            type: String,
            default: () => null
        },
        status_tempo_geral: {
            type: String,
            default: () => null
        },
        id_programacao_carga_rodopar: {
            type: Number,
            default: () => null
        },
        id_alocacao_carga_romaneio: {
            type: Number,
            default: () => null
        },
        numero_cte: {
            type: Number,
            default: () => null
        },
        nome_tomador_servico: {
            type: String,
            default: () => null
        },
        terminal_coleta: {
            type: String,
            default: () => null
        },
        terminal_entrega: {
            type: String,
            default: () => null
        },
        placa_veiculo_cavalo: {
            type: String,
            default: () => null
        },
        veiculo_frota: {
            type: String,
            default: () => null
        },
        veiculo_modelo: {
            type: String,
            default: () => null
        },
        corDoCardDeAcordoComTempoDaJornada: {
            type: String,
            default: () => null
        },
    },
    mounted() {
        this.alteraStatusTempoGeralDaJornadaTooltip()  
    },
    computed: {
        nomeSobrenome() {
            const palavras = this.motorista.split(' ');

            const nome = palavras[0];
            const sobrenome = palavras[palavras.length - 1];

            return `${nome} ${sobrenome}`;
        },
    },
    methods: {
        imagemAvatar(imagemAvatar) {
            return imagemAvatar ?? require('@/assets/image/perfil/img_avatar.png');
        },

        alteraStatusTempoGeralDaJornadaTooltip() {

            if(this.corDoCardDeAcordoComTempoDaJornada == 'orange lighten-4') {
                this.statusTempoGeralDaJornadaTooltip.color = 'orange'
                this.statusTempoGeralDaJornadaTooltip.message = 'O motorista está quase excedendo o tempo limite de 11h em sua jornada (8h padrão, 3h extras)'
                return
            }

           if(this.corDoCardDeAcordoComTempoDaJornada == 'red lighten-4') {
                this.statusTempoGeralDaJornadaTooltip.color = 'red'
                this.statusTempoGeralDaJornadaTooltip.message = 'O motorista excedeu o tempo limite de 11h em sua jornada (8h padrão, 3h extras)'
                return
           }

            this.statusTempoGeralDaJornadaTooltip.color = 'black'
            this.statusTempoGeralDaJornadaTooltip.message = 'O motorista está dentro do tempo limite de 11h em sua jornada (8h padrão, 3h extras)'
        }
    },
    data() {
        return {
            show: false,
            statusTempoGeralDaJornadaTooltip: {
                color: '',
                message: ''
            },
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            cabecalho: [
                {
                    text: 'Ordem',
                    sortable: false,
                    value: 'ordem',
                    width: '80px'
                },
                {
                    text: 'Prog. Carga',
                    sortable: false,
                    value: 'id_programacao_carga_rodopar',
                    width: '100px'
                },
                {
                    text: 'CT-e',
                    sortable: false,
                    value: 'numero_cte',
                    width: '110px'
                },
                {
                    text: 'Cliente',
                    sortable: false,
                    value: 'cliente',
                    width: '150px'
                },
                {
                    text: 'Coleta',
                    sortable: false,
                    value: 'terminal_coleta',
                    width: '150px'
                },
                {
                    text: 'Entrega',
                    sortable: false,
                    value: 'terminal_entrega',
                    width: '150px'
                },
                {
                    text: 'Observação Entrega',
                    sortable: false,
                    value: 'observacao_gerais',
                    width: '150px'
                }
            ]
        };
    }
};
</script>
