<template>
    <div>
        <h2 class="font-weight-black pb-3">Relatórios > RH > Funcionários</h2>
        <v-card outlined elevation="2" class="mb-4">
            <v-card-title>Filtros:</v-card-title>
            <v-card-text dense>
                <v-row>
                    <v-col cols="12" md="6">
                        <InputTextV3 v-model="form.nome" rotulo="Nome" :contador="80" />
                    </v-col>

                    <v-col cols="12" md="6">
                        <InputTextV3 v-model="form.razao_social" rotulo="Razão Social" :contador="80" />
                    </v-col>

                    <v-col cols="12" md="6">
                        <InputTextV3 v-model="form.cpf" rotulo="CPF" campoFormatacao="###.###.###-##" :contador="14" />
                    </v-col>

                    <v-col cols="12" md="6">
                        <InputTextV3 v-model="form.cnpj" rotulo="CNPJ" campoFormatacao="##.###.###/####-##" :contador="18" />
                    </v-col>

                    <v-col cols="12" align="right">
                        <v-btn elevation="2" color="warning" @click="downloadArquivo('xls')">Gera XLS</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <div class="text-center">
            <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                {{ notificacao.mensagem }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import ApiService from '@/services/ApiService.js';
import { DownloadArquivo } from '@/helpers/Funcoes.js';

import InputSelectV3 from '@/components/Form/InputSelectV3.vue';
import InputTextV3 from '@/components/Form/InputTextV3.vue';

export default {
    name: 'Functionario',
    components: {
        InputSelectV3,
        InputTextV3
    },
    async mounted() {},
    computed: {},
    watch: {},
    methods: {
        async downloadArquivo(tipoArquivo) {
            try {
                const parametros = this.montaFiltros();

                parametros.tipo_arquivo = tipoArquivo;

                await ApiService({
                    method: 'GET',
                    url: `V1/relatorio/rh/funcionario`,
                    responseType: 'blob',
                    params: parametros
                })
                    .then(resposta => {
                        console.log('sim');
                        DownloadArquivo(resposta.data, resposta.headers['x-suggested-filename']);
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        montaFiltros() {
            const parametros = {};

            if (this.form.nome) {
                parametros.nome = this.form.nome;
            }

            if (this.form.razao_social) {
                parametros.razao_social = this.form.razao_social;
            }

            if (this.form.cpf) {
                parametros.cpf = this.form.cpf?.replace(/\D/g, '');
            }

            if (this.form.cnpj) {
                parametros.cnpj = this.form.cnpj?.replace(/\D/g, '');
            }

            return parametros;
        }
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            form: {
                nome: '',
                razao_social: '',
                cpf: null,
                cnpj: null
            }
        };
    }
};
</script>
