import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './vuex/store';
import vuetify from './plugins/vuetify';
import '@babel/polyfill';
import VueQuillEditor from 'vue-quill-editor';
import VueCookies from 'vue-cookies';
import echarts from 'echarts';

Vue.config.productionTip = false;

import './plugins/vuetify-money.js';
import DatetimePicker from 'vuetify-datetime-picker';

import '@/assets/css/splitpane/splitpanes-2-3-6.css';
import '@/assets/css/splitpane/default.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import money from 'v-money';
// import VueTheMask from 'vue-the-mask'
import Maska from 'maska';
import { VueMaskFilter } from 'v-mask';

Vue.use(DatetimePicker);
Vue.use(VueQuillEditor);
Vue.use(money);
// Vue.use(VueTheMask)
Vue.use(Maska);
Vue.filter('VMask', VueMaskFilter);
Vue.use(VueCookies);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');

/*
store.dispatch('checkLogin')
        .then(() => router.push({name: 'admin'}))
        .catch((error) => router.push({name: 'login'}));
*/
