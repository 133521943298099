<template>
    <div class="atendimentoConversa">
        <div style="height: 80px">
            <v-app-bar color="white" elevation="1" height="80px">
                <v-list-item>
                    <v-list-item-avatar size="50">
                        <v-img src="@/assets/image/perfil/img_avatar.png"></v-img>
                    </v-list-item-avatar>

                    <template>
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-bold text-md-body-1">
                                {{ whatsapp_nome_profile }} -

                                <v-chip right small class="subtitle-2" color="light-green lighten-3">
                                    <v-icon small left> mdi-account-circle </v-icon>

                                    {{ nome_completo }}
                                </v-chip>
                            </v-list-item-title>

                            <v-list-item-title class="text-caption">
                                {{ telefone_envio | VMask('+## (##) # ####-####') }}
                            </v-list-item-title>

                            <v-list-item-subtitle>
                                <v-chip
                                    v-if="id_whatsapp_chamado"
                                    class="mt-2"
                                    color="deep-purple darken-3"
                                    text-color="white"
                                    small
                                >
                                    <v-icon small left> mdi-label </v-icon>
                                    <span class="subtitle-2"> Chamado {{ id_whatsapp_chamado }} </span>
                                </v-chip>

                                &nbsp;

                                <v-chip v-if="setor_texto" class="mt-2" :color="setor_texto_cor" text-color="white" small>
                                    <v-icon small left> mdi-label </v-icon>
                                    <span class="subtitle-2">{{ setor_texto }}</span>
                                </v-chip>
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action v-if="tipoAtendimentoEnum.ATENDIMENTO != tipo_atendimento">
                            <v-list-item-subtitle class="text-caption text-end">
                                <v-btn
                                    class="ma-2"
                                    outlined
                                    color="blue-grey darken-1"
                                    @click="transferir()"
                                    :disabled="desabilita_transferir_atendimento"
                                    :dark="!desabilita_transferir_atendimento"
                                >
                                    <v-icon>mdi-swap-horizontal</v-icon>

                                    TRANSFERIR
                                </v-btn>

                                <v-btn
                                    class="ma-2"
                                    color="indigo darken-2"
                                    @click="finalizar()"
                                    :disabled="desabilita_finaliza_atendimento"
                                    :dark="!desabilita_finaliza_atendimento"
                                >
                                    FINALIZAR
                                </v-btn>

                                <v-btn color="black" icon class="mt-n2">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </v-list-item-subtitle>
                        </v-list-item-action>
                    </template>
                </v-list-item>
            </v-app-bar>
        </div>

        <div id="mensagens" class="overflow-auto pa-0 m-0" style="background-color: #efefef">
            <v-card v-intersect="infiniteScrolling"></v-card>

            <br />
            <br />

            <CarregandoLogoEmpresa :carregando="carregando" :mensagem="carregandoMensagem" />

            <template v-for="(mensagem, index) in mensagens">
                <mensagem
                    :key="index"
                    :texto="mensagem.mensagem"
                    :mensagemErro="mensagem.mensagem_erro"
                    :dataEnvio="mensagem.data_envio"
                    :dataRecebe="mensagem.data_recebe"
                    :dataLeitura="mensagem.data_leitura"
                    :envioRemetente="mensagem.envio_remetente"
                    :media="mensagem.media"
                    @abre-imagem="$emit('abre-imagem', $event)"
                />
            </template>

            <br />
            <br />
        </div>

        <v-row style="margin-top: 8px">
            <v-col v-show="!!arquivos.length" cols="12" md="11" class="pl-5">
                <anexos :arquivos="arquivos" @anexo-remove="anexoRemove" />
            </v-col>

            <v-col v-show="!!arquivos.length" cols="1" md="1" class="pa-0">
                <v-icon color="red" @click="anexoRemove()"> mdi-close </v-icon>
            </v-col>

            <v-col cols="12" md="1" class="mt-1 pl-5">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-btn icon>
                            <v-icon>mdi-microphone</v-icon>
                        </v-btn>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-btn icon @click="abreInputFile2()">
                            <v-icon>mdi-paperclip-plus</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <InputFileV4
                    v-show="false"
                    v-model="arquivosSelecionados"
                    :regra="regrasArquivo"
                    :multiplo="true"
                    icone="upload"
                    rotulo="Selecione o arquivo (PDF,JPG,PNG)"
                    :tipoArquivoPermitido="['application/pdf', 'image/jpeg', 'image/jpg', 'image/png']"
                    :abreInputFile="abreInputFile"
                    v-on:change="anexosAdiciona($event)"
                    ref="arquivosSelecionados2"
                />
            </v-col>

            <v-col cols="12" md="10" class="pl-5">
                <v-textarea
                    v-model="mensagemTexto"
                    type="text"
                    label="Digite sua mensagem aqui"
                    append-icon="mdi-emoticon"
                    clear-icon="mdi-close-circle"
                    ref="mensagemTexto"
                    id="mensagemTexto"
                    :rows="1"
                    :disabled="desabilita_enviar_mensagem"
                    @click:clear="limpaMensagem()"
                    @keyup.ctrl.enter="quebraLinha"
                    @keyup.enter="enviaMensagem"
                    filled
                    clearable
                    solo
                    no-resize
                    dense
                    auto-grow
                ></v-textarea>
            </v-col>

            <v-col cols="12" md="1">
                <v-btn :disabled="!mensagemTexto && !arquivos.length > 0" @click="enviaMensagem()" icon>
                    <v-icon>mdi-send</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="modalTransferir" persistent max-width="800px">
            <v-card>
                <v-toolbar color="orange" dark>
                    <v-toolbar-title>Selecione o atendente que será transferido</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn icon @click="cancelaTransferir()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="pt-5">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <InputAutoCompleteV3
                                    v-model="id_whatsapp_atendente"
                                    :datatableConfiguracao="autoCompleta.usuario_transferencia.datatable"
                                    tituloBusca="Usuário atendente transferência Busca..."
                                    :itemsOpcoes="itensUsuarioTransferencia"
                                    itemValor="id_whatsapp_atendente"
                                    itemTexto="nome_completo"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="cancelaTransferir()"> Cancelar </v-btn>
                    <v-btn color="blue darken-1" text @click="confirmaTransferir()"> Transferir ? </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="notificacao.mostra"
            :multi-line="true"
            :color="notificacao.color ? notificacao.color : 'green lighten-3'"
            timeout="2000"
            tile
            right
        >
            {{ notificacao.mensagem }}
        </v-snackbar>
    </div>
</template>

<script>
import { API_URL, API_URL_BASE } from '@/config/configs';

import TipoAtendimento from '../Enum/TipoAtendimento';

import { sleep } from '@/helpers/Funcoes.js';

import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import InputAutoCompleteV3 from '@/components/Form/InputAutoCompleteV3.vue';
import InputFileV4 from '@/components/Form/InputFileV4.vue';
import Mensagem from './Mensagem.vue';
import Anexos from './Anexos.vue';
import Anexo from './Anexo.vue';

export default {
    name: 'AtendimentoConversa',
    components: {
        CarregandoLogoEmpresa,
        InputAutoCompleteV3,
        InputFileV4,
        Mensagem,
        Anexos,
        Anexo,
    },
    props: {
        id_whatsapp_fluxo_conversa: {
            type: Number,
            default: () => null,
        },
        id_whatsapp_conversa: {
            type: Number,
            default: () => null,
        },
        id_whatsapp_chamado: {
            type: Number,
            default: () => null,
        },
        whatsapp_nome_profile: {
            type: String,
            required: true,
        },
        nome_completo: {
            type: String,
            default: () => '',
        },
        telefone_envio: {
            type: Number,
            required: true,
        },
        avatar: {
            type: String,
            default: () => null,
        },
        mensagens: {
            type: Array,
            required: true,
            default: () => [
                {
                    mensagem: '',
                    mensagem_erro: null,
                    data_envio: '',
                    data_recebe: '',
                    data_leitura: '',
                    envio_remetente: '',
                    media: null,
                },
            ],
        },
        carregando: {
            type: Boolean,
            default: () => false,
        },
        setor_texto: {
            type: String,
            default: () => '',
        },
        setor_texto_cor: {
            type: String,
            default: () => 'red lighten-1',
        },
        tipo_atendimento: {
            type: Number,
            default: () => TipoAtendimento.ATENDIMENTO,
        },
        desabilita_finaliza_atendimento: {
            type: Boolean,
            default: () => false,
        },
        desabilita_transferir_atendimento: {
            type: Boolean,
            default: () => false,
        },
        desabilita_enviar_mensagem: {
            type: Boolean,
            default: () => false,
        },
    },
    mounted() {},
    watch: {
        mensagens: {
            deep: true,
            handler(novoValor) {
                this.mensagens = novoValor;

                this.scrollFinalMensagens();
            },
        },
    },
    computed: {},
    methods: {
        abreInputFile2() {
            this.abreInputFile = true;
            setTimeout(() => (this.abreInputFile = false), 1000);
        },
        infiniteScrolling(entries, observer, isIntersecting) {
            if (isIntersecting && !this.carregando) {
                this.$emit('carrega-mensagens-anteriores');
            }
        },
        async scrollFinalMensagens() {
            await sleep(0);

            /*
            console.log('scrollFinalPagina', !this.scrollFinalPagina);

            if (!this.scrollFinalPagina) {
                return;
            }
            */

            let container = document.getElementById('mensagens');

            container.scrollTo({ left: 0, top: container.scrollHeight, behavior: 'smooth' });

            this.scrollFinalPagina = false;
        },
        async quebraLinha() {
            this.mensagemTexto += '\r\n';

            await sleep(0);

            let container = document.getElementById('mensagemTexto');

            container.scrollTo({ left: 0, top: container.scrollHeight, behavior: 'smooth' });
        },
        enviaMensagem(event) {
            // charCodeAt == 10 é a quebra de linha
            if ((this.mensagemTexto == '' || this.mensagemTexto.charCodeAt(0) == 10) && !this.arquivos.length) {
                this.mensagemTexto = '';
                return;
            }

            // se segurou o control, é para pular linha, não envia a mensagem
            if (event != undefined && (event.ctrlKey || event.shiftKey)) {
                return;
            }

            this.notificacao = {
                mensagem: 'Mensagem registrada, aguardando confirmação...',
                mostra: true,
            };

            this.$emit('envia_mensagem', {
                id_whatsapp_fluxo_conversa: this.id_whatsapp_fluxo_conversa,
                id_whatsapp_conversa: this.id_whatsapp_conversa,
                mensagem: this.mensagemTexto,
                arquivos: this.arquivos,
            });

            this.limpaMensagem();
        },
        limpaMensagem() {
            this.arquivos = [];
            this.mensagemTexto = '';
        },
        async transferir() {
            this.modalTransferir = true;

            const confirmaModal = await new Promise((resolve, reject) => {
                this.confirmaModalTransferencia = resolve;
            });

            if (!confirmaModal) {
                return;
            }

            this.$emit('transferir_atendimento', {
                id_whatsapp_fluxo_conversa: this.id_whatsapp_fluxo_conversa,
                id_whatsapp_conversa: this.id_whatsapp_conversa,
                id_whatsapp_atendente: this.id_whatsapp_atendente,
            });

            this.modalTransferir = false;
        },
        finalizar() {
            this.$emit('finalizar_atendimento', {
                id_whatsapp_fluxo_conversa: this.id_whatsapp_fluxo_conversa,
                id_whatsapp_conversa: this.id_whatsapp_conversa,
            });
        },
        cancelaTransferir() {
            this.id_whatsapp_atendente = null;
            this.modalTransferir = false;
        },
        confirmaTransferir() {
            if (!this.id_whatsapp_atendente) {
                this.notificacao = {
                    mensagem: 'Preencha o usuário para transferir o atendimento',
                    color: 'red lighten-3',
                    mostra: true,
                };

                return;
            }

            this.modalTransferir = false;

            this.confirmaModalTransferencia(true);
        },
        anexoRemove(posicao) {
            if (posicao == null) {
                this.arquivos = [];

                return;
            }

            this.arquivos.splice(posicao, 1);
        },
        anexosAdiciona(arquivos) {
            Array.from(arquivos).forEach(async (arquivo) => {
                const arquivoURL = URL.createObjectURL(arquivo);

                this.arquivos.push({
                    loading: true,
                    localUrl: arquivoURL,
                    arquivo: arquivo,
                });

                const blobFile = await fetch(arquivoURL).then((res) => res.blob());

                let loadedFile = this.arquivos.find((arquivo) => arquivo.localUrl === arquivoURL);

                if (loadedFile) {
                    loadedFile.blob = blobFile;
                    loadedFile.loading = false;
                    delete loadedFile.loading;
                }
            });
        },
    },
    data() {
        return {
            carregandoMensagem: 'Aguarde, carregando mensagens anteriores...',
            notificacao: {
                mostra: false,
                mensagem: '',
                color: 'green lighten-3',
            },
            mensagemTexto: '',
            modalTransferir: false,
            confirmaModalTransferencia: null,
            id_whatsapp_atendente: null,
            itensUsuarioTransferencia: [],
            tipoAtendimentoEnum: TipoAtendimento,
            scrollFinalPagina: true,
            arquivosSelecionados: [],
            arquivos: [],
            regrasArquivo: [
                (files) => !files || !files.some((file) => file.size > 3000000) || 'Arquivo não pode ser maior que 3 MB!',
            ],
            abreInputFile: false,
            autoCompleta: {
                usuario_transferencia: {
                    datatable: {
                        id_datatable: 'usuario_id_usuario',
                        chave_primaria: 'usuario_id_usuario',
                        url: `${API_URL_BASE}${API_URL.erp.whatsapp.datatable}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.whatsapp.buscaAtendentes}`,
                        colunas: [
                            {
                                id: 'id_usuario',
                                nome: 'id_usuario',
                                descricao: 'ID Usuário',
                            },
                            {
                                id: 'nome_completo',
                                nome: 'nome_completo',
                                descricao: 'Nome',
                            },
                        ],
                    },
                },
            },
        };
    },
};
</script>

<style>
#mensagemTexto {
    max-height: 80px;
    min-height: 20px;
    height: 20px;
}

.atendimentoConversa {
    position: relative;
    height: 100%;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-flow: column;
}
</style>