const ABERTO = 1;
const FINALIZADO = 2;
const AGUARDANDO_DEVOLUCAO = 3;
const CANCELADO = 4;

const Situacao = {
    ABERTO: ABERTO,
    FINALIZADO: FINALIZADO,
    AGUARDANDO_DEVOLUCAO: AGUARDANDO_DEVOLUCAO,
    CANCELADO: CANCELADO
};

export function situacaoDescricao() {
    return [
        {
            value: ABERTO,
            label: 'Aberto'
        },
        {
            value: AGUARDANDO_DEVOLUCAO,
            label: 'Aguardando Devolução'
        },
        {
            value: CANCELADO,
            label: 'Cancelado'
        },
        {
            value: FINALIZADO,
            label: 'Finalizado'
        }
    ];
}

export default Situacao;
