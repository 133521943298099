import store from '@/vuex/store.js';
import axios from 'axios';
import { API_URL_BASE } from '../config/configs';

const chamaApiServico = async configs => {
    return await ApiService(configs)
        .then(resposta => {
            return resposta;
        })
        .catch(erro => {
            throw new Error(erro.message);
        });
};

const ApiService = async configs => {
    const apiSrv = axios.create({
        baseURL: `${API_URL_BASE}`,
        headers: {
            'Content-Type': configs?.headers?.contentType ?? 'application/json',
            common: {
                Authorization: `Bearer ${store.getters['autenticacao/getToken']}`
            }
        }
    });

    let carregando = configs.hasOwnProperty('mostraCarregando') ? (configs.mostraCarregando == true ? true : false) : true;

    apiSrv.interceptors.request.use(
        async resposta => {
            store.commit('CARREGANDO', carregando);

            return resposta;
        },
        erro => {
            store.commit('CARREGANDO', false);

            return Promise.reject(erro);
        }
    );

    apiSrv.interceptors.response.use(
        async (resposta, teste) => {
            try {
                store.commit('CARREGANDO', false);

                return resposta;
            } catch (erro) {
                store.commit('CARREGANDO', false);

                throw new Error(erro);
            }
        },
        async erro => {
            try {
                let statusResposta = erro.response && erro.response.status ? erro.response.status : 0;

                if (statusResposta === 401) {
                    if (erro.response.data.mensagem == 'Token expirado') {
                        store.commit('setSnack', {
                            show: true,
                            message: 'Renovando token expirado'
                        });

                        return new Promise(async (resolve, reject) => {
                            await store
                                .dispatch('autenticacao/renovaToken')
                                .then(async resposta => {
                                    store.commit('setSnack', {
                                        show: true,
                                        message: resposta
                                    });

                                    resolve(await chamaApiServico(configs));
                                })
                                .catch(erro => {
                                    reject(erro);
                                });
                        });
                    }

                    throw new Error(erro.response.data.mensagem);
                }

                if (statusResposta === 422) {
                    let msgErro = '';

                    if (erro.response?.data?.message) {
                        erro.response.data.mensagem.forEach(function (item, index, arr) {
                            msgErro += `${item}\n `;
                        });
                    }

                    if (erro.response?.data?.erros) {
                        Object.entries(erro.response.data.erros).forEach(([index, items]) => {
                            msgErro += items.map(erro => index + ' ' + erro).join('\n ') + '\n';
                        });
                    }

                    if (erro.response?.data?.erro) {
                        msgErro = erro.response.data.erro;
                    }

                    if (erro.response?.data?.mensagem) {
                        msgErro = erro.response.data.mensagem;
                    }

                    throw new Error(msgErro);
                }

                if (statusResposta === 403) {
                    //throw new Error("Essa ação não é autorizada, solicite permissão.");
                }

                if (erro.response) {
                    const msg = erro.response?.data?.mensagem ? erro.response?.data?.mensagem : "Essa ação não é autorizada, solicite permissão.";

                    let msgErro = `${msg} - (${erro.response.status} - ${erro.response.statusText})`;

                    throw new Error(msgErro);
                }

                throw new Error(erro.message);
            } catch (err) {
                store.commit('CARREGANDO', false);

                throw new Error(err.message);
            }
        }
    );

    return await apiSrv(configs);
};

export default ApiService;
