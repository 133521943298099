<template>
    <v-form ref="formDados">
        <v-row>
            <v-col cols="12" md="12">
                <TextAreaV3
                    v-model="form.orientacao_criterio"
                    :contador="3000"
                    :obriatorio="true"
                    :regra="regrasOrientacao"
                    rotulo="Orientação"
                />
            </v-col>

            <v-col cols="12">
                <v-badge
                    :content="totalRegistros"
                    :value="totalRegistros"
                    icon="mdi-numeric-positive-1"
                    color="deep-purple accent-4"
                    overlap
                >
                    <v-btn tile color="success" @click="adicionacriterio">
                        <v-icon left> mdi-plus </v-icon>
                        Criterios
                    </v-btn>
                </v-badge>
            </v-col>

            <v-col cols="12">
                <v-expansion-panels v-for="(criterio, i) in this.form.criterios" :key="i" class="mt-3">
                    <v-expansion-panel>
                        <v-btn class="mt-3 mr-3 float-right" fab dark x-small color="red" @click="removeCriterio(i)">
                            <v-icon dark> mdi-close </v-icon>
                        </v-btn>

                        <v-expansion-panel-header>
                            <h3>Criterio #{{ i + 1 }}</h3>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content eager>
                            <CriterioForm ref="criterioRef" :criterioData="criterio" />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>

            <v-col cols="12">
                <v-badge
                    v-if="totalRegistros > 0"
                    :content="totalRegistros"
                    :value="totalRegistros"
                    icon="mdi-numeric-positive-1"
                    color="deep-purple accent-4"
                    overlap
                >
                    <v-btn tile color="success" @click="adicionacriterio">
                        <v-icon left> mdi-plus </v-icon>
                        Criterios
                    </v-btn>
                </v-badge>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { Maximo, Minimo, Obrigatorio, Email } from '@/helpers/ValidadorCampo.js';

import { PreencheDadosMesmaPropriedade } from '@/helpers/Funcoes.js';

import CriterioForm from '@/views/AvaliacaoDesempenho/CriterioForm.vue';

import TextAreaV3 from '@/components/Form/TextAreaV3.vue';

export default {
    name: 'CriteriosForm',
    components: {
        CriterioForm,
        TextAreaV3
    },
    props: {
        criterioData: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    created() {
        this.criterioModelo = this.form.criterios[0];
    },
    mounted() {},
    computed: {
        totalRegistros() {
            return this.form.criterios.length;
        }
    },
    watch: {},
    methods: {
        adicionacriterio: function(dadosCriteiro) {
            let criterio = {};

            if (dadosCriteiro.hasOwnProperty('id_avaliacao_desempenho_criterio')) {
                criterio = { ...dadosCriteiro };
            }

            this.form.criterios.push(criterio);
        },
        removeCriterio(posicao) {
            const listaCriterios = this.$refs.criterioRef.filter((item, index) => index !== posicao);

            this.form.criterios = [];

            listaCriterios.forEach((dados, index) => {
                this.form.criterios.push(dados.criterio);
            });
        },
        preparaDados() {
            let criterios = [];

            this.$refs.criterioRef.forEach((dados, index) => {
                dados.criterio.peso = parseInt(dados.criterio.peso);

                criterios.push(dados.criterio);
            });

            return criterios;
        },
        preencheDados(dados) {
            this.form.criterios = [];
            this.form.orientacao_criterio = dados.orientacao_criterio;

            let self = this;

            if (dados.criterios) {
                Object.keys(dados.criterios).forEach(function(key) {
                    self.form.criterios.push(PreencheDadosMesmaPropriedade(self.criterioModelo, dados.criterios[key]));
                });
            }
        }
    },
    data() {
        return {
            criterioModelo: {},
            form: {
                orientacao_criterio: null,
                criterios: [
                    {
                        id_avaliacao_desempenho_criterio: null,
                        nome: null,
                        peso: null
                    }
                ]
            },
            regrasOrientacao: [Obrigatorio(), Minimo(100), Maximo(3000)]
        };
    }
};
</script>

<style></style>
