const ABERTO = 1;
const FINALIZADO = 2;

const Situacao = {
    ABERTO: ABERTO,
    FINALIZADO: FINALIZADO
};

export function situacaoDescricao() {
    return [
        {
            value: ABERTO,
            label: 'Aberto'
        },
        {
            value: FINALIZADO,
            label: 'Finalizado'
        }
    ];
}

export default Situacao;
