<template>
    <div>
        <TituloModulo />

        <AlertaMensagem dense style="width: 100%" />

        <CarregandoLogoEmpresa :mensagem="mensagemCarregando" />

        <BarraNavegacaoBotaoV3
            v-if="!carregando.mostraCarregando && permissao"
            :botoes="botoesComPermissao(avaliacaoSituacao)"
        />

        <v-card v-show="!carregando.mostraCarregando && permissao">
            <v-tabs v-model="tabComponente" background-color="orange accent-2" dense centered dark icons-and-text>
                <v-tabs-slider></v-tabs-slider>

                <v-tab>
                    <v-badge v-if="tabDadosTotalErros" :content="`${tabDadosTotalErros}`" color="red accent-4" overlap>
                        <v-icon>mdi-file-sign </v-icon>
                        <br /><br />
                        Dados
                    </v-badge>

                    <span v-else>
                        <v-icon>mdi-file-sign </v-icon>
                        <br /><br />
                        Dados
                    </span>
                </v-tab>

                <v-tab>
                    <v-badge v-if="tabCriteriosTotalErros" :content="`${tabCriteriosTotalErros}`" color="red accent-4" overlap>
                        <v-icon>mdi-car-brake-alert</v-icon>
                        <br /><br />
                        Critérios
                    </v-badge>

                    <span v-else>
                        <v-icon>mdi-car-brake-alert</v-icon>
                        <br /><br />
                        Critérios
                    </span>
                </v-tab>

                <v-tab>
                    <v-badge
                        v-if="tabAfirmacoesTotalErros"
                        :content="`${tabAfirmacoesTotalErros}`"
                        color="red accent-4"
                        overlap
                    >
                        <v-icon>mdi-clipboard-edit</v-icon>
                        <br /><br />
                        Afirmaçoes
                    </v-badge>

                    <span v-else>
                        <v-icon>mdi-clipboard-edit</v-icon>
                        <br /><br />
                        Afirmaçoes
                    </span>
                </v-tab>

                <v-tab>
                    <v-badge v-if="tabAvaliadosTotalErros" :content="`${tabAvaliadosTotalErros}`" color="red accent-4" overlap>
                        <v-icon>mdi-account-check</v-icon>
                        <br /><br />
                        Avaliados
                    </v-badge>

                    <span v-else>
                        <v-icon>mdi-clipboard-edit</v-icon>
                        <br /><br />
                        Avaliados
                    </span>
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabComponente">
                <v-form ref="avaliacaoDesempenhoForm">
                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-text>
                                <DadosForm ref="dadosRef" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-text>
                                <CriteriosForm ref="criteriosRef" :criterioData="criterioData" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-text>
                                <AfirmacoesForm ref="afirmacoesRef" :afirmacaoData="afirmacaoData" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-text>
                                <AvaliadosForm ref="avaliadosRef" :avaliadoData="avaliadoData" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-form>
            </v-tabs-items>
        </v-card>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { Alerta } from '@/helpers/Funcoes.js';

import SituacaoForm from '@/Enum/AvaliacaoDesempenho/SituacaoForm.js';

import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';

import TituloModulo from '@/components/Default/TituloModulo.vue';
import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import DadosForm from '@/views/AvaliacaoDesempenho/DadosForm.vue';
import CriteriosForm from '@/views/AvaliacaoDesempenho/CriteriosForm.vue';
import AfirmacoesForm from '@/views/AvaliacaoDesempenho/AfirmacoesForm.vue';
import AvaliadosForm from '@/views/AvaliacaoDesempenho/AvaliadosForm.vue';

export default {
    name: 'AvaliacaoDesempenhoForm',
    components: {
        CarregandoLogoEmpresa,
        TituloModulo,
        AlertaMensagem,
        BarraNavegacaoBotaoV3,
        DadosForm,
        CriteriosForm,
        AfirmacoesForm,
        AvaliadosForm
    },
    async mounted() {
        this.mostraCarregando(true);

        this.mensagemCarregando = 'Encontrado avaliação, aguarde...';

        const id_avaliacao_desempenho = this.$route.params.id_avaliacao_desempenho ?? null;

        await this.buscaDados(id_avaliacao_desempenho);

        this.mostraCarregando(false);
    },
    computed: {
        ...mapGetters(['botoesComPermissao']),
        ...mapState(['carregando'])
    },
    watch: {},
    methods: {
        ...mapActions(['montaNavegacao', 'mostraCarregando']),
        async buscaDados(id_avaliacao_desempenho) {
            try {
                await this.buscaPermissoes();
                await this.buscaForm(id_avaliacao_desempenho);
            } catch (error) {
                Alerta([error.message], 'error');

                return false;
            }
        },
        async buscaPermissoes() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.avaliacaodesempenho.permissao}`,
                data: {},
                mostraCarregando: false
            })
                .then(async resposta => {
                    const permissoes = resposta.data.data.atributos;

                    await this.montaNavegacao({ botoes: this.botoes, permissoes });
                })
                .catch(error => {
                    throw new Error(error);
                });
        },
        async buscaForm(id_avaliacao_desempenho) {
            if (!id_avaliacao_desempenho) {
                this.permissao = true;

                return;
            }

            await ApiService({
                method: 'get',
                url: `${API_URL.erp.avaliacaodesempenho.busca}/${id_avaliacao_desempenho}`,
                data: {},
                mostraCarregando: false
            })
                .then(async resposta => {
                    this.permissao = true;

                    const item = resposta.data.data.atributos;

                    item.id_avaliacao_desempenho = resposta.data.data.id_registro;

                    this.avaliacaoSituacao = item.situacao;

                    this.preencheFormulario(item);
                })
                .catch(erro => {
                    Alerta([erro.message], 'error');
                });
        },
        preencheFormulario(dados) {
            const form = { ...dados };

            this.$refs.dadosRef.preencheDados(form);

            this.$refs.criteriosRef.preencheDados({
                orientacao_criterio: form.orientacao_criterio,
                criterios: form.criterio
            });

            this.$refs.afirmacoesRef.preencheDados({
                afirmacoes: form.afirmacao
            });

            this.$refs.avaliadosRef.preencheDados({
                avaliados: form.avaliados
            });
        },
        async salvaForm() {
            try {
                if (!this.validaFormularios()) {
                    return;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', 'Salvar registro ?', {
                        cor: 'green'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        this.mostraCarregando(true);

                        this.mensagemCarregando = 'Salvando os dados, aguarde...';

                        let conteudoFormulario = this.FormataDadosParaEnviarForm();

                        const id_avaliacao_desempenho = this.$refs.dadosRef.form.id_avaliacao_desempenho;

                        let urlForm = id_avaliacao_desempenho
                            ? `${API_URL.erp.avaliacaodesempenho.salva}/${this.$refs.dadosRef.form.id_avaliacao_desempenho}`
                            : `${API_URL.erp.avaliacaodesempenho.salva}`;

                        let urlConfig = {
                            method: id_avaliacao_desempenho ? 'PUT' : 'POST',
                            url: urlForm,
                            data: conteudoFormulario,
                            mostraCarregando: false
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                this.notificacao = {
                                    mensagem: `Avaliação de Desempenho `,
                                    tipo: 'success',
                                    mostra: true
                                };

                                const item = resposta.data.data.atributos;

                                item.id_avaliacao_desempenho = resposta.data.data.id_registro;

                                this.preencheFormulario(item);

                                this.tabComponente = 0;
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (erro) {
                this.mostraCarregando(false);

                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }

            this.mostraCarregando(false);
        },
        cancelaForm() {
            this.mostraCarregando(true);
            this.$router.push({ name: 'avaliacao-desempenho' });
        },
        FormataDadosParaEnviarForm() {
            return {
                dados: {
                    id_avaliacao_desempenho: this.$refs.dadosRef.form.id_avaliacao_desempenho,
                    nome: this.$refs.dadosRef.form.nome,
                    periodo_de: this.$refs.dadosRef.form.periodo_de,
                    periodo_ate: this.$refs.dadosRef.form.periodo_ate,
                    orientacao: this.$refs.dadosRef.form.orientacao,
                    orientacao_criterio: this.$refs.criteriosRef.form.orientacao_criterio,
                    situacao: 1,
                    criterios: this.$refs.criteriosRef.preparaDados(),
                    afirmacoes: this.$refs.afirmacoesRef.preparaDados(),
                    avaliados: this.$refs.avaliadosRef.preparaDados()
                }
            };
        },
        validaFormularios() {
            this.mensagensValidacao = [];
            this.tabDadosTotalErros = 0;
            this.tabCriteriosTotalErros = 0;
            this.tabAfirmacoesTotalErros = 0;
            this.tabAvaliadosTotalErros = 0;

            if (!this.$refs.dadosRef.$refs.formDados.validate()) {
                this.mensagensValidacao.push('Dados contém campos inválidos');

                this.tabDadosTotalErros = 0;
                Object.values(this.$refs.dadosRef.$refs.formDados.errorBag).map(item => {
                    if (item) {
                        this.tabDadosTotalErros += 1;
                    }
                });
            }

            if (!this.$refs.criteriosRef.$refs.formDados.validate()) {
                this.mensagensValidacao.push('Critérios contém campos inválidos');

                this.tabCriteriosTotalErros = 0;
                Object.values(this.$refs.criteriosRef.$refs.formDados.errorBag).map(item => {
                    if (item) {
                        this.tabCriteriosTotalErros += 1;
                    }
                });
            }

            if (!this.$refs.afirmacoesRef.$refs.formDados.validate()) {
                this.mensagensValidacao.push('Afirmações contém campos inválidos');

                this.tabAfirmacoesTotalErros = 0;
                Object.values(this.$refs.afirmacoesRef.$refs.formDados.errorBag).map(item => {
                    if (item) {
                        this.tabAfirmacoesTotalErros += 1;
                    }
                });
            }

            if (!this.$refs.avaliadosRef.$refs.formDados.validate()) {
                this.mensagensValidacao.push('Avaliados contém campos inválidos');

                this.tabAvaliadosTotalErros = 0;
                Object.values(this.$refs.avaliadosRef.$refs.formDados.errorBag).map(item => {
                    if (item) {
                        this.tabAvaliadosTotalErros += 1;
                    }
                });
            }

            if (this.mensagensValidacao.length) {
                Alerta(this.mensagensValidacao, 'error');

                return false;
            }

            return true;
        }
    },
    data() {
        return {
            permissao: false,
            botoes: [
                {
                    id_permissao: [2, 3],
                    nome: 'Salva',
                    acao: 'salvaForm',
                    icone: 'mdi-newspaper-variant-multiple',
                    cor: 'green lighten-1',
                    form: [SituacaoForm.NOVO, SituacaoForm.ATUALIZA]
                },
                {
                    id_permissao: 2,
                    nome: 'Fechar',
                    acao: 'cancelaForm',
                    icone: 'mdi-file-cancel',
                    cor: 'orange lighten-1',
                    form: ['todos']
                }
            ],
            avaliacaoSituacao: SituacaoForm.NOVO,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            mensagemCarregando: 'Buscando, aguarde...',
            tabComponente: 0,
            tabs: ['dados', 'critérios', 'afirmações', 'avaliados'],
            criterioData: [],
            afirmacaoData: [],
            avaliadoData: [],
            tabDadosTotalErros: 0,
            tabCriteriosTotalErros: 0,
            tabAfirmacoesTotalErros: 0,
            tabAvaliadosTotalErros: 0
        };
    }
};
</script>

<style></style>
