<template>
    <v-container class="pa-0" fluid>
        <v-app-bar class="pa-3" color="white" elevation="1">
            <v-badge bordered bottom color="green" dot offset-x="11" offset-y="13">
                <v-avatar class="mt-n7" size="40" elevation="10">
                    <img src="@/assets/image/perfil/img_avatar.png" />
                </v-avatar>
            </v-badge>

            <v-toolbar-title class="title pl-0 ml-2 mt-n4"> {{ conversa.whatsapp_nome_profile }} </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn color="black" icon class="mt-n5">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <v-btn color="black" icon class="mt-n5">
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </v-app-bar>

        <div id="mensagens" class="overflow-auto pa-0 m-0" style="height: 590px; background-color: #efefef;">
            <v-card v-intersect="infiniteScrolling"></v-card>

            <br />
            <br />

            <Mensagem
                v-for="(mensagem, index) in mensagens"
                :key="index"
                :texto="mensagem.mensagem"
                :mensagemErro="mensagem.mensagem_erro"
                :dataEnvio="mensagem.data_envio"
                :dataRecebe="mensagem.data_recebe"
                :dataLeitura="mensagem.data_leitura"
                :envioRemetente="mensagem.envio_remetente"
            />

            <CarregandoLogoEmpresa :carregando="carregando" :mensagem="carregandoMensagem" />

            <br />
            <br />
        </div>

        <v-app-bar color="blue-grey lighten-5" flat>
            <v-textarea
                v-model="mensagemTexto"
                type="text"
                label="Digite sua mensagem aqui"
                append-icon="mdi-emoticon"
                clear-icon="mdi-close-circle"
                :rows="2"
                filled
                clearable
                rounded
                solo
                no-resize
                @click:clear="limpaMensagem()"
            ></v-textarea>

            <v-btn icon class="mt-n5" @click="enviaMensagem()">
                <v-icon v-if="mensagemTexto">mdi-send</v-icon>
                <v-icon v-else>mdi-microphone</v-icon>
            </v-btn>
        </v-app-bar>

        <v-snackbar v-model="notificacao.mostra" :multi-line="true" color="green lighten-3" timeout="2000" tile right>
            {{ notificacao.mensagem }}
        </v-snackbar>
    </v-container>
</template>

<script>
import { sleep } from '@/helpers/Funcoes.js';

import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import Mensagem from './Mensagem.vue';

export default {
    name: 'Conversa',
    components: {
        CarregandoLogoEmpresa,
        Mensagem
    },
    props: {
        conversa: {
            type: Object,
            required: true,
            default: () => ({
                nome: '',
                avatar: null
            })
        },
        mensagens: {
            type: Array,
            default: () => []
        },
        carregando: {
            type: Boolean,
            default: () => false
        }
    },
    mounted() {},
    watch: {
        contatos: {
            deep: true,
            handler(novoValor) {
                this.contatosFiltrados = novoValor;
            }
        },
        mensagens: {
            deep: true,
            handler(novoValor) {
                this.mensagens = novoValor;

                this.scrollFinalMensagens();
            }
        }
    },
    computed: {},
    methods: {
        infiniteScrolling(entries, observer, isIntersecting) {
            if (isIntersecting && !this.carregando) {
                this.$emit('carrega-mensagens-anteriores');
            }
        },
        async scrollFinalMensagens() {
            await sleep(0);

            let container = document.getElementById('mensagens');

            container.scrollTo({ left: 0, top: container.scrollHeight, behavior: 'smooth' });
        },
        enviaMensagem() {
            this.notificacao = {
                mensagem: 'Mensagem registrada, aguardando confirmação...',
                mostra: true
            };

            if (!this.mensagemTexto || this.mensagemTexto == '') {
                return;
            }

            this.$emit('envia-mensagem', { mensagem: this.mensagemTexto });

            this.limpaMensagem();
        },
        limpaMensagem() {
            this.mensagemTexto = '';
        }
    },
    data() {
        return {
            carregandoMensagem: 'Aguarde, carregando mensagens anteriores...',
            notificacao: {
                mostra: false,
                mensagem: ''
            },
            mensagemTexto: ''
        };
    }
};
</script>
