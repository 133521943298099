<template>
  <div class="text-center">
    <v-bottom-sheet v-model="sheet" inset>
      <template v-slot:activator="{ on, attrs }">
        <div class="background-style">
          <v-btn color="green" outlined v-bind="attrs" v-on="on" class="btn-tab-permissoes">
            <v-badge
              color="green"
              :content="perfisSelecionados.length"
            >PERFIS SELECIONADOS
            </v-badge>
          </v-btn>
        </div>

      </template>
      <v-list>
        <div class="text-center pa-5 title elevation-1 mb-4">Modificações a serem aplicadas</div>
        <v-list-item v-for="perfil, key in perfisSelecionados" :key="key" class="text-center perfil-selecionado">

          <v-col class="my-auto" cols="2" v-if="perfil.acao == 'apagar'">
            <div class="text-caption">Ação</div>
            <div>
              <v-chip color="red" small class="white--text">
                APAGAR
              </v-chip>
            </div>
          </v-col>

          <v-col class="my-auto" cols="2" v-if="perfil.acao == 'adicionar'">
            <div class="text-caption">Ação</div>
            <div>
              <v-chip color="blue" small class="white--text">
                ADICIONAR
              </v-chip>
            </div>
          </v-col>

          <v-col class="my-auto" cols="2">
            <div class="text-caption">ID Perfil</div>
            <div class="subtitle-2">{{ perfil.id }}</div>
          </v-col>
          <v-col class="my-auto" cols="3">
            <div class="text-caption">Título</div>
            <div class="subtitle-2">{{ perfil.titulo }}</div>
          </v-col>
          <v-col class="my-auto">
            <div class="text-caption">Descrição</div>
            <div class="subtitle-2">{{ perfil.descricao }}</div>
          </v-col>
          <v-col cols="1" class="my-auto">
            <v-list-item-icon>
              <v-btn icon @click="removerPerfilDaLista(perfil.id)"><v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-col>

        </v-list-item>
      </v-list>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  name: 'UsuarioPerfilTabPermissoesSelecionadas',
  data: () => ({
    sheet: false,
  }),
  props: {
    perfisSelecionados: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    removerPerfilDaLista(id) {
      this.$emit('eventoAtualizarPerfisSelecionados', id);
    }
  }
}
</script>

<style scoped>
.background-style {
  position: absolute;
  background-color: white;
  bottom: 5%;
  width: 93%;
  z-index: 1;
  height: 60px;
  display: flex;
  justify-content: center
}

.btn-tab-permissoes {
  display: flex;
  justify-content: center;
  margin: auto auto;
  padding-top: 20px
}

.perfil-selecionado:hover {
  background-color: rgb(241, 241, 241);
}
</style>