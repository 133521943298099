<template>
    <v-badge color="white" transition="slide-x-transition" style="width: 100%;" :overlap="overlap">
        <template v-if="ajudaMensagem" v-slot:badge>
            <v-tooltip v-if="ajudaMensagem" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="green" v-bind="attrs" v-on="on" small>
                        mdi-help
                    </v-icon>
                </template>
                <span v-html="ajudaMensagem"></span>
            </v-tooltip>
        </template>
        <v-autocomplete
            :value="value"
            @input="$emit('input', $event)"
            :items="itensOpcoes"
            :search-input.sync="buscaPorApi"
            :loading="estaCarregando"
            :label="tituloBusca"
            :rules="regra"
            :disabled="desativado"
            :item-value="itemValor"
            :item-text="itemTexto"
            :value-comparator="comparacaoValor"
            @click:append="abrePesquisa"
            @change="valorAlterado"
            item-color="blue-grey darken-1"
            loader-height="3"
            append-icon="search"
            hide-no-data
            :return-object="returnObject"
            outlined
            clearable
            dense
        >
            <template v-slot:append>
                <span v-if="estaCarregando"> Aguarde... </span>
            </template>
        </v-autocomplete>
    </v-badge>
</template>

<script>
import ApiService from '@/services/ApiService.js';

export default {
    props: {
        value: {
            type: Number | String,
            default: () => null
        },
        datatableConfiguracao: {
            type: Object,
            default: () => {}
        },
        tituloBusca: {
            type: String,
            default: () => ''
        },
        itemsOpcoes: {
            type: Array,
            default: () => {}
        },
        itemValor: {
            type: String,
            default: () => ''
        },
        itemTexto: {
            type: String,
            default: () => ''
        },
        regra: {
            type: Array,
            default: () => []
        },
        formatoRetornado: {
            type: Object,
            default() {
                return { descricao: '%s - %s' };
            }
        },
        desativado: {
            type: Boolean,
            default: () => false
        },
        obrigatorio: {
            type: Boolean,
            default: () => false
        },
        returnObject: {
            type: Boolean,
            default: () => false
        },
        returnObject: {
            type: Boolean,
            default: () => false
        },
        ajudaMensagem: {
            type: String,
            default: () => null
        }
    },
    data: () => ({
        urlBusca: '',
        estaCarregando: false,
        buscaPorApi: null,
        respBusca: [],
        objetoClicado: null,
        objetoRetorno: null,
        buscaAoSelecionar: false
    }),
    async created() {},
    computed: {
        itensOpcoes() {
            return this.respBusca;
        },
        overlap() {
            if (this.ajudaMensagem) {
                return true;
            }

            return false;
        }
    },
    watch: {
        buscaPorApi(valor, valorAntigo) {
            /**
             * Para não buscar quando selecionar a opção
             */
            if (!this.buscaAoSelecionar) {
                this.buscaAoSelecionar = true;
                return;
            }

            valor && valor !== this.value && this.busca(valor);
        },
        itemsOpcoes: {
            handler: function(dadosAtual, dadosAntigo) {
                this.respBusca = dadosAtual;
            },
            immediate: true
        }
    },
    mounted() {},
    methods: {
        comparacaoValor(valorA, valorB) {
            if (valorA === null || valorB === null) {
                return false;
            }

            const valorIgual = valorA === valorB;

            if (valorIgual) {
                this.buscaAoSelecionar = false;
            }

            return valorIgual;
        },
        valorAlterado(valor) {
            this.buscaAoSelecionar = false;

            const encontrado = this.respBusca.find(item => item[this.itemValor] === valor);

            this.$emit('change', valor);
            this.$emit('valor-object', encontrado);
        },
        atualizaValor(objectoOpcoes, valorDefinido) {
            this.respBusca = objectoOpcoes;

            this.$emit('input', valorDefinido);
        },
        abrePesquisa() {
            this.$emit(
                'abrePesquisa',
                this,
                this.itemsOpcoes,
                this.formatoRetornado,
                this.tituloBusca,
                this.datatableConfiguracao
            );
        },
        async verificaSeRegistroJaRetornouNaBusca(valor) {
            const encontrado = this.respBusca.find(item => item[this.itemValor] === this.value);

            if (encontrado) {
                return true;
            }

            return false;
        },
        busca(valor) {
            // cancela chamadas pendentes
            clearTimeout(this._timerId);

            // delay para novas chamadas
            this._timerId = setTimeout(async () => {
                /*
                const encontrado = await this.verificaSeRegistroJaRetornouNaBusca(valor)

                if (encontrado) {
                    return;
                }
                */

                this.respBusca = [];
                this.estaCarregando = true;

                let urlConsulta = `${this.datatableConfiguracao.urlBusca}/${valor}`;

                let urlConfig = {
                    method: 'get',
                    url: `${urlConsulta}`,
                    data: {},
                    mostraCarregando: false
                };

                ApiService(urlConfig)
                    .then(resposta => {
                        this.respBusca = resposta.data.data.atributos;
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    })
                    .finally(() => {
                        this.estaCarregando = false;
                    });
            }, 700);
        }
    }
};
</script>

<style scoped></style>
