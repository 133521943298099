const ETAPA_PRE_CTE = 1;
const ETAPA_POS_CTE = 2;
const ETAPA_POS_OST = 3;

const AuditoriaEtapaEnum = {
    ETAPA_PRE_CTE: ETAPA_PRE_CTE,
    ETAPA_POS_CTE: ETAPA_POS_CTE,
    ETAPA_POS_OST: ETAPA_POS_OST
};

export function descricao(etapa) {
    switch (etapa) {
        case ETAPA_PRE_CTE:
            return 'PRÉ CTE';
        case ETAPA_POS_CTE:
            return 'PÓS CTE';
        case ETAPA_POS_OST:
            return 'PÓS CTE';
        default:
            return '';
    }
}

export function listaDescricao() {
    return [
        {
            value: ETAPA_PRE_CTE,
            label: 'PRÉ CTE'
        },
        {
            value: ETAPA_POS_CTE,
            label: 'PÓS CTE'
        },
        {
            value: ETAPA_POS_OST,
            label: 'PÓS OST'
        }
    ];
}

export default AuditoriaEtapaEnum;
