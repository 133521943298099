<template>
    <v-card dense>
        <v-system-bar height="45" class="primary white--text" style="font-size: 25px">
            Painel de Notícias
            <v-spacer></v-spacer>
        </v-system-bar>

        <CarregandoOverlay :overlay="overlay" mensagem="Carregando painel de notícias, aguarde..." />

        <v-row class="pa-2" v-if="overlay">
            <v-col cols="12" md="12">
                <v-skeleton-loader class="mx-auto" type="table-heading, divider, list-item-three-line"></v-skeleton-loader>
            </v-col>

            <v-col cols="12" md="12">
                <v-skeleton-loader class="mx-auto" type="table-heading, divider, list-item-three-line"></v-skeleton-loader>
            </v-col>
        </v-row>

        <v-timeline align-top dense v-if="!overlay">
            <v-timeline-item v-for="noticia in noticias" :key="noticia.id_noticia" fill-dot>
                <template v-slot:icon>
                    <v-tooltip v-model="tooltip" right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-avatar v-bind="attrs" v-on="on" color="blue-grey white--text">
                                <img v-if="noticia.imagem_avatar != null" :src="noticia.imagem_avatar" />
                                <span v-if="noticia.imagem_avatar == null">
                                    {{ iniciaisUsuario(noticia.nome_criador) }}
                                </span>
                            </v-avatar>
                        </template>

                        <PerfilModal v-if="tooltip" :id_usuario="noticia.id_usuario_ultima_alteracao" />
                    </v-tooltip>
                </template>

                <div class="card-noticia">
                    <v-card class="elevation-10">
                        <v-card-title class="headline">
                            {{ noticia.titulo }}
                        </v-card-title>

                        <v-card-text>
                            <div class="conteudo-noticia" v-html="noticia.texto"></div>
                        </v-card-text>
                        <v-banner outlined>
                            <v-icon>mdi-clock</v-icon>
                            {{ noticia.data_criacao }}
                            {{ noticia.hora_criacao }} -
                            {{ noticia.nome_criador }}
                        </v-banner>
                    </v-card>
                </div>
            </v-timeline-item>
        </v-timeline>
        <v-btn @click="getNoticia()" block color="primary" elevation="9" v-if="apareceBotao">Carregar mais noticias</v-btn>
        <v-alert max-width="1071" dense type="error" v-if="!apareceBotao">
            Não há mais notícias
        </v-alert>
    </v-card>
</template>

<script>
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import CarregandoOverlay from '@/components/Default/CarregandoOverlay.vue';
import PerfilModal from '@/views/Usuario/PerfilModal.vue';

export default {
    name: 'NoticiaLinhaDoTempo',
    components: {
        CarregandoOverlay,
        PerfilModal
    },
    mounted() {
        this.getNoticia();
    },
    data: () => ({
        overlay: true,
        apareceBotao: true,
        apareceNoticia: true,
        loading: false,
        tooltip: false,
        noticias: []
    }),
    methods: {
        async getNoticia() {
            const ultimaNoticia = this.noticias.length === 0 ? 0 : this.noticias[this.noticias.length - 1].id_noticia;

            let urlConfig = {
                method: 'get',
                url: `${API_URL.erp.noticia.linhaTempo}/${ultimaNoticia}/quantidade-registro/5`,
                data: {},
                mostraCarregando: false
            };

            await ApiService(urlConfig)
                .then(resposta => {
                    let tamanho = resposta.data.conteudo.length;

                    if (tamanho == 0) {
                        this.apareceBotao = false;
                    }

                    if (tamanho > 0) {
                        this.noticias = Array.from(new Set(this.noticias.concat(resposta.data.conteudo)));
                    }

                    if (this.noticia) {
                        this.apareceNoticia = false;
                    }
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });

            this.overlay = false;
        },
        iniciaisUsuario(nome_usuario) {
            const separador = nome_usuario.split(' ');

            const primeiroNome = separador[0].substr(0, 1);

            const ultimoNome = separador[separador.length - 1].substr(0, 1);

            if (separador.length - 1 == 0) {
                return `${primeiroNome}.`;
            }

            return `${primeiroNome}.${ultimoNome}.`;
        }
    }
};
</script>

<style>
.conteudo-noticia > p > img {
    width: 100%;
}

@media (min-width: 1369px) {
    .card-noticia {
        max-width: 95%;
    }
}

.v-tooltip__content.menuable__content__active {
    opacity: 1 !important;
}
</style>
