<template>
    <v-card-text>
        <v-row>
            <v-col cols="2">
                <InputTextV3
                    v-model="afirmacao.id_avaliacao_desempenho_afirmacao"
                    :contador="100"
                    rotulo="ID"
                    desativado
                    clearable
                />
            </v-col>

            <v-col cols="10">
                <InputTextV3 v-model="afirmacao.nome" rotulo="Nome" :contador="200" :obrigatorio="true" :regra="regrasNome" />
            </v-col>

            <v-col cols="12">
                <InputTextV3
                    v-model="afirmacao.palavra_chave"
                    rotulo="Palavra Chave"
                    :contador="250"
                    :obrigatorio="true"
                    :regra="regrasPalavraChave"
                />
            </v-col>

            <v-col cols="12" md="12">
                <TextAreaV3
                    v-model="afirmacao.consideracao"
                    :contador="2000"
                    :obrigatorio="true"
                    :regra="regrasConsideracao"
                    rotulo="Consideração"
                />
            </v-col>
        </v-row>
    </v-card-text>
</template>

<script>
import { Maximo, Minimo, Obrigatorio } from '@/helpers/ValidadorCampo.js';

import InputTextV3 from '@/components/Form/InputTextV3.vue';

import TextAreaV3 from '@/components/Form/TextAreaV3.vue';

export default {
    name: 'AfirmacaoForm',
    components: {
        InputTextV3,
        TextAreaV3
    },
    props: {
        afirmacaoData: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    watch: {
        afirmacaoData: {
            handler: function(dadosAtual, dadosAntigo) {
                this.preencheDados(dadosAtual);
            }
        }
    },
    created() {
        if (!_.isEmpty(this.afirmacaoData)) {
            this.preencheDados(this.afirmacaoData);
        }
    },
    data() {
        return {
            afirmacao: {
                id_avaliacao_desempenho_afirmacao: null,
                nome: null,
                palavra_chave: null,
                consideracao: null
            },
            regrasNome: [Obrigatorio(), Minimo(5), Maximo(200)],
            regrasPalavraChave: [Obrigatorio(), Minimo(5), Maximo(250)],
            regrasConsideracao: [Obrigatorio(), Minimo(20), Maximo(2000)]
        };
    },
    methods: {
        preparaDados() {
            const afirmacao = { ...this.afirmacao };

            return afirmacao;
        },
        preencheDados(afirmacao) {
            this.afirmacao = { ...afirmacao };
        }
    }
};
</script>
