<template>
    <div>
        <TituloModulo />

        <BarraNavegacaoBotaoV3 :botoes="botoesPorForm('form-salva')" referenciaComponenete="TrackingEmail" />

        {{ botoesPorForm('form-salva') }}

        <v-row>
            <v-col cols="12" class="text-right">
                <v-data-table
                    v-model="datatable.itensSelecionados"
                    :headers="datatable.cabecalho"
                    :items="datatable.itens"
                    :options.sync="datatable.opcoes"
                    :server-items-length="datatable.totalRegistros"
                    :sort-desc="[true]"
                    :fixed-header="true"
                    :items-per-page="50"
                    :footer-props="{
                        'items-per-page-options': [50, 100, 200, -1]
                    }"
                    :show-current-page="true"
                    :loading="datatable.carregando"
                    :loading-text="datatable.mensagemCarregando"
                    height="60vh"
                    :item-key="datatable.chave_primaria"
                    class="elevation-4"
                    show-select
                    dense
                >
                    <template v-slot:item.acao="{ item }">
                        <v-icon small class="mr-2" @click="novoItem()">
                            mdi-folder-plus
                        </v-icon>
                        <v-icon small class="mr-2" @click="formAtualiza(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon small @click="deleteItem(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import TituloModulo from '@/components/Default/TituloModulo.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';

export default {
    name: 'TrackingEmail',
    components: {
        TituloModulo,
        BarraNavegacaoBotaoV3
    },
    async mounted() {
        await this.dadosPaginaInicial();
    },
    computed: {
        ...mapGetters(['botoesPorForm'])
    },
    watch: {
        options: {
            handler() {
                this.buscaDatatable();
            },
            deep: true
        }
    },
    methods: {
        ...mapActions(['montaBarraNavegacao']),
        async dadosPaginaInicial() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.trackingemail.dadosPaginaInicial}`,
                data: {}
            })
                .then(resposta => {
                    this.montaBarraNavegacao(resposta.data.conteudo.permissoes);

                    console.log('BOTOES', this.botoesPorForm('grid'));
                })
                .catch(erro => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });

            await this.buscaDatatable();
        },
        async recarregaTabela() {
            await this.buscaDatatable();
        },
        async buscaDatatable() {
            this.datatable.carregando = true;

            await ApiService({
                method: 'get',
                url: `${API_URL.erp.trackingemail.datatable}`,
                data: {},
                mostraCarregando: false
            })
                .then(resposta => {
                    const itens = resposta.data.conteudo.datatable.itens;

                    this.datatable.itens = itens.map(item => {
                        return item;
                    });

                    this.datatable.totalRegistros = itens.length;
                    this.datatable.carregando = false;
                })
                .catch(erro => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        },
        novoForm(item) {
            this.$router.push({
                name: 'tracking-email-form',
                params: {}
            });
        },
        formAtualiza(item) {
            this.$router.push({
                name: 'tracking-email-form',
                params: { id_tracking_email: `${item.id_tracking_email}` }
            });
        }
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            datatable: {
                carregando: false,
                mensagemCarregando: 'Buscando, aguarde...',
                chave_primaria: 'id_tracking_email',
                itens: [],
                itensSelecionados: [],
                totalRegistros: 0,
                opcoes: {},
                cabecalho: [
                    {
                        text: 'AÇÃO',
                        value: 'acao',
                        align: 'start',
                        width: '100',
                        sortable: false
                    },
                    {
                        text: 'ID',
                        value: 'id_tracking_email',
                        width: '150'
                    },
                    {
                        text: 'Cliente Rodopar',
                        value: 'id_cliente_rodopar',
                        width: '30%'
                    },
                    {
                        text: 'Razão Social',
                        value: 'razao_social',
                        width: '50%'
                    }
                ]
            }
        };
    }
};
</script>

<style></style>
