<template>
    <v-dialog v-model="painelModal" persistent width="90%">
        <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-4" dark v-bind="attrs" v-on="on">
                mdi-card-account-phone-outline
            </v-icon>
        </template>

        <v-card height="100%" style="min-height: 300px;">
            <v-overlay :value="carregandoDados" absolute>
                <CarregandoLogoEmpresa :carregando="carregandoDados" :mensagem="carregandoDadosMensagem" />
            </v-overlay>

            <span v-if="!carregandoDados">
                <v-card-title>
                    <span class="text-h5">Contato Motorista</span>

                    <v-spacer></v-spacer>

                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="green" v-bind="attrs" v-on="on" icon>
                                <v-icon>mdi-help</v-icon>
                            </v-btn>
                        </template>

                        <span>
                            Lista de motoristas cadastrados no Rodopar.

                            <br />

                            Cadastro do telefone corporativo é realizado no TNSYS.
                        </span>
                    </v-tooltip>
                </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn text color="green" class="ma-2" @click="buscaMotorista">
                        Atualizar
                        <v-icon right dark>mdi-cached</v-icon>
                    </v-btn>

                    <v-btn color="red darken-1" text @click="painelModal = false">
                        Fechar
                    </v-btn>
                </v-card-actions>

                <v-card-text>
                    <v-data-iterator
                        :items="motoristas"
                        :search="pesquisa"
                        :sort-by="sortBy.toLowerCase()"
                        hide-default-footer
                        disable-sort
                        disable-pagination
                    >
                        <template v-slot:header>
                            <v-toolbar dark color="blue-grey lighten-1" class="mb-1">
                                <v-text-field
                                    v-model="pesquisa"
                                    prepend-inner-icon="mdi-magnify"
                                    label="Pesquisa motorista"
                                    placeholder="Pesquisa Motorista"
                                    solo-inverted
                                    hide-details
                                    clearable
                                    flat
                                ></v-text-field>
                            </v-toolbar>
                        </template>

                        <template v-slot:default="motoristas">
                            <v-row class="pt-2">
                                <v-col cols="4" v-for="motorista in motoristas.items" :key="motorista.id_motorista">
                                    <painel-card
                                        :motorista="motorista.nome"
                                        :cpf="motorista.cpf"
                                        :rg="motorista.rg"
                                        :imagem_avatar="motorista.url_imagem"
                                        :telefone_celular="motorista.telefone_celular"
                                    />
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-iterator>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="red darken-1" text @click="painelModal = false">
                        Fechar
                    </v-btn>
                </v-card-actions>
            </span>
        </v-card>

        <v-row>
            <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                {{ notificacao.mensagem }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </v-row>
    </v-dialog>
</template>

<script>
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import PainelCard from './PainelCard.vue';

export default {
    name: 'Painel',
    components: {
        CarregandoLogoEmpresa,
        PainelCard
    },
    watch: {
        painelModal: {
            deep: true,
            handler(novoValor) {
                if (novoValor) {
                    this.buscaMotorista();
                }

                if (!novoValor) {
                    this.motoristas = [];
                    this.pesquisa = '';
                }
            }
        }
    },
    methods: {
        async buscaMotorista() {
            try {
                this.carregandoDados = true;

                this.carregandoDadosMensagem = 'Aguarde, carregando lista de motoristas...';

                await ApiService({
                    method: 'GET',
                    url: `${API_URL.erp.motorista.painelMotorista}`,
                    mostraCarregando: false
                })
                    .then(resposta => {
                        if (resposta?.data?.data) {
                            this.motoristas = resposta.data.data.atributos;
                        }
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.carregandoDados = false;
            }
        }
    },
    data() {
        return {
            painelModal: false,
            pesquisa: '',
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            carregandoDados: false,
            carregandoDadosMensagem: 'Aguarde, buscando motoristas...',
            motoristas: [],
            sortBy: 'nome'
        };
    }
};
</script>
