<template>
    <v-breadcrumbs :items="items" large>
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item>
                <h2 class="text-xl-h2 font-weight-bold">{{ item.text.toUpperCase() }}</h2>
            </v-breadcrumbs-item>
        </template>
        <template v-slot:divider>
            <v-icon size="30">mdi-forward</v-icon>
        </template>
    </v-breadcrumbs>
</template>

<script>
export default {
    name: 'TituloModulo',
    props: {
        titulo: {
            type: String
        }
    },
    components: {},
    async created() {},
    mounted() {
        this.montaBreadcrumbs();
    },
    data: () => ({
        collapseOnScroll: true,
        btnCorPadrao: 'primary',
        items: []
    }),
    methods: {
        montaBreadcrumbs() {
            const urlBreadcrumbs = this.$router.currentRoute.meta.breadcrumbs
                ? this.$router.currentRoute.meta.breadcrumbs
                : this.titulo;
            let breadcrumbs = urlBreadcrumbs.split('/');

            if (!breadcrumbs) {
                return;
            }

            breadcrumbs = breadcrumbs.filter(n => n && n != 'admin');

            this.items = breadcrumbs.map(item => {
                item = item.replace('-', ' ');

                return {
                    text: item.charAt(0).toUpperCase() + item.slice(1)
                };
            });
        }
    }
};
</script>
