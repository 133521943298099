<template>
    <div>
        <TituloModulo />

        <AlertaMensagem dense style="width: 100%" />

        <BarraNavegacaoBotaoV3 v-if="!carregando.mostraCarregando && permissao" :botoes="botoesComPermissao(1)" />

        <CarregandoLogoEmpresa :mensagem="mensagemCarregando" />

        <v-card v-show="!carregando.mostraCarregando">
            <v-tabs v-model="tabComponente" background-color="orange accent-2" dense centered dark icons-and-text>
                <v-tabs-slider></v-tabs-slider>

                <v-tab>
                    <v-badge
                        :content="tabDadosTotalErros > 0 ? tabDadosTotalErros : ''"
                        :color="tabDadosTotalErros > 0 ? 'red accent-4' : ''"
                        overlap
                    >
                        <v-icon>mdi-file-sign</v-icon>
                        <v-spacer></v-spacer>
                        Dados
                    </v-badge>
                </v-tab>

                <v-tab>
                    <v-badge
                        :content="tabEnderecoTotalErros > 0 ? tabEnderecoTotalErros : ''"
                        :color="tabEnderecoTotalErros > 0 ? 'red accent-4' : ''"
                        overlap
                    >
                        <v-icon>mdi-map-marker-outline</v-icon>
                        <v-spacer></v-spacer>
                        Endereço
                    </v-badge>
                </v-tab>

                <v-tab>
                    <v-badge
                        :content="tabContatoTotalErros > 0 ? tabContatoTotalErros : ''"
                        :color="tabContatoTotalErros > 0 ? 'red accent-4' : ''"
                        overlap
                    >
                        <v-icon>mdi-contacts-outline</v-icon>
                        <v-spacer></v-spacer>
                        Contato
                    </v-badge>
                </v-tab>

                <v-tab>
                    <v-badge
                        :content="tabAtestadoTotalErros > 0 ? tabAtestadoTotalErros : ''"
                        :color="tabAtestadoTotalErros > 0 ? 'red accent-4' : ''"
                        overlap
                    >
                        <v-icon>mdi-certificate-outline</v-icon>
                        <v-spacer></v-spacer>
                        Atestado
                    </v-badge>
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabComponente">
                <v-form :ref="formNome">
                    <v-tab-item eager>
                        <v-row class="mt-1">
                            <ColaboradorForm
                                :itemsUFRG="itemsEstado"
                                ref="colaboradorRef"
                                :colaboradorData="colaborador"
                                :parceiroData="parceiro"
                                :itensGestorData="itensGestor"
                                @preencheDados="preencheDados"
                                @mensagemAlerta="$emit('mensagemAlerta', $event, 'error')"
                            />
                        </v-row>
                    </v-tab-item>
                    <v-tab-item eager class="py-4">
                        <v-card elevation="2">
                            <v-card-title> Endereco Principal</v-card-title>
                            <EnderecoForm
                                ref="enderecoPrincipalRef"
                                :enderecoData="endereco.principal"
                                :tipoEndereco="1"
                                :itemsEstado="itemsEstado"
                            />
                        </v-card>
                        <v-card elevation="2">
                            <v-card-title>
                                Endereco Entrega

                                <div class="ml-9">
                                    <v-btn
                                        class="ml-2"
                                        fab
                                        dark
                                        :color="!enderecoEntregaAtivo ? 'orange' : 'red'"
                                        @click="handleEnderecoEntrega"
                                    >
                                        <v-icon dark>
                                            {{ !enderecoEntregaAtivo ? 'mdi-plus' : 'mdi-close' }}
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-card-title>

                            <v-card-text v-if="enderecoEntregaAtivo">
                                <EnderecoForm
                                    ref="enderecoEntregaRef"
                                    :tipoEndereco="2"
                                    :itemsEstado="itemsEstado"
                                    :enderecoData="endereco.entrega"
                                />
                            </v-card-text>
                        </v-card>
                        <v-card elevation="2">
                            <v-card-title>
                                Endereco Cobrança

                                <div class="ml-5">
                                    <v-btn
                                        class="ml-2"
                                        fab
                                        dark
                                        :color="!enderecoCobrancaAtivo ? 'orange' : 'red'"
                                        @click="handleEnderecoCobranca"
                                    >
                                        <v-icon dark>
                                            {{ !enderecoCobrancaAtivo ? 'mdi-plus' : 'mdi-close' }}
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-card-title>
                            <v-card-text v-if="enderecoCobrancaAtivo">
                                <EnderecoForm
                                    ref="enderecoCobrancaRef"
                                    :tipoEndereco="3"
                                    :itemsEstado="itemsEstado"
                                    :enderecoData="endereco.cobranca"
                                />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item eager>
                        <v-card flat elevation="2">
                            <v-card-text>
                                <v-badge
                                    :content="totalContato"
                                    :value="totalContato"
                                    icon="mdi-numeric-positive-1"
                                    color="deep-purple accent-4"
                                    overlap
                                >
                                    <v-btn tile color="success" @click="contatoAdiciona">
                                        <v-icon left> mdi-plus </v-icon>
                                        Contato
                                    </v-btn>
                                </v-badge>
                            </v-card-text>

                            <v-card-text>
                                <v-expansion-panels v-for="(contato, i) in contato" :key="i" class="mt-3">
                                    <v-expansion-panel v-show="contato">
                                        <v-btn
                                            class="mt-3 mr-3 float-right"
                                            fab
                                            dark
                                            x-small
                                            color="red"
                                            @click="contatoRemove(i)"
                                        >
                                            <v-icon dark> mdi-close </v-icon>
                                        </v-btn>

                                        <v-expansion-panel-header
                                            ><h3>Contato #{{ i + 1 }}</h3></v-expansion-panel-header
                                        >

                                        <v-expansion-panel-content eager>
                                            <ContatoForm
                                                ref="contatoRef"
                                                :itemsTipoContato="itemsTipoContato"
                                                :contatoData="contato"
                                            ></ContatoForm>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card-text>

                            <v-card-text>
                                <v-badge
                                    :content="totalContato"
                                    :value="totalContato"
                                    icon="mdi-numeric-positive-1"
                                    color="deep-purple accent-4"
                                    overlap
                                >
                                    <v-btn tile color="success" @click="contatoAdiciona">
                                        <v-icon left> mdi-plus </v-icon>
                                        Contato
                                    </v-btn>
                                </v-badge>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item eager>
                        <v-card elevation="2">
                            <v-card-text>
                                <v-row>
                                    <v-btn class="my-2" tile color="success" @click="handleAtestado">
                                        <v-icon left> mdi-plus </v-icon>
                                        Adicionar Atestado
                                    </v-btn>

                                    <v-expansion-panels v-for="(active, i) in atestados" :key="i" class="mt-3">
                                        <v-expansion-panel v-show="active">
                                            <v-expansion-panel-header disable-icon-rotate>
                                                <template v-slot:actions>
                                                    <div>
                                                        <v-btn fab dark x-small color="red" @click="handleRemoveAtestado(i)">
                                                            <v-icon dark>
                                                                mdi-close
                                                            </v-icon>
                                                        </v-btn>
                                                    </div>
                                                </template>

                                                <div>
                                                    <h3>Atestado #{{ i + 1 }}</h3>
                                                </div>
                                            </v-expansion-panel-header>

                                            <v-expansion-panel-content eager>
                                                <AtestadoForm
                                                    ref="atestadoRef"
                                                    :atestadoData="atestadoData[i]"
                                                    :itemsAtestadoCID="itemsTipoAtestadoCID"
                                                    :itemsAtestadoMotivo="itemsTipoAtestadoMotivo"
                                                ></AtestadoForm>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-form>
            </v-tabs-items>
        </v-card>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import FormMixins from '@/Modulo/Colaborador/Dominio/Servico/FormMixins.js';

import BuscaModal from '@/components/Default/BuscaModal.vue';
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';

import InputTextV2 from '@/components/Form/InputTextV2.vue';
import TextAreaV2 from '@/components/Form/TextAreaV2.vue';
import DateTimePicker from '@/components/Form/DateTimePicker.vue';
import EnderecoForm from '@/components/Parceiro/Endereco/EnderecoForm.vue';
import ContatoForm from '@/components/Parceiro/Contato/ContatoForm.vue';
import AtestadoForm from '@/components/Colaborador/AtestadoForm.vue';
import ColaboradorForm from '@/components/Colaborador/ColaboradorForm.vue';
import TextArea from '../../components/Form/TextArea';

export default {
    components: {
        TextArea,
        BuscaModal,
        InputTextV2,
        TextAreaV2,
        DateTimePicker,
        EnderecoForm,
        ColaboradorForm,
        ContatoForm,
        AtestadoForm,
        TituloModulo,
        BarraNavegacaoBotaoV3,
        AlertaMensagem,
        CarregandoLogoEmpresa
    },
    mixins: [FormMixins]
};
</script>

<style scoped></style>
