import { API_URL_BASE, API_URL } from '@/config/configs';

const Colaborador = {
    datatable: {
        id_datatable: 'colaborador',
        chave_primaria: 'id_colaborador',
        url: `${API_URL_BASE}${API_URL.erp.colaborador.datatable}`,
        colunas: false,
    },
    formRefsComponente: 'ColaboradorForm',
    formNome: 'formColaborador',
    moduloNome: 'colaborador',
    chavePrimaria: 'id_colaborador',
    componenteArquivo: 'Colaborador/Form.vue',
    tabRefs: 'tabColaborador',
    urlNovoRegistro: '',
    urlConsultaRegistro: 'listaPorIdForm',
    tabs: []
}

export { Colaborador };

