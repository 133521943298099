<template>
    <v-row justify="center">
        <v-dialog v-model="abreModal" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn color="red lighten-2" icon dark @click="fechaModal()">
                        <v-icon large>mdi-close-circle</v-icon>
                    </v-btn>

                    <v-toolbar-title>
                        Nota fiscal com ocorrências

                        <v-chip class="ma-2" color="orange" text-color="white">
                            {{ datatable.itens.length }}
                            <v-icon right>
                                mdi-file-document-check-outline
                            </v-icon>
                        </v-chip>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-items>
                        <v-btn dark text @click="carregaDados(numero_cte)">
                            <v-icon large>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <CarregandoLogoEmpresa :mensagem="mensagemCarregando" />

                <v-col cols="12" md="12" v-if="carregando.mostraCarregando">
                    <v-skeleton-loader v-bind="attrs" type="article"></v-skeleton-loader>
                    <v-skeleton-loader v-bind="attrs" type="article"></v-skeleton-loader>
                    <v-skeleton-loader v-bind="attrs" type="article"></v-skeleton-loader>
                </v-col>

                <v-list three-line v-if="!carregando.mostraCarregando">
                    <v-list-item>
                        <v-list-item-content>
                            <v-expansion-panels inset elevation="5">
                                <v-expansion-panel v-for="(item, i) in datatable.itens" :key="item.id_registro">
                                    <v-expansion-panel-header>
                                        <v-badge color="orange darken-1" :content="String(item.totalRegistros)" overlap>
                                            <v-chip class="ma-2" color="pink" label text-color="white" width="10">
                                                <v-icon left>
                                                    mdi-truck-fast-outline
                                                </v-icon>
                                                Nota Fiscal: {{ item.id_registro }}
                                            </v-chip>
                                        </v-badge>
                                    </v-expansion-panel-header>

                                    <v-expansion-panel-content>
                                        <TabelaVuetify
                                            ref="tabelaOcorrencia"
                                            :cabecalho="datatable.cabecalho"
                                            :itens="item.ocorrencia_documento"
                                            :total_registros="item.totalRegistros"
                                            :por_pagina="datatable.porPagina"
                                            :itens_por_pagina="[100, 200, -1]"
                                            :ultima_pagina="item.ultimaPagina"
                                            :carregando="false"
                                            :ordenar_direcao="true"
                                            :mostra_selecao="false"
                                            :ordenar_por="datatable.ordenarPor"
                                            chave_primaria=""
                                        >
                                            <template v-slot:item.rodopar_data_chegada="{ item }">
                                                {{ formataData(item.rodopar_data_chegada) }}
                                            </template>

                                            <template v-slot:item.rodopar_data_entrada="{ item }">
                                                {{ formataData(item.rodopar_data_entrada) }}
                                            </template>

                                            <template v-slot:item.rodopar_data_saida="{ item }">
                                                {{ formataData(item.rodopar_data_saida) }}
                                            </template>
                                        </TabelaVuetify>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-dialog>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import moment from 'moment';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { sleep } from '@/helpers/Funcoes.js';

import TabelaVuetify from '@/components/Default/TabelaVuetify.vue';
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';

export default {
    name: 'NotaFiscalOcorrencia',
    components: {
        TabelaVuetify,
        CarregandoLogoEmpresa
    },
    watch: {
        numero_cte: {
            handler: function(dadosAtual) {
                this.carregaDados(dadosAtual);
            },
            deep: true
        }
    },
    computed: {
        ...mapState(['carregando'])
    },
    data() {
        return {
            abreModal: false,
            numero_cte: null,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            mensagemCarregando: 'Buscando nota fiscal com ocorrência, aguarde...',
            attrs: {
                class: 'mb-2',
                boilerplate: true,
                elevation: 5
            },
            datatable: {
                itens: [
                    {
                        id_ocorrencia_documento: null,
                        id_registro: null,
                        ocorrencia_documento: []
                    }
                ],
                totalRegistros: 0,
                ultima_pagina: 0,
                pagina_atual: 1,
                porPagina: 100,
                ordenarPor: 'id_registro',
                ordenarDirecao: true,
                cabecalho: [
                    {
                        text: 'CÓDIGO',
                        value: 'id_ocorrencia',
                        width: '100',
                        showFilter: false
                    },
                    {
                        text: 'DESCRIÇÃO',
                        value: 'descricao',
                        width: '200',
                        showFilter: false
                    },
                    {
                        text: 'OBSERVAÇÃO',
                        value: 'observacao',
                        width: '200',
                        showFilter: false
                    },
                    {
                        text: 'DATA CHEGADA',
                        value: 'rodopar_data_chegada',
                        width: '20%',
                        showFilter: false
                    },
                    {
                        text: 'DATA ENTRADA',
                        value: 'rodopar_data_entrada',
                        width: '20%',
                        showFilter: false
                    },
                    {
                        text: 'DATA SAÍDA',
                        value: 'rodopar_data_saida',
                        width: '20%',
                        showFilter: false
                    }
                ]
            }
        };
    },
    methods: {
        ...mapActions(['mostraCarregando']),
        fechaModal() {
            this.abreModal = false;
            this.numero_cte = null;
        },
        async carregaDados(numeroCte) {
            if (!numeroCte) {
                return;
            }

            this.datatable.itens = [];

            try {
                this.mostraCarregando(true);

                await sleep(1000);

                const url = `${API_URL.erp.cte.notaFiscalOcorrencia}`.replace('$numero_cte', numeroCte);

                await ApiService({
                    method: 'get',
                    url: url,
                    data: {},
                    mostraCarregando: false
                })
                    .then(resposta => {
                        if (resposta.data.data.length) {
                            this.preencheDados(resposta.data.data);
                        }
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.mostraCarregando(false);
            }
        },
        preencheDados(itens) {
            this.datatable.itens = itens.map(item => {
                const totalRegistros = item?.atributos?.ocorrencia_documento?.length ?? 0;

                return {
                    id_registro: item.id_registro,
                    totalRegistros: totalRegistros,
                    ultimaPagina: totalRegistros,
                    ocorrencia_documento: item.atributos.ocorrencia_documento?.map(ocorrenciaDocumento => {
                        return {
                            id_registro: ocorrenciaDocumento.id_registro,
                            id_ocorrencia: ocorrenciaDocumento.id_ocorrencia,
                            descricao: ocorrenciaDocumento.descricao,
                            observacao: ocorrenciaDocumento.observacao,
                            rodopar_data_chegada: ocorrenciaDocumento.rodopar_data_chegada,
                            rodopar_data_entrada: ocorrenciaDocumento.rodopar_data_entrada,
                            rodopar_data_saida: ocorrenciaDocumento.rodopar_data_saida
                        };
                    })
                };
            });
        },
        formataData(data) {
            return data ? moment(data).format('DD/MM/YYYY H:mm:ss') : null;
        }
    }
};
</script>
