<template>
    <v-chip class="ma-2" :color="selecionaCor" label :text-color="corTexto">
        <v-icon class="pr-1" start>mdi-timer-sync-outline</v-icon>
        <span>{{ titulo }} </span>
        {{ tempoExecucao }} (s)
    </v-chip>
</template>

<script>
import moment from 'moment';

export default {
    name: 'TempoExecucao',
    props: {
        inicia: {
            type: Boolean,
            default: () => false
        },
        titulo: {
            type: String,
            default: () => 'Próxima execução: '
        },
        minutos_execucao: {
            type: Number,
            default: () => 0
        },
        tempo_total: {
            type: Number,
            default: () => 0
        }
    },
    watch: {
        inicia: {
            immediate: true,
            handler(novoValor) {
                if (novoValor) {
                    this.intervaloExecucao = setInterval(this.incrementaTempo, 1000);
                } else {
                    clearInterval(this.intervaloExecucao);
                }
            }
        },
        minutos_execucao: {
            immediate: true,
            handler(novoValor) {
                this.minutosExecucao = novoValor;
            }
        }
    },
    computed: {
        tempoExecucao() {
            return moment.utc(this.minutosExecucao * 1000).format('mm:ss');
        },
        selecionaCor() {
            let cor = 'pink';
            this.corTexto = 'white';

            if (this.minutosExecucao <= 10) {
                const parImpar = this.minutosExecucao % 2 == 0 ? true : false;

                if (parImpar) {
                    cor = 'yellow';
                    this.corTexto = 'black';
                }

                if (!parImpar) {
                    cor = 'pink';
                }
            }

            return cor;
        }
    },
    methods: {
        incrementaTempo() {
            if (this.tempo_total > 0) {
                this.minutosExecucao = parseInt(this.minutosExecucao) - 1;

                if (this.minutosExecucao < 0) {
                    this.minutosExecucao = this.tempo_total;
                }

                return;
            }

            this.minutosExecucao = parseInt(this.minutosExecucao) + 1;
        }
    },
    data() {
        return {
            minutosExecucao: this.minutos_execucao,
            intervaloExecucao: null,
            corTexto: 'white'
        };
    }
};
</script>
