const SIM = 1;
const NAO = 2;

const cobrar = {
  NAO: NAO,
  SIM: SIM,
};

export default cobrar;


