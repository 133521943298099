<template>
    <div class="pa-2">
        <TituloModulo />

        <AlertaMensagem dense style="width: 100%" />

        <BarraNavegacaoBotaoV3
            v-if="!carregando.mostraCarregando && permissao"
            :botoes="botoesComPermissao(devolucaoVazioSituacao)"
        />

        <v-overlay :absolute="true" :value="carregando.mostraCarregando">
            <CarregandoLogoEmpresa :mensagem="mensagemCarregando" />
        </v-overlay>

        <v-card>
            <v-tabs v-model="tabComponente" background-color="orange accent-2" dense centered dark icons-and-text>
                <v-tabs-slider></v-tabs-slider>

                <v-tab>
                    <v-badge
                        :content="tabDadosTotalErros > 0 ? tabDadosTotalErros : ''"
                        :color="tabDadosTotalErros > 0 ? 'red accent-4' : ''"
                        overlap
                    >
                        <v-icon>mdi-file-sign</v-icon>
                        <v-spacer></v-spacer>
                        Dados
                    </v-badge>
                </v-tab>

                <v-tab>
                    <v-badge
                        :content="tabAnexoTotalErros > 0 ? tabAnexoTotalErros : ''"
                        :color="tabAnexoTotalErros > 0 ? 'red accent-4' : ''"
                        overlap
                    >
                        <v-icon>mdi-attachment-plus</v-icon>
                        <v-spacer></v-spacer>
                        Anexos
                    </v-badge>
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabComponente">
                <v-form ref="devolucaoVazioForm">
                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-text>
                                <FormDados ref="formDadosRef" :dadosData="form.dados" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-text>
                                <FormAnexos
                                    ref="formAnexosRef"
                                    :dadosAnexo="form.anexo"
                                    :idDevolucaoVazio="id_devolucao_vazio"
                                />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-form>
            </v-tabs-items>
        </v-card>

        <br />

        <BarraNavegacaoBotaoV3
            v-if="!carregando.mostraCarregando && permissao"
            :botoes="botoesComPermissao(devolucaoVazioSituacao)"
        />

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false"> Fechar </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { Alerta, PreencheDadosMesmaPropriedade, sleep } from '@/helpers/Funcoes.js';

import SituacaoForm from '@/Enum/DevolucaoVazio/SituacaoForm.js';

import TituloModulo from '@/components/Default/TituloModulo.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';

import FormDados from '@/views/DevolucaoVazio/FormDados.vue';
import FormAnexos from '@/views/DevolucaoVazio/FormAnexos.vue';

export default {
    name: 'Form',
    components: {
        AlertaMensagem,
        TituloModulo,
        CarregandoLogoEmpresa,
        BarraNavegacaoBotaoV3,
        FormDados,
        FormAnexos,
    },
    async created() {},
    async mounted() {
        this.mostraCarregando(true);

        this.id_devolucao_vazio = parseInt(this.$route.params.id_devolucao_vazio) ?? null;

        await this.buscaDados();

        this.mostraCarregando(false);
    },
    computed: {
        ...mapGetters(['botoesComPermissao']),
        ...mapState(['carregando']),
    },
    watch: {},
    methods: {
        ...mapActions(['montaNavegacao', 'mostraCarregando']),
        async buscaDados() {
            try {
                await this.buscaPermissoes();
                await this.buscaForm(this.id_devolucao_vazio);
            } catch (error) {
                Alerta([error.message], 'error');

                return false;
            }
        },
        async buscaPermissoes() {
            try {
                await ApiService({
                    method: 'get',
                    url: `${API_URL.erp.devolucaovazio.permissao}`,
                    data: {},
                    mostraCarregando: false,
                })
                    .then(async (resposta) => {
                        const permissoes = resposta.data.data.atributos;

                        await this.montaNavegacao({ botoes: this.botoes, permissoes });
                    })
                    .catch((error) => {
                        throw new Error(error);
                    });

                return true;
            } catch (error) {
                Alerta([error.message], 'error');

                return false;
            }
        },
        preencheDados(dadosForm) {
            this.devolucaoVazioSituacao = parseInt(dadosForm.situacao);

            dadosForm.id_devolucao_vazio = dadosForm.id_devolucao_vazio;
            dadosForm.parceiros = [];
            dadosForm.id_parceiro_tomador = dadosForm?.programacao_carga?.parceiro_tomador?.id_parceiro;
            dadosForm.id_programacao_carga_rodopar = dadosForm?.programacao_carga?.id_rodopar;
            dadosForm.numero_container = dadosForm?.programacao_carga?.numero_container;
            dadosForm.container_comprimento = dadosForm?.programacao_carga?.container_comprimento;
            dadosForm.container_tipo = dadosForm?.programacao_carga?.container_tipo;
            dadosForm.container_tara = dadosForm?.programacao_carga?.container_tara;
            dadosForm.data_pedido = dadosForm?.programacao_carga?.data_pedido;
            dadosForm.cobrar_cliente = dadosForm?.cobrar_cliente;
            dadosForm.data_retirada = dadosForm?.programacao_carga?.data_retirada;
            dadosForm.data_entrega = dadosForm?.programacao_carga?.data_entrega;
            dadosForm.programacao_carga_observacao = dadosForm?.programacao_carga?.observacao;
            dadosForm.programacao_carga_particularidade_cliente = dadosForm?.programacao_carga?.particularidade_cliente;
            dadosForm.observacao = dadosForm?.observacao;
            dadosForm.motoristas = [];

            if (dadosForm.id_parceiro_tomador) {
                dadosForm.parceiros.push({
                    id_parceiro: dadosForm.programacao_carga.parceiro_tomador.id_parceiro,
                    razao_social: dadosForm.programacao_carga.parceiro_tomador.razao_social,
                });
            }

            if (dadosForm?.parceiro_armador) {
                dadosForm.parceiros.push({
                    id_parceiro: dadosForm.parceiro_armador.id_parceiro,
                    razao_social: dadosForm.parceiro_armador.razao_social,
                });
            }

            if (dadosForm?.parceiro_depot) {
                dadosForm.parceiros.push({
                    id_parceiro: dadosForm.parceiro_depot.id_parceiro,
                    razao_social: dadosForm.parceiro_depot.razao_social,
                });
            }

            if (dadosForm?.motorista) {
                dadosForm.motoristas.push({
                    id_motorista: dadosForm.id_motorista,
                    nome: dadosForm.motorista.parceiro.nome,
                });
            }

            if (dadosForm?.motorista_terminal_entrega) {
                dadosForm.motoristas.push({
                    id_motorista: dadosForm.motorista_terminal_entrega.id_motorista,
                    nome: dadosForm.motorista_terminal_entrega.parceiro.nome,
                });
            }

            if (dadosForm?.placa_cavalo) {
                dadosForm.placa_cavalo = [
                    {
                        id_veiculo: dadosForm.placa_cavalo.id_veiculo,
                        placa: dadosForm.placa_cavalo.placa,
                    },
                ];
            }

            if (dadosForm?.cobrar_cliente) {
                dadosForm.cobrar_cliente = 2
            }

            if (dadosForm?.placa_carreta) {
                dadosForm.placa_carreta = [
                    {
                        id_veiculo: dadosForm.placa_carreta.id_veiculo,
                        placa: dadosForm.placa_carreta.placa,
                    },
                ];
            }

            const dados = PreencheDadosMesmaPropriedade(this.devolucao_vazio_template, dadosForm);
            const anexo = dadosForm.documento_anexo.map((anexo) => {
                return PreencheDadosMesmaPropriedade(this.devolucao_vazio_template.anexo, anexo);
            });

            this.form.dados = dados;
            this.form.anexo = anexo;
        },
        async buscaForm() {
            try {
                let urlConfig = {
                    method: 'GET',
                    url: `${API_URL.erp.devolucaovazio.busca}/${this.id_devolucao_vazio}`,
                    data: {},
                    mostraCarregando: false,
                };

                await ApiService(urlConfig)
                    .then(async (resposta) => {
                        resposta.data.data.atributos.id_devolucao_vazio = resposta.data.data.id_registro;

                        this.preencheDados(resposta.data.data.atributos);
                    })
                    .catch((erro) => {
                        Alerta([erro.message], 'error');
                    });

                this.permissao = true;
            } catch (erro) {
                Alerta([erro.message], 'error');
            }
        },
        async salva() {
            try {
                if (!this.validaFormularios()) {
                    return;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', 'Salvar ?', {
                        cor: 'green',
                    })
                    .then(async (confirma) => {
                        if (!confirma) {
                            return false;
                        }

                        let conteudoFormulario = this.FormataDadosParaEnviarForm();
                        let id_devolucao_vazio = this.form.dados.id_devolucao_vazio;

                        this.mensagemCarregando = 'Aguarde, salvando...';

                        this.mostraCarregando(true);

                        let urlConfig = {
                            method: 'POST',
                            url: `${API_URL.erp.devolucaovazio.salva}/${id_devolucao_vazio}`,
                            data: conteudoFormulario,
                            mostraCarregando: false,
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        };

                        await ApiService(urlConfig)
                            .then((resposta) => {
                                resposta.data.data.atributos.id_devolucao_vazio = resposta.data.data.id_registro;

                                this.preencheDados(resposta.data.data.atributos);

                                this.notificacao = {
                                    mensagem: `Devolução vazio ID ${resposta.data.data.id_registro} salvo.`,
                                    tipo: 'success',
                                    mostra: true,
                                };
                            })
                            .catch((erro) => {
                                throw new Error(erro.message);
                            });

                        this.mostraCarregando(false);
                    });
            } catch (erro) {
                this.mostraCarregando(false);

                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        async finaliza() {
            try {
                await this.$root.$refs.$confirmaAcaoModal
                    .open('Finalizar', 'Se o documento for finalizado, não poderá ser mais aberto, deseja continuar ?', {
                        cor: 'green',
                    })
                    .then(async (confirma) => {
                        if (!confirma) {
                            return false;
                        }

                        this.mensagemCarregando = 'Aguarde, finalizando...';

                        this.mostraCarregando(true);

                        let urlConfig = {
                            method: 'PATCH',
                            url: `${API_URL.erp.devolucaovazio.salva}/${this.form.dados.id_devolucao_vazio}/finaliza`,
                            data: {},
                            mostraCarregando: false,
                        };

                        await ApiService(urlConfig)
                            .then((resposta) => {
                                this.$router.push({
                                    name: 'devolucao-vazio',
                                    params: {
                                        mensagem: `Devolução do vazio ${this.form.dados.id_devolucao_vazio} finalizado.`,
                                    },
                                });
                            })
                            .catch((erro) => {
                                throw new Error(erro.message);
                            });

                        this.mostraCarregando(false);
                    });
            } catch (erro) {
                this.mostraCarregando(false);

                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        validaFormularios() {
            this.mensagensValidacao = [];
            this.tabDadosTotalErros = 0;
            this.tabAnexoTotalErros = 0;


            if (!this.$refs.formDadosRef.$refs.form.validate()) {
                this.mensagensValidacao.push('Dados contém campos inválidos');

                this.tabDadosTotalErros = 0;

                Object.values(this.$refs.formDadosRef.$refs.form.errorBag).map((item) => {
                    if (item) {
                        this.tabDadosTotalErros += 1;
                    }
                });
            }

            if (this.$refs.formDadosRef.form.parceiro_baixa && !this.$refs.formDadosRef.form.motivo_baixa) {
                this.tabDadosTotalErros += 1;

                throw new Error('Motivo da baixa é obrigatório quando selecionado parceiro baixa.');
            }

            if (this.$refs.formDadosRef.form.parceiro_baixa && !this.$refs.formDadosRef.form.data_parceiro_baixa) {
                this.tabDadosTotalErros += 1;

                throw new Error('Data parceiro baixa é obrigatório quando selecionado parceiro baixa.');
            }

            // if (!this.$refs.formDadosRef.form.motivo_baixa) {
            //     this.tabDadosTotalErros += 1;

            //     throw new Error('Motivo de baixa é obrigatório');
            // }

            // if (!this.$refs.formDadosRef.form.cobrar_cliente) {
            //     this.tabDadosTotalErros += 1;

            //     throw new Error('O campo Cobrar cliente foi preenchido incorretamente');
            // }

            if (!this.$refs.formAnexosRef.$refs.form.validate()) {
                this.tabAnexoTotalErros = 1;

                throw new Error('Anexos contém informações inválidas.');
            }

            if (this.mensagensValidacao.length) {
                Alerta(this.mensagensValidacao, 'error');

                return false;
            }

            return true;
        },
        FormataDadosParaEnviarForm() {
            let formData = new FormData();

            const anexos = this.$refs.formAnexosRef.preparaDados();

            anexos.arquivos.map((arquivo, index) => {
                formData.append('arquivos[][arquivo]', arquivo);
                formData.append('arquivos[][tipo_documento]', anexos.tipo_documento);
            });

            const dados = this.$refs.formDadosRef.preparaDados();

            formData.append('dados', JSON.stringify(dados));

            return formData;
        },
        fechaForm() {
            this.$router.push({ name: 'devolucao-vazio' });
        },
    },
    data() {
        return {
            permissao: false,
            permissoes: [],
            botoes: [
                {
                    id_permissao: [3],
                    nome: 'Salvar',
                    acao: 'salva',
                    icone: 'mdi-newspaper-variant-multiple',
                    cor: 'green',
                    form: [SituacaoForm.ABERTO, SituacaoForm.AGUARDANDO_DEVOLUCAO],
                },
                {
                    id_permissao: [32],
                    nome: 'Finalizar',
                    acao: 'finaliza',
                    icone: 'mdi-newspaper-variant-multiple',
                    cor: 'blue',
                    form: [SituacaoForm.ABERTO, SituacaoForm.AGUARDANDO_DEVOLUCAO],
                },
                {
                    id_permissao: [1],
                    nome: 'Fechar',
                    acao: 'fechaForm',
                    icone: 'mdi-file-cancel',
                    cor: 'orange',
                    form: ['todos'],
                },
            ],
            devolucaoVazioSituacao: null,
            mensagemCarregando: 'Aguarde, buscando devolução vazio...',
            tabDadosTotalErros: 0,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success',
            },
            id_devolucao_vazio: null,
            form: {
                dados: {
                    id_devolucao_vazio: null,
                    id_programacao_carga: null,
                    id_programacao_carga_rodopar: null,
                    id_parceiro_tomador: null,
                    id_parceiro_armador: null,
                    id_parceiro_depot: null,
                    id_motorista: null,
                    id_motorista_terminal_entrega: null,
                    id_placa_cavalo: null,
                    id_placa_carreta: null,
                    situacao: null,
                    data_vencimento_demurrage: null,
                    data_parceiro_baixa: null,
                    data_freetime: null,
                    data_devolucao: null,
                    parceiro_baixa: null,
                    motivo_baixa: null,
                    cobrar_cliente: 2,
                    numero_container: null,
                    container_comprimento: null,
                    container_tipo: null,
                    container_tara: null,
                    motoristas: [],
                    parceiros: [],
                    programacao_carga_observacao: null,
                    programacao_carga_particularidade_cliente: null,
                    observacao: null,
                },
            },
            tabComponente: 0,
            tabAnexoTotalErros: 0,
            tabAnexosTotalErros: 0,
            devolucao_vazio_template: {
                id_devolucao_vazio: null,
                id_programacao_carga: null,
                id_programacao_carga_rodopar: null,
                id_motorista: null,
                id_motorista_terminal_entrega: null,
                id_placa_cavalo: null,
                id_placa_carreta: null,
                id_parceiro_tomador: null,
                id_parceiro_armador: null,
                id_parceiro_depot: null,
                data_vencimento_demurrage: null,
                data_parceiro_baixa: null,
                data_freetime: null,
                data_devolucao: null,
                parceiro_baixa: null,
                motivo_baixa: null,
                cobrar_cliente: null,
                numero_container: null,
                container_comprimento: null,
                container_tipo: null,
                container_tara: null,
                situacao: null,
                motoristas: [],
                parceiros: [],
                placa_cavalo: [],
                placa_carreta: [],
                data_pedido: null,
                data_retirada: null,
                data_entrega: null,
                programacao_carga_observacao: null,
                programacao_carga_particularidade_cliente: null,
                observacao: null,
                anexo: {
                    id_documento_anexo: null,
                    caminho: null,
                    nome: null,
                    tipo_documento: null,
                },
            },
        };
    },
};
</script>
