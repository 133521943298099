<template>
    <v-form ref="form">
        <v-row>
            <v-col cols="12" sm="2">
                <InputTextV3 v-model="form.id_whatsapp_departamento" rotulo="ID" :contador="100" desativado clearable />
            </v-col>

            <v-col cols="12" sm="10">
                <InputTextV3 v-model="form.nome" rotulo="Nome" :contador="100" :regra="regrasNome" clearable />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { Obrigatorio, Maximo, Minimo } from '@/helpers/ValidadorCampo.js';

import InputTextV3 from '@/components/Form/InputTextV3.vue';

import FormAtendenteDepartamento from './FormAtendenteDepartamento.vue';

export default {
    name: 'FormDados',
    components: {
        InputTextV3,
        FormAtendenteDepartamento
    },
    props: {
        id_whatsapp_departamento: {
            type: Number,
            default: () => null
        },
        nome: {
            type: String,
            default: () => ''
        }
    },
    watch: {
        id_whatsapp_departamento: {
            deep: true,
            handler(novoValor) {
                this.form.id_whatsapp_departamento = novoValor;
            }
        },
        nome: {
            deep: true,
            handler(novoValor) {
                this.form.nome = novoValor;
            }
        }
    },
    data() {
        return {
            form: {
                id_whatsapp_departamento: null,
                nome: null
            },
            regrasNome: [Obrigatorio(), Maximo(100), Minimo(2)]
        };
    }
};
</script>
