<template>
    <div>
        <TituloModulo />

        <AlertaMensagem dense style="width: 100%" />

        <v-card outlined elevation="2" class="mb-4">
            <v-card-title>
                Filtros:

                <FiltroData v-model="filtros.data_de" />
                <FiltroData v-model="filtros.data_ate" />

                <v-btn :loading="overlay" elevation="2" color="warning" @click="buscaPEL()">
                    Buscar
                </v-btn>

                <v-chip class="ma-2" color="pink" label text-color="white">
                    <v-icon class="pr-1" start>mdi-timer-sync-outline</v-icon>
                    <span v-if="overlay">Tempo execução: </span>
                    <span v-else>Executado em: </span>
                    {{ tempoExecucao }} (s)
                </v-chip>
            </v-card-title>

            <v-card-title>
                Agrupar por:

                <v-chip
                    class="ma-2"
                    :color="filtros.agrupamento_padrao.grupo_cliente ? 'green lighten-1' : ''"
                    :text-color="filtros.agrupamento_padrao.grupo_cliente ? 'white' : ''"
                    @click="agrupamentoAtalho('grupo_cliente')"
                >
                    Grupo Cliente e Operação

                    <v-icon small right color="green darken-4" v-if="filtros.agrupamento_padrao.grupo_cliente">
                        mdi-arrange-bring-forward
                    </v-icon>
                    <v-icon small right v-else>
                        mdi-arrange-bring-to-front
                    </v-icon>
                </v-chip>

                <v-chip
                    class="ma-2"
                    :color="filtros.agrupamento_padrao.rota ? 'green lighten-1' : ''"
                    :text-color="filtros.agrupamento_padrao.rota ? 'white' : ''"
                    @click="agrupamentoAtalho('rota')"
                >
                    Rota

                    <v-icon small right color="green darken-4" v-if="filtros.agrupamento_padrao.rota">
                        mdi-arrange-bring-forward
                    </v-icon>
                    <v-icon small right v-else>
                        mdi-arrange-bring-to-front
                    </v-icon>
                </v-chip>

                <v-chip
                    class="ma-2"
                    :color="filtros.agrupamento_padrao.rota_frota ? 'green lighten-1' : ''"
                    :text-color="filtros.agrupamento_padrao.rota_frota ? 'white' : ''"
                    @click="agrupamentoAtalho('rota_frota')"
                >
                    Rota e Frota

                    <v-icon small right color="green darken-4" v-if="filtros.agrupamento_padrao.rota_frota">
                        mdi-arrange-bring-forward
                    </v-icon>
                    <v-icon small right v-else>
                        mdi-arrange-bring-to-front
                    </v-icon>
                </v-chip>

                <v-chip
                    class="ma-2"
                    :color="filtros.agrupamento_padrao.vendedor ? 'green lighten-1' : ''"
                    :text-color="filtros.agrupamento_padrao.vendedor ? 'white' : ''"
                    @click="agrupamentoAtalho('vendedor')"
                >
                    Vendedor

                    <v-icon small right color="green darken-4" v-if="filtros.agrupamento_padrao.vendedor">
                        mdi-arrange-bring-forward
                    </v-icon>
                    <v-icon small right v-else>
                        mdi-arrange-bring-to-front
                    </v-icon>
                </v-chip>

                <v-chip
                    class="ma-2"
                    :color="filtros.agrupamento_padrao.unidade_empreendimento ? 'green lighten-1' : ''"
                    :text-color="filtros.agrupamento_padrao.unidade_empreendimento ? 'white' : ''"
                    @click="agrupamentoAtalho('unidade_empreendimento')"
                    atalhoUnidadeEmpreendimento
                >
                    Unidade

                    <v-icon small right color="green darken-4" v-if="filtros.agrupamento_padrao.unidade_empreendimento">
                        mdi-arrange-bring-forward
                    </v-icon>
                    <v-icon small right v-else>
                        mdi-arrange-bring-to-front
                    </v-icon>
                </v-chip>

                <v-chip
                    class="ma-2"
                    :color="filtros.agrupamento_padrao.sem_grupo ? 'green lighten-1' : ''"
                    :text-color="filtros.agrupamento_padrao.sem_grupo ? 'white' : ''"
                    @click="agrupamentoAtalho('sem_grupo')"
                >
                    Sem Grupo

                    <v-icon small right color="green darken-4" v-if="filtros.agrupamento_padrao.sem_grupo">
                        mdi-arrange-bring-forward
                    </v-icon>
                    <v-icon small right v-else>
                        mdi-arrange-bring-to-front
                    </v-icon>
                </v-chip>
            </v-card-title>

            <v-card-title>
                Atalhos da tabela:

                <v-btn color="red lighten-1" class="ma-2 white--text" @click="limparFiltros()">
                    Limpa todos filtros
                    <v-icon right dark>
                        mdi-filter-off
                    </v-icon>
                </v-btn>

                <v-btn color="deep-purple lighten-1" class="ma-2 white--text" @click="fecharGrupos()">
                    Fechar todos grupos
                    <v-icon right dark>
                        mdi-unfold-less-horizontal
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text dense>
                <ag-grid-vue
                    @grid-ready="onGridReady"
                    :rowData="rowData"
                    :localeText="AgGridTraducao"
                    :columnDefs="columnDefs"
                    :columnTypes="columnTypes"
                    :columnHoverHighlight="true"
                    :defaultColDef="defaultColDef"
                    :autoGroupColumnDef="autoGroupColumnDef"
                    :sideBar="sideBar"
                    :rowSelection="rowSelection"
                    :statusBar="statusBar"
                    :aggFuncs="aggFuncs"
                    :animateRows="true"
                    :enableRangeSelection="true"
                    :groupRowsSticky="true"
                    rowGroupPanelShow="always"
                    class="ag-theme-alpine"
                    style="width: 100%; height: 650px;"
                ></ag-grid-vue>
            </v-card-text>
        </v-card>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :color="notificacao.tipo"
                :multi-line="true"
                elevation="10"
                timeout="3000"
                text
                right
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { FormataMoeda } from '@/helpers/Funcoes.js';

import moment from 'moment';

import { AG_GRID_LOCALE_PT_BR } from '@/plugins/AgGrid/Traducao.js';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';

import { Alerta } from '@/helpers/Funcoes.js';

import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';
import CarregandoOverlay from '@/components/Default/CarregandoOverlay.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';
import InputTextV3 from '@/components/Form/InputTextV3.vue';
import InputAutoCompleteV3 from '@/components/Form/InputAutoCompleteV3.vue';
import DateTimePickerV2 from '@/components/Form/DateTimePickerV2.vue';
import ComboBoxPreenchimentoV3 from '@/components/Form/ComboBoxPreenchimentoV3.vue';

import FiltroData from '@/components/Filtro/DataV1.vue';

export default {
    name: 'ProfitLessStatement',
    components: {
        AlertaMensagem,
        AgGridVue,
        CarregandoOverlay,
        TituloModulo,
        InputSelectV3,
        InputTextV3,
        DateTimePickerV2,
        ComboBoxPreenchimentoV3,
        InputAutoCompleteV3,
        FiltroData
    },
    mounted() {},
    created() {
        this.AgGridTraducao = AG_GRID_LOCALE_PT_BR;
        this.autoGroupColumnDef = {
            minWidth: 250,
            pinned: 'left'
        };
        this.rowSelection = 'multiple';
        this.statusBar = {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agTotalRowCountComponent', align: 'center' },
                { statusPanel: 'agFilteredRowCountComponent' },
                { statusPanel: 'agSelectedRowCountComponent' },
                { statusPanel: 'agAggregationComponent' }
            ]
        };

        this.sideBar = {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel'
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel'
                }
            ],
            defaultToolPanel: 'filters',
            hiddenByDefault: false
        };

        this.columnTypes = {
            numberValue: {
                enableValue: true,
                aggFunc: 'sum',
                editable: true,
                valueParser: this.numberParser
            },
            dimension: {
                enableRowGroup: true,
                enablePivot: true
            }
        };

        this.aggFuncs = {};
    },
    watch: {
        overlay: function(dataAtual) {
            if (!dataAtual) {
                clearInterval(this.intervaloExecucao);
            } else {
                this.minutosExecucao = 0;

                this.intervaloExecucao = setInterval(this.incrementaTempo, 1000);
            }
        }
    },
    computed: {
        tempoExecucao() {
            return moment.utc(this.minutosExecucao * 1000).format('mm:ss');
        }
    },
    methods: {
        async onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            this.gridApi.closeToolPanel();

            await this.buscaPEL();
        },
        async buscaPEL() {
            try {
                if (!this.validaFiltros()) {
                    return;
                }

                this.overlay = true;
                this.gridApi.setRowData([]);
                this.gridApi.showLoadingOverlay();

                const parametros = {
                    data_de: this.filtros.data_de,
                    data_ate: this.filtros.data_ate
                };

                await ApiService({
                    method: 'GET',
                    url: API_URL.erp.profitLess.statement,
                    mostraCarregando: false,
                    params: parametros
                })
                    .then(resposta => {
                        if (resposta.data.data) {
                            this.gridApi.setRowData(resposta.data.data);
                        }

                        this.notificacao = {
                            mensagem: 'Tabela atualizada',
                            tipo: 'warning',
                            mostra: true
                        };
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });

                this.permissao = true;
            } catch (erro) {
                Alerta([erro.message], 'error');

                this.permissao = false;

                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.overlay = false;
            }
        },
        validaFiltros() {
            try {
                if (!this.filtros.data_de) {
                    throw new Error('Campo <strong>Data de</strong> é obrigatório');
                }

                if (!this.filtros.data_ate) {
                    throw new Error('Campo <strong>Data até</strong> é obrigatório');
                }

                return true;
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'red',
                    mostra: true
                };
            }
        },
        formataMoeda(params) {
            if (!params.value) {
                return 0;
            }

            return FormataMoeda(params.value, {}, true);
        },
        formataPercentual(params) {
            if (!params.value || params.value === 0) {
                return this.mascaraPercentual(0);
            }

            return this.mascaraPercentual(Math.round(params.value * 100));
        },
        mascaraPercentual(valor) {
            return `${valor} %`;
        },
        numberParser(params) {
            return parseFloat(params.newValue);
        },
        percentualGmCor(params) {
            let gmPercentual = params.node.group
                ? params.node.aggData.gm_valor / params.node.aggData.frete_total
                : params.data.gm_percentual;

            if (gmPercentual > 0.45) {
                return;
            }

            return {
                backgroundColor: '#f4a460'
            };
        },
        desativaFiltroAgrupamento() {
            Object.keys(this.filtros.agrupamento_padrao).map((item, key) => {
                this.filtros.agrupamento_padrao[item] = false;
            });
        },
        limpaGruposColunas() {
            this.columnDefs = this.columnDefs.map(item => {
                item.rowGroup = false;
                item.hide = false;

                return item;
            });
        },
        habilitaColuna(colunas) {
            this.columnDefs = this.columnDefs.map(item => {
                const coluna = colunas.find(coluna => coluna === item.field);

                item.hide = true;

                if (coluna) {
                    item.hide = false;
                }

                return item;
            });
        },
        habilitaGrupo(grupos) {
            this.columnDefs = this.columnDefs.map(item => {
                const grupo = grupos.find(grupo => grupo === item.field);

                item.rowGroup = false;

                if (grupo) {
                    item.rowGroup = true;
                }

                return item;
            });
        },
        agrupamentoAtalho(atalho) {
            this.desativaFiltroAgrupamento();

            this.filtros.agrupamento_padrao[atalho] = true;

            const funcao = ['atalho']
                .concat(
                    atalho.split('_').map(item => {
                        return `${item[0].toUpperCase()}${item.substring(1)}`;
                    })
                )
                .join('');

            this[funcao]();
        },
        atalhoGrupoCliente() {
            this.limpaGruposColunas();
            this.habilitaColuna([
                'custo_adicional',
                'frete_total',
                'icms_iss_valor',
                'pis_valor',
                'cofins_valor',
                'pedagio_valor',
                'cogs_valor',
                'gm_valor',
                'gm_percentual'
            ]);
            this.habilitaGrupo(['grupo_cliente', 'operacao']);

            this.gridApi.setColumnDefs(this.columnDefs);
        },
        atalhoRota() {
            this.limpaGruposColunas();
            this.habilitaColuna([
                'custo_adicional',
                'frete_total',
                'icms_iss_valor',
                'pis_valor',
                'cofins_valor',
                'pedagio_valor',
                'cogs_valor',
                'gm_valor',
                'gm_percentual'
            ]);
            this.habilitaGrupo(['rota']);

            this.gridApi.setColumnDefs(this.columnDefs);
        },
        atalhoRotaFrota() {
            this.limpaGruposColunas();
            this.habilitaColuna([
                'custo_adicional',
                'frete_total',
                'icms_iss_valor',
                'pis_valor',
                'cofins_valor',
                'pedagio_valor',
                'cogs_valor',
                'gm_valor',
                'gm_percentual'
            ]);
            this.habilitaGrupo(['rota', 'frota']);

            this.gridApi.setColumnDefs(this.columnDefs);
        },
        atalhoVendedor() {
            this.limpaGruposColunas();
            this.habilitaColuna([
                'custo_adicional',
                'frete_total',
                'icms_iss_valor',
                'pis_valor',
                'cofins_valor',
                'cogs_valor',
                'pedagio_valor',
                'gm_valor',
                'gm_percentual'
            ]);
            this.habilitaGrupo(['vendedor']);

            this.gridApi.setColumnDefs(this.columnDefs);
        },
        atalhoUnidadeEmpreendimento() {
            this.limpaGruposColunas();
            this.habilitaColuna([
                'custo_adicional',
                'frete_total',
                'icms_iss_valor',
                'pis_valor',
                'cofins_valor',
                'cogs_valor',
                'pedagio_valor',
                'gm_valor',
                'gm_percentual'
            ]);
            this.habilitaGrupo(['unidade_empreendimento']);

            this.gridApi.setColumnDefs(this.columnDefs);
        },
        atalhoSemGrupo() {
            this.limpaGruposColunas();

            this.gridApi.setColumnDefs(this.columnDefs);
        },
        toggleTempoExecucao() {
            if (this.overlay) {
                clearInterval(this.intervaloExecucao);
            } else {
                this.intervaloExecucao = setInterval(this.incrementaTempo, 1000);
            }
        },
        incrementaTempo() {
            this.minutosExecucao = parseInt(this.minutosExecucao) + 1;
        },
        limparFiltros() {
            this.gridApi.setFilterModel(null);

            this.notificacao = {
                mensagem: 'Sem filtros',
                tipo: 'warning',
                mostra: true
            };
        },
        fecharGrupos() {
            this.gridApi.collapseAll();

            this.notificacao = {
                mensagem: 'Grupos fechados',
                tipo: 'warning',
                mostra: true
            };
        }
    },
    data() {
        return {
            permissao: false,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            overlay: false,
            filtros: {
                agrupamento_padrao: {
                    grupo_cliente: true,
                    rota: false,
                    rota_frota: false,
                    vendedor: false,
                    unidade_empreendimento: false,
                    sem_grupo: false
                },
                data_de: moment()
                    .startOf('year')
                    .format('YYYY-MM-DD'),
                data_ate: moment().format('YYYY-MM-DD')
            },
            columnDefs: [
                {
                    field: 'grupo_cliente',
                    headerName: 'GRUPO CLIENTE',
                    rowGroup: true,
                    hide: true,
                    minWidth: 170,
                    filter: 'agSetColumnFilter'
                },
                {
                    field: 'operacao',
                    headerName: 'OPERAÇÃO',
                    rowGroup: true,
                    hide: true,
                    minWidth: 500,
                    filter: 'agSetColumnFilter'
                },
                { field: 'placa', headerName: 'PLACA', hide: true, minWidth: 150, filter: 'agSetColumnFilter' },
                {
                    field: 'cidade_origem',
                    headerName: 'CIDADE ORIGEM',
                    hide: true,
                    minWidth: 150,
                    filter: 'agSetColumnFilter'
                },
                {
                    field: 'cidade_destino',
                    headerName: 'CIDADE DESTINO',
                    hide: true,
                    minWidth: 150,
                    filter: 'agSetColumnFilter'
                },
                {
                    field: 'rota',
                    headerName: 'ROTA',
                    hide: true,
                    minWidth: 150,
                    filter: 'agSetColumnFilter',
                    valueGetter: params => {
                        if (!params.node.group) {
                            return `${params.data.cidade_origem} x ${params.data.cidade_destino}`;
                        }
                    }
                },
                {
                    field: 'frota',
                    headerName: 'FROTA',
                    hide: true,
                    minWidth: 150,
                    filter: 'agSetColumnFilter',
                    valueGetter: params => {
                        if (!params.node.group) {
                            switch (params.data.veiculo_tipo_vinculo) {
                                case 'T':
                                    return 'TERCEIRO';
                                case 'C':
                                    return 'FROTA';
                                case 'A':
                                    return 'FROTA';
                                case 'M':
                                    return 'FROTA';
                                case 'L':
                                    return 'FROTA';
                                default:
                                    return 'NÃO DEFINIDO';
                            }
                        }
                    }
                },
                {
                    field: 'vendedor',
                    headerName: 'VENDEDOR',
                    hide: true,
                    minWidth: 150,
                    filter: 'agSetColumnFilter',
                    valueGetter: params => {
                        if (!params.node.group) {
                            return `${params.data.vendedor}`;
                        }
                    }
                },
                {
                    field: 'km_maior',
                    headerName: 'KM MAIOR',
                    hide: true,
                    minWidth: 150,
                    filter: 'agSetColumnFilter'
                },
                {
                    field: 'km_total',
                    headerName: 'KM TOTAL',
                    hide: true,
                    minWidth: 150,
                    filter: 'agSetColumnFilter'
                },
                {
                    field: 'unidade_empreendimento',
                    headerName: 'UNIDADE',
                    hide: true,
                    minWidth: 100,
                    filter: 'agSetColumnFilter'
                },
                {
                    field: 'tipo_cte',
                    headerName: 'TIPO CT-E',
                    hide: true,
                    minWidth: 100,
                    filter: 'agSetColumnFilter'
                },
                {
                    field: 'codigo',
                    headerName: 'CT-E',
                    rowGroup: false,
                    hide: true,
                    minWidth: 150,
                    filter: 'agSetColumnFilter'
                },
                {
                    field: 'manifesto',
                    headerName: 'MANIFESTO',
                    rowGroup: false,
                    hide: true,
                    minWidth: 150,
                    filter: 'agSetColumnFilter'
                },
                {
                    field: 'custo_por_km',
                    headerName: 'CUSTO POR KM',
                    hide: true,
                    type: 'numberValue',
                    valueFormatter: this.formataMoeda,
                    filter: 'agNumberColumnFilter'
                },
                {
                    field: 'frete_total',
                    headerName: 'FRETE TOTAL',
                    aggFunc: 'sum',
                    type: 'numberValue',
                    valueFormatter: this.formataMoeda,
                    filter: 'agNumberColumnFilter'
                },
                { field: 'icms_iss_valor', headerName: 'ICMS / ISS', aggFunc: 'sum', valueFormatter: this.formataMoeda },
                {
                    field: 'pis_valor',
                    headerName: 'PIS',
                    aggFunc: 'sum',
                    valueFormatter: this.formataMoeda,
                    filter: 'agNumberColumnFilter'
                },
                {
                    field: 'cofins_valor',
                    headerName: 'COFINS',
                    aggFunc: 'sum',
                    valueFormatter: this.formataMoeda,
                    filter: 'agNumberColumnFilter'
                },
                {
                    field: 'pedagio_valor',
                    headerName: 'VLR. PEDÁGIO',
                    aggFunc: 'sum',
                    valueFormatter: this.formataMoeda,
                    minWidth: 190,
                    filter: 'agNumberColumnFilter'
                },
                {
                    field: 'cogs_valor',
                    headerName: 'COGs FRETE',
                    aggFunc: 'sum',
                    valueFormatter: this.formataMoeda,
                    filter: 'agNumberColumnFilter'
                },
                {
                    field: 'custo_adicional',
                    headerName: 'INSS EMPRESA',
                    hide: false,
                    type: 'numberValue',
                    valueFormatter: this.formataMoeda,
                    filter: 'agNumberColumnFilter'
                },
                {
                    field: 'gm_valor',
                    headerName: 'VLR. GM',
                    aggFunc: 'sum',
                    valueFormatter: this.formataMoeda,
                    minWidth: 150,
                    filter: 'agNumberColumnFilter'
                },
                {
                    field: 'gm_percentual',
                    headerName: '% GM',
                    valueFormatter: this.formataPercentual,
                    cellStyle: this.percentualGmCor,
                    valueGetter: params => {
                        let gm_valor = params.node.group ? params.node.aggData.gm_valor : params.data.gm_valor;
                        let frete_total = params.node.group ? params.node.aggData.frete_total : params.data.frete_total;

                        return gm_valor / frete_total;
                    }
                },
                {
                    field: 'fator_percentual',
                    headerName: 'FATOR PERCENTUAL',
                    hide: true,
                    valueFormatter: params => {
                        if (params.node.group) {
                            params.value = params.node.aggData.peso_kg / params.node.aggData.peso_total;

                            return this.formataPercentual(params);
                        }

                        return this.mascaraPercentual(params.value);
                    }
                },
                {
                    field: 'peso_kg',
                    headerName: 'PESO KG',
                    hide: true,
                    aggFunc: 'sum'
                },
                {
                    field: 'peso_total',
                    headerName: 'PESO TOTAL',
                    hide: true,
                    aggFunc: 'sum'
                }
            ],
            gridApi: null,
            rowData: null,
            columnApi: null,
            defaultColDef: {
                flex: 1,
                minWidth: 180,
                resizable: true,
                enableValue: true,
                enableRowGroup: true,
                enablePivot: true,
                sortable: true,
                floatingFilter: false
            },
            autoGroupColumnDef: null,
            rowSelection: null,
            statusBar: null,
            sideBar: null,
            columnTypes: null,
            aggFuncs: null,
            AgGridTraducao: null,
            minutosExecucao: 0,
            intervaloExecucao: null
        };
    }
};
</script>

<style>
.linha-amarela {
    background-color: sandybrown !important;
}

.ag-theme-alpine {
    /* bright green, 10% opacity */
    --ag-selected-row-background-color: rgb(0, 255, 0, 0.1);
    --ag-range-selection-border-color: rgb(193, 0, 97);
    --ag-range-selection-border-style: dashed;
    --ag-range-selection-background-color: rgb(255, 0, 128, 0.1);
    /* these next 3 variables control the background colour when 2, 3 or 4+ ranges overlap,
       and should have progressively greater opacity to look realistic - see the docs below
       for the correct formulas to use */
    --ag-range-selection-background-color-2: rgb(255, 0, 128, 0.19);
    --ag-range-selection-background-color-3: rgb(255, 0, 128, 0.27);
    --ag-range-selection-background-color-4: rgb(255, 0, 128, 0.34);

    --ag-range-selection-highlight-color: rgb(60, 188, 0, 0.3);
}
</style>
