<template>
    <v-form ref="form">
        <v-card-text>
            <v-row>
                <v-col cols="2" sm="2">
                    <v-text-field
                        dense
                        v-model="atestado.id_colaborador_atestado"
                        label="ID Atestado"
                        data-vv-delay="1000"
                        disabled
                        outlined
                    />
                </v-col>
                <v-col cols="2" sm="5">
                    <v-select
                        dense
                        v-model="atestado.id_atestado_motivo"
                        :items="itemsAtestadoMotivo"
                        item-text="label"
                        item-value="value"
                        placeholder="Motivo"
                        clearable
                        :rules="[(v) => !!v || 'Motivo é obrigatório']"
                        outlined
                    />
                </v-col>
                <v-col cols="2" sm="5">
                    <InputAutoCompleteV3
                        v-model="atestado.id_atestado_cid"
                        :datatableConfiguracao="
                            autoCompleta.atestado_cid.datatable
                        "
                        tituloBusca="Atestado CID Busca..."
                        :itemsOpcoes="itemsAtestadoCID"
                        itemValor="id_atestado_cid"
                        itemTexto="descricao"
                        :regras="[(v) => !!v || 'CID é obrigatório']"
                    />
                </v-col>
                <v-col cols="10" sm="6">
                    <DateTimePickerV2
                        v-model="atestado.data_afastamento_inicio"
                        :dataInicial="atestado.data_afastamento_inicio"
                        label="Data inicial do afastamento"
                        data-vv-delay="1000"
                        outlined
                    />
                </v-col>
                <v-col cols="10" sm="6">
                    <DateTimePickerV2
                        v-model="atestado.data_afastamento_fim"
                        label="Data final do afastamento"
                        :dataInicial="atestado.data_afastamento_fim"
                        data-vv-delay="1000"
                        outlined
                    />
                </v-col>
                <v-col cols="12" sm="12">
                    <v-textarea
                        v-model="atestado.observacao"
                        label="Observação"
                        data-vv-delay="1000"
                        :rules="regraObservacao"
                        clearable
                        counter="500"
                        outlined
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-form>
</template>

<script>
import { API_URL, API_URL_BASE } from '@/config/configs';
import InputAutoCompleteV3 from '@/components/Form/InputAutoCompleteV3.vue';
import DateTimePickerV2 from '@/components/Form/DateTimePickerV2';
import moment from 'moment';

export default {
    components: {
        InputAutoCompleteV3,
        DateTimePickerV2,
    },

    name: 'AtestadoForm',

    props: {
        atestadoData: {
            type: Object,
            required: true,
            default() {
                return {};
            },
        },
        itemsAtestadoCID: {
            type: Array,
            default() {
                return [];
            },
        },
        itemsAtestadoMotivo: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    created() {
        if (!_.isEmpty(this.atestadoData)) {
            this.preencheDados(this.atestadoData);
        }
    },

    data() {
        return {
            regraObservacao: [
                (v) =>
                    _.isEmpty(v) ||
                    v.length <= 500 ||
                    'Observação deve ter até 500 caracteres',
            ],

            atestado: {
                id_colaborador_atestado: '',
                id_atestado_motivo: '',
                id_atestado_cid: '',
                data_afastamento_inicio: '',
                data_afastamento_fim: '',
                observacao: '',
            },

            autoCompleta: {
                atestado_cid: {
                    datatable: {
                        id_datatable: 'atestado_cid_busca',
                        chave_primaria: 'atestado_cid_coleta',
                        url: `${API_URL_BASE}${API_URL.erp.atestadocid.datatable}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.atestadocid.autoCompleta}`,
                        colunas: [
                            {
                                id: 'id_atestado_cid',
                                nome: 'id_atestado_cid',
                                descricao: 'ID',
                            },
                            {
                                id: 'descricao',
                                nome: 'descricao',
                                descricao: 'Descrição',
                            },
                        ],
                    },
                },
            },
        };
    },

    methods: {
        preparaDados() {
            const atestado = { ...this.atestado };

            return atestado;
        },

        preencheDados(atestado) {
            this.atestado = { ...atestado };

            this.atestado.data_afastamento_fim = atestado.data_afastamento_fim
                ? moment(atestado.data_afastamento_fim).format('YYYY-MM-DD')
                : null;

            this.atestado.data_afastamento_inicio =
                atestado.data_afastamento_inicio
                    ? moment(atestado.data_afastamento_inicio).format(
                          'YYYY-MM-DD'
                      )
                    : null;
        },

        limpaDados() {
            this.atestado = {
                id_colaborador_atestado: '',
                id_atestado_motivo: '',
                id_atestado_cid: '',
            };
        },
    },
};
</script>

<style scoped></style>
