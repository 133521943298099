<template>
    <div>
        <TituloModulo />

        <AlertaMensagem dense style="width: 100%" />

        <CarregandoLogoEmpresa :mensagem="mensagemCarregando" />

        <BarraNavegacaoBotaoV3 v-if="!carregando.mostraCarregando && permissao" :botoes="botoesComPermissao('grid')" />

        <v-row v-if="!carregando.mostraCarregando && permissao">
            <v-col cols="12" class="text-right">
                <TabelaVuetify
                    ref="tabela"
                    :cabecalho="datatable.cabecalho"
                    :itens="datatable.itens"
                    :total_registros="datatable.totalRegistros"
                    :por_pagina="datatable.por_pagina"
                    :ultima_pagina="datatable.ultima_pagina"
                    :carregando="datatable.carregando"
                    :chave_primaria="datatable.chave_primaria"
                    :ordenar_por="datatable.ordenarPor"
                    :ordenar_direcao="datatable.ordenarDirecao"
                    :mostra_selecao="false"
                    @opcoes="buscaAvaliacaoDesempenhoItens"
                >
                    <template v-slot:item.acao="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    v-if="item.id_situacao == situacaoEsperarFeedback"
                                    class="mr-2"
                                    @click="respondeFeedback(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-account-switch
                                </v-icon>

                                <v-icon v-else class="mr-2" @click="responde(item)">
                                    mdi-send-clock
                                </v-icon>
                            </template>
                            <span v-if="item.id_situacao == situacaoEsperarFeedback"
                                >Reunião de Feedback, apenas o gestor tem a ação de preenchimento.</span
                            >
                        </v-tooltip>
                    </template>

                    <template v-slot:item.responder_como="{ item }">
                        <v-chip
                            v-if="id_usuario == item.id_usuario_colaborador"
                            class="ma-2"
                            color="pink darken-1"
                            text-color="white"
                            small
                        >
                            <v-icon small left>
                                mdi-account
                            </v-icon>
                            Colaborador
                        </v-chip>

                        <v-chip
                            v-if="id_usuario == item.id_usuario_gestor"
                            class="ma-2"
                            color="indigo darken-1"
                            text-color="white"
                            small
                        >
                            <v-icon small left>
                                mdi-account-multiple
                            </v-icon>
                            Gestor
                        </v-chip>
                    </template>

                    <template v-slot:item.situacao="{ item }">
                        <v-chip small class="ma-2" color="orange darken-1" text-color="white">
                            <v-icon small left>
                                mdi-alert-circle-outline
                            </v-icon>
                            {{ item.situacao }}
                        </v-chip>
                    </template>
                </TabelaVuetify>
            </v-col>
        </v-row>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { Alerta } from '@/helpers/Funcoes.js';

import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';

import SituacaoAvaliadoEnum from '@/Enum/AvaliacaoDesempenho/SituacaoAvaliadoEnum.js';
import SituacaoForm from '@/Enum/AvaliacaoDesempenho/SituacaoForm.js';

import TituloModulo from '@/components/Default/TituloModulo.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import TabelaVuetify from '@/components/Default/TabelaVuetify.vue';

export default {
    name: 'AvaliacaoDesempenhoResposta',
    components: {
        AlertaMensagem,
        BarraNavegacaoBotaoV3,
        CarregandoLogoEmpresa,
        TituloModulo,
        TabelaVuetify
    },
    async mounted() {
        const permissoes = [
            {
                id_permissao: 6,
                permitido: 1
            }
        ];

        await this.montaNavegacao({ botoes: this.botoes, permissoes });
        await this.dadosPaginaInicial();

        if (this.$route.params.mensagem) {
            Alerta([this.$route.params.mensagem], 'success');
        }
    },
    computed: {
        ...mapGetters({
            id_usuario: 'autenticacao/getIdUsuario',
            botoesComPermissao: 'botoesComPermissao'
        }),
        ...mapState(['carregando'])
    },
    watch: {},
    methods: {
        ...mapActions(['montaNavegacao', 'mostraCarregando']),
        async dadosPaginaInicial() {
            this.permissao = true;
        },
        async buscaAvaliacaoDesempenhoItens() {
            this.datatable.carregando = true;

            const query = this.$refs.tabela.queryFiltros();

            await ApiService({
                method: 'get',
                url: `${API_URL.erp.avaliacaodesempenho.datatableResposta}/?${query}`,
                data: {},
                mostraCarregando: false
            })
                .then(resposta => {
                    this.datatable.itens = resposta.data.conteudo.datatable.itens;

                    this.datatable.totalRegistros = resposta.data.conteudo.datatable.total;
                    this.datatable.ultima_pagina = resposta.data.conteudo.datatable.ultima_pagina;
                    this.datatable.carregando = false;
                })
                .catch(erro => {
                    this.datatable.carregando = false;

                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        },
        responde(item) {
            this.$router.push({
                name: 'avaliacao-desempenho-resposta-form',
                params: { id_avaliacao_desempenho_avaliado: item.id_avaliacao_desempenho_avaliados }
            });
        },
        respondeFeedback(item) {
            this.$router.push({
                name: 'avaliacao-desempenho-resposta-feedback',
                params: { id_avaliacao_desempenho_avaliado: item.id_avaliacao_desempenho_avaliados }
            });
        }
    },
    data() {
        return {
            permissao: false,
            botoes: [
                {
                    id_permissao: 6,
                    nome: '',
                    acao: 'buscaAvaliacaoDesempenhoItens',
                    icone: 'mdi-reload',
                    cor: 'grey darken-5',
                    form: [SituacaoForm.GRID]
                }
            ],
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            mensagemCarregando: 'Buscando, aguarde...',
            situacaoEsperarFeedback: SituacaoAvaliadoEnum.ESPERAR_FEEDBACK,
            datatable: {
                carregando: false,
                mensagemCarregando: 'Buscando, aguarde...',
                chave_primaria: 'id_avaliacao_desempenho_resposta',
                itens: [],
                totalRegistros: 0,
                ultima_pagina: 0,
                pagina_atual: 1,
                por_pagina: 50,
                ordenarPor: 'parceiro_colaborador',
                ordenarDirecao: false,
                opcoes: {},
                cabecalho: [
                    {
                        text: 'AÇÃO',
                        value: 'acao',
                        align: 'start',
                        width: '100',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'Avaliação',
                        value: 'avaliacao_desempenho_nome',
                        width: '20%'
                    },
                    {
                        text: 'Responder como ?',
                        value: 'responder_como',
                        width: '151',
                        showFilter: false
                    },
                    {
                        text: 'Colaborador',
                        value: 'parceiro_colaborador',
                        width: '25%'
                    },
                    {
                        text: 'Gestor',
                        value: 'parceiro_gestor',
                        width: '25%'
                    },
                    {
                        text: 'Situação',
                        value: 'situacao',
                        width: '100',
                        showFilter: false
                    },
                    {
                        text: 'Período de',
                        value: 'periodo_de',
                        width: '100'
                    },
                    {
                        text: 'Período até',
                        value: 'periodo_ate',
                        width: '100'
                    }
                ]
            }
        };
    }
};
</script>

<style></style>
