<template>
    <div class="table-responsive">
        <table
            :id="getIdDatatable"
            :chave-primaria="chave_primaria"
            class="display mdl-data-table"
            style="width: 100%"
            cellspacing="0"
        >
            <thead v-html="colunasHeader"></thead>
            <tbody></tbody>
            <tfoot>
                <tr>
                    <th v-for="(coluna, i) in colunas" :key="i" v-html="coluna.descricao"></th>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';

export default {
    components: {
        AlertaMensagem
    },
    name: 'Datatables',
    props: {
        id_datatable: {
            type: String,
            default: ''
        },
        chave_primaria: {
            type: String,
            default: null
        },
        url: {
            type: String,
            default: ''
        },
        colunas: {
            type: Array,
            default: []
        },
        colunasTipo: {
            type: Object,
            default() {
                return {};
            }
        },
        setSelecionaLinha: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        colunasHeader: ''
    }),
    computed: {
        getIdDatatable() {
            return `datatable_${this.id_datatable}`;
        }
    },
    beforeMount() {
        this.montaColunaFiltroHeader(this.getIdDatatable);
        var self = this;

        $(document).ready(function() {
            $('.datatableBuscaPorColuna').on('change', function(e) {
                e.preventDefault();

                let data = $(this);
                let valor = data.val();
                let column = data.attr('data-nome-coluna');
                let datatableID = data.closest('th').attr('aria-controls');

                let table = $(`#${datatableID}`).DataTable();

                table
                    .column(`${column}:name`)
                    .search(valor)
                    .draw();
            });
            /*
            $(`#btnRecarregaTabela`).on('click', function (e) {
                e.preventDefault();

                let table = $(`#${self.getIdDatatable}`).DataTable();

                table.ajax.reload();

                console.log('recarrega a bagaça');
            });
*/
            $(`#${self.getIdDatatable} tbody`).on('dblclick', 'tr', function(e) {
                e.preventDefault();

                let linha = $(this);
                let tabelaTag = linha.closest('table');
                let datatableID = tabelaTag.attr('id');
                let chavePrimaria = tabelaTag.attr('chave-primaria');
                let table = $(`#${datatableID}`).DataTable();

                let data = table.row(this).data();

                self.consultaRegistro(data[chavePrimaria], data);
            });
        });
    },
    mounted() {
        var self = this;
        let datatableID = this.getIdDatatable;
        let url = this.url;
        let token = this.$store.getters['autenticacao/getToken'];
        let colunas = [];
        let colunasConfiguracao = [{ width: '50px', targets: 0 }];

        this.colunas.forEach(function(coluna, i) {
            colunas.push({
                data: coluna.id,
                name: coluna.nome,
                className: 'mdc-data-table__cell'
            });

            if (i > 0) {
                colunasConfiguracao.push({
                    targets: i,
                    className: 'mdc-data-table__cell'
                });
            }

            //console.log(colunasConfiguracao);
        });

        $(document).ready(function() {
            let table = $(`#${datatableID}`)
                //$(`#${datatableID}`)
                //.removeAttr('width')
                .DataTable({
                    responsive: true,
                    autoWidth: true,
                    bAutoWidth: true,
                    destroy: true,
                    bDestroy: true,
                    processing: true,
                    serverSide: true,
                    ajax: {
                        url: url,
                        type: 'POST',
                        dataSrc: function(json) {
                            return json.conteudo.datatable.data;
                        },
                        dataFilter: function(data) {
                            let json = JSON.parse(data);

                            json.recordsTotal = json.conteudo.datatable.recordsTotal;
                            json.recordsFiltered = json.conteudo.datatable.recordsFiltered;

                            return JSON.stringify(json);
                        },
                        headers: {
                            //'Content-type': 'application/json;charset=UTF-8',
                            //'Content-Type': 'multipart/form-data',
                            //'Access-Control-Allow-Origin': '*',
                            //Accept: '*/*',
                            Authorization: `Bearer ${token}`
                        },
                        error: function(xhr, error, code) {
                            let mensagemErro = '';

                            //console.log('xhr -->', xhr);
                            //console.log('error -->', error);
                            //console.log('code --->', code);

                            if (xhr.responseJSON != undefined && xhr.responseJSON.hasOwnProperty('mensagem')) {
                                mensagemErro += `${xhr.responseJSON.mensagem} - (${xhr.status} - ${code})`;
                            }

                            if (!xhr.status) {
                                mensagemErro += `${xhr.message}`;
                            }

                            self.$emit('mensagemAlerta', mensagemErro, 'error');
                            //Alerta(mensagemErro, 'error');
                        }
                    },
                    order: [[0, 'desc']],
                    aLengthMenu: [
                        [10, 25, 50, 100, -1],
                        [10, 25, 50, 100, 'Todos']
                    ],
                    dom: 'lfrtip',
                    bFilter: true,
                    searching: true,
                    fixedHeader: true,
                    columns: colunas,
                    //columnDefs: [{ width: '50px', targets: [0] }],
                    columnDefs: colunasConfiguracao,
                    scrollY: '300px',
                    scrollX: true,
                    scrollCollapse: true,
                    language: {
                        url: '../js/datatables/linguagem/pt_br.json'
                    }
                });

            /**
             * Ajusta as colunas
             * Qndo faz um Collapsed, para abrir e fechar
             * ele buga as dimensões, com o comando abaixo ele ajusta
             */
            table.columns.adjust().draw();
        });

        if (this.setSelecionaLinha) {
            this.setSelecionado(datatableID);
        }
    },
    unmounted() {
        //table.destroy();
    },
    methods: {
        consultaRegistro(id_registro, dadosLinha) {
            this.$emit('consultaRegistro', id_registro, dadosLinha);
        },
        setSelecionado(datatableID) {
            $(`#${datatableID} tbody`).on('click', 'tr', function() {
                // aqui seleciona mais de uma linha
                $(this).toggleClass('selected');
                /*
                * aqui seleciona uma linha
                if ($(this).hasClass('selected')) {
                    $(this).removeClass('selected');
                } else {
                    let table = $(`#${datatableID}`).DataTable();
                    table.$('tr.selected').removeClass('selected');
                    $(this).addClass('selected');
                }
                */
            });
        },
        recarregaTabela() {
            let table = $(`#${this.getIdDatatable}`).DataTable();

            table.ajax.reload();
        },
        montaColunaFiltroHeader(datatableID) {
            let linhaFiltro = '';
            let colunasTipo = this.colunasTipo;

            linhaFiltro += '<tr>';
            this.colunas.forEach(function(coluna, i) {
                if (colunasTipo[coluna.id] == 'select') {
                    let opcoes = '';

                    Object.keys(coluna.dados).forEach(function(chave, i) {
                        opcoes += `<option value="${chave}">${coluna.dados[chave]}</option>`;
                    });

                    linhaFiltro += `<th aria-controls="${datatableID}">
                                    <select name="${coluna.nome}" id="${coluna.nome}" class="form-control datatableBuscaPorColuna" data-nome-coluna="${coluna.nome}">
                                        <option value="">Buscar por:</option>
                                        <option value="" disabled></option>
                                            ${opcoes}
                                        </select>
                                    </th>`;
                }

                if (colunasTipo[coluna.id] == undefined) {
                    linhaFiltro += `<th aria-controls="${datatableID}">
                                    <input
                                        type="text"
                                        class="form-control datatableBuscaPorColuna"
                                        placeholder="Buscar por:"
                                        data-nome-coluna="${coluna.nome}"
                                    />
                                </th>`;
                }
            });
            linhaFiltro += '</tr>';

            linhaFiltro += '<tr>';
            this.colunas.forEach(function(coluna, i) {
                linhaFiltro += `<th>${coluna.descricao}</th>`;
            });
            linhaFiltro += '</tr>';

            this.colunasHeader = linhaFiltro;
        }
    }
};
</script>

<style>
.dataTables_wrapper .dataTables_processing {
    text-align: center;
    background: #262626;
    color: #fff;
    border: 1px solid black;
    position: absolute;
    opacity: 0.8;
    border-radius: 3px !important;
    top: 4% !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    padding: 0px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    z-index: 1;
}

.dataTables_wrapper .dataTables_processing img {
    position: absolute;
    top: 30%;
    left: 45%;
}

.mdc-data-table {
    width: 100%;
}

table tbody tr.selected {
    color: white !important;
    background-color: #ffd40038 !important;
}

.mdc-button--raised:not(:disabled),
.mdc-button--unelevated:not(:disabled) {
    background-color: #fb8c00;
}

..mdc-button--raised:not(:disabled) {
    color: #fff;
}

.mdc-button:not(:disabled) {
    color: #444444;
}

/*
.dataTables_wrapper {
    padding-top: 5px;
}

.dataTables_wrapper .dataTables_processing img {
    position: absolute;
    top: 24%;
    left: 34%;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 0px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:active {
    background-color: white;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    border: 0px;
    background: white;
}
*/
</style>
