import cobrar from '@/Enum/DevolucaoVazio/CobrarCliente.js'

const DEVOLUCAO_EXPIRADA = 1;
const DEVOLUCAO_BLOQUEADA_LAVAGEM_AVARIA = 2;
const FALTA_JANELA_D2 = 3;
const PROGRAMACAO_FROTA = 4;
const DTA = 5;
const AG_ABERTURA_GATE_EXP = 6;

export function descricao() {
    return [
        {
            value: AG_ABERTURA_GATE_EXP,
            label: 'AG. ABERTURA DO GATE-EXP',
            cobrar_cliente:  cobrar.NAO
        },
        {
            value: DEVOLUCAO_EXPIRADA,
            label: 'DEVOLUÇÃO EXPIRADA',
            cobrar_cliente:  cobrar.SIM
        },
        {
            value: DEVOLUCAO_BLOQUEADA_LAVAGEM_AVARIA,
            label: 'DEVOLUÇÃO BLOQUEADA (LAVAGEM / AVARIA)',
            cobrar_cliente:  cobrar.SIM
        },
        {
            value: DTA,
            label: 'DTA',
            cobrar_cliente:  cobrar.NAO
        },
        {
            value: FALTA_JANELA_D2,
            label: 'FALTA DE JANELA EM D+2',
            cobrar_cliente:  cobrar.SIM
        },
        {
            value: PROGRAMACAO_FROTA,
            label: 'PROGRAMAÇÃO FROTA',
            cobrar_cliente:  cobrar.NAO
        }
    ];
}
