<template>
    <v-badge color="white" transition="slide-x-transition" style="width: 100%;" :overlap="overlap">
        <template v-if="ajudaMensagem" v-slot:badge>
            <v-tooltip v-if="ajudaMensagem" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="green" v-bind="attrs" v-on="on" small>
                        mdi-help
                    </v-icon>
                </template>
                <span v-html="ajudaMensagem"></span>
            </v-tooltip>
        </template>
        <vuetify-money
            v-model="preco"
            :label="rotulo"
            :placeholder="rotulo"
            :outlined="outlined"
            :disabled="desativado"
            :readonly="readonly"
            :valueWhenIsEmpty="valueWhenIsEmpty"
            :options="options"
            :rules="regra"
            @input="$emit('input', parseFloat($event))"
            dense
        />
    </v-badge>
</template>

<script>
export default {
    props: {
        value: {
            type: Number | String,
            default: () => 0
        },
        casa_decimal: {
            type: Number,
            default: () => 2
        },
        tamanho: {
            type: Number,
            default: () => 9
        },
        prefixo: {
            type: String,
            default: () => ''
        },
        sufixo: {
            type: String,
            default: () => ''
        },
        regra: {
            type: Array,
            default: () => []
        },
        contador: {
            type: Number,
            default: () => 0
        },
        rotulo: {
            type: String,
            default: () => ''
        },
        desativado: {
            type: Boolean,
            default: () => false
        },
        obrigatorio: {
            type: Boolean,
            default: () => false
        },
        campoFormatacao: {
            type: String,
            default: () => ''
        },
        tipo: {
            type: String,
            default: () => 'text'
        },
        iconeAppend: {
            type: String,
            default: () => ''
        },
        iconePrepend: {
            type: String,
            default: () => ''
        },
        outlined: {
            type: Boolean,
            default: () => true
        },
        clickAppend: {
            type: Function,
            default: () => null
        },
        ajudaMensagem: {
            type: String,
            default: () => null
        }
    },
    watch: {
        value(valorDefinido) {
            this.preco = valorDefinido;
        }
    },
    computed: {
        overlap() {
            if (this.ajudaMensagem) {
                return true;
            }

            return false;
        }
    },
    mounted() {},
    methods: {},
    data() {
        return {
            preco: this.value,
            readonly: false,
            clearable: this.clearable,
            valueWhenIsEmpty: '',
            options: {
                locale: 'pt-BR',
                prefix: this.prefixo,
                suffix: this.sufixo,
                length: this.tamanho,
                precision: this.casa_decimal
            },
            properties: {
                hint: 'hint'
            }
        };
    }
};
</script>

<style scoped></style>
