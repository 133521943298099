import { API_URL_BASE, API_URL } from '@/config/configs';

const Sindicato = {
    datatable: {
        id_datatable: 'sindicato',
        chave_primaria: 'id_sindicato',
        url: `${API_URL_BASE}${API_URL.erp.sindicato.datatable}`,
        colunas: false,
    },
    formRefsComponente: 'SindicatoForm',
    formNome: 'formSindicato',
    moduloNome: 'sindicato',
    chavePrimaria: 'id_sindicato',
    componenteArquivo: 'Sindicato/Form.vue',
    tabRefs: 'tabSindicato',
    urlNovoRegistro: '',
    urlConsultaRegistro: 'listaPorIdForm',
    tabs: []
}

export { Sindicato };

