<template>
    <v-layout v-show="preloader" justify-center>
        <v-card-actions>
            {{ mensagem }} &nbsp;&nbsp;
            <v-progress-circular :size="80" color="tnlogLaranja" indeterminate>
                <v-avatar :size="70">
                    <v-img src="@/assets/image/logo/terranova_logo_fundo_branco.png" />
                </v-avatar>
            </v-progress-circular>
        </v-card-actions>
    </v-layout>
</template>

<script>
export default {
    name: 'CarregandoLogoEmpresa',
    props: {
        carregando: {
            type: Boolean,
            default: false
        },
        mensagem: {
            type: String,
            default: 'Aguarde, preparando os dados...'
        }
    },
    computed: {
        preloader() {
            if (this.carregando) {
                return true;
            }

            return this.$store.state.carregando.mostraCarregando;
        }
    }
};
</script>

<style scoped></style>
