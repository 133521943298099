import _ from 'lodash';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { DownloadArquivoBse64 } from '@/helpers/Funcoes.js';

const state = {
    edita: false,
    dashboards: [],
    dashboardsEdita: []
};

const actions = {
    habilitaPainel({ commit }, paineis) {
        const dashboardPainel = paineis.map(painel => {
            let layout = JSON.parse(painel.atributos.layout);

            if (layout == null) {
                layout = { h: 11, i: painel.id_registro, w: 8, x: 0, y: 0 };
            }

            return { ...painel.atributos, ...layout };
        });

        commit('setDashboards', dashboardPainel);
    },
    dashboards({ commit }, dashboards) {
        commit('setDashboards', dashboards);
    },
    edita({ commit, getters }, editaSimNao) {
        if (editaSimNao) {
            commit('setDashboardsEdita', _.cloneDeep(getters.dashboards));
        }

        if (!editaSimNao) {
            commit('setDashboards', _.cloneDeep(getters.dashboardsEdita));
            commit('setDashboardsEdita', []);
        }

        commit('setEdita', editaSimNao);
    },
    async salvaPerfil({ commit, state }) {
        try {
            const dashboardsFormatado = state.dashboards.map(layout => {
                return {
                    id_dashboard: layout.id_dashboard,
                    colunas: layout.colunas,
                    h: layout.h,
                    w: layout.w,
                    x: layout.x,
                    y: layout.y,
                    altura_pixel: layout.altura_pixel,
                    largura_pixel: layout.largura_pixel
                };
            });

            const dados = { dados: { dashboards: dashboardsFormatado } };

            ApiService({
                method: 'PUT',
                url: `${API_URL.erp.usuario.usuarioPerfilDashboardAtualiza}`,
                data: dados,
                mostraCarregando: false
            })
                .then(resposta => {
                    commit('setEdita', false);
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        } catch (erro) {
            throw new Error(erro.message);
        } finally {
        }
    },
    baixarImagem({ state }, graficoComponente) {
        const grafico = graficoComponente.getDataURL({
            pixelRatio: 2,
            backgroundColor: '#fff'
        });

        const nomeArquivo = `OrderToDeliveryAno-${state.ano}.png`;

        DownloadArquivoBse64(grafico, nomeArquivo);
    }
};

const mutations = {
    setDashboards(state, estado) {
        state.dashboards = estado;
    },
    setDashboardsEdita(state, estado) {
        state.dashboardsEdita = estado;
    },
    setEdita(state, estado) {
        state.edita = estado;
    }
};

const getters = {
    dashboards: state => {
        return state.dashboards;
    },
    dashboardsEdita: state => {
        return state.dashboardsEdita;
    },
    edita: state => {
        return state.edita;
    },
    tamanhoDashboard: state => {
        return ({ id_dashboard }) => {
            const dashboard = state.dashboards.find(dashboard => dashboard.id_dashboard == id_dashboard);
            let height = 350;

            if (dashboard) {
                height = dashboard.altura_pixel - 85;
            }

            return {
                height: `${height}px`,
                position: 'relative'
            };
        };
    }
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
};
