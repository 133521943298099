<template>
    <v-container fluid>
        <v-row>
            <v-col cols="6">
                <v-card outlined elevation="2" class="mb-4">
                    <v-card-title>
                        Filtros:

                        <v-chip class="ma-2" color="deep-purple accent-4 white--text" label text-color="white">
                            {{ filtrosTotal }}
                            <v-icon right>
                                mdi-account-filter
                            </v-icon>
                        </v-chip>

                        <v-spacer></v-spacer>

                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon>
                                    <v-icon>mdi-help</v-icon>
                                </v-btn>
                            </template>
                            <span>
                                <strong>Regra:</strong> Romaneios sem lançamento das ocorrências
                                <strong>1 - FINALIZADA</strong> e
                                <strong>105 - CHEGADA CLIENTE</strong>

                                <br />

                                Filtro na data criação do romaneio maior que 14/08/2023.

                                <br /><br />

                                <strong>Exibição:</strong> Será exibido de forma agrupada por dia de criação do romaneio
                                ordenado pelo mais recente
                            </span>
                        </v-tooltip>
                    </v-card-title>

                    <v-card-text dense>
                        <v-row>
                            <v-col cols="6">
                                <ComboBoxPreenchimentoV3
                                    v-model="filtros.id_programacao_carga_rodopar"
                                    tituloBusca="Programação Carga"
                                    :smallChips="true"
                                    :deletableChips="true"
                                    :multiple="true"
                                />
                            </v-col>

                            <v-col cols="6">
                                <ComboBoxPreenchimentoV3
                                    v-model="filtros.numero_cte"
                                    tituloBusca="CTe"
                                    :smallChips="true"
                                    :deletableChips="true"
                                    :multiple="true"
                                />
                            </v-col>

                            <v-col cols="6">
                                <InputSelectV3
                                    v-model="filtros.tipo_viagem"
                                    rotulo="Tipo Viagem"
                                    :itens="opcaoTipoViagem"
                                    itemTexto="descricao"
                                    itemValor="valor"
                                />
                            </v-col>

                            <v-col cols="6">
                                <InputAutoCompleteV3
                                    v-model="filtros.motorista"
                                    :datatableConfiguracao="buscas.motorista.datatable"
                                    tituloBusca="Motorista Busca..."
                                    itemValor="id_motorista"
                                    itemTexto="nome"
                                />
                            </v-col>

                            <v-col cols="12" class="text-right">
                                <v-btn
                                    elevation="2"
                                    color="deep-purple accent-4 white--text"
                                    :loading="carregandoDados"
                                    :disabled="carregandoDados"
                                    @click="buscaJornadaMotorista()"
                                >
                                    Buscar Jornada
                                    <v-icon right> mdi-magnify </v-icon>
                                    <template v-slot:loader>
                                        <span>Buscando... </span>
                                        <v-progress-circular
                                            indeterminate
                                            color="warning"
                                            :size="20"
                                            :width="2"
                                        ></v-progress-circular>
                                    </template>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                
            </v-col>
            

            <v-col cols="6">
                <v-row>
                    <v-col cols="6">
                        <card-info
                            iconeBoxCor="blue darken-2"
                            icone="mdi-truck-fast-outline"
                            titulo="INÍCIO DE VIAGEM"
                            :value="jornadaInicio"
                            rodape-texto="Quantidade com situação ínicio de viagem"
                            :carregando="carregandoDados"
                        />
                    </v-col>

                    <v-col cols="6">
                        <card-info
                            iconeBoxCor="yellow darken-3"
                            icone="mdi-truck-alert-outline"
                            titulo="PARADA DE ESPERA"
                            :value="jornadaParaEspera"
                            rodape-texto="Quantidade com situação parada de espera"
                            :carregando="carregandoDados"
                        />
                    </v-col>

                    <v-col cols="6">
                        <card-info
                            iconeBoxCor="green darken-2"
                            icone="mdi-truck-check-outline"
                            titulo="FIM DE VIAGEM"
                            :value="jornadaFim"
                            rodape-texto="Quantidade com situação fim de viagem"
                            :carregando="carregandoDados"
                        />
                    </v-col>

                    <v-col cols="6">
                        <card-info
                            iconeBoxCor="teal lighten-1"
                            icone="mdi-package-variant-closed-check"
                            titulo="Total"
                            :value="totalViagens"
                            rodape-texto="Quantidade total de viagens"
                            :carregando="carregandoDados"
                        />
                    </v-col>
                </v-row>
            </v-col>

            <v-col class="mx-auto col-12">
                <v-card class="pa-3">
                    <div class="text-center pb-5 body-1 grey--text">Legenda</div>

                    <v-tabs centered color="grey darken-3">
                        <v-tab v-for="filtro, key in tabsParaFiltragemDeMotoristasDeAcordoComOTempoDaJornada" :key="key"  @click="setFilter(filtro.value)">
                            <v-card  class="d-flex font-weight-bold px-2 py-6 caption align-center" color="grey lighten-2" style="gap: 10px; height: 24px;">
                                {{filtro.name}}
                                <v-btn :color="filtro.color" fab x-small elevation="2" class="subtitle-1 font-weight-bold"> {{ filtro.totalDeJornadas }} </v-btn>
                            </v-card>
                        </v-tab>
                    </v-tabs>
                </v-card>
            </v-col>
            
        </v-row>

        <CarregandoLogoEmpresa class="mt-10" :carregando="carregandoDados" :mensagem="carregandoDadosMensagem" />

        <span v-if="!carregandoDados">
            <v-row>
                <v-col cols="12" md="4">
                    <v-card class="mt-5" color="blue lighten-5">
                        <v-toolbar color="blue darken-2" >
                            <v-toolbar-title>
                                <span class="white--text">
                                    <v-icon left dark large>
                                        mdi-truck-fast-outline
                                    </v-icon>

                                    INÍCIO DE VIAGEM
                                </span>
                            </v-toolbar-title>
                        </v-toolbar>

                        <v-card-text>
                            <v-row>
                                <div v-for="(jornadaDeAcordoComOTempoDaJornada, indexTipoJornada) in filtrarMotoristasDeAcordoComOTempoDaJornada(jornadas.inicio_viagem)" :key="indexTipoJornada">
                                    <v-col
                                        md="12"
                                        sm="12"
                                        v-for="(jornada, indexJornada) in jornadaDeAcordoComOTempoDaJornada"
                                        :key="indexJornada"
                                    >
                                        <motorista-card
                                            :imagem_avatar="jornada.imagem_avatar"
                                            :motorista="jornada.motorista_nome"
                                            :telefone_celular="jornada.telefone_celular"
                                            :status_tempo_total="jornada.direcao"
                                            :status_tempo_geral="jornada.tempo_geral"
                                            :id_programacao_carga_rodopar="jornada.id_programacao_carga_rodopar"
                                            :id_alocacao_carga_romaneio="jornada.id_alocacao_carga_romaneio"
                                            :numero_cte="jornada.numero_cte"
                                            :nome_tomador_servico="jornada.tomador_servico_nome"
                                            :terminal_coleta="jornada.tomador_servico_nome"
                                            :terminal_entrega="jornada.terminal_coleta_nome"
                                            :placa_veiculo_cavalo="jornada.placa_veiculo_cavalo"
                                            :veiculo_frota="jornada.veiculo_frota"
                                            :veiculo_modelo="jornada.veiculo_modelo"
                                            :corDoCardDeAcordoComTempoDaJornada = corDoCardDeAcordoComTempoDaJornada(jornada.ctrl)
                                        />
                                    </v-col>
                                </div>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" md="4">
                    <v-card class="mt-5" color="yellow lighten-5">
                        <v-toolbar color="yellow darken-3" >
                            <v-toolbar-title>
                                <span class="white--text">
                                    <v-icon left dark large>
                                        mdi-truck-alert-outline
                                    </v-icon>

                                    PARADA DE ESPERA / PARADA / ALIMENTAÇÃO
                                </span>
                            </v-toolbar-title>
                        </v-toolbar>

                        <v-card-text>
                            <v-row>
                                <div v-for="(jornadaDeAcordoComOTempoDaJornada, indexTipoJornada) in filtrarMotoristasDeAcordoComOTempoDaJornada(jornadas.parada_espera)" :key="indexTipoJornada">
                                    <v-col
                                    md="12"
                                    sm="12"
                                    v-for="(jornada, indexJornada) in jornadaDeAcordoComOTempoDaJornada"
                                    :key="indexJornada"
                                    >
                                    <motorista-card
                                        :imagem_avatar="jornada.imagem_avatar"
                                        :motorista="jornada.motorista_nome"
                                        :telefone_celular="jornada.telefone_celular"
                                        :status_tempo_total="jornada.direcao"
                                        :status_tempo_geral="jornada.tempo_geral"
                                        :id_programacao_carga_rodopar="jornada.id_programacao_carga_rodopar"
                                        :id_alocacao_carga_romaneio="jornada.id_alocacao_carga_romaneio"
                                        :numero_cte="jornada.numero_cte"
                                        :nome_tomador_servico="jornada.tomador_servico_nome"
                                        :terminal_coleta="jornada.tomador_servico_nome"
                                        :terminal_entrega="jornada.terminal_coleta_nome"
                                        :placa_veiculo_cavalo="jornada.placa_veiculo_cavalo"
                                        :veiculo_frota="jornada.veiculo_frota"
                                        :veiculo_modelo="jornada.veiculo_modelo"
                                        :corDoCardDeAcordoComTempoDaJornada = corDoCardDeAcordoComTempoDaJornada(jornada.ctrl)
                                    />
                                    </v-col>
                                </div>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" md="4">
                    <v-card class="mt-5" color="green lighten-5">
                        <v-toolbar color="green darken-2" >
                            <v-toolbar-title>
                                <span class="white--text">
                                    <v-icon left dark large>
                                        mdi-truck-check-outline
                                    </v-icon>

                                    FIM DE VIAGEM
                                </span>
                            </v-toolbar-title>
                        </v-toolbar>

                        <v-card-text>
                            <v-row>
                                <div v-for="(jornadaDeAcordoComOTempoDaJornada, indexTipoJornada) in filtrarMotoristasDeAcordoComOTempoDaJornada(jornadas.fim_viagem)" :key="indexTipoJornada">
                                    <v-col
                                        md="12"
                                        sm="12"
                                        v-for="(jornada, indexJornada) in jornadaDeAcordoComOTempoDaJornada"
                                        :key="indexJornada"
                                    >
                                        <motorista-card
                                            :imagem_avatar="jornada.imagem_avatar"
                                            :motorista="jornada.motorista_nome"
                                            :telefone_celular="jornada.telefone_celular"
                                            :status_tempo_total="jornada.direcao"
                                            :status_tempo_geral="jornada.tempo_geral"
                                            :id_programacao_carga_rodopar="jornada.id_programacao_carga_rodopar"
                                            :id_alocacao_carga_romaneio="jornada.id_alocacao_carga_romaneio"
                                            :numero_cte="jornada.numero_cte"
                                            :nome_tomador_servico="jornada.tomador_servico_nome"
                                            :terminal_coleta="jornada.tomador_servico_nome"
                                            :terminal_entrega="jornada.terminal_coleta_nome"
                                            :placa_veiculo_cavalo="jornada.placa_veiculo_cavalo"
                                            :veiculo_frota="jornada.veiculo_frota"
                                            :veiculo_modelo="jornada.veiculo_modelo"
                                            :corDoCardDeAcordoComTempoDaJornada = corDoCardDeAcordoComTempoDaJornada(jornada.ctrl)
                                        />
                                    </v-col>
                                </div>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </span>

        <v-row>
            <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                {{ notificacao.mensagem }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </v-row>
    </v-container>
</template>

<script>
import { API_URL, API_URL_BASE } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import {
    UltimaMacroEnum,
    listaDescricao as macroLista
} from '@/Modulo/Monitoramento/Motorista/Dominio/Enum/UltimaMacroEnum.js';

import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import InputAutoCompleteV3 from '@/components/Form/InputAutoCompleteV3.vue';

import MotoristaCard from '@/Modulo/Monitoramento/Motorista/Componente/MotoristaCard.vue';
import ComboBoxPreenchimentoV3 from '@/components/Form/ComboBoxPreenchimentoV3.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';
import CardInfo from '@/components/Default/CardInfo.vue';

export default {
    name: 'PainelJornadaMotorista',
    components: {
        CarregandoLogoEmpresa,
        MotoristaCard,
        ComboBoxPreenchimentoV3,
        InputSelectV3,
        InputAutoCompleteV3,
        CardInfo
    },
    mounted() {
        this.buscaJornadaMotorista();
        
    },
    computed: {
        jornadaInicio() {
            let total = 0
            let todasAsjornadas = this.jornadas

            for (let jornadaDeAcordoComOTempoDaJornada in todasAsjornadas.inicio_viagem) {
                let categoria = todasAsjornadas.inicio_viagem[jornadaDeAcordoComOTempoDaJornada]
                total += categoria.length
            }
            return total;
        },
        jornadaParaEspera() {
            let total = 0
            let todasAsjornadas = this.jornadas

            for (let jornadaDeAcordoComOTempoDaJornada in todasAsjornadas.parada_espera) {
                let categoria = todasAsjornadas.parada_espera[jornadaDeAcordoComOTempoDaJornada]
                total += categoria.length
            }
            return total;
        },
        jornadaFim() {
            let total = 0
            let todasAsjornadas = this.jornadas

            for (let jornadaDeAcordoComOTempoDaJornada in todasAsjornadas.fim_viagem) {
                let categoria = todasAsjornadas.fim_viagem[jornadaDeAcordoComOTempoDaJornada]
                total += categoria.length
            }
            return total;
        },
        totalViagens() {
            let total = 0
            let todasAsjornadas = this.jornadas

            for (let macroDeJornada in todasAsjornadas) {
                let categoria = todasAsjornadas[macroDeJornada]
                for (let jornadaDeAcordoComOTempoDaJornada in categoria) {
                   total += categoria[jornadaDeAcordoComOTempoDaJornada].length;
                };
            }
            
            return total;
        },
        filtrosTotal() {
            let total = 0;

            Object.values(this.filtros).map(function(item) {
                if (item == null) {
                    return;
                }

                if (item != null && item.length > 0) {
                    total += 1;
                    return;
                }

                if (item > 0) {
                    total += 1;
                    return;
                }
            });

            return total;
        }
    },
    methods: {
        async buscaJornadaMotorista() {
            try {
                this.carregandoDados = true;

                this.carregandoDadosMensagem = 'Aguarde, buscando jornada...';

                const parametros = {
                    id_programacao_carga_rodopar: this.filtros.id_programacao_carga_rodopar,
                    numero_cte: this.filtros.numero_cte,
                    tipo_viagem: this.filtros.tipo_viagem,
                    motorista: this.filtros.motorista
                };

                await ApiService({
                    method: 'GET',
                    url: `${API_URL.erp.motorista.painelJornadaMotorista}`,
                    mostraCarregando: false,
                    params: parametros
                })
                    .then(resposta => {
                        if (!resposta?.data?.data) {
                            this.jornadas = {};
                        }

                        let motoristasFiltrados = []

                        resposta?.data?.data?.map(motorista => {     
                            if (motorista.motorista_nome != 'DIEGO CORREA SEMENDRI') {
                                motoristasFiltrados.push(motorista)
                            }
                        })                                 
                        this.preenchePainelJornada(motoristasFiltrados);
                    })
                    .catch(erro => {
                    
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.carregandoDados = false;
            }
        },
        
        preenchePainelJornada(jornadas) {

            // zerando a variavel a cada nova requisição
            this.zeraPainelDeJornada();

            jornadas.sort((a, b) => {
                if (a.motorista_nome < b.motorista_nome) {
                    return -1;
                }
                if (a.motorista_nome > b.motorista_nome) {
                    return 1;
                }
                return 0;
            });         
             
            const oitoHorasEmSegundos = 8 * 3600;
            const onzeHorasEmSegundos = 11 * 3600;

            jornadas.map((item) => {
                if(item.placa_veiculo_cavalo) {
                    const novaPlaca = item.placa_veiculo_cavalo.toUpperCase()
                    item.placa_veiculo_cavalo = novaPlaca
                };
                
                const jornadaDoMotoristaEmSegundos = this.ConvertetempoEmSegundos(item.tempo_geral);

                const macroMap = {
                    [UltimaMacroEnum.INICIO_VIAGEM]: 'inicio_viagem',
                    [UltimaMacroEnum.INICIO_JORNADA]: 'inicio_viagem',
                    [UltimaMacroEnum.PARADA_ESPERA]: 'parada_espera',
                    [UltimaMacroEnum.PARADA]: 'parada_espera',
                    [UltimaMacroEnum.FIM_VIAGEM]: 'fim_viagem',
                    [UltimaMacroEnum.ALIMENTACAO]: 'parada_espera',
                    [UltimaMacroEnum.PARADA_EXTRA]: 'parada_espera'
                };

                const macroDaJornadaAtualDeAcordoComOId = macroMap[item.ultima_macro_id]
                if(macroDaJornadaAtualDeAcordoComOId != 'parada_espera' && macroDaJornadaAtualDeAcordoComOId != 'inicio_viagem' && macroDaJornadaAtualDeAcordoComOId != 'fim_viagem') {
                    return
                }
                
                // Inicializa variáveis para o destino e listas
                let destino;
                let lista;

                // Determina a categoria da jornada e a lista de destino
                if (jornadaDoMotoristaEmSegundos > oitoHorasEmSegundos && jornadaDoMotoristaEmSegundos <= onzeHorasEmSegundos) {
                    destino = 'quaseExcedida';
                    this.CTRLDosMotoristasComJornadaQuaseExcedida.push(item.ctrl);
                }

                if (jornadaDoMotoristaEmSegundos > onzeHorasEmSegundos) {
                    destino = 'excedida';
                    this.CTRLDosMotoristasComJornadaExcedida.push(item.ctrl);
                }

                if (jornadaDoMotoristaEmSegundos <= oitoHorasEmSegundos) {
                    destino = 'normal';
                    this.CTRLDosMotoristasComJornadaNormal.push(item.ctrl);
                }
                
                // Adiciona o item à lista apropriada se a macro corresponder
                if (macroDaJornadaAtualDeAcordoComOId) {
                    lista = this.jornadas[macroDaJornadaAtualDeAcordoComOId][`motoristasComJornada${this.capitalizeFirstLetter(destino)}`];
                    if (lista) {
                        lista.push(item);
                    }
                }
            });

            this.alterarValoresTotaisDasTabsDasDeJornadaBaseadasNoTempo()
        },

        alterarValoresTotaisDasTabsDasDeJornadaBaseadasNoTempo() {

            for (let tabDeAcordoComOtempoDaJornada in this.tabsParaFiltragemDeMotoristasDeAcordoComOTempoDaJornada) {

                if (tabDeAcordoComOtempoDaJornada !== 'todos') {
                    const nomeDaVariavelDaJornadaDeAcordoComOTempo = `CTRLDos${this.capitalizeFirstLetter(tabDeAcordoComOtempoDaJornada)}`
                    const totalDeJornadasDeAcordoComOTempo = this[nomeDaVariavelDaJornadaDeAcordoComOTempo].length
                    this.tabsParaFiltragemDeMotoristasDeAcordoComOTempoDaJornada[tabDeAcordoComOtempoDaJornada].totalDeJornadas = totalDeJornadasDeAcordoComOTempo
                    continue
                }
                this.tabsParaFiltragemDeMotoristasDeAcordoComOTempoDaJornada[tabDeAcordoComOtempoDaJornada].totalDeJornadas = this.CTRLDosMotoristasComJornadaExcedida.length + this.CTRLDosMotoristasComJornadaQuaseExcedida.length + this.CTRLDosMotoristasComJornadaNormal.length  
            }
        },

        zeraPainelDeJornada() {
            this.CTRLDosMotoristasComJornadaExcedida = [];
            this.CTRLDosMotoristasComJornadaNormal = [];
            this.CTRLDosMotoristasComJornadaQuaseExcedida = [];

            this.jornadas = {
                inicio_viagem: {
                    motoristasComJornadaExcedida: [],
                    motoristasComJornadaQuaseExcedida: [],
                    motoristasComJornadaNormal: [],
                },
                parada_espera: {
                    motoristasComJornadaExcedida: [],
                    motoristasComJornadaQuaseExcedida: [],
                    motoristasComJornadaNormal: [],
                },
                fim_viagem: {
                    motoristasComJornadaExcedida: [],
                    motoristasComJornadaQuaseExcedida: [],
                    motoristasComJornadaNormal: [],
                }
            }
        },
        filtrosBusca() {
            let formData = {};

            if (this.filtros.id_programacao_carga_rodopar) {
                formData.id_programacao_carga_rodopar = [this.filtros.id_programacao_carga_rodopar];
            }

            if (this.filtros.numero_cte) {
                formData.numero_cte = [this.filtros.numero_cte];
            }

            if (this.filtros.tipo_viagem) {
                formData.tipo_viagem = [this.filtros.status];
            }

            if (this.filtros.motorista) {
                formData.id_motorista = [this.filtros.motorista];
            }

            return formData;
        },

        ConvertetempoEmSegundos(time) {
            const [hours, minutes, seconds] = time.split(':').map(Number);
                return (hours * 3600) + (minutes * 60) + seconds;
        },

        corDoCardDeAcordoComTempoDaJornada(ctrl) {
            if (this.CTRLDosMotoristasComJornadaQuaseExcedida.includes(ctrl)) {
                return 'orange lighten-4'
            }

            if ( this.CTRLDosMotoristasComJornadaExcedida.includes(ctrl)) {
                return 'red lighten-4'
            } 

            if ( this.CTRLDosMotoristasComJornadaNormal.includes(ctrl)) {
                return 'white'
            }
            
            return
        },

        setFilter(tipo) {
            this.filtroAtual = tipo;
        },

        filtrarMotoristasDeAcordoComOTempoDaJornada(jornadaDeUmaMacroEspecifica) {
            if (this.filtroAtual === 'todos') {
                // Retorna todos os motoristas se não houver filtro aplicado
                return jornadaDeUmaMacroEspecifica;
            }
            // Retorna apenas os motoristas filtrados
            let jornadaFiltrada = {
                jornadaDeAcordoComOTempoDaJornada: jornadaDeUmaMacroEspecifica[`motoristasComJornada${this.capitalizeFirstLetter(this.filtroAtual)}`]
            }
                
            return jornadaFiltrada;
        },

        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
    },

    data() {
        return {
            show: false,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            carregandoDados: true,
            carregandoDadosMensagem: 'Aguarde, buscando romaneios disponíveis...',
            filtros: {
                id_programacao_carga_rodopar: [],
                numero_cte: [],
                tipo_viagem: null,
                motorista: null
            },
            opcaoTipoViagem: macroLista(),
            buscas: {
                motorista: {
                    valor: '',
                    complemento: [{ id_motorista: null, nome: '' }],
                    datatable: {
                        id_datatable: 'motorista_busca',
                        chave_primaria: 'motorista',
                        url: `${API_URL_BASE}${API_URL.erp.motorista.completaCampo}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.motorista.completaCampo}`,
                        colunas: [
                            {
                                id: 'id_motorista',
                                nome: 'id_motorista',
                                descricao: 'ID'
                            },
                            {
                                id: 'nome',
                                nome: 'nome',
                                descricao: 'Nome'
                            }
                        ]
                    }
                }
            },
            filtroAtual: 'todos',
            tabsParaFiltragemDeMotoristasDeAcordoComOTempoDaJornada : {
                todos: {
                    name: 'TODOS',
                    value: 'todos',
                    color: 'teal lighten-1',
                    totalDeJornadas: null
                },
                motoristasComJornadaExcedida: {
                    name: 'JORNADA EXCEDIDA',
                    value: 'excedida',
                    color: 'red lighten-4',
                    totalDeJornadas: null
                },
                motoristasComJornadaQuaseExcedida: {
                    name: 'JORNADA QUASE EXCEDIDA',
                    value: 'quaseExcedida',
                    color: 'orange lighten-4',
                    totalDeJornadas: null
                },
                motoristasComJornadaNormal: {
                    name: 'JORNADA NORMAL',
                    value: 'normal',
                    color: 'white',
                    totalDeJornadas: null
                }
            },
            jornadas: {
                inicio_viagem: {
                    motoristasComJornadaExcedida: [],
                    motoristasComJornadaQuaseExcedida: [],
                    motoristasComJornadaNormal: [],
                },
                parada_espera: {
                    motoristasComJornadaExcedida: [],
                    motoristasComJornadaQuaseExcedida: [],
                    motoristasComJornadaNormal: [],
                },
                fim_viagem: {
                    motoristasComJornadaExcedida: [],
                    motoristasComJornadaQuaseExcedida: [],
                    motoristasComJornadaNormal: [],
                }
            },
            CTRLDosMotoristasComJornadaQuaseExcedida: [],
            CTRLDosMotoristasComJornadaExcedida: [],
            CTRLDosMotoristasComJornadaNormal: [],
            url_arquivo: 'http://api-tnsys-dev.tnlog.com.br/api/V1/imagem/whatsapp/whatsapp_20240223162818.jpeg',
            imageUrl: null
        };
    }
};
</script>
