<template>
    <v-row>
        <v-col cols="12" sm="12">
            <InputTextV3
                v-model="form.id_whatsapp_fluxo_item"
                rotulo="id_whatsapp_fluxo_item"
                :contador="500"
                :obriatorio="true"
                :regra="regras"
                :rows="3"
                v-show="false"
                df-id-whatsapp-fluxo-item
            />

            <TextAreaV3
                v-model="form.descricao"
                rotulo="Descrição"
                :contador="500"
                :obriatorio="true"
                :regra="regras"
                :rows="3"
                df-descricao
            />
        </v-col>

        <v-col cols="12" sm="12">
            <v-btn @click="adicionaItem()" small>
                <v-icon small>mdi-plus</v-icon>
                Itens
            </v-btn>
        </v-col>

        <v-card-actions v-for="(item, index) in form.itens" :key="`itens-${index}`" class="box">
            <v-col cols="12" sm="10">
                <InputTextV3
                    v-model="item.descricao"
                    :contador="100"
                    :regra="regras"
                    :clearable="false"
                    rotulo="Questão"
                    df-item-descricao
                />
            </v-col>

            <v-col cols="12" sm="2">
                <v-icon color="red" @click="removeItem(index)" large>mdi-close-box</v-icon>
            </v-col>
        </v-card-actions>
    </v-row>
</template>

<script>
import bus from './eventBus.js';

import { Obrigatorio, Minimo, Maximo } from '@/helpers/ValidadorCampo.js';

import InputTextV3 from '@/components/Form/InputTextV3.vue';
import TextAreaV3 from '@/components/Form/TextAreaV3.vue';

export default {
    name: 'Itens',
    components: {
        InputTextV3,
        TextAreaV3
    },
    props: {
        id_whatsapp_fluxo_item: {
            type: Number,
            default: () => null
        },
        nome_node: {
            type: String,
            default: () => null
        },
        descricao: {
            type: String,
            default: () => 'Descrição'
        },
        itens: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    methods: {
        adicionaItem() {
            this.form.itens.push({ descricao: '' });

            bus.$emit('adicionaOutput', { nome_node: this.nome_node, quantidade: 1 });
        },
        removeItem(posicao) {
            const id_output = posicao + 1;

            this.form.itens = this.form.itens.filter((item, index) => index !== posicao);

            bus.$emit('itensRemovido', { nome_node: this.nome_node, id_output: id_output });
        }
    },
    data() {
        return {
            form: {
                id_whatsapp_fluxo_item: this.id_whatsapp_fluxo_item,
                descricao: this.descricao,
                itens: this.itens
            },
            regras: [Obrigatorio(), Minimo(2), Maximo(200)]
        };
    }
};
</script>
