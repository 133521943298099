<template>
    <div>
        <div class="my-4 bg-grey">
            <Avatar
                :url="uploadUrl"
                :nome="parceiro.nome"
                :fotoUrl="colaborador.imagem_avatar"
                @upload="avatarUpload"
                nomePadrao="Colaborador"
            />
        </div>

        <v-divider></v-divider>

        <v-form ref="form" class="my-4">
            <v-card-text>
                <v-row>
                    <v-col class="pt-0" cols="12" md="2">
                        <v-text-field
                            dense
                            v-model="colaborador.id_colaborador"
                            outlined
                            label="ID Colaborador"
                            data-vv-delay="1000"
                            disabled
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="2">
                        <v-select
                            dense
                            v-model="parceiro.tipo_pessoa"
                            label="Tipo Pessoa"
                            data-vv-delay="1000"
                            :items="itemsTipoPessao"
                            item-text="label"
                            item-value="value"
                            :rules="regraTipoPessoa"
                            clearable
                            outlined
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="4">
                        <v-text-field
                            dense
                            v-model="parceiro.nome"
                            label="Nome"
                            data-vv-delay="1000"
                            :rules="regraNome"
                            counter="150"
                            clearable
                            outlined
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="4">
                        <InputAutoCompleteV3
                            v-model="colaborador.id_colaborador_gestor"
                            :datatableConfiguracao="autoCompleta.gestor.datatable"
                            tituloBusca="Gestor Busca..."
                            :itemsOpcoes="itemsGestor"
                            itemValor="id_colaborador"
                            itemTexto="descricao"
                            :regras="[v => !!v || 'Gestor é obrigatório']"
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="4">
                        <v-text-field
                            dense
                            v-model="parceiro.razao_social"
                            label="Razão Social"
                            :disabled="ehPF"
                            data-vv-delay="1000"
                            :rules="regraRazaoSocial"
                            counter="150"
                            clearable
                            outlined
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="4">
                        <v-text-field
                            dense
                            v-model="parceiro.representante_legal"
                            label="Representante Legal"
                            :disabled="ehPF"
                            data-vv-delay="1000"
                            :rules="regraRepresentanteLegal"
                            counter="150"
                            clearable
                            outlined
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="4">
                        <v-text-field
                            dense
                            v-model="parceiro.nome_fantasia"
                            label="Nome Fantasia"
                            :disabled="ehPF"
                            data-vv-delay="1000"
                            :rules="regraNomefantasia"
                            counter="150"
                            clearable
                            outlined
                        />
                    </v-col>
                    <v-col class="pt-0" cols="12" md="4">
                        <v-text-field
                            v-model="maskCPF"
                            label="CPF"
                            v-maska="cpfMask"
                            data-vv-delay="1000"
                            :rules="regraCPF"
                            :counter="14"
                            @blur="buscaParceiro(parceiro.cpf, 'CPF')"
                            clearable
                            outlined
                            dense
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="4">
                        <v-text-field
                            v-model="maskCNPJ"
                            label="CNPJ"
                            v-maska="cnpjMask"
                            data-vv-delay="1000"
                            :rules="regraCNPJ"
                            :counter="18"
                            :disabled="ehPF"
                            :maxlength="18"
                            @blur="buscaParceiro(parceiro.cnpj, 'CNPJ')"
                            outlined
                            clearable
                            dense
                        />
                    </v-col>
                    <v-col class="pt-0" cols="12" md="4">
                        <v-text-field
                            dense
                            v-model="parceiro.inscricao_estadual"
                            label="Inscrição Estadual"
                            data-vv-delay="1000"
                            counter="8"
                            v-maska="'########'"
                            :disabled="ehPF"
                            :rules="regraInscricaoEstadual"
                            clearable
                            outlined
                        />
                    </v-col>
                    <v-col class="pt-0" cols="12" md="4">
                        <v-text-field
                            dense
                            v-model="parceiro.inscricao_municipal"
                            label="Inscrição Municipal"
                            data-vv-delay="1000"
                            counter="11"
                            v-maska="'###########'"
                            :disabled="ehPF"
                            :rules="regraInscricaoMunicipal"
                            clearable
                            outlined
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="4">
                        <v-text-field
                            v-model="maskCNAE"
                            v-maska="cnaeMask"
                            label="CNAE"
                            data-vv-delay="1000"
                            counter="14"
                            :rules="regraCnae"
                            :disabled="ehPF"
                            dense
                            clearable
                            outlined
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="4">
                        <v-text-field
                            dense
                            v-model="parceiro.rg"
                            label="RG"
                            v-maska="'#########'"
                            data-vv-delay="1000"
                            counter="9"
                            :rules="regraRg"
                            clearable
                            outlined
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="4">
                        <v-select
                            dense
                            v-model="parceiro.id_uf_rg"
                            :items="itemsUFRG"
                            label="UF do RG"
                            item-text="label"
                            item-value="value"
                            clearable
                            outlined
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="4">
                        <DateTimePickerV2
                            v-model="parceiro.data_emissao_rg"
                            label="Data de Emissão do RG"
                            data-vv-delay="1000"
                            :rules="regraDataEmissaoRG"
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="4">
                        <v-text-field
                            dense
                            v-model="parceiro.orgao_emissor"
                            label="Orgão Emissor do RG"
                            counter="6"
                            data-vv-delay="1000"
                            :rules="regraOrgaoEmissorRg"
                            clearable
                            outlined
                        />
                    </v-col>

                    <!-- END PARCEIRO -->

                    <v-col class="pt-0" cols="12" md="3">
                        <DateTimePickerV2
                            v-model="colaborador.data_nascimento"
                            outlined
                            label="Data de Nascimento *"
                            data-vv-delay="1000"
                            :rules="[v => !!v || 'Data de Nascimento é obrigatório']"
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3">
                        <v-text-field
                            v-model="maskPIS"
                            label="PIS"
                            v-maska="pisMask"
                            data-vv-delay="1000"
                            :rules="regraPis"
                            counter="14"
                            outlined
                            dense
                            clearable
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3">
                        <v-text-field
                            dense
                            v-model="colaborador.numero_cnh"
                            outlined
                            label="CNH"
                            v-maska="'###########'"
                            data-vv-delay="1000"
                            :rules="regraCNH"
                            counter="11"
                            clearable
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3">
                        <DateTimePickerV2
                            v-model="colaborador.data_vencimento_cnh"
                            outlined
                            label="Vencimento CNH"
                            data-vv-delay="1000"
                            :rules="regraVencimentoCNH"
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="6">
                        <v-text-field
                            dense
                            v-model="colaborador.nome_mae"
                            outlined
                            label="Nome da Mãe"
                            data-vv-delay="1000"
                            :rules="regraNomePaiOuMae"
                            counter="150"
                            clearable
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="6">
                        <v-text-field
                            dense
                            v-model="colaborador.nome_pai"
                            outlined
                            label="Nome do Pai"
                            data-vv-delay="1000"
                            :rules="regraNomePaiOuMae"
                            counter="150"
                            clearable
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="6">
                        <v-select
                            dense
                            v-model="colaborador.id_filial"
                            outlined
                            :items="itemsFilial"
                            label="Empresa *"
                            item-text="label"
                            item-value="value"
                            :rules="[v => !!v || 'Empresa é obrigatório']"
                            clearable
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="6">
                        <v-select
                            dense
                            v-model="colaborador.id_unidade_empreendimento"
                            outlined
                            :items="itemsUnidadeEmpreendimento"
                            label="Unidade de Empreendimento *"
                            item-text="label"
                            item-value="value"
                            :rules="[v => !!v || 'Unidade de Empreendimento é obrigatório']"
                            clearable
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3">
                        <v-select
                            dense
                            v-model="colaborador.id_departamento"
                            outlined
                            :items="itemsDepartamento"
                            label="Departamento *"
                            item-text="label"
                            item-value="value"
                            :rules="[v => !!v || 'Departamento é obrigatório']"
                            clearable
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3">
                        <v-select
                            dense
                            v-model="colaborador.id_tipo_contratacao"
                            outlined
                            :items="itemsTipoContratacao"
                            label="Tipo de Contratação *"
                            item-text="label"
                            item-value="value"
                            clearable
                            :rules="[v => !!v || 'Tipo de Contratação é obrigatório']"
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3">
                        <DateTimePickerV2
                            v-model="colaborador.data_admissao"
                            outlined
                            :label="labelDataAdmissao"
                            :dataInicial="colaborador.data_admissao"
                            data-vv-delay="1000"
                            :rules="[v => !!v || 'Data de Admissão é obrigatório']"
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3">
                        <DateTimePickerV2
                            v-model="colaborador.data_prevista_experiencia"
                            outlined
                            :disabled="ehPJ"
                            :dataInicial="colaborador.data_prevista_experiencia"
                            label="Data Prevista Experiência"
                            data-vv-delay="1000"
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3">
                        <v-text-field
                            dense
                            v-model="colaborador.matricula"
                            outlined
                            label="Matrícula *"
                            data-vv-delay="1000"
                            counter="36"
                            :rules="regraMatricula"
                            clearable
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3">
                        <v-select
                            dense
                            v-model="colaborador.status_contrato"
                            outlined
                            :items="itemsStatusContrato"
                            label="Status do Contrato *"
                            item-text="label"
                            item-value="value"
                            clearable
                            :rules="[v => !!v || 'Status do Contrato é obrigatório']"
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3">
                        <v-select
                            dense
                            v-model="colaborador.id_cargo"
                            outlined
                            :items="itemsCargo"
                            label="Cargo *"
                            item-text="label"
                            item-value="value"
                            clearable
                            :rules="[v => !!v || 'Cargo é obrigatório']"
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3">
                        <vuetify-money
                            dense
                            v-model="colaborador.salario"
                            outlined
                            :options="money"
                            :label="labelSalario"
                            data-vv-delay="1000"
                            :rules="regraSalario"
                            masked
                            clearable
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3">
                        <v-select
                            dense
                            v-model="colaborador.id_sindicato"
                            outlined
                            :items="itemsSindicato"
                            label="Sindicato"
                            item-text="label"
                            item-value="value"
                            clearable
                        >
                        </v-select>
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3">
                        <DateTimePickerV2
                            v-model="colaborador.data_vencimento_toxicologico"
                            outlined
                            label="Vencimento Exame Toxicológico"
                            data-vv-delay="1000"
                            :rules="regraDataToxicologico"
                        />
                    </v-col>
                    <v-col class="pt-0" cols="12" md="3">
                        <DateTimePickerV2
                            v-model="colaborador.data_vencimento_movimentacao_produto_perigoso"
                            outlined
                            label="Data Vencimento MOPP"
                            data-vv-delay="1000"
                            :rules="regraDataMOPP"
                        />
                    </v-col>
                    <v-col class="pt-0" cols="12" md="3">
                        <DateTimePickerV2
                            v-model="colaborador.data_ultimo_exame_periodico"
                            outlined
                            label="Último Exame Periódico"
                            data-vv-delay="1000"
                        />
                    </v-col>
                    <v-col class="pt-0" cols="12" md="3">
                        <DateTimePickerV2
                            v-model="colaborador.data_proximo_exame_periodico"
                            outlined
                            label="Próximo Exame Periódico"
                            data-vv-delay="1000"
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3">
                        <v-select
                            dense
                            v-model="colaborador.pesquisa_gerenciador_risco"
                            outlined
                            :items="itemsSwitch"
                            label="Pesquisa GR"
                            item-text="label"
                            item-value="value"
                            clearable
                        >
                        </v-select>
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3">
                        <v-select
                            dense
                            v-model="colaborador.status"
                            outlined
                            :items="itemsStatus"
                            label="Status"
                            item-text="label"
                            item-value="value"
                            clearable
                        >
                        </v-select>
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3" v-show="mostraAfastamento">
                        <DateTimePickerV2
                            v-model="colaborador.data_saida_afastamento"
                            outlined
                            :dataInicial="colaborador.data_saida_afastamento"
                            label="Data Saída Afastamento"
                            data-vv-delay="1000"
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3" v-show="mostraAfastamento">
                        <DateTimePickerV2
                            v-model="colaborador.data_retorno_afastamento"
                            outlined
                            :dataInicial="colaborador.data_retorno_afastamento"
                            label="Data Retorno Afastamento"
                            data-vv-delay="1000"
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3" v-show="mostraSuspensao">
                        <DateTimePickerV2
                            v-model="colaborador.data_saida_suspensao"
                            outlined
                            :dataInicial="colaborador.data_saida_suspensao"
                            label="Data Saída Suspensão"
                            data-vv-delay="1000"
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3" v-show="mostraSuspensao">
                        <DateTimePickerV2
                            v-model="colaborador.data_retorno_suspensao"
                            outlined
                            :dataInicial="colaborador.data_retorno_suspensao"
                            label="Data Retorno Suspensão"
                            data-vv-delay="1000"
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3">
                        <v-select
                            dense
                            v-model="colaborador.ativo"
                            outlined
                            :items="itemsSwitch"
                            label="Ativo"
                            item-text="label"
                            item-value="value"
                            clearable
                            :rules="regraAtivo"
                        >
                        </v-select>
                    </v-col>

                    <v-col class="pt-0" cols="12" md="3" v-show="mostraDataDesligamento">
                        <DateTimePickerV2
                            v-model="colaborador.data_desligamento"
                            outlined
                            :dataInicial="colaborador.data_desligamento"
                            :label="labelDataDesligamento"
                            data-vv-delay="1000"
                        />
                    </v-col>

                    <v-col class="pt-0" cols="12">
                        <v-textarea
                            v-model="colaborador.observacao"
                            outlined
                            label="Observacao"
                            data-vv-delay="1000"
                            :rules="regraObservacao"
                            clearable
                            counter="500"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-form>
    </div>
</template>

<script>
import { API_URL, API_URL_BASE } from '@/config/configs';
import DateTimePickerV2 from '@/components/Form/DateTimePickerV2.vue';
import ApiService from '@/services/ApiService.js';
import moment, { now } from 'moment';
import { mask } from 'maska';
import CargoEnum from '@/Enum/CargoEnum';
import StatusColaboradorEnum from '@/Enum/StatusColaboradorEnum';
import { isEmpty, isBoolean } from 'lodash';
import Avatar from '@/components/Upload/Avatar.vue';
import InputAutoCompleteV3 from '@/components/Form/InputAutoCompleteV3.vue';

export default {
    name: 'ColaboradorForm',
    components: {
        DateTimePickerV2,
        Avatar,
        InputAutoCompleteV3
    },
    props: {
        colaboradorData: {
            type: Object,
            default() {
                return {};
            }
        },
        parceiroData: {
            type: Object,
            default() {
                return {};
            }
        },
        itensGestorData: {
            type: Array,
            default() {
                return [];
            }
        },
        itemsUFRG: {
            type: Array,
            default() {
                return [];
            }
        }
    },

    mounted() {
        this.getFilial();
        this.getUnidadeEmpreendimento();
        this.getTipoContratacao();
        this.getSindicato();
        this.getStatusContrato();
        this.getCargo();
        this.getDepartamento();
        this.getStatus();
    },

    watch: {
        colaboradorData: {
            handler(data) {
                this.colaborador = data;
            },
            deep: true
        },
        parceiroData: {
            handler(data) {
                this.parceiro = data;
            },
            deep: true
        },
        itensGestorData: {
            handler(data) {
                this.itemsGestor = data;
            },
            deep: true
        },
        'colaborador.data_admissao': function(data) {
            if (this.parceiro.tipo_pessoa !== 'J' && !isEmpty(data)) {
                this.colaborador.data_prevista_experiencia = moment(data)
                    .add(3, 'months')
                    .format('YYYY-MM-DD');

                return;
            }

            this.colaborador.data_prevista_experiencia = '';
        },

        'parceiro.tipo_pessoa': function(tipo_pessoa) {
            if (tipo_pessoa === 'J') {
                this.colaborador.data_prevista_experiencia = '';

                return;
            }

            this.parceiro.razao_social = '';
            this.parceiro.representante_legal = '';
            this.parceiro.nome_fantasia = '';
            this.parceiro.inscricao_estadual = '';
            this.parceiro.inscricao_municipal = '';
            this.parceiro.cnae = '';
            this.cnpj = '';

            this.colaborador.data_prevista_experiencia = isEmpty(this.colaborador.data_admissao)
                ? ''
                : moment(this.colaborador.data_admissao)
                      .add(3, 'months')
                      .format('YYYY-MM-DD');
        }
    },

    computed: {
        maskCPF: {
            get: function() {
                return isEmpty(this.parceiro.cpf) ? '' : mask(this.parceiro.cpf, this.cpfMask);
            },
            set: function(newValue) {
                this.parceiro.cpf = newValue;

                return newValue;
            }
        },
        maskCNPJ: {
            get: function() {
                return isEmpty(this.parceiro.cnpj) ? '' : mask(this.parceiro.cnpj, this.cnpjMask);
            },
            set: function(newValue) {
                this.parceiro.cnpj = newValue;

                return newValue;
            }
        },
        maskPIS: {
            get: function() {
                return this.colaborador.pis ? mask(this.colaborador.pis, this.pisMask) : '';
            },
            set: function(newValue) {
                this.colaborador.pis = newValue;

                return newValue;
            }
        },
        maskCNAE: {
            get: function() {
                return this.parceiro.cnae ? mask(this.parceiro.cnae, this.cnaeMask) : '';
            },
            set: function(newValue) {
                this.parceiro.cnae = newValue;

                return newValue;
            }
        },
        ehPF() {
            return this.parceiro.tipo_pessoa !== 'J';
        },

        ehPJ() {
            return this.parceiro.tipo_pessoa === 'J';
        },

        labelDataAdmissao() {
            if (this.parceiro.tipo_pessoa === 'J') {
                return 'Data Inicio Contrato *';
            }

            return 'Data de Admissão *';
        },

        labelSalario() {
            if (this.parceiro.tipo_pessoa === 'J') {
                return 'Valor Prestacao Servico';
            }

            return 'Salário *';
        },

        labelDataDesligamento() {
            if (this.parceiro.tipo_pessoa === 'J') {
                return 'Data Encerramento Contrato';
            }

            return 'Data Desligamento';
        },

        mostraDataDesligamento() {
            return this.colaborador.ativo === false;
        },

        mostraAfastamento() {
            return this.colaborador.status === StatusColaboradorEnum.afastado;
        },

        mostraSuspensao() {
            return this.colaborador.status === StatusColaboradorEnum.suspenso;
        }
    },

    data() {
        return {
            colaborador: this.colaboradorData,
            parceiro: {},
            uploadUrl: 'img/upload',
            imagemColaborador: null,
            itemsGestor: [],
            itemsSindicato: [],
            itemsFilial: [],
            itemsUnidadeEmpreendimento: [],
            itemsStatusContrato: [],
            itemsCargo: [],
            itemsDepartamento: [],
            itemsStatus: [],
            itemsTipoContratacao: [],
            itemsSwitch: [
                { label: 'Sim', value: true },
                { label: 'Não', value: false }
            ],
            autoCompleta: {
                gestor: {
                    datatable: {
                        id_datatable: 'gestor_id_colaborador',
                        chave_primaria: 'gestor_id_colaborador',
                        url: `${API_URL_BASE}${API_URL.erp.colaborador.datatable}`,
                        urlBusca: `${API_URL_BASE}${API_URL.erp.colaborador.autoCompletaCampo}`,
                        colunas: [
                            {
                                id: 'id_colaborador',
                                nome: 'id_colaborador',
                                descricao: 'ID'
                            },
                            {
                                id: 'descricao',
                                nome: 'descricao',
                                descricao: 'Descrição'
                            }
                        ]
                    }
                }
            },

            money: {
                locale: 'pt-BR',
                prefix: 'R$',
                suffix: '',
                length: 9,
                precision: 2
            },

            pisMask: '###.#####.##-#',

            regraMatricula: [
                v => !!v || 'Matrícula é obrigatório',
                v => (v && v?.length <= 36) || 'Matrícula deve ter até 11 caracteres'
            ],

            regraSalario: [v => !!v || 'Salário é obrigatório'],

            regraPis: [v => isEmpty(v) || v.length === 14 || 'PIS deve ter 14 caracteres'],

            regraNomePaiOuMae: [
                v => isEmpty(v) || (v?.length > 3 && v?.length <= 150) || 'Nome deve ter entre 3 e 150 caracteres',
                v => !/\d/.test(v) || 'Nome não pode ter caracteres numericos'
            ],

            regraRazaoSocial: [
                v =>
                    (!isEmpty(v) && this.parceiro.tipo_pessoa === 'J') ||
                    (isEmpty(v) && this.ehPF) ||
                    'Razão social é obrigatório para PJ',
                v => isEmpty(v) || (v?.length > 3 && v?.length <= 150) || 'Razão social deve ter entre 3 e 150 caracteres'
            ],

            regraNomefantasia: [
                v =>
                    (!isEmpty(v) && this.parceiro.tipo_pessoa === 'J') ||
                    (isEmpty(v) && this.ehPF) ||
                    'Nome fantasia é obrigatório para PJ',
                v => isEmpty(v) || (v?.length > 3 && v?.length <= 150) || 'Nome fantasia deve ter entre 3 e 150 caracteres',
                v => !/\d/.test(v) || 'Nome fantasia não pode ter caracteres numericos'
            ],

            regraRepresentanteLegal: [
                v =>
                    (!isEmpty(v) && this.parceiro.tipo_pessoa === 'J') ||
                    (isEmpty(v) && this.ehPF) ||
                    'Representante legal é obrigatório para PJ',
                v => isEmpty(v) || (v?.length > 3 && v?.length <= 150) || 'Nome deve ter entre 3 e 150 caracteres',
                v => !/\d/.test(v) || 'Representante legal não pode ter caracteres numericos'
            ],

            regraCNH: [
                v =>
                    (!isEmpty(v) && this.colaborador.id_cargo === CargoEnum.motorista) ||
                    this.colaborador.id_cargo !== CargoEnum.motorista ||
                    'CNH obrigatório para motorista',
                v => isEmpty(v) || v.length === 11 || 'Nome deve ter 11 caracteres'
            ],

            regraVencimentoCNH: [
                v =>
                    (!isEmpty(v) && this.colaborador.id_cargo === CargoEnum.motorista) ||
                    this.colaborador.id_cargo !== CargoEnum.motorista ||
                    'Data CNH obrigatório para motorista'
            ],

            regraDataToxicologico: [
                v =>
                    (!isEmpty(v) && this.colaborador.id_cargo === CargoEnum.motorista) ||
                    this.colaborador.id_cargo !== CargoEnum.motorista ||
                    'Data Toxicológico obrigatório para motorista'
            ],

            regraDataMOPP: [
                v =>
                    (!isEmpty(v) && this.colaborador.id_cargo === CargoEnum.motorista) ||
                    this.colaborador.id_cargo !== CargoEnum.motorista ||
                    'Data MOPP obrigatório para motorista'
            ],

            regraAtivo: [v => isBoolean(v) || 'Ativo é obrigatório'],

            regraObservacao: [v => isEmpty(v) || v.length <= 500 || 'Observação deve ter até 500 caracteres'],

            cnaeMask: '##.##-#-##',

            cpfMask: '###.###.###-##',

            cnpjMask: '##.###.###/####-##',

            regraNome: [
                v => !!v || 'Nome é obrigatório',
                v => (v?.length > 3 && v?.length <= 150) || 'Nome deve ter entre 3 e 150 caracteres',
                v => !/\d/.test(v) || 'Nome não pode ter caracteres numericos'
            ],

            regraInscricaoMunicipal: [v => isEmpty(v) || v?.length === 11 || 'Inscricão Municipal deve ter 11 caracteres'],

            regraInscricaoEstadual: [v => isEmpty(v) || v?.length === 8 || 'Inscricão Estadual deve ter 8 caracteres'],

            regraContribuinteICms: [v => isEmpty(v) || v?.length <= 10 || 'Contribuinte ICMS deve ter até 10 caracteres'],

            regraRg: [
                v => !!v || 'RG é obrigatório',
                v => (!isEmpty(v) && v.length <= 9 && v.length > 6) || 'RG deve ter entre 7 e 9 caracteres'
            ],

            regraOrgaoEmissorRg: [v => isEmpty(v) || v?.length < 6 || 'RG deve ter até 6 caracteres'],

            regraCnae: [v => isEmpty(v) || v?.length === 10 || 'CNAE deve ter 10 caracteres'],

            regraTipoPessoa: [
                v => !!v || 'Tipo Pessoa é obrigatório',
                v => (v && v.length === 1 && ['F', 'J'].includes(v)) || 'Tipo Pessoa deve ser F ou J'
            ],

            regraCNPJ: [
                v => (this.parceiro.tipo_pessoa === 'J' && !isEmpty(v)) || this.ehPF || 'CNPJ é obrigatorio para PJ',
                v => isEmpty(v) || v.length === 18 || 'CNPJ deve ter 18 caracteres'
            ],

            regraCPF: [v => !!v || 'CPF é obrigatório', v => isEmpty(v) || v.length === 14 || 'CPF deve ter 14 caracteres'],

            regraDataEmissaoRG: [
                v =>
                    isEmpty(v) ||
                    moment(v, 'DD/MM/YYYY')
                        .startOf('day')
                        .isBefore(
                            moment()
                                .add(1, 'day')
                                .startOf('day')
                        ) ||
                    'Data de emissão do RG não pode ser no futuro'
            ],

            regraObservacao: [v => isEmpty(v) || v.length <= 500 || 'Observação deve ter até 500 caracteres'],

            itemsTipoPessao: [
                { label: 'Física', value: 'F' },
                { label: 'Jurídica', value: 'J' }
            ]
        };
    },

    methods: {
        avatarUpload(imagemBase64) {
            this.imagemColaborador = imagemBase64;
        },
        avatarUploadLimpa() {
            this.imagemColaborador = null;
        },
        async getFilial() {
            await ApiService({
                method: 'get',
                url: `V1/filial/lista?fields[filial]=id_filial,filial_descricao`,
                data: {}
            })
                .then(resposta => {
                    this.itemsFilial = resposta.data.data.map(filial => {
                        const dados = {};

                        dados.value = filial.id_registro;
                        dados.label = filial.atributos.filial_descricao;

                        return dados;
                    });
                })
                .catch(erro => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        },
        async getUnidadeEmpreendimento() {
            await ApiService({
                method: 'get',
                url: `V1/unidadeempreendimento/lista?fields=empresa,codigo`,
                data: {}
            })
                .then(resposta => {
                    this.itemsUnidadeEmpreendimento = resposta.data.data.map(item => {
                        return {
                            label: `${item.atributos.codigo}`,
                            value: item.id_registro
                        };
                    });
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        },

        async getTipoContratacao() {
            await ApiService({
                method: 'get',
                url: `V1/tipo-contratacao/lista?fields=descricao`,
                data: {}
            })
                .then(resposta => {
                    this.itemsTipoContratacao = resposta.data.data.map(item => {
                        return {
                            label: item.atributos.descricao,
                            value: item.id_registro
                        };
                    });
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        },

        async getSindicato() {
            await ApiService({
                method: 'get',
                url: `V1/sindicato/lista?fields=descricao`,
                data: {}
            })
                .then(resposta => {
                    this.itemsSindicato = resposta.data.data.map(item => {
                        return {
                            label: item.atributos.descricao,
                            value: item.id_registro
                        };
                    });
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        },

        async getStatusContrato() {
            await ApiService({
                method: 'get',
                url: `V1/colaborador/contrato/lista/tipo`,
                data: {}
            })
                .then(resposta => {
                    this.itemsStatusContrato = resposta.data.data.map(item => {
                        return {
                            label: item.atributos.descricao,
                            value: item.id_registro
                        };
                    });
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        },

        async getCargo() {
            await ApiService({
                method: 'get',
                url: `V1/cargo/lista?fields=nome`,
                data: {}
            })
                .then(resposta => {
                    this.itemsCargo = resposta.data.data.map(item => {
                        return {
                            label: item.atributos.nome,
                            value: item.id_registro
                        };
                    });
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        },

        async getDepartamento() {
            await ApiService({
                method: 'get',
                url: `V1/departamento/lista?fields=nome`,
                data: {}
            })
                .then(resposta => {
                    this.itemsDepartamento = resposta.data.data.map(item => {
                        return {
                            label: item.atributos.nome,
                            value: item.id_registro
                        };
                    });
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        },

        async getStatus() {
            await ApiService({
                method: 'get',
                url: `V1/colaborador/status/lista`,
                data: {}
            })
                .then(resposta => {
                    this.itemsStatus = resposta.data.data.map(item => {
                        return {
                            label: item.atributos.descricao,
                            value: item.id_registro
                        };
                    });
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        },

        preparaDados() {
            let colaborador = { ...this.colaborador };

            colaborador.salario = Number(colaborador.salario).toFixed(2);

            colaborador.pis = colaborador.pis?.replace(/\D/g, '');

            return colaborador;
        },

        preparaDadosParceiro() {
            const parceiro = { ...this.parceiro };

            parceiro.nome = isEmpty(parceiro.nome) ? null : parceiro.nome;
            parceiro.cpf = isEmpty(parceiro.cpf) ? null : parceiro.cpf.replace(/\D/g, '');
            parceiro.cnpj = isEmpty(parceiro.cnpj) ? null : parceiro.cnpj.replace(/\D/g, '');
            parceiro.cnae = isEmpty(parceiro.cnae) ? null : parceiro.cnae.replace(/\D/g, '');

            parceiro.tipo_parceiro = 1;

            return parceiro;
        },

        preencheDados(colaborador) {
            this.colaborador = { ...colaborador };

            delete this.colaborador.colaborador_gestor;

            if (colaborador.colaborador_gestor) {
                this.colaborador.id_colaborador_gestor = colaborador.colaborador_gestor.id_colaborador;

                this.itemsGestor = [
                    {
                        id_colaborador: colaborador.colaborador_gestor.id_colaborador,
                        descricao: colaborador.colaborador_gestor.parceiro_gestor.nome
                    }
                ];
            }

            this.colaborador.pis = colaborador.pis ? mask(colaborador.pis, this.pisMask) : '';

            this.colaborador.data_admissao = moment(colaborador.data_admissao).format('YYYY-MM-DD');

            this.colaborador.data_nascimento = moment(colaborador.data_nascimento).format('YYYY-MM-DD');

            this.colaborador.data_vencimento_cnh = colaborador.data_vencimento_cnh
                ? moment(colaborador.data_vencimento_cnh).format('YYYY-MM-DD')
                : null;

            this.colaborador.data_vencimento_toxicologico = colaborador.data_vencimento_toxicologico
                ? moment(colaborador.data_vencimento_toxicologico).format('YYYY-MM-DD')
                : null;

            this.colaborador.data_vencimento_movimentacao_produto_perigoso = colaborador.data_vencimento_movimentacao_produto_perigoso
                ? moment(colaborador.data_vencimento_movimentacao_produto_perigoso).format('YYYY-MM-DD')
                : null;

            this.colaborador.data_ultimo_exame_periodico = colaborador.data_ultimo_exame_periodico
                ? moment(colaborador.data_ultimo_exame_periodico).format('YYYY-MM-DD')
                : null;

            this.colaborador.data_proximo_exame_periodico = colaborador.data_proximo_exame_periodico
                ? moment(colaborador.data_proximo_exame_periodico).format('YYYY-MM-DD')
                : null;

            this.colaborador.data_prevista_experiencia = colaborador.data_prevista_experiencia
                ? moment(colaborador.data_prevista_experiencia).format('YYYY-MM-DD')
                : null;

            this.colaborador.data_desligamento = colaborador.data_desligamento
                ? moment(colaborador.data_desligamento).format('YYYY-MM-DD')
                : null;

            this.colaborador.ativo = colaborador.ativo ? true : false;

            this.colaborador.pesquisa_gerenciador_risco = colaborador.pesquisa_gerenciador_risco ? true : false;

            this.fotoUrl = colaborador.foto?.url;
        },

        preencheDadosParceiro(parceiro) {
            this.colaborador.parceiro.cpf = isEmpty(colaborador.parceiro.cpf)
                ? ''
                : mask(colaborador.parceiro.cpf, this.cpfMask);

            this.colaborador.parceiro.cnpj = isEmpty(colaborador.parceiro.cnpj)
                ? ''
                : mask(colaborador.parceiro.cnpj, this.cnpjMask);

            this.colaborador.parceiro.cnae = isEmpty(colaborador.parceiro.cnae)
                ? null
                : mask(colaborador.parceiro.cnae, this.cnaeMask);

            this.colaborador.parceiro.inscricao_estadual = isEmpty(colaborador.parceiro.inscricao_estadual)
                ? null
                : String(colaborador.parceiro.inscricao_estadual);

            this.colaborador.parceiro.inscricao_municipal = isEmpty(colaborador.parceiro.inscricao_municipal)
                ? null
                : String(colaborador.parceiro.inscricao_municipal);

            this.colaborador.parceiro.contribuinte_icms = isEmpty(colaborador.parceiro.contribuinte_icms)
                ? null
                : String(colaborador.parceiro.contribuinte_icms);

            this.colaborador.parceiro.data_emissao_rg = isEmpty(colaborador.parceiro.data_emissao_rg)
                ? null
                : moment(colaborador.parceiro.data_emissao_rg).format('YYYY-MM-DD');
        },
        async buscaParceiro(cnpjCpf, tipo) {
            /**
             * Não envia requisições quando já houver um registro
             * Pois ele tem que atualizar os dados, e se buscar ele repõe
             */
            if (this.colaborador.id_colaborador) {
                return;
            }

            if (!cnpjCpf) {
                return;
            }

            if (cnpjCpf.length != 14 && tipo == 'CPF') {
                return;
            }

            if (cnpjCpf.length != 18 && tipo == 'CNPJ') {
                return;
            }

            cnpjCpf = cnpjCpf.replace(/\D/g, '');

            await ApiService({
                method: 'get',
                url: `${API_URL.erp.colaborador.buscaParceiroPorCnpjCpf}/${cnpjCpf}`,
                data: {}
            })
                .then(resposta => {
                    if (resposta.data.data.atributos.length) {
                        const dados = {
                            conteudo: { atributos: { colaborador: {} } }
                        };

                        dados.conteudo.atributos.parceiro = resposta.data.data.atributos[0];

                        this.$emit('preencheDados', dados.conteudo.atributos);
                    }
                })
                .catch(erro => {
                    this.$emit('mensagemAlerta', erro.message, 'error');
                });
        }
    }
};
</script>

<style scoped></style>
