<template>
    <v-text-field
        :value="value"
        @input="$emit('input', $event)"
        :rules="dadosForm('regra')"
        :counter="dadosForm('tamanhoMaximo')"
        :label="dadosForm('rotulo')"
        :disabled="dadosForm('desativado')"
        :required="dadosForm('obrigatorio')"
        v-maska="dadosForm('campoFormatacao') || nomask"
        :masked="!!campoFormatacao"
        :type="type"
        data-vv-delay="1000"
        outlined
        dense
        clearable
    ></v-text-field>
</template>

<script>
import { mask } from 'vue-the-mask';
import { FormCampoRetornaDado } from '@/helpers/Componente.js';

export default {
    directives: { mask },
    props: ['value', 'form', 'campo', 'type'],
    async created() {},
    computed: {},
    mounted() {},
    methods: {
        dadosForm(campoSelecionado) {
            if (campoSelecionado == 'campoFormatacao') {
                let dados = FormCampoRetornaDado(
                    this.form,
                    this.campo,
                    campoSelecionado
                );

                this.campoFormatacao = dados;

                return dados;
            }

            return FormCampoRetornaDado(
                this.form,
                this.campo,
                campoSelecionado
            );
        }
    },
    data() {
        return {
            campoFormatacao: '',
            campoMascara: '',
            nomask: {
                mask: '*'.repeat(1000),
                tokens: {
                    '*': { pattern: /./ }
                }
            }
        };
    }
};
</script>

<style scoped>
.myDiv {
    padding-top: 10px;
}
</style>
