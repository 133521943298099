const montaColunasDatatable = (colunas) => {
    let cols = [];

    colunas.forEach(function (coluna, i) {
        cols.push({
            id: coluna.id,
            descricao: coluna.descricao,
            nome: coluna.nome,
        });
    });

    return cols;
}

const datatableGetLinhaSelecionadas = (modulo) => {
    let table = $(`#datatable_${modulo}`).DataTable();
    let selecionados = table.rows('.selected').data();
    let respSelecionados = []

    $.each(selecionados, function (index, value) {
        respSelecionados.push(value);
    });

    return respSelecionados;
}

export default {
    montaColunasDatatable,
    datatableGetLinhaSelecionadas
}
