<template>
    <div>
        <TituloModulo />

        <AlertaMensagem dense style="width: 100%" />

        <CarregandoLogoEmpresa :mensagem="mensagemCarregando" />

        <InstrucaoPreenchimento
            v-if="!avaliacaoIniciou && permissao"
            :nome_avaliacao="avaliacao_desempenho.nome"
            :nome_colaborador="avaliacao_desempenho.nome_colaborador"
            :nome_colaborador_gestor="avaliacao_desempenho.nome_colaborador_gestor"
            :orientacao="avaliacao_desempenho.orientacao"
            :orientacao_criterio="avaliacao_desempenho.orientacao_criterio"
            :criterios="avaliacao_desempenho.criterio"
            @iniciaAvaliacao="iniciaAvaliacao"
        />

        <v-card v-if="avaliacaoIniciou">
            <v-stepper v-model="step" alt-labels>
                <v-stepper-header>
                    <template v-for="(afirmacao, afirmacaoKey) in avaliacao_desempenho.afirmacao">
                        <v-stepper-step
                            :key="`${afirmacaoKey}-step`"
                            :step="afirmacaoKey + 1"
                            :complete="step > afirmacaoKey + 1"
                            color="deep-orange lighten-1"
                            style="margin: 0 10px 0 10px;"
                        >
                            <div class="text-center" style="max-width: 80px;;
                            ;">{{ afirmacao.nome }}</div>
                            <!-- {{ afirmacao.nome }} -->
                            
                        </v-stepper-step>

                        <v-divider v-if="afirmacaoKey + 1 !== totalAfirmacao" :key="afirmacaoKey"></v-divider>
                    </template>

                    <v-divider :key="totalAfirmacao + 1"></v-divider>

                    <v-stepper-step
                        :step="totalAfirmacao + 1"
                        :complete="step > totalAfirmacao + 1"
                        color="deep-orange lighten-1"
                    >
                        Terminou
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content
                        :step="afirmacaoKey + 1"
                        v-for="(afirmacao, afirmacaoKey) in avaliacao_desempenho.afirmacao"
                        :key="afirmacaoKey"
                    >
                        <v-card elevation="0">
                            <v-card-title>
                                <div class="text-xl-h4">
                                    Palavra Chave:
                                </div>
                            </v-card-title>

                            <v-card-subtitle>
                                <div class="text-md-body-1">
                                    {{ afirmacao.palavra_chave }}
                                </div>
                            </v-card-subtitle>

                            <v-card-title>
                                <div class="text-xl-h4">
                                    Considerações:
                                </div>
                            </v-card-title>

                            <v-card-subtitle>
                                <div class="text-md-body-1">
                                    {{ afirmacao.consideracao }}
                                </div>
                            </v-card-subtitle>

                            <v-card-title>
                                <div class="text-xl-h4">
                                    Sinalize em uma das notas abaixo, qual delas descreve melhor a sua atuação
                                </div>
                            </v-card-title>

                            <v-card-text>
                                <template v-for="(criterio, keyCriterio) in avaliacao_desempenho.criterio">
                                    <v-chip
                                        :key="keyCriterio"
                                        :color="criteriosSelecionado == keyCriterio ? 'green' : ''"
                                        @click="criteriosSelecionado = keyCriterio"
                                        class="ma-2"
                                    >
                                        <v-icon left v-if="criteriosSelecionado == keyCriterio">mdi-check-all </v-icon>
                                        <v-icon left v-else color="red">mdi-close-circle </v-icon>

                                        {{ criterio.nome }}
                                    </v-chip>
                                </template>

                                <TextAreaV3
                                    v-model="criteriosObservacao"
                                    :contador="3000"
                                    :obriatorio="true"
                                    :regra="regrasObservacao"
                                    rotulo="Observação"
                                />
                            </v-card-text>
                        </v-card>

                        <v-card-actions>
                            <v-row>
                                <v-col cols="12" class="text-right">
                                    <v-btn v-if="afirmacaoKey" color="mr-2 red darken-5" @click="anteriorStep(afirmacaoKey)">
                                        Voltar
                                    </v-btn>

                                    <v-btn
                                        v-if="!ultimaAfirmacao(afirmacaoKey)"
                                        color="primary"
                                        @click="proximoStep(afirmacaoKey)"
                                    >
                                        Próximo
                                    </v-btn>

                                    <v-btn
                                        v-if="ultimaAfirmacao(afirmacaoKey)"
                                        color="warning"
                                        @click="finaliza(afirmacaoKey)"
                                    >
                                        Terminou ?
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-stepper-content>

                    <v-stepper-content :step="totalAfirmacao + 1">
                        <v-card-text v-if="!avaliacaoFinalizada">
                            Aguarde sua solicitação está sendo processada...
                            <v-progress-circular :size="15" color="green" indeterminate></v-progress-circular>
                        </v-card-text>

                        <v-card-text v-if="avaliacaoFinalizada">
                            Sua avaliação de desempenho foi recebida com sucesso, muito obrigado por participar e resposnder a
                            avaliação.

                            <br />

                            Você será redirecionado para a página de avaliação em {{ tempoRedireciona }}
                        </v-card-text>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { Maximo } from '@/helpers/ValidadorCampo.js';
import { PreencheDadosMesmaPropriedade } from '@/helpers/Funcoes.js';
import { Alerta, sleep } from '@/helpers/Funcoes.js';

import TituloModulo from '@/components/Default/TituloModulo.vue';
import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import InstrucaoPreenchimento from '@/views/AvaliacaoDesempenho/InstrucaoPreenchimento.vue';

import InputSelectV3 from '@/components/Form/InputSelectV3.vue';
import TextAreaV3 from '@/components/Form/TextAreaV3.vue';

export default {
    name: 'FormResposta',
    components: {
        TituloModulo,
        CarregandoLogoEmpresa,
        AlertaMensagem,
        InstrucaoPreenchimento,
        InputSelectV3,
        TextAreaV3
    },
    async created() {
        this.id_avaliacao_desempenho_avaliado = parseInt(this.$route.params.id_avaliacao_desempenho_avaliado) ?? null;

        await this.buscaFormAvaliado();
    },
    async mounted() {},
    computed: {
        ...mapGetters(['botoesPorForm', 'carregando']),
        totalAfirmacao() {
            return this.avaliacao_desempenho.afirmacao.length ?? 0;
        }
    },
    watch: {},
    methods: {
        ...mapActions(['mostraCarregando']),
        async buscaFormAvaliado() {
            try {
                this.mostraCarregando(true);

                this.mensagemCarregando = 'Preparando os dados para resposta, aguarde...';

                let urlConfig = {
                    method: 'GET',
                    url: `${API_URL.erp.avaliacaodesempenho.buscaAvaliacaoAvaliado}/${this.id_avaliacao_desempenho_avaliado}`,
                    data: {},
                    mostraCarregando: false
                };

                await ApiService(urlConfig)
                    .then(resposta => {
                        const id_avaliacao_desempenho = resposta.data.data.id_registro;

                        resposta.data.data.atributos.id_avaliacao_desempenho = id_avaliacao_desempenho;

                        const avaliacao = PreencheDadosMesmaPropriedade(
                            this.avaliacao_desempenho,
                            resposta.data.data.atributos
                        );

                        // CRIA A ESTRUTURA DO FORMA, QNDO PUXA OS DADOS
                        // POIS É DINAMICO
                        this.form.criterios = avaliacao.afirmacao.map(avaliacao => {
                            return {
                                id_avaliacao_desempenho_criterio: null,
                                id_avaliacao_desempenho_afirmacao: null,
                                observacao: null
                            };
                        });

                        this.avaliacao_desempenho = avaliacao;

                        this.permissao = true;
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                Alerta([erro.message], 'error');
            } finally {
                this.mostraCarregando(false);
            }
        },
        criterioSelecionado(posicao) {
            const indice = this.form.criterios.findIndex(index => index == posicao);

            if (indice >= 0) {
                return true;
            }

            return false;
        },
        adicionaCriterio(afirmacaoPosicao) {
            this.form.criterios[afirmacaoPosicao] = {
                id_avaliacao_desempenho_afirmacao: this.avaliacao_desempenho.afirmacao[afirmacaoPosicao]
                    .id_avaliacao_desempenho_afirmacao,
                id_avaliacao_desempenho_criterio: this.avaliacao_desempenho.criterio[this.criteriosSelecionado]
                    .id_avaliacao_desempenho_criterio,
                observacao: this.criteriosObservacao
            };

            this.criteriosSelecionado = this.criterioSelecionado;

            this.criteriosSelecionado = null;
            this.criteriosObservacao = null;
        },
        removeCriterio(posicao) {
            const posicaoStep = posicao - 1;
            const id_avaliacao_desempenho_criterio = this.form.criterios[posicaoStep].id_avaliacao_desempenho_criterio;

            this.form.criterios[posicaoStep] = {
                id_avaliacao_desempenho_afirmacao: null,
                id_avaliacao_desempenho_criterio: null,
                observacao: null
            };

            this.criteriosSelecionado = this.avaliacao_desempenho.criterio
                .map(e => e.id_avaliacao_desempenho_criterio)
                .indexOf(id_avaliacao_desempenho_criterio);

            this.criteriosObservacao = this.form.criterios[posicaoStep].observacao;
        },
        async finaliza(afirmacaoKey) {
            try {
                await this.$root.$refs.$confirmaAcaoModal
                    .open('Finalizar', 'Terminou ?', {
                        cor: 'green'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        if (!this.proximoStep(afirmacaoKey)) {
                            return false;
                        }

                        await sleep(2000);

                        let conteudoFormulario = this.FormataDadosParaEnviarForm();

                        let urlConfig = {
                            method: 'POST',
                            url: `${API_URL.erp.avaliacaodesempenho.salvaResposta}`,
                            data: conteudoFormulario,
                            mostraCarregando: false
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                this.avaliacaoFinalizada = true;

                                this.iniciaContagem();
                            })
                            .catch(erro => {
                                this.anteriorStep(this.totalAfirmacao);

                                Alerta([erro.message], 'error');
                            });
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        FormataDadosParaEnviarForm() {
            const self = this;

            let dados = {
                resposta: this.form.criterios.map(item => {
                    item.id_avaliacao_desempenho = self.avaliacao_desempenho.id_avaliacao_desempenho;
                    item.id_avaliacao_desempenho = self.avaliacao_desempenho.id_avaliacao_desempenho;
                    item.id_avaliacao_desempenho_avaliados = self.id_avaliacao_desempenho_avaliado;

                    return item;
                })
            };

            return {
                dados
            };
        },
        cancelaForm() {
            this.$router.push({ name: 'avaliacao-desempenho' });
        },
        botoes() {
            return [
                {
                    nome: 'Salvar',
                    acao: 'salvaForm',
                    icone: 'mdi-newspaper-variant-multiple',
                    cor: 'green'
                },
                {
                    nome: 'Fechar',
                    acao: 'cancelaForm',
                    icone: 'mdi-file-cancel',
                    cor: 'orange'
                }
            ];
        },
        proximoStep(afirmacaoKey) {
            if (this.criteriosSelecionado == null) {
                this.notificacao = {
                    mensagem: 'Selecione um critério para seguir a próxima etapa.',
                    tipo: 'error',
                    mostra: true
                };

                return false;
            }

            this.adicionaCriterio(afirmacaoKey);

            this.step += 1;

            return true;
        },
        anteriorStep(posicao) {
            this.removeCriterio(posicao);

            this.step -= 1;
        },
        ultimaAfirmacao(posicao) {
            return this.avaliacao_desempenho.afirmacao.length - 1 == posicao;
        },
        iniciaAvaliacao() {
            this.avaliacaoIniciou = true;
        },
        iniciaContagem() {
            this.intervalo = setInterval(() => {
                if (this.tempoRedireciona === 0) {
                    clearInterval(this.intervalo);

                    this.$router.push({
                        name: 'avaliacao-desempenho-resposta',
                        params: {}
                    });
                }

                this.tempoRedireciona -= 1;
            }, 500);
        }
    },
    data() {
        return {
            permissao: false,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            form: {
                criterios: []
            },
            mensagemCarregando: 'Buscando, aguarde...',
            intervalo: {},
            tempoRedireciona: 15,
            avaliacaoIniciou: false,
            avaliacaoFinalizada: false,
            criteriosSelecionado: null,
            criteriosObservacao: null,
            step: 1,
            regrasCriterio: [v => !!v || 'Campo é obrigatório'],
            regrasObservacao: [Maximo(3000)],
            id_avaliacao_desempenho_avaliado: null,
            avaliacao_desempenho: {
                id_avaliacao_desempenho: null,
                nome_colaborador: null,
                nome_colaborador_gestor: null,
                nome: null,
                orientacao: null,
                orientacao_criterio: null,
                criterio: [
                    {
                        id_avaliacao_desempenho_criterio: null,
                        nome: null,
                        peso: null
                    }
                ],
                afirmacao: [
                    {
                        id_avaliacao_desempenho_afirmacao: null,
                        nome: null,
                        palavra_chave: null,
                        consideracao: null
                    }
                ]
            }
        };
    }
};
</script>

<style></style>
