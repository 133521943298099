<template>
    <v-overlay :absolute="true" :value="overlay">
        <CarregandoLogoEmpresa :carregando="true" :mensagem="mensagem" />
    </v-overlay>
</template>

<script>
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';

export default {
    name: 'CarregandoOverlay',
    components: {
        CarregandoLogoEmpresa
    },
    props: {
        overlay: {
            type: Boolean,
            default: false
        },
        mensagem: {
            type: String,
            default: 'Preparando os dados, aguarde...'
        }
    }
};
</script>
