<template>
    <v-card elevation="3">
        <v-card-actions>
            <v-list-item>
                <v-list-item-avatar size="70">
                    <v-img :src="imagemAvatar(dados.imagem_avatar)"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-content class="body-1">
                        <strong>{{ dados.motorista }}</strong>
                    </v-list-item-content>

                    <v-list-item-subtitle>
                        <v-chip class="ma-2" color="pink darken-1" label text-color="white">
                            <v-icon left>
                                mdi-cellphone-message
                            </v-icon>
                            <span v-if="dados.telefone_celular">
                                {{ dados.telefone_celular | VMask('+55 (##) # ####-####') }}
                            </span>
                            <span v-else>
                                SEM CADASTRO
                            </span>
                        </v-chip>

                        <v-icon color="green" v-if="dados.mensagem_enviada == 'S'">
                            mdi-check-all
                        </v-icon>
                        <v-icon v-else>
                            mdi-check
                        </v-icon>

                        <v-tooltip bottom v-if="mensagemComErro">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="red accent-3" class="pl-1" v-bind="attrs" v-on="on">
                                    mdi-alert-circle
                                </v-icon>
                            </template>
                            <span>{{ mensagemErro }}</span>
                        </v-tooltip>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-spacer></v-spacer>

            <span v-if="!dados.enviando_mensagem">
                <v-icon
                    :color="dados.mensagem_enviada == 'S' ? 'green accent-4' : 'red accent-4'"
                    class="mr-2"
                    @click="enviaRomaneioDisponivel()"
                    large
                >
                    mdi-whatsapp
                </v-icon>
            </span>

            <span class="text-caption" v-else>
                Enviando ...
                <v-progress-circular indeterminate color="warning" :size="20" :width="2"></v-progress-circular>
            </span>
        </v-card-actions>

        <v-card-actions>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="body-2">
                        <span class="font-weight-bold body-2">ROMANEIO:</span> {{ dados.id_alocacao_carga_romaneio }}
                        <span v-if="dados.data_hora_inicio">
                            <span class="font-weight-bold body-2">INÍCIO:</span>
                            {{ dados.data_hora_inicio }} hs
                        </span>
                    </v-list-item-title>

                    <v-list-item-title class="body-2">
                        <span class="font-weight-bold body-2">PLACA:</span> {{ dados.placa_veiculo_cavalo }} -
                        {{ dados.veiculo_frota }} - {{ dados.veiculo_modelo }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-card-actions>

        <v-card-actions>
            <v-btn color="orange lighten-2" @click="show = !show" text>
                Cargas
            </v-btn>

            <v-chip class="ma-2" color="light-blue lighten-2" text-color="white" label small>
                {{ dados.cargas.length }}
            </v-chip>

            <v-spacer></v-spacer>

            <v-btn icon @click="show = !show">
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
        </v-card-actions>

        <v-expand-transition>
            <div v-show="show">
                <v-card-text>
                    <v-data-table :headers="cabecalho" :items="dados.cargas" :items-per-page="20" class="elevation-1">
                    </v-data-table>
                </v-card-text>
            </div>
        </v-expand-transition>

        <v-snackbar v-model="notificacao.mostra" :multi-line="true" color="success" timeout="2000" right>
            {{ notificacao.mensagem }}

            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="notificacao.mostra = false">
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
export default {
    name: 'RomaneiosDisponiveis',
    props: {
        dados: {
            type: Object,
            default: () => {}
        },
        mensagemErro: {
            type: String,
            default: () => ''
        }
    },
    mounted() {},
    watch: {},
    computed: {
        mensagemComErro() {
            return this.mensagemErro.length ? true : false;
        }
    },
    methods: {
        enviaRomaneioDisponivel() {
            this.$emit('romaneio-disponivel', { ...this.dados });
        },
        imagemAvatar(imagemAvatar) {
            return imagemAvatar ?? require('@/assets/image/perfil/img_avatar.png');
        }
    },
    data() {
        return {
            show: false,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            cabecalho: [
                {
                    text: 'Ordem',
                    sortable: false,
                    value: 'ordem',
                    width: '80px'
                },
                {
                    text: 'Prog. Carga',
                    sortable: false,
                    value: 'id_programacao_carga_rodopar',
                    width: '100px'
                },
                {
                    text: 'CT-e',
                    sortable: false,
                    value: 'numero_cte',
                    width: '110px'
                },
                {
                    text: 'Cliente',
                    sortable: false,
                    value: 'cliente',
                    width: '150px'
                },
                {
                    text: 'Coleta',
                    sortable: false,
                    value: 'terminal_coleta',
                    width: '150px'
                },
                {
                    text: 'Entrega',
                    sortable: false,
                    value: 'terminal_entrega',
                    width: '150px'
                },
                {
                    text: 'Observação Entrega',
                    sortable: false,
                    value: 'observacao_gerais',
                    width: '150px'
                }
            ]
        };
    }
};
</script>

<style scoped>
.v-data-table {
    overflow-x: auto !important;
}
</style>
