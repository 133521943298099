<template>
    <div>
        <TituloModulo />

        <AlertaMensagem dense style="width: 100%" />

        <CarregandoLogoEmpresa :mensagem="mensagemCarregando" />

        <BarraNavegacaoBotaoV3
            v-if="!carregando.mostraCarregando && permissao"
            :botoes="botoesComPermissao(devolucaoVazioSituacao)"
        />

        <v-row v-if="!carregando.mostraCarregando && permissao">
            <v-col cols="12" class="text-right">
                <TabelaVuetify
                    ref="tabela"
                    :cabecalho="datatable.cabecalho"
                    :itens="datatable.itens"
                    :total_registros="datatable.totalRegistros"
                    :por_pagina="datatable.por_pagina"
                    :ultima_pagina="datatable.ultima_pagina"
                    :carregando="datatable.carregando"
                    :chave_primaria="datatable.chave_primaria"
                    :ordenar_por="datatable.ordenarPor"
                    :ordenar_direcao="datatable.ordenarDirecao"
                    @opcoes="buscaDevolucaoVazioItens"
                >
                    <template v-slot:item.situacao="{ item }">
                        <v-chip small class="ma-2" :color="selecionaCor(item.id_situacao)" text-color="white">
                            <v-icon small left>
                                mdi-alert-circle-outline
                            </v-icon>
                            {{ item.situacao }}
                        </v-chip>
                    </template>

                    <template v-slot:item.acao="{ item }">
                        <v-icon small class="mr-2" @click="editaItem(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                </TabelaVuetify>
            </v-col>
        </v-row>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { Alerta, ExportaArrayObjectsParaExcel } from '@/helpers/Funcoes.js';

import SituacaoForm from '@/Enum/DevolucaoVazio/SituacaoForm.js';
import Situacao, { situacaoDescricao } from '@/Enum/DevolucaoVazio/Situacao.js';
import { descricao as motivoBaixaDescricao } from '@/Enum/DevolucaoVazio/MotivoBaixa.js';
import { descricao as parceiroBaixaDescricao } from '@/Enum/DevolucaoVazio/ParceiroBaixa.js';

import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import ComboBoxPreenchimentoV3 from '@/components/Form/ComboBoxPreenchimentoV3.vue';
import TabelaVuetify from '@/components/Default/TabelaVuetify.vue';

export default {
    name: 'Grid',
    components: {
        AlertaMensagem,
        BarraNavegacaoBotaoV3,
        CarregandoLogoEmpresa,
        TituloModulo,
        ComboBoxPreenchimentoV3,
        TabelaVuetify
    },
    async mounted() {
        this.mostraCarregando(true);

        await this.dadosPaginaInicial();

        this.mostraCarregando(false);

        if (this.$route.params.mensagem) {
            Alerta([this.$route.params.mensagem], 'success');
        }
    },
    computed: {
        ...mapGetters(['botoesComPermissao']),
        ...mapState(['carregando'])
    },
    watch: {},
    methods: {
        ...mapActions(['montaNavegacao', 'mostraCarregando']),
        async buscaPermissoes() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.devolucaovazio.permissao}`,
                data: {},
                mostraCarregando: false
            })
                .then(async resposta => {
                    const permissoes = resposta.data.data.atributos;

                    this.devolucaoVazioSituacao = SituacaoForm.GRID;

                    await this.montaNavegacao({ botoes: this.botoes, permissoes });
                })
                .catch(error => {
                    throw new Error(error);
                });
        },
        async dadosPaginaInicial() {
            try {
                await ApiService({
                    method: 'get',
                    url: `${API_URL.erp.devolucaovazio.dadosPaginaInicial}`,
                    data: {},
                    mostraCarregando: false
                })
                    .then(async resposta => {
                        await this.buscaPermissoes();
                        //await this.buscaDevolucaoVazioItens();
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });

                this.permissao = true;
            } catch (erro) {
                Alerta([erro.message], 'error');
            }
        },
        async buscaDevolucaoVazioItens() {
            this.datatable.carregando = true;

            const query = this.$refs.tabela.queryFiltros();

            await ApiService({
                method: 'get',
                url: `${API_URL.erp.devolucaovazio.datatable}/?${query}`,
                data: {},
                mostraCarregando: false
            })
                .then(resposta => {
                    this.datatable.itens = resposta.data.conteudo.datatable.itens;
                    this.datatable.totalRegistros = resposta.data.conteudo.datatable.total;
                    this.datatable.ultima_pagina = resposta.data.conteudo.datatable.ultima_pagina;
                    this.datatable.carregando = false;
                })
                .catch(erro => {
                    Alerta([erro.message], 'error');
                });
        },
        limpaFiltro() {
            this.$refs.tabela.limpaFiltros();
        },
        editaItem(item) {
            this.$router.push({
                name: 'devolucao-vazio-form',
                params: { id_devolucao_vazio: item.id_devolucao_vazio }
            });
        },
        selecionaCor(situacao) {
            if (Situacao.ABERTO == situacao) {
                return 'grey darken-1';
            }

            if (Situacao.FINALIZADO == situacao) {
                return 'green darken-1';
            }

            if (Situacao.CANCELADO == situacao) {
                return 'red darken-1';
            }

            return 'yellow darken-1';
        },
        exportaTabelaParaExcel() {
            try {
                ExportaArrayObjectsParaExcel(this.datatable.itens, this.datatable.cabecalho, 'devolucao-vazio');
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        }
    },
    data() {
        return {
            permissao: false,
            permissoes: [],
            botoes: [
                {
                    id_permissao: 6,
                    nome: 'Exportar Excel',
                    acao: 'exportaTabelaParaExcel',
                    icone: 'mdi-file-excel-outline',
                    cor: 'green darken-5',
                    form: [SituacaoForm.GRID]
                },
                {
                    id_permissao: 6,
                    nome: '',
                    acao: 'buscaDevolucaoVazioItens',
                    icone: 'mdi-reload',
                    cor: 'grey darken-5',
                    form: [SituacaoForm.GRID]
                },
                {
                    id_permissao: 6,
                    nome: '',
                    acao: 'limpaFiltro',
                    icone: 'mdi-filter-multiple',
                    cor: 'red darken-5',
                    form: [SituacaoForm.GRID]
                }
            ],
            devolucaoVazioSituacao: null,
            mensagemCarregando: 'Buscando, aguarde...',
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            datatable: {
                carregando: false,
                mensagemCarregando: 'Buscando, aguarde...',
                chave_primaria: 'id_devolucao_vazio',
                itens: [],
                totalRegistros: 0,
                ultima_pagina: 0,
                pagina_atual: 1,
                por_pagina: 50,
                ordenarPor: 'id_devolucao_vazio',
                ordenarDirecao: true,
                opcoes: {},
                cabecalho: [
                    {
                        text: 'AÇÃO',
                        value: 'acao',
                        align: 'start',
                        width: '100',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'ID',
                        value: 'id_devolucao_vazio',
                        width: '140',
                        filter: this.datatableFiltro
                    },
                    {
                        text: 'Programação Carga',
                        value: 'id_programacao_carga_rodopar',
                        width: '200'
                    },
                    {
                        text: 'CT-e',
                        value: 'numero_cte',
                        width: '150'
                    },
                    {
                        text: 'Ref Cliente',
                        value: 'referencia_cliente',
                        width: '200'
                    },
                    {
                        text: 'Número Container',
                        value: 'numero_container',
                        width: '200'
                    },
                    {
                        text: 'Tipo Container',
                        value: 'container_tipo',
                        width: '170'
                    },
                    {
                        text: 'Comprimento Container',
                        value: 'container_comprimento',
                        width: '220'
                    },
                    {
                        text: 'Situação',
                        value: 'situacao',
                        width: '150',
                        type: 'select',
                        filterData: situacaoDescricao()
                    },
                    {
                        text: 'Cliente',
                        value: 'razao_social_tomador',
                        width: '250'
                    },
                    {
                        text: 'CNPJ Cliente',
                        value: 'cnpj_tomador',
                        width: '160',
                        showFilter: false
                    },
                    {
                        text: 'Armador',
                        value: 'razao_social_armador',
                        width: '250'
                    },
                    {
                        text: 'CNPJ Armador',
                        value: 'cnpj_armador',
                        width: '160',
                        showFilter: false
                    },
                    {
                        text: 'Depot',
                        value: 'razao_social_depot',
                        width: '250'
                    },
                    {
                        text: 'Parceiro baixa',
                        value: 'parceiro_baixa',
                        width: '170',
                        type: 'select',
                        filterData: parceiroBaixaDescricao()
                    },
                    {
                        text: 'Motivo Baixa',
                        value: 'motivo_baixa',
                        width: '200',
                        type: 'select',
                        filterData: motivoBaixaDescricao()
                    },
                    {
                        text: 'Data Parceiro Baixa',
                        value: 'data_parceiro_baixa',
                        width: '200',
                        type: 'date'
                    },
                    {
                        text: 'Data Freetime',
                        value: 'data_freetime',
                        width: '200',
                        type: 'date'
                    },
                    {
                        text: 'Data Pedido',
                        value: 'data_pedido',
                        width: '180',
                        type: 'date'
                    },
                    {
                        text: 'Data Entrega',
                        value: 'data_entrega',
                        width: '180',
                        type: 'date'
                    },
                    {
                        text: 'Data Devolução',
                        value: 'data_devolucao',
                        width: '200',
                        type: 'date'
                    },
                    {
                        text: 'Nome Motorista',
                        value: 'nome_motorista',
                        width: '200'
                    },
                    {
                        text: 'CPF Motorista',
                        value: 'cpf_motorista',
                        width: '150',
                        showFilter: false
                    },
                    {
                        text: 'Cobrar Cliente',
                        value: 'cobrar_cliente',
                        width: '200',
                    },
                    {
                        text: 'Observação',
                        value: 'observacao',
                        width: '200',
                        showFilter: true
                    },
                    {
                        text: 'Usuário Criação',
                        value: 'usuario_criacao',
                        width: '150',
                        showFilter: false
                    },
                    {
                        text: 'Data Criação',
                        value: 'data_criacao',
                        width: '180',
                        type: 'date'
                    },
                    {
                        text: 'Usuário Última Alteração',
                        value: 'usuario_ultima_alteracao',
                        width: '150',
                        showFilter: false
                    },
                    {
                        text: 'Data Última Alteração',
                        value: 'data_ultima_alteracao',
                        width: '220',
                        type: 'date'
                    }
                ]
            },
            filtros: {
                id_devolucao_vazio: [],
                id_programacao_carga_rodopar: [],
                situacao: []
            }
        };
    }
};
</script>

<style></style>
