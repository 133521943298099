const INICIO_VIAGEM = 1;
const INICIO_JORNADA = 2;
const PARADA_ESPERA = 3;
const PARADA = 4;
const FIM_VIAGEM = 5;
const ALIMENTACAO = 6;
const PARADA_EXTRA = 7;

export const UltimaMacroEnum = {
    INICIO_VIAGEM: INICIO_VIAGEM,
    INICIO_JORNADA: INICIO_JORNADA,
    PARADA_ESPERA: PARADA_ESPERA,
    PARADA: PARADA,
    FIM_VIAGEM: FIM_VIAGEM,
    ALIMENTACAO: ALIMENTACAO,
    PARADA_EXTRA: PARADA_EXTRA,
};

export function listaDescricao() {
    return [
        {
            valor: INICIO_VIAGEM,
            descricao: 'Início Viagem'
        },
        {
            valor: INICIO_JORNADA,
            descricao: 'Início Jornada'
        },
        {
            valor: PARADA_ESPERA,
            descricao: 'Parada de Espera'
        },
        {
            valor: PARADA,
            descricao: 'Parada'
        },
        {
            valor: FIM_VIAGEM,
            descricao: 'Fim Viagem'
        },
        {
            valor: ALIMENTACAO,
            descricao: 'Alimentação'
        },
        {
            valor: PARADA_EXTRA,
            descricao: 'Parada Extra'
        }
    ];
}

export const lista = [
    INICIO_VIAGEM,
    INICIO_JORNADA,
    PARADA_ESPERA,
    PARADA,
    FIM_VIAGEM,
    ALIMENTACAO,
    PARADA_EXTRA,
];

export default UltimaMacroEnum;
