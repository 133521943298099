import { API_URL_BASE, API_URL } from '@/config/configs';

const Departamento = {
    datatable: {
        id_datatable: 'departamento',
        chave_primaria: 'id_departamento',
        url: `${API_URL_BASE}${API_URL.erp.departamento.datatable}`,
        colunas: false,
    },
    formRefsComponente: 'DepartamentoForm',
    formNome: 'formDepartamento',
    moduloNome: 'departamento',
    chavePrimaria: 'id_departamento',
    componenteArquivo: 'Departamento/Form.vue',
    tabRefs: 'tabDepartamento',
    urlNovoRegistro: '',
    urlConsultaRegistro: 'listaPorIdForm',
    tabs: []
}

export { Departamento };

