<template>
    <v-form ref="form">
        <v-row>
            <v-col cols="2">
                <InputTextV3 v-model="form.id_tracking_email" rotulo="ID" :contador="200" desativado />
            </v-col>

            <v-col cols="10">
                <InputAutoCompleteV3
                    v-model="form.id_cliente_rodopar"
                    :datatableConfiguracao="autoCompleta.id_cliente_rodopar.datatable"
                    :itemsOpcoes="autoCompleta.id_cliente_rodopar.complemento"
                    :contador="200"
                    tituloBusca="Cliente Rodopar Busca..."
                    itemValor="id_parceiro"
                    itemTexto="razao_social"
                />
            </v-col>

            <v-col cols="12">
                <TextAreaV3 v-model="form.observacao" :contador="1000" :obriatorio="false" rotulo="Observação" />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { API_URL, API_URL_BASE } from '@/config/configs';
import { PreencheDadosMesmaPropriedade } from '@/helpers/Funcoes.js';

import InputTextV3 from '@/components/Form/InputTextV3.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';
import InputAutoCompleteV3 from '@/components/Form/InputAutoCompleteV3.vue';
import TextAreaV3 from '@/components/Form/TextAreaV3.vue';
import DateTimePickerV2 from '@/components/Form/DateTimePickerV2.vue';

export default {
    name: 'DadosForm',
    components: {
        InputTextV3,
        InputAutoCompleteV3,
        InputSelectV3,
        TextAreaV3,
        DateTimePickerV2
    },
    props: {
        dados: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    async mounted() {},
    computed: {},
    watch: {
        dados: {
            handler: function(dadosAtual, dadosAntigo) {
                this.preencheDados(dadosAtual);
            }
        }
    },
    methods: {
        preencheDados(dados) {
            this.form = PreencheDadosMesmaPropriedade(this.form, dados);

            this.autoCompleta.id_cliente_rodopar.complemento = [];

            this.autoCompleta.id_cliente_rodopar.complemento.push({
                id_parceiro: this.form.id_cliente_rodopar,
                razao_social: dados.razao_social_autocompleta
            });
        }
    },
    data() {
        return {
            form: {
                id_tracking_email: null,
                id_cliente_rodopar: null,
                observacao: null
            },
            autoCompleta: {
                id_cliente_rodopar: {
                    complemento: [{ id_parceiro: null, razao_social: '' }],
                    datatable: {
                        id_datatable: 'parceiro_rodopar_busca',
                        chave_primaria: 'id_cliente_rodopar',
                        urlBusca: `${API_URL_BASE}${API_URL.erp.parceiro.autoCompletaParceiroRodopar}`,
                        colunas: [
                            {
                                id: 'id_parceiro',
                                nome: 'id_parceiro',
                                descricao: 'ID'
                            },
                            {
                                id: 'razao_social',
                                nome: 'razao_social',
                                descricao: 'Razão Social'
                            }
                        ]
                    }
                }
            }
        };
    }
};
</script>

<style></style>
