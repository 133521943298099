import { mapGetters, mapActions, mapState } from 'vuex';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { Alerta } from '@/helpers/Funcoes.js';

import SituacaoFormEnum from '@/Enum/Shared/SituacaoForm.js';
import SituacaoEnum, { situacaoDescricao } from '@/Modulo/WhatsappMarketing/Dominio/Enum/Situacao.js';

import TabelaAcao from '@/Modulo/Compartilhado/Tabela/Dominio/Servico/Acao';

const gridMixins = {
    mixins: [TabelaAcao],
    async mounted() {
        await this.carregaDados();

        if (this.$route.params.mensagem) {
            Alerta([this.$route.params.mensagem], 'success');
        }
    },
    computed: {
        ...mapGetters(['botoesComPermissao']),
        ...mapState(['carregando'])
    },
    methods: {
        ...mapActions(['montaNavegacao', 'mostraCarregando']),
        async carregaDados() {
            try {
                this.mostraCarregando(true);

                const permissoes = await this.dadosPaginaInicial();

                this.aplicaPermissoes(permissoes);

                this.permissao = true;
            } catch (erro) {
                Alerta([erro.message], 'error');
            } finally {
                this.mostraCarregando(false);
            }
        },
        async dadosPaginaInicial() {
            return await ApiService({
                method: 'get',
                url: `${API_URL.erp.whatsappmarketing.dadosPaginaInicial}`,
                data: {},
                mostraCarregando: false
            })
                .then(async resposta => {
                    return resposta.data.conteudo.permissoes;
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        },
        aplicaPermissoes(permissoes) {
            this.situacaoForm = SituacaoFormEnum.GRID;

            this.montaNavegacao({ botoes: this.botoes, permissoes });
        },
        async buscaDadosGrid() {
            this.datatable.carregando = true;

            const query = this.$refs.tabela.queryFiltros();

            await ApiService({
                method: 'get',
                url: `${API_URL.erp.whatsappmarketing.datatable}/?${query}`,
                data: {},
                mostraCarregando: false
            })
                .then(resposta => {
                    this.datatable.itens = resposta.data.conteudo.datatable.itens;

                    this.datatable.totalRegistros = resposta.data.conteudo.datatable.total;
                    this.datatable.ultima_pagina = resposta.data.conteudo.datatable.ultima_pagina;
                    this.datatable.carregando = false;
                })
                .catch(erro => {
                    Alerta([erro.message], 'error');
                });
        },
        novoItem() {
            this.$router.push({
                name: 'whatsapp-marketing-form'
            });
        },
        editaItem(item) {
            this.$router.push({
                name: 'whatsapp-marketing-form',
                params: { id_whatsapp_marketing: item.id_whatsapp_marketing }
            });
        },
        async apagaItem(item) {
            try {
                const ids = this.datatable.itensSelecionados
                    .map(function (item) {
                        return item.id_whatsapp_marketing;
                    })
                    .join();

                if (!ids) {
                    Alerta(['Selecione um ou mais registro no grid para apagar.'], 'error');

                    return;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Apagar', `Atendente(s) ${ids} ?`, {
                        cor: 'red'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        this.mensagemCarregando = 'Aguarde, apagando...';

                        this.mostraCarregando(true);

                        let urlConfig = {
                            method: 'DELETE',
                            url: `${API_URL.erp.whatsappmarketing.apaga}/${ids}`,
                            data: {},
                            mostraCarregando: false
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                Alerta([`Atendente(s) ${ids} apagado(s).`], 'success');

                                //this.buscaDadosGrid();
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });

                        //this.buscaDadosGrid();

                        this.datatable.itensSelecionados = [];
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.mostraCarregando(false);
            }
        },
        selecionaCor(situacao) {
            if (SituacaoEnum.ABERTO == situacao) {
                return 'grey darken-1';
            }

            if (SituacaoEnum.FINALIZADO == situacao) {
                return 'green darken-1';
            }

            return 'yellow darken-1';
        }
    },
    data() {
        return {
            permissao: false,
            permissoes: [],
            botoes: [
                {
                    id_permissao: 2,
                    nome: 'Novo',
                    acao: 'novoItem',
                    icone: 'mdi-newspaper-variant-multiple',
                    cor: 'green lighten-1',
                    form: [SituacaoFormEnum.GRID]
                },
                {
                    id_permissao: 4,
                    nome: 'Apaga',
                    acao: 'apagaItem',
                    icone: 'mdi-trash-can-outline',
                    cor: 'red lighten-1',
                    form: [SituacaoFormEnum.GRID]
                },
                {
                    id_permissao: 6,
                    nome: '',
                    acao: 'buscaDadosGrid',
                    icone: 'mdi-reload',
                    cor: 'grey darken-5',
                    form: [SituacaoFormEnum.GRID]
                },
                {
                    id_permissao: 6,
                    nome: '',
                    acao: 'limpaFiltro',
                    icone: 'mdi-filter-multiple',
                    cor: 'red darken-5',
                    form: [SituacaoFormEnum.GRID]
                }
            ],
            situacaoForm: null,
            mensagemCarregando: 'Buscando, aguarde...',
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            datatable: {
                carregando: false,
                mensagemCarregando: 'Buscando, aguarde...',
                chave_primaria: 'id_whatsapp_marketing',
                itens: [],
                totalRegistros: 0,
                ultima_pagina: 0,
                pagina_atual: 1,
                por_pagina: 50,
                ordenarPor: 'id_whatsapp_marketing',
                ordenarDirecao: true,
                opcoes: {},
                itensSelecionados: [],
                cabecalho: [
                    {
                        text: 'AÇÃO',
                        value: 'acao',
                        align: 'start',
                        width: '100',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'ID',
                        value: 'id_whatsapp_marketing',
                        width: '120'
                    },
                    {
                        text: 'Situação',
                        value: 'situacao',
                        width: '150',
                        type: 'select',
                        filterData: situacaoDescricao()
                    },
                    {
                        text: 'Telefone Atendimento',
                        value: 'telefone_atendimento',
                        width: '210'
                    },
                    {
                        text: 'Descrição',
                        value: 'descricao',
                        width: '250'
                    },
                    {
                        text: 'Data Hora Envio',
                        value: 'data_hora_envio',
                        width: '180'
                    },
                    {
                        text: 'Usuário Criação',
                        value: 'usuario_criacao',
                        width: '150',
                        showFilter: false
                    },
                    {
                        text: 'Data Criação',
                        value: 'data_criacao',
                        width: '180',
                        type: 'date'
                    },
                    {
                        text: 'Usuário Última Alteração',
                        value: 'usuario_ultima_alteracao',
                        width: '150',
                        showFilter: false
                    },
                    {
                        text: 'Data Última Alteração',
                        value: 'data_ultima_alteracao',
                        width: '220',
                        type: 'date'
                    }
                ]
            }
        };
    }
};

export default gridMixins;
