import moment from 'moment';
import { ExportaArrayObjectsParaExcel } from '@/helpers/Funcoes.js';

const acao = {
    methods: {
        exportaTabelaParaExcel({ arquivoNome }) {
            try {
                const dataHora = moment().format('DDMMYYYY-Hmmss');

                arquivoNome = arquivoNome ? `${arquivoNome}-${dataHora}` : `arquivo-${dataHora}`;

                ExportaArrayObjectsParaExcel(this.datatable.itens, this.datatable.cabecalho, arquivoNome);
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            }
        },
        limpaFiltro() {
            this.$refs.tabela.limpaFiltros();
        }
    }
};

export default acao;
