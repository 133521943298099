<template>
    <v-row>
        <v-col cols="12" sm="12">
            <InputTextV3
                v-model="form.id_whatsapp_fluxo_item"
                rotulo="id_whatsapp_fluxo_item"
                :contador="500"
                :obriatorio="true"
                :regra="regras"
                v-show="false"
                df-id-whatsapp-fluxo-item
            />

            <InputTextV3
                v-model="form.parametros"
                rotulo="parametros"
                v-show="true"
                :contador="5000"
                :obriatorio="false"
                ref="parametros"
                df-parametros
            />

            <TextAreaV3
                v-model="form.descricao"
                rotulo="Descrição"
                :contador="500"
                :obriatorio="true"
                :regra="regras"
                :rows="3"
                df-descricao
            />
        </v-col>

        <v-card-actions class="box">
            <v-col cols="12" sm="12">
                Selecione o departamento que será redirecionado a demanda:

                <InputAutoCompleteLocalV3
                    v-model="form.id_whatsap_departamento"
                    tituloBusca="Departamento"
                    :itemsOpcoes="opcaoDepartamento"
                    :smallChips="true"
                    :deletableChips="true"
                    :multiple="false"
                    itemValor="id_whatsapp_departamento"
                    itemTexto="nome"
                    class="pa-4 pt-10"
                />
            </v-col>
        </v-card-actions>
    </v-row>
</template>

<script>
import bus from './eventBus.js';

import { Obrigatorio, Minimo, Maximo } from '@/helpers/ValidadorCampo.js';

import InputTextV3 from '@/components/Form/InputTextV3.vue';
import TextAreaV3 from '@/components/Form/TextAreaV3.vue';
import InputAutoCompleteLocalV3 from '@/components/Form/InputAutoCompleteLocalV3.vue';

export default {
    name: 'RedirecionaDespartamento',
    components: {
        InputTextV3,
        TextAreaV3,
        InputAutoCompleteLocalV3
    },
    props: {
        id_whatsapp_fluxo_item: {
            type: Number,
            default: () => null
        },
        nome_node: {
            type: String,
            default: () => null
        },
        descricao: {
            type: String,
            default: () => 'Descrição'
        },
        parametros: {
            type: String,
            default: () => ''
        },
        departamentos: {
            type: Array,
            required: true
        },
        id_teste: {
            type: String,
            default: () => 'Sei laa'
        }
    },
    watch: {
        'form.id_whatsap_departamento': {
            deep: true,
            handler(novoValor) {
                this.defineParametros();
            }
        }
    },
    methods: {
        defineParametros() {
            const params = JSON.stringify({
                id_whatsapp_departamento: 5555555
            });

            this.form.parametros = params;
        }
    },
    data() {
        return {
            form: {
                id_whatsapp_fluxo_item: this.id_whatsapp_fluxo_item,
                descricao: this.descricao,
                id_whatsapp_departamento: this.id_whatsapp_departamento,
                parametros: this.parametros,
                id_teste: 123
            },
            regras: [Obrigatorio(), Minimo(2), Maximo(200)],
            opcaoDepartamento: this.departamentos
        };
    }
};
</script>
