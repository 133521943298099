<template>
    <v-chip :color="cor" class="ma-2">
        {{ rotulo }} <span class="pa-1 font-weight-black">{{ dataFormatada }}</span>
        <v-menu v-model="menuAbreFecha" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon small right color="green darken-1" v-if="value">
                        mdi-filter-check
                    </v-icon>
                    <v-icon small right v-else>
                        mdi-filter-multiple
                    </v-icon>
                </v-btn>
            </template>
            <v-container class="pa-4 pt-10 font-weight-medium" style="background-color: white;">
                <DateTimePickerV2
                    :value="value"
                    :label="rotulo"
                    data-vv-delay="1000"
                    :dataInicial="value"
                    @input="$emit('input', $event)"
                />

                <v-btn @click="menuAbreFecha = false" small text color="green" class="ml-2 mb-2">Confirmar</v-btn>
                <v-btn @click="$emit('input', null)" small text color="red" class="ml-2 mb-2">Limpar</v-btn>
            </v-container>
        </v-menu>
    </v-chip>
</template>

<script>
import moment from 'moment';

import DateTimePickerV2 from '@/components/Form/DateTimePickerV2.vue';

export default {
    components: {
        DateTimePickerV2
    },
    props: {
        value: {
            default: () => ''
        },
        regra: {
            type: Array,
            default: () => []
        },
        contador: {
            type: Number,
            default: () => 0
        },
        rotulo: {
            type: String,
            default: () => 'Data'
        },
        desativado: {
            type: Boolean,
            default: () => false
        },
        obrigatorio: {
            type: Boolean,
            default: () => false
        },
        campoFormatacao: {
            type: String,
            default: () => ''
        },
        tipo: {
            type: String,
            default: () => 'text'
        },
        iconeAppend: {
            type: String,
            default: () => ''
        },
        iconePrepend: {
            type: String,
            default: () => ''
        },
        estiloCampo: {
            type: String,
            default: () => 'outlined'
        },
        clickAppend: {
            type: Function,
            default: () => null
        },
        backgroundColor: {
            type: String,
            default: () => null
        }
    },
    async created() {},
    computed: {
        dataFormatada() {
            return this.value
                ? moment(this.value)
                      .locale('pt-br')
                      .format('L')
                : '';
        },
        cor() {
            if (moment(this.value, 'YYYY-MM-DD', true).isValid()) {
                return 'grey lighten-2';
            } else {
                return 'red lighten-2';
            }
        }
    },
    watch: {},
    mounted() {},
    methods: {
        estilo(estiloDefinido) {
            if (this.estiloCampo == estiloDefinido) {
                return true;
            }

            return false;
        }
    },
    data() {
        return {
            menuAbreFecha: false,
            campoMascara: '',
            nomask: {
                mask: '*'.repeat(1000),
                tokens: {
                    '*': { pattern: /./ }
                }
            }
        };
    }
};
</script>

<style scoped></style>
