<template>
    <v-app>
        <div class="backgruond"></div>
        <v-main class="d-flex justify-center align-center">
            <v-col cols="10" lg="4" class="mx-auto">
                <v-card class="pa-4">
                    <div class="text-center">
                        <div class="loginLogo">
                            <v-img slot="icon" contain src="@/assets/image/logo/logo_tnsys.png" />
                        </div>

                        <v-avatar size="100" class="mb-3">
                            <img :src="avatar" />
                        </v-avatar>

                        <h2 class="grey--text mb-3" v-if="nomeCompleto">{{ nomeCompleto }}</h2>
                        <h2 class="grey--text mb-3" v-else>Outro Usuário</h2>
                    </div>
                    <v-form ref="form" v-model="formularioValidado">
                        <v-card-text>
                            <InputTextV3
                                v-model="login"
                                :contador="20"
                                :regra="validaLogin"
                                @onEnter="onEnter"
                                estiloCampo="solo"
                                rotulo="Login"
                                iconePrepend="mdi-account"
                            />

                            <InputTextV3
                                v-model="senha"
                                :contador="20"
                                :regra="validaSenha"
                                :tipo="passwordShow ? 'text' : 'password'"
                                :clickAppend="
                                    () => {
                                        passwordShow = !passwordShow;
                                    }
                                "
                                :iconeAppend="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                                @onEnter="onEnter"
                                iconePrepend="mdi-key"
                                estiloCampo="solo"
                                rotulo="Senha"
                                class="mt-5"
                            />

                            <InputSelectV3
                                v-model="autenticacapTipo"
                                :regra="validaAutenticacaoTipo"
                                :itens="autenticacapTipoItens"
                                estiloCampo="solo"
                                iconePrepend="mdi-login"
                                rotulo="Autenticação"
                                itemTexto="nome"
                                itemValor="id_autenticacao_ldap"
                                class="mt-5"
                            />
                        </v-card-text>

                        <v-card-actions class="justify-center">
                            <v-btn :loading="loading" :disabled="!formularioValidado" @click="submit" color="grey darken-1">
                                <span class="white--text px-8">ENTRAR</span>
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-main>

        <v-snackbar
            v-model="notificacao.mostra"
            :multi-line="true"
            :color="`${notificacao.tipo} accent-4`"
            elevation="10"
            timeout="3000"
            bottom
            text
        >
            <span v-html="notificacao.mensagem"></span>

            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import InputTextV3 from '@/components/Form/InputTextV3.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';

export default {
    name: 'login',
    components: {
        InputTextV3,
        InputSelectV3
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            btnCarregando: false,
            formularioValidado: false,
            login: '',
            senha: '',
            passwordShow: false,
            autenticacapTipo: null,
            autenticacapTipoItens: [],
            validaLogin: [v => !!v || 'Login é obrigatório', v => v.length >= 5 || 'Senha tem que ser maior que 5'],
            validaSenha: [v => !!v || 'Senha é obrigatório', v => v?.length >= 4 || 'Senha tem que ser maior que 4'],
            validaAutenticacaoTipo: [v => !!v || 'Autenticação é obrigatório'],
            loading: false
        };
    },
    async mounted() {
        await this.getAutenticacaoLdap();

        this.login = this.loginGravado ? this.loginGravado : '';
    },
    computed: {
        ...mapGetters({
            nomeCompleto: 'autenticacao/getNomeCompleto',
            loginGravado: 'autenticacao/getLogin',
            avatar: 'autenticacao/getAvatar'
        })
    },
    methods: {
        async submit() {
            if (this.$refs.form.validate()) {
                this.loading = true;

                this.$store
                    .dispatch(
                        'autenticacao/entra',
                        {
                            login: this.login,
                            senha: this.senha,
                            autenticacao_tipo: this.autenticacapTipo
                        },
                        false
                    )
                    .then(async () => {
                        this.loading = false;

                        this.notificacao = {
                            mensagem: 'Autenticado com sucesso, redirecionando...',
                            tipo: 'success',
                            mostra: true
                        };

                        setTimeout(() => {
                            this.$router.push({ name: 'admin' });
                        }, 3000);
                    })
                    .catch(erro => {
                        this.loading = false;

                        this.notificacao = {
                            mensagem: erro,
                            tipo: 'error',
                            mostra: true
                        };
                    });
            }
        },
        async getAutenticacaoLdap() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.autenticacaoLdap.listaTipoCombo}`,
                data: {}
            })
                .then(resposta => {
                    this.autenticacapTipoItens = resposta.data.data.atributos.map(autenticacaoLdap => {
                        return autenticacaoLdap;
                    });

                    const autenticacaoTipoPrincipal = this.autenticacapTipoItens.find(
                        autenticacaoLdap => autenticacaoLdap.principal == 'S'
                    );

                    if (autenticacaoTipoPrincipal) {
                        this.autenticacapTipo = autenticacaoTipoPrincipal.id_autenticacao_ldap;
                    }
                })
                .catch(erro => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        },
        onEnter: function() {
            this.submit();
        }
    }
};
</script>

<style scoped>
.loginLogo {
    margin: auto;
    margin-top: -15px;
    max-width: 250px;
    vertical-align: middle;
    padding: 10px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.backgruond {
    background-image: url(../../assets/image/logo/transporte.jpg) !important;
    height: 300px;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    background-size: cover;
}
</style>
