<template>
  <div>
    <TituloModulo />

    <AlertaMensagem dense style="width: 100%" />

    <v-overlay :absolute="true" opacity="0.8" :value="carregando.mostraCarregando">
      <CarregandoLogoEmpresa :carregando="carregando.mostraCarregando" :mensagem="mensagemCarregando" />
    </v-overlay>

    <!-- Barra onde encontram-se os dialogs para clonagem e atribuição de perfis -->
    <usuario-perfil-table-bar v-if="!carregando.mostraCarregando && this.permissoes[0]['permitido'] == 1"
      @disparadoEventoAtualizarTabela="eventoAtualizarTabela" :perfisExistentes="perfis" />

    <v-row v-if="!carregando.mostraCarregando">
      <v-col cols="12" class="text-right">
        <TabelaVuetify v-model="datatable.itensSelecionados" :cabecalho="datatable.cabecalho" :itens="datatable.itens"
          :total_registros="datatable.totalRegistros" :por_pagina="datatable.por_pagina"
          :ultima_pagina="datatable.ultima_pagina" :carregando="datatable.carregando"
          :chave_primaria="datatable.chave_primaria" :ordenar_por="datatable.ordenarPor"
          :ordenar_direcao="datatable.ordenarDirecao" :mostra_selecao="false" @opcoes="buscaUsuariosComPerfil"
          ref="tabelaUserPerfil">
          <template v-slot:item.situacao="{ item }">
            <v-chip small class="ma-2" :color="selecionaCor(item.id_situacao)" text-color="white">
              <v-icon small left>
                mdi-alert-circle-outline
              </v-icon>
              {{ item.situacao }}
            </v-chip>
          </template>

          <template v-slot:item.acao="{ item }">
            <usuario-perfil-lapis-editar :usuario="item" :perfisExistentes="perfis"></usuario-perfil-lapis-editar>
          </template>
        </TabelaVuetify>
      </v-col>
    </v-row>

    <div class="text-center">
      <v-snackbar v-model="notificacao.mostra" :multi-line="true" :color="`${notificacao.tipo} accent-4`" elevation="10"
        timeout="3000" bottom text>
        <span v-html="notificacao.mensagem"></span>

        <template v-slot:action="{ on, attrs }">
          <v-btn color="red" text v-bind="attrs" v-on="on" @click="notificacao.mostra = false">
            Fechar
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { Alerta } from '@/helpers/Funcoes.js';

import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import UsuarioPerfilTableBar from '../../components/UsuarioPerfil/Embeeded/UsuarioPerfilTableBar.vue';
import UsuarioPerfilLapisEditar from '../../components/UsuarioPerfil/Embeeded/UsuarioPerfilLapisEditar.vue';

import TabelaVuetify from '@/components/Default/TabelaVuetify.vue';

export default {
  name: 'UsuarioPerfil',
  components: {
    AlertaMensagem,
    BarraNavegacaoBotaoV3,
    CarregandoLogoEmpresa,
    TituloModulo,
    TabelaVuetify,
    UsuarioPerfilTableBar,
    UsuarioPerfilLapisEditar
  },
  data() {
    return {
      permissao: false,
      botoes: [],
      permissoes: [],
      mensagemCarregando: 'Buscando, aguarde...',
      usuario: null,
      perfis: [],
      notificacao: {
        mostra: false,
        mensagem: '',
        tipo: 'success'
      },
      datatable: {
        carregando: false,
        mensagemCarregando: 'Buscando, aguarde...',
        chave_primaria: 'id_usuario',
        itens: [],
        totalRegistros: 0,
        ultima_pagina: 0,
        pagina_atual: 1,
        por_pagina: 50,
        ordenarPor: 'nome_completo',
        ordenarDirecao: false,
        opcoes: {},
        itensSelecionados: [],
        cabecalho: [
          {
            text: 'AÇÃO',
            value: 'acao',
            align: 'center',
            width: '10',
            sortable: false,
            showFilter: false
          },
          {
            text: 'ID do Usuário',
            value: 'id_usuario',
            width: '10',
          },
          {
            text: 'Nome completo',
            value: 'nome_completo',
            width: '600',
          },
        ]
      },
    };
  },

  async mounted() {

    await this.carregaDados();

    if (this.$route.params.mensagem) {
      Alerta([this.$route.params.mensagem], 'success');
    }

  },
  computed: {
    ...mapState(['carregando'])
  },
  watch: {},
  methods: {

    ...mapActions(['montaNavegacao', 'mostraCarregando']),

    async carregaDados() {
      try {
        this.mostraCarregando(true);

        await this.buscaPermissoes();
        await this.buscaPerfis();
        this.permissao = true;

      } catch (erro) {
        Alerta([erro.message], 'error');
      } finally {
        this.mostraCarregando(false);
      }
    },

    async buscaPermissoes() {

      await ApiService({
        method: 'get',
        url: `${API_URL.erp.usuarioPerfil.permissao}`,
        data: {},
        mostraCarregando: false
      })
        .then(async resposta => {
          const permissoes = resposta.data.data.atributos;
          this.permissoes = permissoes
        })
        .catch(error => {
          throw new Error(error);
        });
    },

    // async dadosPaginaInicial() {

    //   try {
    //     await ApiService({
    //       method: 'get',
    //       url: `${API_URL.erp.usuarioPerfil.datatable}`,
    //       data: {},
    //       mostraCarregando: false
    //     })
    //       .then(async resposta => {
    //         await this.buscaUsuariosComPerfil();
    //       })
    //       .catch(erro => {
    //         throw new Error(erro.message);
    //       });
    //     this.permissao = true;
    //   } catch (erro) {
    //     Alerta([erro.message], 'error');
    //   }

    // },

    async buscaPerfis() {

      try {
        await ApiService({
          method: 'get',
          url: `${API_URL.erp.usuarioPerfil.buscaPerfis}`,
          data: {},
          mostraCarregando: false
        })
          .then(async resposta => {
            this.perfis = resposta.data.data
          })
          .catch(erro => {
            throw new Error(erro.message);
          });
      } catch (erro) {
        Alerta([erro.message], 'error');
      }

    },

    async buscaUsuariosComPerfil() {

      this.datatable.carregando = true;
      const query = this.$refs.tabelaUserPerfil.queryFiltros();

      await ApiService({
        method: 'GET',
        url: `${API_URL.erp.usuarioPerfil.datatable}/?${query}`,
        data: {},
        mostraCarregando: false
      })
        .then(resposta => {
          this.datatable.itens = resposta.data.conteudo.datatable.itens;
          this.datatable.totalRegistros = resposta.data.conteudo.datatable.total;
          this.datatable.ultima_pagina = resposta.data.conteudo.datatable.ultima_pagina;
          this.datatable.carregando = false;
        })
        .catch(erro => {
          Alerta([erro.message], 'error');
        })
    },

    async eventoAtualizarTabela() {
      await this.carregaDados();
    }
  }
}
</script>