<template>
    <v-card outlined elevation="2">
        <v-card-title class="">
            <span class="text-h6"> Demanda Top 15 Clientes</span>

            <v-spacer></v-spacer>

            <span class="pr-2">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="blue darken-1" dark v-bind="attrs" v-on="on" @click="downloadImagem(dataDe, dataAte)">
                            mdi-cloud-download
                        </v-icon>
                    </template>
                    <span>Baixar imagem</span>
                </v-tooltip>
            </span>

            <span class="pr-2">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="blue-grey darken-1"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="carregaDashboard(dataDe, dataAte)"
                        >
                            mdi-refresh
                        </v-icon>
                    </template>
                    <span>Recarregar</span>
                </v-tooltip>
            </span>

            <span class="pr-2">
                <v-tooltip max-width="200" left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="warning darken-1" dark v-bind="attrs" v-on="on" @click="ajuda = true">
                            mdi-account-question
                        </v-icon>
                    </template>
                    <span>
                        Ajuda com a composição do gráfico ? Clique para saber mais.
                    </span>
                </v-tooltip>
            </span>
        </v-card-title>

        <v-card-text>
            <v-chart
                :option="opcoes"
                :init-options="initOptions"
                :loading="carregando"
                :loadingOptions="carregandoOpcoes"
                :style="tamanhoPixel"
                ref="Grafico"
                autoresize
            />
        </v-card-text>

        <div class="text-center">
            <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                {{ notificacao.mensagem }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>

        <v-dialog v-model="ajuda" max-width="800">
            <v-card>
                <v-card-title class="text-h5">
                    Demanda Top 15 Clientes
                </v-card-title>

                <v-card-text>
                    Programações de cargas com o status Previsto e Confirmado em percentual, de acordo com o filtro da data de
                    e até.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="blue darken-1" text @click="ajuda = false">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import moment from 'moment';
import VChart from 'vue-echarts';

export default {
    name: 'DemandaTopClientes',
    components: {
        VChart
    },
    watch: {
        dadosTopClientes: {
            handler: function(dadosAtual) {
                this.adicionaDadosAoGrafico(dadosAtual);
            },
            deep: true
        }
    },
    async mounted() {
        this.opcoes.color = this.cor;
    },
    computed: {
        ...mapGetters({
            carregando: 'demanda/carregando',
            carregandoOpcoes: 'demanda/carregandoOpcoes',
            dataDe: 'demanda/dataDe',
            dataAte: 'demanda/dataAte',
            dadosTopClientes: 'demanda/dadosTopClientes',
            tamanhoDashboard: 'dashboard/tamanhoDashboard'
        }),
        ...mapState('demanda', {
            cor(state, getters) {
                return getters['cor'];
            }
        }),
        tamanhoPixel() {
            return this.tamanhoDashboard({ id_dashboard: this.id_dashboard });
        }
    },
    methods: {
        ...mapActions({
            buscaDashboard: 'demanda/buscaDashboard',
            baixarImagem: 'demanda/baixarImagem'
        }),
        carregaDashboard(data_de, data_ate) {
            this.buscaDashboard({ data_de: data_de, data_ate: data_ate })
                .then(async () => {
                    this.adicionaDadosAoGrafico(this.dadosTopClientes);
                })
                .catch(erro => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        },
        formataData(data) {
            return moment(data).format('DD/MM/YYYY');
        },
        downloadImagem() {
            this.baixarImagem(this.$refs['Grafico']);
        },
        adicionaDadosAoGrafico(graficoDados) {
            if (!graficoDados.length) {
                return;
            }

            let cliente = [];
            let previsto = [];
            let confirmado = [];

            graficoDados?.map((item, index) => {
                cliente.push(item.cliente);
                previsto.push(item.previsto);
                confirmado.push(item.confirmado);
            });

            this.opcoes.yAxis.data = cliente;

            this.opcoes.series[0].data = previsto;
            this.opcoes.series[1].data = confirmado;
        }
    },
    data() {
        return {
            id_dashboard: 10,
            ajuda: false,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            initOptions: {
                renderer: 'png'
            },
            opcoes: {
                tooltip: {
                    trigger: 'item'
                },
                xAxis: {
                    max: 'dataMax'
                },
                yAxis: {
                    type: 'category',
                    data: [],
                    inverse: true,
                    animationDuration: 300,
                    animationDurationUpdate: 300,
                    max: 15 // only the largest 3 bars will be displayed
                },
                grid: {
                    left: '35%',
                    right: '15%',
                    bottom: '7%'
                },
                series: [
                    {
                        realtimeSort: false,
                        name: 'Previsto',
                        type: 'bar',
                        data: [],
                        label: {
                            show: true,
                            position: 'right',
                            valueAnimation: true
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 12,
                                fontWeight: 'bold'
                            },
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    },
                    {
                        realtimeSort: false,
                        name: 'Confirmado',
                        type: 'bar',
                        data: [],
                        label: {
                            show: true,
                            position: 'right',
                            valueAnimation: true
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 12,
                                fontWeight: 'bold'
                            },
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ],
                legend: {
                    show: true
                },
                animationDuration: 0,
                animationDurationUpdate: 500,
                animationEasing: 'linear',
                animationEasingUpdate: 'linear'
            }
        };
    }
};
</script>

<style scoped>
.echarts {
    width: 100%;
    height: 300px;
}
</style>
