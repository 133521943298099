<template>
    <v-form ref="form">
        <CarregandoLogoEmpresa :mensagem="mensagemCarregando" :carregando="!!mensagemCarregando" />

        <v-row v-show="!!!mensagemCarregando">
            <v-col cols="12" sm="2">
                <InputTextV3 v-model="form.id_whatsapp_fluxo" rotulo="ID" :contador="100" desativado clearable />
            </v-col>

            <v-col cols="12" sm="4">
                <InputSelectV3
                    v-model="form.id_whatsapp_numero_atendimento"
                    rotulo="Whatsapp Nº Atendimento"
                    :itens="opcao_whatsapp_numero_atendimento"
                    :carregando="carregandoNumeroAtendimento"
                    :desativado="carregandoNumeroAtendimento"
                    itemTexto="descricao"
                    itemValor="id_whatsapp_numero_atendimento"
                />
            </v-col>

            <v-col cols="12" sm="6">
                <InputTextV3 v-model="form.descricao" rotulo="Descrição" :contador="100" :regra="regrasDescricao" clearable />
            </v-col>

            <v-col cols="12" sm="2">
                MENU
            </v-col>

            <v-col cols="12" sm="10">
                COMPONENTE DRAWFLOW
            </v-col>
        </v-row>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-form>
</template>

<script>
import data from './data.js';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

import { Obrigatorio, Maximo, Minimo } from '@/helpers/ValidadorCampo.js';

import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import InputTextV3 from '@/components/Form/InputTextV3.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';

import Itens from './Itens.vue';
import RedirecionaDespartamento from './RedirecionaDespartamento.vue';

export default {
    name: 'FormDados',
    components: {
        CarregandoLogoEmpresa,
        InputTextV3,
        InputSelectV3,
        Itens,
        RedirecionaDespartamento
    },
    props: {
        id_whatsapp_fluxo: {
            type: Number,
            default: () => null
        },
        id_whatsapp_numero_atendimento: {
            type: Number,
            default: () => null
        },
        descricao: {
            type: String,
            default: () => ''
        },
        fluxo_item: {
            type: Array,
            default: () => []
        },
        fluxo_json: {
            type: String,
            default: () => ''
        },
        opcao_whatsapp_numero_atendimento: {
            type: Array,
            required: true
        }
    },
    watch: {
        id_whatsapp_fluxo: {
            deep: true,
            handler(novoValor) {
                this.form.id_whatsapp_fluxo = novoValor;
            }
        },
        id_whatsapp_numero_atendimento: {
            deep: true,
            handler(novoValor) {
                this.form.id_whatsapp_numero_atendimento = novoValor;
            }
        },
        descricao: {
            deep: true,
            handler(novoValor) {
                this.form.descricao = novoValor;
            }
        },
        fluxo_item: {
            deep: true,
            immediate: true,
            handler(novoValor) {
                //this.importaDados();
            }
        },
        fluxo_json: {
            deep: true,
            handler(novoValor) {
                this.form.fluxo_json = novoValor;
            }
        }
    },
    computed: {
        carregandoNumeroAtendimento() {
            return this.opcao_whatsapp_numero_atendimento.length == 0 ? true : false;
        }
    },
    created() {},
    async mounted() {
        await this.dadosIniciais();
    },
    methods: {
        async dadosIniciais() {
            try {
                this.mensagemCarregando = 'Carregando departamentos.';

                await ApiService({
                    method: 'GET',
                    url: `${API_URL.erp.whatsappfluxo.buscaWhatsappDepartamento}`,
                    mostraCarregando: false,
                    params: {}
                })
                    .then(resposta => {
                        if (resposta.data.data?.atributos?.length) {
                            this.whatsappDepartamentos = resposta.data.data.atributos.map(item => {
                                return {
                                    id_whatsapp_departamento: item.id_whatsapp_departamento,
                                    nome: item.nome
                                };
                            });
                        }
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.mensagemCarregando = null;
            }
        }
    },
    data() {
        return {
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            mensagemCarregando: null,
            form: {
                id_whatsapp_fluxo: null,
                descricao: null,
                fluxo_json: null,
                id_whatsapp_numero_atendimento: []
            },
            regrasDescricao: [Obrigatorio(), Maximo(100), Minimo(2)],
            editor: null,
            modalItens: false,
            tipoNome: null,
            whatsappDepartamentos: []
        };
    }
};
</script>
