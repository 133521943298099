<template>
    <v-form ref="form">
        <v-row>
            <v-col cols="12" sm="12">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left font-weight-black text-lg-h6" width="90%">
                                    Descrição
                                </th>
                                <th class="text-left font-weight-black text-lg-h6">
                                    Não / Sim
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in dados" :key="index">
                                <td class="font-weight-medium text-lg-h7">{{ item.descricao }}</td>
                                <td>
                                    <ButtonSwitchV3
                                        v-model="item.selecionado"
                                        :mostraIcone="true"
                                        :desativado="estaDesativado"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import SituacaoEnum from '@/Enum/PlanoDesenvolvimentoIndividual/SituacaoEnum.js';

import ButtonSwitchV3 from '@/components/Form/ButtonSwitchV3.vue';

export default {
    name: 'FormAnexos',
    components: {
        ButtonSwitchV3
    },
    props: {
        dados: {
            type: Array,
            default() {
                return [];
            }
        },
        situacaoPdi: {
            type: Number,
            default() {
                return 0;
            }
        }
    },
    async created() {},
    async mounted() {},
    computed: {},
    watch: {},
    methods: {
        preparaDados() {
            return this.form.map(form => {
                const dados = { ...form };

                dados.selecionado = form.selecionado ? 'S' : 'N';

                return dados;
            });
        }
    },
    data() {
        return {
            form: this.dados,
            estaDesativado: this.situacaoPdi == SituacaoEnum.FINALIZADO ? true : false
        };
    }
};
</script>

<style>
.theme--light.v-data-table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.06);
}
.theme--dark.v-data-table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.5);
}
</style>
