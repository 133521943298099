export default {
    saldo_inicial: 3000,
    lancamentos: [
        {
            tipo_documento: 'entrada',
            codigo_banco: 341,
            banco_descricao: 'Bank Itáu',
            documento: 'ACB1',
            valor_rateado: 77259.0,
            valor_pagamento: 77259.01,
            conta_contabil_codigo: 1000000,
            conta_contabil_descricao: 'Bank Itáu',
            data_previsao: '2023-06-02',
            data_pagamento: '2023-06-02',
            semana: 1,
            mes: 'junho',
            parceiro: 'TESTE'
        },
        {
            tipo_documento: 'entrada',
            codigo_banco: '001',
            banco_descricao: 'Banco do Brasil',
            documento: 'ACB1',
            valor_rateado: 10000,
            valor_pagamento: 1000,
            conta_contabil_codigo: 1000002,
            conta_contabil_descricao: 'Banco do Brasil',
            data_previsao: '2023-06-03',
            data_pagamento: '2023-06-03',
            semana: 1,
            mes: 'junho',
            parceiro: 'TESTE'
        },
        {
            tipo_documento: 'entrada',
            codigo_banco: 341,
            banco_descricao: 'Bank Itáu',
            documento: 'ACB1',
            valor_rateado: 5000.0,
            conta_contabil_codigo: 1000000,
            conta_contabil_descricao: 'Bank Itáu',
            data_previsao: '2023-06-08',
            semana: 2,
            mes: 'junho',
            parceiro: 'TESTE'
        },
        {
            tipo_documento: 'entrada',
            codigo_banco: '001',
            banco_descricao: 'itau',
            documento: 'ACB1',
            valor_rateado: 15000,
            conta_contabil_codigo: 1000002,
            conta_contabil_descricao: 'Banco do Brasil',
            data_previsao: '2023-06-08',
            semana: 2,
            mes: 'junho',
            parceiro: 'TESTE'
        },
        {
            tipo_documento: 'saida',
            documento: 'ACB1',
            valor_rateado: 610.37,
            valor_pagamento: 610.37,
            conta_contabil_codigo: 1402,
            conta_contabil_descricao: 'Frete Terceiros',
            data_previsao: '2023-06-02',
            data_pagamento: '2023-06-02',
            semana: 1,
            mes: 'junho',
            parceiro: 'TESTE'
        },
        {
            tipo_documento: 'saida',
            documento: 'ACB1',
            valor_rateado: 90000.0,
            valor_pagamento: 95000.0,
            conta_contabil_codigo: 1402,
            conta_contabil_descricao: 'Frete Terceiros',
            data_previsao: '2023-06-03',
            data_pagamento: '2023-06-03',
            semana: 1,
            mes: 'junho',
            parceiro: 'TESTE'
        },
        {
            tipo_documento: 'entrada',
            codigo_banco: 341,
            banco_descricao: 'Bank Itáu',
            documento: 'ACB1',
            valor_rateado: 2000.0,
            valor_pagamento: 2000.0,
            conta_contabil_codigo: 1000000,
            conta_contabil_descricao: 'Bank Itáu',
            data_previsao: '2023-07-02',
            data_pagamento: '2023-07-02',
            semana: 2,
            mes: 'julho',
            parceiro: 'TESTE'
        },
        {
            tipo_documento: 'saida',
            documento: 'ACB2',
            valor_rateado: 700,
            valor_pagamento: 700,
            conta_contabil_codigo: 1402,
            conta_contabil_descricao: 'Frete Terceiros',
            data_previsao: '2023-07-01',
            data_pagamento: '2023-07-01',
            semana: 1,
            mes: 'julho',
            parceiro: 'TESTE'
        },
        {
            tipo_documento: 'saida',
            documento: 'ACB1',
            valor_rateado: 19000.0,
            valor_pagamento: 19000.0,
            conta_contabil_codigo: 1402,
            conta_contabil_descricao: 'Frete Terceiros',
            data_previsao: '2023-06-08',
            data_pagamento: '2023-06-08',
            semana: 2,
            mes: 'junho',
            parceiro: 'TESTE'
        }
    ]
};
