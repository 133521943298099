<template>
    <v-row justify="center">
        <v-dialog v-model="abreModal" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card width="100vw">
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="fechaModal()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Veículos Alocados</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>

                <v-list three-line dense subheader class="pa-2">
                    <v-col cols="12">
                        <v-card width="100vw" elevation="2">
                            <v-card-title>
                                <v-toolbar-title>
                                    Tipo Coleta:
                                    <v-btn-toggle v-model="motoristaTipoColeta" mandatory>
                                        <v-btn
                                            :color="motoristaTipoColeta == 0 ? 'green darken-1' : 'red lighten-3'"
                                            class="pa-3 mt-3"
                                        >
                                            <v-icon dense left v-if="motoristaTipoColeta == 0">
                                                mdi-check-outline
                                            </v-icon>
                                            <v-icon dense left v-else>
                                                mdi-close-circle-outline
                                            </v-icon>
                                            CROSSDOCKING
                                        </v-btn>
                                        <v-btn
                                            :color="motoristaTipoColeta == 1 ? 'green darken-1' : 'red lighten-3'"
                                            class="pa-3 mt-3"
                                        >
                                            <v-icon dense left v-if="motoristaTipoColeta == 1">
                                                mdi-check-outline
                                            </v-icon>
                                            <v-icon dense left v-else>
                                                mdi-close-circle-outline
                                            </v-icon>
                                            ENTREGA CLIENTE
                                        </v-btn>
                                        <v-btn
                                            :color="motoristaTipoColeta == 2 ? 'green darken-1' : 'red lighten-3'"
                                            class="pa-3 mt-3"
                                        >
                                            <v-icon dense left v-if="motoristaTipoColeta == 2">
                                                mdi-check-outline
                                            </v-icon>
                                            <v-icon dense left v-else>
                                                mdi-close-circle-outline
                                            </v-icon>
                                            TODOS
                                        </v-btn>
                                    </v-btn-toggle>
                                </v-toolbar-title>
                            </v-card-title>

                            <v-card-title>
                                <v-toolbar-title> Filtros motorista:</v-toolbar-title>
                            </v-card-title>

                            <v-card-text>
                                <v-chip
                                    dark
                                    close
                                    label
                                    class="ma-2"
                                    :color="
                                        filtrosDatatable.motorista_entrega == motorista ||
                                        filtrosDatatable.motorista_coleta == motorista
                                            ? 'indigo lighten-1'
                                            : 'grey lighten-1'
                                    "
                                    @click="aplicaFiltromotorista(motorista)"
                                    @click:close="limpaFiltroTabela"
                                    v-for="motorista in motoristaFiltrado"
                                    :key="motorista"
                                >
                                    <v-icon left color="white">
                                        mdi-label
                                    </v-icon>
                                    {{ motorista }}
                                </v-chip>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" class="text-right">
                        <v-data-table
                            v-model="selecionados"
                            :headers="cabecalho"
                            :items="itens"
                            item-key="id_programacao_carga"
                            :items-per-page="50"
                            :search="programacaoCargaBuscar"
                            @current-items="itensAdicionaFiltrados"
                            height="65vh"
                            loading-text="Buscando, aguarde..."
                            show-select
                            multi-sort
                            class="elevation-5 datatableVeiculosAlocados"
                            calculate-widths
                            fixed-header
                            dense
                        >
                            <template v-slot:item.acao="{ item }">
                                <v-icon small class="page__grab-icon">
                                    mdi-arrow-all
                                </v-icon>
                            </template>

                            <template v-slot:top>
                                <v-tooltip bottom color="indigo lighten-2">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="indigo lighten-2"
                                            class="mr-2 pa-3 mt-3"
                                            @click="atualizarDados()"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon dense left>
                                                mdi-reload
                                            </v-icon>
                                            Atualizar dados
                                        </v-btn>
                                    </template>
                                    <span>
                                        Atualiza os dados da Programação de Carga, o mesmo botão "Buscar" do filtro da tela
                                        anterior.
                                    </span>
                                </v-tooltip>

                                <v-tooltip bottom color="purple lighten-2">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            :disabled="!registrosSelecionados"
                                            :dark="registrosSelecionados"
                                            color="purple lighten-2"
                                            class="mr-2 pa-3 mt-3"
                                            @click="gerarRomaneio()"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon dense left>
                                                mdi-reload
                                            </v-icon>
                                            Gerar Romaneio
                                        </v-btn>
                                    </template>
                                    <span>
                                        Cria o romaneio das cargas selecionadas para o motorista
                                    </span>
                                </v-tooltip>

                                <v-tooltip bottom color="red lighten-2">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            :disabled="!registrosSelecionados"
                                            :dark="registrosSelecionados"
                                            color="red lighten-2"
                                            class="mr-2 pa-3 mt-3"
                                            @click="desvincular"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon dense left>
                                                mdi-truck-remove-outline
                                            </v-icon>
                                            Desvincular
                                        </v-btn>
                                    </template>
                                    <span>
                                        Desvincula o motorista e o status da programação de carga volta para tela principal.
                                    </span>
                                </v-tooltip>

                                <v-text-field
                                    v-model="programacaoCargaBuscar"
                                    append-icon="mdi-magnify"
                                    label="Buscar..."
                                    single-line
                                    hide-details
                                    class="pa-4"
                                ></v-text-field>
                            </template>

                            <template v-slot:item.telefone_principal="{ item }">
                                <span v-if="item.telefone_principal && item.telefone_principal.length >= 11"
                                    >{{ item.telefone_principal | VMask('(##) # ####-####') }}
                                </span>
                                <span v-else>{{ item.telefone_principal | VMask('(##) ####-####') }} </span>
                            </template>

                            <template v-slot:item.valor_mercadoria="{ item }">
                                {{ mascaraMoeda(item.valor_mercadoria) }}
                            </template>
                        </v-data-table>
                    </v-col>
                </v-list>

                <div class="text-center">
                    <v-snackbar
                        v-model="notificacao.mostra"
                        :multi-line="true"
                        :color="`${notificacao.tipo} accent-4`"
                        elevation="10"
                        timeout="3000"
                        bottom
                        text
                    >
                        <span v-html="notificacao.mensagem"></span>

                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                                Fechar
                            </v-btn>
                        </template>
                    </v-snackbar>
                </div>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { FormataMoeda } from '@/helpers/Funcoes.js';

import AlocacaoCargaSituacaoEnum from '@/Enum/AlocacaoCargaSituacaoEnum';
import AlocacaoCargaTipoColetaEnum from '@/Enum/AlocacaoCargaTipoColetaEnum';

import { groupBy, isEmpty } from 'lodash';
import Sortable from 'sortablejs';

export default {
    components: {},
    props: {
        programacaoCargas: {
            type: Array,
            default: () => [],
            required: false
        },
        motoristas: {
            type: Array,
            default: () => []
        }
    },
    mounted() {},
    computed: {
        motoristaFiltrado() {
            let motoristas = [];

            this.itens.map(programacaoCarga => {
                if (!!programacaoCarga.motorista_entrega) {
                    motoristas.push(programacaoCarga.motorista_entrega);
                }

                if (!!programacaoCarga.motorista_coleta) {
                    motoristas.push(programacaoCarga.motorista_coleta);
                }
            });

            // está removendo os duplicados criando um array novo
            motoristas = [...new Set(motoristas)];

            return motoristas;
        },
        registrosSelecionados(valor) {
            return this.selecionados.length > 0;
        },
        motoristaTipoColeta: {
            get() {
                return this.motoristaTipoColetaSelecionado;
            },
            set(valorNovo) {
                this.motoristaTipoColetaSelecionado = valorNovo;

                let motorista = null;

                if (!!this.filtrosDatatable.motorista_coleta) {
                    motorista = this.filtrosDatatable.motorista_coleta;
                }

                if (!!this.filtrosDatatable.motorista_entrega) {
                    motorista = this.filtrosDatatable.motorista_entrega;
                }

                if (!!motorista) {
                    this.aplicaFiltromotorista(motorista);
                }

                return valorNovo;
            }
        }
    },
    watch: {
        programacaoCargas: {
            handler: function(dadosAtual, dadosAntigo) {
                this.preencheProgramacao(dadosAtual);
            },
            immediate: true,
            deep: true
        },
        abreModal: {
            handler: function(dadosAtual, dadosAntigo) {
                if (!this.sortableEstaInstanciado) {
                    let _self = this;

                    setTimeout(function() {
                        _self.initSortable();
                    }, 500);
                }
            }
        }
    },
    data() {
        return {
            abreModal: false,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            programacaoCargaBuscar: '',
            cabecalho: [
                { text: 'AÇÃO', value: 'acao', sortable: false },
                {
                    text: 'ID',
                    value: 'id_programacao_carga',
                    width: '80',
                    sortable: false
                },
                {
                    text: 'Programação de Carga Rodopar',
                    value: 'id_programacao_carga_rodopar',
                    align: 'start',
                    width: '80',
                    sortable: false
                },
                {
                    text: 'SOLICITANTE',
                    value: 'solicitante',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'TIPO OPERAÇÃO',
                    value: 'tipo_solicitacao',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'TERMINAL COLETA',
                    value: 'terminal coleta',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'MANIFESTO',
                    value: 'manifesto',
                    width: '200',
                    sortable: false
                },
                { text: 'CTE', value: 'cte', width: '200', sortable: false },
                { text: 'OS', value: 'os', width: '200', sortable: false },
                {
                    text: 'N° DI',
                    value: 'numero_di',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'N° DTA',
                    value: 'numero_dta',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'REFERÊNCIA',
                    value: 'referencia',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'OBS. ENTREGA',
                    value: 'observacao',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'OBS. GERAIS',
                    value: 'observacao_gerais',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'CLIENTE',
                    value: 'nome_tomador_servico',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'ORIGEM',
                    value: 'terminal_coleta',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'ORIGEM ESTADO',
                    value: 'itinerario_origem_estado',
                    width: '200'
                },
                {
                    text: 'DESTINO',
                    value: 'terminal_entrega',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'DESTINO ESTADO',
                    value: 'itinerario_destino_estado',
                    width: '200'
                },
                {
                    text: 'TIPO CARGA',
                    value: 'tipo_carga',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'QTD.',
                    value: 'quantidade_itens',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'ALTURA',
                    value: 'altura',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'LARGURA',
                    value: 'largura',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'PROFUNDIDADE',
                    value: 'profundidade',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'PESO',
                    value: 'peso_calculado',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'VALOR CARGA',
                    value: 'valor_mercadoria',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'PARCIULARIDADE CLIENTE',
                    value: 'particularidade_cliente',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'MOTORISTA COLETA',
                    value: 'motorista_coleta',
                    width: '200',
                    sortable: false,
                    filter: this.filtroDatatableMotoristaColeta
                },
                {
                    text: 'PLACA COLETA',
                    value: 'placa_coleta',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'MOTORISTA ENTREGA',
                    value: 'motorista_entrega',
                    width: '200',
                    sortable: false,
                    filter: this.filtroDatatableMotoristaEntrega
                },
                {
                    text: 'PLACA ENTREGA',
                    value: 'placa_entrega',
                    width: '200',
                    sortable: false
                },
                {
                    text: 'CONTATO TEL.',
                    value: 'telefone_principal',
                    width: '200',
                    sortable: false
                }
            ],
            itens: [],
            itensFiltrados: [],
            selecionados: [],
            filtrosDatatable: {
                motorista_entrega: null,
                motorista_coleta: null
            },
            motoristaTipoColetaSelecionado: 2,
            dragNdrop: [],
            sortable: null,
            sortableEstaInstanciado: false
        };
    },
    methods: {
        atualizarDados() {
            this.selecionados = [];

            this.$emit('atualizaDados');
        },
        mascaraMoeda(valor) {
            return FormataMoeda(valor, {}, true);
        },
        fechaModal() {
            this.selecionados = [];
            this.abreModal = false;
            this.limpaFiltroTabela();
            this.$emit('fechouModal');
        },
        preencheProgramacao(dados) {
            this.itens = dados.filter(programacaoCarga => {
                if (programacaoCarga.alocacao_carga_situacao == AlocacaoCargaSituacaoEnum.VEICULO_ALOCADO) {
                    return programacaoCarga;
                }
            });
        },
        desvincular() {
            let registros = [];

            this.selecionados.map(function(programacao) {
                // SE TIVER ROMANEIO, NÃO IRÁ CONSEGUIR DESVINCULAR
                if (
                    !isEmpty(programacao.id_alocacao_carga_romaneio_item_crossdocking) ||
                    !isEmpty(programacao.id_alocacao_carga_romaneio_item_entrega)
                ) {
                    return;
                }

                if (!!programacao.id_alocacao_carga_romaneio_item_crossdocking == false && !!programacao.tipo_coleta_coleta) {
                    registros.push({
                        id_alocacao_carga: programacao.id_alocacao_carga,
                        tipo_coleta: programacao.tipo_coleta_coleta
                    });
                }

                if (!!programacao.id_alocacao_carga_romaneio_item_entrega == false && !!programacao.tipo_coleta_entrega) {
                    registros.push({
                        id_alocacao_carga: programacao.id_alocacao_carga,
                        tipo_coleta: programacao.tipo_coleta_entrega
                    });
                }
            });

            if (registros.length == 0) {
                this.notificacao = {
                    mensagem: 'Existem romaneios gerados e não é possível desvincular.',
                    tipo: 'error',
                    mostra: true
                };

                return;
            }

            this.$emit('desvincular', registros);
        },
        filtroDatatableMotoristaEntrega(motorista) {
            if (isEmpty(this.filtrosDatatable.motorista_entrega)) {
                return true;
            }

            return this.filtrosDatatable.motorista_entrega.includes(motorista);
        },
        filtroDatatableMotoristaColeta(motorista) {
            if (isEmpty(this.filtrosDatatable.motorista_coleta)) {
                return true;
            }

            return this.filtrosDatatable.motorista_coleta.includes(motorista);
        },
        async gerarRomaneio() {
            try {
                /**
                 * Faz a ordenação, caso não tenha feito nenhuma
                 */
                this.ordenaItensSelecionados();

                let romaneios = [];

                if (this.selecionados.length == 0) {
                    this.notificacao = {
                        mensagem: 'Selecione as cargas para criar o romaneio.',
                        tipo: 'error',
                        mostra: true
                    };

                    return;
                }

                const alocacoesColeta = this.alocacaoCargaPorMotoristaColeta();
                const alocacoesEntrega = this.alocacaoCargaPorMotoristaEntrega();

                if (alocacoesColeta.length > 0) {
                    alocacoesColeta.map(function(alocacao) {
                        romaneios.push(alocacao);
                    });
                }

                if (alocacoesEntrega.length > 0) {
                    alocacoesEntrega.map(function(alocacao) {
                        romaneios.push(alocacao);
                    });
                }

                if (romaneios.length == 0) {
                    this.notificacao = {
                        mensagem: 'Não identificado nenhum motorista de entrega ou coleta para gerar o romaneio.',
                        tipo: 'error',
                        mostra: true
                    };

                    return;
                }

                const romaneiosGerados = await this.salvaRomaneio(romaneios);

                if (!romaneiosGerados) {
                    return false;
                }

                const romaneiosGeradosIds = romaneiosGerados.map(romaneio => {
                    return romaneio.id_alocacao_carga_romaneio;
                });

                const romaneiosGeradosTexto = romaneiosGeradosIds.join(', ');

                this.atualizaRomaneioSituacaoAlocacaoCarga(romaneiosGerados);

                this.limpaFiltroTabela();

                this.notificacao = {
                    mensagem: `Romaneio(s) <strong>${romaneiosGeradosTexto}</strong> criado(s) com sucesso`,
                    tipo: 'success',
                    mostra: true
                };
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };

                return false;
            }
        },
        buscaMotorista(motoristaNome) {
            return this.motoristas.find(motorista => motorista.parceiro.nome === motoristaNome);
        },
        alocacaoCargaPorMotoristaColeta() {
            const _self = this;
            const motorista_coleta = _.chain(this.selecionados)
                .groupBy('motorista_coleta')
                .value();
            let romaneios = [];

            Object.values(motorista_coleta).map(function(motoristaGrupo, indice) {
                const motoristaNome = Object.keys(motorista_coleta)[indice];

                if (motoristaNome == 'null') {
                    return false;
                }

                const alocacao = motoristaGrupo
                    .map(function(programacao) {
                        // so vincula se não tem romaneio gerado
                        if (!programacao.id_alocacao_carga_romaneio_item_crossdocking) {
                            return {
                                id_alocacao_carga_entrega: programacao.id_alocacao_carga_entrega_crossdocking,
                                ordem: programacao.ordem,
                                observacao: ''
                            };
                        }
                    })
                    .filter(notUndefined => notUndefined !== undefined);

                if (alocacao.length) {
                    const motorista = _self.buscaMotorista(motoristaNome);

                    const id_motorista = !!motorista ? motorista.id_motorista : null;

                    romaneios.push({
                        id_motorista: id_motorista,
                        alocacoes_cargas: alocacao
                    });
                }
            });

            return romaneios;
        },
        alocacaoCargaPorMotoristaEntrega() {
            const _self = this;
            const motorista_entrega = _.chain(this.selecionados)
                .filter(object => _.has(object, 'motorista_entrega'))
                .groupBy('motorista_entrega')
                .value();
            let romaneios = [];

            Object.values(motorista_entrega).map(function(motoristaGrupo, indice) {
                const motoristaNome = Object.keys(motorista_entrega)[indice];

                if (motoristaNome == 'null') {
                    return false;
                }

                const alocacao = motoristaGrupo
                    .map(function(programacao) {
                        // so vincula se não tem romaneio gerado
                        if (!programacao.id_alocacao_carga_romaneio_item_entrega) {
                            return {
                                id_alocacao_carga_entrega: programacao.id_alocacao_carga_entrega_entrega,
                                ordem: programacao.ordem,
                                observacao: ''
                            };
                        }
                    })
                    .filter(notUndefined => notUndefined !== undefined);

                if (alocacao.length) {
                    const motorista = _self.buscaMotorista(motoristaNome);

                    const id_motorista = !!motorista ? motorista.id_motorista : null;

                    romaneios.push({
                        id_motorista: id_motorista,
                        alocacoes_cargas: alocacao
                    });
                }
            });

            return romaneios;
        },
        buscaMotoristaSelecionado() {
            // pegar o motorista de acordo com a coleta
            // pois pode Coletar e Entregar o mesmo motorista
            const motoristas = this.selecionados.map(function(programacao) {
                if (programacao.tipo_coleta_coleta == AlocacaoCargaTipoColetaEnum.CROSSDOCKING) {
                    return programacao.motorista_coleta;
                }

                if (programacao.tipo_coleta_entrega == AlocacaoCargaTipoColetaEnum.ENTREGA_CLIENTE) {
                    return programacao.motorista_entrega;
                }
            });

            const motoristasDuplicados = motoristas.filter((e, i, a) => a.indexOf(e) == i);

            if (motoristasDuplicados.length > 1) {
                const motoristasDuplicadosTexto = motoristasDuplicados.join(', ');

                this.notificacao = {
                    mensagem: `Não é possível criar romaneio com motoristas diferentes. (${motoristasDuplicadosTexto})`,
                    tipo: 'error',
                    mostra: true
                };

                return false;
            }

            return this.motoristas.find(motorista => motorista.parceiro.nome === motoristasDuplicados[0]);
        },
        initSortable() {
            let table = document.querySelector('.datatableVeiculosAlocados > .v-data-table__wrapper > table > tbody');

            const _self = this;

            this.sortable = Sortable.create(table, {
                swap: false,
                swapClass: 'highlight',
                animation: 150,
                onUpdate: function(evt) {
                    _self.reorder(evt.oldIndex, evt.newIndex);
                }
            });

            this.sortableEstaInstanciado = true;
        },
        reorder(oldIndex, newIndex) {
            this.itensFiltrados.splice(newIndex, 0, this.itensFiltrados.splice(oldIndex, 1)[0]);

            this.ordenaItensSelecionados();
        },
        ordenaItensSelecionados() {
            var _self = this;

            this.itensFiltrados.forEach(function(item, index) {
                item.ordem = index + 1;

                const prg = _self.itens.find(prgVei => prgVei.id_programacao_carga === item.id_programacao_carga);

                if (prg) {
                    prg.ordem = item.ordem;
                }
            });
        },
        itensAdicionaFiltrados(valores) {
            this.itensFiltrados = JSON.parse(JSON.stringify(valores));
        },
        async salvaRomaneio(romaneios) {
            try {
                const dataForm = {
                    dados: {
                        romaneios: romaneios
                    }
                };

                const romaneio = await ApiService({
                    method: 'post',
                    url: `${API_URL.erp.alocacaocargaromaneio.novo}`,
                    data: dataForm
                })
                    .then(resposta => {
                        const dados = resposta.data.data.atributos.map(romaneio => {
                            return romaneio;
                        });

                        return dados;
                    })
                    .catch(erro => {
                        this.notificacao = {
                            mensagem: erro.message,
                            tipo: 'error',
                            mostra: true
                        };
                    });

                return romaneio;
            } catch (erro) {
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };

                return false;
            }
        },
        atualizaRomaneioSituacaoAlocacaoCarga(romaneio) {
            var _self = this;

            romaneio.forEach(function(item, index) {
                item.alocacao_carga.map(alocacaoCarga => {
                    const prg = _self.itens.find(prgVei => prgVei.id_alocacao_carga === alocacaoCarga.id_alocacao_carga);

                    if (prg) {
                        prg.alocacao_carga_situacao = alocacaoCarga.situacao;
                    }
                });
            });
        },
        limpaFiltroTabela() {
            this.filtrosDatatable.motorista_entrega = '';
            this.filtrosDatatable.motorista_coleta = '';
            this.motoristaTipoColeta = this.motoristaTipoColetaSelecionado;
            this.selecionados = [];
        },
        aplicaFiltromotorista(motorista) {
            const motoristaTipoColetaAtual = this.motoristaTipoColeta;

            this.limpaFiltroTabela();

            this.motoristaTipoColeta = motoristaTipoColetaAtual;

            if (this.motoristaTipoColeta == 0) {
                this.filtrosDatatable.motorista_coleta = motorista;

                return;
            }

            if (this.motoristaTipoColeta == 1) {
                this.filtrosDatatable.motorista_entrega = motorista;

                return;
            }

            if (this.motoristaTipoColeta == 2) {
                this.filtrosDatatable.motorista_coleta = null;
                this.filtrosDatatable.motorista_entrega = null;

                return;
            }

            this.notificacao = {
                mensagem: 'Filtro tipo coleta não identificado.',
                tipo: 'error',
                mostra: true
            };
        }
    }
};
</script>

<style scoped></style>
