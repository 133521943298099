<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="1800px">

      <usuario-perfil-tab-permissoes-selecionadas v-if="IDSperfisSelecionados.length > 0"
        :perfisSelecionados="perfisSelecionadosParaExibirNaTab"
        @eventoAtualizarPerfisSelecionados="atualizarPerfisSelecionados">
      </usuario-perfil-tab-permissoes-selecionadas>

      <template v-slot:activator="{ on, attrs }">
        <slot></slot>
      </template>

      <v-card>

        <v-card-title>
          <v-list-item class="grow">

            <span class="text-btn text-uppercase">Editar perfis do Usuário</span>

            <v-row justify="end">
              <v-icon class="mr-1" large color="red" @click="eventoAbrirOuFecharModal()">
                mdi-close-circle-outline
              </v-icon>
            </v-row>

          </v-list-item>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <div class="pt-4 pb-2 text-center">Usuário selecionado:</div>
            <div class="d-flex justify-center mb-6 mx-2">
              <v-chip large>
                <v-chip color="blue" circle="true" class="mr-2 white--text">
                  <strong>ID: {{ usuario.id_usuario }} </strong>
                </v-chip>
                {{ usuario.nome }}
              </v-chip>
            </div>

            <!-- <v-row v-if="!exibirPerfis">
              <v-col cols="3" v-for="skeletons, key in skeletonsNumber" :key="key">
                <template>
                  <v-sheet
                    :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
                    class="pa-3"
                  >
                    <v-skeleton-loader
                      class="mx-auto"
                      max-width="300"
                      type="card"
                    ></v-skeleton-loader>
                  </v-sheet>
                </template>
              </v-col>
            </v-row> -->

            <v-row v-if="perfisExistentes != []">
              <v-col cols="3" v-for="perfil, key in perfisExistentes" :key="key" :ref="'perfil-' + perfil.id_registro">
                <label class="perfil-card-pointer">
                  <input type="checkbox" class="invisible-checkbox" v-model="IDSperfisSelecionados"
                    :value="perfil.id_registro">
                  <v-container fluid>
                    <v-card :color="cardDePermissoesCor(perfil.id_registro)" style="min-height: 200px;"
                      class="pa-2 align-center text-center v-card--hover">
                      <v-chip pill small style="min-width: 25px;"><strong>ID: {{ perfil.id_registro }}</strong></v-chip>
                      <v-row class="my-4 text-subtitle-1">
                        <v-col cols="12">
                          <v-chip color="primary" x-small>NÍVEL DE ACESSO:</v-chip>
                        </v-col>
                        <span class="text--success text-subtitle-2 mx-auto" style="text-transform: uppercase;">{{
                          perfil.atributos.nome }}</span>
                        <v-col cols="12">
                          <span class="text--success caption">{{ perfil.atributos.descricao }}</span>
                        </v-col>

                      </v-row>

                    </v-card>

                  </v-container>
                </label>
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>

        <v-card-subtitle>
          <v-btn text color="success" class="ma-2 white--text mb-10" @click="apagaOuAdicionaPerfis()">
            ATUALIZAR
          </v-btn>

          <v-btn text plain color="red" class="ma-2 white--text mb-10" @click="eventoAbrirOuFecharModal()">
            CANCELAR
          </v-btn>
        </v-card-subtitle>

      </v-card>

    </v-dialog>

    <div>
      <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
        {{ notificacao.mensagem }}

        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
            Fechar
          </v-btn>
        </template>
      </v-snackbar>
    </div>

  </div>
</template>

<script>
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import UsuarioPerfilTabPermissoesSelecionadas from '@/components/UsuarioPerfil/Embeeded/UsuarioPerfilTabPermissoesSelecionadas'
import { Alerta } from '@/helpers/Funcoes.js';

export default {
  name: 'DialogEditarPermissoes',
  components: {
    UsuarioPerfilTabPermissoesSelecionadas
  },
  data() {
    return {
      usuario: {
        id_usuario: null,
        nome: null
      },
      notificacao: {
        mostra: false,
        mensagem: '',
        tipo: 'success'
      },
      dialog: false,
      perfisExistentes: [],
      perfisQueOUsuarioPossui: [],
      IDSperfisSelecionados: [],
      perfisSelecionadosParaExibirNaTab: [],
    }
  },
  watch: {
    IDSperfisSelecionados: {
      handler: function (dadosAtual, dadosAntigo) {
        this.perfisParaExibicaoNaTabDeModificacoes(dadosAtual)
      }
    }
  },
  methods: {
    eventoAbrirOuFecharModal() {
      this.dialog = !this.dialog
      this.removerIDsSelecionados()
    },

    preencheDados(usuario) {
      this.usuario = { ...usuario };
    },

    async buscaPerfis() {
      try {
        await ApiService({
          method: 'get',
          url: `${API_URL.erp.usuarioPerfil.buscaPerfis}`,
          data: {},
          mostraCarregando: true
        })
          .then(async resposta => {
            this.perfisExistentes = resposta.data.data
          })
      } catch (erro) {
        Alerta([erro.message], 'error');
      } finally {
        this.carregando = false;
      }
    },

    verificarSeOPerfilEstaDentroDoArray(arrayDePerfis, id) {
      for (let perfil in arrayDePerfis) {
        if (arrayDePerfis[perfil].id_perfil === id) {
          return true;
        }
      }
      return false;
    },

    atualizarPerfisSelecionados(id) {
      const listaDePerfisAtualizada = this.IDSperfisSelecionados.filter(IDperfil => IDperfil !== id);
      this.IDSperfisSelecionados = listaDePerfisAtualizada
    },

    async buscaPerfisDoUsuario(idDoUsuario) {
      try {
        await ApiService({
          method: 'get',
          url: `${API_URL.erp.usuarioPerfil.buscaPerfisDoUsuario}/${idDoUsuario}`,
          data: {},
          mostraCarregando: true
        })
          .then(async resposta => {

            let perfisQueOUsuarioPossui = []
            // armazenando os perfis que o usuario possui em forma de objeto pois preciso do id_usuario_perfil para fazer a exclusao posteriormente
            resposta.data[0].perfis.map(perfil => {
              perfisQueOUsuarioPossui.push({
                id_usuario_perfil: perfil.id_usuario_perfil,
                id_perfil: perfil.id_perfil,
              });
            });

            this.perfisQueOUsuarioPossui = perfisQueOUsuarioPossui
          })
      } catch (erro) {
        this.notificacao = {
          mensagem: erro.message,
          tipo: 'error',
          mostra: true
        };
      } finally {
        this.carregando = false;
      }
    },

    async atribuirUmPerfil(idPerfil) {
      try {
        await ApiService({
          method: 'POST',
          url: `${API_URL.erp.usuarioPerfil.novo}`,
          data: {
            id_usuario: this.usuario.id_usuario,
            id_perfil: idPerfil,
          }
        })
      } catch (erro) {
        this.notificacao = {
          mensagem: erro.message,
          tipo: 'error',
          mostra: true
        }
      }
    },

    async atribuirMuitosPerfis() {

      this.mostraCarregando = true

      if (this.usuario.id_usuario == null) {
        this.notificacao = {
          mensagem: 'O usuário não foi selecionado',
          tipo: 'error',
          mostra: true
        }
        this.mostraCarregando = false
        return
      }

      if (this.IDSperfisSelecionados.length == 0) {
        this.notificacao = {
          mensagem: 'Nenhuma alteração realizada',
          tipo: 'success',
          mostra: true
        }
        this.mostraCarregando = false
        return
      }

      try {
        for (let IDperfil in this.IDSperfisSelecionados) {
          await this.atribuirUmPerfil(this.IDSperfisSelecionados[IDperfil])
        }
      } catch (erro) {
        this.notificacao = {
          mensagem: erro.message,
          tipo: 'error',
          mostra: true
        }
      } finally {
        this.mostraCarregando = false
        this.notificacao = {
          mensagem: 'Nova(s) permissõe(s) atribuída(s)',
          tipo: 'success',
          mostra: true
        }
        this.buscaPerfisDoUsuario(this.usuario.id_usuario)
        this.removerIDsSelecionados()
      }
    },

    async apagaUsuarioPerfil(idUsuarioPerfil) {
      try {
        await ApiService({
          method: 'DELETE',
          url: `${API_URL.erp.usuarioPerfil.apaga}/${idUsuarioPerfil}`,
          data: {},
          mostraCarregando: true
        })
      } catch (erro) {
        this.notificacao = {
          mensagem: erro.message,
          tipo: 'error',
          mostra: true
        }
      }
    },

    async apagaOuAdicionaPerfis() {

      this.mostraCarregando = true

      if (this.IDSperfisSelecionados.length == 0) {
        this.notificacao = {
          mensagem: 'Nenhuma alteração realizada',
          tipo: 'success',
          mostra: true
        }
        return
      }

      try {
        // Ocorre uma validação simples, se ele ja possuir o perfil este será excluido, senão este será adicionado
        for (let key in this.IDSperfisSelecionados) {

          const IDPerfil = this.IDSperfisSelecionados[key]
          const usuarioPossuiOPerfilSelecionado = this.verificarSeOPerfilEstaDentroDoArray(this.perfisQueOUsuarioPossui, IDPerfil)

          if (!usuarioPossuiOPerfilSelecionado) {
            await this.atribuirUmPerfil(IDPerfil)
          }

          if (usuarioPossuiOPerfilSelecionado) {
            const usuarioPerfil = this.encontrarUsuarioPerfilPeloIDPerfil(this.perfisQueOUsuarioPossui, IDPerfil)
            await this.apagaUsuarioPerfil(usuarioPerfil.id_usuario_perfil);
          }
        }
      } catch (erro) {
        this.notificacao = {
          mensagem: erro.message,
          tipo: 'error',
          mostra: true
        }
      } finally {
        this.mostraCarregando = false
        this.notificacao = {
          mensagem: 'Modificações realizadas com sucesso!',
          tipo: 'success',
          mostra: true
        }
        this.buscaPerfis()
        this.buscaPerfisDoUsuario(this.usuario.id_usuario)
        this.removerIDsSelecionados()
      }
    },

    encontrarUsuarioPerfilPeloIDPerfil(array, id) {
      const perfil = array.find(perfil => perfil.id_perfil === id);
      return perfil;
    },

    perfisParaExibicaoNaTabDeModificacoes(dadosAtuaisDoWatcher) {
      let perfisSelecionadosParaExibirNaTab = [];

      /**
       * percorro o array de perfis existente e comparo se o id selecionado é igual ao do perfil existente, 
       * se for eu faço uma segunda validação para saber se esta sendo excluido ou adicionado e modifico suas propridades
      **/

      for (let key in dadosAtuaisDoWatcher) {
        const IDDoPerfilSelecionado = dadosAtuaisDoWatcher[key]

        this.perfisExistentes.map(perfil => {
          if ((perfil.id_registro == IDDoPerfilSelecionado)) {

            let acaoValue;

            if (this.verificarSeOPerfilEstaDentroDoArray(this.perfisQueOUsuarioPossui, IDDoPerfilSelecionado)) {
              acaoValue = 'apagar'
            }

            if (!this.verificarSeOPerfilEstaDentroDoArray(this.perfisQueOUsuarioPossui, IDDoPerfilSelecionado)) {
              acaoValue = 'adicionar'
            }

            const perfilParaTab = {
              acao: acaoValue,
              id: perfil.id_registro,
              titulo: perfil.atributos.nome,
              descricao: perfil.atributos.descricao
            }
            perfisSelecionadosParaExibirNaTab.push(perfilParaTab)
          }
        })
      }
      this.perfisSelecionadosParaExibirNaTab = perfisSelecionadosParaExibirNaTab
    },

    removerIDsSelecionados() {
      this.IDSperfisSelecionados = []
      this.perfisSelecionadosParaExibirNaTab = []
    },

    cardDePermissoesCor(id_registro) {

      if (this.IDSperfisSelecionados.includes(id_registro) && this.verificarSeOPerfilEstaDentroDoArray(this.perfisQueOUsuarioPossui, id_registro)) {
        return 'red lighten-4'
      }

      if (this.verificarSeOPerfilEstaDentroDoArray(this.perfisQueOUsuarioPossui, id_registro)) {
        return 'grey lighten-3'
      }

      if (this.IDSperfisSelecionados.includes(id_registro)) {
        return 'blue lighten-5'
      }

      return 'white'
    },
  }
}
</script>

<style scoped>
.invisible-checkbox {
  display: none;
}

.perfil-card-pointer {
  cursor: pointer;
}

.perfil-card:hover {
  background-color: rgb(216, 70, 70) !important;
}
</style>