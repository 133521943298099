<template>
    <v-card color="grey lighten-2blue-grey lighten-4" elevation="1" height="100">
        <div class="btnCloseAnexo">
            <v-icon color="red" @click="$emit('remove', posicao)"> mdi-close-circle </v-icon>
        </div>

        <v-img :src="arquivo.localUrl"></v-img>
    </v-card>
</template>

<script>
export default {
    name: 'Anexo',
    props: {
        arquivo: {
            type: Object,
            required: true,
        },
        posicao: {
            type: Number,
            required: true,
        },
    },
    emits: ['remove'],
    data() {
        return {};
    },
};
</script>

<style scoped>
.btnCloseAnexo {
    position: absolute;
    top: 6px;
    left: 6px;
    z-index: 10;
}
</style>
