<template>
    <v-card outlined elevation="2">
        <v-card-title class="">
            <span class="text-h6">
                OTD {{ ano }} e unidade

                <v-menu rounded="0" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="text-h5" color="grey darken-2" v-bind="attrs" v-on="on" dark tile>
                            {{ unidade }}
                            <v-icon right dark>
                                mdi-chevron-down
                            </v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item v-for="(item, i) in unidadeEmpreendimento" :key="i" link>
                            <v-list-item-title v-model="unidade" @click="adicionaDadosAoGrafico(item)">{{
                                item
                            }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </span>

            <v-spacer></v-spacer>

            <span class="pr-2">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="blue darken-1" dark v-bind="attrs" v-on="on" @click="downloadImagem(ano)">
                            mdi-cloud-download
                        </v-icon>
                    </template>
                    <span>Baixar imagem</span>
                </v-tooltip>
            </span>

            <span class="pr-2">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="blue-grey darken-1" dark v-bind="attrs" v-on="on" @click="carregaDashboard(ano)">
                            mdi-refresh
                        </v-icon>
                    </template>
                    <span>Recarregar</span>
                </v-tooltip>
            </span>

            <span class="pr-2">
                <v-tooltip max-width="200" left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="warning darken-1" dark v-bind="attrs" v-on="on" @click="ajuda = true">
                            mdi-account-question
                        </v-icon>
                    </template>
                    <span>
                        Ajuda com a composição do gráfico ? Clique para saber mais.
                    </span>
                </v-tooltip>
            </span>
        </v-card-title>

        <v-card-text>
            <v-chart
                :option="opcoes"
                :init-options="initOptions"
                :loading="carregando"
                :loadingOptions="carregandoOpcoes"
                :style="tamanhoPixel"
                ref="Grafico"
                autoresize
            />
        </v-card-text>

        <div class="text-center">
            <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                {{ notificacao.mensagem }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>

        <v-dialog v-model="ajuda" max-width="800">
            <v-card>
                <v-card-title class="text-h5"> OTD por ano {{ ano }} e unidade </v-card-title>

                <v-card-text>
                    Adicionar texto de ajuda
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="blue darken-1" text @click="ajuda = false">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import VChart from 'vue-echarts';

import InputSelectV3 from '@/components/Form/InputSelectV3.vue';

export default {
    name: 'OrderToDeliveryAno',
    components: {
        VChart,
        InputSelectV3
    },
    watch: {
        dadosGraficoUnidadeEmpreendimento: {
            handler: function() {
                this.adicionaDadosAoGrafico();
            },
            deep: true
        }
    },
    async mounted() {
        this.opcoes.color = this.cor;

        this.opcoes.series[0].emphasis = this.emphasis;
        this.opcoes.series[1].emphasis = this.emphasis;
    },
    computed: {
        ...mapGetters({
            carregando: 'orderToDelivery/carregando',
            carregandoOpcoes: 'orderToDelivery/carregandoOpcoes',
            ano: 'orderToDelivery/ano',
            anosPesquisa: 'orderToDelivery/anosPesquisa',
            unidadeEmpreendimento: 'orderToDelivery/unidadeEmpreendimento',
            dadosGraficoUnidadeEmpreendimento: 'orderToDelivery/dadosGraficoUnidadeEmpreendimento',
            tamanhoDashboard: 'dashboard/tamanhoDashboard'
        }),
        ...mapState('orderToDelivery', {
            cor(state, getters) {
                return getters['cor'];
            },
            emphasis(state, getters) {
                return getters['emphasis'];
            }
        }),
        tamanhoPixel() {
            return this.tamanhoDashboard({ id_dashboard: this.id_dashboard });
        }
    },
    methods: {
        ...mapActions({
            buscaDashboard: 'orderToDelivery/buscaDashboard',
            baixarImagem: 'orderToDelivery/baixarImagem'
        }),
        carregaDashboard(ano) {
            this.buscaDashboard(this.ano)
                .then(async () => {
                    this.adicionaDadosAoGrafico();
                })
                .catch(erro => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true
                    };
                });
        },
        downloadImagem() {
            this.baixarImagem(this.$refs['Grafico']);
        },
        adicionaDadosAoGrafico(unidade) {
            let noPrazo = [];
            let foraPrazo = [];
            let meta = [];
            var dadosGraficoUnidadeEmpreendimento = this.dadosGraficoUnidadeEmpreendimento;
            var unidadeEmpreendimento = unidade ?? this.unidade;

            this.unidade = unidadeEmpreendimento;

            Object.keys(dadosGraficoUnidadeEmpreendimento).forEach(function(item, key) {
                if (item == 'no_prazo') {
                    noPrazo = dadosGraficoUnidadeEmpreendimento[item][unidadeEmpreendimento]?.valor.map((valor, chave) => {
                        return {
                            value: valor,
                            quantidade: dadosGraficoUnidadeEmpreendimento[item][unidadeEmpreendimento]?.quantidade[chave],
                            total_processos: dadosGraficoUnidadeEmpreendimento['total_processos'][unidadeEmpreendimento][chave]
                        };
                    });
                }

                if (item == 'fora_prazo') {
                    foraPrazo = dadosGraficoUnidadeEmpreendimento[item][unidadeEmpreendimento]?.valor.map((valor, chave) => {
                        const valorFormatado = valor <= 0 ? 0 : -Math.abs(parseFloat(valor));

                        return {
                            value: valorFormatado,
                            quantidade: dadosGraficoUnidadeEmpreendimento[item][unidadeEmpreendimento]?.quantidade[chave]
                        };
                    });
                }

                if (item == 'meta') {
                    meta = dadosGraficoUnidadeEmpreendimento[item]?.valor;
                }
            });

            this.opcoes.series[0].data = noPrazo;
            this.opcoes.series[1].data = foraPrazo;
            this.opcoes.series[2].data = meta;
        }
    },
    data() {
        return {
            id_dashboard: 3,
            ajuda: false,
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            unidade: 'SSZ',
            initOptions: {
                renderer: 'png'
            },
            opcoes: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    },
                    formatter: function(params) {
                        let label = `<div style="margin: 0px 0 0;line-height:1;">
                                    <div style="margin: 0px 0 0;line-height:1;">
                                        <div style="font-size:14px;color:#666;font-weight:400;line-height:1;">${params[0].name}</div>
                                        <div style="margin: 10px 0 0;line-height:1;">
                                            <div style="margin: 0px 0 0;line-height:1;">
                                                <div style="margin: 0px 0 0;line-height:1;">
                                                ${params[0].marker}
                                                <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${params[0].seriesName}</span>
                                                <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${params[0].value}</span>
                                                <div style="clear:both"></div>
                                                </div>
                                                <div style="clear:both"></div>
                                            </div>
                                            <div style="margin: 10px 0 0;line-height:1;">
                                                <div style="margin: 0px 0 0;line-height:1;">
                                                ${params[1].marker}
                                                <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${params[1].seriesName}</span>
                                                <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${params[1].value}</span>
                                                <div style="clear:both">
                                                </div>
                                                </div>
                                                <div style="clear:both"></div>
                                            </div>`;

                        if (params[2]) {
                            label += `<div style="margin: 10px 0 0;line-height:1;">
                                    <div style="margin: 0px 0 0;line-height:1;">
                                    ${params[2].marker}
                                    <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${params[2].seriesName}</span>
                                    <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${params[2].value}</span>
                                    <div style="clear:both">
                                    </div>
                                    </div>
                                    <div style="clear:both"></div>
                                </div>`;
                        }

                        label += `<div>&nbsp;</div>
                                <div style="margin: 10px 0 0;line-height:1;">
                                    <div style="margin: 0px 0 0;line-height:1;">
                                    ${params[0].marker}
                                    <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">Qtd. ${params[0].seriesName}</span>
                                    <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${params[0].data.quantidade}</span>
                                    <div style="clear:both">
                                    </div>
                                    </div>
                                    <div style="clear:both"></div>
                                </div>
                                <div style="margin: 10px 0 0;line-height:1;">
                                    <div style="margin: 0px 0 0;line-height:1;">
                                    ${params[1].marker}
                                    <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">Qtd. ${params[1].seriesName}</span>
                                    <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${params[1].data.quantidade}</span>
                                    <div style="clear:both">
                                    </div>
                                    </div>
                                    <div style="clear:both"></div>
                                </div>`;

                        if (params[0].data.total_processos) {
                            label += `<div>&nbsp;</div>
                                <div style="margin: 10px 0 0;line-height:1;">
                                    <div style="margin: 0px 0 0;line-height:1;">
                                        <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">Total Processos</span>
                                        <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${params[0].data.total_processos}</span>
                                        <div style="clear:both"></div>
                                    </div>
                                    <div style="clear:both"></div>
                                </div>`;
                        }

                        label += `<div style="clear:both"></div>
                                </div>
                                <div style="clear:both"></div>
                            </div>
                            <div style="clear:both"></div>
                            </div>`;

                        return label;
                    }
                },
                legend: {
                    data: ['Meta', 'No Prazo', 'Fora Prazo']
                },
                toolbox: {
                    feature: {
                        dataView: { show: false, readOnly: false },
                        restore: { show: false },
                        saveAsImage: { show: false }
                    }
                },
                grid: {
                    top: '20%',
                    bottom: '20%',
                    right: '5%'
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            show: true
                        },
                        axisLabel: {
                            fontSize: 12,
                            fontWeight: 'bold',
                            rotate: 50
                        },
                        data: [
                            'Janeiro.',
                            'Fevereiro.',
                            'Março',
                            'Abril',
                            'Maio',
                            'Junho',
                            'Julho',
                            'Agosto',
                            'Setembro',
                            'Outubro',
                            'Novembro',
                            'Dezembro'
                        ]
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: 'No Prazo',
                        position: 'left',
                        nameRotate: 50,
                        alignTicks: true,
                        boundaryGap: ['10%', '10%'],
                        axisLine: {
                            show: true
                        },
                        axisLabel: {
                            fontSize: 12,
                            fontWeight: 'bold',
                            formatter: '{value} %'
                        },
                        nameTextStyle: {
                            fontSize: 12,
                            fontWeight: 'bold'
                        },
                        splitArea: {
                            show: true
                        }
                    }
                ],
                series: [
                    {
                        name: 'No Prazo',
                        type: 'bar',
                        stack: 'Total',
                        yAxisIndex: 0,
                        barWidth: '40%',
                        data: [],
                        itemStyle: {
                            borderType: 'dashed'
                        },
                        label: {
                            show: true,
                            fontSize: 10,
                            fontWeight: 'bold',
                            position: 'top',
                            formatter: function(value) {
                                return `${value.value} %`;
                            }
                        },
                        emphasis: this.emphasis
                    },
                    {
                        name: 'Fora Prazo',
                        type: 'bar',
                        stack: 'Total',
                        yAxisIndex: 0,
                        barWidth: '40%',
                        data: [],
                        itemStyle: {
                            borderType: 'dashed'
                        },
                        label: {
                            show: true,
                            fontSize: 10,
                            fontWeight: 'bold',
                            shadowBlur: 3,
                            position: 'bottom',
                            formatter: function(value) {
                                if (value.value == 0) {
                                    return '';
                                }

                                return `${value.value} %`;
                            }
                        },
                        emphasis: this.emphasis
                    },
                    {
                        name: 'Meta',
                        type: 'bar',
                        data: [],
                        markLine: {
                            data: [{ type: 'max', name: 'Meta' }],
                            label: {
                                fontSize: 12,
                                fontWeight: 'bold',
                                formatter: function(value) {
                                    return `${value.value} %`;
                                }
                            },
                            lineStyle: {
                                type: 'dashed'
                            }
                        }
                    }
                ]
            }
        };
    }
};
</script>
